import React from "react";
import { BsX } from "react-icons/bs";
import { FaPlus, FaCircle, FaListUl } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const ChattingMenu = ({ userSeq, performChatRoomInfo, isOnAirLive, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span title={t("팀업_상대", { ns: "chat" })}>
                    <b>{t("팀업_상대", { ns: "chat" })}</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="title-area h-auto" title={t("팀업_멤버_초대", { ns: "chat" })} onClick={() => handleSelectedFunc({ selectedFunc: "INVITE", chatRoomSeq: performChatRoomInfo.chatRoomSeq })}>
                <button className="d-flex button w-100 align-items-center justify-content-start p-2">
                    <FaPlus className="d-flex font-md mr-2" />{t("멤버_초대", { ns: "chat" })}
                </button>
            </div>
            {
                performChatRoomInfo.list_member !== undefined && performChatRoomInfo.list_member !== null && performChatRoomInfo.list_member.length > 0 ?
                    <div className="list-group" style={{ overflow: 'auto' }}>
                        {
                            performChatRoomInfo.list_member.map(member => (
                                <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={member.userSeq}>
                                    <div className="d-flex area-15 fit-content">
                                        <CustomProfileImage url={member.profileImgUrl} alt={member.userNickname} />
                                    </div>
                                    <div className="text-area justify-content-start">
                                        {
                                            member.userSeq === userSeq &&
                                            <div className="d-flex round white-text grey darken-2 mr-1 small">
                                                <span className="m-0 p-1">&nbsp;나&nbsp;</span>
                                            </div>
                                        }
                                        <span className="hiclass-text" title={member.userNickname}><b>{member.userNickname}</b></span>
                                    </div>
                                    {
                                        member.entryYN === "Y" &&
                                        <div className="d-flex area-10 ml-1">
                                            <FaCircle className="d-flex font-md green-text" />
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div> :
                    <></>
            }
            <div className="title-area h-auto" onClick={() => handleSelectedFunc({ selectedFunc: "LIST_LIVE" })}>
                <button className="d-flex button w-100 align-items-center justify-content-start p-2" title={t("내_클래스_목록_조회", { ns: "live" })}>
                    <FaListUl className="d-flex font-md mr-2" />{t("내_클래스_목록", { ns: "live" })}
                </button>
                {
                    isOnAirLive &&
                    <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" style={{ borderRadius: '1rem' }} title={t("클래스_전체_OFF", { ns: "live" })} onClick={() => handleSelectedFunc({ selectedFunc: "STOP_LIVE" })}>
                        {t("온에어", { ns: "live" })}
                    </button>
                }
            </div>
        </div>
    );
}

export default ChattingMenu;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MDBNavItem } from "mdbreact";

import { enAdminTap } from "../../../lib/ConstCommand";

const WebHeaderManageMenu = ({ isMobile, isConfigAdmin, isHiClassDomain, isMediasoupAdmin, isAdminPage, hiclasstvInfo, tossAndHandleSelectedFunc }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (isMobile) {
            let navbar = document.getElementById("navbar");
            let totalWidth = navbar.offsetWidth;
            let baseWidth = navbar.parentElement.offsetWidth;

            let offset = -1;

            if (isAdminPage) {
                switch (hiclasstvInfo.currentAdminTap) {
                    case enAdminTap.Home:
                        offset = document.getElementById("tool_manage").offsetLeft;
                        break;
    
                    case enAdminTap.LocalServer:
                        offset = document.getElementById("tool_manage_local_server").offsetLeft;
                        break;

                    case enAdminTap.LocalSite:
                        offset = document.getElementById("tool_manage_local_site").offsetLeft;
                        break;

                    case enAdminTap.LiteLocalServer:
                        offset = document.getElementById("tool_manage_lite_server").offsetLeft;
                        break;

                    case enAdminTap.Studio:
                        offset = document.getElementById("tool_manage_studio").offsetLeft;
                        break;
                    
                    case enAdminTap.Media:
                        offset = document.getElementById("tool_manage_media").offsetLeft;
                        break;

                    default:
                        break;
                }
            } else {
                offset = offset = document.getElementById("tool_manage").offsetLeft;
            }

            let position = -1;

            if (totalWidth - offset < baseWidth) {
                position = totalWidth - baseWidth;
            } else {
                position = offset - 12;
            }

            navbar.parentElement.scrollTo(position, 0);
        }
    }, [isMobile, isAdminPage, hiclasstvInfo]);

    return (
        isMobile === true ?
            <>
                <li className="nav_item">
                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Home) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_HOME")}>{t("DOMAIN", { ns: "admin" })}</span>
                </li>
                {
                    isConfigAdmin && isMediasoupAdmin &&
                    <>
                        {
                            isHiClassDomain &&
                            <>
                                <li className="nav_item" id="tool_manage_local_server">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalServer) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SERVER")}>{t("LOCAL_SERVER", { ns: "admin" })}</span>
                                </li>
                                <li className="nav_item" id="tool_manage_media">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalSite) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SITE")}>{t("LOCAL_SITE", { ns: "admin" })}</span>
                                </li>
                                <li className="nav_item" id="tool_manage_lite_server">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LiteLocalServer) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LITE_SERVER")}>{t("LITE_LOCAL_SERVER", { ns: "admin" })}</span>
                                </li>
                                <li className="nav_item" id="tool_manage_studio">
                                    <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Studio) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_STUDIO")}>{t("STUDIO", { ns: "studio" })}</span>
                                </li>
                            </>
                        }
                        <li className="nav_item" id="tool_manage_media">
                            <span className={((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Media) ? "" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_MEDIA")}>{t("MEDIA", { ns: "studio" })}</span>
                        </li>
                    </>
                }
            </> :
            <>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Home) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_HOME")}>
                        <b>{t("DOMAIN", { ns: "admin" })}</b>
                    </button>
                </MDBNavItem>
                {
                    isConfigAdmin && isMediasoupAdmin &&
                    <>
                        {
                            isHiClassDomain &&
                            <>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalServer) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SERVER")}>
                                        <b>{t("LOCAL_SERVER", { ns: "admin" })}</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LocalSite) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LOCAL_SITE")}>
                                        <b>{t("LOCAL_SITE", { ns: "admin" })}</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.LiteLocalServer) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_LITE_SERVER")}>
                                        <b>{t("LITE_LOCAL_SERVER", { ns: "admin" })}</b>
                                    </button>
                                </MDBNavItem>
                                <MDBNavItem className="mx-2">
                                    <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Studio) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_STUDIO")}>
                                        <b>{t("STUDIO", { ns: "admin" })}</b>
                                    </button>
                                </MDBNavItem>
                            </>
                        }
                        <MDBNavItem className="mx-2">
                            <button className={"hiclasstv-btn-transparent my-0" + ((isAdminPage && hiclasstvInfo.currentAdminTap === enAdminTap.Media) ? "" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_ADMIN_MEDIA")}>
                                <b>{t("MEDIA", { ns: "admin" })}</b>
                            </button>
                        </MDBNavItem>
                    </>
                }
            </>
    );
}

export default WebHeaderManageMenu;
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getFloatFixed } from "../../../../lib/func/ExportFunction";

const ScoreBox = ({ score }) => {
    switch (parseInt(score / 10)) {
        case 10:
        case 9:
        case 8:
            return (
                <div className="score">
                    <span className="green"><b>{getFloatFixed(score, 2)}</b></span>
                </div>
            );

        case 7:
        case 6:
            return (
                <div className="score">
                    <span className="orange"><b>{getFloatFixed(score, 2)}</b></span>
                </div>
            );

        default:
            return (
                <div className="score">
                    <span className="red"><b>{getFloatFixed(score, 2)}</b></span>
                </div>
            );
    }
}

const ScoreTitle = ({ title }) => {
    return (
        <div className="score score-title">
            <span>{title}</span>
        </div>
    );
}

const PronunciationResultItem = ({ result, passScore }) => {
    const { t } = useTranslation();

    const [overallScore, setOverallScore] = useState(-1);
    const [accuracyScore, setAccuracyScore] = useState(-1);
    const [fluencyScore, setFluencyScore] = useState(-1);
    const [completenessScore, setCompletenessScore] = useState(-1);
    const [omissionWord, setOmissionWord] = useState("");

    useEffect(() => {
        if (result !== undefined && result !== null) {
            setOverallScore(result.pronunciationScore);
            setAccuracyScore(result.accuracyScore);
            setFluencyScore(result.fluencyScore);
            setCompletenessScore(result.completenessScore);
            let omission_word = "";
            for (let i = 0; i < result.detailResult.Words.length; i++) {
                let word_info = result.detailResult.Words[i];
                if (word_info.PronunciationAssessment.ErrorType === "Omission") {
                    console.log("error type is omission");
                    if (omission_word !== "") {
                        omission_word += ", ";
                    }

                    console.log(word_info.Word);
                    omission_word += word_info.Word;
                }
            }
            setOmissionWord(omission_word);
        }
    }, [result]);

    return (
        <div className="d-flex flex-column w-100">
            <div className="d-flex text-center w-100">
                <span className="font-weight-bold font-xlg red-text w-100 m-1 p-1"><i>{getFloatFixed(overallScore, 2)}</i></span>
            </div>
            <div className="d-flex justify-content-end w-100">
                <span className="hiclasstv-darkblue-text font-weight-bold px-2">{`${t("발음_합격_점수", { ns: "classTalk" })} : ` + passScore}</span>
            </div>
            <div className="d-flex text-center w-100">
                <ScoreTitle title={t("발음_종합_점수", { ns: "classTalk" })} />
                <ScoreBox score={overallScore} />
            </div>
            <div className="d-flex text-center w-100">
                <ScoreTitle title={t("발음_정확도_점수", { ns: "classTalk" })} />
                <ScoreBox score={accuracyScore} />
            </div>
            <div className="d-flex text-center w-100">
                <ScoreTitle title={t("발음_유창성_점수", { ns: "classTalk" })} />
                <ScoreBox score={fluencyScore} />
            </div>
            <div className="d-flex text-center w-100">
                <ScoreTitle title={t("발음_완전성_점수", { ns: "classTalk" })} />
                <ScoreBox score={completenessScore} />
            </div>
            <div className="d-flex text-center w-100">
                <ScoreTitle title={t("발음_생략_단어", { ns: "classTalk" })} />
                <div className="score">
                    {
                        omissionWord !== "" &&
                        <span className="red my-1 p-1"><b>{omissionWord}</b></span>
                    }
                </div>
            </div>
        </div>
    );
}

export default PronunciationResultItem;
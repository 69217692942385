import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

import { enBrowserType, enManagePage, enPentalkPage, enWebPage } from "./lib/ConstCommand";

// for HiClass
import FreindListPage from "./pages/FriendListPage";
import ChatListPage from "./pages/ChatListPage";
import BroadcastLiveListPage from "./pages/BroadcastLiveListPage";
import ChattingPage from "./pages/ChattingPage";
import WhisperPage from "./pages/WhisperPage";
import QuizPage from "./pages/QuizPage";
import RemoconPage from "./pages/RemoconPage";

// for Web
import WebQRLogInPage from "./pages/WebQRLogInPage";
import WebMainPage from "./pages/WebMainPage";
import WebFriendListPage from "./pages/WebFriendListPage";
import WebChatListPage from "./pages/WebChatListPage";
import WebLiveListPage from "./pages/WebLiveListPage";
import WebStudioPage from "./pages/WebStudioPage";
import WebLivePage from "./pages/WebLivePage";
import WebPassPage from "./pages/WebPassPage";
import WebPromotionPage from "./pages/WebPromotionPage";
import WebDownloadPage from "./pages/WebDownloadPage";
import WebHiClassInfoPage from "./pages/WebHiClassInfoPage";
import WebAdminPage from "./pages/WebAdminPage";
import WebPolicyPage from "./pages/WebPolicyPage";
import WebSmartTVPage from "./pages/WebSmartTVPage";
import WebSmartTVLoginPage from "./pages/WebSmartTVLoginPage";
import WebMixedLoginPage from "./pages/WebMixedLoginPage";
import NotFoundPage from "./pages/NotFoundPage";

import { changeShowMenuKind } from "./modules/hiclasstv";

class App extends Component {

    componentDidMount() {
        //alert(navigator.maxTouchPoints);
        //console.log(window.screen);
        //console.log(`outerWidth[${window.outerWidth}], outerHeight[${window.outerHeight}]`);
        //console.log(`innerWidth[${window.innerWidth}], innerHeight[${window.innerHeight}]`);

        //console.log(`offsetWidth[${document.body.offsetWidth}], offsetHeight[${document.body.offsetHeight}]`);            

        window.addEventListener("scroll", this.didScroll);
        window.addEventListener("click", this.closeProfileView);

        /* let isMobile = false;
        isMobile = navigator.userAgent.toLowerCase().indexOf("android") > -1 ? true : false;
        isMobile = navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ? true : isMobile;
        isMobile = navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2 ? true : isMobile;
        //alert(isMobile);
        if (!isMobile) {
            window.addEventListener("beforeunload", (event) => {
                event.preventDefault();
                //event.returnValue = "";

                console.log("beforeunload");

                const { cookies } = this.props;
                let newDate = new Date(Date.now());
                newDate.setDate(newDate.getDate() - 1);
                cookies.set("liveSeq", cookies.get("liveSeq"), { path: "/", expires: newDate });
                cookies.set("userID", cookies.get("userID"), { path: "/", expires: newDate });
                cookies.set("userSeq", cookies.get("userSeq"), { path: "/", expires: newDate });
                cookies.set("viewKind", cookies.get("viewKind"), { path: "/", expires: newDate });
            });
        } */
    }

    componentDidUpdate(prevProps) {
        //console.log("App componentDidUpdate");
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.didScroll);
        window.removeEventListener("click", this.closeProfileView);
    }

    didScroll = () => {
        const { changeShowMenuKind } = this.props;

        var header = document.getElementById("header");

        if (header !== undefined && header !== null) {
            if (window.pageYOffset > 150) {
                if (header.classList.contains("ani_fixed") === false) {
                    header.classList.add("ani_fixed");
                    changeShowMenuKind({ selectedFunc: "CLOSE_HEADER_BIG_MENU" });
                }
            } else {
                if (header.classList.contains("ani_fixed")) {
                    header.classList.remove("ani_fixed");
                    changeShowMenuKind({ selectedFunc: "CLOSE_HEADER_BIG_MENU" });
                }
            }
        }
    }

    closeProfileView = () => {
        let profileView = document.getElementById("myProfile");

        if (profileView !== undefined && profileView !== null) {
            if (profileView.classList.contains("show")) {
                profileView.classList.remove("show");
            }
        }
    }

    render() {
        const { cookies, isLoginYn, isMixedClassMember } = this.props;

        return (
            <Switch>
                <Route path="/list_friend_hiclass" exact={true}
                    render={({ history, match, location }) => (<FreindListPage cookies={cookies} browserType={enBrowserType.HiClass} selectMainTap={"LIST_FRIEND"} routeProps={{ history, match, location }} />)}
                />
                <Route path="/list_chat_hiclass" exact={true}
                    render={({ history, match, location }) => (<ChatListPage cookies={cookies} browserType={enBrowserType.HiClass} selectMainTap={"LIST_CHAT"} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/list_live_hiclass", "/hiclass"]} exact={true}
                    render={({ history, match, location }) => (<BroadcastLiveListPage cookies={cookies} browserType={enBrowserType.HiClass} selectMainTap={"LIST_LIVE"} routeProps={{ history, match, location }} />)}
                />
                <Route path="/chatting_hiclass" exact={true}
                    render={({ history, match, location }) => (<ChattingPage cookies={cookies} browserType={enBrowserType.HiClass} selectMainTap={"CHATTING"} routeProps={{ history, match, location }} />)}
                />
                <Route path="/whisper_hiclass/:stdSeq" exact={true}
                    render={({ history, match, location }) => (<WhisperPage cookies={cookies} browserType={enBrowserType.HiClass} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/quiz_hiclass", "/quiz_hiclass/:quizSeq"]} exact={true}
                    render={({ history, match, location }) => (<QuizPage cookies={cookies} browserType={enBrowserType.HiClass} currentPageType={enPentalkPage.Quiz} routeProps={{ history, match, location }} />)}
                />
                <Route path="/remocon_hiclass" exact={true}
                    render={({ history, match, location }) => (<RemoconPage cookies={cookies} browserType={enBrowserType.HiClass} selectMainTap={"REMOCON"} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/main", "/"]} exact={true}
                    render={({ history, match, location }) => (<WebMainPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Home} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/main-qrLogin/:liveSeq", "/main_qrLogin/:liveSeq", "/main-qr-login/:liveSeq", "/main_qr_login/:liveSeq"]} exact={true}
                    render={({ history, match, location }) => (<WebQRLogInPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.LiveQRLogin} routeProps={{ history, match, location }} />)}
                />
                <Route path="/list_chat" exact={true}
                    render={({ history, match, location }) => (<WebChatListPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.TeamUp} routeProps={{ history, match, location }} />)}
                />
                <Route path="/list_live" exact={true}
                    render={({ history, match, location }) => (<WebLiveListPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Live} routeProps={{ history, match, location }} />)}
                />
                <Route path="/list_studio" exact={true}
                    render={({ history, match, location }) => (<WebStudioPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Studio} routeProps={{ history, match, location }} />)}
                />
                <Route path="/live" exact={true}
                    render={({ history, match, location }) => (<WebLivePage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.LiveClassTalk} isLoginYn={isLoginYn} isJoinLive={false} isMixedClassMember={false} routeProps={{ history, match, location }} />)}
                />
                <Route path="/live/:liveSeq" exact={true}
                    render={({ history, match, location }) => (<WebLivePage cookies={cookies} browserType={enBrowserType.Web} currentPageType={isMixedClassMember ? enWebPage.MixedClassLiveClassTalk : enWebPage.LiveClassTalk} isLoginYn={isLoginYn} isJoinLive={true} isMixedClassMember={isMixedClassMember} routeProps={{ history, match, location }} />)}
                />
                <Route path="/list_friend" exact={true}
                    render={({ history, match, location }) => (<WebFriendListPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Friend} routeProps={{ history, match, location }} />)}
                />
                <Route path="/download" exact={true}
                    render={({ history, match, location }) => (<WebDownloadPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Download} routeProps={{ history, match, location }} />)}
                />
                <Route path="/info" exact={true}
                    render={({ history, match, location }) => (<WebHiClassInfoPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Info} routeProps={{ history, match, location }} />)}
                />
                <Route path="/admin" exact={true}
                    render={({ history, match, location }) => (<WebAdminPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Manage} currentManagePageType={enManagePage.SubDomainInfo} routeProps={{ history, match, location }} />)}
                />
                <Route path="/admin/subdomain/:subDomainSeq" exact={true}
                    render={({ history, match, location }) => (<WebAdminPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Manage} currentManagePageType={enManagePage.ConfigSubDomain} routeProps={{ history, match, location }} />)}
                />
                <Route path="/admin/subdomain/:subDomainSeq/member" exact={true}
                    render={({ history, match, location }) => (<WebAdminPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Manage} currentManagePageType={enManagePage.ConfigSubDomainMember} routeProps={{ history, match, location }} />)}
                />
                <Route path="/pass" exact={true}
                    render={({ history, match, location }) => (<WebPassPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Pass} routeProps={{ history, match, location }} />)}
                />
                <Route path="/promotion" exact={true}
                    render={({ history, match, location }) => (<WebPromotionPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Promotion} isPromotionInfo={false} routeProps={{ history, match, location }} />)}
                />
                <Route path="/promotion/:promotionSeq"
                    render={({ history, match, location }) => (<WebPromotionPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Promotion} isPromotionInfo={true} routeProps={{ history, match, location }} />)}
                />
                <Route path="/policy" exact={true}
                    render={({ history, match, location }) => (<WebPolicyPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.Policy} routeProps={{ history, match, location }} />)}
                />
                <Route path="/app_policy" exact={true}
                    render={({ history, match, location }) => (<WebPolicyPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.AppPolicy} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/smart_view/:liveSeq", "/smart_view", "/smarttv/:liveSeq", "/smarttv"]} exact={true}
                    render={({ history, match, location }) => (<WebSmartTVPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.SmartTV} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/smart_view/login/:liveSeq", "/smarttv_login/:liveSeq"]} exact={true}
                    render={({ history, match, location }) => (<WebSmartTVLoginPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.SmartTV} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/group_smart_view/:liveSeq", "/group_smart_view", "/group_smarttv/:liveSeq", "/group_smarttv"]} exact={true}
                    render={({ history, match, location }) => (<WebSmartTVPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.GroupSmartTV} routeProps={{ history, match, location }} />)}
                />
                <Route path={["/group_smart_view/login/:liveSeq", "/group_smarttv_login/:liveSeq"]} exact={true}
                    render={({ history, match, location }) => (<WebSmartTVLoginPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.GroupSmartTV} routeProps={{ history, match, location }} />)}
                />
                <Route path={"/mixed_login/:mixedClassSeq"} exact={true}
                    render={({ history, match, location }) => <WebMixedLoginPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.MixedClassLogin} routeProps={{ history, match, location }} />}
                />
                <Route
                    render={({ history, match, location }) => (<NotFoundPage cookies={cookies} browserType={enBrowserType.Web} currentPageType={enWebPage.NotFound} routeProps={{ history, match, location }} />)}
                />
            </Switch>
        );
    }
}

export default withCookies(connect(
    ({ user, live }) => ({
        isLoginYn           : user.isLoginYn,

        isMixedClassMember  : live.isMixedClassMember
    }),
    {
        changeShowMenuKind,
    }
)(App));
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { MDBInput } from "mdbreact";

import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";

const QRLoginModal = ({ isModalOpened, selectedUser_info, toggleModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [liveStudentNickname, setLiveStudentNickname] = useState("");

    const [alertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [alertMessage, setAlertMessage] = useState("");

    const handleKeyPress = (e) => {
        if(e.key === "Enter") {
            onHandleLogin();
        }
    }

    const onChangeLiveStudentNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("유효성_검사_닉네임_글자수오류", { ns: "user" }));
        } else {
            if (alertMessage !== "") {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
        setLiveStudentNickname(str);
    }

    const onHandleLogin = () => {
        if (liveStudentNickname === "") {
            handleSelectedFunc({
                selectedFunc: "LOGIN_BY_QR",
                loginInfo: {
                    userID: selectedUser_info.userID,
                    userSeq: selectedUser_info.userSeq,
                    userNickname: selectedUser_info.userNickname
                }
            });
        } else {
            if (liveStudentNickname.length > 15) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("유효성_검사_닉네임_글자수오류", { ns: "user" }));
                return;
            } else {
                handleSelectedFunc({
                    selectedFunc: "LOGIN_BY_QR",
                    loginInfo: {
                        userID: selectedUser_info.userID,
                        userSeq: selectedUser_info.userSeq,
                        userNickname: liveStudentNickname
                    }
                });
            }
        }
    }

    const onClosedModal = () => {
        setLiveStudentNickname("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onClosedModal} >
            <div className="card container-fluid px-3 m-0">
                <div className="card-body">
                    <div className="d-flex title-area w-100 justify-content-end text-right mb-3">
                        <button type="button" className="hiclasstv-btn p-1" title={t("닫기", { ns: "common" })} onClick={() => toggleModalOpened(false)}>
                            <FaTimes className="d-flex m-1 black-text" />
                        </button>
                    </div>
                    <div className="d-flex flex-column w-100 justify-content-center align-items-start mb-2">
                        <span className="font-weight-bold font-md plus p-2">
                            {t("클래스용_닉네임_설명_4", { ns: "user" })}
                        </span>
                        <span className="font-weight-bold font-md plus pb-2 px-2">
                            {t("클래스용_닉네임_설명_5", { ns: "user" })}
                        </span>
                    </div>
                    <div className="d-flex flex-column align-items-center pt-3 pb-2 area-90">
                        <MDBInput type="text" id="inputNn" className="input-form-control" containerClass="input-form m-0" label={t("클래스용_닉네임", { ns: "user" })} labelClass="input-form-label" placeholder={t("유효성_검사_닉네임_입력오류", { ns: "user" })} onChange={onChangeLiveStudentNickname} value={liveStudentNickname} onKeyPress={handleKeyPress} />
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description info">· {t("닉네임_구성_설명", { ns: "user" })}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description info">· {t("클래스용_닉네임_설명_1", { ns: "user" })}</span>
                    </div>
                    <div className="d-flex w-100 justify-content-start">
                        <span className="input-description notice">* {t("클래스용_닉네임_설명_2", { ns: "user" })}</span>
                    </div>
                    {
                        alertMessage !== "" &&
                        <div className="d-flex w-100 justify-content-center align-items-center mb-3">
                            <Alerts kind={alertKind} message={alertMessage} />
                        </div>
                    }
                    <div className="d-flex w-100 justify-content-center align-items-center pt-3">
                        <button className="btn hiclasstv-blue white-text" disabled={selectedUser_info ? false : true} onClick={() => onHandleLogin()}>
                            {t("Login", { ns: "user"})}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default QRLoginModal;
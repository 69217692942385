import React from 'react';
import { useTranslation } from "react-i18next";

const AppServicePolicy = () => {
    const { t } = useTranslation();
    
    return (
        <span>
            <p className="last-p"><b></b></p>
            
            <h3>1. {t("앱_이용약관_1_개인정보처리방침", { ns: "policy" })}</h3>
            <ul>
                <li>
                    <span className="sub-title">1.1 {t("앱_이용약관_1_개인정보처리방침_1", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_2", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">1.2 {t("앱_이용약관_1_개인정보처리방침_3", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_4", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">1.3 {t("앱_이용약관_1_개인정보처리방침_5", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_6", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">1.4 {t("앱_이용약관_1_개인정보처리방침_7", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_8", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">1.4 {t("앱_이용약관_1_개인정보처리방침_9", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_10", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">1.5 {t("앱_이용약관_1_개인정보처리방침_11", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_1_개인정보처리방침_12", { ns: "policy" })}
                </li>
            </ul>
            
            <h3>2. {t("앱_이용약관_2_기타사항", { ns: "policy" })}</h3>
            <ul>
                <li>
                    <span className="sub-title">2.1 {t("앱_이용약관_2_기타사항_1", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_2_기타사항_2", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">2.2 {t("앱_이용약관_2_기타사항_3", { ns: "policy" })}</span><br />                    
                    {t("앱_이용약관_2_기타사항_4", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">2.3 {t("앱_이용약관_2_기타사항_5", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_2_기타사항_6", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">2.4 {t("앱_이용약관_2_기타사항_7", { ns: "policy" })}</span><br />
                    {t("앱_이용약관_2_기타사항_8", { ns: "policy" })}
                </li>
            </ul>
        </span>
    )
}

export default AppServicePolicy;
import React,{ Component } from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { rawDataWithRoutineKey, sendedFromHiClass } from "./lib/EoBrowserRcvUtil";
import { createStore, applyMiddleware, compose } from "redux";
//import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./modules";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension"

class HiclassTV extends Component {

    store = createStore(rootReducer, 
        compose(composeWithDevTools(applyMiddleware(ReduxThunk)), window.devToolsExtension ? window.devToolsExtension() : f => f));

    sendRawDataWithRoutinKey = (rawData, routingKey) => {
        console.log("sendRawDataWithRoutinKey - routingKey => ", routingKey);
        rawDataWithRoutineKey(this.store, rawData, routingKey, false);
    }

    sendWhisperData = (rawData, routingKey) => {
        console.log("sendWhisperData - routingKey => ", routingKey);
        rawDataWithRoutineKey(this.store, rawData, routingKey, true);
    }

    sendedDataFromHiClass = (sendKind, sendData) => {
        sendedFromHiClass(this.store, sendKind, sendData);
    }

    render() {
        return (
            <Provider store={this.store}>
                <CookiesProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                  </CookiesProvider>
            </Provider>
        );
      }
  }

export default HiclassTV;
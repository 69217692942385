import React, { useState } from "react";
import { FaPlusSquare, FaSmile, FaPaperPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const InputGroupBox = ({ selIndex, isLiveChattingLocked, onHandleClassTalkFunc, handleSelectedClassTalkFunc }) => {
    const { t } = useTranslation();

    const [lMessageValue, setMessageValue] = useState("");
    const [tHeight, setTHeight] = useState("32px");

    const onChangeMessageValue = (e) => {
        if (e.target.scrollHeight > 32) {
            //console.log("onChangeMessageValue : ", e.target.scrollHeight);
            let newHeight;
            if (e.target.scrollHeight > 160) {
                newHeight = "160px";
            } else {
                newHeight = e.target.scrollHeight + "px";
            }

            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        } else {
            //console.log("onChangeMessageValue : ", e.target.scrollHeight);
            let newHeight = "32px";
            if (newHeight !== tHeight) {
                setTHeight(newHeight);
            }
        }
        setMessageValue(e.target.value);
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            //if (!e.shiftKey) {
            createSend();
            e.preventDefault();
            //}
        }
    }

    const createSend = () => {
        if (selIndex < 0) {
            if (lMessageValue !== "") {
                //handleSelectedClassTalkFunc({selectedFunc:"SEND_MSG", msgText:lMessageValue});
                onHandleClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
                setMessageValue("");
                setTHeight("32px");
            } else {
                console.log("createSend() - 메시지 입력x");
            }
        } else {
            //handleSelectedClassTalkFunc({selectedFunc:"SEND_MSG", msgText:lMessageValue});
            onHandleClassTalkFunc({ selectedFunc: "SEND_MSG", msgText: lMessageValue });
            setMessageValue("");
            setTHeight("32px");
        }
    }

    return (
        <div className="hiclasstv-classtalk-input-main" style={{ height: `${tHeight}`, width: '100%', minHeight: '2rem' }}>
            <div className={"chatTouchLockGuard" + (isLiveChattingLocked ? " visible" : "")}>
                {t("채팅_잠김_알림_메시지", { ns: "classTalk" })}
            </div>
            <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" data-toggle="tooltip" title={t("메뉴_더_보기", { ns: "classTalk" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_TOGGLE" })}>
                <FaPlusSquare className="font-md plus" aria-hidden="true" />
            </button>
            <div className="hiclasstv-classtalk-input-box">
                <textarea className="hiclasstv-classtalk-input" style={{ resize: 'none', boxSizing: ' border-box' }} placeholder={t("채팅_입력_알림_메시지", { ns: "classTalk" })} onChange={onChangeMessageValue} value={lMessageValue} onKeyPress={handleKeyPress} />
                <div className="input-group-append align-items-center h-100">
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-yellow-text" data-toggle="tooltip" title={t("이모티콘_선택", { ns: "classTalk" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "EMOTICON_TOGGLE" })}>
                        <FaSmile className="font-md plus" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" title={t("전송", { ns: "common" })} onClick={createSend}>
                <FaPaperPlane className="font-md plus" aria-hidden="true" />
            </button>
        </div>
    );
}

export default InputGroupBox;
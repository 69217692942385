import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";

import { FaRegClock, FaUserAlt, FaPlus, FaMinus } from 'react-icons/fa';
import { BsX } from 'react-icons/bs';

import "react-datepicker/dist/react-datepicker.css";

import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';

import ConstData from '../../../lib/ConstData';

import Alerts from '../../etc/list/Alerts';
import CustomDatePicker from '../../etc/button/CustomDatePicker';

const Ampm = ['AM', 'PM'];
const Hour = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const Minute = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

const NotifyLiveBroadcast = ({ list_live, handleSelectedFunc, alertMessage, alertKind }) => {
    const { t } = useTranslation();

    const [lLiveList, setLiveList] = useState(list_live);
    const [selectedLiveOption, setSelectedLiveOption] = useState(-1);
    
    const startDateRef = new createRef();
    const endDateRef = new createRef();

    const [startDate, setStartDate] = useState(new Date(Date.now()));
    const [endDate, setEndDate] = useState(new Date());

    const [isValidateStartDate, setIsValidateStartDate] = useState(true);
    const [isValidateEndDate, setIsValidateEndDate] = useState(true);
    const [isValidateGuestList, setIsValidateGuestList] = useState(true);
    
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    const [lGuestArr, setGuestArr] = useState([]);

    useEffect(() => {
        let stDate = new Date(startDate);
        stDate.setHours(startDate.getHours() + 1);
        stDate.setMinutes(0, 0, 0);
        setStartDate(stDate);

        let eDate = new Date(stDate);
        eDate.setHours(stDate.getHours() + 1);
        eDate.setMinutes(0, 0, 0);
        setEndDate(eDate);
    }, []);

    useEffect(() => {
        let now = Date.now();

        if (startDate < now) {
            setIsValidateStartDate(false);
        } else {
            setIsValidateStartDate(true);
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate <= startDate) {
            setIsValidateEndDate(false);
        } else {
            setIsValidateEndDate(true);
        }
    }, [endDate]);

    useEffect(() => {
        checkInvalidData(lGuestArr);
    }, [lGuestArr]);

    useEffect(() => {
        if (lLiveList && lLiveList.length > 0) {
            setSelectedLiveOption({ idx:0, liveSeq:lLiveList[0].liveSeq, liveNmae:lLiveList[0].liveName });
        }
    }, [lLiveList]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const selectLive = (e) => {
        setSelectedLiveOption({idx:e.target.value, liveSeq:lLiveList[e.target.value].liveSeq, liveName:lLiveList[e.target.value].liveName});
    }
    
    const updateEndDate = (preStartDate, currentStartDate) => {        
        let dayGap = parseInt((endDate - preStartDate) / (1000 * 60 * 60 * 24));
        let hourGap = parseInt(((endDate - preStartDate) / (1000 * 60 * 60)) % 24);
        let minuteGap = parseInt(((endDate - preStartDate) / (1000 * 60)) % 60);

        // 현재 endDate + gap 만큼 더해주기
        let eDate = new Date(currentStartDate);
        eDate.setDate(eDate.getDate() + dayGap);
        eDate.setHours(eDate.getHours() + hourGap);
        eDate.setMinutes(eDate.getMinutes() + minuteGap);
        setEndDate(eDate);
    }

    const onChangeStartDatePicker = (date) => {
        setStartDate(date);
        updateEndDate(startDate, date);
    }

    const onChangeEndDatePicker = (date) => {
        setEndDate(date);
    }

    const selectStartAPM = (e) => {
        let stDate = new Date(startDate);

        switch (e.target.value) {
            case Ampm[0] : 
                stDate.setHours(stDate.getHours() - 12);
                break;

            case Ampm[1] : 
                stDate.setHours(stDate.getHours() + 12);
                break;

            default :
                break;
        }

        setStartDate(stDate);
        updateEndDate(startDate, stDate);
    }

    const selectStartHour = (e) => {
        let hour = e.target.value * 1;
        let stDate = new Date(startDate);

        if (stDate.getHours() < 12) {
            stDate.setHours(hour);
        } else {
            stDate.setHours(hour + 12);
        }

        setStartDate(stDate);
        updateEndDate(startDate, stDate);
    }

    const selectStartMinute = (e) => {
        let stDate = new Date(startDate);
        stDate.setMinutes(e.target.value);
        setStartDate(stDate);
        updateEndDate(startDate, stDate);
    }
    
    const selectEndAPM = (e) => {
        let eDate = new Date(endDate);

        switch (e.target.value) {
            case Ampm[0] : 
                eDate.setHours(eDate.getHours() - 12);
                break;

            case Ampm[1] : 
                eDate.setHours(eDate.getHours() + 12);
                break;

            default :
                break;
        }

        setEndDate(eDate);
    }

    const selectEndHour = (e) => {
        let hour = e.target.value * 1;
        let eDate = new Date(endDate);

        if (eDate.getHours() < 12) {
            eDate.setHours(hour);
        } else {
            eDate.setHours(hour + 12);
        }

        setEndDate(eDate);
    }

    const selectEndMinute = (e) => {
        let eDate = new Date(endDate);
        eDate.setMinutes(e.target.value);
        setEndDate(eDate);
    }

    const checkInvalidData = (list) => {
        let check = true;

        list.forEach(info => {
            if (info.email === "" || info.nickname === "") {
                check = false;
            }
        });

        setIsValidateGuestList(check);
    }

    const addGuestEmail = () => {
        const arr = lGuestArr.map(info => info);

        let item = {
            isEmailValidate : false,
            email : "",
            isNicknameValidate : false,
            nickname : ""
        };
        
        arr.push(item);
        setGuestArr(arr);
        setIsValidateGuestList(false);
    }

    const removeGuestItem = (idx) => {
        const guestList = lGuestArr.filter(
            (info, index) => 
                !(index === idx && (info.email === lGuestArr[idx].email && info.nickname === lGuestArr[idx].nickname)
        ));
        setGuestArr(guestList);
    }

    const onChangeGuestNickname = (e) => {
        let id = e.target.id.split('-');

        let str = e.target.value;

        if (str.length > 10) {
            str = str.substr(0, 15);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("유효성_검사_닉네임_글자수오류", { ns: "user" }));
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage('');
        }
        
        const arr = lGuestArr.map(
            (info, idx) => {
                let value = idx === parseInt(id[1]) ? {
                    ...info,
                    isNicknameValidate : str !== "" ? true : false,
                    nickname : str
                } : info;
                
                return value;
            }
        );

        setGuestArr(arr);
    }

    const onChangeGuestEmail = (e) => {
        let id = e.target.id.split('-');
        
        let str = e.target.value;        
        str = str.replace(' ', '');

        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("유효성_검사_이메일_글자수오류", { ns: "user" }));
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage('');
        }

        const arr = lGuestArr.map(
            (info, idx) => {
                let value = idx === parseInt(id[1]) ? {
                    ...info,
                    isEmailValidate : str !== "" ? true : false,
                    email : str
                } : info;
                
                return value;
            }
        );

        setGuestArr(arr);
    }

    const notify = () => {
        if (isValidateGuestList) {
            const arr = lGuestArr.map(info => info);
            let isEmailValidate = true;
            let isNicknameValidate = true;

            arr.forEach(info => {
                if (info.email.indexOf("@") === -1) {
                    isEmailValidate = false;
                } else {
                    if(info.email.indexOf(".") === -1) {
                        isEmailValidate = false;
                    } else {
                        if(info.email.indexOf(".") >= (info.email.length - 2)) {
                            isEmailValidate = false;
                        }
                    }
                } 

                if (info.nickname === "") {
                    isNicknameValidate = false;
                }
            });

            if (isEmailValidate && isNicknameValidate) {
                if (lGuestArr && lGuestArr.length > 0) {
                    handleSelectedFunc({
                        selectedFunc: "NOTIFY_OK", liveSeq: selectedLiveOption.liveSeq,
                        data: { startDate: format(startDate, 'yyyyMMddHHmmss'), endDate: format(endDate, 'yyyyMMddHHmmss'), list_guest:lGuestArr.map(info => info = {nickname: info.nickname, email: info.email})}
                    });
                } else {
                    handleSelectedFunc({
                        selectedFunc: "NOTIFY_OK", liveSeq: selectedLiveOption.liveSeq,
                        data: { startDate: format(startDate, 'yyyyMMddHHmmss'), endDate: format(endDate, 'yyyyMMddHHmmss') }
                    });
                }
            } else {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("입력_정보_확인", { ns: "live" }));
            }
        } else {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("입력_정보_확인", { ns: "live" }));
        }        
    }

    return (
        <div className="trans-area" onClick={handlePreventClick}>
            <div>
                <div className="title-area">
                    <span>
                        <b>{t("클래스_방송_알림", { ns: "live" })}</b>
                    </span>
                    <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc:"NONE" })}>
                        <BsX />
                    </span>
                </div>
                <div className="input-area mt-2">
                    <span className="input-label justify-content-center pr-2 area-15 hiclasstv-deepDarkgrey-text">
                        {t("대상", { ns: "pass" })}
                    </span>
                    <div className="input-data">
                        <select id='targetLive' className="custom-select font-md" style={{width:'100%'}} value={selectedLiveOption.idx} onChange={selectLive}>
                            {
                                !lLiveList || lLiveList.length <= 0 ?
                                <option value={-1}>
                                    {t("선택_가능한_클래스_없음", { ns: "live" })}
                                </option> :
                                lLiveList.map(
                                    (info, index) => (
                                        <option value={index} key={'item-' + index}>{info.liveName}</option>
                                    )
                                )
                            }
                        </select>
                    </div>
                </div>
                <div className="input-area align-items-baseline">
                    <span className="input-label justify-content-center pr-2 area-15">
                        <FaRegClock className="d-flex hiclasstv-deepDarkgrey-text" />
                    </span>
                    <div className="input-data flex-column align-items-center">
                        <div className={"d-flex w-100 align-items-center justify-content-center mb-1" + (isValidateStartDate ? "" : " invalid")}>
                            <div className="area-50">
                                <DatePicker
                                    locale={ko}
                                    ref={startDateRef}
                                    selected={startDate}
                                    minDate={new Date(Date.now())}
                                    onChange={date => onChangeStartDatePicker(date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={startDateRef} dateInfo={startDate} />}
                                />
                            </div>
                            <div className="area-50 ml-2">
                                <div className="timepicker">
                                    <div className="timepicker-input">
                                        <select id='s-ampmSelector' className="custom-select font-md fit-content mr-2" value={startDate.getHours() < 12 ? Ampm[0] : Ampm[1]} onChange={selectStartAPM}>
                                            {
                                                Ampm.map(
                                                    item => (
                                                        <option value={item} key={'ampm-' + item}>{item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                        <select id='s-hourSelector' className="custom-select font-md fit-content" value={startDate.getHours() > 12 ? startDate.getHours() - 12 : startDate.getHours()} onChange={selectStartHour}>
                                            {
                                                Hour.map(
                                                    (item, index) => (
                                                        <option value={index} key={'hour-' + item}>{item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                        <span className="mx-1">:</span>
                                        <select id='s-minuteSelector' className="custom-select font-md fit-content" value={startDate.getMinutes()} onChange={selectStartMinute}>
                                            {
                                                Minute.map(
                                                    item => (
                                                        <option value={item} key={'min-' + item}>{item < 10 ? '0' + item : item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"d-flex w-100 align-items-center justify-content-center" + (isValidateEndDate ? "" : " invalid")}>
                            <div className="area-50">
                                <DatePicker
                                    locale={ko}
                                    ref={endDateRef}
                                    selected={endDate}
                                    minDate={startDate}
                                    onChange={date => onChangeEndDatePicker(date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={endDateRef} dateInfo={endDate} />}
                                />
                            </div>
                            <div className="area-50 ml-2">
                                <div className="timepicker">
                                    <div className="timepicker-input">
                                        <select id='e-ampmSelect' className="custom-select font-md fit-content mr-2" value={endDate.getHours() < 12 ? Ampm[0] : Ampm[1]} onChange={selectEndAPM}>
                                            {
                                                Ampm.map(
                                                    item => (
                                                        <option value={item} key={'ampm-' + item}>{item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                        <select id='e-hourSelector' className="custom-select font-md fit-content" value={endDate.getHours() > 12 ? endDate.getHours() - 12 : endDate.getHours()} onChange={selectEndHour}>
                                            {
                                                Hour.map(
                                                    item => (
                                                        <option value={item} key={'hour-' + item}>{item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                        <span className="mx-1">:</span>
                                        <select id='e-minuteSelector' className="custom-select font-md fit-content" value={endDate.getMinutes()} onChange={selectEndMinute}>
                                            {
                                                Minute.map(
                                                    item => (
                                                        <option value={item} key={'min-' + item}>{item < 10 ? '0' + item : item}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-area mb-2 align-items-baseline">
                    <span className="input-label justify-content-center pr-2 area-15">
                        <FaUserAlt className="d-flex hiclasstv-deepDarkgrey-text" />
                    </span>
                    <div className="input-data flex-column align-items-center">
                        {
                            (lGuestArr && lGuestArr.length > 0) &&
                            lGuestArr.map(
                                (info, index) => (
                                    <div key={'input-' + index} className={"d-flex w-100 align-items-center justify-content-center" + (index === (lGuestArr.length - 1) ? " mb-2" : "")}>
                                        <input id={'nickname-' + index} value={info.nickname} type="text" className={(info.isNicknameValidate ? "" : " outline-red")} placeholder={t("사용자_이름_입력", { ns: "live" })} onChange={onChangeGuestNickname} />
                                        <input id={'email-' + index} value={info.email} type="text" className={(info.isEmailValidate ? "" : " outline-red")} placeholder={t("초대_할_이메일_입력", { ns: "live" })} onChange={onChangeGuestEmail} />
                                        <button type="basic" className="red px-2 py-1" onClick={() => removeGuestItem(index)}>
                                            <FaMinus className="d-flex" />
                                        </button>
                                    </div>
                                )
                            )
                        }
                        <button type="basic" className="round" onClick={() => addGuestEmail()}>
                            <FaPlus className="d-flex font-md mr-2" disabled={lGuestArr.length < 20 ? false : true} />{t("게스트_추가", { ns: "live" })}
                        </button>
                    </div>
                </div>
                <Alerts kind={lAlertKind} style={{marginTop:'0'}} message={lAlertMessage} />
                <div className="d-flex footer-area align-items-center justify-content-end">
                    <button type="basic" className="hiclasstv-blue" disabled={!(isValidateStartDate && isValidateEndDate && isValidateGuestList)} onClick={() => notify()}>
                        {t("전송", { ns: "common" })}
                    </button>
                    <button type="basic" onClick={() => handleSelectedFunc({ selectedFunc:"NONE" })}>
                        {t("취소", { ns: "common" })}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotifyLiveBroadcast;
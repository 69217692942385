import React from 'react';
import { useTranslation } from "react-i18next";
import { FaRegGrinBeamSweat } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NotFound = () => {
    const { t } = useTranslation();

    return(
        <div className="d-flex flex-column align-items-center w-100 py-5">
            <FaRegGrinBeamSweat className="font-xxxlg hiclasstv-blue-text" />
            <span className="font-xxlg oops hiclasstv-blue-text">{t("Not_Found_Page_문구_1", { ns: "hiclasstv" })}</span>
            <div className="d-flex flex-column align-items-center justify-content-center hiclasstv-blue white-text w-100 p-4">
                <span className="font-xxlg">{t("Not_Found_Page_문구_2", { ns: "hiclasstv" })}</span>
                <span className="font-xlg mb-3">{t("Not_Found_Page_문구_3", { ns: "hiclasstv" })}</span>
                <button className="btn rounded hiclasstv-btn">
                    <Link to="/main">
                        {t("HOME", { ns: "hiclasstv" })}
                    </Link>
                </button>
            </div>
        </div>
    );
}

export default NotFound;
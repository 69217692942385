import React from "react";

import RoleScript from "./item/RoleScript";

const RoleScriptCard = ({ cardInfo }) => {
    return (
        cardInfo && cardInfo.data && cardInfo.data.length > 0 ?
            <div>
                {
                    cardInfo.data.map((info, idx) => (
                        <RoleScript key={"role-" + idx} role={info.role} script={info.script} />
                    ))
                }
            </div> :
            <></>
    );
}

export default RoleScriptCard;
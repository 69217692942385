import React from 'react';
import { useTranslation } from "react-i18next";

const WebUserResetPWButton = ({ subDomainSeq, member_info, toggleModalOpened }) => {
    const { t } = useTranslation();
    
    return (
        <div className="list-row align-items-center justify-content-center area-50">
            <button type="button" className="btn btn-sm small outline bold white m-0 ml-2 p-1 outline-red red-text" title={t("비밀번호_초기화", { ns: "user" })} onClick={() => toggleModalOpened(true, subDomainSeq, member_info)}>
                {t("RESET_PW", { ns: "admin" })}
            </button>
        </div>
    );
}

export default WebUserResetPWButton;
import axios from "axios";
import ConstData from "../ConstData";

if (ConstData.IS_FOR_DEV) {
    //console.log("개발용");
    ConstData.CLOUD_HOST_ADD = "https://test.hiclass.tv";
    ConstData.PAY_HOST_ADD = ConstData.CLOUD_HOST_ADD + ConstData.PAY_HOST_ADD;
    ConstData.MOBILE_PAY_HOST_ADD = ConstData.CLOUD_HOST_ADD + ConstData.MOBILE_PAY_HOST_ADD;

    ConstData.MAIN_HOST_ADD = window.location.origin;

    // console.log("ConstData.MAIN_HOST_ADD => ", ConstData.MAIN_HOST_ADD);
    // console.log("ConstData.PAY_HOST_ADD => ", ConstData.PAY_HOST_ADD);
    // console.log("ConstData.MOBILE_PAY_HOST_ADD => ", ConstData.MOBILE_PAY_HOST_ADD);
} else {
    if (ConstData.IS_DEV_VALUE) {
        ConstData.CLOUD_HOST_ADD = "https://test.hiclass.tv";
    } else {
        ConstData.CLOUD_HOST_ADD = "https://hiclass.tv";
    }

    ConstData.PAY_HOST_ADD = ConstData.CLOUD_HOST_ADD + ConstData.PAY_HOST_ADD;
    ConstData.MOBILE_PAY_HOST_ADD = ConstData.CLOUD_HOST_ADD + ConstData.MOBILE_PAY_HOST_ADD;

    ConstData.MAIN_HOST_ADD = window.location.origin;

    // console.log("ConstData.MAIN_HOST_ADD => ", ConstData.MAIN_HOST_ADD);
    // console.log("ConstData.PAY_HOST_ADD => ", ConstData.PAY_HOST_ADD);
    // console.log("ConstData.MOBILE_PAY_HOST_ADD => ", ConstData.MOBILE_PAY_HOST_ADD);

    let origin = window.location.origin;
    let port = window.location.port;
    let protocol = window.location.protocol;
    // let pathname = window.location.pathname;
    // let host = window.location.host;
    let regEx = new RegExp(":" + port, "gi");

    console.log(`window.location - origin[${origin}]`);
    console.log(`window.location - protocol[${protocol}], port[${port}]`);

    if (protocol === "https:") {
        console.log("case - protocol https:");
        if (port === "") {
            console.log("case - port empty. default port case.");
            port = "443";
            origin += ":" + port;
            regEx = new RegExp(":" + port, "gi");
        }
    } else {    // smart tv 접속 case
        console.log("case - protocol http:");
        if (port === "") {
            console.log("case - port empty. default port case.");
            port = "80";
            origin += ":" + port;
            regEx = new RegExp(":" + port, "gi");
        }
    }

    let newRestApiHostAdd = "";

    if (port === "443") {
        console.log("case - port 443");
        newRestApiHostAdd = origin.replace(regEx, ":8001");
    } else if (port === "80") {
        console.log("case - port 80");
        newRestApiHostAdd = origin.replace(regEx, ":8000");
    } else if (port === "8080") {
        console.log("case - port 8080");
        newRestApiHostAdd = origin.replace(regEx, ":8000");
    } else {
        console.log("case - port is else ... port => ", port);
        newRestApiHostAdd = origin.replace(regEx, ":8001");
    }

    console.log(`newRestApiHostAdd[${newRestApiHostAdd}]`);
    if (newRestApiHostAdd !== "") {
        ConstData.REST_API_HOST_ADD = newRestApiHostAdd;
    }

    console.log(`new ConstData.REST_API_HOST_ADD[${ConstData.REST_API_HOST_ADD}]`);
}

const client = axios.create({ baseURL: ConstData.REST_API_HOST_ADD });

export default client;
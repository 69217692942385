import React, { useState, useEffect } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { FaSortUp, FaSortDown, FaCircle, FaBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const convertDateTime = (t, date) => {
    let dateTime = date.replace(" ", "");
    let dt = new Date();
    dt.setFullYear(dateTime.substring(0, 4));
    dt.setMonth(dateTime.substring(4, 6) - 1);
    dt.setDate(dateTime.substring(6, 8));
    dt.setHours(dateTime.substring(8, 10));
    dt.setMinutes(dateTime.substring(10, 12));
    dt.setSeconds(dateTime.substring(12, 14));

    return (`${dt.getFullYear()}. ${dt.getMonth()}. ${dt.getDate()}. ` + (dt.getHours() > 12 ? `${t("오후", { ns: "live" })} ` : `${t("오전", { ns: "live" })} `) + `${dt.getHours() - 12}:` + (dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes()));
}

const ChattingMenuLiveList = ({ list_live, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const liveArr = list_live.map(live => ({
        liveSeq: live.liveSeq,
        isOpened: false,
        isBroadcastOpened: false,
        list_broadcast: live.list_broadcast.map(broadcast => ({
            broadcastSeq: broadcast.broadcastSeq,
            isOpened: false
        }))
    }));

    const [list_live_toggle, setLiveToggleValue] = useState(liveArr);

    useEffect(() => {
        if (list_live_toggle.length > 0) {
            list_live_toggle.forEach(toggle => {
                toggle.isOpened = false;
            });
        }
    }, [list_live_toggle.forEach(toggle => toggle.isOpened)]);

    useEffect(() => {
        if (list_live_toggle.length > 0) {
            list_live_toggle.forEach(toggle => {
                toggle.isBroadcastOpened = false;
            });
        }
    }, [list_live_toggle.forEach(toggle => toggle.isBroadcastOpened)]);

    const changeLiveToggleValue = (liveSeq, value) => {
        if (list_live_toggle && list_live_toggle.length > 0) {
            const arr = list_live_toggle.map((toggle) => {
                let item = toggle.liveSeq === liveSeq ? {
                    liveSeq: toggle.liveSeq,
                    isOpened: value,
                    isBroadcastOpened: toggle.isBroadcastOpened,
                    list_broadcast: toggle.list_broadcast
                } : toggle;

                return item;
            });
            setLiveToggleValue(arr);
        }
    }

    const changeBroadcastToggleValue = (liveSeq, value) => {
        if (list_live_toggle && list_live_toggle.length > 0) {
            const arr = list_live_toggle.map((toggle) => {
                let item = toggle.liveSeq === liveSeq ? {
                    liveSeq: toggle.liveSeq,
                    isOpened: toggle.isOpened,
                    isBroadcastOpened: value,
                    list_broadcast: toggle.list_broadcast
                } : toggle;

                return item;
            });
            setLiveToggleValue(arr);
        }
    }

    const changeBroadcastGuestToggleValue = (liveSeq, broadcastSeq, value) => {
        if (list_live_toggle && list_live_toggle.length > 0) {
            let live = list_live_toggle.find(info => info.liveSeq === liveSeq);

            if (live.list_broadcast && live.list_broadcast.length > 0) {
                const arr = live.list_broadcast.map((toggle) => {
                    let item = toggle.broadcastSeq === broadcastSeq ? {
                        broadcastSeq: toggle.broadcastSeq,
                        isOpened: value
                    } : toggle;

                    return item;
                });

                live.list_broadcast = arr;
            }

            setLiveToggleValue(list_live_toggle.map((info) => {
                let item = info.liveSeq === liveSeq ? live : info;
                return item;
            }));
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-between">
                <span className="hiclasstv-darkgrey-text button" title={t("이전", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "LIST_LIVE" })}>
                    {t("이전", { ns: "common" })}
                </span>
                <span title={t("내_클래스_목록", { ns: "live" })}>
                    <b>{t("내_클래스_목록", { ns: "live" })}</b>
                </span>
                <span className="hiclasstv-darkgrey-text button" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    {t("닫기", { ns: "common" })}
                </span>
            </div>
            <div className="list-group" style={{ overflow: 'auto' }}>
                {
                    list_live && list_live.length > 0 ?
                        list_live.map((live, index) => (
                            <div key={"live-" + live.liveSeq} className="list-group-hitem list-group-hitem-action p-0" style={{ height: 'auto', minHeight: 'max-content' }}>
                                <div className="d-flex justify-content-center align-items-center p-2">
                                    <div className="text-area flex-column align-items-start">
                                        <span className="hiclass-text" title={live.liveName}>
                                            <b>{live.liveName}</b>
                                        </span>
                                        <div className="d-flex w-100">
                                            <span className="hiclass-text hiclasstv-darkgrey-text small" title={`${t("참여자", { ns: "live" })} ${live.list_member.length}${t("명", { ns: "common" })}`}>
                                                {t("참여자", { ns: "live" })}&nbsp;<b>{live.list_member.length}</b>{t("명", { ns: "common" })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex area-10 ml-2">
                                        <span className="func-btn" id={"live_member_toggler-" + live.liveSeq}>
                                            {
                                                list_live_toggle[index].isOpened ?
                                                    <FaSortUp className="font-md" title={t("숨기기", { ns: "live" })} /> :
                                                    <FaSortDown className="font-md" title={t("더_보기", { ns: "classTalk" })} />
                                            }
                                        </span>
                                    </div>
                                </div>
                                <UncontrolledCollapse toggler={"#live_member_toggler-" + live.liveSeq} defaultOpen={false} onEntering={() => changeLiveToggleValue(live.liveSeq, true)} onExiting={() => changeLiveToggleValue(live.liveSeq, false)}>
                                    {
                                        live.list_broadcast && live.list_broadcast.length > 0 &&
                                        <>
                                            <div className="title-area sub hiclasstv-yellow white-text p-2">
                                                <div className="text-area flex-column align-items-start" title={`${t("방송_예약_목록", { ns: "live" })} (${live.list_broadcast.length})`}>
                                                    <b>{t("방송_예약_목록", { ns: "live" })} ({live.list_broadcast.length})</b>
                                                </div>
                                                <div className="d-flex area-10 ml-2">
                                                    <span className="func-btn" id={"live-" + live.liveSeq + "_broadcast_toggler"}>
                                                        {
                                                            list_live_toggle[index].isBroadcastOpened ?
                                                                <FaSortUp className="font-md" title={t("방송_목록_숨기기", { ns: "live" })} /> :
                                                                <FaSortDown className="font-md" title={t("방송_목록_보기", { ns: "live" })} />
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                            <UncontrolledCollapse toggler={"#live-" + live.liveSeq + "_broadcast_toggler"} defaultOpen={false} onEntering={() => changeBroadcastToggleValue(live.liveSeq, true)} onExiting={() => changeBroadcastToggleValue(live.liveSeq, false)}>
                                                <div className="list-group">
                                                    {
                                                        live.list_broadcast.map((info, idx) => (
                                                            <div className="d-flex flex-column w-100" key={"brdcst-" + info.broadcastSeq}>
                                                                <div className="d-flex list-group-hitem list-group-hitem-action broadcast p-2 justify-content-center align-items-center">
                                                                    <div className="d-flex area-5 ml-1">
                                                                        {
                                                                            info.broadcastOpenYN === "Y" ?
                                                                                <FaBell className="font-xsm green-text" title={t("예약_방송_켜짐", { ns: "live" })} /> :
                                                                                <FaBell className="font-xsm hiclasstv-darkgrey-text" title={t("예약_방송_꺼짐", { ns: "live" })} />
                                                                        }
                                                                    </div>
                                                                    <div className="text-area flex-column align-items-start ml-2">
                                                                        <span className="hiclass-text" style={{ fontSize: '.75rem' }}>
                                                                            {`${t("시작", { ns: "common" })} ` + convertDateTime(info.broadcastDateTime.strt)}
                                                                        </span>
                                                                        <span className="hiclass-text" style={{ fontSize: '.75rem' }}>
                                                                            {`${t("종료", { ns: "common" })} ` + convertDateTime(info.broadcastDateTime.end)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="d-flex area-10 ml-2">
                                                                        <span className="func-btn" id={"live-" + live.liveSeq + "-broadcast-" + info.broadcastSeq + "-guest_toggler"}>
                                                                            {
                                                                                list_live_toggle[index].list_broadcast[idx].isOpened ?
                                                                                    <FaSortUp className="font-md" title={t("게스트_명단_숨기기", { ns: "live" })} /> :
                                                                                    <FaSortDown className="font-md" title={t("게스트_명단_보기", { ns: "live" })} />
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <UncontrolledCollapse toggler={"#live-" + live.liveSeq + "-broadcast-" + info.broadcastSeq + "-guest_toggler"} defaultOpen={false} onEntering={() => changeBroadcastGuestToggleValue(live.liveSeq, info.broadcastSeq, true)} onExiting={() => changeBroadcastGuestToggleValue(live.liveSeq, info.broadcastSeq, false)}>
                                                                    <div className="list-group">
                                                                        {
                                                                            info.list_guest && info.list_guest.length > 0 ?
                                                                                info.list_guest.map(member => (
                                                                                    <div className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 justify-content-center align-items-center" key={"brdcst-" + info.broadcastSeq + "-guest-" + member.userSeq}>
                                                                                        <div className="d-flex area-15 fit-content">
                                                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                                                        </div>
                                                                                        <div className="text-area flex-column align-items-start">
                                                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}>
                                                                                                <b>{member.userNickname}</b>
                                                                                            </span>
                                                                                        </div>
                                                                                        {
                                                                                            member.userJoinStatus === "Y" &&
                                                                                            <div className="d-flex area-10 ml-1">
                                                                                                <FaCircle className="d-flex font-md green-text" />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                )) :
                                                                                <div className="text-center p-2 white grey-text small">
                                                                                    {t("클래스_알림_메시지_3", { ns: "live" })}
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </UncontrolledCollapse>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </UncontrolledCollapse>
                                        </>
                                    }
                                    <div className="title-area sub white-text" style={{ backgroundColor: '#3A3A3A' }} title={`${t("승인대기", { ns: "live" })} (${live.list_waitMember.length})`}>
                                        <b>{t("승인대기", { ns: "live" })} ({live.list_waitMember.length})</b>
                                    </div>
                                    <div className="list-group">
                                        {
                                            live.list_waitMember && live.list_waitMember.length > 0 ?
                                                live.list_waitMember.map(member => (
                                                    <div className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 justify-content-center align-items-center" key={"lwmem-" + member.userSeq}>
                                                        <div className="d-flex area-15 fit-content">
                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                        </div>
                                                        <div className="text-area flex-column align-items-start">
                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}><b>{member.userNickname}</b></span>
                                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={member.userProfile} style={{ fontSize: '70%' }}>{member.userProfile}</span>
                                                        </div>
                                                        <div className="d-flex area-25 ml-2" onClick={handlePreventClick}>
                                                            <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" title={t("승인", { ns: "live" })} onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_ACCEPT", liveSeq: live.liveSeq, memberSeq: member.userSeq })}>
                                                                {t("승인", { ns: "live" })}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )) :
                                                <div className="text-center p-2 white grey-text small">
                                                    {t("클래스_알림_메시지_1", { ns: "live" })}
                                                </div>
                                        }
                                    </div>
                                    <div className="title-area sub white-text" title={`${t("멤버", { ns: "common" })} (${live.list_member.length})`} style={{ backgroundColor: '#2874ff', justifyContent: 'space-between' }}>
                                        <b>{t("멤버", { ns: "common" })} ({live.list_member.length})</b>
                                        <button type="button" className="btn btn-sm red white-text my-0 mx-1 py-1 px-2" title={`${t("클래스_멤버_내보내기", { ns: "live" })}`} onClick={() => handleSelectedFunc({ selectedFunc: "NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS", liveSeq: live.liveSeq })}>
                                            {t("클래스_멤버_내보내기", { ns: "live" })}
                                        </button>
                                    </div>
                                    <div className="list-group">
                                        {
                                            live.list_member && live.list_member.length > 0 ?
                                                live.list_member.map(member => (
                                                    <div key={"lmem-" + member.userSeq} className="d-flex list-group-hitem list-group-hitem-action member py-2 px-1 align-items-center justify-content-center">
                                                        <div className="d-flex area-15 fit-content">
                                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                                        </div>
                                                        <div className="text-area flex-column align-items-start">
                                                            <span className="hiclass-text" title={member.userNickname} style={{ fontSize: '90%' }}>
                                                                <b>{member.userNickname}</b>
                                                            </span>
                                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text" title={member.userProfile} style={{ fontSize: '70%' }}>
                                                                {member.userProfile}
                                                            </span>
                                                        </div>
                                                        {
                                                            member.userJoinStatus === "Y" &&
                                                            <div className="d-flex area-10 ml-1">
                                                                <FaCircle className="d-flex font-md green-text" />
                                                            </div>
                                                        }
                                                    </div>
                                                )) :
                                                <div className="text-center p-2 grey-text white small">
                                                    {t("클래스_알림_메시지_2", { ns: "live" })}
                                                </div>
                                        }
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        )) :
                        <div className="view p-2 text-center grey-text">
                            {t("클래스_알림_메시지_4", { ns: "live" })}
                        </div>
                }
            </div>
        </div>
    );
}

export default ChattingMenuLiveList;
import React from "react";
import { useTranslation } from "react-i18next";

// import greenSmart01 from "../../image/info/green/greenSmart01.jpg"
// import greenSmart02 from "../../image/info/green/greenSmart02.jpg"
// import greenSmart03 from "../../image/info/green/greenSmart03.jpg"
// import greenSmart04 from "../../image/info/green/greenSmart04.jpg"

const WebHiClassInfoGreenSmartDevice = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-blue-text">{t("인포_스마트칠판_타이틀_1", { ns: "info" })}&nbsp;</span>
                            <span className="hiclasstv-darkblue-text">{t("인포_스마트칠판_타이틀_2", { ns: "info" })}</span>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>{t("인포_스마트칠판_스마트시트_타이틀_1", { ns: "info" })}&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>{t("인포_스마트칠판_스마트시트_타이틀_2", { ns: "info" })}</span>
                            </div>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart01.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_1", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_2", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_3", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_4", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_5", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트시트_설명_6", { ns: "info" })}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>{t("인포_스마트칠판_스마트자석시트_타이틀_1", { ns: "info" })}&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>{t("인포_스마트칠판_스마트자석시트_타이틀_2", { ns: "info" })}</span>
                            </div>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart02.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">{t("인포_스마트칠판_스마트자석시트_설명_1", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트자석시트_설명_2", { ns: "info" })}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>{t("인포_스마트칠판_스마트필름_타이틀_1", { ns: "info" })}&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>{t("인포_스마트칠판_스마트필름_타이틀_2", { ns: "info" })}</span>
                            </div>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart03.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">{t("인포_스마트칠판_스마트필름_설명_1", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트필름_설명_2", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트필름_설명_3", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트필름_설명_4", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_스마트필름_설명_5", { ns: "info" })}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column my-4 w-100">
                            <div className="d-flex flex-wrap align-items-center">
                                <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold' }}>{t("인포_스마트칠판_페이퍼태블릿_타이틀_1", { ns: "info" })}&nbsp;</span>
                                <span className="d-flex grey-text" style={{ fontSize: '1.3rem', fontWeight: '400' }}>{t("인포_스마트칠판_페이퍼태블릿_타이틀_2", { ns: "info" })}</span>
                            </div>
                            <div className="d-flex flex-wrap mb-2">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/green/greenSmart04.jpg" alt="" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex flex-column" style={{ fontSize: '1rem' }}>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_1", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_2", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_3", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_4", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_5", { ns: "info" })}</span>
                                        <span className="my-1">{t("인포_스마트칠판_페이퍼태블릿_설명_6", { ns: "info" })}</span>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoGreenSmartDevice;
import React from "react";

import QRLogInContainer from "../containers/QRLogInContainer";

const WebSmartTVLoginPage = ({ ...props }) => {
    return (
        <QRLogInContainer {...props} />
    );
};

export default WebSmartTVLoginPage;
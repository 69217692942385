import React from 'react';
import { useTranslation } from "react-i18next";
import { FaCrown } from 'react-icons/fa';

const WebConfigAdminGrantButton = ({ subDomainSeq, subDomainName, member_info, toggleModalOpened }) => {
    const { t } = useTranslation();

    return (
        <div className="list-row area-50">
            <span 
                className={"button small outline bold p-1 mr-1" + (member_info.isConfigAdmin ? " outline-hiclasstv-yellow hiclasstv-yellow-text" : " outline-hiclasstv-grey hiclasstv-grey-text")} 
                style={{ borderRadius: '1rem', boxShadow: 'none' }} 
                title={member_info.isConfigAdmin ? t("관리자_지정_해제", { ns: "admin" }) : t("관리자_추가", { ns: "admin" })}
                onClick={() => toggleModalOpened(true, subDomainSeq, subDomainName, member_info)}
            >
                <FaCrown className="d-flex font-sm" />
            </span>
        </div>
    );
}

export default WebConfigAdminGrantButton;
import React from "react";
import MainTemplate from "../components/template/MainTemplate";
import MainContainer from "../containers/MainContainer";
import ChatListContainer from "../containers/ChatListContainer";
import ContentTemplate from "../components/template/ContentTemplate";

const ChatListPage = ({ cookies, browserType, selectMainTap, routeProps }) => {
    return (
        <MainTemplate>
            <MainContainer 
                browserType={browserType} 
                cookies={cookies} 
                routeProps={routeProps}
            >
                <ContentTemplate selectMainTap={selectMainTap}>
                    <ChatListContainer 
                        browserType={browserType} 
                        routeProps={routeProps}
                        isConfigChatListModalOpened={false} 
                    />
                </ContentTemplate>
            </MainContainer>
        </MainTemplate>
    );
};

export default ChatListPage;
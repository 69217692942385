import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import ConstData from '../../../lib/ConstData';

const FindPW = ({ handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [userId, setId] = useState("");

    const onChangeID = (e) => {
        let convertStr = e.target.value.replace(/[^a-z0-9_]/gi, '');
        setId(convertStr);
        //console.log(e.target.value);
    }

    return (
        <div className="d-flex flex-column align-items-center my-2">
            <div className="d-flex flex-column font-md text-center py-4 px-2 w-100">
                <span>
                    {t("비밀번호_찾기_안내_문구_1", { ns: "user" })}
                </span>
                <div className="input-area">
                    <input type="text" id="findPwId" className="d-flex flex-fill web-search-input" placeholder={t("유효성_검사_ID_입력오류", { ns: "user" })} onChange={onChangeID} value={userId} />
                </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"PRE", step:ConstData.FIND_STEP.START})}>{t("이전", { ns: "common" })}</button>
                <button className="btn btn-sm btn-primary px-4" onClick={() => handleSelectedFunc({selectedFunc:"CHECK_ID", userId})} disabled={userId === "" ? true : false}>{t("다음", { ns: "common" })}</button>
                <button className="btn btn-sm hiclasstv-grey px-3" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>{t("취소", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default FindPW;
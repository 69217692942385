import React from "react";
import { useTranslation } from "react-i18next";

import WebRequestedWatchLive from "./WebRequestedWatchLive";
import WebWatchLiveInfo from "./WebWatchLiveInfo";

const WebWatchLiveList = ({ isGuestYn, broadcastOpenYN, list_watchLive, list_requestedWatchLive, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        list_watchLive !== undefined && list_watchLive !== null &&
            list_requestedWatchLive !== undefined && list_requestedWatchLive !== null &&
            (list_watchLive.length > 0 || list_requestedWatchLive.length > 0) ?
            <table className="d-flex flex-column table table-sm table-hover flex-list wlive-list mb-0">
                <thead>
                    <tr className="d-flex">
                        <th className="area-80">
                            <div className="only-web w-100">
                                <span className="area-60" title={t("클래스_이름", { ns: "live" })}>
                                    <b>{t("클래스_이름", { ns: "live" })}</b>
                                </span>
                                <span className="area-40" title={t("진행자", { ns: "live" })}>
                                    <b>{t("진행자", { ns: "live" })}</b>
                                </span>
                            </div>
                            <div className="only-mobile w-100" title={t("클래스_정보", { ns: "live" })}>
                                <b>{t("클래스_정보", { ns: "live" })}</b>
                            </div>
                        </th>
                        <th className="area-20" title={t("상태", { ns: "common" })}>
                            <b>{t("상태", { ns: "common" })}</b>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list_watchLive.map(live => (
                            <WebWatchLiveInfo
                                key={"watch-class-" + live.liveSeq}
                                isGuestYn={isGuestYn}
                                broadcastOpenYN={broadcastOpenYN}
                                live_info={live}
                                handleSelectedFunc={handleSelectedFunc}
                            />
                        ))
                    }
                    {
                        list_requestedWatchLive.map(live => (
                            <WebRequestedWatchLive
                                key={"requested-watch-class-" + live.liveSeq}
                                live_info={live}
                                handleSelectedFunc={handleSelectedFunc}
                            />
                        ))
                    }
                </tbody>
            </table> :
            <div className="view m-3 text-center grey-text">
                {t("클래스_알림_메시지_6", { ns: "live" })}
            </div>
    );
}

export default WebWatchLiveList;
import React from 'react';
import { useTranslation } from "react-i18next";

const WebFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="footer-template-web">
            <span>
                <span className="font-weight-bold mr-2">{t("EasySystems_copyright_2", { ns: "policy" })}</span>
                <span>{t("EasySystems_copyright_3", { ns: "policy" })}</span>
                <span className="mx-2">|</span>
                <span>{t("EasySystems_copyright_4", { ns: "policy" })}</span>
                <span className="mx-2">|</span>
                <span>{t("EasySystems_copyright_5", { ns: "policy" })}</span>
                <span className="mx-2">|</span>
                <span>{t("EasySystems_copyright_6", { ns: "policy" })}</span>
                <span className="mx-2">|</span>
                <span>{t("EasySystems_copyright_7", { ns: "policy" })}</span>
                <br />
                <span className="font-weight-bold">
                    <a href="/policy">{t("이용약관_및_개인정보처리방침", { ns: "policy" })}</a>
                </span>
                <span className="mx-2">|</span>
                <span>{t("EasySystems_copyright_1", { ns: "policy" })}</span>
            </span>
        </div>
    );
}

export default WebFooter;
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import PerformChattingContainer from './chat/PerformChattingContainer';
import ClassTalkContainer from './chat/classTalk/ClassTalkContainer';

import ChattingMenu from '../components/chat/modal/ChattingMenu';
import ChattingMenuInvite from '../components/chat/modal/ChattingMenu_Invite';
import ChattingMenuLiveList from '../components/chat/modal/ChattingMenu_LiveList';
import QuizList from '../components/classtalk/quiz/QuizList';
import QuizInfo from '../components/classtalk/quiz/QuizInfo';
import QuizResponseInfo from '../components/classtalk/quiz/QuizResponseInfo';
import QuizResponseResult from '../components/classtalk/quiz/QuizResponseResult';

import RightSideModalInChatting from '../components/template/modal/RightSideModalInChatting';
import CenteredModalInChatting from '../components/template/modal/CenteredModalInChatting';
import AllCoveredModal from '../components/template/modal/AllCoveredModal';
import AlertModal from '../components/etc/AlertModal';

import { closeClasstalkMenuModal, showQuizResponseResult, fileDirRemoveToggle } from '../modules/classTalk';
import { getQuizList, getQuizInfo, sendQuiz, openCreateQuizModal, openEditQuizModal, closeQuizModal } from '../modules/quiz';
import { updateLiveMember, removeLiveMember, stopLive, notifyLogoutToLoginLiveMembers } from '../modules/live';
import { changeFalseToGotoChatting, 
         closeChatMenuModal, 
         toggle_alertModal,toggle_chatMenu, 
         toggle_chatMenu_liveList,
         inviteChatRoomMember, 
         getInviteableFriends } from '../modules/chat';


class ChattingContainer extends Component {

    componentDidMount() {
        if (this.props.isGotoChatting) {
            this.props.changeFalseToGotoChatting();
        }

        if (this.props.isChattingMenuOpened) {
            this.props.closeChatMenuModal("NONE");
        }
    }

    handleSelectedFunc = ({ selectedFunc, chatRoomSeq, list_member, liveSeq, memberSeq, quizSeq, data }) => {
        const { performChatRoomInfo, userSeq, userNickname } = this.props;
        const { chatRoomType } = performChatRoomInfo;

        switch (selectedFunc) {
            case "STOP_LIVE":
                this.props.stopLive({ userSeq });
                break;

            case "CHAT_MENU":
                this.props.toggle_chatMenu(selectedFunc);
                break;

            case "LIST_LIVE":
                this.props.toggle_chatMenu_liveList({ selectedFunc });
                break;

            case "INVITE":
                if (chatRoomType === "MULTI") {
                    this.props.getInviteableFriends({ chatRoomSeq, userSeq });
                } else {
                    this.props.toggle_alertModal({ selectedFunc: "MEMBER_INVITE_ALERT" });
                }
                break;

            case "INVITE_OK":
                if (chatRoomType === "MULTI") {
                    this.props.inviteChatRoomMember({ chatRoomSeq, userSeq, userSEQList: list_member });
                } else {
                    this.props.toggle_alertModal({ selectedFunc: "MEMBER_INVITE_ALERT" });
                }
                break;

            case "MEMBER_INVITE_ALERT":
                this.props.toggle_alertModal({ selectedFunc });
                break;

            case "MEMBER_ACCEPT":
                this.props.updateLiveMember({ liveSeq, userSeq: memberSeq, liveCertiYN: "Y", mode: "hiclass" });
                break;

            case "MEMBER_DELETE":
                this.props.removeLiveMember({ liveSeq, userSeq: memberSeq, mode: "hiclass" });
                break;

            case "NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS":
                console.log("NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS - ", liveSeq, userSeq);
                this.props.notifyLogoutToLoginLiveMembers({ liveSeq, userSeq: this.props.userSeq });
                break;

            // --------------------------- Quiz --------------------------- //

            case "SELECT_QUIZ":
                this.props.getQuizInfo({ quizSeq });
                break;

            case "SEND_QUIZ":
                let memArr = [];
                memArr = this.props.activeLive.list_member.filter(info => info.userJoinStatus === 'Y');

                this.props.sendQuiz({ quizSeq, data, userNickname, list_member: memArr });
                this.props.closeClasstalkMenuModal();
                break;

            case "GO_BACK_QUIZ_LIST":
                this.props.getQuizList({ userSeq });
                break;

            case "OPEN_CREATE_QUIZ_MODAL":
                console.log('create quiz');
                this.props.openCreateQuizModal({ quizSeq: -1, userSeq });
                break;

            case "OPEN_EDIT_QUIZ_MODAL":
                console.log('edit quiz');
                this.props.openEditQuizModal({ quizSeq, userSeq });
                break;

            case "SHOW_QUIZ_RESPONSE_RESULT":
                this.props.showQuizResponseResult({ idx: data });
                break;

            case "SAVE_QUIZ_RESPONSE_RESULT":
                break;

            ///////////////////////////////////////////////////////////////////////

            case "NONE":
                this.props.closeChatMenuModal();
                this.props.closeClasstalkMenuModal();
                this.props.closeQuizModal();
                this.props.fileDirRemoveToggle({ isModalOpened: false });
                break;

            default:
                break;
        }
    }

    render() {
        const { isGotoChatList, isGotoFriendList, isGotoLiveList } = this.props;
        const { handleSelectedFunc } = this;

        if (isGotoFriendList) {
            return <Redirect to='/list_friend_hiclass' />;
        } else if (isGotoChatList) {
            return <Redirect to='/list_chat_hiclass' />;
        } else if (isGotoLiveList) {
            return <Redirect to='/list_live_hiclass' />;
        }

        return (
            <>
                {
                    this.props.isQuizWindowOpened &&
                    <CenteredModalInChatting modalClose={handleSelectedFunc}>
                        {
                            this.props.isQuizListMode ?
                                <QuizList
                                    list_quiz={this.props.list_quiz}
                                    isEditQuizModalOpened={this.props.isEditQuizModalOpened}
                                    handleSelectedFunc={handleSelectedFunc}
                                /> :
                                <QuizInfo
                                    quiz_info={this.props.quiz_info}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                        }
                    </CenteredModalInChatting>
                } {
                    (this.props.isQuizResponseInfoOpened || this.props.isQuizResponseResultOpened) &&
                    <CenteredModalInChatting modalClose={handleSelectedFunc}>
                        {
                            this.props.isQuizResponseInfoOpened ?
                                <QuizResponseInfo
                                    responseInfo={this.props.responseInfo}
                                    handleSelectedFunc={handleSelectedFunc}
                                /> :
                                <QuizResponseResult
                                    responseInfo={this.props.responseInfo}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                        }
                    </CenteredModalInChatting>
                } {
                    this.props.isChattingMenuOpened &&
                    <RightSideModalInChatting modalClose={handleSelectedFunc}>
                        <ChattingMenu
                            userSeq={this.props.userSeq}
                            isOnAirLive={this.props.isOnAirLive}
                            performChatRoomInfo={this.props.performChatRoomInfo}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </RightSideModalInChatting>
                } {
                    this.props.isInviteMemberModalOpened &&
                    <RightSideModalInChatting modalClose={handleSelectedFunc}>
                        <ChattingMenuInvite
                            chatRoomSeq={this.props.performChatRoomInfo.chatRoomSeq}
                            list_inviteableFriends={this.props.list_inviteableFriends}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </RightSideModalInChatting>
                } {
                    this.props.isLiveListModalOpened &&
                    <RightSideModalInChatting modalClose={handleSelectedFunc}>
                        <ChattingMenuLiveList
                            list_live={this.props.list_live}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </RightSideModalInChatting>
                } {
                    this.props.isAlertModal &&
                    <AllCoveredModal modalClose={handleSelectedFunc}>
                        <AlertModal
                            handleSelectedFunc={handleSelectedFunc}
                            modalType={this.props.modalType}
                        />
                    </AllCoveredModal>
                }
                <PerformChattingContainer
                    userSeq={this.props.userSeq}
                    userNickname={this.props.userNickname}
                    isOnAirLive={this.props.isOnAirLive}
                    handleSelectedFunc={handleSelectedFunc}
                />
                <ClassTalkContainer
                    userSeq={this.props.userSeq}
                    handleSelectedFunc={this.handleSelectedFunc}
                    performChatRoomInfo={this.props.performChatRoomInfo}
                />
            </>
        );
    }
}

export default connect(
    ({ chat, user, live, quiz, classTalk }) => ({ 
        userSeq                     : user.userSeq,
        userNickname                : user.userNickname,

        list_live                   : live.list_live,
        isOnAirLive                 : live.isOnAirLive,

        activeLive                  : 
            live.list_live.find(info => info.selectStatus === 'Y') !== undefined && live.list_live.find(info => info.selectStatus === 'Y') !== null ? 
                live.list_live.find(info => info.selectStatus === 'Y') : 
                {
                    liveSeq     : -1,
                    liveName    : '',
                    list_member : []
                }, 

        list_inviteableFriends      : chat.list_inviteableFriends,

        modalType                   : chat.modalType,
        isAlertModal                : chat.isAlertModal,
        isGotoChatting              : chat.isGotoChatting,
        isGotoChatList              : chat.isGotoChatList,
        isGotoFriendList            : chat.isGotoFriendList,
        isGotoLiveList              : chat.isGotoLiveList,
        isOpened                    : chat.isOpened,

        performChatRoomInfo         : chat.performChatRoomInfo,

        isChattingMenuOpened        : chat.isChattingMenuOpened,
        isInviteMemberModalOpened   : chat.isInviteMemberModalOpened,
        isLiveListModalOpened       : chat.isLiveListModalOpened,

        isQuizWindowOpened          : classTalk.isQuizWindowOpened,
        isQuizResponseInfoOpened    : classTalk.isQuizResponseInfoOpened,
        isQuizResponseResultOpened  : classTalk.isQuizResponseResultOpened,
        responseInfo                : classTalk.responseInfo,

        isQuizListMode              : quiz.isQuizListMode,
        list_quiz                   : quiz.list_quiz,
        quiz_info                   : quiz.quiz_info,

        isQuizResponseWindowOpened  : quiz.isQuizResponseWindowOpened,
        isQuizResponseResultMode    : quiz.isQuizResponseResultMode,

        isEditQuizModalOpened       : quiz.isEditQuizModalOpened,
    }),
    {
        changeFalseToGotoChatting,
        toggle_chatMenu,
        toggle_chatMenu_liveList,
        toggle_alertModal,

        closeQuizModal,
        closeChatMenuModal,
        closeClasstalkMenuModal,

        stopLive,
        updateLiveMember,
        removeLiveMember,
        notifyLogoutToLoginLiveMembers,

        getInviteableFriends,
        inviteChatRoomMember,

        getQuizList,
        getQuizInfo,
        sendQuiz,
        openCreateQuizModal,
        openEditQuizModal,

        showQuizResponseResult,
        fileDirRemoveToggle
    }
)(ChattingContainer);
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

const LiveReJoinAlertModal = ({ isAlertModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2"></span>
                        <div className="text-area text-center">
                            <span className="hiclass-text area-70">
                                {t("로그인_정보_유지_안내", { ns: "live" })}
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button type="button" className="btn btn-primary" onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_RETRY_LOGIN_MODAL", value: true })}>{t("확인", { ns: "common" })}</button>
                        <button type="button"  className="btn btn-white btn-outline-primary" onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_RETRY_LOGIN_MODAL", value: false })}>{t("닫기", { ns: "common" })}</button>
                    </div>                    
                </div>
            </div>
        </Modal>
    );
}

export default LiveReJoinAlertModal;
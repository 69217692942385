module.exports = {
    LOGIN                       : 0,
    LOGOUT                      : 1,
    LOGIN_WEB                   : 2,
    EDIT_USER_INFO              : 3,

    LIVE_INFO_INIT              : 5,

    ADD_FRIEND                  : 10,
    APPLY_FRIEND                : 11,
    CANCEL_FRIEND_APPLY         : 12,
    CANCELED_FRIEND_APPLY       : 13,
    REJECT_FRIEND_APPLY         : 14,
    REJECTED_FRIEND_APPLY       : 15,
    REMOVE_FRIEND               : 16,

    CHATROOM_MEMBER_REMOVE      : 100,
    CHATROOM_MEMBER_REMOVED     : 101,

    CHATROOM_JOIN               : 102,
    CHATROOM_UPDATE             : 103,
    CHATROOM_REMOVE             : 104,

    /*** C# 에서 사용 시작 */
    SEND_MEDIASOUP_CODE_START   : 105,
    CHATROOM_FINISH             : 105,
    CHATROOM_EXIT               : 106,

    CHATROOM_LIVETALK           : 107,
    CHATROOM_VOICETALK          : 108,
    CHATROOM_NONE               : 109,
    SEND_MEDIASOUP_CODE_END     : 109,
    /*** C# 에서 사용 끝 */

    CHATROOM_MEMBER_LIVETALK    : 110,
    CHATROOM_MEMBER_VOICETALK   : 111,
    CHATROOM_MEMBER_NONE        : 112,

    PERFORM_CHATROOM            : 115, 

    LIVE_ON                     : 120,
    LIVE_OFF                    : 121,

    LIVE_REMOVE                 : 122,
    LIVE_APPLY                  : 123,

    LIVE_MEMBER_ADD             : 125,
    LIVE_MEMBER_REJECT          : 126,
    LIVE_MEMBER_REJECTED        : 127,
    LIVE_MEMBER_REMOVE          : 128,
    LIVE_MEMBER_REMOVED         : 129,

    /** C#에서 사용 시작  */
    SEND_HANDSUP_CODE_START     : 150,
    LIVE_JOIN                   : 150, 
    LIVE_EXIT                   : 151,

    HANDS_UP_INIT_DONE          : 152,      // 학생이 Hands up 준비가 다 되었음을 선생님께 알릴 때 사용
    HANDS_UP_INIT_DONE_MIXED    : 153,      // 학생이 Hands up 준비가 다 되었음을 선생님께 알릴 때 사용 for mixed class
    HANDS_UP_ON                 : 154,      // 학생이 Hands up 할 때 사용
    HANDS_UP_OFF                : 155,      // 학생이 Hands up을 취소하거나, 선생님이 해당 학생의 Hands up을 종료시킬 때 사용

    HANDS_UP_MODE_START         : 156,      // 선생님이 Hands up mode를 시작할 때 사용  -> IMGE_MODE_START,VIEW_MODE_CAMERA
    HANDS_UP_MODE_STOP          : 157,      // 선생님이 Hands up mode를 끌 때 사용      -> IMGE_MODE_STOP
    HANDS_UP_ACTIVE             : 158,
    HANDS_UP_INACTIVE           : 159,

    // hands up mic 상태 관련
    MIC_ON                      : 160,      // mic on
    MIC_OFF                     : 161,      // mic off
    LOCAL_MIC_READY             : 162,      // 학생 마이크 준비됨을 선생님께 알릴 때 사용 (for local server)
    DISABLE_MIC                 : 163,      // mic button disable
    ENABLE_MIC                  : 164,      // mic button enable

    // p2p consume 관련
    START_P2P_AUDIO             : 165,      // 선생님이 선택한 학생과 p2p audio를 할 때 사용
    STOP_P2P_AUDIO              : 166,      // 선생님이 선택한 학생과 p2p audio를 멈출 때 사용
    RESTART_P2P_MODE            : 167,      // restart p2p
    P2P_AUDIO_MUTE_MODE         : 168,      // 선생님이 선택한 학생의 audio를 듣고 있지 않은 상태일 때 사용
    P2P_AUDIO_LISTEN_MODE       : 169,      // 선생님이 선택한 학생의 audio를 듣고 있는 상태일 때 사용

    // NOTIFY_MIXED_CLASS_MEMBER   : 180,      // 학생들이 클라우드 서버에서 믹스드 클래스 참여 시 선생님께 알릴 때 사용 ... by hjkim 20240202
    NOTIFY_START_MIXED_CLASS    : 180,      // 선생님이 학생들에게 해당 클래스의 믹스드 클래스가 시작한 것을 알릴 때 사용
    NOTIFY_STOP_MIXED_CLASS     : 181,      // 선생님이 학생들에게 해당 클래스의 믹스드 클래스가 멈춘 것을 알릴 때 사용

    UPDATE_SCREEN_IMAGE         : 190,      // 학생들이 이미지 업로드 후 선생님께 이미지 업데이트를 알릴 때 사용

    SEND_HANDSUP_CODE_END       : 190,
    /** C#에서 사용 끝 */

    LIVE_SOUND_LEVEL_1          : 200,      // 학생들의 음성 level
    LIVE_SOUND_LEVEL_2          : 201,
    LIVE_SOUND_LEVEL_3          : 202,
    LIVE_SOUND_LEVEL_4          : 203,
    LIVE_SOUND_LEVEL_5          : 204,

    DISPLAY_ON                  : 220,      // display on
    DISPLAY_OFF                 : 221,      // display off
};
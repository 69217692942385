import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_local_server_info_by_seq = ({ localServerSeq }) => {
    console.log(`api get_local_server_info_by_seq:) localServerSeq[${localServerSeq}]`);
    return axiosClient.get(`/local/${localServerSeq}`, options);
};

export const check_local_server_auth_code = ({ authCode }) => {
    console.log(`api check_local_server_auth_code:) authCode[${authCode}]`);
    return axiosClient.get(`/local/code?kind=check-code&authCode=${authCode}`, options);
};

export const create_local_server_auth_code = () => {
    console.log(`api create_local_server_auth_code:)`);
    return axiosClient.get(`/local/code?kind=create-code`, options);
};

export const create_local_server = ({ serverName, subDomainSeq, localSiteSeq, localSiteName }) => {
    return axiosClient.post(`/local`, {
        serverName,
        subDomainSEQ: subDomainSeq,
        localSiteSEQ: localSiteSeq
    }, options);
};

export const remove_local_server = ({ subDomainSeq, serverSeq }) => {
    return axiosClient.delete(`/local`, options);
};

export const change_local_site_info = ({ localServerSeq, localSiteSeq }) => {
    //console.log('change_local_site_info : ', localServerSeq, localSiteSeq);
    return axiosClient.put(`/local/${localServerSeq}/site`, {
        localSiteSEQ: localSiteSeq
    }, options);
}

export const change_local_server_port_info = ({ localServerSeq, portNumber, historySeq, historyMemo, status }) => {
    console.log(`change_local_server_port_info:) status[${status}] localServerSeq[${localServerSeq}], historySeq[${historySeq}], portNumber[${portNumber}], historyMemo[${historyMemo}]`);

    if (status) {
        return axiosClient.put(`/local/${localServerSeq}/port`, {
            portNumber,
            historyMemo,
            status
        }, options);
    } else {
        return axiosClient.put(`/local/${localServerSeq}/port`, {
            historySEQ: historySeq,
            status
        }, options);
    }
}

export const get_local_site_list = () => {
    return axiosClient.get(`/local/site-list`, options);
}

export const create_local_site = ({ localSiteName }) => {
    return axiosClient.post(`/local-site`, {
        localSiteName
    }, options);
}

export const delete_local_site = ({ localSiteSeq }) => {
    return axiosClient.delete(`/local-site/${localSiteSeq}`, options);
}

export const class_open_on_local_site = ({ localSiteSeq, openLocalSiteSeq }) => {
    console.log(`class_open_on_local_site:) localSiteSeq[${localSiteSeq}], openLocalSiteSeq[${openLocalSiteSeq}]`);

    return axiosClient.post(`/local-site/${localSiteSeq}/${openLocalSiteSeq}?kind=open`, options);
}

export const class_close_on_local_site = ({ localSiteSeq, openLocalSiteSeq }) => {
    console.log(`class_close_on_local_site:) localSiteSeq[${localSiteSeq}], openLocalSiteSeq[${openLocalSiteSeq}]`);

    return axiosClient.delete(`/local-site/${localSiteSeq}/${openLocalSiteSeq}?kind=close`, options);
}
import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import createRequestThunk from "../lib/createRequestThunk";
import i18n from "../locales/config";

import ConstData from "../lib/ConstData";

import { get_owner_studio_info, get_admin_studio_info, create_studio, remove_studio, modify_studio_name, 
         modify_studio_group_limit, update_studio_user_info, update_studio_live_info, create_display_info, 
         remove_display_info, modify_display_info, modify_display_group, modify_display_sub_domain, 
         add_display_to_studio, remove_display_from_studio, request_studio_assign_user, cancel_reqeust_studio_assign_user,
         clear_studio_assign_user } from "../lib/api/studio";

import { callEoForSendMediaScreen } from "../lib/EoBrowserSendUtil";

import { LIVE_INIT_FOR_END_STUDIO } from "./live";

export const STUDIO_INFO_INIT               = "studio/STUDIO_INFO_INIT";
export const STUDIO_STATE_INIT              = "studio/STUDIO_STATE_INIT";
const INIT_STUDIO_LIST                      = "studio/INIT_STUDIO_LIST";

const GET_OWNER_STUDIO_INFO                 = "studio/GET_OWNER_STUDIO_INFO";
const GET_OWNER_STUDIO_INFO_SUCCESS         = "studio/GET_OWNER_STUDIO_INFO_SUCCESS";
const GET_ADMIN_STUDIO_INFO                 = "studio/GET_ADMIN_STUDIO_INFO";
const GET_ADMIN_STUDIO_INFO_SUCCESS         = "studio/GET_ADMIN_STUDIO_INFO_SUCCESS";

const CREATE_STUDIO                         = "studio/CREATE_STUDIO";
const CREATE_STUDIO_SUCCESS                 = "studio/CREATE_STUDIO_SUCCESS";
const REMOVE_STUDIO                         = "studio/REMOVE_STUDIO";
const REMOVE_STUDIO_SUCCESS                 = "studio/REMOVE_STUDIO_SUCCESS";

const MODIFY_STUDIO_NAME                    = "studio/MODIFY_STUDIO_NAME";
const MODIFY_STUDIO_NAME_SUCCESS            = "studio/MODIFY_STUDIO_NAME_SUCCESS";
const MODIFY_STUDIO_GROUP_LIMIT             = "studio/MODIFY_STUDIO_GROUP_LIMIT";
const MODIFY_STUDIO_GROUP_LIMIT_SUCCESS     = "studio/MODIFY_STUDIO_GROUP_LIMIT_SUCCESS";

const UPDATE_STUDIO_USER_INFO               = "studio/UPDATE_STUDIO_USER_INFO";
const UPDATE_STUDIO_USER_INFO_SUCCESS       = "studio/UPDATE_STUDIO_USER_INFO_SUCCESS";
const UPDATE_STUDIO_LIVE_INFO               = "studio/UPDATE_STUDIO_LIVE_INFO";
const UPDATE_STUDIO_LIVE_INFO_SUCCESS       = "studio/UPDATE_STUDIO_LIVE_INFO_SUCCESS";

const CREATE_DISPLAY_INFO                   = "studio/CREATE_DISPLAY_INFO";
const CREATE_DISPLAY_INFO_SUCCESS           = "studio/CREATE_DISPLAY_INFO_SUCCESS";
const REMOVE_DISPLAY_INFO                   = "studio/REMOVE_DISPLAY_INFO";
const REMOVE_DISPLAY_INFO_SUCCESS           = "studio/REMOVE_DISPLAY_INFO_SUCCESS";

const MODIFY_DISPLAY_INFO                   = "studio/MODIFY_DISPLAY_INFO";
const MODIFY_DISPLAY_INFO_SUCCESS           = "studio/MODIFY_DISPLAY_INFO_SUCCESS";
const MODIFY_DISPLAY_GROUP                  = "studio/MODIFY_DISPLAY_GROUP";
const MODIFY_DISPLAY_GROUP_SUCCESS          = "studio/MODIFY_DISPLAY_GROUP_SUCCESS";
const MODIFY_DISPLAY_SUB_DOMAIN             = "studio/MODIFY_DISPLAY_SUB_DOMAIN";
const MODIFY_DISPLAY_SUB_DOMAIN_SUCCESS     = "studio/MODIFY_DISPLAY_SUB_DOMAIN_SUCCESS";

const ADD_DISPLAY_TO_STUDIO                 = "studio/ADD_DISPLAY_TO_STUDIO";
const ADD_DISPLAY_TO_STUDIO_SUCCESS         = "studio/ADD_DISPLAY_TO_STUDIO_SUCCESS";
const REMOVE_DISPLAY_FROM_STUDIO            = "studio/REMOVE_DISPLAY_FROM_STUDIO";
const REMOVE_DISPLAY_FROM_STUDIO_SUCCESS    = "studio/REMOVE_DISPLAY_FROM_STUDIO_SUCCESS";

const REQUEST_ASSIGN_USER                   = "studio/REQUEST_ASSIGN_USER";
const REQUEST_ASSIGN_USER_SUCCESS           = "studio/REQUEST_ASSIGN_USER_SUCCESS";
const CANCEL_REQUEST_ASSIGN_USER            = "studio/CANCEL_REQUEST_ASSIGN_USER";
const CANCEL_REQUEST_ASSIGN_USER_SUCCESS    = "studio/CANCEL_REQUEST_ASSIGN_USER_SUCCESS";
const CLEAR_ASSIGN_USER                     = "studio/CLEAR_ASSIGN_USER";
const CLEAR_ASSIGN_USER_SUCCESS             = "studio/CLEAR_ASSIGN_USER_SUCCESS";

export const initStudioList                 = createAction(INIT_STUDIO_LIST);
export const getOwnerStudioList             = createRequestThunk(GET_OWNER_STUDIO_INFO, get_owner_studio_info);
export const getAdminStudioList             = createRequestThunk(GET_ADMIN_STUDIO_INFO, get_admin_studio_info);

export const callCreateStudio               = createRequestThunk(CREATE_STUDIO, create_studio);
export const callRemoveStudio               = createRequestThunk(REMOVE_STUDIO, remove_studio);
export const callModifyStudioName           = createRequestThunk(MODIFY_STUDIO_NAME, modify_studio_name);
export const callModifyStudioGroupLimit     = createRequestThunk(MODIFY_STUDIO_GROUP_LIMIT, modify_studio_group_limit);
export const callUpdateStudioUser           = createRequestThunk(UPDATE_STUDIO_USER_INFO, update_studio_user_info);
export const callUpdateStudioLive           = createRequestThunk(UPDATE_STUDIO_LIVE_INFO, update_studio_live_info);

export const callCreateDisplayInfo          = createRequestThunk(CREATE_DISPLAY_INFO, create_display_info);
export const callRemoveDisplayInfo          = createRequestThunk(REMOVE_DISPLAY_INFO, remove_display_info);
export const callModifyDisplayInfo          = createRequestThunk(MODIFY_DISPLAY_INFO, modify_display_info);
export const callModifyDisplayGroup         = createRequestThunk(MODIFY_DISPLAY_GROUP, modify_display_group);
export const callModifyDisplaySubDomain     = createRequestThunk(MODIFY_DISPLAY_SUB_DOMAIN, modify_display_sub_domain);
export const callAddDisplayToStudio         = createRequestThunk(ADD_DISPLAY_TO_STUDIO, add_display_to_studio);
export const callRemoveDisplayFromStudio    = createRequestThunk(REMOVE_DISPLAY_FROM_STUDIO, remove_display_from_studio);
export const callRequestAssignUser          = createRequestThunk(REQUEST_ASSIGN_USER, request_studio_assign_user);
export const callCancelRequestAssignUser    = createRequestThunk(CANCEL_REQUEST_ASSIGN_USER, cancel_reqeust_studio_assign_user);
export const callClearAssignUser            = createRequestThunk(CLEAR_ASSIGN_USER, clear_studio_assign_user, [LIVE_INIT_FOR_END_STUDIO]);

/******** rbmq func ********/
const MQ_UPDATE_STUDIO_USER                 = "studio/MQ_UPDATE_STUDIO_USER";
const MQ_UPDATE_STUDIO_LIVE                 = "studio/MQ_UPDATE_STUDIO_LIVE";
const MQ_UPDATE_DISPLAY_STATUS              = "display/MQ_UPDATE_DISPLAY_STATUS";
const MQ_UPDATE_DISPLAY_INFO                = "display/MQ_UPDATE_DISPLAY_INFO";

export const mqUpdateStudioUser             = createAction(MQ_UPDATE_STUDIO_USER);
export const mqUpdateStudioLive             = createAction(MQ_UPDATE_STUDIO_LIVE);
export const mqUpdateDisplayStatus          = createAction(MQ_UPDATE_DISPLAY_STATUS);
export const mqUpdateDisplayInfo            = createAction(MQ_UPDATE_DISPLAY_INFO);
/********    end    ********/

const initialState = {
    list_studio             : [],
    list_display            : [],
    connectedStudioInfo     : null,

    alertKind               : ConstData.ALERT_KIND.NONE,
    alertMessage            : ""
};

const studio = handleActions({
    [INIT_STUDIO_LIST]: (state, action) => {
        return {
            ...state,
            list_studio : [],
            list_display : []
        }
    },

    [STUDIO_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("LIVE_STATE_INIT");

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                return {
                    ...state,
                    list_display    : [],
                    list_studio     : [],
                    connectedStudioInfo : null
                }
            } else {
                console.log("isLogoutSuccess is ", isLogoutSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [STUDIO_INFO_INIT]: (state, action) => {
        //console.log("STUDIO_INFO_INIT", action.payload);
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLoginSuccess } = action.payload;
            
            if (isLoginSuccess) {
                const { list_studio, list_display } = action.payload;
                
                let clist_studio = [];
                let clist_display = [];

                if (list_studio !== undefined && list_studio !== null) {
                    clist_studio = list_studio.map(row => ({
                        studioSeq       : row.STU_SEQ,
                        studioName      : row.STU_NM,
                        studioOwner     : row.STU_OWNER,
                        studioStatus    : row.STU_STAT,
                        groupLimit      : row.GROUP_LIMIT_NUM === null ? -1 : Number.parseInt(row.GROUP_LIMIT_NUM),
                        assignedUserSeq : row.USER_SEQ === null ? -1 : row.USER_SEQ,
                        assignedLiveSeq : row.LIVE_SEQ === null ? -1 : row.LIVE_SEQ,
                        createDT        : row.STU_CREATE_DT,
                        subDomainSeq    : row.SUB_DOMAIN_SEQ,
                        list_display    : row.list_display === undefined || row.list_display === null ? 
                                            [] : 
                                            row.list_display.map(dRow => ({
                                                displaySeq    : dRow.DISP_SEQ,
                                                displayStatus : dRow.DISP_STAT,
                                                groupNum      : dRow.GROUP_NUM === null ? -1 : Number.parseInt(dRow.GROUP_NUM),
                                                pchsDT        : dRow.DISP_PCHS_DT
                                            }))
                    }));
                }

                if (list_display !== undefined && list_display !== null) {
                    clist_display = list_display.map(row => ({
                        displaySeq      : row.displaySeq,
                        displayStatus   : row.displayStatus,
                        groupNum        : row.groupNum,
                        pchsDT          : row.pchsDT,
                        studioSeq       : row.studioSeq,
                        subDomainSeq    : row.subDomainSeq
                    }));
                }

                return {
                    ...state,
                    list_studio         : clist_studio,
                    list_display        : clist_display
                }
            } else {
                return {
                    ...state,
                }
            }
        } else {
            console.log("STUDIO_INFO_INIT- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [GET_OWNER_STUDIO_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_OWNER_STUDIO_INFO_SUCCESS");

        if (isSuccessed) {
            const { list_studio, list_display } = action.payload;
            let clist_studio = [];
            let clist_display = [];

            if (list_studio !== undefined && list_studio !== null) {
                clist_studio = list_studio.map(row => ({
                    studioSeq           : row.STU_SEQ,
                    studioName          : row.STU_NM,
                    studioOwner         : row.STU_OWNER,
                    studioStatus        : row.STU_STAT,
                    groupLimit          : row.GROUP_LIMIT_NUM === null ? -1 : Number.parseInt(row.GROUP_LIMIT_NUM),
                    assignedUserSeq     : row.USER_SEQ,
                    assignedLiveSeq     : row.LIVE_SEQ,
                    createDT            : row.STU_CREATE_DT,
                    subDomainSeq        : row.SUB_DOMAIN_SEQ,
                    list_display        : row.list_display === undefined || row.list_display === null ?
                                        [] :
                                        row.list_display.map(dRow => ({
                                            displaySeq: dRow.DISP_SEQ,
                                            displayStatus: dRow.DISP_STAT,
                                            groupNum: dRow.GROUP_NUM === null ? -1 : Number.parseInt(dRow.GROUP_NUM),
                                            pchsDT: dRow.DISP_PCHS_DT
                                        }))
                }));
            }

            if (list_display !== undefined && list_display !== null) {
                clist_display = list_display.map(row => ({
                    displaySeq          : row.displaySeq,
                    displayOwner        : row.displayOwner,
                    displayStatus       : row.displayStatus,
                    groupNum            : row.groupNum,
                    pchsDT              : row.pchsDT,
                    studioSeq           : row.studioSeq,
                    subDomainSeq        : row.subDomainSeq
                }));
            }

            return {
                ...state,
                list_studio         : clist_studio,
                list_display        : clist_display
            }
        } else {
            return {
                ...state,
            }
        }
    },

    [GET_ADMIN_STUDIO_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_ADMIN_STUDIO_INFO_SUCCESS");

        if (isSuccessed) {
            const { list_studio, list_display } = action.payload;
            let clist_studio = [];
            let clist_display = [];

            if (list_studio !== undefined && list_studio !== null) {
                clist_studio = list_studio.map(row => ({
                    studioSeq           : row.STU_SEQ,
                    studioName          : row.STU_NM,
                    studioOwner         : row.STU_OWNER,
                    studioStatus        : row.STU_STAT,
                    groupLimit          : row.GROUP_LIMIT_NUM === null ? -1 : Number.parseInt(row.GROUP_LIMIT_NUM),
                    assignedUserSeq     : row.USER_SEQ,
                    assignedLiveSeq     : row.LIVE_SEQ,
                    createDT            : row.STU_CREATE_DT,
                    subDomainSeq        : row.SUB_DOMAIN_SEQ,
                    list_display        : row.list_display === undefined || row.list_display === null ?
                                        [] :
                                        row.list_display.map(dRow => ({
                                            displaySeq: dRow.DISP_SEQ,
                                            displayStatus: dRow.DISP_STAT,
                                            groupNum: dRow.GROUP_NUM === null ? -1 : Number.parseInt(dRow.GROUP_NUM),
                                            pchsDT: dRow.DISP_PCHS_DT
                                        }))
                }));
            }

            if (list_display !== undefined && list_display !== null) {
                clist_display = list_display.map(row => ({
                    displaySeq          : row.displaySeq,
                    displayOwner        : row.displayOwner,
                    displayStatus       : row.displayStatus,
                    groupNum            : row.groupNum,
                    pchsDT              : row.pchsDT,
                    studioSeq           : row.studioSeq,
                    subDomainSeq        : row.subDomainSeq
                }));
            }

            return {
                ...state,
                list_studio         : clist_studio,
                list_display        : clist_display
            }
        } else {
            return {
                ...state,
            }
        }
    },

    [CREATE_STUDIO_SUCCESS]: (state, action) => {
        const { userSeq, studioName, subDomainSeq, groupLimit } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CREATE_STUDIO_SUCCESS");

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { studioSEQ } = action.payload;
                const studio_info = {
                    studioSeq       : studioSEQ,
                    studioName      : studioName,
                    studioOwner     : userSeq !== undefined && userSeq !== null ? userSeq : null,
                    groupLimit      : groupLimit,
                    assignedUserSeq : -1,
                    assignedLiveSeq : -1,
                    list_display    : [],
                    createDT        : new Date().toDateString(),
                    subDomainSeq    : subDomainSeq,
                };

                return {
                    ...state,
                    list_studio     : state.list_studio.concat(studio_info)
                }
            } else {
                console.log("CREATE_STUDIO_SUCCESS- isAddSuccess is ", isAddSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("CREATE_STUDIO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [REMOVE_STUDIO_SUCCESS]: (state, action) => {
        const { studioSeq, studioOwner } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isCanUse } = action.payload;

            if (isCanUse) {
                const { isRemoveSuccess } = action.payload;

                if (isRemoveSuccess) {
                    return produce (state, draft => {
                        const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                        if (studio_info !== undefined && studio_info !== null) {
                            if (studio_info.list_display !== undefined && studio_info.list_display && studio_info.list_display.length > 0) {
                                draft.list_display.push(studio_info.list_display);
                            }
                            draft.list_studio = draft.list_studio.filter(info => info.studioSeq !== studioSeq);
                        }
                    });
                } else {
                    console.log("REMOVE_STUDIO - isRemoveSuccess is ", isRemoveSuccess);
                    return {
                        ...state,
                    }
                }
            } else {
                console.log("REMOVE_STUDIO - isCanUse is ", isCanUse);
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("스튜디오_삭제_불가능_알림", { ns: "studio" })
                }
            }
        } else {
            const { errMsg } = action.payload;
            console.log("REMOVE_STUDIO - isSuccessed is ", isSuccessed);
            console.log(errMsg);
            return {
                ...state,
            }
        }
    },

    [MODIFY_STUDIO_NAME_SUCCESS]: (state, action) => {
        const { studioSeq, studioName } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isModifySuccess } = action.payload;

            if (isModifySuccess) {
                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        studio_info.studioName = studioName;
                    }
                });
            } else {
                console.log("MODIFY_STUDIO_NAME_SUCCESS- isModifySuccess is ", isModifySuccess);
                return {
                    ...state,
                } 
            }
        } else {
            console.log("MODIFY_STUDIO_NAME_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [MODIFY_STUDIO_GROUP_LIMIT_SUCCESS]: (state, action) => {
        const { studioSeq, groupLimit } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("MODIFY_STUDIO_GROUP_LIMIT_SUCCESS");

        if (isSuccessed) {
            const { isModifySuccess } = action.payload;

            if (isModifySuccess) {
                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        studio_info.groupLimit = groupLimit;
                    }
                });
            } else {
                console.log("MODIFY_STUDIO_GROUP_LIMIT_SUCCESS- isModifySuccess is ", isModifySuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("MODIFY_STUDIO_GROUP_LIMIT_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [UPDATE_STUDIO_USER_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("UPDATE_STUDIO_USER_INFO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [UPDATE_STUDIO_LIVE_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("UPDATE_STUDIO_LIVE_INFO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [CREATE_DISPLAY_INFO_SUCCESS]: (state, action) => {
        const { subDomainSeq, studioSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CREATE_DISPLAY_INFO_SUCCESS");

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { displaySEQ } = action.payload;

                const display_info = {
                    displaySeq    : displaySEQ,
                    displayStatus : "N",
                    groupNum      : -1,
                    pchsDT        : new Date().toDateString()
                };

                if (studioSeq === undefined || studioSeq === null) {
                    console.log("studioSeq x", display_info);

                    return {
                        ...state,
                        list_display : state.list_display.concat(display_info)
                    }
                } else {
                    return produce (state, draft => {
                        const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                        if (studio_info !== undefined && studio_info !== null) {
                            if (studio_info.list_display !== undefined && studio_info.list_display !== null) {
                                studio_info.list_display.push(display_info);
                            } else {
                                studio_info.list_display = [display_info];
                            }
                        }
                    });
                }
            } else {
                console.log("CREATE_DISPLAY_INFO_SUCCESS- isAddSuccess is", isAddSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("CREATE_DISPLAY_INFO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [REMOVE_DISPLAY_INFO_SUCCESS]: (state, action) => {
        const { displaySeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return {
                    ...state,
                    list_display : state.list_display.filter(info => info.displaySeq !== displaySeq)
                }
            } else {
                console.log("REMOVE_DISPLAY_INFO_SUCCESS- isRemoveSuccess is ", isRemoveSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("REMOVE_DISPLAY_INFO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [MODIFY_DISPLAY_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("MODIFY_DISPLAY_INFO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [MODIFY_DISPLAY_GROUP_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("MODIFY_DISPLAY_GROUP_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [MODIFY_DISPLAY_SUB_DOMAIN_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("MODIFY_DISPLAY_SUB_DOMAIN_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [ADD_DISPLAY_TO_STUDIO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            return {
                ...state,
            }
        } else {
            console.log("ADD_DISPLAY_TO_STUDIO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [REMOVE_DISPLAY_FROM_STUDIO_SUCCESS]: (state, action) => {
        const { studioSeq, displaySeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_DISPLAY_FROM_STUDIO_SUCCESS");

        if (isSuccessed) {
            const { isModifySuccess } = action.payload;

            if (isModifySuccess) {
                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        if (studio_info.list_display !== undefined && studio_info.list_display !== null) {
                            const display_info = studio_info.list_display.find(info => info.displaySeq === displaySeq);
                            if (display_info !== undefined && display_info !== null) {
                                draft.list_display.push(display_info);
                            }
                            studio_info.list_display = studio_info.list_display.filter(info => info.displaySeq !== displaySeq);
                        }
                    }
                });
            } else {
                console.log("REMOVE_DISPLAY_FROM_STUDIO_SUCCESS- isModifySuccess is ", isModifySuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("REMOVE_DISPLAY_FROM_STUDIO_SUCCESS- isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [REQUEST_ASSIGN_USER_SUCCESS]: (state, action) => {
        const { studioSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("REQUEST_ASSIGN_USER_SUCCESS - action.payload => ", action.payload);

        if (isSuccessed) {
            const { isRequestSuccess } = action.payload;

            if (isRequestSuccess) {
                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        studio_info.studioStatus = "R";
                        studio_info.assignedUserSeq = userSeq;
                    }
                });
            } else {
                console.log("isRequestSuccess is ", isRequestSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("REQUEST_ASSIGN_USER_SUCCESS isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [CANCEL_REQUEST_ASSIGN_USER_SUCCESS]: (state, action) => {
        const { studioSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        if (studio_info.assignedUserSeq === userSeq) {
                            studio_info.studioStatus = "N";
                            studio_info.assignedUserSeq = -1;
                        }
                    }
                });
            } else {
                console.log("isUpdateSuccess is ", isUpdateSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("CANCEL_REQUEST_ASSIGN_USER_SUCCESS isSuccessed is ", isSuccessed);
            return {
                ...state,
            }
        }
    },

    [CLEAR_ASSIGN_USER_SUCCESS]: (state, action) => {
        const { studioSeq, userSeq, list_live } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CLEAR_ASSIGN_USER_SUCCESS - ", studioSeq, userSeq);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                if (state.connectedStudioInfo !== null) {
                    if (state.connectedStudioInfo.studioSeq === studioSeq) {
                        console.log("CLEAR_ASSIGN_USER - ", list_live);
                        callEoForSendMediaScreen("studioAction", { kind: "disconnectStudio", studioSeq: studioSeq, list_live });
                    }
                }

                return produce (state, draft => {
                    const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
                    if (studio_info !== undefined && studio_info !== null) {
                        console.log("CLEAR_ASSIGN_USER - studio_info.assignedUserSeq => ", studio_info.assignedUserSeq, userSeq);
                        if (studio_info.assignedUserSeq === userSeq) {
                            studio_info.studioStatus = "N";
                            studio_info.assignedUserSeq = -1;

                            if (studio_info.assignedLiveSeq !== -1) {
                                studio_info.assignedLiveSeq = -1;
                            }
                        }
                    }
                    if (draft.connectedStudioInfo !== null) {
                        draft.connectedStudioInfo = null;
                    }
                });
            } else {
                console.log("isUpdateSuccess is ", isUpdateSuccess);
                return {
                    ...state,
                }
            }
        } else {
            const { errMsg } = action.payload;

            console.log("CLEAR_ASSIGN_USER_SUCCESS isSuccessed is ", isSuccessed, errMsg);
            return {
                ...state,
            }
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_UPDATE_STUDIO_USER]: (state, action) => {
        const { studioSeq, userSeq, list_display } = action.payload;
        console.log("MQ_UPDATE_STUDIO_USER - ", action.payload);
        console.log(list_display);

        const studio_info = state.list_studio.find(info => info.studioSeq === studioSeq);
        
        if (studio_info !== undefined && studio_info !== null) {
            if (userSeq !== -1) {
                callEoForSendMediaScreen("studioAction", { kind: "start", studioSeq });
            }
        }

        return produce (state, draft => {
            const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
            
            if (studio_info !== undefined && studio_info !== null) {
                studio_info.assignedUserSeq = userSeq;
                
                if (list_display !== undefined && list_display !== null) {
                    studio_info.list_display = list_display;
                }

                if (userSeq !== -1) {
                    draft.connectedStudioInfo = studio_info;
                    //callEoForSendMediaScreen("studioAction", { kind: "start", studioSeq });
                } else {
                    draft.connectedStudioInfo = null;
                }
            }
        });
    },

    [MQ_UPDATE_STUDIO_LIVE]: (state, action) => {
        const { studioSeq, userSeq, liveSeq } = action.payload;
        console.log("MQ_UPDATE_STUDIO_LIVE");

        return produce (state, draft => {
            const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);
            
            if (studio_info !== undefined && studio_info !== null) {
                if (liveSeq > -1) {
                    if (studio_info.assignedUserSeq === -1) {
                        studio_info.assignedUserSeq = userSeq;
                    }
                }

                studio_info.assignedLiveSeq = liveSeq;
            }
        });
    },

    [MQ_UPDATE_DISPLAY_STATUS]: (state, action) => {
        const { studioSeq, displaySeq, status } = action.payload;
        console.log("MQ_UPDATE_DISPLAY_STATUS");

        return produce (state, draft => {
            if (studioSeq > -1 && studioSeq < 65535) {
                if (draft.connectedStudioInfo !== null) {
                    if (draft.connectedStudioInfo.list_display !== undefined && draft.connectedStudioInfo.list_display !== null) {
                        const display_info = draft.connectedStudioInfo.list_display.find(info => info.displaySeq === displaySeq);
                        
                        if (display_info !== undefined && display_info !== null) {
                            display_info.displayStatus = status;
                        }
                    }
                }

                const studio_info = draft.list_studio.find(info => info.studioSeq === studioSeq);

                if (studio_info !== undefined && studio_info !== null) {
                    if (studio_info.list_display !== undefined && studio_info.list_display !== null) {
                        const display_info = studio_info.list_display.find(info => info.displaySeq === displaySeq);
                        
                        if (display_info !== undefined && display_info !== null) {
                            display_info.displayStatus = status;
                        }
                    }
                }
            } else {
                const display_info = draft.list_display.find(info => info.displaySeq === displaySeq);

                if (display_info !== undefined && display_info !== null) {
                    display_info.displayStatus = status;
                }
            }
        });
    },

    [MQ_UPDATE_DISPLAY_INFO]: (state, action) => {
        const { displaySeq, prevInfo, assignedInfo } = action.payload;
        console.log("MQ_UPDATE_DISPLAY_INFO");

        return produce (state, draft => {
            if (prevInfo.studioSeq === -1) {
                const display_info = draft.list_display.find(info => info.displaySeq === displaySeq);

                if (display_info !== undefined && display_info !== null) {
                    display_info.groupNum = assignedInfo.groupNum;

                    if (assignedInfo.studioSeq >= 0) {
                        const studio_info = draft.list_studio.find(info => info.studioSeq === assignedInfo.studioSeq);
                        
                        if (studio_info !== undefined && studio_info !== null) {
                            if (studio_info.list_display !== undefined && studio_info.list_display !== null) {
                                studio_info.list_display.push(display_info);
                            }
                        }

                        draft.list_display = draft.list_display.filter(info => info.displaySeq !== displaySeq);
                    }
                }
            } else {

            }
        });
    },
}, initialState);

export default studio;
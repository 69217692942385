import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const QnA004 = ({ isOpened, onChangeOpenedFlag }) => {
    const { t } = useTranslation();

    useEffect(() => {
        let content = document.getElementById("qna04");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(3)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>4. {t("FAQ_질문_4", { ns: "info" })}</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna04">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    {t("FAQ_공통_답변_감사", { ns: "info" })}<br />
                    {t("FAQ_질문_4_답변_1", { ns: "info" })}<br />
                    <b>{t("FAQ_공통_답변_전화", { ns: "info" })}</b><br />
                    <b>{t("FAQ_공통_답변_메일", { ns: "info" })}</b>
                </p>
            </div>
        </div>
    );
}

export default QnA004;
import React from "react";

import ClassTalkMassages from "../classtalk/ClassTalkMessages";
import EmoticItem from "../classtalk/emoticon/EmoticItem";
import EmoticList from "../classtalk/emoticon/EmoticList";
import AudioRecord from "../classtalk/voiceMemo/AudioRecord";
import InputGroupBox from "../classtalk/InputGroupBox";
import FunctionList from "../classtalk/FunctionList";

const ClassTalk = ({ openInfo, classTalkMsg, handleSelectedClassTalkFunc }) => {
    return (
        <div className="d-flex flex-column white" style={{ minHeight: '50%', height: '100%' }}>
            <ClassTalkMassages
                classTalkMsg={classTalkMsg}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.selectedEmoticonSeq > -1 &&
                <EmoticItem
                    selIndex={openInfo.selectedEmoticonSeq}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
            {
                openInfo.isEmoticonSelected &&
                <EmoticList handleSelectedClassTalkFunc={handleSelectedClassTalkFunc} />
            }
            {
                openInfo.isAudioOpened ?
                    <AudioRecord
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    /> :
                    <InputGroupBox
                        selIndex={openInfo.selectedEmoticonSeq}
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    />
            }
            {
                openInfo.isAddFunctionOpened &&
                <FunctionList
                    isWhisper={true}
                    isChatting={true}
                    isActiveLive={false}
                    isPanelWindowOpened={false}
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
        </div>
    );
}

export default ClassTalk;
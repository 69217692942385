import axiosClient from "./axiosClient";
import ConstData from "../ConstData";
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const upload_file = ({ mode, chatRoomSeq, teacherSeq, liveSeq, userSeq, data, filename, command, userNickname, memberSeqList, isWhisper, smallGroupKey, isMixedClassMember, mixedClassStatus, localServerSeq, localLiveSeq, localTeacherSeq }) => {
    console.log('upload file - ', smallGroupKey);
    if (mode === 'web') {
        //console.log('data : ', data);
        const form = new FormData();
        form.append('file', data, filename);
        form.append('teacherSeq', teacherSeq);
        form.append('liveSeq', liveSeq);
        form.append('userSeq', userSeq);
        form.append('mode', 'web');
        form.append('command', command);
        form.append('userNickname', userNickname);
        form.append('whisperYN', isWhisper ? 'Y' : 'N');
        // form.append('mixedClassMemberYN', isMixedClassMember ? 'Y' : 'N');
        form.append('isMixedClassMember', isMixedClassMember);
        form.append('mixedClassStatus', mixedClassStatus);

        if (isMixedClassMember) {
            form.append('localServerSeq', localServerSeq);
            form.append('localLiveSeq', localLiveSeq);
            form.append('localTeacherSeq', localTeacherSeq);
        }

        if (smallGroupKey !== undefined && smallGroupKey !== "") {
            form.append('smallGroupKey', smallGroupKey);
        }

        console.log("form - ", mode, chatRoomSeq, teacherSeq, liveSeq, userSeq, data, filename, command, userNickname, memberSeqList, isWhisper, smallGroupKey, isMixedClassMember, mixedClassStatus, localServerSeq, localLiveSeq, localTeacherSeq);

        return axiosClient.post(`/upload`, form, options);
    } else if (mode === 'hiclass') {
        const form = new FormData();
        form.append('file', data, filename);
        form.append('chatRoomSeq', chatRoomSeq);
        form.append('teacherSeq', teacherSeq);
        form.append('userSeq', 0);
        form.append('mode', 'hiclass');
        form.append('command', command);
        form.append('userNickname', userNickname);
        form.append('memberSeqList', memberSeqList.map(info => info.userSeq));
        form.append('mixedClassStatus', mixedClassStatus);

        return axiosClient.post(`/upload`, form, options);
    }
}

export const download_file = ({ url }) => {
    return axiosClient.get(`/download/${url}`, options);
}

export const get_download_file_list = ({ teacherSeq, liveSeq }) => {
    console.log(`api get_download_file_list:) teacherSeq[${teacherSeq}], liveSeq[${liveSeq}]`);
    return axiosClient.get(`/download-list/${teacherSeq}/${liveSeq}`, options);
}

export const clear_download_files = ({ teacherSeq, liveSeq }) => {
    console.log(`api clear_download_files:) teacherSeq[${teacherSeq}], liveSeq[${liveSeq}]`);
    return axiosClient.delete(`/download-clear/${teacherSeq}/${liveSeq}`, options);
}
import React from "react";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { ctWebActionType } from "../../lib/ConstCommand";

const WebWhisper = ({ web_isWhisper, classTalkVisibility, handleSelectedClassTalkFunc }) => {
    const { t } = useTranslation();

    const onClick = () => {
        handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.Whisper });
    }

    return (
        <>
            {
                web_isWhisper && classTalkVisibility &&
                <div className="d-flex justify-content-right overflow-auto" style={{ border: '.25rem solid rgb(246, 248, 250)' }}>
                    <div className={"web-whisper-modal overflow-auto" + ((classTalkVisibility === "visible") && web_isWhisper ? " visible" : "")} /* style={{ bottom: `${bottom}rem` }} */ >
                        {t("귓속말_메시지_2", { ns: "classTalk" })}
                    </div>
                    <button type="button" className="hiclasstv-classtalk-button hiclasstv-blue-text" onClick={onClick}>
                        <FaTimes className="font-sm plus" aria-hidden="true" />
                    </button>
                </div>
            }
        </>
    );
}

export default WebWhisper;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";

import { enWebPage } from "../../lib/ConstCommand";
import ConstData from "../../lib/ConstData";

import Alerts from "./list/Alerts";

const handleGroupMember = (SMALL_GROUP_NUMBER, list_member) => {
    let groupSeqArr = [];
    let groupEl = [];

    for (let i = 0; i < list_member.length; i++) {
        if (groupEl.length >= SMALL_GROUP_NUMBER) {
            groupSeqArr.push(groupEl);
            groupEl = [];
        }
        groupEl.push(list_member[i]);
    }

    if (groupEl.length > 0) {
        groupSeqArr.push(groupEl);
    }

    // console.log("groupSeqArr => ", groupSeqArr);

    let list_group = groupSeqArr.map((row, idx) => ({
        groupSeq: idx + 1,
        groupName: "Group " + Number(idx + 1),
        list_member: row,
        userID: "",
        userSeq: -1,
        userNickname: "",
    }));

    // console.log("list_group => ", list_group);

    // setGroupList(list_group);
    return list_group;
}

const GroupQRLogInWindow = ({ live_info, list_member, alertKind, loginMessage, isInvalidRoute, currentPageType, handleSelectedFunc }) => {
    const { t } = useTranslation();

    // const SMALL_GROUP_NUMBER = live_info.groupLimit ? live_info.groupLimit : 4;

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [list_group, setGroupList] = useState([]);

    // const [selectedUserIdx, setSelectedUserIdx] = useState(-1);
    // const [selectedUserInfo, setSelectedUserInfo] = useState({ userID: "", userSeq: -1, userNickname: "" });
    // const [prevSelectedUserInfo, setPrevSelectedUserInfo] = useState({ userID: "", userSeq: -1, userNickname: "" });
    const [selectedGroupIdx, setSelectedGroupIdx] = useState(-1);
    const [selectedGroupInfo, setSelectedGroupInfo] = useState({ userID: "", userSeq: -1, userNickname: "", groupSeq: -1, list_member: [], groupName: "" });
    const [prevSelectedGroupInfo, setPrevSelectedGroupInfo] = useState({ userID: "", userSeq: -1, userNickname: "", groupSeq: -1, list_member: [], groupName: "" });
    const [liveStudentNickname, setLiveStudentNickname] = useState("");

    const [errorMsg, setErrorMsg] = useState(loginMessage);
    const [errorKind, setErrorKind] = useState(alertKind);

    useEffect(() => {
        setGroupList([
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 1, list_member: [], groupName: "Group 1" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 2, list_member: [], groupName: "Group 2" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 3, list_member: [], groupName: "Group 3" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 4, list_member: [], groupName: "Group 4" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 5, list_member: [], groupName: "Group 5" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 6, list_member: [], groupName: "Group 6" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 7, list_member: [], groupName: "Group 7" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 8, list_member: [], groupName: "Group 8" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 9, list_member: [], groupName: "Group 9" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 10, list_member: [], groupName: "Group 10" }
        ]);
    }, []);

    useEffect(() => {
        /* setGroupList([
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 1, list_member: [], groupName: "Group 1" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 2, list_member: [], groupName: "Group 2" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 3, list_member: [], groupName: "Group 3" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 4, list_member: [], groupName: "Group 4" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 5, list_member: [], groupName: "Group 5" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 6, list_member: [], groupName: "Group 6" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 7, list_member: [], groupName: "Group 7" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 8, list_member: [], groupName: "Group 8" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 9, list_member: [], groupName: "Group 9" },
            { userID: "", userSeq: -1, userNickname: "", groupSeq: 10, list_member: [], groupName: "Group 10" }
        ]); */

        // handleGroupMember(list_member);
        const SMALL_GROUP_NUMBER = live_info.groupLimit ? live_info.groupLimit : 4;
        let list_group = handleGroupMember(SMALL_GROUP_NUMBER, list_member);
        setGroupList(list_group);
    }, [live_info.groupLimit, list_member]);

    /* useEffect(() => {
        if (currentPageType === enWebPage.SmartTVQRLogin || currentPageType === enWebPage.SmartTV) {
            if (live_info && list_member && list_member.length > 0) {
                const teacher_info = list_member.find((info, idx) => idx === 0);
                if (teacher_info) {
                    setSelectedUserInfo({
                        userID: teacher_info.userID,
                        userSeq: teacher_info.userSeq,
                        userNickname: teacher_info.userNickname
                    });
                }
                setSelectedUserIdx(0);
            }
        }
    }, [currentPageType, live_info, list_member]); */

    useEffect(() => {
        setErrorKind(alertKind);
        setErrorMsg(loginMessage);
    }, [alertKind, loginMessage]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            onHandleLogin();
        }
    }

    const onClickSelectedUser = (e) => {
        // console.log("onClickOptionSelectedUser - ", e.target.value, typeof e.target.value);
        if (list_member && list_member.length > 0) {
            let selectedUserSeq = e.target.value;
            /* let selectedIndex = e.target.selectedIndex;
            if (currentPageType === enWebPage.LiveQRLogin) {
                const member_info = list_member.find((info, idx) => idx === selectedIndex);
                if (member_info) {
                    setSelectedUserInfo({
                        userID: member_info.userID,
                        userSeq: member_info.userSeq,
                        userNickname: member_info.userNickname
                    });
                    setIsModalOpened(true);
                }
            }
            setSelectedUserIdx(selectedIndex); */
        }
    }

    const onClickCaptureSelectedGroup = (e) => {
        // console.log("onClickCaptureSelectedUser - ", e.target.value, e.target.selectedIndex - 1);
        if (errorMsg !== "") {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (!ConstData.IS_LOCAL_VERSION || currentPageType !== enWebPage.LiveQRLogin) return;

        if (list_member && list_member.length > 0) {
            if (!isModalOpened && selectedGroupIdx > -1 && selectedGroupInfo.userSeq > -1) {
                if (prevSelectedGroupInfo.userSeq === -1) {
                    setPrevSelectedGroupInfo({
                        userID: selectedGroupInfo.userID,
                        userSeq: selectedGroupInfo.userSeq,
                        userNickname: selectedGroupInfo.userNickname,
                        groupSeq: selectedGroupInfo.groupSeq,
                        list_member: selectedGroupInfo.list_member,
                        groupName: selectedGroupInfo.groupName
                    });
                } else {
                    if (selectedGroupInfo.userSeq === prevSelectedGroupInfo.userSeq) {
                        setPrevSelectedGroupInfo({
                            userID: "",
                            userSeq: -1,
                            userNickname: "",
                            groupSeq: -1,
                            list_member: [],
                            groupName: ""
                        });
                        setIsModalOpened(true);
                    }
                }
            }
        }
    }

    const onChangeSelectedGroup = (e) => {
        // console.log("onChangeSelectedUser - ", e.target.selectedIndex - 1);
        if (errorMsg !== "") {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (list_member && list_member.length > 0) {
            let selectedIndex = e.target.selectedIndex - 1;
            const member_info = list_group.find((info, idx) => idx === selectedIndex);
            if (member_info) {
                setSelectedGroupInfo({
                    userID: member_info.userID,
                    userSeq: member_info.userSeq,
                    userNickname: member_info.userNickname,
                    groupSeq: member_info.groupSeq,
                    list_member: member_info.list_member,
                    groupName: member_info.groupName
                });

                if (ConstData.IS_LOCAL_VERSION && currentPageType === enWebPage.LiveQRLogin) {
                    if (prevSelectedGroupInfo.userSeq > -1 && prevSelectedGroupInfo.userSeq !== member_info.userSeq) {
                        setPrevSelectedGroupInfo({
                            userID: "",
                            userSeq: -1,
                            userNickname: "",
                            groupSeq: -1,
                            list_member: [],
                            groupName: ""
                        });
                    }
                    setIsModalOpened(true);
                }
            }
            setSelectedGroupIdx(selectedIndex);
        }
    }

    const onHandleLogin = () => {
        // console.log("onHandleLogin - selectedGroupIdx => ", selectedGroupIdx);
        // console.log("onHandleLogin - selectedGroupInfo => ", selectedGroupInfo);
        if (selectedGroupIdx === -1) {
            setErrorKind(ConstData.ALERT_KIND.DANGER);
            setErrorMsg(t("그룹_로그인_문구_1", { ns: "user" }));
            return;
        } else {
            setErrorKind(ConstData.ALERT_KIND.NONE);
            setErrorMsg("");
        }

        if (list_group.length > 0) {
            if (liveStudentNickname === "") {
                handleSelectedFunc({
                    selectedFunc: "LOGIN_BY_QR",
                    loginInfo: {
                        userID: list_group[selectedGroupIdx].userID,
                        userSeq: list_group[selectedGroupIdx].userSeq,
                        userNickname: list_group[selectedGroupIdx].userNickname,
                        groupSeq: list_group[selectedGroupIdx].groupSeq,
                        list_member: list_group[selectedGroupIdx].list_member,
                        groupName: list_group[selectedGroupIdx].groupName
                    }
                });
            } else {
                if (liveStudentNickname.length > 15) {
                    setErrorKind(ConstData.ALERT_KIND.DANGER);
                    setErrorMsg(t("유효성_검사_닉네임_글자수오류", { ns: "user" }));
                    return;
                } else {
                    handleSelectedFunc({
                        selectedFunc: "LOGIN_BY_QR",
                        loginInfo: {
                            userID: list_group[selectedGroupIdx].userID,
                            userSeq: list_group[selectedGroupIdx].userSeq,
                            userNickname: liveStudentNickname,
                            groupSeq: list_group[selectedGroupIdx].groupSeq,
                            list_member: list_group[selectedGroupIdx].list_member,
                            groupName: list_group[selectedGroupIdx].groupName
                        }
                    });
                }
            }
        }
    }

    const onClickRefresh = () => {
        window.location.reload();
    }

    /* const handleGroupMember = (list_member) => {
        let groupSeqArr = [];
        let groupEl = [];

        for (let i = 0; i < list_member.length; i++) {
            if (groupEl.length >= SMALL_GROUP_NUMBER) {
                groupSeqArr.push(groupEl);
                groupEl = [];
            }
            groupEl.push(list_member[i]);
        }

        if (groupEl.length > 0) {
            groupSeqArr.push(groupEl);
        }

        // console.log("groupSeqArr => ", groupSeqArr);

        let list_group = groupSeqArr.map((row, idx) => ({
            groupSeq: idx + 1,
            groupName: "Group " + Number(idx + 1),
            list_member: row,
            userID: "",
            userSeq: -1,
            userNickname: "",
        }));

        // console.log("list_group => ", list_group);

        // setGroupList(list_group);
        return list_group;
    } */

    return (
        <>
            <div className="login-window d-flex flex-column align-items-center w-100 p-5">
                <div className="d-flex justify-content-between align-items-center p-3">
                    <button type="button" className="hiclasstv-button hiclasstv-blue-text p-1" style={{ visibility: 'hidden' }} disabled>
                        <div className="d-flex flex-column">
                            <MdRefresh className="font-xxlg" style={{ visibility: 'hidden' }} aria-hidden="true" />
                            <span className="font-xxsm">
                                {t("새로고침", { ns: "common" })}
                            </span>
                        </div>
                    </button>
                    <span className="font-xxlg hiclasstv-blue-text p-1" style={{ fontWeight: 400 }} title="LogIn:">
                        {t("LogIn", { ns: "user" })} :
                    </span>
                    <button type="button" className="hiclasstv-button hiclasstv-blue-text p-1" title={t("새로고침", { ns: "common" })} onClick={onClickRefresh}>
                        <div className="d-flex flex-column">
                            <MdRefresh className="font-xxlg" aria-hidden="true" />
                            <span className="font-xxsm">
                                {t("새로고침", { ns: "common" })}
                            </span>
                        </div>
                    </button>
                </div>
                <div className="d-flex flex-column align-items-center p-2">
                    {
                        isInvalidRoute ?
                            <div className="d-flex flex-column align-items-center w-100 p-2">
                                <div className="card web-list-card border-none grey lighten-3">
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">{t("클래스", { ns: "live" })}</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={t("클래스", { ns: "live" }) + " " + t("정보없음", { ns: "common" })}>
                                                    <b>{t("클래스", { ns: "live" })} {t("정보없음", { ns: "common" })}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-30">
                                            <span className="mr-1">{t("진행자", { ns: "live" })}</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-70">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={t("진행자", { ns: "live" }) + " " + t("정보없음", { ns: "common" })}>
                                                    <b>{t("진행자", { ns: "live" })} {t("정보없음", { ns: "common" })}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="font-md plus p-2">{t("클래스_비활성화_안내_문구_1", { ns: "live" })}</span>
                                <span className="font-md plus pb-2 px-2">{t("클래스_비활성화_안내_문구_2", { ns: "live" })}</span>
                            </div> :
                            <div className="d-flex flex-column justify-content-center align-items-center w-100 p-1">
                                <div className="card web-list-card border-none grey lighten-3">
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-35">
                                            <span className="mr-1">{t("클래스", { ns: "live" })}</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-65">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={live_info.liveName}>
                                                    <b>{live_info.liveName}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-area transparent">
                                        <div className="input-label medium area-35">
                                            <span className="mr-1">{t("진행자", { ns: "live" })}</span>
                                            <span>|</span>
                                        </div>
                                        <div className="input-data medium area-65">
                                            <div className="text-area">
                                                <span className="hiclass-text" title={live_info.teacher_info ? live_info.teacher_info.userNickname : ""}>
                                                    <b>{live_info.teacher_info ? live_info.teacher_info.userNickname : ""}</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <span className="font-md plus p-2">로그인할 사용자를 선택해 주세요.</span> */}
                            </div>
                    }
                    <select size="10" className="custom-select arrow-1 my-2" value={selectedGroupInfo.groupSeq} onChange={onChangeSelectedGroup} onClickCapture={onClickCaptureSelectedGroup}>
                        {
                            /* list_member && list_member.length > 0 ?
                                <>
                                    <option value="-1" disabled>로그인할 그룹을 선택해 주세요.</option>
                                    {
                                        list_member.map((info, index) => (
                                            <option value={info.userSeq} key={'qrlogin-' + info.userSeq + "-" + index}>
                                                {info.userNickname}
                                            </option>
                                        ))
                                    }
                                </> :
                                <option value="-1">접속 가능한 사용자가 없습니다.</option> */
                            list_group && list_group.length > 0 ?
                                <>
                                    <option value="-1" disabled>{t("그룹_로그인_문구_1", { ns: "user" })}</option>
                                    {
                                        list_group.map((info, index) => (
                                            <option value={info.groupSeq} key={'qrlogin-' + info.groupSeq + "-" + index}>
                                                {info.groupName}
                                            </option>
                                        ))
                                    }
                                </> :
                                <option value="-1">{t("그룹_목록_없음", { ns: "user" })}</option>
                        }
                    </select>
                    {
                        errorMsg !== "" &&
                        <div className="d-flex justify-content-center align-items-center w-100 mb-3">
                            <Alerts kind={errorKind} message={errorMsg} />
                        </div>
                    }
                    <button className="btn hiclasstv-blue white-text w-100 pt-3" disabled={list_group && list_group.length > 0 ? false : true} onClick={() => onHandleLogin()}>
                        {t("Login", { ns: "user" })}
                    </button>
                </div>
            </div>
            {
                /* isModalOpened &&
                <QRLoginModal
                    isModalOpened={isModalOpened}
                    selectedUser_info={selectedGroupInfo}
                    toggleModalOpened={setIsModalOpened}
                    handleSelectedFunc={handleSelectedFunc}
                /> */
            }
        </>
    );
}

export default GroupQRLogInWindow;
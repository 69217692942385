import React, { useEffect, useState, useMemo } from "react";
import { FaBell, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

/* const getTimeInfo = (time) => {
    if (time !== null) {
        let hour = parseInt(time.substring(8, 10));
        hour = hour > 11 ? "오후 " + (hour - 12).toString().padStart(2, "0") : "오전 " + hour.toString().padStart(2, "0");
        let minutes = time.substring(10, 12);

        return hour + ":" + minutes;
    } else {
        return "";
    }
} */

const NotifyBroadcastTime = ({ notifyInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [startTime, setStartTime] = useState(null);
    const [liveName, setLiveName] = useState("");

    const broadcastTime = useMemo(() => {
        let time = startTime;
        if (time !== null && time !== undefined) {
            let hour = parseInt(time.substring(8, 10));
            let minutes = time.substring(10, 12);
            hour = hour > 11 ? `${t("오후", { ns: "live" })} ` + (hour - 12).toString().padStart(2, "0") : `${t("오전", { ns: "live" })} ` + hour.toString().padStart(2, "0");

            return hour + ":" + minutes;
        } else {
            return "";
        }
    }, [startTime, t]);

    useEffect(() => {
        if (notifyInfo && notifyInfo.broadcastDateTime) {
            let strtDateTime = notifyInfo.broadcastDateTime.strt.replace(" ", "");
            setStartTime(strtDateTime);
            setLiveName(notifyInfo.liveName);
        }
    }, [notifyInfo]);

    return (
        <div className="title-area notify background-gradation">
            <FaBell className="font-sm mr-2" aria-hidden="true" />
            <span className="mr-2" style={{ fontSize: '.7rem' }} title={t("예약_방송_오픈_알림", { ns: "live" })}>
                [{t("예약_방송_오픈_알림", { ns: "live" })}]
            </span>
            <div className="title">
                <span className="hiclass-text font-weight-bold" title={liveName}>
                    {liveName}
                </span>
            </div>
            <span className="ml-2">
                <b>{broadcastTime}</b>
            </span>
            <span className="func-btn" style={{ position: 'absolute', right: '0' }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "TOGGLE_BROADCAST_NOTIFY" })}>
                <FaTimes className="font-sm" />
            </span>
        </div>
    );
}

export default NotifyBroadcastTime;
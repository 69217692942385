import React from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const AlertErrorModal = ({ alertMessage, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "ALERT_ERROR_TOGGLE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" title={t("확인", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "ALERT_ERROR_TOGGLE" })}>
                    {t("확인", { ns: "common" })}
                </button>
            </div>
        </div>
    );
}

export default AlertErrorModal;
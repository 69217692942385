import React from "react";

import WebWhisperArea from "./WebWhisperArea";
import EmoticItem from "../classtalk/emoticon/EmoticItem";
import EmoticList from "../classtalk/emoticon/EmoticList";
import AudioRecord from "../classtalk/voiceMemo/AudioRecord";
import InputGroupBox from "../classtalk/InputGroupBox";
import FunctionList from "../classtalk/FunctionList";

const WebWhisperModal = ({ openInfo, isLiveChattingLocked, msgInfo, handleSelectedClassTalkFunc }) => {
    return (
        <div className="d-flex flex-column">
            <WebWhisperArea
                msgInfo={msgInfo}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.selectedEmoticonSeq > -1 &&
                <EmoticItem
                    selIndex={openInfo.selectedEmoticonSeq}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
            {
                openInfo.isEmoticonSelected &&
                <EmoticList
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
            {
                openInfo.isAudioOpened ?
                    <AudioRecord
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    /> :
                    <InputGroupBox
                        selIndex={openInfo.selectedEmoticonSeq}
                        isLiveChattingLocked={isLiveChattingLocked}
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    />
            }
            {
                openInfo.isAddFunctionOpened &&
                <FunctionList
                    isWhisper={true}
                    isChatting={true}
                    isSpeakingMode={false}
                    isActiveLive={false}
                    isPanelWindowOpened={false}
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
        </div>
    );
}

export default WebWhisperModal;
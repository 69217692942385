import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

import ConstData from '../../../lib/ConstData';
import { enResponseStatus } from '../../../lib/ConstCommand';

import Alerts from '../../etc/list/Alerts';

const isMobile = (navigator.userAgent.toLowerCase().indexOf('android') > -1 || navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2));
//const mid = "INIpayTest";
const mid = "easysystem";

const WebPayModal = ({ isModalOpened, orderInfo, togglePayModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lOrderInfo, setOrderInfo] = useState({}); 
    const [goPayMethod, setGooPayMethod] = useState(isMobile ? 'CARD' : 'Card');
    const [acceptMethod, setAcceptMethod] = useState(isMobile ? 'below1000=Y' : 'below1000');
    const [terms, setTerms] = useState(false);
    const [lAlertMessage, setAlertMessage] = useState('');
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        window.addEventListener('message', onRcvMessage);

        return () => {
            window.removeEventListener('message', onRcvMessage);
        }
    }, []);

    useEffect(() => {
        if (orderInfo) {
            setOrderInfo(orderInfo);
        }
    }, [orderInfo]);

    const onRcvMessage = (e) => {
        //console.log('onRcvMessage', e.data);
        const { code, msg } = e.data;

        switch (code) {
            case enResponseStatus.Success : {
                const jsonObject = JSON.parse(msg);
                const { isSuccessed, isAddSuccess, pchs_info } = jsonObject;
                togglePayModalOpened(false);
                handleSelectedFunc({ selectedFunc : 'PG_PAYMENT_SUCCESS', data : { isSuccessed, isAddSuccess, pchs_info } });
            } break;

            case enResponseStatus.AuthFailed :
                console.log('결제를 진행할 수 없습니다. 관리자에게 문의 바랍니다.');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("이용권_결제_문구_1", { ns: "pass" }));
                break;

            case enResponseStatus.PaymentFailed :
                console.log('결제에 실패했습니다.');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("이용권_결제_문구_2", { ns: "pass" }));
                break;

            case enResponseStatus.UserCanceled :
                console.log('사용자가 결제를 취소하였습니다.');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("이용권_결제_문구_3", { ns: "pass" }));
                //togglePayModalOpened(false);
                break;

            case enResponseStatus.FailedWithError :
                console.log('오류로 인해 결제에 실패했습니다.');
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("이용권_결제_문구_4", { ns: "pass" }));
                break;

            case enResponseStatus.MobilePaymentFailed :
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(msg);
                break;

            default :
                break;
        }
    }

    const onChangeMainPayType = (e) => {
        let key_code = e.target.getAttribute('data-tab');
        let before_current = document.getElementsByClassName('payContent current');

        for (let i = 0; i < before_current.length; i++) {
            let li_arr = before_current.item(i).getElementsByTagName('li');

            for (let j = 0; j < li_arr.length; j++) {
                li_arr.item(j).classList.remove('current');
            }

            before_current.item(i).classList.remove('current');
        }

        let now_current = document.getElementById(key_code);
        now_current.classList.add('current');
        let li_arr = now_current.getElementsByTagName('li');
        for (let i = 0; i < li_arr.length; i++) {
            let li = li_arr.item(i);
            if (li.children.item(0).checked) {
                li.classList.add('current');
                setGooPayMethod(li.children.item(0).getAttribute('data-gmth'));
                setAcceptMethod(li.children.item(0).getAttribute('data-amth'));
            }
        }
    }

    const onChangeContentPayType = (e) => {
        let key_code = e.target.getAttribute('data-tab');
        console.log(key_code);
        setGooPayMethod(e.target.getAttribute('data-gmth'));
        setAcceptMethod(e.target.getAttribute('data-amth'));
    }

    const handlePaymentSubmit = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage('');
        }
        
        if (isMobile) {
            //e.target = '_blank';
        } else {
            window.open('', 'INIpayStd_Return', 'width=860, height=620, left=0, top=0, resizable=yes, location=no, menubar=no, status=no, titlebar=no, scrollbars=no');
            e.target = 'INIpayStd_Return';
        }
    }

    const onCloseModal = () => {
        setOrderInfo({});
        setGooPayMethod(isMobile ? 'CARD' : 'Card');
        setAcceptMethod(isMobile ? 'below1000=Y' : 'below1000');
        setTerms(false);
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage('');
    }

    return(
        <Modal isOpen={isModalOpened} centered /* backdrop={false}  */style={{ minWidth:'fit-content' }} onClosed={onCloseModal} toggle={() => togglePayModalOpened(false)}>
            <div className="card pay-container">
                <div className="title-area">
                    <img src="hiclasstvIcon.png" alt="hiclasstv_logo" />
                    <div className="d-flex flex-column">
                        <span className="hiclass-text font-lg plus">{t("이용권_결제_문구_5", { ns: "pass" })}HiClassTV 상품 결제</span>
                        <span className="hiclass-text font-sm">{t("이용권_결제_문구_6", { ns: "pass" })}</span>
                    </div>
                </div>
                <div className="main-table-area">
                    <table className="table table-sm">
                        <caption>{t("이용권_결제_문구_7", { ns: "pass" })}</caption>
                        <tbody>
                            <tr>
                                <th scope="row"><span>{t("이용권_결제_문구_8", { ns: "pass" })}</span></th>
                                <td colSpan="2"><span><strong>{lOrderInfo.goodname}</strong></span></td>
                            </tr>
                            <tr>
                                <th scope="row"><span>{t("이용권_결제_문구_9", { ns: "pass" })}</span></th>
                                <td colSpan="2"><span><strong>{lOrderInfo.price}</strong>{t("원", { ns: "pass" })}</span></td>
                            </tr>
                            <tr>
                                <th scope="row"><span>{t("이용권_결제_문구_10", { ns: "pass" })}</span></th>
                                <td colSpan="2">
                                    <div id="divMainPay">
                                        <div id="divMainPayType">
                                            <ul className="mainPayType" onChange={onChangeMainPayType}>
                                                <li data-tab="tab-easy">
                                                    <input type="radio" name="pay-type" id="EP1001" data-tab="tab-easy" data-keycode="EP1001" /* disabled */ />
                                                    <label htmlFor="EP1001">{t("이용권_결제_문구_11", { ns: "pass" })}</label>
                                                </li>
                                                <li data-tab="tab-credit">
                                                    <input type="radio" name="pay-type" id="EP2001" data-tab="tab-credit" data-keycode="EP2001" defaultChecked />
                                                    <label htmlFor="EP2001">{t("이용권_결제_문구_12", { ns: "pass" })}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="tab-easy" className="payContent">
                                            <ul onChange={onChangeContentPayType}>
                                                <li id="tab-kakao" className="current" data-paytype="tab-easy" data-tab="tab-kakao">
                                                    <input type="radio" name="tab-easy" id="EP1011" data-tab="tab-kakao" data-gmth={isMobile ? 'CARD' : 'onlykakaopay'} data-amth={isMobile ? 'd_kakaopay=Y' : 'cardonly'} defaultChecked />
                                                    <label htmlFor="EP1011">{t("이용권_결제_문구_13", { ns: "pass" })}</label>
                                                </li>
                                                <li id="tab-naver" data-paytype="tab-easy" data-tab="tab-naver">
                                                    <input type="radio" name="tab-easy" id="EP1021" data-tab="tab-naver" data-gmth={isMobile ? 'CARD' : 'onlynaverpay'} data-amth={isMobile ? 'd_npay=Y' : 'cardonly'} />
                                                    <label htmlFor="EP1021">{t("이용권_결제_문구_14", { ns: "pass" })}</label>
                                                </li>
                                                <li id="tab-ssp" data-paytype="tab-easy" data-tab="tab-ssp">
                                                    <input type="radio" name="tab-easy" id="EP1031" data-tab="tab-ssp" data-gmth={isMobile ? 'CARD' : 'onlyssp'} data-amth={isMobile ? 'd_samsungpay=Y' : 'cardonly'} />
                                                    <label htmlFor="EP1031">{t("이용권_결제_문구_15", { ns: "pass" })}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="tab-credit" className="payContent current">
                                            <ul onChange={onChangeContentPayType}>
                                                <li id="tab-local" className="current" data-paytype="tab-credit" data-tab="tab-local">
                                                    <input type="radio" name="tab-credit" id="EP2011" data-tab="tab-local" data-gmth={isMobile ? 'CARD' : 'Card'} data-amth={isMobile ? 'below1000=Y' : 'below1000'} defaultChecked />
                                                    <label htmlFor="EP2011">{t("이용권_결제_문구_16", { ns: "pass" })}</label>
                                                </li>
                                                <li id="tab-intl" data-paytype="tab-credit" data-tab="tab-intl">
                                                    <input type="radio" name="tab-credit" id="EP2021" data-tab="tab-intl" data-gmth={isMobile ? 'CARD' : 'Card'} data-amth="no_use" disabled />
                                                    <label htmlFor="EP2021">{t("이용권_결제_문구_17", { ns: "pass" })}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="terms-area" id="divCheckTerms">
                    <input type="checkbox" id="checkTerms" onChange={(e) => setTerms(e.target.checked)} />
                    <label className="font-sm" htmlFor="checkTerms">{t("이용권_결제_문구_18", { ns: "pass" })}</label>
                    <a href="#!" id="viewTerms">
                        <span className="font-xsm">{t("이용권_결제_문구_19", { ns: "pass" })}</span>
                    </a>
                </div>
                <div className="area-80">
                    <Alerts kind={lAlertKind} message={lAlertMessage} />
                </div>
                <div className="btn-area">
                    {
                        isMobile ? 
                        <form id="payForm1" name="payForm1" method="POST" acceptCharset="EUC-KR" target="_blank" action="https://mobile.inicis.com/smart/payment/" /* action="https://mobile.inicis.com/smart/payment/" */ onSubmit={handlePaymentSubmit}>
                            <input type="hidden" name="P_MID" value={mid} />
                            <input type="hidden" name="P_OID" value={mid + '_' + Date.now()} />
                            <input type="hidden" name="P_UNAME" value={lOrderInfo.buyername || ''} />
                            <input type="hidden" name="P_MOBILE" value={lOrderInfo.buyertel || ''} />
                            <input type="hidden" name="P_EMAIL" value={lOrderInfo.buyeremail || ''} />
                            <input type="hidden" name="P_AMT" value={lOrderInfo.price || ''} />
                            <input type="hidden" name="P_GOODS" value={lOrderInfo.goodname || ''} />
                            <input type="hidden" name="P_NOTI" value={lOrderInfo.uSeq + ',' + lOrderInfo.dSeq + ',' + lOrderInfo.pSeq || ''} />
                            <input type="hidden" name="P_INI_PAYMENT" value={goPayMethod} />
                            <input type="hidden" name="P_RESERVED" value={acceptMethod} />
                            <input type="hidden" name="P_NEXT_URL" value={ConstData.MOBILE_PAY_HOST_ADD} />
                            <button type="submit" className="btn btn-success" id="mainBillMethod" disabled={terms ? false : true} /* onClick={handlePaymentSubmit} */ >{t("이용권_결제_문구_20", { ns: "pass" })}</button>
                        </form> : 
                        <form id="payForm1" name="payForm1" method="POST" acceptCharset="UTF-8" target="INIpayStd_Return" action={ConstData.PAY_HOST_ADD} onSubmit={handlePaymentSubmit}>
                            <input type="hidden" name="buyername" value={lOrderInfo.buyername || ''} />
                            <input type="hidden" name="buyertel" value={lOrderInfo.buyertel || ''} />
                            <input type="hidden" name="buyeremail" value={lOrderInfo.buyeremail || ''} />
                            <input type="hidden" name="price" value={lOrderInfo.price || ''} />
                            <input type="hidden" name="goodname" value={lOrderInfo.goodname || ''} />
                            <input type="hidden" name="merchantData" value={lOrderInfo.merchantData || ''} />
                            <input type="hidden" name="gopaymethod" value={goPayMethod} />
                            <input type="hidden" name="acceptmethod" value={acceptMethod} />
                            <button type="submit" className="btn btn-success" id="mainBillMethod" disabled={terms ? false : true} /* onClick={handlePaymentSubmit} */ >{t("이용권_결제_문구_20", { ns: "pass" })}</button>
                        </form>
                    }
                    <button type="button" className="btn btn-light" id="cancelBtn" onClick={() => togglePayModalOpened(false)}>{t("취소", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
};

export default WebPayModal;
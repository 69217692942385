import React, { useMemo } from 'react';
import { useTranslation } from "react-i18next";

import { getEntryMemberCount } from '../../../lib/func/ExportFunction';

const WebUserStatus = ({ kind, list_member, memberCnt }) => {
    const { t } = useTranslation();
    
    const entryMemberCount = useMemo(() => getEntryMemberCount(kind, list_member), [kind, list_member]);

    return (
        list_member !== undefined && list_member !== null ? 
            <>
                {
                    entryMemberCount > 0 ? 
                    <span className="green-text font-weight-bold">{entryMemberCount}</span> :
                    <span>{entryMemberCount}</span>
                }
                <span>&nbsp;{' / ' + list_member.length + ' ' + t("명", { ns: "common" })}</span>
            </> :
        memberCnt !== undefined && memberCnt !== null ?
            <span>{memberCnt + ' ' + t("명", { ns: "common" })}</span> :
            <></>
    );
}

export default WebUserStatus;
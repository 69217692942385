import React from "react";
import { useTranslation } from "react-i18next";

import AppServicePolicy from "./AppServicePolicy";

const WebAppPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="web-main list-area align-items-center justify-content-center p-2">
            <h3>
                <b>{t("Pentalk_onScreen_개인정보처리방침", { ns: "policy" })}</b>
            </h3>
            <div className="web-policy-box no-modal p-2 my-2 scroll-show">
                <AppServicePolicy />
            </div>
            <div className="footer-template-web" style={{ border: 'none' }}>
                <span>{t("EasySystems_copyright_1", { ns: "policy" })}</span>
            </div>
        </div>
    );
};

export default WebAppPolicy;
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import ConstData from "../../lib/ConstData";
import { enAdminTap, enManagePage } from "../../lib/ConstCommand";

import WebStudioContainer from "./WebStudioContainer";
import WebMyConfigSubDomainList from "../../components/admin/WebMyConfigSubDomainList";
import WebMyCreateSubDomainList from "../../components/admin/WebMyCreateSubDomainList";
import WebSubDomainDetailConfig from "../../components/admin/subDomain/WebSubDomainDetailConfig";
import WebMediaConfig from "../../components/admin/WebMediaConfig";
import WebLocalServerConfig from "../../components/admin/WebLocalServerConfig";
import WebLiteServerConfig from "../../components/admin/WebLiteServerConfig";
import WebLocalSiteConfig from "../../components/admin/WebLocalSiteConfig";
import WebSubDomainDetailInfo from "../../components/admin/subDomain/WebSubDomainDetailInfo";

import {
    sendGetState
} from "../../lib/MediaSoupClient";

import {
    changeAlertMessage,
    getMyManageSubDomainList,
    resetUserLoginStatus,
    resetUserPW,
    findUser,
    addSubDomainInfo,
    selectSubDomainInfo,
    selectSubDomainConfigInfo,
    selectUserInfo,
    changeSubDomainLogo,
    falseToChangeLogo,
    duplicateCheckSubDomain,
    addSubDomainConfigAdmin,
    removeSubDomainConfigAdmin,
    addSubDomainCreateAdmin,
    removeSubDomainCreateAdmin,
    getSubDomainLocalServerList,
    getUserLocalServerList,
    createLocalServer,
    changeLocalServerPortInfo,
    getLocalSiteList,
    createLocalSite,
    deleteLocalSite,
    classOpenOnLocalSite,
    classCloseOnLocalSite,
    getLiteServerList,
    createLiteServer,
    changeLocalSiteInfo,
    changeLiteServerInfo,
    duplicateCheckUsers,
    createUsers
} from "../../modules/admin";

import {
    changeShowPassKind
} from "../../modules/hiclasstv";

import {
    getPurchasePass
} from "../../modules/pass";

class WebAdminContainer extends Component {
    handleSelectedFunc = ({ selectedFunc, subDomainName, selectedUserSeq, subDomainSeq, mode, password, message, keyword, kind, data, filename, subDomain_info }) => {
        switch (selectedFunc) {
            case "FIND_USER":
                this.props.findUser({
                    kind: "search-user",
                    userKind: subDomainName,
                    keyWord: keyword
                });
                break;

            case "GET_MANAGE_INFO":
                this.props.getMyManageSubDomainList({
                    userSeq: this.props.userSeq,
                    userKind: this.props.userKind
                });
                break;

            case "GET_SERVER_STATE":
                sendGetState();
                break;

            case "GET_PCHS_LIST":
                this.props.selectSubDomainInfo({
                    userKind: this.props.userKind,
                    subDomainSeq
                });

                this.props.changeShowPassKind({
                    selectedFunc: "SHOW_PASS_PCHS_LIST"
                });

                if (kind === "SUB_DOMAIN") {
                    this.props.getPurchasePass({
                        term: "all",
                        kindCode: "all",
                        subDomainSeq
                    });
                } /* else {
                    this.props.selectUserInfo({
                        subDomainSeq,
                        selUserSeq: selectedUserSeq
                    });

                    this.props.getPurchasePass({
                        term: "all",
                        kindCode: "all",
                        subDomainSeq,
                        selUserSeq: selectedUserSeq
                    });
                } */

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push("/pass");
                }
                break;

            case "CHECK_WEB_SUB_DOMAIN":
                this.props.duplicateCheckSubDomain({
                    userSeq: this.props.userSeq,
                    subDomainName
                });
                break;

            case "ADD_OK_WEB_SUB_DOMAIN":
                this.props.addSubDomainInfo({
                    userSeq: this.props.userSeq,
                    subDomainName,
                    data,
                    filename
                });
                break;

            case "RESET_USER_STATUS":
                this.props.resetUserLoginStatus({
                    userSeq: this.props.userSeq,
                    selectedUserSeq,
                    subDomainSeq,
                    mode
                });
                break;

            case "RESET_USER_PW":
                this.props.resetUserPW({
                    userSeq: this.props.userSeq,
                    selectedUserSeq,
                    subDomainSeq,
                    password
                });
                break;

            case "ADD_SUB_DOMAIN_ADMIN":
                this.props.addSubDomainConfigAdmin({
                    userSeq: this.props.userSeq,
                    subDomainSeq,
                    selectedUserSeq
                });
                break;

            case "REMOVE_SUB_DOMAIN_ADMIN":
                this.props.removeSubDomainConfigAdmin({
                    userSeq: this.props.userSeq,
                    subDomainSeq,
                    selectedUserSeq
                });
                break;

            case "ADD_CREATE_SUB_DOMAIN_ADMIN":
                this.props.addSubDomainCreateAdmin({
                    userSeq: this.props.userSeq,
                    subDomainSeq,
                    selectedUserSeq
                });
                break;

            case "REMOVE_CREATE_SUB_DOMAIN_ADMIN":
                this.props.removeSubDomainCreateAdmin({
                    userSeq: this.props.userSeq,
                    subDomainSeq,
                    selectedUserSeq
                });
                break;

            case "CHANGE_ALERT_MESSAGE":
                this.props.changeAlertMessage(message, kind);
                break;

            case "CONFIG_SUB_DOMAIN":
                // this.props.selectSubDomainConfigInfo({ subDomain_info });

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push(`/admin/subdomain/${subDomain_info.subDomainSeq}`);
                }
                break;

            case "CONFIG_SUB_DOMAIN_MEMBER":
                // this.props.selectSubDomainConfigInfo({ subDomain_info });

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push(`/admin/subdomain/${subDomain_info.subDomainSeq}/member`);
                }
                break;

            case "CHANGE_LOGO":
                this.props.changeSubDomainLogo({
                    subDomainSeq,
                    subDomainName,
                    data,
                    filename
                });
                break;

            case "FALSE_TO_CHANGE_LOGO":
                this.props.falseToChangeLogo();
                break;

            case "GOTO_SUB_DOMAIN_HOME":
                /* this.props.selectSubDomainConfigInfo({
                    subDomain_info: {
                        selectedIdx: -1,
                        subDomainSeq: -1,
                        subDomainName: ""
                    }
                }); */

                if (this.props.routeProps.history) {
                    this.props.routeProps.history.push("/admin");
                }
                break;

            case "GET_SUB_DOMAIN_LOCAL_SERVER_INFO":
                this.props.getSubDomainLocalServerList({ subDomainSeq });
                break;

            case "GET_USER_LOCAL_SERVER_INFO":
                this.props.getUserLocalServerList({ subDomainSeq, selectedUserSeq });
                break;

            case "CREATE_LOCAL_SERVER":
                console.log("CREATE_LOCAL_SERVER - ", data);
                this.props.createLocalServer(data);
                break;

            case "DELETE_LOCAL_SERVER":
                console.log("DELETE_LOCAL_SERVER - ", data);
                // this.props.removeLocalServer();
                break;

            case "CHANGE_LOCAL_SITE":
                console.log("CHANGE_LOCAL_SITE - ", data);
                this.props.changeLocalSiteInfo(data);
                break;

            case "CHANGE_LOCAL_SERVER_PORT_NUM":
                console.log("CHANGE_LOCAL_SERVER_PORT_NUM - ", data);
                this.props.changeLocalServerPortInfo({
                    localServerSeq: data.localServer_info.localServerSeq,
                    portNumber: data.portNumber,
                    historyMemo: data.historyMemo,
                    status: true
                });
                break;

            case "CLOSE_LOCAL_SERVER_PORT":
                console.log("CLOSE_LOCAL_SERVER_PORT - ", data);
                this.props.changeLocalServerPortInfo({
                    localServerSeq: data.localServer_info.localServerSeq,
                    historySeq: data.localServer_info.historySeq,
                    status: false
                });
                break;

            case "GET_LOCAL_SITE_LIST":
                this.props.getLocalSiteList();
                break;

            case "CREATE_LOCAL_SITE":
                this.props.createLocalSite({ localSiteName: data });
                break;

            case "DELETE_LOCAL_SITE":
                this.props.deleteLocalSite({ localSiteSeq: data });
                break;

            case "SET_LOCAL_SITE_OPEN":
                console.log("SET_LOCAL_SITE_OPEN - ", data);
                this.props.classOpenOnLocalSite(data);
                break;

            case "SET_LOCAL_SITE_CLOSE":
                console.log("SET_LOCAL_SITE_CLOSE - ", data);
                this.props.classCloseOnLocalSite(data);
                break;

            case "GET_LITE_SERVER_LIST":
                this.props.getLiteServerList();
                break;

            case "CREATE_LITE_SERVER":
                console.log("CREATE_LITE_SERVER - ", data);
                this.props.createLiteServer(data);
                break;

            case "UPDATE_LITE_SERVER":
                console.log("UPDATE_LITE_SERVER - ", data);
                this.props.changeLiteServerInfo({
                    kind: "modify",
                    liteServerSeq: data.liteServerSeq,
                    authCode: data.authCode,
                    liteServerMemo: data.memo
                });
                break;

            case "INIT_LITE_SERVER":
                this.props.changeLiteServerInfo({
                    kind: "init",
                    liteServerSeq: data.liteServerSeq,
                    authCode: data.authCode,
                    initStatus: data.initStatus
                });
                break;

            case "DELETE_LITE_SERVER":
                break;

            case "DUPLICATE_CHECK_USERS":
                this.props.duplicateCheckUsers(data);
                break;

            case "CREATE_USERS":
                this.props.createUsers(data);
                break;

            default:
                break;
        }
    }

    render() {
        if (this.props.userSeq < 0 || !(this.props.isConfigAdmin || this.props.isCreateAdmin)) {
            return <Redirect to="/main" />;
        }

        if (this.props.currentManagePageType !== enManagePage.SubDomainInfo) {
            const { params } = this.props.routeProps.match;
            const subDomain_info = this.props.list_configSubDomain.find(info => info.subDomainSeq === Number(params.subDomainSeq));
            
            if (subDomain_info === undefined || subDomain_info === null) {
                return <Redirect to="/admin" />;
            }

            return (
                this.props.currentManagePageType === enManagePage.ConfigSubDomain ?
                    <WebSubDomainDetailConfig
                        subDomain_info={subDomain_info}
                        isChangeLogoOK={this.props.isChangeLogoOK}
                        alertKind={this.props.alertKind}
                        alertMessage={this.props.alertMessage}
                        handleSelectedFunc={this.handleSelectedFunc}
                    /> :
                    <WebSubDomainDetailInfo
                        userSeq={this.props.userSeq}
                        userKind={this.props.userKind}
                        isStandardType={this.props.isStandardType}
                        subDomain_info={subDomain_info}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
            );
        } else {
            switch (this.props.currentAdminTap) {
                case enAdminTap.Home:
                    return (
                        <>
                            {
                                this.props.isConfigAdmin &&
                                <WebMyConfigSubDomainList
                                    userSeq={this.props.userSeq}
                                    userKind={this.props.userKind}
                                    isStandardType={this.props.isStandardType}
                                    list_subDomainInfo={this.props.list_configSubDomain}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            } {
                                this.props.isCreateAdmin &&
                                <WebMyCreateSubDomainList
                                    userSeq={this.props.userSeq}
                                    userKind={this.props.userKind}
                                    isStandardType={this.props.isStandardType}
                                    alertKind={this.props.alertKind}
                                    alertMessage={this.props.alertMessage}
                                    isCheckSubDomainOK={this.props.isCheckSubDomainOK}
                                    isAddSubDomainOK={this.props.isAddSubDomainOK}
                                    list_subDomainInfo={this.props.list_createSubDomain}
                                    handleSelectedFunc={this.handleSelectedFunc}
                                />
                            }
                        </>
                    );

                case enAdminTap.LocalServer:
                    return (
                        <WebLocalServerConfig
                            selectedUserInfo={this.props.selectedUserInfo}
                            selectedSubDomainInfo={this.props.selectedSubDomainInfo}
                            list_localSite={this.props.list_localSite}
                            list_configLocalServer={this.props.list_configLocalServer}
                            list_configSubDomain={this.props.list_configSubDomain}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    );

                case enAdminTap.LocalSite:
                    return (
                        <WebLocalSiteConfig
                            list_configLocalSite={this.props.list_configLocalSite}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    );

                case enAdminTap.LiteLocalServer:
                    return (
                        <WebLiteServerConfig
                            selectedSubDomainInfo={this.props.selectedSubDomainInfo}
                            list_configLiteServer={this.props.list_configLiteServer}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    );

                case enAdminTap.Studio:
                    return (
                        <WebStudioContainer
                            currentPageType={this.props.currentPageType}
                        />
                    );

                case enAdminTap.Media:
                    return (
                        <WebMediaConfig
                            media_info={this.props.media_info}
                            handleSelectedFunc={this.handleSelectedFunc}
                        />
                    );

                default:
                    return <></>;
            }
        }
    }
}

export default connect(
    ({ user, hiclasstv, admin }) => ({
        isConfigAdmin           : user.isConfigAdmin,
        isCreateAdmin           : user.isCreateAdmin,
        isStandardType          : admin.isStandardType,

        userSeq                 : user.userSeq,
        userKind                : user.userKind,

        currentAdminTap         : hiclasstv.currentAdminTap,

        list_localSite          : admin.list_localSite,
        
        list_configSubDomain    : admin.list_configSubDomain,
        list_createSubDomain    : admin.list_createSubDomain,
        list_configLocalServer  : admin.list_configLocalServer,
        list_configLocalSite    : admin.list_configLocalSite,
        list_configLiteServer   : admin.list_configLiteServer,

        selectedSubDomainInfo   : admin.selectedSubDomainInfo,
        selectedUserInfo        : admin.selectedUserInfo,

        media_info              : admin.media_info,

        isCheckSubDomainOK      : admin.isCheckSubDomainOK,
        isAddSubDomainOK        : admin.isAddSubDomainOK,
        isChangeLogoOK          : admin.isChangeLogoOK,

        alertMessage            : admin.alertMessage,
        alertKind               : admin.alertKind
    }),
    {
        selectSubDomainInfo,
        selectUserInfo,

        getPurchasePass,
        getMyManageSubDomainList,

        findUser,
        resetUserLoginStatus,
        resetUserPW,
        duplicateCheckSubDomain,
        addSubDomainInfo,
        addSubDomainConfigAdmin,
        removeSubDomainConfigAdmin,
        addSubDomainCreateAdmin,
        removeSubDomainCreateAdmin,
        selectSubDomainConfigInfo,
        changeSubDomainLogo,
        falseToChangeLogo,
        changeAlertMessage,

        changeShowPassKind,

        createLocalServer,
        getSubDomainLocalServerList,
        getUserLocalServerList,
        changeLocalSiteInfo,
        changeLocalServerPortInfo,

        getLocalSiteList,
        createLocalSite,
        deleteLocalSite,
        classOpenOnLocalSite,
        classCloseOnLocalSite,

        getLiteServerList,
        createLiteServer,
        changeLiteServerInfo,

        duplicateCheckUsers,
        createUsers
    }
)(WebAdminContainer);
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { enAdminTap } from "../../../lib/ConstCommand";

const WebConfigSearchBoxExceptUser = ({ adminTap, selectedSubDomainInfo, list_configSubDomain, handleSearchBoxFunc }) => {
    const { t } = useTranslation();

    const [lSubDomainInfo, setSubDomainInfo] = useState({ idx: 0, subDomainSeq: -1, subDomainName: "" });

    useEffect(() => {
        //console.log("WebConfigSearchBox visible");

        return () => {
            //console.log("WebConfigSearchBox invisible");
        }
    }, []);

    useEffect(() => {
        if (list_configSubDomain && list_configSubDomain.length > 0) {
            setSubDomainInfo({ idx: 0, subDomainSeq: list_configSubDomain[0].subDomainSeq, subDomainName: list_configSubDomain[0].subDomainName });
        }
    }, [list_configSubDomain]);

    useEffect(() => {
        if (selectedSubDomainInfo !== undefined && selectedSubDomainInfo !== null) {
            setSubDomainInfo({ idx: selectedSubDomainInfo.selectedIdx, subDomainSeq: selectedSubDomainInfo.subDomainSeq, subDomainName: selectedSubDomainInfo.subDomainName });
        }
    }, [selectedSubDomainInfo]);

    useEffect(() => {
        if (lSubDomainInfo) {
            if (lSubDomainInfo.subDomainSeq !== -1) {
                if (adminTap === enAdminTap.LocalServer) { 
                    handleSearchBoxFunc({ selectedFunc: "GET_SUB_DOMAIN_LOCAL_SERVER_INFO", subDomainSeq: lSubDomainInfo.subDomainSeq });
                }
            }
        }
    }, [lSubDomainInfo]);

    const onChangeSelectedSubDomain = (e) => {
        if (list_configSubDomain && list_configSubDomain.length > 0) {
            let idx = e.target.value;
            setSubDomainInfo({ idx, subDomainSeq: list_configSubDomain[idx].subDomainSeq, subDomainName: list_configSubDomain[idx].subDomainName });
        }
    }

    return (
        <div className="px-2">
            <div className="web-search-box">
                <div className="web-search-option-area white" style={{ justifyContent: 'unset' }}>
                    <div className="web-search-option">
                        <div className="web-search-select-label">
                            <span><b>{t("서브_도메인", { ns: "admin" })}</b></span>
                        </div>
                        <div className="web-search-select-box">
                            <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={lSubDomainInfo.idx} onChange={onChangeSelectedSubDomain}>
                                {
                                    list_configSubDomain && list_configSubDomain.length > 0 ?
                                        list_configSubDomain.map((info, index) => (
                                            <option value={index} key={"itemOption-" + index}>{info.subDomainName}</option>
                                        )) :
                                        <option value={-1}>
                                            {t("관리_가능한_서비스_목록_없음", { ns: "admin" })}
                                        </option>
                                }
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebConfigSearchBoxExceptUser;
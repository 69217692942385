import React, {useState} from 'react';
import { useTranslation } from "react-i18next";

const ChoiceFindInfo = ({ handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [selectOption, setOption] = useState(t("ID", { ns: "user" }));

    const handleSelectOption = (e) => {
        setOption(e.target.value);
    }

    return (
        <div className="d-flex flex-column align-items-center my-2 area-70">
            <div className="d-flex flex-column py-4">
                <div className="d-flex align-items-center mb-2">
                    <input className="d-flex mr-1" type="radio" name="groupOfFindIdPwRadios" id="findIdPwRadio1" value="ID" defaultChecked onChange={handleSelectOption} />
                    {t("ID_찾기", { ns: "user" })}
                </div>
                <div className="d-flex align-items-center mt-2">
                    <input className="d-flex mr-1" type="radio" name="groupOfFindIdPwRadios" id="findIdPwRadio2" value="PW" onChange={handleSelectOption} />
                    {t("비밀번호_찾기", { ns: "user" })}
                </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-sm btn-primary" onClick={() => handleSelectedFunc({selectedFunc:"FIND", type:selectOption})}>{t("찾기", { ns: "common" })}</button>
                <button className="btn btn-sm hiclasstv-grey" onClick={() => handleSelectedFunc({selectedFunc:"CLOSE"})}>{t("취소", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default ChoiceFindInfo;
import React, { useEffect, createRef, useRef, useState } from "react";
import { FaTimes, FaPen, FaPaintBrush, FaEraser, FaTrashAlt, FaCircle, FaPaperPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { enDrawingMode } from "../../../lib/classTalk/CodeEnum";

import palette from "../../../lib/styles/palett";

const InkMemoWindow = ({ toolMode, inkMemoFile, handleSelectedClassTalkFunc }) => {
    const { t } = useTranslation();

    const targetRef = useRef();

    const [canvasRef] = useState(new createRef());
    const canvas = useRef();
    const ctx = useRef();

    const eraser_color = palette.yellow[0];

    var state_color = palette.gray[9];
    var state_thick = 1;

    useEffect(() => {
        canvas.current = canvasRef.current;
        ctx.current = canvas.current.getContext("2d");
        ctx.current.lineCap = "round";
        ctx.current.lineJoin = "round";

        if (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2)) {
            canvas.current.addEventListener("touchstart", initDraw);
            canvas.current.addEventListener("touchmove", draw);
            canvas.current.addEventListener("touchend", finishDraw);
            canvas.current.addEventListener("touchcancel", finishDraw);
            canvas.current.addEventListener("touchmove", handlePreventClick);
        } else {
            canvas.current.addEventListener("mousedown", initDraw);
            canvas.current.addEventListener("mousemove", draw);
            canvas.current.addEventListener("mouseup", finishDraw);
            canvas.current.addEventListener("mouseout", finishDraw);
        }

        if (inkMemoFile !== null && inkMemoFile !== undefined && inkMemoFile !== "") {
            var img = new Image();
            img.src = inkMemoFile;
            ctx.current.drawImage(img, 0, 0, canvas.current.width, canvas.current.height);
        } else {
            ctx.current.fillStyle = palette.yellow[0];
            ctx.current.fillRect(0, 0, canvas.current.width, canvas.current.height);
        }

        return () => {
            if (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2)) {
                canvas.current.removeEventListener("touchstart", initDraw);
                canvas.current.removeEventListener("touchmove", draw);
                canvas.current.removeEventListener("touchend", finishDraw);
                canvas.current.removeEventListener("touchcancel", finishDraw);
            } else {
                canvas.current.removeEventListener("mousedown", initDraw);
                canvas.current.removeEventListener("mousemove", draw);
                canvas.current.removeEventListener("mouseup", finishDraw);
                canvas.current.removeEventListener("mouseout", finishDraw);
            }
        }
    }, []);

    useEffect(() => {
        canvas.current = canvasRef.current;
        ctx.current = canvas.current.getContext("2d");
        ctx.current.lineCap = "round";
        ctx.current.lineJoin = "round";

        if (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2)) {
            canvas.current.addEventListener("touchstart", initDraw);
            canvas.current.addEventListener("touchmove", draw);
            canvas.current.addEventListener("touchend", finishDraw);
            canvas.current.addEventListener("touchcancel", finishDraw);
        } else {
            canvas.current.addEventListener("mousedown", initDraw);
            canvas.current.addEventListener("mousemove", draw);
            canvas.current.addEventListener("mouseup", finishDraw);
            canvas.current.addEventListener("mouseout", finishDraw);
        }

        if (toolMode === enDrawingMode.Marker)
            state_thick = ctx.current.lineWidth * 3;
        else
            state_thick = ctx.current.lineWidth;

        state_color = ctx.current.strokeStyle;
    }, [toolMode]);

    var pos = {
        drawable: false,
        X: -1,
        Y: -1
    }

    const handlePreventClick = (e) => {
        e.preventDefault(false);
        e.stopPropagation();
    }

    const changeColor = (color) => {
        if (toolMode !== enDrawingMode.EraseStroke) {
            switch (color) {
                case "RED":
                    state_color = "red";
                    break;

                case "YELLOW":
                    state_color = palette.yellow[5];
                    break;

                case "GREEN":
                    state_color = palette.green[9];
                    break;

                case "BLUE":
                    state_color = palette.blue[8];
                    break;

                case "BLACK":
                    state_color = "black";
                    break;

                default:
                    break;
            }

            ctx.current.strokeStyle = state_color;
        }
    }

    const changeThick = (thick) => {
        switch (thick) {
            case "small":
                if (toolMode === enDrawingMode.Marker)
                    state_thick = 1 * 3;
                else
                    state_thick = 1;
                break;

            case "midium":
                if (toolMode === enDrawingMode.Marker)
                    state_thick = 3 * 3;
                else
                    state_thick = 3;
                break;

            case "large":
                if (toolMode === enDrawingMode.Marker)
                    state_thick = 5 * 3;
                else
                    state_thick = 5;
                break;

            default:
                break;
        }

        ctx.current.lineWidth = state_thick;
    }

    const initDraw = (e) => {
        pos = {
            drawable: true,
            ...getPosition(e)
        };

        if (toolMode === enDrawingMode.Marker)
            ctx.current.globalAlpha = 0.3;
        else
            ctx.current.globalAlpha = 1;

        if (toolMode === enDrawingMode.EraseStroke)
            ctx.current.strokeStyle = eraser_color;

        ctx.current.lineWidth = state_thick;
        ctx.current.beginPath();
    }

    const draw = (e) => {
        if (pos.drawable) {
            ctx.current.moveTo(pos.X, pos.Y);

            pos = {
                ...pos,
                ...getPosition(e)
            };

            ctx.current.lineTo(pos.X, pos.Y);
            ctx.current.stroke();
        }
    }

    const finishDraw = (e) => {
        ctx.current.closePath();

        pos = {
            drawable: false,
            X: -1,
            Y: -1
        }
    }

    const getPosition = (e) => {
        if (e.type === "mousedown" || e.type === "mousemove" || e.type === "mouseup" || e.type === "mouseout") {
            return {
                X: e.offsetX,
                Y: e.offsetY
            }
        } else {
            return {
                X: e.changedTouches[0].clientX - e.target.offsetLeft,
                Y: e.changedTouches[0].clientY - e.target.offsetTop
            }
        }
    }

    const clear = () => {
        canvas.current = canvasRef.current;

        ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
        ctx.current.globalAlpha = 1;

        if (inkMemoFile !== null && inkMemoFile !== undefined && inkMemoFile !== "") {
            var img = new Image();
            img.src = inkMemoFile;
            ctx.current.drawImage(img, 0, 0, canvas.current.width, canvas.current.height);
        } else {
            ctx.current.fillStyle = palette.yellow[0];
            ctx.current.fillRect(0, 0, canvas.current.width, canvas.current.height);
        }
    }

    const sendInkMemo = () => {
        canvas.current = canvasRef.current;

        var dataUrl = canvas.current.toDataURL("image/jpeg");
        var blob = convertDataURIToBinary(dataUrl);

        //var filename = "im" + Date.now().toString() + ".jpg";

        handleSelectedClassTalkFunc({ selectedFunc: "SEND_INK_MEMO", data: blob, dataUrl: dataUrl });
    }

    const convertDataURIToBinary = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        var bb = new Blob([ab], { "type": mimeString });

        return bb;
    }

    return (
        <div className="ink-memo window" onClick={handlePreventClick}>
            <div className="title-area">
                <span title={t("잉크메모", { ns: "classTalk" })}>
                    <b>{t("잉크메모", { ns: "classTalk" })}</b>
                </span>
                <button className="button p-1" style={{ position: 'absolute', right: '0' }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "CLOSE_INK_MEMO" })}>
                    <FaTimes className="d-flex m-1" />
                </button>
            </div>
            <div className="d-flex ink-memo draw-ground" ref={targetRef}>
                <canvas
                    className="d-flex ink-memo canvas"
                    id="ink-memo-canvas"
                    ref={canvasRef}
                    resize="true"
                    width={300}
                    height={150}
                />
            </div>
            <div className="ink-memo ink-menu" style={{ borderTop: '1px solid #dedede' }}>
                <div className={"ink-memo ink-btn" + (toolMode === enDrawingMode.Pen ? " selected" : "")} data-toggle="tooltip" title={t("펜_모드", { ns: "classTalk" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "INK_MEMO_TOOL", tool: enDrawingMode.Pen })}>
                    <span className="button">
                        <FaPen className="font-sm" />
                    </span>
                </div>
                <div className={"ink-memo ink-btn" + (toolMode === enDrawingMode.Marker ? " selected" : "")} data-toggle="tooltip" title={t("마커_모드", { ns: "classTalk" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "INK_MEMO_TOOL", tool: enDrawingMode.Marker })}>
                    <span className="button">
                        <FaPaintBrush className="font-sm" />
                    </span>
                </div>
                <div className={"ink-memo ink-btn" + (toolMode === enDrawingMode.EraseStroke ? " selected" : "")} data-toggle="tooltip" title={t("지우개_모드", { ns: "classTalk" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "INK_MEMO_TOOL", tool: enDrawingMode.EraseStroke })}>
                    <span className="button">
                        <FaEraser className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn" data-toggle="tooltip" title={t("잉크_전부_삭제", { ns: "classTalk" })} onClick={() => clear()}>
                    <span className="button">
                        <FaTrashAlt className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn red-text" data-toggle="tooltip" title={t("빨간색", { ns: "classTalk" })} onClick={() => changeColor("RED")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn yellow-text" data-toggle="tooltip" title={t("노란색", { ns: "classTalk" })} onClick={() => changeColor("YELLOW")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn green-text" data-toggle="tooltip" title={t("초록색", { ns: "classTalk" })} onClick={() => changeColor("GREEN")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn blue-text" data-toggle="tooltip" title={t("파란색", { ns: "classTalk" })} onClick={() => changeColor("BLUE")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn black-text" data-toggle="tooltip" title={t("검정색", { ns: "classTalk" })} onClick={() => changeColor("BLACK")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn grey-text" data-toggle="tooltip" title={t("얇게", { ns: "classTalk" })} onClick={() => changeThick("small")}>
                    <span className="button">
                        <FaCircle className="font-xxsm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn grey-text" data-toggle="tooltip" title={t("보통", { ns: "classTalk" })} onClick={() => changeThick("midium")}>
                    <span className="button">
                        <FaCircle className="font-sm" />
                    </span>
                </div>
                <div className="ink-memo ink-btn grey-text" data-toggle="tooltip" title={t("굵게", { ns: "classTalk" })} onClick={() => changeThick("large")}>
                    <span className="button">
                        <FaCircle className="font-md" />
                    </span>
                </div>
                <div className="ink-memo ink-btn" data-toggle="tooltip" title={t("보내기", { ns: "common" })} onClick={() => sendInkMemo()}>
                    <span className="button">
                        <FaPaperPlane className="font-md" />
                    </span>
                </div>
            </div>
        </div>
    );
}

export default InkMemoWindow;
import React from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const UserPanelBox = ({ list_member, togglePanelWindow, onHandleSelectHandsupMember }) => {
    const { t } = useTranslation();

    const handelPreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="user-panel">
            <div className="title-area">
                <span>
                    <b>{t("대상자_선택", { ns: "classTalk" })}</b>
                </span>
                <span className="func-btn" title={t("닫기", { ns: "common" })} style={{ position: 'absolute', right: 0 }} onClick={() => togglePanelWindow()}>
                    <BsX />
                </span>
            </div>
            <div className="scroll-show-active">
                {
                    list_member.map((member, index) =>
                        <div className="item" key={("whisper-" + index)}>
                            {
                                member.userJoinStatus === "Y" ?
                                    <>
                                        <button className={"no-effect hiclasstv-green" + (member.isSelected ? " outline bold outline-red" : "")} onClick={() => onHandleSelectHandsupMember(member.userSeq)}>
                                            {index + 1}
                                        </button>
                                        <div className="text-area my-1 px-1">
                                            <span className="hiclass-text" title={member.userNickname}>{member.userNickname}</span>
                                        </div>
                                    </> :
                                    <>
                                        <button className="no-effect hiclasstv-grey" disabled>
                                            {index + 1}
                                        </button>
                                        <div className="text-area my-1 px-1">
                                            <span className="hiclass-text" title={member.userNickname}>{member.userNickname}</span>
                                        </div>
                                    </>
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default UserPanelBox;
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

import { FaChalkboardTeacher, FaGripVertical, FaExchangeAlt } from 'react-icons/fa';

// import chatList from '../image/toolbar/footer_chatList.png';
// import chatting from '../image/toolbar/footer_chatting.png';
// import friendList from '../image/toolbar/footer_friendList.png';
// import etcList from '../image/toolbar/footer_etc.png';

const FooterMenu = ({ selectMainTap, changeWindowPentalkBoard }) => {
    const { t } = useTranslation();

    const clickChangeWindow = () => {
        changeWindowPentalkBoard();
    }

    return ( 
            <div className="footer-template">
                <ul className="list-group list-group-horizontal">
                    <Link to="/list_friend_hiclass">
                        <li className={"footer-menu" + (selectMainTap === "LIST_FRIEND" ? " active" : "")} title={t("내_정보", { ns: "user" })}>
                            <img className="footer-menu-icon" src="/images/toolbar/footer_friendList.png" aria-hidden="true" alt="" />
                        </li>
                    </Link>
                    {/*
                    <Link to="/list_chat_hiclass">
                        <li className={"footer-menu" + (selectMainTap === "LIST_CHAT" ? " active" : "")} title={t("팀업", { ns: "chat" })}>
                            <img className="footer-menu-icon" src="/images/toolbar/footer_chatList.png" aria-hidden="true" alt="" />
                        </li>
                    </Link>
                    */}
                    <Link to="/list_live_hiclass">
                        <li className={"footer-menu" + (selectMainTap === "LIST_LIVE" ? " active" : "")} title={t("클래스", { ns: "live" })}>
                            <FaChalkboardTeacher className="font-lg white-text" aria-hidden="true" alt="" />
                        </li>
                    </Link>
                    <Link to="/chatting_hiclass">
                        <li className={"footer-menu" + (selectMainTap === "CHATTING" ? " active" : "")} title={t("클래스톡", { ns: "classTalk"})}>
                            <img className="footer-menu-icon" src="/images/toolbar/footer_chatting.png" aria-hidden="true" alt="" />
                        </li>
                    </Link>
                    {/*
                    <Link to="/remocon_hiclass">
                        <li className={"footer-menu" + (selectMainTap === "REMOCON" ? " active" : "")} title={t("리모콘", { ns: "hiclasstv" })}>
                            <FaGripVertical className="font-lg white-text" aria-hidden="true" />
                        </li>
                    </Link>*/}
                     {/* // .................. pentalk onChat <--> pencam onBoard 윈도우 전환 ............ 2020-11-05 by ykhan
                    <li className="footer-menu area-20" title="보드/펜톡 전환" onClick={()=>clickChangeWindow()}>
                        <FaExchangeAlt className="font-lg white-text" aria-hidden="true" />
                    </li>
                     */}
                </ul>
            </div>
    );
}

export default FooterMenu;
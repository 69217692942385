import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { FiMinusSquare, FiUserCheck, FiUserPlus, FiUserX } from "react-icons/fi";

import ConstData from "../../../lib/ConstData";

const checkStringValidation = (value) => {
    /* if (value !== undefined && value !== null && value !== "") return true;
    else return false; */
    return value !== undefined && value !== null && value !== "";
}

const checkNumberValidation = (value) => {
    /* if (value !== undefined && value !== null && value > -1) return true;
    else return false; */
    return value !== undefined && value !== null && value > -1;
}

const checkPasswordPattern = (str) => {
    let pattern1 = /[0-9]/;                         // 숫자
    let pattern2 = /[a-zA-Z]/;			            // 문자
    let pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;        // 특수문자

    if (!pattern1.test(str) || !pattern2.test(str) || !pattern3.test(str) || str.length < 8) {
        // alert("비밀번호는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.");
        return false;
    } else {
        return true;
    }
}

const checkBooleanValidation = (value) => {
    return value !== undefined && value !== null && typeof value === "boolean";
}

const WebCreateUserInfo = forwardRef(({ idx, createUser_info, handleFunc }, ref) => {
    const { t } = useTranslation();

    const [id, setId] = useState(checkStringValidation(createUser_info.userID) ? createUser_info.userID : "");
    const [password, setPassword] = useState(checkStringValidation(createUser_info.userPW) ? createUser_info.userPW : "");
    const [nickname, setNickname] = useState(checkStringValidation(createUser_info.userNickname) ? createUser_info.userNickname : "");
    const [profile, setProfile] = useState(checkStringValidation(createUser_info.userProfile) ? createUser_info.userProfile : "");
    const [isIdCheckOK, setIsIdCheckOK] = useState(checkBooleanValidation(createUser_info.isIdCheckOK) ? createUser_info.isIdCheckOK : false);
    const [isJoinSuccess, setIsJoinSuccess] = useState(checkBooleanValidation(createUser_info.isJoinSuccess) ? createUser_info.isJoinSuccess : false);
    const [isExist, setIsExist] = useState(checkBooleanValidation(createUser_info.isExist) ? createUser_info.isExist : false);
    const [userSeq, setUserSeq] = useState(checkNumberValidation(createUser_info.userSeq) ? createUser_info.userSeq : -1);
    const [errMsg, setErrMsg] = useState(checkStringValidation(createUser_info.errMsg) ? createUser_info.errMsg : "");

    useEffect(() => {
        // console.log("useEffect - createUser_info => ", createUser_info);
        if (createUser_info) {
            if (checkStringValidation(createUser_info.userID)) setId(createUser_info.userID);
            if (checkStringValidation(createUser_info.userPW)) setPassword(createUser_info.userPW);
            if (checkStringValidation(createUser_info.userNickname)) setNickname(createUser_info.userNickname);
            if (checkStringValidation(createUser_info.userProfile)) setProfile(createUser_info.userProfile);
            if (checkBooleanValidation(createUser_info.isIdCheckOK)) setIsIdCheckOK(createUser_info.isIdCheckOK);
            if (checkBooleanValidation(createUser_info.isJoinSuccess)) setIsJoinSuccess(createUser_info.isJoinSuccess);
            if (checkBooleanValidation(createUser_info.isExist)) setIsExist(createUser_info.isExist);
            if (checkNumberValidation(createUser_info.userSeq)) setUserSeq(createUser_info.userSeq);
            if (checkStringValidation(createUser_info.errMsg)) setErrMsg(createUser_info.errMsg);
        }
    }, [createUser_info]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.userID => ", createUser_info.userID);
        if (checkStringValidation(createUser_info.userID)) setId(createUser_info.userID);
    }, [createUser_info.userID]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.userPW => ", createUser_info.userPW);
        if (checkStringValidation(createUser_info.userPW)) setPassword(createUser_info.userPW);
    }, [createUser_info.userPW]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.userNickname => ", createUser_info.userNickname);
        if (checkStringValidation(createUser_info.userNickname)) setNickname(createUser_info.userNickname);
    }, [createUser_info.userNickname]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.userProfile => ", createUser_info.userProfile);
        if (checkStringValidation(createUser_info.userProfile)) setProfile(createUser_info.userProfile);
    }, [createUser_info.userProfile]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.isIdCheckOK => ", createUser_info.isIdCheckOK);
        if (checkBooleanValidation(createUser_info.isIdCheckOK)) setIsIdCheckOK(createUser_info.isIdCheckOK);
    }, [createUser_info.isIdCheckOK]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.isJoinSuccess => ", createUser_info.isJoinSuccess);
        if (checkBooleanValidation(createUser_info.isJoinSuccess)) setIsJoinSuccess(createUser_info.isJoinSuccess);
    }, [createUser_info.isJoinSuccess]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.isExist => ", createUser_info.isExist);
        if (checkBooleanValidation(createUser_info.isExist)) setIsExist(createUser_info.isExist);
    }, [createUser_info.isExist]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.userSeq => ", createUser_info.userSeq);
        if (checkNumberValidation(createUser_info.userSeq)) setUserSeq(createUser_info.userSeq);
    }, [createUser_info.userSeq]);

    useEffect(() => {
        // console.log("useEffect - createUser_info.errMsg => ", createUser_info.errMsg);
        if (checkStringValidation(createUser_info.errMsg)) setErrMsg(createUser_info.errMsg);
    }, [createUser_info.errMsg]);

    const onChangeId = (e) => {
        let str = e.target.value;
        str = str.replace(" ", "");

        if (str.length < 4 || str.length > 16) {
            if (str.length > 16) str = str.substr(0, 16);
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.DANGER,
                    alertMessage: t("유효성_검사_ID_글자수오류", { ns: "user" })
                }
            });
        } else {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.NONE,
                    alertMessage: ""
                }
            });
        }

        setId(str);

        handleFunc({
            selectedFunc: "update-info",
            data: {
                createUser_info: {
                    ...createUser_info,
                    index: idx,
                    userID: str
                }
            }
        });
    }

    const onChangePassword = (e) => {
        let str = e.target.value;
        let isChecked = checkPasswordPattern(str);

        /* if (!isChecked) {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.DANGER,
                    alertMessage: "비밀번호는 8자리 이상 영문자, 숫자, 특수문자로 구성하여야 합니다."
                }
            });
        } else {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.SUCCESS,
                    alertMessage: "사용 가능한 비밀번호입니다."
                }
            });
        } */

        if (str.length < 4 || str.length > 16) {
            if (str.length > 16) str = str.substr(0, 16);
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.DANGER,
                    alertMessage: t("유효성_검사_비밀번호_구성오류", { ns: "user" })
                }
            });
        } else {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.NONE,
                    alertMessage: ""
                }
            });
        }

        setPassword(str);

        handleFunc({
            selectedFunc: "update-info",
            data: {
                createUser_info: {
                    ...createUser_info,
                    index: idx,
                    userPW: str
                }
            }
        });
    }

    const onChangeNickname = (e) => {
        let str = e.target.value;
        str = str.replace(" ", "");

        if (str.length > 15) {
            str = str.substr(0, 15);
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.DANGER,
                    alertMessage: t("유효성_검사_닉네임_글자수오류", { ns: "user" })
                }
            });
        } else {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.NONE,
                    alertMessage: ""
                }
            });
        }

        setNickname(str);

        handleFunc({
            selectedFunc: "update-info",
            data: {
                createUser_info: {
                    ...createUser_info,
                    index: idx,
                    userNickname: str
                }
            }
        });
    }

    const onChangeProfile = (e) => {
        let str = e.target.value;

        if (str.length > 30) {
            str = str.substr(0, 30);
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.DANGER,
                    alertMessage: t("유효성_검사_프로필_글자수오류", { ns: "user" })
                }
            });
        } else {
            handleFunc({
                selectedFunc: "alert",
                data: {
                    alertKind: ConstData.ALERT_KIND.NONE,
                    alertMessage: ""
                }
            });
        }

        setProfile(str);

        handleFunc({
            selectedFunc: "update-info",
            data: {
                createUser_info: {
                    ...createUser_info,
                    index: idx,
                    userProfile: str
                }
            }
        });
    }

    const onClickMinusBtn = () => {
        handleFunc({ selectedFunc: "delete-info", data: { idx } });
    }

    return (
        <div className="input-area">
            {/* <div className="minus-btn-area" style={{ width: '5%', paddingRight: '0.75rem' }} onClick={onClickMinusBtn}>
                <FiMinusSquare className="font-lg hiclasstv-coral-text" />
            </div> */}
            {/* <button className="minus-btn-area" style={{ width: '5%', paddingRight: '0.75rem' }} onClick={onClickMinusBtn}>
                <FiMinusSquare className="font-lg hiclasstv-coral-text" />
            </button> */}
            <button type="button" className="hiclasstv-btn-transparent hiclasstv-coral-text p-0" style={{ width: '5%', paddingRight: '0.75rem' }} onClick={onClickMinusBtn}>
                <FiMinusSquare className="font-lg" />
            </button>
            <div className="input-data" style={{ width: '20%', paddingRight: '0.75rem', fontSize: '0.7rem' }}>
                <input type="email" id="inputUserID" autoComplete="off" placeholder={t("유효성_검사_ID_입력오류", { ns: "user" })} value={id} onChange={onChangeId} />
            </div>
            <div className="input-data" style={{ width: '15%', paddingRight: '0.75rem' }}>
                <input type="password" id="inputUserPW" autoComplete="new-password" placeholder={t("유효성_검사_비밀번호_입력오류", { ns: "user" })} value={password} onChange={onChangePassword} />
            </div>
            <div className="input-data" style={{ width: '20%', paddingRight: '0.75rem' }}>
                <input type="text" id="inputUserNickname" autoComplete="off" placeholder={t("유효성_검사_닉네임_입력오류", { ns: "user" })} value={nickname} onChange={onChangeNickname} />
            </div>
            <div className="input-data" style={{ width: '25%', paddingRight: '0.75rem' }}>
                <input type="text" id="inputUserProfile" autoComplete="off" placeholder={t("유효성_검사_프로필_입력오류", { ns: "user" })} value={profile} onChange={onChangeProfile} />
            </div>
            <div className="minus-btn-area" style={{ width: '15%', padding: '0rem 0.75rem 0rem 0rem' }}>
                {
                    // console.log(`isIdCheckOK[${isIdCheckOK}], isExist[${isExist}], isJoinSuccess[${isJoinSuccess}]`)
                }
                {
                    isExist ?
                        <>
                            {/* <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                <div className="only-web flex-column justify-content-center align-items-center w-100">
                                    <FiUserX className="font-lg hiclasstv-coral-text" />
                                    <span className="font-sm hiclasstv-coral-text">사용 중인</span>
                                    <span className="font-sm hiclasstv-coral-text">아이디</span>
                                </div>
                                <div className="only-mobile w-100">
                                    <span className="font-xsm hiclasstv-coral-text">사용 중인 아이디</span>
                                </div>
                            </div> */}
                            {/* <div className="only-web flex-column justify-content-center align-items-center w-100">
                                <FiUserX className="font-lg hiclasstv-coral-text" />
                                <span className="font-sm hiclasstv-coral-text">사용 중인</span>
                                <span className="font-sm hiclasstv-coral-text">아이디</span>
                            </div>
                            <div className="only-mobile justify-content-center align-items-center w-100">
                                <span className="font-xsm hiclasstv-coral-text">사용 중인 아이디</span>
                            </div> */}
                            {/* <div className="only-web justify-content-center w-100">
                                <div className="d-flex flex-column align-items-center hiclasstv-coral-text">
                                    <FiUserX className="font-lg" />
                                    <span className="font-sm">사용 중인</span>
                                    <span className="font-sm">아이디</span>
                                </div>
                            </div>
                            <div className="only-mobile w-100">
                                <div className="d-flex flex-column align-items-center hiclasstv-coral-text">
                                    <span className="font-xsm">사용 중인 아이디</span>
                                </div>
                            </div> */}
                            <div className="d-flex justify-content-center align-items-center hiclasstv-coral-text w-100">
                                <div className="only-web mr-1">
                                    <FiUserX className="font-lg" />
                                </div>
                                <div className="d-flex flex-column text-center">
                                    <span className="font-xsm">{t("사용_중인", { ns: "user" })}</span>
                                    <span className="font-xsm">{t("ID", { ns: "user" })}</span>
                                </div>
                            </div>
                        </> :
                        isJoinSuccess ?
                            <>
                                {/* <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                    <FiUserPlus className="font-lg hiclasstv-green-text" />
                                    <span className="font-sm hiclasstv-grey-text">가입 성공</span>
                                </div> */}
                                {/* <div className="only-web flex-column justify-content-center align-items-center w-100">
                                    <FiUserPlus className="font-lg hiclasstv-green-text" />
                                    <span className="font-sm hiclasstv-green-text">가입 성공</span>
                                </div>
                                <div className="only-mobile justify-content-center align-items-center w-100">
                                    <span className="font-xsm hiclasstv-green-text">가입 성공</span>
                                </div> */}
                                <div className="d-flex justify-content-center align-items-center hiclasstv-green-text w-100">
                                    <div className="only-web mr-1">
                                        <FiUserPlus className="font-lg" />
                                    </div>
                                    <div className="d-flex flex-column text-center">
                                        <span className="font-xsm">{t("가입성공", { ns: "user" })}</span>
                                    </div>
                                </div>
                            </> :
                            <>
                               {/*  <div className="d-flex flex-column justify-content-center align-items-center w-100">
                                    <FiUserCheck className={(isIdCheckOK && !isExist) ? "font-lg hiclasstv-green-text" : "font-lg hiclasstv-grey-text"} />
                                    <span className="font-sm hiclasstv-grey-text">{(isIdCheckOK && !isExist) ? "사용 가능한 아이디" : ""}</span>
                                </div> */}
                                {/* <div className="only-web flex-column justify-content-center align-items-center w-100">
                                    <FiUserCheck className={(isIdCheckOK && !isExist) ? "font-lg hiclasstv-green-text" : "font-lg hiclasstv-grey-text"} />
                                    <span className={"font-sm " + ((isIdCheckOK && !isExist) ? "hiclasstv-green-text" : "hiclasstv-grey-text")}>
                                        {(isIdCheckOK && !isExist) ? "사용 가능한 아이디" : ""}
                                    </span>
                                </div> */}
                                {/* <div className="only-web justify-content-center w-100">
                                    {
                                        (isIdCheckOK && !isExist) ?
                                            <div className="d-flex flex-column align-items-center hiclasstv-green-text">
                                                <FiUserCheck className="font-lg" />
                                                <span className="font-sm">사용 가능한</span>
                                                <span className="font-sm">아이디</span>
                                            </div> :
                                            <div className="d-flex flex-column align-items-center hiclasstv-grey-text">
                                                <FiUserCheck className="font-lg" />
                                            </div>
                                    }
                                </div>
                                <div className="only-mobile justify-content-center align-items-center w-100">
                                    <span className={"font-xsm " + ((isIdCheckOK && !isExist) ? "hiclasstv-green-text" : "hiclasstv-grey-text")}>
                                        {(isIdCheckOK && !isExist) ? "사용 가능한 아이디" : ""}
                                    </span>
                                </div> */}
                                <div className={"d-flex justify-content-center align-items-center w-100" + ((isIdCheckOK && !isExist) ? " hiclasstv-green-text" : " hiclasstv-grey-text")}>
                                    <div className="only-web mr-1">
                                        <FiUserCheck className="font-lg" />
                                    </div>
                                    <div className="d-flex flex-column text-center">
                                        {
                                            (isIdCheckOK && !isExist) ? 
                                            <>
                                                <span className="font-xsm">{t("사용_가능한", { ns: "user" })}</span>
                                                <span className="font-xsm">{t("ID", { ns: "user" })}</span>
                                            </>:
                                            <></>
                                        }
                                    </div>
                                </div>
                            </>
                }
            </div>
            {/* <input type="email" id="inputUserID" className="mr-2" placeholder="사용자 아이디를 입력하세요." style={{ width: '20%' }} value={id} onChange={onChangeId} />
            <input type="password" id="inputUserPW" className="mr-2" placeholder="사용자 비밀번호를 입력하세요." style={{ width: '20%' }} value={password} onChange={onChangePassword} />
            <input type="text" id="inputUserNickname" className="mr-2" placeholder="사용자 닉네임을 입력하세요." style={{ width: '25%' }} value={nickname} onChange={onChangeNickname} />
            <input type="text" id="inputUserProfile" className="mr-2" placeholder="사용자 프로필을 입력하세요." style={{ width: '30%' }} value={profile} onChange={onChangeProfile} /> */}
        </div>
    );
});

export default WebCreateUserInfo;
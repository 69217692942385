import React from "react";
import { useTranslation } from "react-i18next";

const WebLiveJoinButton = ({ handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        <button type="button" className="btn btn-sm hiclasstv-yellow white-text m-0 py-1 px-2" title={t("입장하기", { ns: "live" })} onClick={handleSelectedFunc}>
            <b>{t("입장", { ns: "live" })}</b>
        </button>
    )
}

export default WebLiveJoinButton;
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";

const WebHiClassInfoEdutech = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">

                    <div className="d-flex w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span>HiClassTV&nbsp;</span>
                            <span className="hiclasstv-blue-text">Pentalk</span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온챗_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnChat</span>
                        </div>
                        <div className="d-flex flex-column my-3">
                            <span className="d-flex hiclasstv-darkblue-text mt-3" style={{ fontSize: '1.2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온챗_설명_1", { ns: "info" })}
                            </span>
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_온챗_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온챗_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온챗_설명_4", { ns: "info" })}</span>
                            </span>
                        </div>
                        <div className="d-flex flex-column mb-5">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '1.2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온챗_설명_5", { ns: "info" })}
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onChat.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온노트_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnNote</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_온노트_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온노트_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온노트_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온노트_설명_4", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onNote.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_팀업_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">TeamUp</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_팀업_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_팀업_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_팀업_설명_3", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/teamUp.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_클래스_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">{t("인포_에듀텍_클래스_타이틀_2", { ns: "info" })}</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_클래스_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_클래스_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_클래스_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_클래스_설명_4", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_클래스_설명_5", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/handsUp.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>


                    <div className="d-flex w-100 mt-5">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span>HiClass&nbsp;</span>
                            <span className="hiclasstv-blue-text">Pencam</span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온에어_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnAir</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_4", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_5", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_6", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_7", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_8", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_9", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_10", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온에어_설명_11", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onAir.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온미디어_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnMedia</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_온미디어_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온미디어_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온미디어_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온미디어_설명_4", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온미디어_설명_5", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex mt-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onMedia.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>

                    <div className="d-flex flex-column w-100 my-4">
                        <div className="d-flex my-2">
                            <span className="d-flex hiclasstv-darkblue-text" style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                {t("인포_에듀텍_온보드_타이틀_1", { ns: "info" })}
                            </span>
                        </div>
                        <div className="d-flex flex-wrap" style={{ fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-coral-text">OnBoard</span>
                        </div>
                        <div className="d-flex flex-column mt-3 mb-5">
                            <span className="d-flex flex-column my-3" style={{ fontSize: '1rem' }}>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_1", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_2", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_3", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_4", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_5", { ns: "info" })}</span>
                                <span className="my-1">{t("인포_에듀텍_온보드_설명_6", { ns: "info" })}</span>
                            </span>
                            <span className="d-flex my-3 w-100 h-100 align-items-center justify-content-center">
                                <img src="/images/info/edu/onBoard.png" className="w-100 h-100" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoEdutech;
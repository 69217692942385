import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { stAttachmentType, stQuizDataType } from "../../../../lib/ConstCommand";

const Video = ({ type, data, title }) => {
    const { t } = useTranslation();

    const videoRef = useRef(null);

    useEffect(() => {
        return () => {
            if (videoRef && videoRef.current) {
                if (!videoRef.current.paused) {
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                }

                if (videoRef.current.src && videoRef.current.src !== "") {
                    URL.revokeObjectURL(videoRef.current.src);
                    videoRef.current.src = "";
                }

                videoRef.current = null;
            }
        }
    }, []);

    switch (type) {
        case stAttachmentType.Iframe:
        case stQuizDataType.Iframe:
            return (
                <iframe src={data} title={title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                    <p>{t("아이프레임_미지원_메시지", { ns: "classTalk" })}</p>
                </iframe>
            );

        case stAttachmentType.Object:
        case stQuizDataType.Object:
            return (
                <object className="video-item" type="text/html" data={data} title={title}>
                    <p>{t("오브젝트_미지원_메시지", { ns: "classTalk" })}</p>
                </object>
            );

        case stAttachmentType.Embed:
        case stQuizDataType.Embed:
            return <embed className="video-item" type="text/html" src={data} title={title} />;

        default:
            return <video ref={videoRef} className="video-item" src={data} title={title} controlsList="nodownload" controls />;
    }
}

const VideoItem = ({ video, title }) => {
    const { t } = useTranslation();

    const [videoInfo, setVideoInfo] = useState(null);
    const isMount = useRef(false);

    const videoRef = useRef(null);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (videoRef && videoRef.current) {
                if (!videoRef.current.paused) {
                    videoRef.current.pause();
                    videoRef.current.currentTime = 0;
                }

                if (videoRef.current.src && videoRef.current.src !== "") {
                    URL.revokeObjectURL(videoRef.current.src);
                    videoRef.current.src = "";
                }

                videoRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (video !== undefined && video !== null) {
            if (video.data !== undefined && video.data !== null) {
                if (video.type === stAttachmentType.URL || video.type === stQuizDataType.URL) {
                    (async function fetchData() {
                        try {
                            const res = await fetch(video.data);
                            const blob = await res.blob();
                            if (isMount.current) {
                                let objURL = URL.createObjectURL(blob);

                                if (videoRef && videoRef.current) {
                                    videoRef.current.src = objURL;
                                    // videoRef.current.onload = function () {
                                    //     //cleanup.
                                    //     URL.revokeObjectURL(objURL);
                                    // }
                                }
                                setVideoInfo({ type: video.type, data: objURL, title });
                            }
                        } catch (err) {
                            console.log("fetchData(VideoItem) - err => ", err);
                        }
                    }());
                } else if (video.type === stAttachmentType.Iframe || video.type === stQuizDataType.Iframe) {
                    if (isMount.current) {
                        setVideoInfo({ type: video.type, data: video.data, title });
                    }
                } else if (video.type === stAttachmentType.Object || video.type === stQuizDataType.Object) {
                    if (isMount.current) {
                        setVideoInfo({ type: video.type, data: video.data, title });
                    }
                } else if (video.type === stAttachmentType.Embed || video.type === stQuizDataType.Embed) {
                    if (isMount.current) {
                        setVideoInfo({ type: video.type, data: video.data, title });
                    }
                } else {
                    let byteString = atob(video.data.split(",")[1]);
                    let mimeString = video.data.split(",")[0].split(":")[1].split(";")[0];
                    let ab = new ArrayBuffer(byteString.length);
                    let ia = new Uint8Array(ab);

                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }

                    let blob = new Blob([ab], { type: mimeString });
                    if (isMount.current) {
                        let objURL = URL.createObjectURL(blob);

                        if (videoRef && videoRef.current) {
                            videoRef.current.src = objURL;
                            // videoRef.current.onload = function () {
                            //     //cleanup.
                            //     URL.revokeObjectURL(objURL);
                            // }
                        }
                        setVideoInfo({ type: video.type, data: objURL, title });
                    }
                }
            }
        }
    }, [video, title]);

    if (videoInfo && videoInfo.data !== undefined && videoInfo.data !== null) {
        switch (videoInfo.type) {
            case stAttachmentType.URL:
            case stQuizDataType.URL:
                return <video ref={videoRef} className="video-item" type="item" src={videoInfo.data} title={videoInfo.title} controlsList="nodownload" controls />;

            case stAttachmentType.Iframe:
            case stQuizDataType.Iframe:
                return (
                    <iframe src={videoInfo.data} title={videoInfo.title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                        <p>{t("아이프레임_미지원_메시지", { ns: "classTalk" })}</p>
                    </iframe>
                );

            case stAttachmentType.Object:
            case stQuizDataType.Object:
                return (
                    <object className="video-item" type="text/html" data={videoInfo.data} title={videoInfo.title}>
                        <p>{t("오브젝트_미지원_메시지", { ns: "classTalk" })}</p>
                    </object>
                );

            case stAttachmentType.Embed:
            case stQuizDataType.Embed:
                return <embed className="video-item" type="text/html" src={videoInfo.data} title={videoInfo.title} />;

            default:
                return <video ref={videoRef} className="video-item" type="item" src={videoInfo.data} title={videoInfo.title} controlsList="nodownload" controls />;
        }
    } else {
        return <></>;
    }
}

export default VideoItem;
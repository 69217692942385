import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_owner_studio_info = ({ userSeq }) => {
    return axiosClient.get(`/studio?kind=manage-owner&userSEQ=${userSeq}`, options);
};

export const get_admin_studio_info = ({ subDomain, subDomainSeq, userSeq }) => {
    // admin 이 보는 studio 목록에서는 삭제 된 것도 볼 수 있어야 한다 (추후에 검색 조건에 반영될 예정)
    let url = `/studio?kind=manage-admin`;

    if (userSeq && userSeq >= 0) {
        url += `&userSEQ=${userSeq}`;
    }

    if (subDomainSeq && subDomainSeq >= 0) {
        url += `&subDomainSEQ=${subDomainSeq}`;
    } else if (subDomain !== undefined && subDomain !== null) {
        url += `&subDomain=${subDomain}`;
    }

    return axiosClient.get(url, options);
};

export const create_studio = ({ userSeq, studioName, subDomainSeq, groupLimit }) => {
    if (subDomainSeq === 1 && userSeq && userSeq >= 0) { // 개인에게 스튜디오 등록
        return axiosClient.post(`/studio?kind=add`, {
            studioName,
            studioOwner: userSeq,
            studioGroupLimit: groupLimit,
            subDomainSEQ: subDomainSeq
        }, options);
    } else { // 서브 도메인에 스튜디오 등록
        return axiosClient.post(`/studio?kind=add`, {
            studioName,
            studioGroupLimit: groupLimit,
            subDomainSEQ: subDomainSeq
        }, options);
    }
};

export const remove_studio = ({ studioSeq, studioOwner }) => {
    //console.log('remove studio - ', studioSeq, studioOwner);
    return axiosClient.delete(`/studio/${studioSeq}/${studioOwner}?kind=remove-studio`, options);
};

export const modify_studio_name = ({ studioSeq, studioName }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=modify-name`, {
        studioName,
        //currentUserSEQ : null,
        //currentLiveSEQ : null
    }, options);
};

export const modify_studio_group_limit = ({ studioSeq, groupLimit }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=modify-group-limit`, {
        studioGroupLimit: groupLimit,
        //currentUserSEQ : null,
        //currentLiveSEQ : null
    }, options);
};

export const update_studio_user_info = ({ studioSeq, userSeq }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=update-user`, {
        currentUserSEQ: userSeq,
        //currentLiveSEQ : null
    }, options);
};

export const update_studio_live_info = ({ studioSeq, liveSeq }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=update-live`, {
        //currentUserSEQ : null,
        currentLiveSEQ: liveSeq
    }, options);
};

/** studio 연결 요청 */
export const request_studio_assign_user = ({ studioSeq, userSeq }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=request-user`, {
        currentUserSEQ: userSeq
    }, options);
};

/** studio 연결 요청 취소 */
export const cancel_reqeust_studio_assign_user = ({ studioSeq, userSeq }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=request-cancel`, {
        currentUserSEQ: userSeq
    }, options);
};

/** studio 연결 해제 */
export const clear_studio_assign_user = ({ studioSeq, userSeq }) => {
    return axiosClient.put(`/studio/${studioSeq}?kind=assign-user`, {
        currentUserSEQ: null
    }, options);
};

/** display */
export const create_display_info = ({ userSeq, subDomainSeq, studioSeq }) => {
    let data = { subDomainSEQ: subDomainSeq };
    let kind = studioSeq === undefined || studioSeq === null ? 'init-display' : 'add-display';

    if (userSeq !== undefined && userSeq !== null) {
        data.displayOwner = userSeq;
    }

    if (studioSeq !== undefined && studioSeq !== null) {
        data.studioSEQ = studioSeq;
    }

    return axiosClient.post(`/display?kind=${kind}`, data, options);
};

export const remove_display_info = ({ displaySeq }) => {
    //console.log('remove display - ', displaySeq);
    return axiosClient.delete(`/display/${displaySeq}?kind=remove-display`, options);
};

export const modify_display_info = ({ displaySeq, studioSeq, groupNum, subDomainSeq }) => {
    let data = {};

    if (studioSeq !== undefined) {
        data.studioSEQ = studioSeq;
    }

    if (groupNum !== undefined) {
        data.displayCurrentGroup = groupNum;
    }

    if (subDomainSeq !== undefined) {
        data.subDomainSEQ = subDomainSeq;
    }

    return axiosClient.put(`/display/${displaySeq}?kind=modify-info`, data, options);
};

export const modify_display_group = ({ displaySeq, groupNum }) => {
    return axiosClient.put(`/display/${displaySeq}?kind=modify-group`, {
        //studioSEQ : null,
        displayCurrentGroup: groupNum
    }, options);
};

export const modify_display_sub_domain = ({ displaySeq, subDomainSeq }) => {
    return axiosClient.put(`/display/${displaySeq}?kind=modify-sub-domain`, {
        //studioSEQ : null,
        subDomainSEQ: subDomainSeq
    }, options);
};

export const add_display_to_studio = ({ displaySeq, studioSeq }) => {
    return axiosClient.put(`/display/${displaySeq}?kind=modify-studio`, {
        studioSEQ: studioSeq
    }, options);
};

export const remove_display_from_studio = ({ displaySeq }) => {
    return axiosClient.put(`/display/${displaySeq}?kind=remove-studio`, {
        studioSEQ: null
    }, options);
};
import React from "react";
// import pentalkIcon from "../../../images/hiclasstvIcon.png";

const AlertMessage = ({ msgInfo }) => {
    return (
        <div className="d-flex flex-column justify-content-center w-100 py-1 px-2">
            <div className="d-flex justify-content-start">
                <img className="ct-profile-img" src="/images/hiclasstvIcon.png" alt="hiclasstvIcon.png" />
                <div className="d-flex flex-column w-100">
                    <span className="ct-profile-nickname" title={msgInfo.rcvName}>
                        {msgInfo.rcvName}
                    </span>
                    <div>
                        {
                            msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                            <span className="ct-bubble bubble-msg msg-rcv msg-bg-indigo-btext">
                                {msgInfo.text}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
        /* <div className="d-flex hiclasstv-classtalk-alert-action">
            <span className="d-flex ct-bubble bubble-alert">
                {msgText}
            </span>
        </div> */
    );
}

export default AlertMessage;
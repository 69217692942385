import React from 'react';
import { useTranslation } from "react-i18next";
import { FaCog } from 'react-icons/fa';
import { remoconType } from '../../../lib/classTalk/CodeEnum';

const RemoconTitleTemplate = ({ viewCMD, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const configAudioSpeakerDevice = () => {
        handleSelectedFunc({selectedFunc:"OPEN_SOUND_SETTING"});
    }
    
    return (
        <div className="title-area main justify-content-between white-text" style={{ backgroundColor: '#2874ff' }}>
            <b>{t("리모콘", { ns: "hiclasstv" })}</b>
            <div className="func-area right">
                { // sound control mode 가 아니어도 음향기기 설정 버튼이 보이도록 수정 by hjkim 20230503
                    // viewCMD === remoconType.SoundControl &&
                    <button type="button" title={t("음향기기_설정", { ns: "hiclasstv" })} onClick={configAudioSpeakerDevice}>
                        <FaCog className="d-flex font-md white-text" />
                    </button>
                }
            </div>
        </div>
    )
}
export default RemoconTitleTemplate;
import React, { useState } from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const EditChatRoom = ({ selectedChatRoomInfo, handleSelectedConfigFunc }) => {
    const { t } = useTranslation();

    const { chatRoomSeq, chatRoomName } = selectedChatRoomInfo;

    const [lChatRoomName, setChatRoomName] = useState(chatRoomName);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeInputChatRoomName = (e) => {
        setChatRoomName(e.target.value);
    }

    const saveChatroomName = () => {
        if (lChatRoomName === "") {
            handleSelectedConfigFunc({ selectedFunc: "SAVE", chatRoomSeq: chatRoomSeq, chatRoomName: chatRoomName });
        } else {
            handleSelectedConfigFunc({ selectedFunc: "SAVE", chatRoomSeq: chatRoomSeq, chatRoomName: lChatRoomName });
        }
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span title={t("팀업_이름_수정", { ns: "chat" })}>
                    <b>{t("팀업_이름_수정", { ns: "chat" })}</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: '0' }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="input-area mt-2">
                <input type="text" title={`${t("변경_전_이름", { ns: "chat" })} : ` + chatRoomName} placeholder={chatRoomName} onChange={onChangeInputChatRoomName} />
            </div>
            <div className="d-flex justify-content-center align-items-center my-2">
                <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 py-1 px-2" title={t("수정", { ns: "common" })} onClick={() => saveChatroomName()}>
                    {t("수정", { ns: "common" })}
                </button>
            </div>
        </div>
    );
}

export default EditChatRoom;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Modal, UncontrolledCollapse } from 'reactstrap';
import { FaCheck, FaGift } from 'react-icons/fa';
import { registPromotionCouponCode } from '../../../modules/promotion';

const WebPromotionInfoModal = ({ isModalOpened, isLoginYn, selectedPromotion, isCheckedRegistCouponCode, isSuccessedRegistCoupon, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lItemArr, setItemArr] = useState([]);
    const [inputEmail, setInputEmail] = useState("");
    const [inputLicense, setInputLicense] = useState("");
    const [isValidateFailed, setIsValidateFailed] = useState(false);

    //const [lIsSuccessedRegistCoupon, setIsSuccessedRegistCoupon] = useState(isSuccessedRegistCoupon);
    const [couponCode, setCouponCode] = useState("");

    useEffect(() => {
        if (selectedPromotion.list_item !== undefined && selectedPromotion.list_item !== null) {
            const listArr = selectedPromotion.list_item.map(item => ({
                ...item,
                id      : "item" + item.itemSeq,
                value   : "",
                isWrong : true
            }));
            console.log(listArr);
            setItemArr(listArr);
        }

        if (selectedPromotion.isRequiredEmailYN === "Y") {
            setInputEmail({
                id      : "item-email", 
                value   : "", 
                isWrong : true
            });
        }

        if (selectedPromotion.isRequiredLicenseYN === "Y") {
            setInputLicense({
                id      : "item-license",
                value   : "",
                isWrong : true
            });
        }
    }, [selectedPromotion]);

    
    // 프로모션 쿠폰 코드 관련 //
    useEffect(() => {
        if (isSuccessedRegistCoupon === true) {
            setCouponCode("");
        }
    }, [isSuccessedRegistCoupon]);

    const onChangeInputCouponCode = (e) => {
        if (isLoginYn === "Y") {
            handleSelectedFunc({selectedFunc:"SHOW_LOGIN_WINDOW"});
        } else {
            setCouponCode(e.target.value);
        }
    }

    const validateCouponCode = () => {
        if (couponCode !== "") {
            handleSelectedFunc({selectedFunc:"REGIST_PROMOTION_COUPON_CODE", promotionSeq:selectedPromotion.promotionSeq, kind:"coupon", info:{couponCode:couponCode}});
        }
    }
    /////////////////////////////


    const onChangeInputItem = (e) => {
        setIsValidateFailed(false);

        if (lItemArr && lItemArr.length > 0) {
            let itemArr = lItemArr.map(element => {
                let value = element.id === e.target.id ? {
                    ...element,
                    value : e.target.value,
                } : element;

                return value;
                /*
                if (element.id === e.target.id) {
                    element.value = e.target.value;
                    console.log("id [" + e.target.id + "] ------ [" + e.target.value + "]");
                }*/
            });

            setItemArr(itemArr);
        }
    }

    const onChangeInputEmail = (e) => {
        setIsValidateFailed(false);

        setInputEmail({
            ...inputEmail,
            value : e.target.value
        });
    }

    const onChangeInputLicense = (e) => {
        setIsValidateFailed(false);

        setInputLicense({
            ...inputLicense,
            value : e.target.value
        });
    }

    const validateInputInfo = () => {
        let validate = false;
        let itemArr = [];
        let inputInfoArr = [];

        if (lItemArr && lItemArr.length > 0) {
            itemArr = lItemArr.map(element => {
                let value;

                if (element.value === "" || element.value === undefined || element.value === null) {
                    validate = true;
                    value = {
                        ...element,
                        isWrong : true,
                    }
                } else {
                    value = {
                        ...element,
                        isWrong : false
                    };

                    inputInfoArr.push({itemSeq:element.itemSeq, value:element.value});
                }

                return value;
            });

            setItemArr(itemArr);
        }

        if (selectedPromotion.isRequiredEmailYN === "Y") {
            console.log(inputEmail);

            if (inputEmail.value === "" || inputEmail.value === undefined || inputEmail.value === null) {
                validate = true;

                setInputEmail({
                    ...inputEmail,
                    isWrong : true
                });
            } else {
                setInputEmail({
                    ...inputEmail,
                    isWrong : false
                })
            }
        }

        if (selectedPromotion.isRequiredLicenseYN === "Y") {
            if (inputLicense.value === "" || inputLicense.value === undefined || inputLicense.value === null) {
                validate = true;

                setInputLicense({
                    ...inputLicense,
                    isWrong : true
                });
            } else {
                setInputLicense({
                    ...inputLicense,
                    isWrong : false
                });
            }
        }

        if (validate === false) {
            setIsValidateFailed(false);

            let itemInforsList = [];

            lItemArr.forEach(
                item => {
                    itemInforsList.push(item.value);
                }
            )
            console.log(itemInforsList);

            handleSelectedFunc({selectedFunc:"REGIST_PROMOTION", promotionSeq:selectedPromotion.promotionSeq, info:{list_item:itemInforsList, email:inputEmail.value, license:inputLicense.value}});

        } else {
            setIsValidateFailed(true);
        }
    }

    return(
        <Modal size="lg" isOpen={isModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="d-flex flex-column justiyf-content-center align-items-center m-0 p-0">
                        <div className="d-flex flex-column justify-content-center align-items-center mb-2 p-0 dark-grey-text w-100" title={selectedPromotion.promotionName}>
                            <br />
                            <span style={{fontSize:'1.5rem', fontWeight:'bold'}}>
                                {selectedPromotion.promotionTitle}
                            </span>
                            <br />
                            <span style={{fontSize:'1.2rem'}}>
                                -&nbsp;{selectedPromotion.promotionPassName}&nbsp;-
                            </span>
                            <br />
                            {
                                selectedPromotion.promotionContents !== undefined && selectedPromotion.promotionContents !== null && selectedPromotion.promotionContents !== "" &&
                                <>
                                    <br />
                                    <span>
                                        <center>{selectedPromotion.promotionContents}</center>
                                    </span>
                                    <br />
                                </>
                            }                            
                            {
                                selectedPromotion.isHasCouponYN === "Y" && isLoginYn === "Y" &&
                                <div className="d-flex flex-column justify-content-center align-items-center my-2 w-100">
                                    <span className="d-flex my-1 small">{t("프로모션_안내_문구_1", { ns: "promotion" })}<u>{t("프로모션_안내_문구_2", { ns: "promotion" })}</u>{t("프로모션_안내_문구_3", { ns: "promotion" })}</span>
                                    <span className="d-flex my-1">▼▼▼▼▼▼▼▼▼▼</span>
                                    <span className="d-flex my-1 button" id="registCodeToggler"><u><b>{t("프로모션_안내_문구_4", { ns: "promotion" })}</b></u></span>
                                    <div className="d-flex w-100 justify-content-center">
                                        <UncontrolledCollapse className="w-100 align-items-center justify-content-center border-bottom" toggler="#registCodeToggler" defaultOpen={false}>
                                            <div className="d-flex w-100 align-items-center justify-content-center my-3 align-items-center md-form form-sm">
                                                <input type="text" id="inputCouponCode" className="w-50 form-control m-0 white" onChange={onChangeInputCouponCode} value={couponCode} />
                                                <button type="button" className="d-flex btn hiclasstv-blue white-text p-2" style={{minWidth:'max-content'}} onClick={() => validateCouponCode()}>{t("프로모션_안내_문구_5", { ns: "promotion" })}</button>
                                            </div>
                                            {
                                                isCheckedRegistCouponCode &&
                                                <div className="d-flex w-100 align-items-center justify-content-center mb-2">
                                                    {
                                                        isSuccessedRegistCoupon ?
                                                            <div className="d-flex w-75 align-items-center justify-content-center alert alert-success" role="alert">
                                                                <span>{t("프로모션_안내_문구_6", { ns: "promotion" })}</span>
                                                            </div> :
                                                            <div className="d-flex w-75 align-items-center justify-content-center alert alert-danger" role="alert">
                                                                <span>{t("프로모션_안내_문구_7", { ns: "promotion" })}</span>
                                                            </div>
                                                    }
                                                </div>
                                            }
                                        </UncontrolledCollapse>
                                    </div>                                
                                </div>
                            }
                        </div>
                        {
                            lItemArr.length > 0 ?
                                <div className="d-flex flex-column w-100 md-form form-sm">
                                    {
                                        lItemArr.map(
                                            item => (
                                                <div className="d-flex align-items-center my-2" key={item.itemSeq}>
                                                    <span className="mr-2" style={{ width: '30%' }}>ㆍ{item.itemName}</span>
                                                    <input type="text" id={"item" + item.itemSeq} className="form-control form-control-sm white" onChange={onChangeInputItem} value={item.value} />
                                                    {
                                                        isValidateFailed &&
                                                        <span className={"ml-2" + (isValidateFailed && item.isWrong ? " red-text" : " green-text")}><FaCheck className="small" /></span>
                                                    }
                                                </div>
                                            )
                                        )
                                    } {
                                        selectedPromotion.isRequiredEmailYN === "Y" &&
                                        <div className="d-flex align-items-center my-2">
                                            <span className="mr-2" style={{ width: '30%' }}>ㆍE-mail</span>
                                            <input type="text" id="item-email" className="form-control form-control-sm white" onChange={onChangeInputEmail} value={inputEmail.value} />
                                            {
                                                isValidateFailed &&
                                                <span className={"ml-2" + (isValidateFailed && inputEmail.isWrong ? " red-text" : " green-text")}><FaCheck className="small" /></span>
                                            }
                                        </div>
                                    } {
                                        selectedPromotion.isRequiredLicenseYN === "Y" &&
                                        <div className="d-flex align-items-center my-2">
                                            <span className="mr-2" style={{ width: '30%' }}>ㆍLicense</span>
                                            <input type="text" id="item-license" className="form-control form-control-sm white" onChange={onChangeInputLicense} value={inputLicense.value} />
                                            {
                                                isValidateFailed &&
                                                <span className={"ml-2" + (isValidateFailed && inputLicense.isWrong ? " red-text" : " green-text")}><FaCheck className="small" /></span>
                                            }
                                        </div>
                                    }
                                </div> :
                                <></>
                        }
                        {
                            isValidateFailed &&
                            <div className="w-100 text-center my-1">
                                <div className="alert alert-danger" role="alert">{t("프로모션_안내_문구_8", { ns: "promotion" })}</div>
                            </div>
                        }                        
                        <div className="d-flex justify-content-center m-0 my-2 p-0">
                            <button type="button" className="btn grey white-text" onClick={() => handleSelectedFunc({selectedFunc:"TOGGLE_MODAL_CLOSED"})}>
                                {t("취소", { ns: "common" })}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={() => validateInputInfo()}>
                                {t("취소", { ns: "common" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebPromotionInfoModal;
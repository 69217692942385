import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const WebSubDomainConfigAdminUserAlertModal = ({ isModalOpened, selectedMemberInfo, toggleModalOpened, onClickAdminIcon, alertKind, alertMessage }) => {
    const { t } = useTranslation();

    const [memberInfo, setMemberInfo] = useState(selectedMemberInfo);
    //const [lAlertMessage, setAlertMessage] = useState(alertMessage);
    //const [lAlertKind, setAlertKind] = useState(alertKind);

    useEffect(() => {
        setMemberInfo(selectedMemberInfo);
    }, [selectedMemberInfo]);

    useEffect(() => {
        //setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        //setAlertMessage(alertMessage);
    }, [alertMessage]);

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>
                            {
                                memberInfo.isConfigAdmin ? 
                                    t("관리자_서브_도메인_권한_문구_3", { ns: "admin" }) : 
                                    t("관리자_서브_도메인_권한_문구_4", { ns: "admin" })
                            }
                        </span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <div className="d-flex flex-column align-items-center justify-content-center area-80 mb-3">
                            <span className="small mb-1">[{t("서브_도메인_이름", { ns: "admin" })}]</span>
                            <div className="text-area text-center">
                                <span className="hiclass-text" title={memberInfo.subDomainName}>
                                    <b>{memberInfo.subDomainName}</b>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center area-80">
                            <span className="small mb-1">[{t("관리자_서브_도메인_권한_문구_5", { ns: "admin" })}]</span>
                            <div className="d-flex align-items-center justify-content-center w-100">
                                <div className="area-15 fit-content">
                                    <CustomProfileImage url={memberInfo.profileImgUrl} style={{ width: '2rem', height:'2rem' }} alt={memberInfo.userNickname} />
                                </div>
                                <span className="hiclass-text" title={memberInfo.userNickname}>
                                    <b>{memberInfo.userNickname}</b>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <button type="button" className="btn red white-text mr-1 py-1 px-2" onClick={() => onClickAdminIcon(memberInfo.subDomainSeq, memberInfo)}>{t("예", { ns: "common" })}</button>
                        <button type="button" className="btn green white-text ml-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>{t("아니오", { ns: "common" })}</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebSubDomainConfigAdminUserAlertModal;
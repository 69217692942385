import React from 'react';

const CenteredModalInLive = ({ modalClose, children }) => {
    return(
        <div className="hiclasstv-modal centered web" onClick={() => modalClose({ selectedFunc: "NONE" })}>
            {children}
        </div>
    ) 
}

export default CenteredModalInLive;
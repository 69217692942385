import React from 'react';
import { useTranslation } from "react-i18next";

const ConfigChatListTitleTemplate = ({handleSelectedFunc}) => {
    const { t } = useTranslation();

    return(
        <div className="title-area main white-text" style={{ backgroundColor: '#2874ff' }}>
            <div className="d-flex align-items-center" style={{position:'absolute', left:'0'}} onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>
                <span className="button px-2"><b>{t("닫기", { ns: "common" })}</b></span>
            </div>
            <div className="d-flex align-items-center">
                <b>{t("팀업_관리", { ns: "chat" })}</b>
            </div>
            <div />
        </div>
    );
}

export default ConfigChatListTitleTemplate;
import React, { useRef, useEffect, useLayoutEffect, useState, useCallback } from 'react';

import ConstData from '../../../lib/ConstData';
import { enTokenCMD, enDrawingMode } from '../../../lib/classTalk/CodeEnum';

import { initImageSrc } from '../../../lib/RabbitmqClient';

const createInkStrokeAnnot = (scale, path, context) => {
    //alert('create ink stroke');
    let cur_annot = {};
    cur_annot.path = path;

    let drawingAttributes = path.stroke.DrawingAttributes;
    let offsetX = Math.round(path.minPoints.x - (drawingAttributes.lineWidth / 2));
    let offsetY = Math.round(path.minPoints.y - (drawingAttributes.lineWidth / 2));
    let boundWidth = Math.round((path.maxPoints.x - path.minPoints.x) + drawingAttributes.lineWidth);
    let boundHeight = Math.round((path.maxPoints.y - path.minPoints.y) + drawingAttributes.lineWidth);

    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;

    let offsetX_rate = Math.round(offsetX * scale.width);
    let offsetY_rate = Math.round(offsetY * scale.height);
    let boundWidth_rate = Math.round(boundWidth * scale.width);
    let boundHeight_rate = Math.round(boundHeight * scale.height);

    let cur_div = document.createElement('canvas');
    cur_div.style.position = 'absolute';
    cur_div.style.left = offsetX_rate + 'px';
    cur_div.style.top = offsetY_rate + 'px';
    cur_div.width = boundWidth_rate;
    cur_div.height = boundHeight_rate;
    cur_div.style.zIndex = 30;

    let imgData = context.getImageData(offsetX_rate, offsetY_rate, boundWidth_rate, boundHeight_rate);
    cur_annot.imgData = imgData;

    let cur_ctx = cur_div.getContext('2d');
    cur_ctx.putImageData(imgData, 0, 0);
    cur_annot.div = cur_div;

    let drawingCanvas = document.getElementById('canvas-area');
    drawingCanvas.appendChild(cur_div);

    context.clearRect(0, 0, context.canvas.width, context.canvas.height);

    return cur_annot;
}

const deleteInk = (annotList, startPosition, endPosition, callback) => {
    if (annotList === undefined || annotList === null) return;

    let cur_annotList = [];
    cur_annotList = annotList;

    let drawingCanvas = document.getElementById('canvas-area');

    annotList.forEach(
        (annot, index) => {
            let points = annot.path.stroke.StylusPoints;
            let lastIndex = 0;

            lastIndex = points.length - 1;
            if ((points[0].x === startPosition.x) && (points[0].y === startPosition.y) &&
                (points[lastIndex].x === endPosition.x) && (points[lastIndex].y === endPosition.y)) {
                drawingCanvas.removeChild(annot.div);
                cur_annotList.splice(index, 1);
            }
        }
    );

    return cur_annotList;
}

const LiveDrawingCanvas = ({ rcvPath, androidPenPath, rcvStroke, rcvStrokes, drawingMode, tipSize, tipColor, isClearInkStroke, handleSelectedDrawingToolFunc }) => {
    const { rcvCmd, rcvMode, rcvColor, rcvWidth, rcvPoint1, rcvPoint2 } = rcvPath;

    const annotList = useRef([]);

    const lDrawingMode = useRef(drawingMode);
    const lDrawingTipSize = useRef(tipSize);
    const lDrawingTipColor = useRef(tipColor);

    const imgData = useRef(null);
    const bgImgSize = useRef({ width: ConstData.SCREEN_RATE.WIDTH, height: ConstData.SCREEN_RATE.HEIGHT });

    const scale_x = useRef(1);
    const scale_y = useRef(1);

    const targetRef = useRef();
    const bgCanvasRef = useRef(null);

    const canvasRef = useRef(null);
    const mainCtxRef = useRef(null);
    const pathRef = useRef(null);

    const sCanvasRef = useRef(null);
    const sCtxRef = useRef(null);
    const serverPathRef = useRef(null);

    const rcvCanvasRef = useRef(null);
    const rcvCtxRef = useRef(null);

    const pCanvasRef = useRef(null);
    const pCtxRef = useRef(null);
    const penPathRef = useRef(null);

    const aCanvasRef = useRef(null);
    const aCtxRef = useRef(null);
    const aPenPathRef = useRef(null);

    useEffect(() => {
        initCanvasSize();
        onChangeCanvasSize();

        // isMobileDevice 체크가 의미 없었음. 항상 pointer 이벤트로 처리하게 수정 ... by ykhan 241108
        canvasRef.current.addEventListener("pointerdown", initDraw);
        canvasRef.current.addEventListener("pointermove", draw);
        canvasRef.current.addEventListener("pointerup", finishDraw);

        // drawing이 이상하게 동작한다면 여기를 보시오 ... by ykhan 241108
        // if (isMobileDevice) {
        //     canvasRef.current.addEventListener("pointerdown", initDraw);
        //     canvasRef.current.addEventListener("pointermove", draw);
        //     canvasRef.current.addEventListener("pointerup", finishDraw);
        // } else {
        //     canvasRef.current.addEventListener("mousedown", initDraw);
        //     canvasRef.current.addEventListener("mousemove", draw);
        //     canvasRef.current.addEventListener("mouseup", finishDraw);
        //     canvasRef.current.addEventListener("mouseout", finishDraw);
        // }

        window.addEventListener('resize', onChangeCanvasSize);
        window.addEventListener('message', onRcvExtensionMessage);

        return () => {
            window.removeEventListener('resize', onChangeCanvasSize);
            window.removeEventListener('message', onRcvExtensionMessage);
            document.removeEventListener('touchmove', preventBehavior, { passive: false });

            if (canvasRef.current) {
                canvasRef.current.removeEventListener("pointerdown", initDraw);
                canvasRef.current.removeEventListener("pointermove", draw);
                canvasRef.current.removeEventListener("pointerup", finishDraw);
            }

            canvasRef.current = null;
        }
    }, []);

    useEffect(() => {
        initImageSrc(getImgSrcData);
    }, [bgCanvasRef]);

    useEffect(() => {
        switch (rcvCmd) {
            case enTokenCMD.Ink_SDn:
                server_initDraw(rcvPoint1);
                break;

            case enTokenCMD.Ink_SM:
                server_draw(rcvPoint1);
                break;

            case enTokenCMD.Ink_SDM:
                console.log("double move - ", rcvPoint1, rcvPoint2);

                break;

            case enTokenCMD.Ink_SUp:
                server_finishDraw(rcvPoint1);
                break;

            case enTokenCMD.Ink_Erase1:
                server_eraseStroke(rcvPoint1, rcvPoint2);
                break;

            case enTokenCMD.Ink_Mode:
                //console.log("change mode - ", rcvMode);
                break;

            case enTokenCMD.Ink_Color:
                //console.log("change color - ", rcvColor);
                break;

            case enTokenCMD.Ink_Tip:
                //console.log("change width - ", rcvWidth);
                break;

            case enTokenCMD.rq_PageClear:
                initClearImageSrc();
                clear();
                break;

            default:
                break;
        }
    }, [rcvCmd, rcvPoint1, rcvPoint2]);

    useEffect(() => {
        lDrawingMode.current = drawingMode;
        lDrawingTipSize.current = tipSize;
        lDrawingTipColor.current = tipColor;

        let drawingCanvas = document.getElementById('canvas-area');

        if (lDrawingMode.current === enDrawingMode.Hand) {
            //drawingCanvas.style.overflowY = 'scroll';
            drawingCanvas.classList.remove('draw');
            document.removeEventListener('touchmove', preventBehavior, { passive: false });
        } else {
            //drawingCanvas.style.overflowY = 'hidden';
            if ((enDrawingMode.Pen <= drawingMode && drawingMode <= enDrawingMode.Marker) || drawingMode === enDrawingMode.EraseStroke) {
                drawingCanvas.classList.add('draw');
            }

            if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1 ||
                (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2)) {
                document.addEventListener('touchmove', preventBehavior, { passive: false });
            }
        }
    }, [drawingMode, tipSize, tipColor]);

    useEffect(() => {
        draw1Stroke(rcvStroke);
    }, [rcvStroke]);

    useEffect(() => {
        drawNStroke(rcvStrokes);
    }, [rcvStrokes]);

    useEffect(() => {
        //console.log('useEffect() - androidPenPath - ', androidPenPath);

        switch (androidPenPath.cmd) {
            case enTokenCMD.Ink_DDn:
                android_pen_initDraw(androidPenPath.point1);
                break;

            case enTokenCMD.Ink_DM:
                android_pen_draw(androidPenPath.point1);
                break;

            case enTokenCMD.Ink_DDM:
                android_pen_double_draw(androidPenPath.point1, androidPenPath.point2);
                break;

            case enTokenCMD.Ink_DUp:
                android_pen_finishDraw(androidPenPath.point1);
                break;

            default:
                break;
        }
    }, [androidPenPath.cmd, androidPenPath.point1, androidPenPath.point2]);

    useEffect(() => {
        if (isClearInkStroke) {
            clear();
        }
    }, [isClearInkStroke]);

    const isMobileDevice = () => {
        let isMobileDeviceFlag = false;

        if (navigator.userAgent.toLowerCase().indexOf('android') > -1) isMobileDeviceFlag = true;
        if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) isMobileDeviceFlag = true;
        if (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2) isMobileDeviceFlag = true;

        return isMobileDeviceFlag;
    }

    const preventBehavior = useCallback((e) => {
        e.preventDefault();
    }, []);

    const initCanvasSize = () => {
        bgCanvasRef.current.width = bgImgSize.current.width;
        bgCanvasRef.current.height = bgImgSize.current.height;

        canvasRef.current.width = bgImgSize.current.width;
        canvasRef.current.height = bgImgSize.current.height;

        sCanvasRef.current.width = bgImgSize.current.width;
        sCanvasRef.current.height = bgImgSize.current.height;

        aCanvasRef.current.width = bgImgSize.current.width;
        aCanvasRef.current.height = bgImgSize.current.height;

        pCanvasRef.current.width = bgImgSize.current.width;
        pCanvasRef.current.height = bgImgSize.current.height;

        rcvCanvasRef.current.width = bgImgSize.current.width;
        rcvCanvasRef.current.height = bgImgSize.current.height;
    }

    const onChangeCanvasSize = () => {
        let newWidth, newHeight;

        if (targetRef.current === undefined || targetRef.current === null) return;

        if (bgImgSize.current.width > bgImgSize.current.height) { //가로 문서
            scale_x.current = targetRef.current.offsetWidth / bgImgSize.current.width;
            scale_y.current = targetRef.current.offsetHeight / bgImgSize.current.height;
        } else {
            scale_x.current = targetRef.current.offsetWidth / bgImgSize.current.width;
            scale_y.current = scale_x.current;
        }

        if (bgImgSize.current.width > bgImgSize.current.height) { // 가로 문서
            newWidth = bgImgSize.current.width * scale_x.current;
            newHeight = bgImgSize.current.height * scale_y.current;
        } else {
            newWidth = bgImgSize.current.width * scale_x.current;
            newHeight = bgImgSize.current.height * scale_x.current;
        }

        bgCanvasRef.current.width = newWidth;
        bgCanvasRef.current.height = newHeight;

        canvasRef.current.width = newWidth;
        canvasRef.current.height = newHeight;

        sCanvasRef.current.width = newWidth;
        sCanvasRef.current.height = newHeight;

        aCanvasRef.current.width = newWidth;
        aCanvasRef.current.height = newHeight;

        pCanvasRef.current.width = newWidth;
        pCanvasRef.current.height = newHeight;

        rcvCanvasRef.current.width = newWidth;
        rcvCanvasRef.current.height = newHeight;

        onChangeBackgroundImage();
        onResizeAnnot();

        //handleSelectedDrawingToolFunc({ selectedFunc: "SET_OFFSET", data: { offset: { x: lOffsetX.current, y: lOffsetY.current } } });
    }

    const onChangeBackgroundImage = () => {
        
        if (imgData.current) {
            let ctx = bgCanvasRef.current.getContext('2d');

            console.log("onChangeBackgroundImage - imgData size :", imgData.current.width, imgData.current.height);
            console.log("onChangeBackgroundImage - bgCanvasRef size : ", bgCanvasRef.current.width, bgCanvasRef.current.height);

            // let img = new Image();
            // img.onload = () => {
            //     ctx.drawImage(img, 0, 0, bgCanvasRef.current.width, bgCanvasRef.current.height);
            // }
            // img.src = imgData.current;

            ctx.clearRect(0, 0, bgCanvasRef.current.width, bgCanvasRef.current.height);
            ctx.drawImage(imgData.current, 0, 0, bgCanvasRef.current.width, bgCanvasRef.current.height);
        }
    }

    const onResizeAnnot = () => {
        if (annotList.current === undefined || annotList.current === null || annotList.current.length === 0) return;

        let drawingCanvas = document.getElementById('canvas-area');

        let arr = [];
        let drawingAttributes = {};

        annotList.current.forEach(annot => {
            drawingCanvas.removeChild(annot.div);

            drawingAttributes = annot.path.stroke.DrawingAttributes;

            mainCtxRef.current = canvasRef.current.getContext('2d');
            mainCtxRef.current.lineWidth = drawingAttributes.lineWidth;
            mainCtxRef.current.lineJoin = 'round';
            mainCtxRef.current.lineCap = 'round';
            mainCtxRef.current.strokeStyle = 'rgba(' + drawingAttributes.Color.R + ', ' + drawingAttributes.Color.G + ', ' + drawingAttributes.Color.B + ', ' + drawingAttributes.Color.A + ')';
            mainCtxRef.current.fillStyle = 'rgba(' + drawingAttributes.Color.R + ', ' + drawingAttributes.Color.G + ', ' + drawingAttributes.Color.B + ', ' + drawingAttributes.Color.A + ')';

            onPaint(mainCtxRef.current, annot.path);

            let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, annot.path, mainCtxRef.current);
            arr.push(newAnnot);
        });

        annotList.current = arr;
    }

    const getImgSrcData = (imgSrc) => {
        imgData.current = imgSrc;
        console.log("getImgSrcData - imgSrc : ", imgSrc.width, imgSrc.height);
        if (imgSrc.width > imgSrc.height) {
            bgImgSize.current = { width: 1122, height: 793 };
        } else {
            bgImgSize.current = { width: 793, height: 1122 };
        }
        //bgImgSize.current = { width: imgSrc.width, height: imgSrc.height };

        onChangeCanvasSize();
    }

    const initClearImageSrc = () => {
        imgData.current = null;
        bgImgSize.current = { width: ConstData.SCREEN_RATE.WIDTH, height: ConstData.SCREEN_RATE.HEIGHT };

        let ctx = bgCanvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, bgCanvasRef.current.width, bgCanvasRef.current.height);

        ctx.fillStyle = "#fffdf5";
        ctx.fillRect(0, 0, bgCanvasRef.current.width, bgCanvasRef.current.height);

        onChangeCanvasSize();
    }

    const erase = (curPath, position) => {
        if (annotList.current.length < 1) return;

        let drawingCanvas = document.getElementById('canvas-area');

        let min = {
            x: curPath.preEraserPoint.x > position.x ? position.x : curPath.preEraserPoint.x,
            y: curPath.preEraserPoint.y > position.y ? position.y : curPath.preEraserPoint.y
        };

        let max = {
            x: curPath.preEraserPoint.x < position.x ? position.x : curPath.preEraserPoint.x,
            y: curPath.preEraserPoint.y < position.y ? position.y : curPath.preEraserPoint.y
        };

        let cur_annotList = [];

        annotList.current.forEach(
            (annot, index) => {
                let pointSet = annot.path.stroke.StylusPoints;
                let lineWidth = annot.path.stroke.DrawingAttributes.lineWidth / 2;
                let dx = 0, dy = 0, dist = 0;
                let check = true;

                for (let i = 0; i < pointSet.length; i++) {
                    dx = position.x - pointSet[i].x;
                    dy = position.y - pointSet[i].y;
                    dist = Math.sqrt(dx * dx + dy * dy);

                    if (dist < lineWidth) {
                        if (drawingCanvas.hasChildNodes(annot.div)) {
                            //console.log("clear 1");
                            drawingCanvas.removeChild(annot.div);
                            check = false;

                            cur_annotList.push(index);

                            break;
                        }
                    }
                }

                if (check) {
                    let sMin = annot.path.minPoints;
                    let sMax = annot.path.maxPoints;
                    let rect = null, imgData = null;
                    let length = 0, idx = 0;

                    if ((sMin.x <= max.x) && (sMin.y <= max.y) && (sMax.x >= min.x) && (sMax.y >= min.y)) {
                        rect = {
                            mn_x: Math.round((Math.max(min.x, sMin.x) - sMin.x) * scale_x.current),
                            mn_y: Math.round((Math.max(min.y, sMin.y) - sMin.y) * scale_y.current),
                            mx_x: Math.round((Math.min(max.x, sMax.x) - sMin.x) * scale_x.current),
                            mx_y: Math.round((Math.min(max.y, sMax.y) - sMin.y) * scale_y.current)
                        };

                        imgData = annot.div.getContext('2d').getImageData(rect.mn_x, rect.mn_y, (rect.mx_x - rect.mn_x) + 1, (rect.mx_y - rect.mn_y) + 1);
                        length = imgData.data.length;

                        while (check && idx < length) {
                            if (imgData.data[idx] !== 0) {
                                if (drawingCanvas.hasChildNodes(annot.div)) {
                                    drawingCanvas.removeChild(annot.div);
                                    check = false;

                                    //console.log('clear 2');
                                }
                            }

                            idx = idx + 1;
                        }

                        if (!check) {
                            cur_annotList.push(index);
                        }
                    }
                }
            }
        );

        let seq = cur_annotList.pop();

        while (seq !== undefined) {
            annotList.current.splice(seq, 1);
            seq = cur_annotList.pop();
        }
    }

    const clear = () => {
        if (mainCtxRef.current === undefined || mainCtxRef.current === null) mainCtxRef.current = canvasRef.current.getContext('2d');

        let drawingCanvas = document.getElementById('canvas-area');

        mainCtxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        annotList.current.forEach(annot => {
            drawingCanvas.removeChild(annot.div);
        });

        annotList.current = [];

        handleSelectedDrawingToolFunc({ selectedFunc: "CLEAR_SUCCESS" });
    }

    const onPaint = (ctx, curPath, position) => {
        let pointList = curPath.stroke.StylusPoints;

        if (position !== undefined && position !== null) {
            pointList.push(position);
        }

        ctx.save();
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        ctx.scale(scale_x.current, scale_y.current);
        //ctx.clip();

        if (pointList.length < 3) {
            ctx.beginPath();
            ctx.arc(pointList[0].x, pointList[0].y, ctx.lineWidth / 2, 0, Math.PI * 2, !0);
            ctx.fill();
            ctx.closePath();

            ctx.restore();

            return;
        }

        ctx.beginPath();
        ctx.moveTo(pointList[0].x, pointList[0].y);

        for (var i = 1; i < pointList.length - 2; i++) {
            var c = (pointList[i].x + pointList[i + 1].x) / 2;
            var d = (pointList[i].y + pointList[i + 1].y) / 2;

            ctx.quadraticCurveTo(pointList[i].x, pointList[i].y, c, d);
        }

        // For the last 2 points
        ctx.quadraticCurveTo(
            pointList[i].x,
            pointList[i].y,
            pointList[i + 1].x,
            pointList[i + 1].y
        );
        ctx.stroke();
        ctx.restore();
    }

    const initDraw = (e) => {
        if (lDrawingMode === enDrawingMode.Hand) return;

        let classtalk = document.getElementById("classtalk");
        if (classtalk) classtalk.style.zIndex = 30;

        let studentArea = document.getElementById("student-area");
        if (studentArea) studentArea.style.zIndex = 35;

        let teacherArea = document.getElementById("teacher-area");
        if (teacherArea) teacherArea.style.zIndex = 35;

        handleSelectedDrawingToolFunc({ selectedFunc: "SET_DRAWING_STATUS", data: { isDrawingInkStroke: true } });

        let offsetY = targetRef.current.scrollTop;
        if (offsetY === undefined || offsetY < 0) offsetY = 0;

        let pointX = 0, pointY = 0;

        /* original
        if (e.type === 'pointerdown') {
            pointX = e.x;
            pointY = offsetY + e.y;
        } else {
            pointX = e.offsetX;
            pointY = e.offsetY;
        }*/

        pointX = e.offsetX;
        pointY = e.offsetY;

        //console.log(e, pointX, pointY);

        let point = { x: Math.round((pointX / scale_x.current) * 10) / 10, y: Math.round((pointY / scale_y.current) * 10) / 10 };

        if (pathRef.current === null) {
            if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
                pathRef.current = {};
                pathRef.current.isDrawing = true;
                pathRef.current.id = e.pointerId;

                // drawing 시 쓰이는 컬러 rgb(0~255) / rgba(0~255, 0~1)
                let cur_color = { ...lDrawingTipColor.current, A: (lDrawingMode.current === enDrawingMode.Pen ? 1 : 0.5) };
                let cur_thick = (lDrawingMode.current === enDrawingMode.Pen ? lDrawingTipSize.current : (lDrawingTipSize.current * 2.5));

                pathRef.current.stroke = {};
                pathRef.current.stroke.DrawingAttributes = { Color: cur_color, lineWidth: cur_thick };
                pathRef.current.stroke.StylusPoints = [];

                mainCtxRef.current = canvasRef.current.getContext('2d');
                mainCtxRef.current.lineWidth = cur_thick;
                mainCtxRef.current.lineJoin = 'round';
                mainCtxRef.current.lineCap = 'round';
                mainCtxRef.current.strokeStyle = 'rgba(' + cur_color.R + ',' + cur_color.G + ',' + cur_color.B + ',' + cur_color.A + ')';
                mainCtxRef.current.fillStyle = 'rgba(' + cur_color.R + ',' + cur_color.G + ',' + cur_color.B + ',' + cur_color.A + ')';

                pathRef.current.stroke.StylusPoints.push({ x: point.x, y: point.y });
                pathRef.current.minPoints = { x: point.x, y: point.y };
                pathRef.current.maxPoints = { x: point.x, y: point.y };

                onPaint(mainCtxRef.current, pathRef.current, { x: point.x, y: point.y });
            } else if (lDrawingMode.current === enDrawingMode.EraseStroke) {
                //console.log(annotList);
                pathRef.current = {};
                pathRef.current.isDrawing = true;
                pathRef.current.id = e.pointerId;

                pathRef.current.preEraserPoint = point;
                erase(pathRef.current, point);
            }
        }
    }

    const draw = (e) => {
        if (pathRef.current && pathRef.current.isDrawing) {
            if (pathRef.current.id !== e.pointerId) return;

            let offsetY = targetRef.current.scrollTop;
            if (offsetY === undefined || offsetY < 0) offsetY = 0;

            let pointX = 0, pointY = 0;

            /* original
            if (e.type === 'pointermove') {
                pointX = e.x;
                pointY = offsetY + e.y;
            } else {
                pointX = e.offsetX;
                pointY = e.offsetY;
            }*/

            pointX = e.offsetX;
            pointY = e.offsetY;

            //console.log(e, pointX, pointY);

            let point = { x: Math.round((pointX / scale_x.current) * 10) / 10, y: Math.round((pointY / scale_y.current) * 10) / 10 };
            //let point = { x: pointX, y: pointY };

            if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
                if (pathRef.current.minPoints.x > point.x) pathRef.current.minPoints.x = point.x;
                if (pathRef.current.minPoints.y > point.y) pathRef.current.minPoints.y = point.y;

                let brushThick = mainCtxRef.current.lineWidth / 2;
                if (pointX < (canvasRef.current.width - brushThick) && pathRef.current.maxPoints.x < point.x) pathRef.current.maxPoints.x = point.x;
                if (pointY < (canvasRef.current.height - brushThick) && pathRef.current.maxPoints.y < point.y) pathRef.current.maxPoints.y = point.y;
                //if (pathRef.current.maxPoints.x < point.x) pathRef.current.maxPoints.x = point.x;
                //if (pathRef.current.maxPoints.y < point.y) pathRef.current.maxPoints.y = point.y;

                if (pointX < (canvasRef.current.width - brushThick) && pointY < (canvasRef.current.height - brushThick)) {
                    onPaint(mainCtxRef.current, pathRef.current, { x: point.x, y: point.y });
                }
            } else if (lDrawingMode.current === enDrawingMode.EraseStroke) {
                erase(pathRef.current, point);
                pathRef.current.preEraserPoint = point;
            }
        }
    }

    const finishDraw = (e) => {
        if (pathRef.current && pathRef.current.isDrawing) {
            if (pathRef.current.id !== e.pointerId) return;

            if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
                let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, pathRef.current, mainCtxRef.current);
                annotList.current.push(newAnnot);

                handleSelectedDrawingToolFunc({ selectedFunc: "SEND_STROKE", data: { StylusPoints: pathRef.current.stroke.StylusPoints, DrawingAttributes: pathRef.current.stroke.DrawingAttributes, mode: lDrawingMode.current } });

                pathRef.current.isDrawing = false;
                pathRef.current = null;
            } else if (lDrawingMode.current === enDrawingMode.EraseStroke) {
                draw(e);
                pathRef.current.isDrawing = false;
                pathRef.current = null;
            }
        }

        handleSelectedDrawingToolFunc({ selectedFunc: "SET_DRAWING_STATUS", data: { isDrawingInkStroke: false } });

        let classtalk = document.getElementById("classtalk");
        if (classtalk) classtalk.style.zIndex = 50;

        let studentArea = document.getElementById("student-area");
        if (studentArea) studentArea.style.zIndex = 45;

        let teacherArea = document.getElementById("teacher-area");
        if (teacherArea) teacherArea.style.zIndex = 45;
    }

    /**
     * Server Drawing Path
     * (Windows & Android connect bluetooth Pen)
     */
    const server_initDraw = (point) => {
        if (!serverPathRef.current) {
            if (!(rcvMode === enDrawingMode.Pen || rcvMode === enDrawingMode.Marker)) return;

            let color = { ...rcvColor, A: (rcvMode === enDrawingMode.Pen ? 1 : 0.5) };

            serverPathRef.current = {};
            serverPathRef.current.stroke = {};
            serverPathRef.current.stroke.DrawingAttributes = { Color: color, lineWidth: rcvWidth };
            serverPathRef.current.stroke.StylusPoints = [];

            // 만약 선생님과 동시에 그릴 때, 색상이 바껴버리면 컨텍스트 구분하기
            /*let canvas = document.createElement('canvas');
            canvas.style.position = 'absolute';
            canvas.style.top = 0;
            canvas.style.left = 0;
            canvas.width = canvasRef.current.width;
            canvas.height = canvasRef.current.height;
            
            let drawingCanvas = document.getElementById('serverCanvas');
            drawingCanvas.appendChild(canvas);
            */

            let context = sCanvasRef.current.getContext('2d');
            context.lineWidth = rcvWidth;
            context.lineJoin = 'round';
            context.lineCap = 'round';
            context.strokeStyle = 'rgba(' + color.R + ',' + color.G + ',' + color.B + ',' + color.A + ')';
            context.fillStyle = 'rgba(' + color.R + ',' + color.G + ',' + color.B + ',' + color.A + ')';

            serverPathRef.current.stroke.StylusPoints.push({ x: point.X, y: point.Y });
            serverPathRef.current.minPoints = { x: point.X, y: point.Y };
            serverPathRef.current.maxPoints = { x: point.X, y: point.Y };
            serverPathRef.current.canvas = sCanvasRef.current;

            onPaint(context, serverPathRef.current, { x: point.X, y: point.Y });
        }
    }

    const server_draw = (point) => {
        if (serverPathRef.current === undefined || serverPathRef.current === null) return;

        if (!(rcvMode === enDrawingMode.Pen || rcvMode === enDrawingMode.Marker)) return;

        serverPathRef.current.stroke.StylusPoints.push({ x: point.X, y: point.Y });
        if (serverPathRef.current.minPoints.x > point.X) serverPathRef.current.minPoints.x = point.X;
        if (serverPathRef.current.minPoints.y > point.Y) serverPathRef.current.minPoints.y = point.Y;

        if (serverPathRef.current.maxPoints.x < point.X) serverPathRef.current.maxPoints.x = point.X;
        if (serverPathRef.current.maxPoints.y < point.Y) serverPathRef.current.maxPoints.y = point.Y;

        //onPaint(sCtxRef.current, serverPathRef.current, { x: point.X, y: point.Y });
        onPaint(serverPathRef.current.canvas.getContext('2d'), serverPathRef.current, { x: point.X, y: point.Y });
    }

    const server_finishDraw = (point) => {
        if (serverPathRef.current === undefined || serverPathRef.current === null) return;

        if (!(rcvMode === enDrawingMode.Pen || rcvMode === enDrawingMode.Marker)) return;

        //let newAnnot = createInkStrokeAnnot( { width: scale_x.current, height: scale_y.current }, serverPathRef.current, sCtxRef.current);
        let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, serverPathRef.current, serverPathRef.current.canvas.getContext('2d'));
        annotList.current.push(newAnnot);

        serverPathRef.current = null;
    }

    const server_eraseStroke = (spoint, epoint) => {
        deleteInk(annotList.current, { x: spoint.X, y: spoint.Y }, { x: epoint.X, y: epoint.Y });
    }

    const draw1Stroke = (stroke) => {
        if (stroke === undefined || stroke === null) return;

        if (stroke.DrawingAttributes === undefined || stroke.DrawingAttributes === null) return;

        console.log(stroke.DrawingAttributes);

        let rcvPathRef = {};
        let color = { ...stroke.DrawingAttributes.Color, A: stroke.DrawingAttributes.IsHighlighter ? 0.5 : 1 };
        let lineWidth = stroke.DrawingAttributes.lineWidth;

        rcvPathRef.stroke = {};
        rcvPathRef.stroke.DrawingAttributes = { Color: color, lineWidth: lineWidth };
        rcvPathRef.stroke.StylusPoints = stroke.StylusPoints;
        rcvPathRef.minPoints = stroke.minPoints;
        rcvPathRef.maxPoints = stroke.maxPoints;

        rcvCtxRef.current = rcvCanvasRef.current.getContext('2d');
        rcvCtxRef.current.lineWidth = lineWidth;
        rcvCtxRef.current.lineJoin = 'round';
        rcvCtxRef.current.lineCap = 'round';
        rcvCtxRef.current.strokeStyle = 'rgba(' + color.R + ', ' + color.G + ', ' + color.B + ', ' + color.A + ')';
        rcvCtxRef.current.fillStyle = 'rgba(' + color.R + ', ' + color.G + ', ' + color.B + ', ' + color.A + ')';

        onPaint(rcvCtxRef.current, rcvPathRef);

        let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, rcvPathRef, rcvCtxRef.current);
        annotList.current.push(newAnnot);
    }

    const drawNStroke = (strokes) => {
        if (strokes === undefined || strokes === null) return;

        strokes.forEach(stroke => {
            draw1Stroke(stroke);
        });
    }

    /**
     * Smart Pen (Windows)
     * Windows connect bluetooth Pen
     */
    const onRcvExtensionMessage = (e) => {
        if (e.data && e.data.msg) {
            const { msg } = e.data;
            switch (msg.msg) {
                case 'PenDown':
                    pen_initDraw({ X: msg.X_Position, Y: msg.Y_Position });
                    break;

                case 'PenMove':
                    pen_draw({ X: msg.X_Position, Y: msg.Y_Position });
                    break;

                case 'PenUp':
                    pen_finishDraw({ X: msg.X_Position, Y: msg.Y_Position });
                    break;

                default:
                    break;
            }
        }
    }

    const pen_initDraw = (point) => {
        if (!penPathRef.current) {
            if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
                penPathRef.current = {};
                penPathRef.current.stroke = {};
                penPathRef.current.stroke.DrawingAttributes = { Color: rcvColor, lineWidth: rcvWidth };
                penPathRef.current.stroke.StylusPoints = [];

                pCtxRef.current = pCanvasRef.current.getContext('2d');
                pCtxRef.current.lineWidth = rcvWidth;
                pCtxRef.current.lineJoin = 'round';
                pCtxRef.current.lineCap = 'round';
                pCtxRef.current.strokeStyle = 'rgba(' + rcvColor.r + ', ' + rcvColor.g + ', ' + rcvColor.b + ', ' + (lDrawingMode.current === enDrawingMode.Pen ? '1)' : '0.3)');
                pCtxRef.current.fillStyle = 'rgba(' + rcvColor.r + ', ' + rcvColor.g + ', ' + rcvColor.b + ', ' + (lDrawingMode.current === enDrawingMode.Pen ? '1)' : '0.3)');

                penPathRef.current.stroke.StylusPoints.push({ x: point.x, y: point.y });
                penPathRef.current.minPoints = { x: point.x, y: point.y };
                penPathRef.current.maxPoints = { x: point.x, y: point.y };

                onPaint(pCtxRef.current, penPathRef.current, { x: point.x, y: point.y });
            }
        }
    }

    const pen_draw = (point) => {
        if (penPathRef.current === undefined || penPathRef.current === null) return;

        if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
            if (penPathRef.current.minPoints.x > point.x) penPathRef.current.minPoints.x = point.x;
            if (penPathRef.current.minPoints.y > point.y) penPathRef.current.minPoints.y = point.y;

            if (penPathRef.current.maxPoints.x < point.x) penPathRef.current.maxPoints.x = point.x;
            if (penPathRef.current.maxPoints.y < point.y) penPathRef.current.maxPoints.y = point.y;

            onPaint(pCtxRef.current, penPathRef.current, { x: point.x, y: point.y });
        }
    }

    const pen_finishDraw = (point) => {
        if (penPathRef.current === undefined || penPathRef.current === null) return;

        if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
            penPathRef.current.isDrawing = false;

            let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, penPathRef.current, pCtxRef.current);
            annotList.current.push(newAnnot);

            penPathRef.current = null;
        }
    }

    /**
     * Smart Pen (Android)
     * Android connect bluetooth Pen
     */

    const android_pen_initDraw = (point) => {
        if (!aPenPathRef.current) {
            if (!(lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker)) return;

            aPenPathRef.current = {};
            aPenPathRef.current.stroke = {};
            aPenPathRef.current.stroke.DrawingAttributes = { Color: lDrawingTipColor.current, lineWidth: lDrawingTipSize.current };
            aPenPathRef.current.stroke.StylusPoints = [];

            aCtxRef.current = aCanvasRef.current.getContext('2d');
            aCtxRef.current.lineWidth = lDrawingTipSize.current;
            aCtxRef.current.lineJoin = 'round';
            aCtxRef.current.lineCap = 'round';
            aCtxRef.current.strokeStyle = 'rgba(' + lDrawingTipColor.current.R + ', ' + lDrawingTipColor.current.G + ', ' + lDrawingTipColor.current.B + ', ' + (lDrawingMode.current === enDrawingMode.Pen ? '1)' : '0.3)');
            aCtxRef.current.fillStyle = 'rgba(' + lDrawingTipColor.current.R + ', ' + lDrawingTipColor.current.G + ', ' + lDrawingTipColor.current.B + ', ' + (lDrawingMode.current === enDrawingMode.Pen ? '1)' : '0.3)');

            aPenPathRef.current.stroke.StylusPoints.push({ x: point.X, y: point.Y });
            aPenPathRef.current.minPoints = { x: point.X, y: point.Y };
            aPenPathRef.current.maxPoints = { x: point.X, y: point.Y };

            onPaint(aCtxRef.current, aPenPathRef.current, { x: point.X, y: point.Y });
        }
    }

    const android_pen_draw = (point) => {
        if (aPenPathRef.current === undefined || aPenPathRef.current === null) return;

        if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
            if (aPenPathRef.current.minPoints.x > point.X) aPenPathRef.current.minPoints.x = point.X;
            if (aPenPathRef.current.minPoints.y > point.Y) aPenPathRef.current.minPoints.y = point.Y;

            if (aPenPathRef.current.maxPoints.x < point.X) aPenPathRef.current.maxPoints.x = point.X;
            if (aPenPathRef.current.maxPoints.y < point.Y) aPenPathRef.current.maxPoints.y = point.Y;

            onPaint(aCtxRef.current, aPenPathRef.current, { x: point.X, y: point.Y });
        }
    }

    const android_pen_double_draw = (point1, point2) => {
        if (aPenPathRef.current === undefined || aPenPathRef.current === null) return;

        if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
            let minPointX, minPointY, maxPointX, maxPointY;
            if (point1.X < point2.X) {
                minPointX = point1.X;
                maxPointX = point2.X;
            } else {
                maxPointX = point1.X;
                minPointX = point2.X;
            }

            if (point1.Y < point2.Y) {
                minPointY = point1.Y;
                maxPointY = point2.Y;
            } else {
                maxPointY = point1.Y;
                minPointY = point2.Y;
            }

            if (aPenPathRef.current.minPoints.x > minPointX) aPenPathRef.current.minPoints.x = minPointX;
            if (aPenPathRef.current.minPoints.y > minPointY) aPenPathRef.current.minPoints.y = minPointY;

            if (aPenPathRef.current.maxPoints.x < maxPointX) aPenPathRef.current.maxPoints.x = maxPointX;
            if (aPenPathRef.current.maxPoints.y < maxPointY) aPenPathRef.current.maxPoints.y = maxPointY;

            //let prePoint = aPenPath.current.lastSegment.point;
            //aPenPath.current.quadraticCurveTo(prePoint, new Point(cp1.x, cp1.y));
            //aPenPath.current.quadraticCurveTo(new Point(cp1.x, cp1.y), new Point(cp2.x, cp2.y));

            aPenPathRef.current.stroke.StylusPoints.push({ x: point1.X, y: point1.Y });
            //onPaint(aCtxRef.current, aPenPathRef.current, { x: point1.x, y: point1.y });
            onPaint(aCtxRef.current, aPenPathRef.current, { x: point2.X, y: point2.Y });
        }
    }

    const android_pen_finishDraw = (point) => {
        if (aPenPathRef.current === undefined || aPenPathRef.current === null) return;

        if (lDrawingMode.current === enDrawingMode.Pen || lDrawingMode.current === enDrawingMode.Marker) {
            aPenPathRef.current.isDrawing = false;

            aCtxRef.current = aCanvasRef.current.getContext('2d');

            let newAnnot = createInkStrokeAnnot({ width: scale_x.current, height: scale_y.current }, aPenPathRef.current, aCtxRef.current);
            annotList.current.push(newAnnot);

            aPenPathRef.current = null;
        }
    }

    return (
        <div className="live-area canvas">
            <div id="canvas-area" ref={targetRef}>
                <canvas
                    id="backgroundCanvas"
                    className="bg-canvas"
                    ref={bgCanvasRef}
                    width={ConstData.SCREEN_RATE.WIDTH}
                    height={ConstData.SCREEN_RATE.HEIGHT}
                />
                <canvas
                    id="serverCanvas"
                    className="draw-canvas"
                    ref={sCanvasRef}
                />
                <canvas
                    id="androidCanvas"
                    className="draw-canvas"
                    ref={aCanvasRef}
                />
                <canvas
                    id="penCanvas"
                    className="draw-canvas"
                    ref={pCanvasRef}
                />
                <canvas
                    id="smallGroupCanvas"
                    className="draw-canvas"
                    ref={rcvCanvasRef}
                />
                <canvas
                    id="drawingCanvas"
                    className="draw-canvas"
                    ref={canvasRef}
                />
            </div>
        </div>
    )
}

export default LiveDrawingCanvas;
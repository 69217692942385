import React from 'react';
import ClassTalkMassages from '../classtalk/ClassTalkMessages';
import EmoticItem from '../classtalk/emoticon/EmoticItem';
import EmoticList from '../classtalk/emoticon/EmoticList';
import AudioRecord from '../classtalk/voiceMemo/AudioRecord';
import InputGroupBoxInLive from '../classtalk/InputGroupBoxInLive';
import FunctionList from '../classtalk/FunctionList';
import WebWhisper from "../classtalk/WebWhisper";

import { getIsAlivePenCamService } from '../../lib/RabbitmqClient';

const ClassTalkLive = ({ classTalkVisibility, web_isWhisper, openInfo, isLiveChattingLocked, handsUpYN, myHandsUpYN, connectStatus, classTalkMsg, isConnectedOnScreenApp, handleSelectedClassTalkFunc }) => {
    return (
        <div className="d-flex flex-column white" style={{ minHeight: '50%', height: '100%', overflow: 'hidden' }}>
            <ClassTalkMassages
                classTalkMsg={classTalkMsg}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.selectedEmoticonSeq > -1 &&
                <EmoticItem
                    selIndex={openInfo.selectedEmoticonSeq}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
            {
                openInfo.isEmoticonSelected &&
                <EmoticList handleSelectedClassTalkFunc={handleSelectedClassTalkFunc} />
            }
            <WebWhisper
                web_isWhisper={web_isWhisper}
                classTalkVisibility={classTalkVisibility}
                isAddFunctionOpened={openInfo.isAddFunctionOpened}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.isAudioOpened ?
                    <AudioRecord
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    /> :
                    <InputGroupBoxInLive
                        classTalkVisibility={classTalkVisibility}
                        isLiveChattingLocked={isLiveChattingLocked}
                        web_isWhisper={web_isWhisper}
                        isAddFunctionOpened={openInfo.isAddFunctionOpened}
                        selIndex={openInfo.selectedEmoticonSeq}
                        onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    />
            }
            {
                openInfo.isAddFunctionOpened &&
                <FunctionList
                    isChatting={false}
                    handsUpYN={handsUpYN}
                    myHandsUpYN={myHandsUpYN}
                    connectStatus={connectStatus}
                    onHandleClassTalkFunc={handleSelectedClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    isConnectedOnScreenApp={isConnectedOnScreenApp}
                />
            }
        </div>
    );
}
export default ClassTalkLive;
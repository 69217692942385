import common from "./common/common.json";

import policyCompo1 from "./policy/compo1.json";

import adminModule from "./admin/module.json";
import adminCompo1 from "./admin/compo1.json";

import chatModule from "./chat/module.json";
import chatCompo1 from "./chat/compo1.json";

import classTalkCommon from "./classTalk/common.json";
import classTalkModule from "./classTalk/module.json";
import classTalkCompo1 from "./classTalk/compo1.json";
import classTalkCompo2 from "./classTalk/compo2.json";

import friendCompo1 from "./friend/compo1.json";

import hiclasstvModule from "./hiclasstv/module.json";
import hiclasstvCompo1 from "./hiclasstv/compo1.json";

import liveCommon from "./live/common.json";
import liveModule from "./live/module.json";
import liveCompo1 from "./live/compo1.json";

import passModule from "./pass/module.json";
import passCompo1 from "./pass/compo1.json";

import promotionCompo1 from "./promotion/compo1.json";

import studioCompo1 from "./studio/compo1.json";

import userCommon from "./user/common.json";
import userModule from "./user/module.json";
import userCompo1 from "./user/compo1.json";

import infoCompo1 from "./info/compo1.json";
import infoCompo2 from "./info/compo2.json";

const resource = {
    common: {
        ...common
    },
    policy: {
        ...policyCompo1,
    },
    admin: {
        ...adminModule,
        ...adminCompo1
    },
    chat: {
        ...chatModule,
        ...chatCompo1
    },
    classTalk: {
        ...classTalkCommon,
        ...classTalkModule,
        ...classTalkCompo1,
        ...classTalkCompo2
    },
    friend: {
        ...friendCompo1
    },
    hiclasstv: {
        ...hiclasstvModule,
        ...hiclasstvCompo1
    },
    live: {
        ...liveCommon,
        ...liveModule,
        ...liveCompo1
    },
    pass: {
        ...passModule,
        ...passCompo1
    },
    promotion: {
        ...promotionCompo1
    },
    studio: {
        ...studioCompo1
    },
    user: {
        ...userCommon,
        ...userModule,
        ...userCompo1
    },
    info: {
        ...infoCompo1,
        ...infoCompo2
    }
};

export default resource;
import { startLoading, finishLoading } from '../modules/loading';

export default function createRequestMultiThunk(type, request, kind, afterTypeArr) {
    //성공 및 실패 액션 타입을 정의합니다.
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return parms => async (dispatch, getState) => {
        dispatch({ type });   //시작됨
        dispatch(startLoading(type));

        //console.log("createRequestThunk called");

        try {
            const reponse = await request(parms);

            if (reponse.data.isSuccessed === true) {
                dispatch({
                    type: SUCCESS,
                    parms: parms,
                    payload: reponse.data
                }); //성공

                if (afterTypeArr !== undefined && afterTypeArr !== null) {
                    afterTypeArr.forEach(typeName => { 
                        dispatch({
                            type: typeName,
                            parms: parms,
                            payload: reponse.data
                        });
                    });
                }
            } else {
                dispatch({
                    type: SUCCESS,
                    parms: parms,
                    payload: reponse.data
                }); //성공
            }
            dispatch(finishLoading(type))
        } catch (e) {
            dispatch({
                type: FAILURE,
                payload: e,
                error: true
            }); //에라 발생
            dispatch(startLoading(type))
            throw e;
        }
    }
}
import React from "react";
import { useTranslation } from "react-i18next";

const ChatTitle = ({ isLocalVersion }) => {
    const { t } = useTranslation();

    if (isLocalVersion) {
        return (
            <span className="white-text">
                <b>{t("채팅방", { ns: "chat" })}</b>
            </span>
        );
    } else {
        return (
            <span className="white-text">
                <b>{t("나와의_채팅", { ns: "chat" })}</b>
            </span>
        );
    }
}

export default ChatTitle;
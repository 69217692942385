import React, { Component } from 'react';
import { connect } from 'react-redux';

import { enTokenCMD } from '../lib/classTalk/CodeEnum';
import { ctActionType } from '../lib/ConstCommand';

import WhisperChattingInfo from '../components/whisper/WhisperChattingInfo';
import ClassTalk from '../components/whisper/ClassTalk';

import { whisperPopupInitialize,
         appendSendMessage,
         appendSendAudioMemo,
         appendSendFileShare, 
         emoticonSelectedToggle, 
         selectEmoticonSeq,
         emoticonSelectedCancel, 
         addFunctionOpenedToggle, 
         selectFunctionSeq, 
         inkMemoOpenedToggle,
         voiceMemoClosedToggle, 
         fileShareToggle, 
         fileOpenToggle, 
         imgThumbnailClick } from '../modules/whisper';

class WhisperPopupContainer extends Component {

    componentDidMount() {
        console.log('componentDidMount');

        const { routeProps } = this.props;
        if (routeProps.match && routeProps.match.params) {
            const stdSeq = routeProps.match.params.stdSeq;
            console.log('WhisperPopupContainer - have to stdSeq ; ', stdSeq);

            if (Number(stdSeq) > 0) {
                this.props.whisperPopupInitialize({ stdSeq });
            }
        }
    }

    handleSelectedClassTalkFunc = ({ selectedFunc, emoticonSeq, func, msgText, data, filename, fileURL, idx, fileType }) => {
        const { userSeq, userNickname } = this.props;

        console.log(`selectedFunc[${selectedFunc}], msgText[`, msgText, `], data[`, data, `]`);
        switch(selectedFunc) {
            // --------------------------- Emoticon --------------------------- //
            case "EMOTICON_TOGGLE" :
                this.props.emoticonSelectedToggle();
                break;

            case "EMOTICON_SELECT" :
                this.props.selectEmoticonSeq(emoticonSeq);
                break;

            case "EMOTICON_CANCLE" :
                this.props.emoticonSelectedCancel();
                break;

            // --------------------------- Send message --------------------------- //

            case "SEND_MSG" :
                console.log('send msg ; ', msgText);
                this.props.appendSendMessage({ msgText, userNickname }); 
                //this.props.appendSendMessage({ msgText, userNickname }); 
                break;

            case "SEND_VOICE_MEMO" :
                this.props.appendSendAudioMemo({ data, url: fileURL, userNickname, userSeq });
                //this.props.appendSendAudioMemo({ selectedFunc, data, url: fileURL, userNickname, userSeq });
                break;

            // --------------------------- Classtalk func --------------------------- //

            case "FUNC_TOGGLE" :                
                this.props.addFunctionOpenedToggle();
                break;

            case "FUNC_SELECT" :
                this.props.selectFunctionSeq({ func, mode: "hiclass", userNickname, userSeq, memberSeqList:[this.props.stdInfo.stdSeq] });
                
                switch(func) {
                    case ctActionType.InkMemo :
                        //this.props.inkMemoOpenedToggle({ performChatRoomSeq: performChatRoomInfo.chatRoomSeq, userNickname });
                        break; 

                    case ctActionType.FileShare : 
                        let chatRoomSeq = 0;
                        //if (performChatRoomInfo.chatRoomSeq !== -1) chatRoomSeq = performChatRoomInfo.chatRoomSeq;
                        
                        this.props.appendSendFileShare({
                            mode: "hiclass",
                            chatRoomSeq,
                            data,
                            teacherSeq: userSeq,
                            filename,
                            userSeq,
                            userNickname,
                            command: enTokenCMD.ct_UploadAndShareFiles,
                            memberSeqList: [{ userSeq: this.props.stdInfo.stdSeq }],
                            mixedClassStatus: this.props.mixedClassStatus
                        });
                        break;

                    default:
                        break;
                }
                break;
                
            case "CLOSE_VOICE_MEMO" :
                this.props.voiceMemoClosedToggle();
                break;
                
            case "INK_MEMO" :
                this.props.inkMemoOpenedToggle({ selectedFunc, imgUrl: fileURL, userNickname, userSeq /**, performChatRoomSeq: performChatRoomInfo.chatRoomSeq */ });
                break;

            case "PAGE_JPEG" : 
                this.props.imgThumbnailClick({ imgDataUrl: fileURL, idx });
                break;

            case "FILE_DOWNLOAD" :
                this.props.fileShareToggle({ fileURL, idx });
                break;

            case "FILE_OPEN" : 
                //console.log(`handleSelectedClassTalkFunc - fileType[${fileType}]`);
                this.props.fileOpenToggle({ fileURL, fileType });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <>
                <WhisperChattingInfo
                    stdInfo={this.props.stdInfo}
                />
                <ClassTalk
                    openInfo={this.props.openInfo}
                    classTalkMsg={this.props.whisperMsg}
                    handleSelectedClassTalkFunc={this.handleSelectedClassTalkFunc}
                />
            </>
        );
    }
}

export default connect(
    ({ live, whisper, classTalk }) => ({
        userSeq                     : whisper.userInfo.userSeq,
        userNickname                : whisper.userInfo.userNickname,

        stdInfo                     : whisper.stdInfo,

        whisperMsg                  : whisper.whisperMsg,

        openInfo                    : {
            isEmoticonSelected      : whisper.isEmoticonSelected,
            selectedEmoticonSeq     : whisper.selectedEmoticonSeq,
            isAddFunctionOpened     : whisper.isAddFunctionOpened,
            isAudioOpened           : whisper.isAudioOpened,
            audioStatus             : whisper.audioStatus,
            selectedFunctionSeq     : whisper.selectedFunctionSeq
        },

        isMixedClassMember          : live.isMixedClassMember,
        mixedClassStatus            : live.performLiveInfo.mixedClassStatus
    }),

    {
        emoticonSelectedToggle,
        selectEmoticonSeq,
        emoticonSelectedCancel,

        appendSendMessage,
        appendSendAudioMemo,
        appendSendFileShare,

        selectFunctionSeq,
        addFunctionOpenedToggle,
        voiceMemoClosedToggle,
        inkMemoOpenedToggle,
        imgThumbnailClick,
        fileShareToggle,
        fileOpenToggle,

        whisperPopupInitialize
    }
)(WhisperPopupContainer);
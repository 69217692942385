import React, { useState, useEffect, useRef } from "react";
import { FiPlusCircle, FiArrowDownCircle } from "react-icons/fi";
// import { AiOutlinePlusCircle } from "react-icons/ai";
// import { BsPlusCircle } from "react-icons/bs";
// import { BiPlusCircle } from "react-icons/bi";
// import { HiOutlinePlusCircle } from "react-icons/hi";
// import { MdAddCircleOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { stQnAItemType, stQuizDataKind, stQuizDataType, stQuizFileUploadActionKind } from "../../lib/ConstCommand";
import ConstData from "../../lib/ConstData";

import Alerts from "../etc/list/Alerts";
import QuizQuestionData from "./QuizQuestionData";
import QuizExampleData from "./QuizExampleData";
import ModifyAlertModal from "./modal/ModifyAlertModal";
import RemoveAlertModal from "./modal/RemoveAlertModal";
import RemoveUrlAlertModal from "./modal/RemoveUrlAlertModal";

const checkStringValidation = (value) => {
    /* if (value !== undefined && value !== null && value !== "") return true;
    else return false; */
    return value !== undefined && value !== null && value !== "";
}

const checkNumberValidation = (value) => {
    /* if (value !== undefined && value !== null && value > -1) return true;
    else return false; */
    return value !== undefined && value !== null && value > -1;
}

const checkConvertDataType = (value) => {
    /* if (checkStringValidation(value) && value !== stQuizDataType.URL) return true;
    else return false; */
    return checkStringValidation(value) && value !== stQuizDataType.URL;
}

const checkURLValidation = (value) => {
    let isUrlText = false;
    try {
        const regExp =
            /(?:(?:(https?|ftp|telnet):\/\/|[^\s\t\r\n[\]`<>"'])((?:[\w$\-_.+!*'(),]|%[0-9a-f][0-9a-f])*:(?:[\w$\-_.+!*'(),;?&=]|%[0-9a-f][0-9a-f])+@)?(?:((?:(?:[a-z0-9\-가-힣]+\.)+[a-z0-9-]{2,})|(?:[\d]{1,3}\.){3}[\d]{1,3})|localhost)(?::([0-9]+))?((?:\/(?:[\w$\-_.+!*'(),;:@&=ㄱ-ㅎㅏ-ㅣ가-힣]|%[0-9a-f][0-9a-f])+)*)(?:\/([^\s/?.:<>|#]*(?:\.[^\s/?:<>|#]+)*))?(\/?[?;](?:[a-z0-9-]+(?:=[^\s:&<>]*)?&)*[a-z0-9-]+(?:=[^\s:&<>]*)?)?(#[\w-]+)?)/gmi;
        let arr = value.match(regExp);
        console.log("checkURLValidation - arr => ", arr);
        if (arr && arr.length > 0) {
            // url = text.match(regExp)[1];
            isUrlText = true;
        }
    } catch (err) {
        console.log("checkURLValidation - err => ", err);
    }

    return isUrlText;
}

const QuizModalHeader = ({ }) => {
    return <div className="quiz-header" />;
}

const QuizModalTitle = ({ modalQuiz_info }) => {
    const { t } = useTranslation();

    if (modalQuiz_info && modalQuiz_info.quizSeq > 0) {
        return <span className="quiz-title">{t("퀴즈_수정", { ns: "classTalk" })}</span>;
    } else {
        return <span className="quiz-title">{t("퀴즈_생성", { ns: "classTalk" })}</span>;
    }
}

const QuizModal = ({ modalQuiz_info, upload_info, alertKind, alertMessage, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [isQuizModified, setIsQuizModified] = useState(false);
    const [savedQuizInfo, setSavedQuizInfo] = useState(null);

    const [isModifyAlertModalOpened, setIsModifyAlertModalOpened] = useState(false);
    const [isRemoveAlertModalOpened, setIsRemoveAlertModalOpened] = useState(false);
    const [isRemoveUrlAlertModalOpened, setIsRemoveUrlAlertModalOpened] = useState(false);

    const lQuestionKind = stQuizDataKind.Text;
    const lQuestionDataType = stQuizDataType.None;

    const [lQuizTitle, setQuizTitle] = useState("");
    const [lQuestionData, setQuestionData] = useState([
        { questionKind: lQuestionKind, questionDataType: lQuestionDataType, questionData: "", uploadUrlYN: "N", uploadPath: "" }
    ]);
    const [lQuestionUploadInfo, setQuestionUploadInfo] = useState(null);
    const [lAnswerExampleYN, setAnswerExampleYN] = useState("Y");
    const [lAnswerKind, setAnswerKind] = useState(stQuizDataKind.Text);
    const [lAnswerType, setAnswerType] = useState(stQuizDataType.None);
    const [lAnswerData, setAnswerData] = useState("");
    const [lAnswerExample, setAnswerExample] = useState({
        index: -1, exampleKind: "", exampleType: "", exampleData: "", uploadUrlYN: "N", uploadPath: ""
    });
    const [lExampleKind, setExampleKind] = useState(stQuizDataKind.Text);
    const [lExampleType, setExampleType] = useState(stQuizDataType.None);
    const [lExampleData, setExampleData] = useState([
        { exampleKind: lExampleKind, exampleType: lExampleType, exampleData: "", uploadUrlYN: "N", uploadPath: "" },
        { exampleKind: lExampleKind, exampleType: lExampleType, exampleData: "", uploadUrlYN: "N", uploadPath: "" }
    ]);
    const [lExampleUploadInfo, setExampleUploadInfo] = useState(null);
    const [lCongratulationImageType, setCongratulationImageType] = useState("");
    const [lCongratulationImageData, setCongratulationImageData] = useState([
        { congratulationImageKind: "", congratulationImageType: "", congratulationImageData: "", uploadUrlYN: "N", uploadPath: "" }
    ]);

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    const [lTopAlertMessage, setTopAlertMessage] = useState("");
    const [lMiddleAlertMessage, setMiddleAlertMessage] = useState("");
    const [lBottomAlertMessage, setBottomAlertMessage] = useState("");

    const savedHandleActionRef = useRef(null);

    const minQuestionCnt = 1;
    const maxQuestionCnt = 4;
    const minExampleCnt = 2;
    const maxExampleCnt = 10;

    useEffect(() => {
        console.log(modalQuiz_info);

        if (modalQuiz_info) {
            let quizSeq = -1;
            let quizTitle = "";
            let question_data = [];
            let answerExampleYN = "";
            let answerKind = "";
            let answerType = "";
            let answerData = "";
            let answerExampleSeq = -1;
            let answerExample_info = {
                index: -1,
                exampleKind: "",
                exampleType: "",
                exampleData: "",
                uploadUrlYN: "N",
                uploadPath: ""
            };
            let example_data = [];
            let congratulationImage_data = [];

            if (checkNumberValidation(modalQuiz_info.quizSeq)) {
                quizSeq = modalQuiz_info.quizSeq;
            }

            if (quizSeq === -1) {
                console.log("QuizModal - create mode. use default state value.");
                /* question_data = [
                    { questionKind: stQuizDataKind.Text, questionDataType: stQuizDataType.None, questionData: "", uploadUrlYN: "N", uploadPath: "" }
                ]; */

                /* example_data = [
                    { exampleKind: stQuizDataKind.Text, exampleType: stQuizDataType.None, exampleData: "", uploadUrlYN: "N", uploadPath: "" },
                    { exampleKind: stQuizDataKind.Text, exampleType: stQuizDataType.None, exampleData: "", uploadUrlYN: "N", uploadPath: "" }
                ]; */

                /* congratulationImage_data = [
                    { congratulationImageKind: "", congratulationImageType: "", congratulationImageData: "", uploadUrlYN: "N", uploadPath: "" }
                ]; */
            } else {
                if (checkStringValidation(modalQuiz_info.quizTitle)) {
                    quizTitle = modalQuiz_info.quizTitle;
                } else {
                    if (checkStringValidation(modalQuiz_info.title)) {
                        quizTitle = modalQuiz_info.title;
                    }
                }

                if (modalQuiz_info.data !== undefined && modalQuiz_info.data !== null && modalQuiz_info.data.length > 0) {
                    for (let i = 0; i < modalQuiz_info.data.length; i++) {
                        console.log(modalQuiz_info.data[i]);

                        if (modalQuiz_info.data[i].type === stQnAItemType.Question) {
                            let quizQuestion_info = modalQuiz_info.data[i].data;
                            if (quizQuestion_info !== undefined && quizQuestion_info !== null && quizQuestion_info.length > 0) {
                                question_data = quizQuestion_info.map(info => ({
                                    questionKind: info.kind,
                                    questionDataType: info.type,
                                    questionData: info.data,
                                    uploadUrlYN: info.uploadUrlYN,
                                    uploadPath: ""
                                }));
                            }

                            if (modalQuiz_info.data[i].exYN) {
                                answerExampleYN = modalQuiz_info.data[i].exYN;
                            }
                        } else if (modalQuiz_info.data[i].type === stQnAItemType.Answer) {
                            let answer_info = modalQuiz_info.data[i].data;
                            if (answer_info !== undefined && answer_info !== null) {
                                if (answer_info.kind) {
                                    answerKind = answer_info.kind;
                                }

                                if (answer_info.type) {
                                    answerType = answer_info.type;
                                }

                                if (answer_info.data) {
                                    answerData = answer_info.data;
                                }

                                if (answerExampleYN === "Y") {
                                    if (typeof answer_info.no === "number") {
                                        if (checkNumberValidation(answer_info.no)) {
                                            answerExampleSeq = answer_info.no;
                                            answerExampleSeq -= 1; // answerExampleNo에 1부터 표시되기 위해 1을 더했었기 때문에 ...
                                        }
                                    } else {
                                        if (checkStringValidation(answer_info.no)) {
                                            answerExampleSeq = Number(answer_info.no);
                                            answerExampleSeq -= 1; // answerExampleNo에 1부터 표시되기 위해 1을 더했었기 때문에 ...
                                        }
                                    }
                                }
                            }
                        } else if (modalQuiz_info.data[i].type === stQnAItemType.Example) {
                            let exampleData_info = modalQuiz_info.data[i].data;
                            if (exampleData_info !== undefined && exampleData_info !== null && exampleData_info.length > 0) {
                                example_data = exampleData_info.map(info => ({
                                    exampleKind: info.kind,
                                    exampleType: info.type,
                                    exampleData: info.data,
                                    uploadUrlYN: info.uploadUrlYN,
                                    uploadPath: ""
                                }));
                            }
                        } else if (modalQuiz_info.data[i].type === stQnAItemType.Congratulation) {
                            let congratulationImageData_info = modalQuiz_info.data[i].data;
                            if (congratulationImageData_info !== undefined && congratulationImageData_info !== null && congratulationImageData_info.length > 0) {
                                congratulationImage_data = congratulationImageData_info.map(info => ({
                                    congratulationImageKind: info.kind,
                                    congratulationImageType: info.type,
                                    congratulationImageData: info.type,
                                    uploadUrlYN: "N",
                                    uploadPath: ""
                                }));
                            }
                        }
                    }
                } else {
                    console.log("QuizModal - move url success! changed edit mode.");
                    if (modalQuiz_info.questionData !== undefined && modalQuiz_info.questionData !== null && modalQuiz_info.questionData.length > 0) {
                        question_data = modalQuiz_info.questionData;
                    }

                    if (modalQuiz_info.answerData !== undefined && modalQuiz_info.answerData !== null) {
                        if (modalQuiz_info.answerData.answerKind) {
                            answerKind = modalQuiz_info.answerData.answerKind;
                        }

                        if (modalQuiz_info.answerData.answerType) {
                            answerType = modalQuiz_info.answerData.answerType;
                        }

                        if (modalQuiz_info.answerData.answerData) {
                            answerData = modalQuiz_info.answerData.answerData;
                        }
                    }

                    if (modalQuiz_info.answerExampleYN) {
                        answerExampleYN = modalQuiz_info.answerExampleYN;
                    }

                    if (answerExampleYN === "Y") {
                        if (typeof modalQuiz_info.answerExampleSeq === "number") {
                            if (checkNumberValidation(modalQuiz_info.answerExampleSeq)) {
                                answerExampleSeq = modalQuiz_info.answerExampleSeq;
                            }
                        } else {
                            if (checkStringValidation(modalQuiz_info.answerExampleSeq)) {
                                answerExampleSeq = Number(modalQuiz_info.answerExampleSeq);
                            }
                        }
                    }

                    if (modalQuiz_info.exampleData !== undefined && modalQuiz_info.exampleData !== null && modalQuiz_info.exampleData.length > 0) {
                        example_data = modalQuiz_info.exampleData;
                    }

                    if (modalQuiz_info.congratulationImageData !== undefined && modalQuiz_info.congratulationImageData !== null && modalQuiz_info.congratulationImageData.length > 0) {
                        congratulationImage_data = modalQuiz_info.congratulationImageData;
                    }
                }
            }

            if (checkStringValidation(quizTitle)) {
                setQuizTitle(quizTitle);
            } else {
                console.log("not setQuizTitle case");
            }

            if (question_data.length > 0) {
                setQuestionData(question_data);
            } else {
                console.log("not setQuestionData case");
            }

            if (checkStringValidation(answerKind)) {
                setAnswerKind(answerKind);
            } else {
                console.log("not setAnswerKind case");
            }

            if (checkStringValidation(answerType)) {
                setAnswerType(answerType);
            } else {
                console.log("not setAnswerType case");
            }

            if (checkStringValidation(answerData)) {
                setAnswerData(answerData);
            } else {
                console.log("not setAnswerData case");
            }

            if (checkStringValidation(answerExampleYN)) {
                setAnswerExampleYN(answerExampleYN);
            } else {
                console.log("not setAnswerExampleYN case");
            }

            if (checkNumberValidation(answerExampleSeq) && answerExampleYN === "Y") {
                const exampleData_info = example_data.find((info, idx) => idx === answerExampleSeq);
                if (exampleData_info) {
                    answerExample_info.index = answerExampleSeq;
                    answerExample_info.exampleKind = exampleData_info.exampleKind;
                    answerExample_info.exampleType = exampleData_info.exampleType;
                    answerExample_info.exampleData = exampleData_info.exampleData;
                    answerExample_info.uploadUrlYN = exampleData_info.uploadUrlYN;
                    answerExample_info.uploadPath = exampleData_info.uploadPath;
                }

                console.log("(1) answerExample_info => ", answerExample_info);
            }

            if (answerExample_info.index > -1) {
                setAnswerExample(answerExample_info);
            } else {
                console.log("not setAnswerExample case");
            }

            if (example_data.length > 0) {
                setExampleData(example_data);
            } else {
                console.log("not setExampleData case");
            }

            if (congratulationImage_data.length > 0) {
                setCongratulationImageData(congratulationImage_data);
            } else {
                console.log("not setCongratulationImageData case");
            }
        }
    }, [modalQuiz_info]);

    useEffect(() => {
        if (upload_info && alertKind === ConstData.ALERT_KIND.SUCCESS) {
            switch (upload_info.kind) {
                case stQuizFileUploadActionKind.QuestionFileUpload:
                    setQuestionUploadInfo({ kind: "upload", seq: upload_info.seq, path: upload_info.path });
                    break;

                case stQuizFileUploadActionKind.ExampleFileUpload:
                    setExampleUploadInfo({ kind: "upload", seq: upload_info.seq, path: upload_info.path });
                    break;

                case stQuizFileUploadActionKind.RemoveQuestionFile:
                    setQuestionUploadInfo({ kind: "remove", seq: upload_info.seq, path: upload_info.path });
                    break;

                case stQuizFileUploadActionKind.RemoveExampleFile:
                    setExampleUploadInfo({ kind: "remove", seq: upload_info.seq, path: upload_info.path });
                    break;

                default:
                    break;
            }
        }
    }, [upload_info, alertKind, alertMessage]);

    const onChangeQuizTitle = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            // setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let str = e.target.value;
        if (str.length > 50) {
            str = str.substr(0, 50);
            setTopAlertMessage(t("퀴즈_알림_메시지_11", { ns: "classTalk" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        }

        setQuizTitle(str);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onChangeAnswerExampleYN = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            // setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let answerExampleYN = e.target.value;

        if (answerExampleYN === "N") {
            setAnswerKind(stQuizDataKind.Text);
            setAnswerType(stQuizDataType.None);
            setAnswerData("");
        } else {
            setAnswerExample({
                index: -1,
                exampleKind: "",
                exampleType: "",
                exampleData: "",
                uploadUrlYN: "N",
                uploadPath: ""
            });
            setAlertKind(lAnswerExample.exampleKind);
            setAnswerType(lAnswerExample.exampleType);
            setAnswerData("");
        }

        if (lAnswerExampleYN !== answerExampleYN) {
            /* setExampleData(current => current.map(info => ({
                ...info,
                exampleData: ""
            }))); */
            setExampleData([
                { exampleKind: lExampleKind, exampleType: lExampleType, exampleData: "", uploadUrlYN: "N", uploadPath: "" },
                { exampleKind: lExampleKind, exampleType: lExampleType, exampleData: "", uploadUrlYN: "N", uploadPath: "" },
            ]);
        }

        setAnswerExampleYN(answerExampleYN);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onChangeAnswerData = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            //setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        if (lAnswerExampleYN === "N") {
            setAnswerData(e.target.value);
        } else {
            let index = Number(e.target.value);
            const example_data = lExampleData.find((info, idx) => idx === index);
            if (example_data) {
                setAnswerExample({
                    index,
                    exampleKind: example_data.exampleKind,
                    exampleType: example_data.exampleType,
                    exampleData: example_data.exampleData,
                    uploadUrlYN: example_data.uploadUrlYN,
                    uploadPath: example_data.uploadPath
                });
            }
        }

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onChangeExampleKind = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            //setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let exampleKind = e.target.value;
        let exampleType = stQuizDataType.None;

        switch (exampleKind) {
            case stQuizDataKind.Text:
                exampleType = stQuizDataType.None;
                break;

            case stQuizDataKind.Image:
            case stQuizDataKind.Audio:
            case stQuizDataKind.Video:
                exampleType = stQuizDataType.File;
                break;

            default:
                exampleType = stQuizDataType.None;
                break;
        }

        setExampleKind(exampleKind);
        setExampleType(exampleType);

        setExampleData(current => current.map(info => ({
            //...info,
            exampleKind,
            exampleType,
            exampleData: lExampleKind !== exampleKind && info.exampleData !== "" ? "" : info.exampleData,
            uploadUrlYN: info.uploadUrlYN,
            uploadPath: info.uploadPath
        })));

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onClickAddQuestionData = () => {
        if (lQuestionData.length >= maxQuestionCnt) {
            // setAlertMessage(t("퀴즈_알림_메시지_12", { ns: "classTalk" }));
            setTopAlertMessage(t("퀴즈_알림_메시지_12", { ns: "classTalk" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            return;
        }

        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            // setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let question_data = { questionKind: lQuestionKind, questionDataType: lQuestionDataType, questionData: "", uploadUrlYN: "N", uploadPath: "" };
        setQuestionData(current => [...current, question_data]);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onClickRemoveQuestionData = (index, quizQuestion_data) => {
        if (lQuestionData.length <= minQuestionCnt) {
            // setAlertMessage(t("퀴즈_알림_메시지_13", { ns: "classTalk" }));
            setTopAlertMessage(t("퀴즈_알림_메시지_13", { ns: "classTalk" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            return;
        }

        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            //setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let list_arr = lQuestionData.filter((info, idx) => idx !== index);
        setQuestionData(list_arr);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onClickAddExampleData = () => {
        if (lExampleData.length >= maxExampleCnt) {
            // setAlertMessage(t("퀴즈_알림_메시지_14", { ns: "classTalk" }));
            setBottomAlertMessage(t("퀴즈_알림_메시지_14", { ns: "classTalk" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            return;
        }

        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            // setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let example_data = { exampleKind: lExampleKind, exampleType: lExampleType, exampleData: "", uploadUrlYN: "N", uploadPath: "" };
        setExampleData(current => [...current, example_data]);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const onClickRemoveExampleData = (index, example_data) => {
        if (lExampleData.length <= minExampleCnt) {
            // setAlertMessage(t("퀴즈_알림_메시지_15", { ns: "classTalk" }));
            setMiddleAlertMessage(t("퀴즈_알림_메시지_15", { ns: "classTalk" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            return;
        }

        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            // setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        if (lAnswerExampleYN === "Y") {
            if (lAnswerExample.index === index) {
                setAnswerExample({
                    index: -1,
                    exampleKind: "",
                    exampleType: "",
                    exampleData: "",
                    uploadUrlYN: "N",
                    uploadPath: ""
                });
            }
        }

        let list_arr = lExampleData.filter((info, idx) => idx !== index);
        setExampleData(list_arr);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const setQuizDataByLoadFile = (file_info) => {
        console.log(file_info);

        let quizSeq = -1;
        let quizTitle = "";
        let question_data = [];
        let answerExampleYN = "";
        let answerKind = "";
        let answerType = "";
        let answerData = "";
        let answerExampleSeq = -1;
        let answerExample_info = {
            index: -1,
            exampleKind: "",
            exampleType: "",
            exampleData: "",
            uploadUrlYN: "N",
            uploadPath: ""
        };
        let example_data = [];
        let congratulationImage_data = [];

        if (file_info.quizTitle) {
            quizTitle = file_info.quizTitle;
        }

        /////////////////////////////////////////////
        //............. Question Data .............//
        /////////////////////////////////////////////
        if (file_info.questionData !== undefined && file_info.questionData !== null && file_info.questionData.length > 0) {
            question_data = file_info.questionData.map(info => ({
                questionKind: info.questionKind,
                questionDataType: info.questionDataType,
                questionData: info.questionData,
                uploadUrlYN: info.uploadUrlYN,
                uploadPath: ""
            }));
        }

        if (file_info.answerExampleYN) {
            answerExampleYN = file_info.answerExampleYN;
        }

        /////////////////////////////////////////////
        //.............. Answer Data ..............//
        /////////////////////////////////////////////        
        if (file_info.answerKind) {
            answerKind = file_info.answerKind;
        }

        if (file_info.answerType) {
            answerType = file_info.answerType;
        }

        if (file_info.answerData) {
            answerData = file_info.answerData;
        }

        if (file_info.answerExampleYN === "Y") {
            answerExampleSeq = file_info.answerExampleSeq;
        }

        /////////////////////////////////////////////
        //............. Example Data ..............//
        /////////////////////////////////////////////
        if (file_info.exampleData !== undefined && file_info.exampleData !== null) {
            example_data = file_info.exampleData;
        }

        /////////////////////////////////////////////
        //......... Congratulation Data ...........//
        /////////////////////////////////////////////
        if (file_info.congratulationImageType === stQnAItemType.Congratulation) {
            // congratulationImage_data = file_info.congratulationImage_data;
        }

        // set Quiz Data
        if (checkStringValidation(quizTitle)) {
            setQuizTitle(quizTitle);
        } else {
            console.log("not setQuizTitle case");
        }

        if (question_data.length > 0) {
            setQuestionData(question_data);
        } else {
            console.log("not setQuestionData case");
        }

        if (checkStringValidation(answerKind)) {
            setAnswerKind(answerKind);
        } else {
            console.log("not setAnswerKind case");
        }

        if (checkStringValidation(answerType)) {
            setAnswerType(answerType);
        } else {
            console.log("not setAnswerType case");
        }

        if (checkStringValidation(answerData)) {
            setAnswerData(answerData);
        } else {
            console.log("not setAnswerData case");
        }

        if (checkStringValidation(answerExampleYN)) {
            setAnswerExampleYN(answerExampleYN);
        } else {
            console.log("not setAnswerExampleYN case");
        }

        if (checkNumberValidation(answerExampleSeq) && answerExampleYN === "Y") {
            const exampleData_info = example_data.find((info, idx) => idx === answerExampleSeq);
            if (exampleData_info) {
                answerExample_info.index = answerExampleSeq;
                answerExample_info.exampleKind = exampleData_info.exampleKind;
                answerExample_info.exampleType = exampleData_info.exampleType;
                answerExample_info.exampleData = exampleData_info.exampleData;
                answerExample_info.uploadUrlYN = exampleData_info.uploadUrlYN;
                answerExample_info.uploadPath = exampleData_info.uploadPath;
            }

            console.log("(1) answerExample_info => ", answerExample_info);
        }

        if (answerExample_info.index > -1) {
            setAnswerExample(answerExample_info);
        } else {
            console.log("not setAnswerExample case");
        }

        if (example_data.length > 0) {
            setExampleData(example_data);
        } else {
            console.log("not setExampleData case");
        }

        if (congratulationImage_data.length > 0) {
            setCongratulationImageData(congratulationImage_data);
        } else {
            console.log("not setCongratulationImageData case");
        }
    }

    const onClickLoadQuiz = (e) => {
        if (e.target.files !== undefined && e.target.files !== null) {
            if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
                if (e.target.files[0].name !== undefined && e.target.files[0].name) {
                    let fileName = e.target.files[0].name;
                    let fileData = e.target.files[0];

                    let reader = new FileReader();
                    reader.onload = () => {
                        console.log(reader.result);
                        let file_info = JSON.parse(reader.result);
                        setQuizDataByLoadFile(file_info);
                    }
                    reader.readAsText(fileData);
                } else {
                    console.log("onClickLoadQuiz 1 : cancel was pressed");
                }
            } else {
                console.log("onClickLoadQuiz 2 : cancel was pressed");
            }
        } else {
            console.log("onClickLoadQuiz 3 : cancel was pressed");
        }
    }

    const onClickSaveQuiz = (isRealSave) => {
        console.log("테스트 : ", isRealSave, savedQuizInfo);

        if (isRealSave || (!isRealSave && savedQuizInfo === null)) {
            console.log("처음 내려받기 / 찐 저장버튼");

            if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
                // setAlertMessage("");
                setTopAlertMessage("");
                setMiddleAlertMessage("");
                setBottomAlertMessage("");
                setAlertKind(ConstData.ALERT_KIND.NONE);
            }

            let save_info = {};

            if (checkNumberValidation(modalQuiz_info.quizSeq)) {
                save_info.quizSeq = modalQuiz_info.quizSeq;
            }

            if (checkStringValidation(lQuizTitle)) {
                save_info.quizTitle = lQuizTitle;
            } else {
                // setAlertMessage(t("퀴즈_알림_메시지_16", { ns: "classTalk" }));
                setBottomAlertMessage(t("퀴즈_알림_메시지_16", { ns: "classTalk" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                return;
            }

            if (lQuestionData && lQuestionData.length > 0) {
                save_info.questionData = [];

                for (let i = 0; i < lQuestionData.length; i++) {
                    let quizQuestion_info = lQuestionData[i];
                    let isValidData = true;

                    if (!checkStringValidation(quizQuestion_info.questionKind)) {
                        isValidData = false;
                    }

                    if (!checkStringValidation(quizQuestion_info.questionDataType)) {
                        isValidData = false;
                    }

                    if (quizQuestion_info.questionDataType === stQuizDataType.URL) {
                        console.log("quizQuestion_info.questionDataType === stQuizDataType.URL");
                        if (!checkURLValidation(quizQuestion_info.questionData)) {
                            console.log("!checkURLValidation quizQuestion_info.questionData !!!!");
                            isValidData = false;
                            setBottomAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_17", { ns: "classTalk" })}`);
                            setAlertKind(ConstData.ALERT_KIND.DANGER);
                            return;
                        }
                    } else {
                        if (!checkStringValidation(quizQuestion_info.questionData)) {
                            isValidData = false;
                        }
                    }

                    if (!checkStringValidation(quizQuestion_info.uploadUrlYN)) {
                        isValidData = false;
                    } else {
                        if (quizQuestion_info.uploadUrlYN === "Y") {
                            if (checkConvertDataType(quizQuestion_info.questionDataType)) {
                                if (checkStringValidation(quizQuestion_info.uploadPath)) {
                                    console.log("(1) quizQuestion_info.questionDataType => ", quizQuestion_info.questionDataType);
                                    console.log("(1) quizQuestion_info.questionData => ", quizQuestion_info.questionData);
                                    quizQuestion_info.questionDataType = stQuizDataType.URL;
                                    quizQuestion_info.questionData = quizQuestion_info.uploadPath;
                                    console.log("(2) quizQuestion_info.questionDataType => ", quizQuestion_info.questionDataType);
                                    console.log("(2) quizQuestion_info.questionData => ", quizQuestion_info.questionData);
                                }
                            }
                        }
                    }

                    if (!isValidData) {
                        // setAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_18", { ns: "classTalk" })}`);
                        setBottomAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_18", { ns: "classTalk" })}`);
                        setAlertKind(ConstData.ALERT_KIND.DANGER);
                        return;
                    } else {
                        save_info.questionData.push({
                            questionKind: quizQuestion_info.questionKind,
                            questionDataType: quizQuestion_info.questionDataType,
                            questionData: quizQuestion_info.questionData,
                            uploadUrlYN: quizQuestion_info.uploadUrlYN
                        });
                    }
                }
            }

            if (checkStringValidation(lAnswerExampleYN)) {
                save_info.answerExampleYN = lAnswerExampleYN;
            } else {
                // setAlertMessage(t("퀴즈_알림_메시지_21", { ns: "classTalk" }));
                setBottomAlertMessage(t("퀴즈_알림_메시지_21", { ns: "classTalk" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                return;
            }

            if (lAnswerExampleYN === "N") {
                if (checkStringValidation(lAnswerKind)) {
                    save_info.answerKind = lAnswerKind;
                } else {
                    // setAlertMessage(`${t("퀴즈_알림_메시지_22", { ns: "classTalk" })} (Code.AK23)`);
                    setBottomAlertMessage(`${t("퀴즈_알림_메시지_22", { ns: "classTalk" })} (Code.AK23)`);
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    return;
                }

                if (checkStringValidation(lAnswerType)) {
                    save_info.answerType = lAnswerType;
                } else {
                    // setAlertMessage(`${t("퀴즈_알림_메시지_22", { ns: "classTalk" })} (Code.AT24)`);
                    setBottomAlertMessage(`${t("퀴즈_알림_메시지_22", { ns: "classTalk" })} (Code.AT24)`);
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    return;
                }

                if (checkStringValidation(lAnswerData)) {
                    save_info.answerData = lAnswerData;
                } else {
                    // setAlertMessage("퀴즈의 정답을 입력해 주세요. (Code.AD25)");
                    setBottomAlertMessage(`${t("퀴즈_알림_메시지_22", { ns: "classTalk" })} (Code.AD25)`);
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    return;
                }
            } else {
                if (lExampleData && lExampleData.length > 0) {
                    save_info.exampleData = [];

                    for (let i = 0; i < lExampleData.length; i++) {
                        let exampleData_info = lExampleData[i];
                        exampleData_info.exampleNo = i + 1;
                        exampleData_info.exampleNo = exampleData_info.exampleNo.toString();
                        let isValidData = true;

                        if (!checkStringValidation(exampleData_info.exampleKind)) {
                            isValidData = false;
                        }

                        if (!checkStringValidation(exampleData_info.exampleType)) {
                            isValidData = false;
                        }

                        if (exampleData_info.exampleType === stQuizDataType.URL) {
                            console.log("exampleData_info.exampleType === stQuizDataType.URL");
                            if (!checkURLValidation(exampleData_info.exampleData)) {
                                console.log("!checkURLValidation exampleData_info.exampleData !!!!");
                                isValidData = false;
                                setBottomAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_19", { ns: "classTalk" })}`);
                                setAlertKind(ConstData.ALERT_KIND.DANGER);
                                return;
                            }
                        } else {
                            if (!checkStringValidation(exampleData_info.exampleData)) {
                                isValidData = false;
                            }
                        }

                        if (!checkStringValidation(exampleData_info.uploadUrlYN)) {
                            isValidData = false;
                        } else {
                            if (exampleData_info.uploadUrlYN === "Y") {
                                if (checkConvertDataType(exampleData_info.exampleType)) {
                                    if (checkStringValidation(exampleData_info.uploadPath)) {
                                        console.log("(1) exampleData_info.exampleType => ", exampleData_info.exampleType);
                                        console.log("(1) exampleData_info.exampleData => ", exampleData_info.exampleData);
                                        exampleData_info.exampleType = stQuizDataType.URL;
                                        exampleData_info.exampleData = exampleData_info.uploadPath;
                                        console.log("(2) exampleData_info.exampleType => ", exampleData_info.exampleType);
                                        console.log("(2) exampleData_info.exampleData => ", exampleData_info.exampleData);
                                    }
                                }
                            }
                        }

                        if (!isValidData) {
                            // setAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_20", { ns: "classTalk" })}`);
                            setBottomAlertMessage(`${t("퀴즈의", { ns: "classTalk" })} ${i + 1}${t("퀴즈_알림_메시지_20", { ns: "classTalk" })}`);
                            setAlertKind(ConstData.ALERT_KIND.DANGER);
                            return;
                        } else {
                            save_info.exampleData.push({
                                exampleNo: exampleData_info.exampleNo,
                                exampleKind: exampleData_info.exampleKind,
                                exampleType: exampleData_info.exampleType,
                                exampleData: exampleData_info.exampleData,
                                uploadUrlYN: exampleData_info.uploadUrlYN
                            });
                        }
                    }
                }

                if (checkNumberValidation(lAnswerExample.index)) {
                    save_info.answerExampleSeq = lAnswerExample.index;

                    if (checkStringValidation(lAnswerExample.exampleKind)) {
                        save_info.answerKind = lAnswerExample.exampleKind;
                    }

                    if (checkStringValidation(lAnswerExample.exampleType)) {
                        save_info.answerType = lAnswerExample.exampleType;
                    }

                    if (checkStringValidation(lAnswerExample.exampleData)) {
                        save_info.answerData = lAnswerExample.exampleData;
                    }

                    if (checkStringValidation(lAnswerExample.uploadUrlYN)) {
                        if (lAnswerExample.uploadUrlYN === "Y") {
                            console.log("(1) save_info.answerType => ", save_info.answerType);
                            save_info.answerType = stQuizDataType.URL;
                            console.log("(2) save_info.answerType => ", save_info.answerData);
                            if (checkStringValidation(lAnswerExample.uploadPath)) {
                                console.log("(1) save_info.answerData => ", save_info.answerData);
                                save_info.answerData = lAnswerExample.uploadPath;
                                console.log("(2) save_info.answerData => ", save_info.answerData);
                            }
                        }
                    }

                    // save_info.answerExampleSeq += 1; // answerExampleNo에 1부터 표시되기 위해서...
                } else {
                    // setAlertMessage(t("퀴즈_알림_메시지_23", { ns: "classTalk" }));
                    setBottomAlertMessage(t("퀴즈_알림_메시지_23", { ns: "classTalk" }));
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    return;
                }
            }

            if (checkStringValidation(lCongratulationImageType)) {
                save_info.congratulationImageType = lCongratulationImageType;
            }

            if (isRealSave) {
                console.log("찐 저장버튼");
                if (save_info.quizSeq !== undefined) {
                    console.log("edit quiz case");
                    savedHandleActionRef.current = { selectedFunc: "EDIT_QUIZ", save_info };
                    setIsModifyAlertModalOpened(true);
                    //handleSelectedFunc({ selectedFunc: "EDIT_QUIZ", save_info });
                } else {
                    console.log("create quiz case");
                    handleSelectedFunc({ selectedFunc: "CREATE_QUIZ", save_info });
                }
            } else {
                console.log("내려받기 버튼");
                console.log(save_info);

                let data = JSON.stringify(save_info);
                console.log(data);

                downloadJsonData(data, "quiz_" + lQuizTitle + ".json");
            }
        } else {
            console.log("savedQuizInfo가 있는 상황. 내려받기 버튼 클릭");
            console.log(savedQuizInfo);

            let data = JSON.stringify(savedQuizInfo);
            console.log(data);

            downloadJsonData(data, "quiz_" + lQuizTitle + ".json");
        }
    }

    const downloadJsonData = (data, filename) => {
        let file = new Blob([data]);
        let fileUrl = window.URL.createObjectURL(file);
        let downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = fileUrl;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(fileUrl);

        /* (async function createJsonFile() {
            try {
                const res = await fetch(data);
                const blob = res.blob();

                const downloadUrl = window.URL.createObjectURL(blob);

                let downloadLink = document.createElement("a");
                downloadLink.download = filename;
                downloadLink.href = downloadUrl;
                downloadLink.style.display = "none";
                document.body.appendChild(downloadLink);
                downloadLink.click();

                document.body.removeChild(downloadLink);
                window.URL.revokeObjectURL(downloadUrl);
            } catch (err) {
                console.log("createJsonFile Err => ", err);
            }
        })(); */

        /* let csvFile;
        let fileUrl;
        let downloadLink;

        // 한글 처리를 해주기 위해 BOM 추가하기
        const BOM = "\uFEFF";
        data = BOM + data;

        csvFile = new Blob([data], { type: "text/csv" });
        fileUrl = window.URL.createObjectURL(csvFile);
        downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = fileUrl;
        downloadLink.style.display = "none";
        // downloadLink.
        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(fileUrl); */
    }

    const onClickRemoveQuiz = () => {
        let remove_info = {};

        if (checkNumberValidation(modalQuiz_info.quizSeq)) {
            remove_info.quizSeq = modalQuiz_info.quizSeq;
        }

        if (checkStringValidation(lQuizTitle)) {
            remove_info.quizTitle = lQuizTitle;
        }

        if (remove_info.quizSeq !== undefined) {
            console.log("remove quiz case");
            savedHandleActionRef.current = { selectedFunc: "REMOVE_QUIZ", remove_info };
            setIsRemoveAlertModalOpened(true);
            //handleSelectedFunc({ selectedFunc: "REMOVE_QUIZ", remove_info });
        }
    }

    const handleChangeQuestionData = (question_data) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            //setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let list_arr = lQuestionData.map((info, idx) => {
            if (idx !== question_data.index) return info;
            else {
                return {
                    questionKind: question_data.questionKind,
                    questionDataType: question_data.questionDataType,
                    questionData: question_data.questionData,
                    uploadUrlYN: question_data.uploadUrlYN,
                    uploadPath: question_data.uploadPath
                };
            }
        });
        setQuestionData(list_arr);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const handleChangeExampleData = (example_data) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            //setAlertMessage("");
            setTopAlertMessage("");
            setMiddleAlertMessage("");
            setBottomAlertMessage("");
            setAlertKind(ConstData.ALERT_KIND.NONE);
        }

        let list_arr = lExampleData.map((info, idx) => {
            if (idx !== example_data.index) return info;
            else {
                return {
                    exampleKind: example_data.exampleKind,
                    exampleType: example_data.exampleType,
                    exampleData: example_data.exampleData,
                    uploadUrlYN: example_data.uploadUrlYN,
                    uploadPath: example_data.uploadPath
                };
            }
        });
        setExampleData(list_arr);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const handleChangeRecentlyExampleKind = (kind) => {
        let exampleKind = kind;
        let exampleType = stQuizDataType.None;

        switch (exampleKind) {
            case stQuizDataKind.Text:
                exampleType = stQuizDataType.None;
                break;

            case stQuizDataKind.Image:
            case stQuizDataKind.Audio:
            case stQuizDataKind.Video:
                exampleType = stQuizDataType.File;
                break;

            default:
                exampleType = stQuizDataType.None;
                break;
        }

        setExampleKind(exampleKind);
        setExampleType(exampleType);

        if (!isQuizModified) setIsQuizModified(true);
    }

    const handleQuestionFileUpload = (selectedFunc, file_info) => {
        if (checkNumberValidation(modalQuiz_info.quizSeq)) {
            if (file_info.kind === stQuizFileUploadActionKind.RemoveQuestionFile) {
                console.log("remove question file case");
                savedHandleActionRef.current = { selectedFunc, file_info: { ...file_info, quizSeq: modalQuiz_info.quizSeq } };
                setIsRemoveUrlAlertModalOpened(true);
            } else {
                handleSelectedFunc({ selectedFunc, file_info: { ...file_info, quizSeq: modalQuiz_info.quizSeq } });
            }
        } else {
            handleSelectedFunc({ selectedFunc, file_info });
        }

        if (!isQuizModified) setIsQuizModified(true);
    }

    const handleExampleFileUpload = (selectedFunc, file_info) => {
        if (checkNumberValidation(modalQuiz_info.quizSeq)) {
            if (file_info.kind === stQuizFileUploadActionKind.RemoveExampleFile) {
                console.log("remove example file case");
                savedHandleActionRef.current = { selectedFunc, file_info: { ...file_info, quizSeq: modalQuiz_info.quizSeq } };
                setIsRemoveUrlAlertModalOpened(true);
            } else {
                handleSelectedFunc({ selectedFunc, file_info: { ...file_info, quizSeq: modalQuiz_info.quizSeq } });
            }
        } else {
            handleSelectedFunc({ selectedFunc, file_info });
        }

        if (!isQuizModified) setIsQuizModified(true);
    }

    const handleModifyAlertModal = (isWillModifyQuiz) => {
        console.log("handleModifyAlertModal - ", isWillModifyQuiz, savedHandleActionRef.current);
        if (isWillModifyQuiz) {
            let handleSelectedAction_info = {
                selectedFunc: savedHandleActionRef.current.selectedFunc,
                save_info: savedHandleActionRef.current.save_info
            };
            console.log("handleSelectedAction_info => ", handleSelectedAction_info);
            handleSelectedFunc(handleSelectedAction_info);
        }

        setSavedQuizInfo(savedHandleActionRef.current.save_info);

        setIsQuizModified(false);   // 퀴즈가 저장되었기때문에 json 파일로 저장 가능한 상태.
        setIsModifyAlertModalOpened(false);
        savedHandleActionRef.current = null;
    }

    const handleRemoveAlertModal = (isWillRemoveQuiz) => {
        console.log("handleRemoveAlertModal - ", isWillRemoveQuiz, savedHandleActionRef.current);
        if (isWillRemoveQuiz) {
            let handleSelectedAction_info = {
                selectedFunc: savedHandleActionRef.current.selectedFunc,
                remove_info: savedHandleActionRef.current.remove_info
            };
            console.log("handleSelectedAction_info => ", handleSelectedAction_info);
            handleSelectedFunc(handleSelectedAction_info);
        }

        setIsQuizModified(true);    // 퀴즈가 삭제되었기때문에 json 파일로 저장 불가능한 상태. 
        setIsRemoveAlertModalOpened(false);
        savedHandleActionRef.current = null;
    }

    const handleRemoveUrlAlertModal = (isWillRemoveUrl) => {
        console.log("handleRemoveUrlAlertModal - ", isWillRemoveUrl, savedHandleActionRef.current);
        if (isWillRemoveUrl) {
            let handleSelectedAction_info = {
                selectedFunc: savedHandleActionRef.current.selectedFunc,
                file_info: savedHandleActionRef.current.file_info
            };
            console.log("handleSelectedAction_info => ", handleSelectedAction_info);
            handleSelectedFunc(handleSelectedAction_info);
        }

        setIsQuizModified(true);   // 퀴즈 정보가 변경되었기때문에 json 파일로 저장 불가능한 상태.
        setIsRemoveUrlAlertModalOpened(false);
        savedHandleActionRef.current = null;
    }

    return (
        <div className="quiz-modal scroll-show-active light">
            {/* <QuizModalHeader /> */}
            <div className="title-area">
                <QuizModalTitle modalQuiz_info={modalQuiz_info} />
            </div>
            <div className="content-area">
                {
                    lTopAlertMessage !== "" &&
                    <Alerts kind={lAlertKind} message={lTopAlertMessage} />
                }
                <div className="question-area">
                    <div className="input-group mb-2">
                        <span className="input-group-text border-0">Title.</span>
                        <input type="text" className="form-control" value={lQuizTitle} onChange={onChangeQuizTitle} />
                    </div>
                    <div className="d-flex w-100 justify-content-between">
                        <span style={{ width: '10%', maxWidth: '80px' }}></span>
                        <span className="input-description pentalk">* {t("1자_50자_텍스트_설명", { ns: "common" })}</span>
                    </div>
                    <hr style={{ marginBottom: '1.25rem' }} />
                    {
                        lQuestionData.map((info, index) => (
                            <QuizQuestionData
                                key={"question-data-" + index}
                                index={index}
                                quizQuestion_info={info}
                                lQuestionUploadInfo={lQuestionUploadInfo}
                                lAlertKind={lAlertKind}
                                lAlertMessage={lAlertMessage}
                                setAlertKind={setAlertKind}
                                setAlertMessage={setMiddleAlertMessage}
                                handleChangeQuestionData={handleChangeQuestionData}
                                handleRemoveQuestionData={onClickRemoveQuestionData}
                                handleQuestionFileUpload={handleQuestionFileUpload}
                            />
                        ))
                    }
                    <div className="add-btn-area">
                        <button type="button" className="hiclasstv-btn-transparent hiclasstv-blue-text p-0" onClick={onClickAddQuestionData}>
                            <FiPlusCircle className="font-lg" />
                            <span className="font-weight-bold ml-2">{t("질문_추가", { ns: "classTalk" })}</span>
                        </button>
                    </div>
                    <div className="d-flex flex-column w-100 mt-1">
                        <div className="d-flex w-100 justify-content-between mt-1">
                            <span style={{ width: '10%', maxWidth: '80px' }}></span>
                            <span className="input-description pentalk">{t("퀴즈_설명_1", { ns: "classTalk" })}</span>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-1">
                            <span style={{ width: '10%', maxWidth: '80px' }}></span>
                            <span className="input-description pentalk">{t("퀴즈_설명_2", { ns: "classTalk" })}</span>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-1">
                            <span style={{ width: '10%', maxWidth: '80px' }}></span>
                            <span className="input-description pentalk">{t("퀴즈_설명_3", { ns: "classTalk" })}</span>
                        </div>
                        <div className="d-flex w-100 justify-content-between mt-1">
                            <span style={{ width: '10%', maxWidth: '80px' }}></span>
                            <span className="input-description pentalk">{t("퀴즈_질문_설명", { ns: "classTalk" })}</span>
                        </div>
                    </div>
                    <hr />
                </div>
                {
                    lMiddleAlertMessage !== "" &&
                    <Alerts kind={lAlertKind} message={lMiddleAlertMessage} />
                }
                <div className="answer-area">
                    <div className="input-group mb-1">
                        <span className="input-group-text border-0">A.</span>
                        <input type="radio" className="form-check-input" id="answerExampleY" value={"Y"} checked={lAnswerExampleYN === "Y"} onChange={onChangeAnswerExampleYN} />
                        <label className="form-check-label" htmlFor="answerExampleY">{t("객관식", { ns: "classTalk" })}</label>
                        <input type="radio" className="form-check-input" id="answerExampleN" value={"N"} checked={lAnswerExampleYN === "N"} onChange={onChangeAnswerExampleYN} />
                        <label className="form-check-label" htmlFor="answerExampleN">{t("주관식", { ns: "classTalk" })}</label>
                    </div>
                </div>
                {
                    lAnswerExampleYN === "N" ?
                        <>
                            <div className="answer-area" type="answer-data">
                                <div className="input-group mb-2">
                                    <input type="text" className="form-control" value={lAnswerData} onChange={onChangeAnswerData} />
                                </div>
                                <span className="input-description pentalk w-100">* {t("1자_50자_텍스트_설명", { ns: "common" })}</span>
                            </div>
                        </> :
                        <>
                            <div className="example-area" type="example-data">
                                {
                                    lExampleData.map((info, index) => (
                                        <QuizExampleData
                                            key={"example-data-" + index}
                                            index={index}
                                            isAnswer={lAnswerExample.index === index}
                                            exampleData_info={info}
                                            lExampleUploadInfo={lExampleUploadInfo}
                                            lAlertKind={lAlertKind}
                                            lAlertMessage={lAlertMessage}
                                            setAlertKind={setAlertKind}
                                            setAlertMessage={setBottomAlertMessage}
                                            onChangeAnswerData={onChangeAnswerData}
                                            handleChangeExampleData={handleChangeExampleData}
                                            handleRemoveExampleData={onClickRemoveExampleData}
                                            handleChangeRecentlyExampleKind={handleChangeRecentlyExampleKind}
                                            handleExampleFileUpload={handleExampleFileUpload}
                                        />
                                    ))
                                }
                                <div className="add-btn-area">
                                    <button type="button" className="hiclasstv-btn-transparent hiclasstv-blue-text p-0" onClick={onClickAddExampleData}>
                                        <FiPlusCircle className="font-lg" />
                                        <span className="font-weight-bold ml-2">{t("예제_추가", { ns: "classTalk" })}</span>
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-100 mt-1">
                                <div className="d-flex w-100 justify-content-between mt-1">
                                    <span style={{ width: '10%', maxWidth: '80px' }}></span>
                                    <span className="input-description pentalk">{t("퀴즈_설명_1", { ns: "classTalk" })}</span>
                                </div>
                                <div className="d-flex w-100 justify-content-between mt-1">
                                    <span style={{ width: '10%', maxWidth: '80px' }}></span>
                                    <span className="input-description pentalk">{t("퀴즈_설명_2", { ns: "classTalk" })}</span>
                                </div>
                                <div className="d-flex w-100 justify-content-between mt-1">
                                    <span style={{ width: '10%', maxWidth: '80px' }}></span>
                                    <span className="input-description pentalk">{t("퀴즈_설명_3", { ns: "classTalk" })}</span>
                                </div>
                                <div className="d-flex w-100 justify-content-between mt-1">
                                    <span style={{ width: '10%', maxWidth: '80px' }}></span>
                                    <span className="input-description pentalk">{t("퀴즈_예제_설명", { ns: "classTalk" })}</span>
                                </div>
                            </div>
                        </>
                }
                {
                    lBottomAlertMessage !== "" &&
                    <Alerts kind={lAlertKind} message={lBottomAlertMessage} />
                }
            </div>
            <div className="btn-area justify-content-end">
                {
                    modalQuiz_info && modalQuiz_info.quizSeq > 0 ?
                        <button type="button" className="btn modal-btn modal-btn-primary" onClick={() => onClickSaveQuiz(false)} disabled={isQuizModified ? true : false}>
                            {t("내려받기", { ns: "classTalk" })}
                        </button> :
                        <>
                            <button type="file" className="btn modal-btn modal-btn-primary" onClick={onClickLoadQuiz}>
                                <input
                                    id="load_file"
                                    type="file"
                                    className="form-control"
                                    style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0, 0, 0, 0)' }}
                                    value=""
                                    onChange={onClickLoadQuiz}
                                />
                                <label className="file-input-label m-0 button" htmlFor="load_file">
                                    {t("불러오기", { ns: "classTalk" })}
                                </label>
                            </button>
                        </>
                }
                <div style={{ borderLeft: '1px solid var(--h-grey)', padding: '0 0 0 .5rem', margin: '0 .5rem' }}>
                    <button type="button" className="btn modal-btn modal-btn-success" onClick={() => onClickSaveQuiz(true)}>
                        {t("저장", { ns: "common" })}
                    </button>
                    {
                        modalQuiz_info.quizSeq > -1 &&
                        <button type="button" className="btn modal-btn modal-btn-danger" onClick={onClickRemoveQuiz}>
                            {t("삭제", { ns: "common" })}
                        </button>
                    }
                </div>
            </div>
            {
                isModifyAlertModalOpened &&
                <ModifyAlertModal
                    isModalOpened={isModifyAlertModalOpened}
                    handleAction_info={savedHandleActionRef.current}
                    handleModifyAlertModal={handleModifyAlertModal}
                />
            }
            {
                isRemoveAlertModalOpened &&
                <RemoveAlertModal
                    isModalOpened={isRemoveAlertModalOpened}
                    handleAction_info={savedHandleActionRef.current}
                    handleRemoveAlertModal={handleRemoveAlertModal}
                />
            }
            {
                isRemoveUrlAlertModalOpened &&
                <RemoveUrlAlertModal
                    isModalOpened={isRemoveUrlAlertModalOpened}
                    handleAction_info={savedHandleActionRef.current}
                    handleRemoveUrlAlertModal={handleRemoveUrlAlertModal}
                />
            }
        </div>
    );
}

export default QuizModal;
import React from 'react';
import { useTranslation } from "react-i18next";

const ShowIdList = ({ isExistUserInfo, handleSelectedFunc, list_findUserId}) => {
    const { t } = useTranslation();

    return(
        <div className="d-flex flex-column align-items-center justify-content-center w-100 my-2">
            <div className="d-flex flex-column text-center font-md w-100 py-4 px-2">
                {
                    isExistUserInfo ? 
                        <>
                            <span className="small">{t("ID_찾기_안내_문구_1", { ns: "user" })}</span>
                            <ul className="list-group w-100 mt-2">
                                {
                                    list_findUserId.map(
                                        (info, index) => (
                                            <li className="d-flex list-group-item align-items-center justify-content-between" key={index}>
                                                <div className="full mr-2">
                                                    <span className="font-weight-bold" title={info.USER_ID}>{info.USER_ID}</span>
                                                </div>
                                                <span className="fit-content">
                                                    {t("가입일자", { ns: "user" })} : {info.USER_SIGNUP_DT.substr(0, 10)}
                                                </span>
                                            </li>
                                        )
                                    )
                                }
                            </ul>
                        </>: 
                        <span className="small">
                            {t("회원정보_찾기_안내_문구_1", { ns: "user" })}
                        </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc:"LOGIN"})}>{t("로그인", { ns: "user" })}</button>
                {
                    isExistUserInfo && 
                    <button className="btn btn-warning btn-sm" onClick={() => handleSelectedFunc({selectedFunc:"FIND", type:"PW"})}>{t("비밀번호_찾기", { ns: "user" })}</button>
                        
                }
            </div>
        </div>
    );
}

export default ShowIdList;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import WebConfigSubDomainInfo from './WebConfigSubDomainInfo';
import WebPagination from '../../etc/WebPagination';

const WebConfigSubDomainList = ({ userKind, isStandardType, list_subDomain, goToConfigPage, goToConfigMemberPage, goToPassListPage, toggleModalOpened, togglePWModalOpened, toggleSubDomainAdminModalOpened, toggleCreateSubDomainAdminModalOpened }) => {
    const { t } = useTranslation();
    
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState((page - 1) * limit);
    const [blockNum, setBlockNum] = useState(0);

    useEffect(() => {
        let offset = (page - 1) * limit;
        setOffset(offset);
    }, [page, limit]);

    useEffect(() => {
        return () => {
            setPage(1);
            setOffset((page - 1) * limit);
        }
    }, [list_subDomain]);

    return (
        list_subDomain && list_subDomain.length > 0 ?
            <>
                <WebPagination
                    total={list_subDomain.length <= 0 ? 1 : list_subDomain.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    blockNum={blockNum}
                    setBlockNum={setBlockNum}
                />
                <table className="d-flex flex-column table-sm flex-list mb-0">
                    <thead>
                        <tr className="d-flex">
                            <th className="area-35">
                                <b>{t("서브_도메인_이름", { ns: "admin" })}</b>
                            </th>
                            <th className="area-25">
                                <div className="only-web w-100">
                                    <span className="area-60"><b>{t("접속현황", { ns: "admin" })}</b></span>
                                    <span className="area-40"><b>{t("멤버", { ns: "common" })}</b></span>
                                </div>
                                <div className="only-mobile w-100" style={{ justifyContent: 'center' }}>
                                    <b>{t("사용자", { ns: "admin" })}</b>
                                </div>
                            </th>
                            <th className="area-25">
                                <div className="only-web w-100">
                                    <span className="area-50"><b>{t("팀업", { ns: "chat" })}</b></span>
                                    <span className="area-50"><b>{t("클래스", { ns: "live" })}</b></span>
                                </div>
                                <div className="only-mobile w-100">
                                    <b>{t("팀업", { ns: "chat" })}/{t("클래스", { ns: "live" })}</b>
                                </div>
                            </th>
                            <th className="area-15">
                                {
                                    isStandardType &&
                                    <b>{t("이용권", { ns: "pass" })}</b>
                                }
                            </th>
                        </tr>
                    </thead>
                    {
                        // list_subDomain.map((subDomain, idx) => (
                        list_subDomain.slice(offset, offset + limit).map((subDomain, idx) => (
                            <WebConfigSubDomainInfo
                                key={"config-" + subDomain.subDomainSeq}
                                idx={idx}
                                userKind={userKind}
                                isStandardType={isStandardType}
                                subDomain_info={subDomain}
                                goToConfigPage={goToConfigPage}
                                goToConfigMemberPage={goToConfigMemberPage}
                                goToPassListPage={goToPassListPage}
                                toggleModalOpened={toggleModalOpened}
                                togglePWModalOpened={togglePWModalOpened}
                                toggleSubDomainAdminModalOpened={toggleSubDomainAdminModalOpened}
                                toggleCreateSubDomainAdminModalOpened={toggleCreateSubDomainAdminModalOpened}
                            />
                        ))
                    }
                </table>
            </> :
            <div className="d-flex view m-3 justify-content-center grey-text">
                {t("서브_도메인_목록_없음", { ns: "admin" })}
            </div>
    );
}

export default WebConfigSubDomainList;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse } from 'reactstrap';
import { BsX } from 'react-icons/bs';
import { FaSortUp, FaSortDown } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';
import ConstData from '../../../lib/ConstData';

const EditFriendsList =  ({list_friends, list_rcvRequestFriends, list_sendRequestFriends, handleSelectedFunc}) => {
    const { t } = useTranslation();

    const [isRcvOpened, setIsRcvOpened] = useState(false);
    const [isSendOpened, setIsSendOpened] = useState(false);
    const [isFriendsOpened, setIsFriendsOpened] = useState(false);

    useEffect(() => {
        if (list_friends.length > 0) {
            setIsFriendsOpened(true);
        }
    }, [list_friends]);

    useEffect(() => {
        if (list_rcvRequestFriends.length > 0) {
            setIsRcvOpened(true);
        }
    }, [list_rcvRequestFriends]);

    useEffect(() => {
        if (list_sendRequestFriends.length > 0) {
            setIsSendOpened(true);
        }
    }, [list_sendRequestFriends]);
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area b-none">
                <span>
                    <b>{t("친구_관리", { ns: "friend" })}</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="title-area sub justify-content-between hiclasstv-blue white-text">
                <b>{t("나에게_요청한_사용자", { ns: "friend" })}</b>
                <button type="button" className="button py-1" id="rcvFriends_toggler" disabled={list_rcvRequestFriends.length <= 0 ? true : false}>
                    {
                        list_rcvRequestFriends.length > 0 && isRcvOpened ? 
                            <FaSortUp className="d-flex font-md white-text" aria-hidden="true" /> : 
                            <FaSortDown className="d-flex font-md white-text" aria-hidden="true" />
                    }
                </button>
            </div>
            {
                list_rcvRequestFriends.length > 0 ?
                    <UncontrolledCollapse toggler="#rcvFriends_toggler" defaultOpen={true} onEntering={() => setIsRcvOpened(true)} onExiting={() => setIsRcvOpened(false)}>
                        <div className="list-group">
                            {
                                list_rcvRequestFriends.map(
                                    friend => (
                                        <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={"rcv_" + friend.userSeq}>
                                            <div className="d-flex area-15 fit-content">
                                                <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                            </div>
                                            <div className="text-area flex-column align-items-start">
                                                <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                            </div>
                                            <div className="d-flex flex-column area-25 ml-2" onClick={handlePreventClick}>
                                                <button type="button" className="btn btn-sm green white-text m-0 mt-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "ACCEPT", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("수락", { ns: "friend" })}</button>
                                                <button type="button" className="btn btn-sm red white-text m-0 my-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "REJECT", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("거절", { ns: "common" })}</button>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </UncontrolledCollapse> :
                    <div className="text-center grey-text p-2">
                        <b>{t("신규_요청_내역_없음", { ns: "friend" })}</b>
                    </div>
            }
            
            <div className="title-area sub justify-content-between hiclasstv-blue white-text">
                <b>{t("내가_요청한_사용자", { ns: "friend" })}</b>
                <button type="button" className="button py-1" id="sendFriends_toggler" disabled={list_sendRequestFriends.length <= 0 ? true : false}>
                    {
                        list_sendRequestFriends.length > 0 && isSendOpened ? 
                            <FaSortUp className="d-flex font-md white-text" aria-hidden="true" /> : 
                            <FaSortDown className="d-flex font-md white-text" aria-hidden="true" />
                    }
                </button>
            </div>
            {
                list_sendRequestFriends.length > 0 ?
                    <UncontrolledCollapse toggler="#sendFriends_toggler" defaultOpen={true} onEntering={() => setIsSendOpened(true)} onExiting={() => setIsSendOpened(false)}>
                        <div className="list-group">
                            {
                                list_sendRequestFriends.map(
                                    friend => (
                                        <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={"send_" + friend.userSeq}>
                                            <div className="d-flex area-15 fit-content">
                                                <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                            </div>
                                            <div className="text-area flex-column align-items-start" style={{minWidth:'30%'}}>
                                                <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                            </div>
                                            <div className="d-flex flex-column align-items-end area-45 ml-2" style={{minWidth:'max-content'}}>
                                                <span style={{fontSize:'70%'}}>
                                                    {
                                                        friend.applyStatus === ConstData.FRIEND_APPLY_STATUS.APPLY ? t("수락대기", { ns: "friend" }) : t("거절됨", { ns: "friend" })
                                                    }
                                                </span>
                                                <div>
                                                    {
                                                        friend.applyStatus === ConstData.FRIEND_APPLY_STATUS.APPLY ?
                                                            <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("취소", { ns: "common" })}</button> :
                                                            <>
                                                                <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "APPLY_SINGLE", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("신청", { ns: "common" })}</button>
                                                                <button type="button" className="btn btn-sm red white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("취소", { ns: "common" })}</button>
                                                            </>
                                                            
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </UncontrolledCollapse> :
                    <div className="text-center grey-text p-2">
                        {t("요청_내역_없음", { ns: "friend" })}
                    </div>
            }            
            <div className="title-area sub justify-content-between hiclasstv-blue white-text">
                <b>{t("친구", { ns: "friend" })}</b>
                <button type="button" className="button py-1" id="friends_toggler" disabled={list_friends.length <= 0 ? true : false}>
                    {
                        list_friends.length > 0 && isFriendsOpened ? 
                        <FaSortUp className="d-flex font-md white-text" aria-hidden="true" /> : 
                        <FaSortDown className="d-flex font-md white-text" aria-hidden="true" />
                    }
                </button>
            </div>
            {
                list_friends.length > 0 ?
                    <UncontrolledCollapse toggler="#friends_toggler" defaultOpen={true} onEntering={() => setIsFriendsOpened(true)} onExiting={() => setIsFriendsOpened(false)}>
                        <div className="list-group">
                            {
                                list_friends.map(
                                    friend => (
                                        <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={friend.userSeq}>
                                            <div className="d-flex area-15 fit-content">
                                                <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                            </div>
                                            <div className="text-area flex-column align-items-start">
                                                <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                                <span className="hiclass-text hiclasstv-darkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                            </div>
                                            <div className="d-flex flex-column area-25 ml-2">
                                                <button type="button" className="btn btn-sm red white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "DELETE", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("삭제", { ns: "common" })}</button>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </UncontrolledCollapse> :
                    <div className="text-center grey-text p-2">
                        {t("친구_목록_없음", { ns: "friend" })} 
                    </div>
            }
            
        </div>
    );
}
export default EditFriendsList;
import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import i18n from "../locales/config";

import createRequestThunk from "../lib/createRequestThunk";
import { callEoForSendAction, callEoForSendWhisperAction } from "../lib/EoBrowserSendUtil";

import { ctActionType } from "../lib/ConstCommand";
import { ctRcvKind, ctBubbleKind, enTokenCMD, enMQType } from "../lib/classTalk/CodeEnum";

import { upload_file } from "../lib/api/file";

export const WHISPER_STATE_INIT             = "whisper/WHISPER_STATE_INIT";

const WHISPER_INIT                          = "whisper/WHISPER_INIT";

const MQ_SEND_WHISPER_INIT_DATA             = "whisper/MQ_SEND_WHISPER_INIT_DATA";

const APPEND_SEND_MESSAGE                   = "whisper/APPEND_SEND_MESSAGE";
const APPEND_SEND_AUDIO_MEMO                = "whisper/APPEND_SEND_AUDIO_MEMO";
const APPEND_SEND_FILE_SHARE                = "whisper/APPEND_SEND_FILE_SHARE";
const APPEND_SEND_FILE_SHARE_SUCCESS        = "whisper/APPEND_SEND_FILE_SHARE_SUCCESS";

const APPEND_RECEIVE_TEACHER_MESSAGE        = "whisper/APPEND_RECEIVE_TEACHER_MESSAGE";
const APPEND_RECEIVE_STUDENT_MESSAGE        = "whisper/APPEND_RECEIVE_STUDENT_MESSAGE";

const EMOTICON_TOGGLE_OPENED                = "whisper/EMOTICON_TOGGLE_OPENED";
const EMOTICON_SELECTED_CANCEL              = "whisper/EMOTICON_SELECT_CANCEL";
const SELECT_EMOTICON_SEQ                   = "whisper/SELECT_EMOTICON_SEQ";

const ADD_FUNCTION_TOGGLE_OPENED            = "whisper/ADD_FUNCTION_TOGGLE_OPENED";
const SELECT_FUNCTION_SEQ                   = "whisper/SELECT_FUNCTION_SEQ";

const VOICE_MEMO_TOGGLE_CLOSED              = "whisper/VOICE_MEMO_TOGGLE_CLOSED";

const INK_MEMO_TOGGLE_OPENED                = "whisper/INK_MEMO_TOGGLE_OPENED";
const IMG_THUMBNAIL_CLICK                   = "whisper/IMG_THUMBNAIL_CLICK";

const FILE_SHARE_TOGGLE                     = "whisper/FILE_SHARE_TOGGLE";
const FILE_OPEN_TOGGLE                      = "whisper/FILE_OPEN_TOGGLE";
const FILE_SHARE_RESULT                     = "whisper/FILE_SHARE_RESULT";
const FILE_DOWNLOADING                      = "whisper/FILE_DOWNLOADING";

export const whisperPopupInitialize         = createAction(WHISPER_INIT);

export const mqSendInitData                 = createAction(MQ_SEND_WHISPER_INIT_DATA);

export const appendSendMessage              = createAction(APPEND_SEND_MESSAGE);
export const appendSendAudioMemo            = createAction(APPEND_SEND_AUDIO_MEMO);
export const appendSendFileShare            = createRequestThunk(APPEND_SEND_FILE_SHARE, upload_file);

export const appendReceiveTeacherMessage    = createAction(APPEND_RECEIVE_TEACHER_MESSAGE);
export const appendReceiveStudentMessage    = createAction(APPEND_RECEIVE_STUDENT_MESSAGE);

export const emoticonSelectedToggle         = createAction(EMOTICON_TOGGLE_OPENED);
export const emoticonSelectedCancel         = createAction(EMOTICON_SELECTED_CANCEL);
export const selectEmoticonSeq              = createAction(SELECT_EMOTICON_SEQ);

export const addFunctionOpenedToggle        = createAction(ADD_FUNCTION_TOGGLE_OPENED);
export const selectFunctionSeq              = createAction(SELECT_FUNCTION_SEQ);

// function menu
export const voiceMemoClosedToggle          = createAction(VOICE_MEMO_TOGGLE_CLOSED);

export const inkMemoOpenedToggle            = createAction(INK_MEMO_TOGGLE_OPENED);
export const imgThumbnailClick              = createAction(IMG_THUMBNAIL_CLICK);

export const fileShareToggle                = createAction(FILE_SHARE_TOGGLE);
export const fileOpenToggle                 = createAction(FILE_OPEN_TOGGLE); 
export const fileShareResult                = createAction(FILE_SHARE_RESULT); 
export const fileDownloading                = createAction(FILE_DOWNLOADING);

const initialState = {
    mqSendKey           : "",

    isEmoticonSelected  : false,
    selectedEmoticonSeq : -1,
    selectedFunctionSeq : -1,
    isAudioOpened       : false,
    audioStatus         : "STOPED",
    
    isAddFunctionOpened : false,
    isInkMemoOpened     : false, // ink memo 창

    userInfo            : "",
    stdInfo             : "",

    whisperMsg          : []
}

const whisper = handleActions({
    [WHISPER_STATE_INIT]: (state, action) => {
        return {
            ...state,
            
            mqSendKey           : "",

            isEmoticonSelected  : false,
            selectedEmoticonSeq : -1,
            selectedFunctionSeq : -1,
            isAudioOpened       : false,
            audioStatus         : "STOPED",
            
            isAddFunctionOpened : false,
            isInkMemoOpened     : false, // ink memo 창

            userInfo            : "",
            stdInfo             : "",

            whisperMsg          : []
        }
    },

    [WHISPER_INIT]: (state, action) => {
        console.log("WHISPER_INIT");
        const { stdSeq } = action.payload;

        callEoForSendWhisperAction("initDone", stdSeq);

        return {
            ...state,
            stdInfo : {
                stdSeq
            }
        }
    },

    [MQ_SEND_WHISPER_INIT_DATA]: (state, action) => {
        console.log("MQ_SEND_WHISPER_INIT_DATA - ", action.payload);
        const { data } = action.payload;

        return {
            ...state,
            userInfo    : data.userInfo,
            stdInfo     : {
                ...state.stdInfo,
                stdNickname: data.data.stdNickname,
                stdProfileImg: data.data.profileImg //Number(data.profileImg)
            },
            whisperMsg : data.data.msg
        }
    },

    [APPEND_SEND_MESSAGE]: (state, action) => {
        const { msgText } = action.payload;
        const { userInfo, stdInfo } = state;
        const { userNickname } = userInfo;
        console.log("APPEND_SEND_MESSAGE - ", action.payload);

        let msgInfo = {
            kind    : ctBubbleKind.SendMsg,
            msgInfo : { 
                rcvKind     : ctRcvKind.Teacher,
                text        : msgText, 
                emoticSeq   : state.selectedEmoticonSeq, 
                urlInfo     : ""
            }
        };

        callEoForSendAction("message_talk", { text: msgText, emoticSeq: state.selectedEmoticonSeq, userNickname }, stdInfo.stdSeq);

        return {
            ...state,
            whisperMsg          : state.whisperMsg.concat(msgInfo),
            isEmoticonSelected  : false,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false
        }
    },

    [APPEND_SEND_AUDIO_MEMO]: (state, action) => {
        const { url, data } = action.payload;
        const { userInfo, stdInfo } = state;
        const { userSeq, userNickname } = userInfo;
        console.log("APPEND_SEND_AUDIO_MEMO - ", action.payload);

        let msgInfo = {
            kind    : ctBubbleKind.SendMsg,
            msgInfo : { 
                rcvKind : ctRcvKind.Teacher,
                kind    : "audio_memo", 
                text    : i18n.t("음성메모", { ns: "classTalk" }), 
                urlInfo : url
            }
        };

        callEoForSendAction("audio_memo", { voiceData:data, userSeq, userNickname }, stdInfo.stdSeq);

        return {
            ...state,
            whisperMsg          : state.whisperMsg.concat(msgInfo),                    
            isAudioOpened       : false,
            isAddFunctionOpened : false
        }
    },

    [APPEND_SEND_FILE_SHARE_SUCCESS]: (state, action) => {
        const { filename } = action.parms;
        const { isSuccessed } = action.payload;
        const { stdInfo, userInfo } = state;
        const { userNickname } = userInfo;
        console.log("APPEND_SEND_FILE_SHARE_SUCCESS");

        if (isSuccessed) {
            const { isUploadSuccess } = action.payload;
            
            if (isUploadSuccess) {
                callEoForSendAction("whisperFileShare", { filename, userNickname }, stdInfo.stdSeq);

                const { path } = action.payload;

                let msgInfo = {
                    kind : ctBubbleKind.SendMsg,
                    msgInfo : {
                        rcvKind: ctRcvKind.Teacher,
                        kind: "file_share",
                        text: filename + i18n.t("파일_전송_완료_알림", { ns: "classTalk" }),
                        urlInfo: path
                    }
                };        

                return {
                    ...state,
                    whisperMsg          : state.whisperMsg.concat(msgInfo),
                    isAddFunctionOpened : false
                }
            } else {
                console.log("isUploadSuccess is ", isUploadSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("isSuccessed is ",isSuccessed);

            return {
                ...state
            }
        }
    },

    [APPEND_RECEIVE_TEACHER_MESSAGE]: (state, action) => {
        const { msgInfo } = action.payload;
        console.log("APPEND_RECEIVE_TEACHER_MESSAGE - ", action.payload);

        return {
            ...state,
            whisperMsg : state.whisperMsg.concat({
                kind : ctBubbleKind.SendMsg,
                msgInfo
            })
        }

    }, 

    [APPEND_RECEIVE_STUDENT_MESSAGE]: (state, action) => {
        const { msgInfo } = action.payload;
        console.log("APPEND_RECEIVE_STUDENT_MESSAGE - ", action.payload);

        return {
            ...state,
            whisperMsg : state.whisperMsg.concat({
                kind : ctBubbleKind.RcvWhisper,
                msgInfo
            })
        }
    },

    [EMOTICON_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected  : !state.isEmoticonSelected,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [EMOTICON_SELECTED_CANCEL]: (state, action) => {
        return {
            ...state,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [SELECT_EMOTICON_SEQ]: (state, action) => {
        const selectedEmoticonSeq = action.payload;

        return {
            ...state,
            isEmoticonSelected  : false,
            selectedEmoticonSeq : selectedEmoticonSeq,
            isAddFunctionOpened : false,
        }
    },

    [ADD_FUNCTION_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : !state.isAddFunctionOpened
        }
    },

    [SELECT_FUNCTION_SEQ]: (state, action) => {
        const { func, userSeq, userNickname } = action.payload;
        const { stdInfo } = state;

        let preSelectedFuncSeq = state.selectedFunctionSeq;
        
        if (func === ctActionType.InkMemo) {
            const actionData = {
                userNickname,
                userSeq,
                command     : enTokenCMD.chat_Memo,
                isWhisper   : true,
            }

            callEoForSendAction("memoTalk", actionData, JSON.stringify([{ userSeq: stdInfo.stdSeq, userNickname: stdInfo.stdNickname, profileImgUrl: stdInfo.stdProfileImg }]));
        } 

        return {
            ...state,                
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : func === ctActionType.VoiceMemo,
            selectedFunctionSeq     : preSelectedFuncSeq
        }
    },

    [VOICE_MEMO_TOGGLE_CLOSED]: (state, action) => {
        console.log("close voice memo");
        return {
            ...state,
            isAudioOpened : false
        }
    },

    [INK_MEMO_TOGGLE_OPENED]: (state, action) => {
        const { selectedFunc, userNickname, userSeq } = action.payload;
        const { stdInfo } = state;
        console.log("INK_MEMO_TOGGLE_OPENED : ", selectedFunc);
        
        if(selectedFunc !== undefined && selectedFunc !== null) { // ink memo 말풍선 클릭한 경우
            const { imgUrl } = action.payload;
            const actionData = {
                userNickname,
                userSeq,
                command     : enTokenCMD.chat_Memo,
                inkURL      : imgUrl,
                isWhisper   : true
            }

            callEoForSendAction("inkMemo", actionData, JSON.stringify([{ userSeq: stdInfo.stdSeq, userNickname: stdInfo.stdNickname, profileImgUrl: stdInfo.stdProfileImg }]));

            return {
                ...state
            }
        } else { 
            console.log("이런경우가 있나..?");
            
            return {
                ...state
            }
        }
    },

    [IMG_THUMBNAIL_CLICK]: (state, action) => {
        const { imgDataUrl, idx } = action.payload;
        const { receivedMsg } = state;
        console.log("IMG_THUMBNAIL_CLICK msg idx : ", idx);

        const rcvMsg = receivedMsg[idx];
        if (rcvMsg !== undefined && rcvMsg !== null) {
            console.log("rcvMsg is not null");

            if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                const userSeq = rcvMsg.msgInfo.rcvUserSeq;
                const userNickname = rcvMsg.msgInfo.rcvName;
                console.log("rcvMsg rcvUserSeq => ", userSeq);
                console.log("rcvMsg rcvName => ", userNickname);

                const actionData = {
                    userSeq,
                    userNickname,
                    imgDataUrl
                };

                callEoForSendAction("showImage", actionData);
            }
        }

        return {
            ...state,
        }
    },

    [FILE_SHARE_TOGGLE]: (state, action) => {
        const { fileURL, idx } = action.payload;
        console.log(`FILE_SHARE_TOGGLE - idx[${idx}], fileURL[${fileURL}]`);
        const { stdInfo } = state;

        const actionData = {
            fileURL,
            filename : fileURL.substr(fileURL.lastIndexOf("/") + 1),
            idx
        };

        callEoForSendAction("fileShare", actionData, stdInfo.stdSeq);

        return {
            ...state,
        }
    },

    [FILE_OPEN_TOGGLE]: (state, action) => {
        const { fileURL, fileType } = action.payload;
        console.log(`FILE_OPEN_TOGGLE - fileType[${fileType}], fileURL[${fileURL}]`);
        const { stdInfo } = state;

        const actionData = {
            filename : fileURL.substr(fileURL.lastIndexOf("/") + 1),
            fileType
        };

        callEoForSendAction("fileOpen", actionData, stdInfo.stdSeq);

        return {
            ...state,
        }
    },

    [FILE_SHARE_RESULT]: (state, action) => {
        const { idx, memberSeqList } = action.payload;
        console.log("FILE_SHARE_RESULT - ", idx);

        return produce(state, draft => {
            const rcvMsg = draft.whisperMsg[idx];
            if (rcvMsg !== undefined && rcvMsg !== null) {
                if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                    rcvMsg.msgInfo.isDownloading = false;
                    rcvMsg.msgInfo.isDownloaded = true;
                    //rcvMsg.msgInfo.downUrlInfo = fileURL;
                    const fileURL = rcvMsg.msgInfo.urlInfo;

                    const ext = fileURL.substring(fileURL.lastIndexOf("."), fileURL.length);
                    switch (ext) {
                        case ".mp4" :
                        case ".wmv" :
                            rcvMsg.msgInfo.type = enMQType.Video;
                            break;

                        case ".mp3" :
                        case ".wma" :
                            rcvMsg.msgInfo.type = enMQType.Audio;
                            break;

                        case ".im3" :
                            rcvMsg.msgInfo.type = enMQType.InkMedia;
                            break;

                        default:
                            rcvMsg.msgInfo.type = enMQType.Files;
                            break;
                    }
                    
                    rcvMsg.msgInfo.text = i18n.t("파일_다운로드_완료_알림", { ns: "classTalk" });
                }
            }
        });
    },

    [FILE_DOWNLOADING]: (state, action) => {
        const { idx, progress } = action.payload;
        console.log("FILE_DOWNLOADING");

        return produce(state, draft => {
            const rcvMsg = draft.whisperMsg[idx];
            if (rcvMsg !== undefined && rcvMsg !== null) {
                if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                    rcvMsg.msgInfo.isDownloading = true;
                    rcvMsg.msgInfo.isDownloaded = false;
                    rcvMsg.msgInfo.text = i18n.t("파일_다운로드_중_알림", { ns: "classTalk" });
                    rcvMsg.msgInfo.progress = progress;
                }
            }
        });
    },
}, initialState);

export default whisper;
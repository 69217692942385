import ConstCode from './ConstCode';
import ConstData from './ConstData';

import { decodingPacket } from './classTalk/EncodeDecode';
import { enTokenCMD, ctRcvKind, ctBubbleKind } from './classTalk/CodeEnum';
import { checkUrlText, getUrlText } from './func/ExportFunction';

import {
    logOut,
    updateServerUserInfo,
    mqUpdateInfo,
    updateServerUserAuthResult,
    saveServerUserAuthInfo,
    serverUserAuthResetResult
} from '../modules/user';

import {
    mqLoginFriend,
    mqLogoutFriend,
    mqCancelFriend,
    mqCancelFriendMe,
    mqRejectFriendMe,
    mqRejectFriend,
    mqRemoveFriend,
    mqUpdateFriendStatus,
    mqAddFriend,
    mqApplyFriend,
    mqSendApplyFriend,
    mqMultipleApplyFriend,
    mqMultipleAddFriend,
    mqUpdateFriendInfo
} from '../modules/friend';

import {
    mqLoginChat,
    mqLogoutChat,
    mqJoinChat,
    mqFinishChat,
    mqExitChat,
    mqRemoveChat,
    mqRemoveChatMember,
    mqRemovedChatMember,
    mqCreateChat,
    mqUpdateChat,
    mqNameUpdateChat,
    mqModeUpdateChat,
    mqTypeUpdateChat,
    mqInviteChatMember,
    mqAddChatMember,
    mqUpdateChatMemberInfo,
    updateTeamupMemberStatus
} from '../modules/chat';

import {
    mqJoinLive,
    mqExitLive,
    mqLiveOn,
    mqLiveOff,
    mqRemoveLive,
    mqRejectLiveMember,
    mqRejectedLiveMember,
    mqRemoveLiveMember,
    mqRemovedLiveMember,
    mqCreateLive,
    mqEditLiveName,
    mqApplyLive,
    mqApproveLive,
    mqApproveLiveMember,
    mqAddLiveMember,
    /*mqPerfomChatRoom,*/
    mqUpdateLiveMemberInfo,
    mqHandsUpStart,
    mqHandsUpStop,
    mqP2PStart,
    mqP2PStop,
    mqP2PSoundOn,
    mqP2PSoundOff,
    mqP2PRestart,
    mqHandsUpOn,
    mqHandsUpOff,
    updateHandsupMemberStatus,
    // updateMixedClassExistYn,
    updateMixedClassStatusResult,
    sendMixedClassMailResult,
    updateScreenSeq,
    mqUpdateLiveMemberNickname,
    rcvResultsForLocalLiveOnFromCloud,
    rcvResultsForLocalLiveOffFromCloud,
    rcvResultsForUpdateLocalLivePublicYnFromCloud,
    updateMixedClassExistYnResult,
    updateMixedClassInfoForLive,
    toggleTouchScreenLock,
    toggleChattingLock
} from '../modules/live';

import {
    appendReceiveMessage,
    appendReceiveWhisperMessage,
    appendSendInkMemoFromHiClass,
    appendMessageFromHiclass,
    sendWhisperInitData,
    fileActionResult,
    updateFileDownloading,
    receiveQuizAnswer,
    rcvDownloadFileActionResult,
    updateFileDownloadingForDir
} from '../modules/classTalk';

import {
    setAudioMixerStatus,
    setTeamupSoundDeviceInfo,
    setHandsupSoundDeviceInfo
} from '../modules/hiclasstv';

import {
    mqUpdateStudioUser,
    mqUpdateStudioLive,
    mqUpdateDisplayStatus
} from '../modules/studio';

import {
    appendReceiveTeacherMessage,
    appendReceiveStudentMessage,
    fileShareResult,
    fileDownloading,
    mqSendInitData
} from '../modules/whisper';

import {
    rcvCloseEditQuizModal,
    rcvQuizCreateDone,
    rcvQuizEditDone,
    rcvQuizRemoveDone,
    mqRcvQuizAnswer
} from '../modules/quiz';
import { callEoForSendMediaScreen } from './EoBrowserSendUtil';

export const rawDataWithRoutineKey = (store, rawData, routingKey, isWhisper) => {
    //console.log('ㅎㅏ이.. ', rawData, routingKey, isWhisper);
    const rawBinSring = window.atob(rawData);
    const len = rawBinSring.length;
    let rawBinData = new Uint8Array(len);

    for (var i = 0; i < len; i++) {
        rawBinData[i] = rawBinSring.charCodeAt(i);
    }

    const dataObject = decodingPacket(rawBinData);
    console.log(dataObject);

    if (dataObject === undefined || dataObject === null) return;

    if (isWhisper) {
        // if (routingKey && routingKey !== '') { // from Web
        switch (dataObject.CMD) {
            case enTokenCMD.chat_Text:
                rcvWhisperChatText(store, dataObject.name, dataObject.emoGIDno, dataObject.emoMIDno, dataObject.text, routingKey);
                break;

            case enTokenCMD.chat_Voice:
                rcvWhisperChatVoice(store, dataObject.data, dataObject.name, routingKey);
                break;

            case enTokenCMD.chat_Memo:
                rcvWhisperChatInkMemo(store, dataObject.data, dataObject.name, routingKey);
                break;

            case enTokenCMD.ct_UploadAndShareFiles:
                rcvWhisperFileShare(store, dataObject.name, dataObject.FileName, routingKey);
                break;

            default:
                break;
        }
        // store.dispatch(appendReceiveStudentMEssage({ dataObject }));
        // } else { // from Pentalk (본체)
        //    store.dispatch(appendReceiveTeacherMessage({ msgInfo: dataObject }));
        // }
    } else {
        switch (dataObject.CMD) {
            case enTokenCMD.chat_Text:
                rcvChatText(store, dataObject.name, dataObject.emoGIDno, dataObject.emoMIDno, dataObject.text, routingKey);
                break;

            case enTokenCMD.chat_Voice:
                rcvChatVoice(store, dataObject.data, dataObject.name, routingKey);
                break;

            case enTokenCMD.chat_Memo:
                rcvChatInkMemo(store, dataObject.data, dataObject.name, routingKey);
                break;

            case enTokenCMD.ct_UploadAndShareFiles:
                rcvFileShare(store, dataObject.name, dataObject.FileName, routingKey);
                break;

            case enTokenCMD.page_GetArtifact:
                // 이 command를 받는 경우 내 화면을 캡쳐해서 rest api로 쏴야한다.
                break;

            case enTokenCMD.page_PutArtifact:
            case enTokenCMD.cont_PageJpeg:
            case enTokenCMD.cont_StudentPageJpeg:
            case enTokenCMD.ct_AndroidPageJPEG:
                // 이 command를 받는 경우 해당 user가 보낸 이미지를 받아서 내 화면에 뿌려야한다.
                rcvChatPageJpeg(store, dataObject.name, dataObject.data, routingKey);
                break;
            case enTokenCMD.ct_UserLoginInfo:
                userStatusRcv(store, dataObject.INT1, dataObject.INT2, dataObject.BYTE1);
                break;

            case enTokenCMD.ct_SendToWebJSonInfo:
                jsonDataRcv(store, dataObject.text);
                break;

            default:
                break;
        }
    }
}

const getRcvKind = (routingKey, mySendKey, myChatSendKey) => {
    let selRcvKind = ctRcvKind.Team;
    let sKeyArr = null;
    const rKeyArr = routingKey.split(".");
    let strIdx = routingKey.indexOf("C.");

    if (strIdx >= 0) {
        if (myChatSendKey !== undefined && myChatSendKey !== null) {
            sKeyArr = myChatSendKey.split(".");
            if (rKeyArr[1] === sKeyArr[1] && rKeyArr[2] === sKeyArr[2]) {
                selRcvKind = ctRcvKind.Student;
            }
        }
    } else {
        if (rKeyArr[0] === "G" || rKeyArr[0] === "GM") {
            selRcvKind = ctRcvKind.Student;
        } else if (rKeyArr[0] === "L" || rKeyArr[0] === "LM") {
            selRcvKind = ctRcvKind.Student;
        } else {
            sKeyArr = mySendKey.split(".");
            if (rKeyArr[1] === sKeyArr[1]) {
                selRcvKind = ctRcvKind.Student;
            }
        }
    }

    /* if (strIdx >= 0) {
        if (rKeyArr[0] === "G") {
            selRcvKind = ctRcvKind.Student;
        } else {
            if (myChatSendKey !== undefined && myChatSendKey !== null) {
                sKeyArr = myChatSendKey.split(".");
                if (rKeyArr[1] === sKeyArr[1] && rKeyArr[2] === sKeyArr[2]) {
                    selRcvKind = ctRcvKind.Student;
                }
            }
        }
    } else {
        if (rKeyArr[0] === "G") {
            selRcvKind = ctRcvKind.Student;
        } else {
            sKeyArr = mySendKey.split(".");
            if (rKeyArr[1] === sKeyArr[1]) {
                selRcvKind = ctRcvKind.Student;
            }
        }
    } */

    return selRcvKind;
}

const getProfileImgUrl = (selRcvKind, routingKey) => {
    var store = window.hiclasstv.store;
    const { chat, live } = store.getState();
    const { performChatRoomInfo } = chat;
    const { list_live } = live;
    let profileImgUrl = '1';    // profile image '1' 이 기본 값이기 때문에 변경 ... by hjkim 20240215
    const rKeyArr = routingKey.split('.');

    if (selRcvKind === ctRcvKind.Team) {
        if (performChatRoomInfo.list_member !== undefined && performChatRoomInfo.list_member !== null) {
            // eslint-disable-next-line eqeqeq
            const member_info = performChatRoomInfo.list_member.find(info => info.userSeq == rKeyArr[rKeyArr.length - 1]);
            if (member_info !== undefined && member_info !== null) {
                profileImgUrl = member_info.profileImgUrl;
            }
        }
    } else if (selRcvKind === ctRcvKind.Student) {
        let liveSeq = -1;
        let userSeq = -1;

        if (rKeyArr.length > 4) {   // direct key (+ Small Group)
            if (rKeyArr[0] === "G" || rKeyArr[0] === "GM") {
                liveSeq = rKeyArr[1];
            } else {
                liveSeq = rKeyArr[rKeyArr.length - 3];   
            }
            userSeq = rKeyArr[rKeyArr.length - 2];
        } else {                    // entire key
            liveSeq = rKeyArr[rKeyArr.length - 2];
            userSeq = rKeyArr[rKeyArr.length - 1];
        }
        // eslint-disable-next-line eqeqeq
        const live_info = list_live.find(info => info.liveSeq == liveSeq);
        if (live_info !== undefined && live_info !== null) {
            if (live_info.list_member !== undefined && live_info.list_member !== null) {
                // eslint-disable-next-line eqeqeq
                const member_info = live_info.list_member.find(info => Number(info.userSeq) == Number(userSeq));
                if (member_info !== undefined && member_info !== null) {
                    profileImgUrl = member_info.profileImgUrl;
                }
            }
        }
    }

    return profileImgUrl;
}

const rcvWhisperChatText = (store, userName, emoGIDno, emoMIDno, msgText, routingKey) => {
    const { whisper } = store.getState();
    const { stdInfo } = whisper;

    let msgInfo = {
        rcvName: userName,
        text: msgText,
        emoticSeq: emoMIDno,
        urlInfo: ""
    };

    if (routingKey && routingKey !== '') {
        msgInfo = {
            ...msgInfo,
            iconSeq: stdInfo.stdProfileImg,
            rcvKind: ctRcvKind.Student,
        };

        store.dispatch(appendReceiveStudentMessage({ msgInfo }));
    } else {
        msgInfo = {
            ...msgInfo,
            rcvKind: ctRcvKind.Teacher
        };

        store.dispatch(appendReceiveTeacherMessage({ msgInfo }));
    }
}

const rcvChatText = (store, userName, emoGIDno, emoMIDno, msgText, routingKey) => {
    const { chat } = store.getState();
    const { myHandsUpSendKey, myTeamUpSendKey } = chat;

    let selRcvKind = getRcvKind(routingKey, myHandsUpSendKey, myTeamUpSendKey);
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let isUrlText = checkUrlText(msgText);
    let urlText = "";
    if (isUrlText) getUrlText(msgText);

    let msgInfo = {
        iconSeq: parseInt(profileImgUrl),
        rcvKind: selRcvKind,
        rcvName: userName,
        text: msgText,
        emoticSeq: emoMIDno,
        urlInfo: "",
        isUrlText,
        urlText
    };
    
    let rKeyArr = routingKey.split('.');
    if ((rKeyArr[0] === "L" || rKeyArr[0] === "LM") && rKeyArr.length > 4) { // direct key (귓속말)
        let stdSeq = rKeyArr[3];
        if (Number(stdSeq) === 0) { // from 귓속말 popup (선생님)
            stdSeq = rKeyArr[4];
            store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.SendMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
        } else {                    //from 귓속말 web (학생)
            let newMsgInfo = {
                kind: "whisper",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                text: "귓속말을 보냈습니다.",
                emoticSeq: -1,
                data: [{ userSeq: stdSeq }]
                // urlInfo : { text: msgText, emoticSeq: emoMIDno }
            };

            store.dispatch(appendReceiveMessage(newMsgInfo));
            store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.RcvMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
        }
    } else {
        store.dispatch(appendReceiveMessage(msgInfo));
    }
}

const rcvWhisperChatVoice = (store, voiceData, userName, routingKey) => {
    const { whisper } = store.getState();
    const { stdInfo } = whisper;

    let blob = new Blob([voiceData], { type: 'audio/mp3' });
    let voiceUrl;
    let reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = () => {
        voiceUrl = reader.result;

        let msgInfo = {
            kind: "audio_memo",
            rcvName: userName,
            // text: "음성톡",
            text: "음성메모",
            urlInfo: voiceUrl
        };

        if (routingKey && routingKey !== '') {
            msgInfo = {
                ...msgInfo,
                iconSeq: stdInfo.stdProfileImg,
                rcvKind: ctRcvKind.Student,
            };

            store.dispatch(appendReceiveStudentMessage({ msgInfo }));
        } else {
            msgInfo = {
                ...msgInfo,
                rcvKind: ctRcvKind.Teacher
            }

            store.dispatch(appendReceiveTeacherMessage({ msgInfo }));
        }
    };
}

const rcvChatVoice = (store, voiceData, userName, routingKey) => {
    const { chat } = store.getState();
    const { myHandsUpSendKey, myTeamUpSendKey } = chat;

    let selRcvKind = getRcvKind(routingKey, myHandsUpSendKey, myTeamUpSendKey);
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let blob = new Blob([voiceData], { type: 'audio/mp3' });
    let voiceUrl;
    let reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = () => {
        voiceUrl = reader.result;
        let msgInfo = {
            kind: "audio_memo",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            // text: "음성톡",
            text: "음성메모",
            urlInfo: voiceUrl
        };

        let rKeyArr = routingKey.split('.');
        if ((rKeyArr[0] === "L" || rKeyArr[0] === "LM") && rKeyArr.length > 4) { // direct key (귓속말)
            let stdSeq = rKeyArr[3];
            if (Number(stdSeq) === 0) { // from 귓속말 popup (선생님)
                stdSeq = rKeyArr[4];
                store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.SendMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
            } else {                    //from 귓속말 web (학생)
                let newMsgInfo = {
                    kind: "whisper",
                    iconSeq: parseInt(profileImgUrl),
                    rcvKind: selRcvKind,
                    rcvName: userName,
                    text: "귓속말을 보냈습니다",
                    data: [{ userSeq: stdSeq }],
                    // urlInfo : { text: "음성톡", kind:"audio_memo", urlInfo: voiceUrl },
                    urlInfo : { text: "음성메모", kind:"audio_memo", urlInfo: voiceUrl },
                };

                store.dispatch(appendReceiveMessage(newMsgInfo));
                store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.RcvMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
            }
        } else {
            store.dispatch(appendReceiveMessage(msgInfo));
        }
    };
}

const rcvWhisperChatInkMemo = (store, imgData, userName, routingKey) => {
    const { whisper } = store.getState();
    const { stdInfo } = whisper;

    let blob = new Blob([imgData], { type: 'image/jpeg' });
    let imageUrl;
    let reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = () => {
        imageUrl = reader.result;
        let msgInfo = {
            kind: "ink_memo",
            rcvName: userName,
            // text: "메모톡",
            text: "잉크메모",
            urlInfo: imageUrl
        };

        if (routingKey && routingKey !== '') {
            msgInfo = {
                ...msgInfo,
                iconSeq: stdInfo.stdProfileImg,
                rcvKind: ctRcvKind.Student
            };

            store.dispatch(appendReceiveStudentMessage({ msgInfo }));
        } else {
            msgInfo = {
                ...msgInfo,
                rcvKind: ctRcvKind.Teacher
            };

            store.dispatch(appendReceiveTeacherMessage({ msgInfo }));
        }
    };
}

const rcvChatInkMemo = (store, imgData, userName, routingKey) => {
    const { chat } = store.getState();
    const { myHandsUpSendKey, myTeamUpSendKey } = chat;

    let selRcvKind = getRcvKind(routingKey, myHandsUpSendKey, myTeamUpSendKey);
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let blob = new Blob([imgData], { type: 'image/jpeg' });
    let imageUrl;
    let reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onload = () => {
        imageUrl = reader.result;
        let msgInfo = {
            kind: "ink_memo",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvName: userName,
            // text: "메모톡",
            text: "잉크메모",
            urlInfo: imageUrl
        };

        let rKeyArr = routingKey.split('.');
        if ((rKeyArr[0] === "L" || rKeyArr[0] === "LM") && rKeyArr.length > 4) { // direct key (귓속말)
            let stdSeq = rKeyArr[3];
            if (Number(stdSeq) === 0) { // from 귓속말 popup (선생님)
                stdSeq = rKeyArr[4];
                store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.SendMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
            } else {                    //from 귓속말 web (학생)
                let newMsgInfo = {
                    kind: "whisper",
                    iconSeq: parseInt(profileImgUrl),
                    rcvKind: selRcvKind,
                    rcvName: userName,
                    text: "귓속말을 보냈습니다",
                    data: [{ userSeq: stdSeq }],
                    // urlInfo : { text: "메모톡", kind: "ink_memo", urlInfo: imageUrl },
                    urlInfo : { text: "잉크메모", kind: "ink_memo", urlInfo: imageUrl }
                };

                store.dispatch(appendReceiveMessage(newMsgInfo));
                store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.RcvMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
            }
        } else {
            store.dispatch(appendReceiveMessage(msgInfo));
        }
    };
}

const rcvWhisperFileShare = (store, userName, fileName, routingKey) => {
    const { whisper } = store.getState();
    const { stdInfo } = whisper;

    let msgInfo = {
        kind: "file_share",
        rcvName: userName,
        urlInfo: fileName
    };

    if (routingKey && routingKey !== '') {
        msgInfo = {
            ...msgInfo,
            iconSeq: stdInfo.stdProfileImg,
            rcvKind: ctRcvKind.Student,
            text: userName + "님이 파일을 전송했습니다.",
        };

        store.dispatch(appendReceiveStudentMessage({ msgInfo }));
    } else {
        msgInfo = {
            ...msgInfo,
            rcvKind: ctRcvKind.Teacher,
            text: fileName + " 의 전송이 완료되었습니다.",
        };

        store.dispatch(appendReceiveTeacherMessage({ msgInfo }));
    }
}

const rcvFileShare = (store, userName, fileName, routingKey) => {
    const { chat } = store.getState();
    const { myHandsUpSendKey, myTeamUpSendKey } = chat;

    let selRcvKind = getRcvKind(routingKey, myHandsUpSendKey, myTeamUpSendKey);
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);

    let msgInfo = {
        kind: "file_share",
        iconSeq: parseInt(profileImgUrl),
        rcvKind: selRcvKind,
        rcvName: userName,
        text: userName + "님이 파일을 전송했습니다.",
        urlInfo: fileName
    };

    let rKeyArr = routingKey.split('.');
    if ((rKeyArr[0] === "L" || rKeyArr[0] === "LM") && rKeyArr.length > 4) { // direct key (귓속말)
        let stdSeq = rKeyArr[3];
        if (Number(stdSeq) === 0) { // from 귓속말 popup (선생님)
            let msgInfo = {
                rcvKind: ctRcvKind.Teacher,
                text: fileName + "의 전송이 완료되었습니다."
            };

            stdSeq = rKeyArr[4];
            store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.SendMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
        } else {                    //from 귓속말 web (학생)
            let pentalkMsg = {
                kind: "whisper",
                iconSeq: parseInt(profileImgUrl),
                rcvKind: selRcvKind,
                rcvName: userName,
                text: "귓속말을 보냈습니다",
                data: [{ userSeq: stdSeq }]
            };

            store.dispatch(appendReceiveMessage(pentalkMsg));
            store.dispatch(appendReceiveWhisperMessage({ kind: ctBubbleKind.RcvMsg, stdSeq, msgInfo, userNickname: userName, profileImgUrl }));
        }
    } else {
        store.dispatch(appendReceiveMessage(msgInfo));
    }
}

const rcvChatPageJpeg = (store, userName, imgData, routingKey) => {
    const { chat } = store.getState();
    const { myHandsUpSendKey, myTeamUpSendKey } = chat;

    let selRcvKind = getRcvKind(routingKey, myHandsUpSendKey, myTeamUpSendKey);
    let profileImgUrl = getProfileImgUrl(selRcvKind, routingKey);
    let rKeyArr = routingKey.split(".");
    let sendUserSeq = -1;

    if (selRcvKind === ctRcvKind.Student) {
        if (rKeyArr.length > 4) {
            sendUserSeq = parseInt(rKeyArr[rKeyArr.length - 2]);
        } else {
            sendUserSeq = parseInt(rKeyArr[rKeyArr.length - 1]);
        }
    } else {
        if (rKeyArr.length > 3) {
            sendUserSeq = parseInt(rKeyArr[rKeyArr.length - 2]);
        } else {
            sendUserSeq = parseInt(rKeyArr[rKeyArr.length - 1]);
        }
    }

    let blob = new Blob([imgData], { type: 'image/jpeg' });
    let reader = new FileReader();
    let imageUrl;

    reader.readAsDataURL(blob);
    reader.onload = () => {
        imageUrl = reader.result;
        let msgInfo = {
            kind: "page_jpeg",
            iconSeq: parseInt(profileImgUrl),
            rcvKind: selRcvKind,
            rcvUserSeq: sendUserSeq,
            rcvName: userName,
            text: userName + " 님의 화면이 전송되었습니다.",
            urlInfo: imageUrl
        };

        store.dispatch(appendReceiveMessage(msgInfo));
    };
}

const userStatusRcv = (store, roomSeq, userSeq, status) => {
    //console.log("userStatusRcv - roomSeq : " + roomSeq + ", userSeq : " + userSeq + ", status : " + status);

    switch (status) {
        case ConstCode.LOGIN:
            // 로그인 (in hiclass)
            store.dispatch(mqLoginFriend({ userSeq }));
            store.dispatch(mqLoginChat({ userSeq }));
            break;

        case ConstCode.LOGOUT:
            // 로그아웃
            store.dispatch(mqLogoutFriend({ userSeq }));
            store.dispatch(mqLogoutChat({ userSeq }));
            break;

        case ConstCode.LOGIN_WEB:
            // 로그인 (in web)
            console.log("web login rbmq");
            break;

        case ConstCode.CANCEL_FRIEND_APPLY:
            // 친구 신청 취소 (hiclass -> web 또는 web -> hiclass 처리용)
            store.dispatch(mqCancelFriendMe({ userSeq }));
            break;

        case ConstCode.CANCELED_FRIEND_APPLY:
            // 친구 신청 취소됨
            store.dispatch(mqCancelFriend({ userSeq }));
            break;

        case ConstCode.REJECT_FRIEND_APPLY:
            // 친구 신청 거절 (hiclass -> web 또는 web -> hiclass 처리용)
            store.dispatch(mqRejectFriendMe({ userSeq }));
            break;

        case ConstCode.REJECTED_FRIEND_APPLY:
            // 친구 신청 거절
            store.dispatch(mqRejectFriend({ userSeq }));
            break;

        case ConstCode.REMOVE_FRIEND:
            // 친구 삭제
            store.dispatch(mqRemoveFriend({ userSeq }));
            break;

        case ConstCode.CHATROOM_JOIN:
            // 팀업 입장
            store.dispatch(mqJoinChat({ roomSeq, userSeq }));
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.STUDYING }));
            break;

        case ConstCode.CHATROOM_FINISH:
            // 팀업 종료
            store.dispatch(mqFinishChat({ roomSeq, userSeq }));
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.LOG_IN }));
            break;

        case ConstCode.CHATROOM_EXIT:
            // 팀업 퇴장 (영구)
            store.dispatch(mqExitChat({ roomSeq, userSeq }));
            store.dispatch(mqUpdateFriendStatus({ userSeq, status: ConstData.USER_STATUS.LOG_IN }));
            break;

        case ConstCode.CHATROOM_REMOVE:
            // 팀업 삭제
            store.dispatch(mqRemoveChat({ roomSeq, userSeq }));
            break;

        case ConstCode.CHATROOM_MEMBER_REMOVE:
            // 팀업 멤버 삭제
            store.dispatch(mqRemoveChatMember({ roomSeq, userSeq }));
            break;

        case ConstCode.CHATROOM_MEMBER_REMOVED:
            // 팀업 멤버 퇴출
            store.dispatch(mqRemovedChatMember({ roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_JOIN: {
            // 클래스 참여
            const { user } = store.getState();
            const user_info = { userSeq: user.userSeq, userNickname: user.userNickname };
            store.dispatch(mqJoinLive({ liveSeq: roomSeq, userSeq, user_info }));
        } break;

        case ConstCode.LIVE_EXIT:
            // 클래스 나가기
            store.dispatch(mqExitLive({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_ON:
            // 클래스 On
            store.dispatch(mqLiveOn({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_OFF:
            // 클래스 off
            store.dispatch(mqLiveOff({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_REMOVE:
            // 클래스 삭제
            store.dispatch(mqRemoveLive({ liveSeq: roomSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REJECT:
            // 클래스 멤버 거절
            store.dispatch(mqRejectLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REJECTED:
            // 클래스 멤버 거절됨
            store.dispatch(mqRejectedLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REMOVE:
            // 클래스 멤버 삭제
            store.dispatch(mqRemoveLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.LIVE_MEMBER_REMOVED:
            // 클래스 멤버 퇴출
            store.dispatch(mqRemovedLiveMember({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_MODE_START:
            // hands up 시작
            store.dispatch(mqHandsUpStart({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_MODE_STOP:
            // hands up 종료
            store.dispatch(mqHandsUpStop({ liveSeq: roomSeq, userSeq }));
            break;

        /* case ConstCode.P2P_MODE_START:
            // p2p 시작
            store.dispatch(mqP2PStart({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.P2P_MODE_STOP:
            // p2p 종료
            store.dispatch(mqP2PStop({ liveSeq: roomSeq, userSeq }));
            break; */

        /* case ConstCode.P2P_SOUND_ON:
            // p2p sound on
            store.dispatch(mqP2PSoundOn({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.P2P_SOUND_OFF:
            // p2p sound off
            store.dispatch(mqP2PSoundOff({ liveSeq: roomSeq, userSeq }));
            break; */

        /* case ConstCode.P2P_MODE_RESTART:
            // p2p 재시작
            store.dispatch(mqP2PRestart({ liveSeq: roomSeq, userSeq }));
            break; */

        /* case ConstCode.VIEW_MODE_CAMERA:
            // 보낼 화면을 camera 화면으로 바꿈
            store.dispatch(mqChangeCameraView({ liveSeq: roomSeq, userSeq }));
            break; */

        /* case ConstCode.VIEW_MODE_SCREEN:
            // 보낼 화면을 screen 이미지로 바꿈
            store.dispatch(mqChangeScreenView({ liveSeq: roomSeq, userSeq }));
            break; */

        case ConstCode.HANDS_UP_ON:
            // 학생이 hans up 함
            store.dispatch(mqHandsUpOn({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.HANDS_UP_OFF:
            // 학생이 hans up 취소 또는 선생님이 학생의  hans up을 끔
            store.dispatch(mqHandsUpOff({ liveSeq: roomSeq, userSeq }));
            break;

        case ConstCode.UPDATE_SCREEN_IMAGE:
            // web module 에서 처리해야할 부분이 있나요...?
            break;

        case ConstCode.DISPLAY_ON:
            // display on
            store.dispatch(mqUpdateDisplayStatus({ studioSeq: roomSeq, displaySeq: userSeq, status: 'Y' }));
            break;

        case ConstCode.DISPLAY_OFF:
            // display off
            store.dispatch(mqUpdateDisplayStatus({ studioSeq: roomSeq, displaySeq: userSeq, status: 'N' }));
            break;

        default:
            //console.log("status is default... -> ", status);
            break;
    }
}

const jsonDataRcv = (store, jsonStr) => {
    const dataObject = JSON.parse(jsonStr);
    if (dataObject === undefined || dataObject === null) return;

    const { kind, action, information } = dataObject;
    console.log("EoBrowserRcvUtil - jsonDataRcv ) ", kind, action, information);

    if (kind === "chatting") {
        if (action === "create") {
            const { chatRoom_info } = information;
            store.dispatch(mqCreateChat({ chatRoom_info }));
        } else if (action === "update") {
            const { chatRoom_info } = information;
            store.dispatch(mqUpdateChat({ chatRoom_info }));
        } else if (action === "update-name") {
            const { chatRoom_info } = information;
            store.dispatch(mqNameUpdateChat({ chatRoom_info }));
        } else if (action === "update-mode") {
            const { chatRoom_info } = information;
            store.dispatch(mqModeUpdateChat({ chatRoom_info }));
        } else if (action === "update-type") {
            const { chatRoom_info } = information;
            store.dispatch(mqTypeUpdateChat({ chatRoom_info }));
        } else if (action === "join") {
            const { join_info } = information;
            store.dispatch(mqJoinChat({ join_info }));
        } else if (action === "invite") {
            const { chatRoom_info } = information;
            store.dispatch(mqInviteChatMember({ chatRoom_info }));
        } else if (action === "add-member") {
            const { add_info } = information;
            store.dispatch(mqAddChatMember({ add_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "friend") {
        if (action === "add") {
            const { user_info } = information;
            store.dispatch(mqAddFriend({ user_info }));
        } else if (action === "rcv-apply") {
            const { user_info } = information;
            store.dispatch(mqApplyFriend({ user_info }));
        } else if (action === "send-apply") {
            const { user_info } = information;
            store.dispatch(mqSendApplyFriend({ user_info }));
        } else if (action === "multiple-apply") {
            const { list_user } = information;
            store.dispatch(mqMultipleApplyFriend({ list_user }));
        } else if (action === "multiple-add") {
            const { list_user } = information;
            store.dispatch(mqMultipleAddFriend({ list_user }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "live") {
        if (action === "create") {
            const { live_info } = information;
            store.dispatch(mqCreateLive({ live_info }));
        } else if (action === "edit-name") {
            const { edit_info } = information;
            store.dispatch(mqEditLiveName({ edit_info }));
        } else if (action === "apply-live") {
            const { apply_info } = information;
            store.dispatch(mqApplyLive({ apply_info }));
        } else if (action === "approve-member") {
            const { member_info } = information;
            store.dispatch(mqApproveLiveMember({ member_info }));
        } else if (action === "add-member") {
            const { member_info } = information;
            store.dispatch(mqAddLiveMember({ member_info }));
        } else if (action === "approve-live") {
            const { live_info } = information;
            store.dispatch(mqApproveLive({ live_info }));
        } else if (action === "update-nickname") {
            const { user_info } = information;
            console.log(`kind[${kind}] action[${action}] information => `, information);
            store.dispatch(mqUpdateLiveMemberNickname({ user_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "user") {
        if (action === "create") {
            const { update_info } = information;
            // friend, chatting, live에 모두 처리하기
            store.dispatch(mqUpdateFriendInfo({ update_info }));
            store.dispatch(mqUpdateChatMemberInfo({ update_info }));
            store.dispatch(mqUpdateLiveMemberInfo({ update_info }));
        } else if (action === "update") {
            const { update_info } = information;
            store.dispatch(mqUpdateInfo({ update_info }));
            store.dispatch(mqUpdateFriendInfo({ update_info }));
            store.dispatch(mqUpdateChatMemberInfo({ update_info }));
            store.dispatch(mqUpdateLiveMemberInfo({ update_info }));
        } else {
            console.log("action is else... ", action);
        }
    } else if (kind === "studio") {
        if (action === "assign-user") {
            const { studioSeq, userSeq, list_display } = information;
            store.dispatch(mqUpdateStudioUser({ studioSeq, userSeq, list_display }));
        } else if (action === "clear-user") {
            const { studioSeq } = information;
            store.dispatch(mqUpdateStudioUser({ studioSeq, userSeq: -1 }));
        } else if (action === "assign-live") {
            const { studioSeq, userSeq, liveSeq } = information;
            store.dispatch(mqUpdateStudioLive({ studioSeq, userSeq, liveSeq }));
        } else if (action === "clear-live") {
            const { studioSeq, userSeq } = information;
            store.dispatch(mqUpdateStudioLive({ studioSeq, userSeq, liveSeq: -1 }));
        }
    } else if (kind === "display") {
        if (action === "changed-info") {
            const { displaySeq, prevInfo, assingedInfo } = information;
        }
    } else if (kind === "mixedClassAction") {
        const data = information;
        if (action === "createResult") {
            if (data.isSuccessed && data.isCreateSuccess) {
                // store.dispatch(updateMixedClassExistYn({ localLiveSeq: data.localLiveSeq, mixedClassSeq: data.mixedClassSeq, mixedClassExistYn: 'Y' }));
                store.dispatch(updateMixedClassInfoForLive({ localLiveSeq: data.localLiveSeq, mixedClassSeq: data.mixedClassSeq, mixedClassExistYn: 'Y' }));
            } else {
                store.dispatch(updateMixedClassExistYnResult({ ...data, kind: "create" }));
            }
        } else if (action === "deleteResult") {
            if (data.isSuccessed && data.isDeleteSuccess) {
                // store.dispatch(updateMixedClassExistYn({ localLiveSeq: data.localLiveSeq, mixedClassSeq: null, mixedClassExistYn: 'N' }));
                store.dispatch(updateMixedClassInfoForLive({ localLiveSeq: data.localLiveSeq, mixedClassSeq: null, mixedClassExistYn: 'N' }));
            } else {
                store.dispatch(updateMixedClassExistYnResult({ ...data, kind: "delete" }));
            }
        } else if (action === "startResult" || action === "stopResult") {
            store.dispatch(updateMixedClassStatusResult({ ...data, kind: action }));
        } else if (action === "sendMailLinkResult") {
            store.dispatch(sendMixedClassMailResult(data));
        } else if (action === "requestAuthResult") {
            console.log("requestAuthResult - data => ", data);
            if (data.isSuccessed && data.isExist) {
                if (data.isKeepSaving) {
                    const { localUserSeq, serverUserSeq, localServerSeq } = data;
                    store.dispatch(saveServerUserAuthInfo({ localUserSeq, serverUserSeq, localServerSeq }));
                } else {
                    store.dispatch(updateServerUserAuthResult(data));
                }
            } else {
                store.dispatch(updateServerUserAuthResult(data));
            }
        } else if (action === "requestAuthResetResult") {
            console.log("requestAuthResetResult - data => ", data);
            store.dispatch(serverUserAuthResetResult(data));
        }
    } else if (kind === "relayAction") {
        if (action === "liveStart") {
            store.dispatch(rcvResultsForLocalLiveOnFromCloud(information));
        } else if (action === "liveEnd") {
            store.dispatch(rcvResultsForLocalLiveOffFromCloud(information));
        } else if (action === "liveUpdatePublicYN") {
            store.dispatch(rcvResultsForUpdateLocalLivePublicYnFromCloud(information));
        }
    } else if (kind === "quiz") {
        if (action === "sendQuizAnswer") {
            const { data, userSeq } = information;
            store.dispatch(receiveQuizAnswer({ quiz_info: data, userSeq }));
        }
    } else {
        console.log("kind is else... ", kind);
    }
}

export const sendedFromHiClass = (store, sendKind, sendData) => {
    console.log(`sendedFromHiClass:) sendKind[${sendKind}]`);
    if (sendData === undefined || sendData === null) return;

    if (sendKind === "whisperInkMemoResult") { // inkMemo 전송 시, popup에서 데이터 받는 부분
        console.log(sendData);
        const msgInfo = {
            kind: "ink_memo",
            // text: "메모톡",
            text: "잉크메모",
            urlInfo: sendData
        };

        store.dispatch(appendReceiveTeacherMessage({ msgInfo }));
    } else if (sendKind === "inkMemoResult") { // inkMemo 전송 시, 본체 에서 데이터 받는 부분
        const dataObject = JSON.parse(sendData);
        console.log(dataObject);

        const { imgDataUrl, isWhisper, memberSeqList } = dataObject;

        let memArr = [];

        if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
            let arr = memberSeqList.split('|');
            arr.forEach(item => {
                let arr2 = item.split(',');
                memArr.push({ userSeq: arr2[0], userNickname: arr2[1], profileImgUrl: arr2[2] });
            });
        }

        store.dispatch(appendSendInkMemoFromHiClass({ imgDataUrl, isWhisper, memberSeqList: memArr }));
    } else {
        const dataObject = JSON.parse(sendData);
        console.log("sendedFromHiClass() - dataObject => ", dataObject);

        if (dataObject === undefined || dataObject === null) return;

        if (sendKind === "actionResult") {
            const { actionKind, kind } = dataObject;

            if (kind === "whisper") {
                const { data } = dataObject;
                console.log(data);
                if (data.kind === 'initDone') {
                    const { user } = store.getState();
                    store.dispatch(sendWhisperInitData({ stdSeq: Number(data.data), userInfo: { userSeq: user.userSeq, userNickname: user.userNickname } }));
                } else if (data.kind === '') {
                    //store.dispatch();
                }
            } else if (kind === "whisper-popup") {
                const { data } = dataObject;
                if (data.kind === 'sendInitData') {
                    store.dispatch(mqSendInitData(data));
                }
            } else if (kind === "quiz-popup") {
                if (actionKind === "close") {
                    const { quizSeq, userSeq } = dataObject;
                    store.dispatch(rcvCloseEditQuizModal({ quizSeq, userSeq }));
                } else if (actionKind === "create-done") {
                    const { userSeq } = dataObject;
                    store.dispatch(rcvQuizCreateDone({ userSeq }));
                } else if (actionKind === "edit-done") {
                    const { userSeq } = dataObject;
                    store.dispatch(rcvQuizEditDone({ userSeq }));
                } else if (actionKind === "remove-done") {
                    const { userSeq } = dataObject;
                    store.dispatch(rcvQuizRemoveDone({ userSeq }));
                }
            } else if (actionKind === "logout") {
                const { user } = store.getState();
                const { userSeq } = user;
                if (ConstData.IS_LOCAL_VERSION) {
                    const { live } = store.getState();
                    const { list_live } = live;
                    if (list_live) {
                        let list_localLive = list_live.filter(info => info.liveStatus === 'Y');
                        if (list_localLive && list_localLive.length > 0) {
                            console.log("liveStatus 'Y' live in list_localLive length - ", list_localLive.length);
                            callEoForSendMediaScreen("relayAction", { action: "allLiveEnd", localUserSEQ: userSeq });
                            // callEoForSendMediaScreen("relayAction", { action: "allLiveEnd", list_localLive: list_localLive, localUserSEQ: userSeq });
                        }
                    }
                }
                store.dispatch(logOut({ userSeq, mode: "hiclass", isClickLogout: true }));
            } else if (actionKind === "inkMemoSendResult") {
                const { imgDataUrl, memberSeqList } = dataObject;
                const msgInfo = {
                    kind: "ink_memo",
                    // text: "잉크메모",
                    urlInfo: imgDataUrl
                };

                if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
                    store.dispatch(appendSendInkMemoFromHiClass({ msgInfo, memberSeqList: JSON.parse(memberSeqList) }));
                } else {
                    store.dispatch(appendSendInkMemoFromHiClass({ msgInfo, memberSeqList }));
                }
            } else if (actionKind === "fileShareResult") {
                const { idx, memberSeqList } = dataObject;

                if (memberSeqList && memberSeqList !== '') {
                    store.dispatch(fileShareResult({ idx, memberSeqList }));
                } else {
                    store.dispatch(fileActionResult({ idx }));
                }
            } else if (actionKind === "fileDownloading") {
                if (dataObject.kind && dataObject.kind === "each_other") {
                    const { idx, totalCount } = dataObject;
                    console.log("fileDownloading each_other - dataObject => ", dataObject);
                    let progress = ((idx + 1) / totalCount) * 100;
                    progress = progress.toFixed(2);
                    console.log("fileDownloading each_other - progress => ", progress);

                    store.dispatch(updateFileDownloadingForDir({ idx, progress }));
                } else {
                    const { idx, progress, memberSeqList } = dataObject;

                    if (memberSeqList && memberSeqList !== '') {
                        store.dispatch(fileDownloading({ idx, progress, memberSeqList }));
                    } else {
                        store.dispatch(updateFileDownloading({ idx, progress }));
                    }
                }
            } else if (actionKind === "fileDownloadFinish" || actionKind === "fileDownloadCanceled") {
                store.dispatch(rcvDownloadFileActionResult(dataObject));
            } else if (actionKind === "Pentalk") {
                const { kind, rcvData } = dataObject;

                if (kind === "send") {
                    chatPenTalkSendMsg(store, ctRcvKind.sendPentalk, rcvData);
                } else if (kind === "rcv") {
                    chatPenTalkRcvMsg(store, ctRcvKind.rcvPentalk, rcvData);
                } else if (kind === "tabletLock") {                    
                    const { user } = store.getState();
                    const { live } = store.getState();
                    store.dispatch(toggleTouchScreenLock({ userSeq: user.userSeq, userNickname: user.userNickname }));
                    store.dispatch(toggleChattingLock({ kind: 'tabletLock', isTouchScreenLock: live.isTouchScreenLock, userSeq: user.userSeq, userNickname: user.userNickname }));
                }
            } else if (actionKind === "remocon") {
                const { kind, command, memberSeq, status } = dataObject;

                switch (kind) {
                    case "teamup":
                        store.dispatch(updateTeamupMemberStatus({ command, memberSeq, status }));
                        break;

                    case "handsup":
                        store.dispatch(updateHandsupMemberStatus({ command, memberSeq, status }));
                        break;

                    case "micIconOnOff":
                        store.dispatch(setAudioMixerStatus({ status }));
                        break;

                    default:
                        break;
                }
            }
        } else if (sendKind === "audioInfo") { // teamup audio info
            store.dispatch(setTeamupSoundDeviceInfo(dataObject));
        } else if (sendKind === "audioInfo_handsup") { // handsup audio info
            if (dataObject.audioOutputInfo) {
                store.dispatch(setHandsupSoundDeviceInfo(dataObject));
            } else if (dataObject.smartTVInfo) {
                // const { smartTVInfo } = dataObject;
                // const { liveSeq, startSeqOfSmartTV, binders } = smartTVInfo;
                store.dispatch(updateScreenSeq(dataObject));
            }
        } else if (sendKind === "mixedClassAction") {
            const { actionKind } = dataObject;

            if (actionKind === "serverUserInfo") {
                const { userSeq, localServerSeq } = dataObject;
                if (userSeq >= 0 && localServerSeq >= 0) {
                    store.dispatch(updateServerUserInfo({ userSeq, localServerSeq }));
                }
            }
        }
    }
}

const chatPenTalkSendMsg = (store, kind, rcvData) => {
    let regEx = new RegExp('__UPPERST__', 'gi');
    let textMsg = rcvData.replace(regEx, "'");
    let msgInfo = [];

    msgInfo.push({
        kind: ctBubbleKind.SendMsg,
        msgInfo: {
            iconSeq: 1,
            rcvKind: kind,
            rcvName: 'Pentalk',
            text: textMsg,
            urlInfo: ''
        }
    });

    store.dispatch(appendMessageFromHiclass({ msgInfo }));
}

const chatPenTalkRcvMsg = (store, kind, rcvData) => {
    let regEx = new RegExp('__UPPERST__', 'gi');
    let textMsg = rcvData.replace(regEx, "'");
    let msgInfo = [];

    msgInfo.push({
        kind: ctBubbleKind.Info,
        msgInfo: {
            iconSeq: 1,
            rcvKind: kind,
            rcvName: 'Pentalk',
            text: textMsg,
            urlInfo: ''
        }
    });

    store.dispatch(appendMessageFromHiclass({ msgInfo }));
}
import React from 'react';
import { useTranslation } from "react-i18next";
import { BsX } from 'react-icons/bs';

const OffAirLive = ({ selectedLiveInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq:-1 })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                {t("클래스_방송_종료_문구_1", { ns: "live" })}<br />
                {t("클래스_방송_종료_문구_2", { ns: "live" })}
            </div>
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">[{t("클래스_이름", { ns: "live" })}]</span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={selectedLiveInfo.liveName}>
                        <b>{selectedLiveInfo.liveName}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR", liveSeq: selectedLiveInfo.liveSeq, liveName:selectedLiveInfo.liveName })}>{t("예", { ns: "common" })}</button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq: -1 })}>{t("아니오", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default OffAirLive;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FaFileImage } from 'react-icons/fa';

import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const WebAddLocalServer = ({ isCheckSubDomainOK, selectedSubDomainInfo, list_localSite, isAddLocalServerOK, alertMessage, alertKind, handleSelectedFunc, onChangeIsAddLocalServerOK }) => {
    const { t } = useTranslation();

    const [localServerName, setLocalServerName] = useState("");

    const [lLocalSiteInfo, setLocalSiteInfo] = useState({ idx: 0, localSiteSeq: -1, localSiteName: "" });

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (list_localSite && list_localSite.length > 0) {
            setLocalSiteInfo({ idx: 0, localSiteSeq: list_localSite[0].localSiteSeq, localSiteName: list_localSite[0].localSiteName });
        }
    }, [list_localSite]);
    
    useEffect(() => {
        if (isAddLocalServerOK) {
            setLocalServerName("");
            onChangeIsAddLocalServerOK();
            
            if (list_localSite && list_localSite.length > 0) {
                setLocalSiteInfo({ idx: 0, localSiteSeq: list_localSite[0].localSiteSeq, localSiteName: list_localSite[0].localSiteName });
            } else {
                setLocalSiteInfo({ idx: 0, localSiteSeq: -1, localSiteName: "" });
            }
        }
    }, [isAddLocalServerOK]);

    const onChangeLocalServerName = (e) => {
        setLocalServerName(e.target.value);
    }

    const onChangeSelectLocalSite = (e) => {
        if (list_localSite && list_localSite.length > 0) {
            let idx = e.target.value;
            setLocalSiteInfo({ idx, localSiteSeq: list_localSite[idx].localSiteSeq, localSiteName: list_localSite[idx].localSiteName });
        }
    }

    const onClickAddLocalServer = (e) => {
        handleSelectedFunc({ selectedFunc: 'CREATE_LOCAL_SERVER', data: { serverName: localServerName, subDomainSeq: selectedSubDomainInfo.subDomainSeq, localSiteSeq: lLocalSiteInfo.localSiteSeq, localSiteName: lLocalSiteInfo.localSiteName }});
    }

    return (
        <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
            <div className="card web-list-card border-primary">
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>{t("로컬_서버_이름", { ns: "admin" })}</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="email" id="inputLocalServerName" value={localServerName} className="d-flex flex-fill web-search-input mr-2" placeholder={t("로컬_서버_이름_입력")} style={{width:'70%'}} onChange={onChangeLocalServerName} />
                        </div>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>{t("로컬_사이트", { ns: "admin" })}</b></span>
                    </div>
                    <div className="input-data">
                        <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={lLocalSiteInfo.idx} onChange={onChangeSelectLocalSite}>
                            {
                                list_localSite && list_localSite.length > 0 ?
                                    list_localSite.map((info, index) => (
                                        <option value={index} key={"itemOption-" + index}>{info.localSiteName}</option>
                                    )) :
                                    <option value={-1}>
                                        {t("선택_가능한_로컬_사이트_없음", { ns: "admin" })}
                                    </option>
                            }
                        </select>
                    </div>
                </div>
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                <div className="d-flex text-center mt-3">
                    <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={onClickAddLocalServer} disabled={localServerName !== "" ? false : true}>
                        <b>{t("생성", { ns: "common" })}</b>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default WebAddLocalServer;
import React from "react";
import { useTranslation } from "react-i18next";

const WebLiveCertificationStatus = ({ liveCertiYN, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        liveCertiYN === "N" ?
            <span className="small grey-text px-2" title={t("승인대기", { ns: "live" })}>
                {t("승인대기", { ns: "live" })}
            </span> :
            <div className="landscape-to-portrait align-items-center">
                <span className="small red-text" title={t("거절됨", { ns: "friend" })}>
                    {t("거절됨", { ns: "friend" })}
                </span>
                <button type="button" className="btn btn-sm green white-text m-0 ml-1 py-1 px-2" title={t("신청", { ns: "common" })} onClick={handleSelectedFunc}>
                    {t("신청", { ns: "common" })}
                </button>
            </div>
    );
}

const WebRequestedWatchLive = ({ live_info, handleSelectedFunc }) => {
    return (
        <tr className="d-flex">
            <td className="landscape-to-portrait area-80">
                <div className="list-row area-60">
                    <div className="text-area">
                        <span className="hiclass-text" title={live_info.liveName}>
                            <b>{live_info.liveName}</b>
                        </span>
                    </div>
                </div>
                <div className="list-row area-40">
                    <div className="text-area">
                        <span className="hiclass-text hiclasstv-deepDarkgrey-text" title={live_info.teacher_info.userNickname}>
                            <b>{live_info.teacher_info.userNickname}</b>
                        </span>
                    </div>
                </div>
            </td>
            <td className="d-flex align-items-center justify-content-center area-20">
                <WebLiveCertificationStatus
                    liveCertiYN={live_info.liveCertiYN}
                    handleSelectedFunc={() => handleSelectedFunc({ selectedFunc: "RE_APPLY", liveSeq: live_info.liveSeq })}
                />
            </td>
        </tr>
    );
}

export default WebRequestedWatchLive;
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaCheck, FaRandom } from 'react-icons/fa';

import { getEntryMemberCount } from '../../lib/func/ExportFunction';

import NotifyBroadcastTime from './etc/NotifyBroadcastTime';

const MyBroadcastLiveList = ({ isVisibleTitle, isShowing, notifyInfo, list_live, connectedStudioInfo, serverUserSeq, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lGroupLimitArr, setGroupLimitArr] = useState([]);

    useEffect(() => {
        if (list_live !== undefined && list_live !== null && list_live.length > 0) {
            let list_groupLimit = list_live.map(
                live => ({
                    liveSeq : live.liveSeq,
                    groupLimit : live.groupLimit
                })
            );
    
            setGroupLimitArr(list_groupLimit);
        }
    }, [list_live, connectedStudioInfo]);

    useEffect(() => {
        if (connectedStudioInfo !== undefined && connectedStudioInfo !== null) {
            let liveArr = [];
            
            list_live.forEach(live => {
                if (live.liveStatus === 'Y' && (live.groupLimit === null || live.groupLimit === undefined)) {
                    liveArr.push(live.liveSeq);
                }
            });

            if (liveArr.length > 0) {
                handleSelectedFunc({selectedFunc:"UPDATE_GROUP_LIMIT", liveSeq:liveArr, groupLimit:connectedStudioInfo.groupLimit});
            }
        }
    }, [connectedStudioInfo]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChnageGroupLimitNum = (e) => {
        let str = e.target.id;
        let idList = str.split('-');
        let seq = idList[1];
        
        const arr = lGroupLimitArr.map(
            info => {
                let value = info.liveSeq == seq ? {
                    ...info,
                    groupLimit : e.target.value
                } : info;

                return value;
            }
        );
        setGroupLimitArr(arr);
    }

    const onHandleOnAirLive = ({ liveSeq, liveName, index, list_broadcast }) => {
        let groupLimit = 0;

        if (lGroupLimitArr !== undefined && lGroupLimitArr !== null && lGroupLimitArr.length > 0) {
            groupLimit = lGroupLimitArr[index].groupLimit;
        }

        if (groupLimit !== null && groupLimit !== undefined) {
            handleSelectedFunc({ selectedFunc:"ONAIR", liveSeq, liveName, groupLimit, list_broadcast });
        } else {
            if (connectedStudioInfo !== undefined && connectedStudioInfo !== null) {
                handleSelectedFunc({ selectedFunc:"ONAIR", liveSeq, liveName, groupLimit:connectedStudioInfo.groupLimit, list_broadcast });
            } else {
                handleSelectedFunc({ selectedFunc:"ONAIR", liveSeq, liveName });                
            }
        }
    }

    return (
        <>
            {
                isVisibleTitle && 
                <div className="title-area sub white-text" style={{ backgroundColor: '#2874ff' }}>
                    <b>{t("내_클래스_목록", { ns: "live" })}</b>
                </div>
            }
            {
                isShowing &&
                <NotifyBroadcastTime notifyInfo={notifyInfo} handleSelectedFunc={handleSelectedFunc} />
            }
            {
                list_live && list_live.length > 0 ?
                <div className="list-group scroll-show-active light" style={{ height: '100%', overflow: 'auto' }}>
                    {
                        list_live.map((live, index) => (
                            <div className={"d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" + (live.selectStatus === 'Y'? " active" : "")} key={live.liveSeq} onClick={() => handleSelectedFunc((live.liveStatus === 'Y' ? {selectedFunc: (live.selectStatus === "Y" ? "UNSELECT_ACTIVE_LIVE" : "SELECT_ACTIVE_LIVE"), liveSeq:live.liveSeq} : {selectedFunc:"DISABLE"}))}>
                                {
                                    live.liveStatus === "Y" &&
                                    <div className="d-flex justify-content-center align-items-center area-15 mr-2" onClick={handlePreventClick}>
                                        {
                                            live.selectStatus === "Y" ?
                                                <span className="text-center" style={{ color: '#2874ff' }} title={t("클래스_선택_해제", { ns: "live" })} onClick={() => handleSelectedFunc({ selectedFunc: "UNSELECT_ACTIVE_LIVE", liveSeq: live.liveSeq })}>
                                                    <FaCheck className="d-flex font-md button" />
                                                </span> :
                                                <span className="hiclasstv-grey-text text-center" title={t("클래스_선택", { ns: "live" })} onClick={() => handleSelectedFunc({ selectedFunc: "SELECT_ACTIVE_LIVE", liveSeq: live.liveSeq, groupLimit:live.groupLimit })}>
                                                    <FaCheck className="d-flex font-md button" />
                                                </span>
                                        }
                                    </div>
                                }
                                <div className="text-area flex-column align-items-start">
                                    <div className="text-area justify-content-start">
                                        <span className="hiclass-text" title={live.liveName}><b>{live.liveName}</b></span>
                                        <span className="hiclass-text hiclasstv-deepDarkgrey-text">&nbsp;{getEntryMemberCount('handsUp', live.list_member)}</span>
                                    </div>
                                    <div className="hiclass-text hiclasstv-darkgrey-text small">
                                    {t("참여자", { ns: "live" })}&nbsp;<b>{live.list_member.length}</b>{t("명", { ns: "common" })}
                                    </div>
                                    {
                                        connectedStudioInfo !== null && connectedStudioInfo !== undefined &&
                                        <div className="d-flex mr-2 p-0 align-items-center hiclasstv-darkgrey-text small" title={t("그룹_연결_인원_변경", { ns: "live" })} onClick={handlePreventClick}>
                                            <div className="d-flex align-items-center mr-2">
                                                <input type="radio" className="d-flex mr-1 p-0" name={"live-" + live.liveSeq + "-groupLimitNum"} id={"live-" + live.liveSeq + "-groupLimit_4"} value={4} onChange={onChnageGroupLimitNum} defaultChecked={live.groupLimit === null ? (connectedStudioInfo.groupLimit === 4 ? true : false) : (live.groupLimit == 4 ? true : false)} disabled={live.liveStatus === 'Y' ? true : false} />
                                                <span>4 {t("명", { ns: "common" })}</span>
                                            </div>
                                            <div className="d-flex align-items-center ml-2">
                                                <input type="radio" className="d-flex mr-1 p-0" name={"live-" + live.liveSeq + "-groupLimitNum"} id={"live-" + live.liveSeq + "-groupLimit_9"} value={9} onChange={onChnageGroupLimitNum} defaultChecked={live.groupLimit === null ? (connectedStudioInfo.groupLimit === 9 ? true : false) : (live.groupLimit == 9 ? true : false)} disabled={live.liveStatus === 'Y' ? true : false} />
                                                <span>9 {t("명", { ns: "common" })}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    serverUserSeq >= 0 && (live.liveStatus === 'Y' && live.mixedClassExistYn === 'Y') &&
                                        <div className="d-flex justify-content-center align-items-center area-15 mr-2" onClick={handlePreventClick}>
                                            {
                                                live.mixedClassStatus === "N" ?
                                                    <span className="text-center" style={{ color: '#888888' }} title={t("믹스드_클래스_켜기", { ns: "live" })} onClick={() => handleSelectedFunc({ selectedFunc: "UPDATE_MIXED_CLASS_STATUS", data: { kind: 'start', liveSeq: live.liveSeq } })}>
                                                        <FaRandom className="d-flex font-md button" />
                                                    </span> :
                                                    <span className="green-text text-center" title={t("믹스드_클래스_끄기", { ns: "live" })} disabled={live.liveStatus !== 'Y'} onClick={() => handleSelectedFunc({ selectedFunc: "UPDATE_MIXED_CLASS_STATUS", data: { kind: 'stop', liveSeq: live.liveSeq } })}>
                                                        <FaRandom className="d-flex font-md button" />
                                                    </span>
                                            }
                                        </div>
                                }
                                <div className="d-flex flex-column area-20 align-items-end justify-content-center ml-2" onClick={handlePreventClick}>
                                    {
                                        live.liveStatus === "N" ?
                                            <button type="button" className="btn btn-sm hiclasstv-main-grey white-text m-0 py-1 px-2" title={t("클래스_켜기", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => onHandleOnAirLive({ liveSeq: live.liveSeq, liveName: live.liveName, index, list_broadcast: live.list_broadcast })}>
                                                {t("온에어", { ns: "live" })}
                                            </button> :
                                            <button type="button" className="btn btn-sm hiclasstv-main-blue white-text m-0 py-1 px-2" title={t("클래스_끄기", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "OFFAIR_TOGGLE", liveSeq: live.liveSeq })}>
                                                {t("온에어", { ns: "live" })}
                                            </button>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div> :
                <div className="view p-2 text-center grey-text">
                    {t("클래스_알림_메시지_4", { ns: "live" })}
                </div>
            }
        </>
    );
}

export default MyBroadcastLiveList;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// import Congrats01 from "../../image/card/congrats_01.png";
// import Congrats02 from "../../image/card/congrats_02.png";
// import Congrats03 from "../../image/card/congrats_03.png";
// import Congrats04 from "../../image/card/congrats_04.png";

const CongratsType = ({ type }) => {
    const { t } = useTranslation();

    switch (type) {
        case "1":
            return (
                <>
                    <img src="/images/card/congrats_01.png" alt={t("축하_카드_메시지_1", { ns: "classTalk" })} />
                    {t("축하_카드_메시지_1", { ns: "classTalk" })}
                </>
            );

        case "2":
            return (
                <>
                    <img src="/images/card/congrats_02.png" alt={t("축하_카드_메시지_2", { ns: "classTalk" })} />
                    {t("축하_카드_메시지_2", { ns: "classTalk" })}
                </>
            );

        case "3":
            return (
                <>
                    <img src="/images/card/congrats_03.png" alt={t("축하_카드_메시지_3", { ns: "classTalk" })} />
                    {t("축하_카드_메시지_3", { ns: "classTalk" })}
                </>
            );

        case "4":
            return (
                <>
                    <img src="/images/card/congrats_04.png" alt={t("축하_카드_메시지_4", { ns: "classTalk" })} />
                    {t("축하_카드_메시지_4", { ns: "classTalk" })}
                </>
            );

        default:
            break;
    }
}

const CongratsCard = ({ isCongratsVisible, info }) => {
    return (
        info && isCongratsVisible &&
        <div className="congrats-card">
            <CongratsType type={info.type} />
        </div>
    );
}

export default CongratsCard;
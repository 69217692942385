import React from "react";
import { useTranslation } from "react-i18next";

import ServicePolicy from "./ServicePolicy";

const WebPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="web-main list-area align-items-center justify-content-center p-2">
            <h3>
                <b>{t("HiClassTV_서비스약관", { ns: "common" })}</b>
            </h3>
            <div className="web-policy-box no-modal p-2 my-2 scroll-show">
                <ServicePolicy />
            </div>
            <div className="footer-template-web" style={{ border: 'none' }}>
                <span>{t("EasySystems_copyright_1", { ns: "policy" })}</span>
            </div>
        </div>
    );
};

export default WebPolicy;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { enDrawingMode } from '../../../lib/classTalk/CodeEnum';

import { FaRegCircle, FaCircle, FaUndo, FaRedo, FaPen, FaPaintBrush, FaEraser, FaTrash, FaPalette, FaHandPaper, FaQuidditch } from 'react-icons/fa';

const LiveDrawingTool = ({ drawingMode, isDrawingInkStroke, isPossibleUndo, isPossibleRedo, handleSelectedDrawingToolFunc }) => {
    const { t } = useTranslation();

    const [toolbarZIndex, setToolbarZIndex] = useState("50");
    const [toolbarDisplayMode, setToolbarDisplayMode] = useState('flex');
    const [lIsPossibleRedo, setIsPossibleRedo] = useState(isPossibleRedo);

    const [isPaletteOpened, setIsPaletteOpened] = useState(false);
    const [isTipPaletteOpened, setIsTipPaletteOpened] = useState(false);

    useEffect(() => {
        //console.log("change is possible undo : " + isPossibleUndo + " & redo : " + isPossibleRedo);
        setIsPossibleRedo(isPossibleRedo);
    }, [isPossibleUndo, isPossibleRedo]);

    useEffect(() => {
        let toolbar = document.getElementById("ink-toolbar");

        if (isDrawingInkStroke) {
            //setToolbarDisplayMode('none');
            setToolbarZIndex("30");
        } else {
            //setToolbarDisplayMode('flex');
            setToolbarZIndex("50");
        }
    }, [isDrawingInkStroke]);

    const paletteToggleOpened = () => {
        setIsPaletteOpened(!isPaletteOpened);
        setIsTipPaletteOpened(false);
    }

    const tipPaletteToggleOpened = () => {
        setIsTipPaletteOpened(!isTipPaletteOpened);
        setIsPaletteOpened(false);
    }

    const onClickInkTool = ({ selectedFunc, tool }) => {
        setIsPaletteOpened(false);
        setIsTipPaletteOpened(false);
        handleSelectedDrawingToolFunc({ selectedFunc, tool });
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div id="ink-toolbar" className="live-toolbar-area right" style={{ zIndex: `${toolbarZIndex}` }}>
            <div onClick={handlePreventClick}>
                <ul>
                    <li>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (drawingMode === enDrawingMode.Hand ? " active" : "")} title={t("툴_실행모드", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_MODE", tool: enDrawingMode.Hand })}>
                            <FaHandPaper className='font-lg' />
                        </button>
                    </li>
                    <li>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (drawingMode === enDrawingMode.Pen ? " active" : "")} title={t("툴_펜모드", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_MODE", tool: enDrawingMode.Pen })}>
                            <FaPen className='font-lg' />
                        </button>
                    </li>
                    <li>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (drawingMode === enDrawingMode.Marker ? " active" : "")} title={t("툴_마커모드", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_MODE", tool: enDrawingMode.Marker })}>
                            <FaPaintBrush className='font-lg' />
                        </button>
                    </li>
                    <li>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (drawingMode === enDrawingMode.EraseStroke ? " active" : "")} title={t("툴_지우개모드", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_MODE", tool: enDrawingMode.EraseStroke })}>
                            <FaEraser className='font-lg' />
                        </button>
                    </li>
                    <li style={{ display: 'none' }} title={t("툴_실행취소", { ns: "live" })}>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (isPossibleUndo ? "" : " grey")} onClick={() => onClickInkTool({ selectedFunc: "UNDO" })} disabled={isPossibleUndo ? false : true}>
                            <FaUndo className='font-lg' />
                        </button>
                    </li>
                    <li style={{ display: 'none' }} title={t("툴_다시실행", { ns: "live" })}>
                        <button className={"d-flex no-effect hiclasstv-btn p-1" + (isPossibleRedo ? "" : " grey")} onClick={() => onClickInkTool({ selectedFunc: "REDO" })} disabled={lIsPossibleRedo ? false : true}>
                            <FaRedo className='font-lg' />
                        </button>
                    </li>
                    <li>
                        <button className="d-flex no-effect hiclasstv-btn p-1" title={t("툴_잉크_전부_삭제", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CLEAR" })}>
                            <FaTrash className='font-lg' />
                        </button>
                    </li>
                    <li>
                        <div className="toolbar-dropdown dropleft">
                            <button className={"d-flex no-effect hiclasstv-btn p-1" + (isPaletteOpened ? " hiclasstv-grey" : "")} title={t("툴_잉크_색상_선택", { ns: "live" })} onClick={() => paletteToggleOpened()}>
                                <FaPalette className='font-lg' />
                            </button>
                            <div className={"dropdown-menu p-1" + (isPaletteOpened ? " show" : "")}>
                                <div className="d-flex">
                                    <div className="ink-tool red-text" title={t("툴_색상_빨간색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 255, G: 0, B: 0 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool orange-text" title={t("툴_색상_주황색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 255, G: 127, B: 0 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool yellow-text" title={t("툴_색상_노란색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 255, G: 255, B: 0 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool green-text" title={t("툴_색상_초록색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 0, G: 255, B: 0 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="ink-tool blue-text" title={t("툴_색상_파란색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 0, G: 0, B: 255 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool purple-text" title={t("툴_색상_보라색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 255, G: 0, B: 255 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool black-text" title={t("툴_색상_검정색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 0, G: 0, B: 0 } })}>
                                        <span className="tip-tool">
                                            <FaCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool grey-text" title={t("툴_색상_흰색", { ns: "live" })} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_COLOR", tool: { R: 255, G: 255, B: 255 } })}>
                                        <span className="tip-tool">
                                            <FaRegCircle className='font-lg' style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="toolbar-dropdown dropleft">
                            <button className={"d-flex no-effect hiclasstv-btn p-1" + (isTipPaletteOpened ? " hiclasstv-grey" : "")} title={t("툴_잉크_굵기_선택", { ns: "live" })} onClick={() => tipPaletteToggleOpened()}>
                                <FaQuidditch className='font-lg' />
                            </button>
                            <div className={"dropdown-menu p-1" + (isTipPaletteOpened ? " show" : "")}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="ink-tool" title={t("툴_굵기_얇게", { ns: "live" })} style={{ width: '2rem', height: '2rem' }} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_THICK", tool: drawingMode === "MARKER" ? (2 * 1.5) : 2 })}>
                                        <span className="tip-tool" style={{ fontSize: '.5rem' }}>
                                            <FaCircle style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool" title={t("툴_굵기_보통", { ns: "live" })} style={{ width: '2rem', height: '2rem' }} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_THICK", tool: drawingMode === "MARKER" ? (4 * 1.5) : 4 })}>
                                        <span className="tip-tool" style={{ fontSize: '.8rem' }}>
                                            <FaCircle style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                    <div className="ink-tool" title={t("툴_굵기_두껍게", { ns: "live" })} style={{ width: '2rem', height: '2rem' }} onClick={() => onClickInkTool({ selectedFunc: "CHANGE_THICK", tool: drawingMode === "MARKER" ? (8 * 1.5) : 8 })}>
                                        <span className="tip-tool" style={{ fontSize: '1.2rem' }}>
                                            <FaCircle style={{ margin: '1px' }} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default LiveDrawingTool;
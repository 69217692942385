import React from 'react';
import { useTranslation } from "react-i18next";
import { BsX } from 'react-icons/bs';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const AddRecommendFriends = ({ list_rcvRequestFriends, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>{t("추천_친구", { ns: "friend" })}</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="list-group" style={{overflow:'auto'}}>
                    {
                        list_rcvRequestFriends.length > 0 ?
                            list_rcvRequestFriends.map(
                                friend => (
                                    <div className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center" key={"rcv_" + friend.userSeq}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                        </div>
                                        <div className="d-flex flex-column area-25 ml-1">
                                            <button type="button" className="btn btn-sm green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"ACCEPT", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("수락", { ns: "friend" })}</button>
                                            <button type="button" className="btn btn-sm red white-text m-0 mt-1 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:"REJECT", friendSeq: friend.userSeq, mode: "hiclass" })}>{t("거절", { ns: "common" })}</button>
                                        </div>
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                {t("추천_친구_목록_없음", { ns: "friend" })}
                            </div>
                    }
                </div>
            
        </div>
    );
}
export default AddRecommendFriends;
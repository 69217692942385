import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaExclamation, FaRegGrinBeamSweat } from 'react-icons/fa';

import ConstData from '../../../lib/ConstData';

import Alerts from '../list/Alerts';
import { oAlertKind } from '../../../lib/ConstCommand';

const AlertModal = ({ isModalOpened, alertType, alertKind, alertMessage, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    const onCloseModal = () => {
        setAlertKind('');
        setAlertMessage('');
    };
    
    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid">
                <div className="card-body">
                    {
                        alertType && alertType === oAlertKind.INVALID ?
                            <div className="title-area flex-column align-items-center text-center blue-text">
                                <FaRegGrinBeamSweat className="font-xxxlg hiclasstv-blue-text" />
                            </div> :                            
                            <div className="title-area text-center blue-text">
                                <FaExclamation className="font-xlg mr-2" />
                                <span className="font-xlg">{t("에러", { ns: "common" })}</span>
                            </div>
                    }
                    <div className="content-area white dark-grey-text m-0 p-0">
                        <Alerts kind={lAlertKind} message={lAlertMessage} />
                    </div>
                    <button type="button" className="btn btn-primary white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: 'TOGGLE_ALERT_MODAL' })}>{t("확인", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
};

export default AlertModal;
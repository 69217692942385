import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

import { enWebPage } from "../../../lib/ConstCommand";

import { subscribe } from "../../../lib/MediaSoupClient";
import { connectLiveForSmartTV } from "../../../lib/RabbitmqClient";

// import ConnectImg from "../../../images/connect.png";
// import AndroidConnectImg from "../../../images/android_connect.png";

// const ConnectImgComp = styled.img`
//     width : '100px';
//     height : '100px;
// `;

// const VideoComp = ConnectImgComp.withComponent("video");

const SmartTVMediaSoupRcv = forwardRef(({ idx, userSeq, isModalOpened, currentPageType, performLiveInfo, performLiveStatusInfo, smartTVBindInfo, onSwitchView }, ref) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [teacherInfo, setTeacherInfo] = useState(performLiveInfo && performLiveInfo.teacher_info ?
        performLiveInfo.teacher_info : { userSeq: -1, userNickname: "", userGateWay: "" });

    const [lClassName, setClassName] = useState("main-view");
    const [lDisplay, setDisplay] = useState("block");
    const lWidth = useRef("100%");
    // const [lWidth, setWidth] = useState("100%");
    // const [lZIndexValue, setZIndexValue] = useState("unset");
    // const [lHeight, setHeight] = useState("100%");
    // const [lLeftValue, setLeftValue] = useState("2px");
    // const [lBottomValue, setBottomValue] = useState("2px");
    // const [lWidth, setWidth] = useState("240px");
    // const [lHeight, setHeight] = useState("135px");
    // const [lZIndexValue, setZIndexValue] = useState("5");

    const videoEl = useRef(null);
    const audioEl = useRef(null);
    const micEl = useRef(null);

    const liveMediaSoupHandler = useCallback(() => {
        if (!isModalOpened) {
            if (teacherInfo.userSeq > -1 && performLiveInfo.liveSeq > -1) {
                if (performLiveInfo.liveStatus === "Y") {
                    if (performLiveStatusInfo.connectStatus === "disable") {
                        if (smartTVBindInfo.bindKeyArr.length > 0 && smartTVBindInfo.startSeqOfSmartTV > -1 && smartTVBindInfo.serverGateWay && smartTVBindInfo.serverGateWay !== "") {
                            connectLiveForSmartTV(smartTVBindInfo.bindKeyArr, smartTVBindInfo.startSeqOfSmartTV, smartTVBindInfo.serverGateWay);
                        } else {
                            console.log("liveMediaSoupHandler - smartTVBindInfo.bindKeyArr => ", smartTVBindInfo.bindKeyArr);
                            console.log("liveMediaSoupHandler - smartTVBindInfo.startSeqOfSmartTV => ", smartTVBindInfo.startSeqOfSmartTV);
                            console.log("liveMediaSoupHandler - smartTVBindInfo.serverGateWay => ", smartTVBindInfo.serverGateWay);
                        }
                    } else {
                        console.log(`liveMediaSoupHandler - connectStatus[${performLiveStatusInfo.connectStatus}]`);
                    }
                } else { // performLiveInfo.liveStatus === "N" case
                    console.log(`userSeq[${teacherInfo.userSeq}], liveSeq[${performLiveInfo.liveSeq}], liveStatus[${performLiveInfo.liveStatus}], connectStatus[${performLiveStatusInfo.connectStatus}]`);
                    console.log("smartTVBindInfo.bindKeyArr => ", smartTVBindInfo.bindKeyArr);
                    console.log("smartTVBindInfo.startSeqOfSmartTV => ", smartTVBindInfo.startSeqOfSmartTV);
                    console.log("smartTVBindInfo.serverGateWay => ", smartTVBindInfo.serverGateWay);
                    if (performLiveStatusInfo.connectStatus !== "disable") {
                        alert(t("클래스_종료_안내_문구_3", { ns: "live" }));
                        // history.push(`/smart_view/login/${performLiveInfo.liveSeq}`);
                        // window.location.reload();
                        if (currentPageType === enWebPage.SmartTV) {
                            window.location.replace(`/smarttv_login/${performLiveInfo.liveSeq}`);
                        } else {
                            window.location.replace(`/group_smarttv_login/${performLiveInfo.liveSeq}`);
                        }
                    } else {
                        console.log("liveMediaSoupHandler - 이런 경우가 있나요..? ... connectStatus => ", performLiveStatusInfo.connectStatus);
                    }
                }
            } else {
                console.log(`liveMediaSoupHandler - userSeq[${teacherInfo.userSeq}], liveSeq[${performLiveInfo.liveSeq}]`);
            }
        } else {
            console.log(`liveMediaSoupHandler - isModalOpened[${isModalOpened}]`);
        }
    }, [t, isModalOpened, teacherInfo.userSeq, performLiveInfo.liveSeq, performLiveInfo.liveStatus, performLiveStatusInfo.connectStatus, smartTVBindInfo.bindKeyArr, smartTVBindInfo.startSeqOfSmartTV, smartTVBindInfo.serverGateWay, currentPageType/* , history */]);

    useImperativeHandle(ref, () => ({
        updateMediaView: (value) => updateView(value),
    }));

    useEffect(() => {
        liveMediaSoupHandler();
    }, [liveMediaSoupHandler]);

    useEffect(() => {
        if (performLiveStatusInfo.isConnect) {
            console.log("performLiveStatusInfo.isConnect => ", performLiveStatusInfo.isConnect);
            subscribe(videoEl.current, audioEl.current, micEl.current);
        } else {
            console.log("performLiveStatusInfo.isConnect => ", performLiveStatusInfo.isConnect);
            if (videoEl.current) {
                videoEl.current.pause();
                videoEl.current.currentTime = 0;
                videoEl.current.poster = "/images/connect.png";
            } else {
                console.log("이런 경우는 뭘 해야 하는가..?");
            }
        }
    }, [performLiveStatusInfo.isConnect]);

    useEffect(() => {
        if (performLiveInfo.teacher_info) {
            setTeacherInfo({
                userSeq: performLiveInfo.teacher_info.userSeq,
                userNickname: performLiveInfo.teacher_info.userNickname,
                userGateWay: performLiveInfo.teacher_info.userGateWay
            });
        }
    }, [performLiveInfo.teacher_info]);

    const updateView = ({ className, width, display, zIndex, isImageViewToggle, isMultiViewToggle }) => {
        // let msg = `updateView - className[${className}], lWidth[${lWidth.current}], width[${width}]`;
        // msg += `, display[${display}], zIndex[${zIndex}], isImageViewToggle[${isImageViewToggle}], isMultiViewToggle[${isMultiViewToggle}]`;
        // console.log(msg);
        // if (width) setWidth(width);
        if (width) lWidth.current = width;
        if (className) setClassName(className);
        // console.log(`lWidth[${lWidth.current}], width[${width}]`);
        // console.log(`lDisplay[${lDisplay}], display[${display}]`);
        if (lWidth.current !== "100%" || isMultiViewToggle) {
            if (display) setDisplay(display);
        } else {
            if (lWidth.current === "100%") {
                setDisplay("block");
                // if (zIndex) setZIndexValue(zIndex);
            }
        }
    }

    const onClickView = () => {
        if (idx !== undefined && idx !== null) {
            if (lWidth.current !== "100%") {
                onSwitchView({ kind: "media_full_mode", idx, userSeq });
            }
        } else {
            if (lWidth.current === "100%") {
                onSwitchView({ kind: "image_full_mode", idx, userSeq });
            } else {
                onSwitchView({ kind: "media_full_mode", idx, userSeq });
            }
        }
    }

    return (
        <div
            ref={ref}
            className={"mediasoup_comp_container" + (lClassName !== "" ? " " + lClassName : "")}
            style={{ display: `${lDisplay}` }}
            onClick={onClickView}
        >
            <video id="mediasoup_video_comp" ref={videoEl} className="mediasoup_comp teacher" poster="/images/connect.png" playsInline autoPlay muted />
            <span className="mediasoup_comp teacher">{t("진행자_화면", { ns: "live" })}</span>
            <audio id="audio_comp" ref={audioEl} style={{ display: 'none' }} autoPlay />
            <audio id="mic_comp" ref={micEl} style={{ display: 'none' }} />
        </div>
    );
});

export default SmartTVMediaSoupRcv;
import { enTokenCMD, enDrawingMode } from './CodeEnum';

const CLASSID = 0; 
const PENID = 0;

export const encodeForWeb = (dataObject) => {
    let data = {};

    switch (dataObject.tokenCmd) {
        case enTokenCMD.ct_Poll                 : data = encode_1Byte(dataObject);          break;
        case enTokenCMD.ct_Response             : data = encode_1Byte(dataObject);          break;
        case enTokenCMD.ct_Login                : data = encode_Command(dataObject);        break;
        case enTokenCMD.ct_LoginResp            : data = encode_1Byte(dataObject);          break;
        case enTokenCMD.ct_Logout               : data = encode_Command(dataObject);        break;

        case enTokenCMD.Ink_SDn                 : data = encode_1Point(dataObject);         break;
        case enTokenCMD.Ink_SM                  : data = encode_1Point(dataObject);         break;
        case enTokenCMD.Ink_SDM                 : data = encode_2Point(dataObject);         break;
        case enTokenCMD.Ink_STM                 : data = encode_3Point(dataObject);         break;
        case enTokenCMD.Ink_SUp                 : data = encode_1Point(dataObject);         break;

        case enTokenCMD.Ink_Erase1              : data = encode_2Point(dataObject);         break;

        case enTokenCMD.Ink_SingleStroke        : data = encode_1Stroke(dataObject);        break;
        case enTokenCMD.Ink_PageStroke          : data = encode_NStroke(dataObject);        break;
        case enTokenCMD.Ink_Clear               : data = encode_Command(dataObject);        break;

        case enTokenCMD.Ink_Mode                : data = encode_1Byte(dataObject);          break;
        case enTokenCMD.Ink_Color               : data = encode_3Byte(dataObject);          break;
        case enTokenCMD.Ink_Tip                 : data = encode_2Double(dataObject);        break;

        case enTokenCMD.page_GetArtifact        : data = encode_Command(dataObject);        break;
        case enTokenCMD.page_PutArtifact        : data = encode_FileTransfer(dataObject);   break;

        case enTokenCMD.cont_PageJpeg           : data = encode_FileTransfer(dataObject);   break;
        case enTokenCMD.cont_GetPageJpeg        : data = encode_Command(dataObject);        break;
        case enTokenCMD.cont_StudentPageJpeg    : data = encode_FileTransfer(dataObject);   break;
        case enTokenCMD.cont_GetStudentPageJpeg : data = encode_Command(dataObject);        break;

        case enTokenCMD.chat_FileName           : data = encode_FileName(dataObject);       break;
        case enTokenCMD.chat_FileTransfer       : data = encode_FileTransfer(dataObject);   break;
        case enTokenCMD.chat_Memo               : data = encode_FileTransfer(dataObject);   break;
        case enTokenCMD.chat_Voice              : data = encode_FileTransfer(dataObject);   break;
        case enTokenCMD.chat_Text               : data = encode_ChatText(dataObject);       break;

        case enTokenCMD.ct_UserLoginInfo        : data = encode_2Integer1Byte(dataObject);  break;
        case enTokenCMD.ct_SendToWebJSonInfo    : data = encode_String(dataObject);         break;

        case enTokenCMD.ct_UploadAndShareFiles  : data = encode_FileName(dataObject);       break;
        case enTokenCMD.ct_ScreenUploadWindow   : data = encode_FileName(dataObject);       break;

        case enTokenCMD.ct_UpdateHandsUpImage   : data = encode_2IntegerWithFile(dataObject); break;

        default: data = null; break;
    }

    return data;
};

export const decodeForWeb = (dataBuffer) => {
    let dataObject = null;
    let int8View = new Uint8Array(dataBuffer);

    //console.log(`decodeForWeb - CMD(${int8View[0]})`);
    
    switch (int8View[0]) {
        case enTokenCMD.ct_Poll                 : dataObject = decode_1Byte(int8View);                  break;
        case enTokenCMD.ct_Response             : dataObject = decode_1Byte(int8View);                  break;
        case enTokenCMD.ct_Login                : dataObject = decode_Command(int8View);                break;
        case enTokenCMD.ct_LoginResp            : dataObject = decode_1Byte(int8View);                  break;
        case enTokenCMD.ct_Logout               : dataObject = decode_Command(int8View);                break;

        case enTokenCMD.Ink_SDn                 : dataObject = decode_1Point(int8View);                 break;
        case enTokenCMD.Ink_SM                  : dataObject = decode_1Point(int8View);                 break;
        case enTokenCMD.Ink_SDM                 : dataObject = decode_2Point(int8View);                 break;
        case enTokenCMD.Ink_STM                 : dataObject = decode_3Point(int8View);                 break;
        case enTokenCMD.Ink_SUp                 : dataObject = decode_1Point(int8View);                 break;
        
        case enTokenCMD.Ink_DDn                 : dataObject = decode_1Point(int8View);                 break;
        case enTokenCMD.Ink_DM                  : dataObject = decode_1Point(int8View);                 break;
        case enTokenCMD.Ink_DDM                 : dataObject = decode_2Point(int8View);                 break;
        case enTokenCMD.Ink_DUp                 : dataObject = decode_1Point(int8View);                 break;
        
        case enTokenCMD.Ink_Erase1              : dataObject = decode_2Point(int8View);                 break;

        case enTokenCMD.Ink_SingleStroke        : dataObject = decode_1Stroke(int8View);                break;
        case enTokenCMD.Ink_PageStroke          : dataObject = decode_NStroke(int8View);                break;
        case enTokenCMD.Ink_Clear               : dataObject = decode_Command(int8View);                break;

        case enTokenCMD.Ink_DefaultAttr         : dataObject = decode_InkDefaultAttributes(int8View);   break;
        case enTokenCMD.Ink_Mode                : dataObject = decode_1Byte(int8View);                  break;
        case enTokenCMD.Ink_Color               : dataObject = decode_3Byte(int8View);                  break;
        case enTokenCMD.Ink_Tip                 : dataObject = decode_2Double(int8View);                break;

        case enTokenCMD.page_GetArtifact        : dataObject = decode_Command(int8View);                break;
        case enTokenCMD.page_PutArtifact        : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.page_GetArtifactWithInks: dataObject = decode_Command(int8View);                break;

        case enTokenCMD.cont_PageJpeg           : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.cont_GetPageJpeg        : dataObject = decode_Command(int8View);                break;
        case enTokenCMD.cont_StudentPageJpeg    : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.cont_GetStudentPageJpeg : dataObject = decode_Command(int8View);                break

        case enTokenCMD.chat_FileName           : dataObject = decode_FileName(int8View);               break;
        case enTokenCMD.chat_FileTransfer       : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.chat_Memo               : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.chat_Voice              : dataObject = decode_FileTransfer(int8View);           break;
        case enTokenCMD.chat_Text               : dataObject = decode_ChatText(int8View);               break;
        case enTokenCMD.modum_ScreenMode        : dataObject = decode_2Short(int8View);                 break;
        case enTokenCMD.ctl_PageInfo            : dataObject = decode_2Short(int8View);                 break;

        case enTokenCMD.ct_UserLoginInfo        : dataObject = decode_2Integer1Byte(int8View);          break;
        case enTokenCMD.ct_SendToWebJSonInfo    : dataObject = decode_String(int8View);                 break;

        case enTokenCMD.ct_UploadAndShareFiles  : dataObject = decode_FileName(int8View);               break;

        case enTokenCMD.ct_UpdateHandsUpImage   : dataObject = decode_2IntegerWithFile(int8View);       break;

        case enTokenCMD.rq_PageClear            : dataObject = decode_Command(int8View);                break;

        default: dataObject = { CMD: int8View[0] === undefined ? enTokenCMD.NULL : int8View[0] }; break;
    }

    return dataObject;
};

const encode_Command = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(3);
    let int8View = new Uint8Array(arrayBuffer);

    int8View[idx++] = dataObject.tokenCmd;                  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    return Array.from(int8View);
};

const decode_Command = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    return rtnObject;
};

const decode_InkDefaultAttributes = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    rtnObject.INT1      = int8View[idx++];  // [3] ink mode
    rtnObject.BYTE1     = int8View[idx++];  // [4] r
    rtnObject.BYTE2     = int8View[idx++];  // [5] g
    rtnObject.BYTE3     = int8View[idx++];  // [6] b

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int16Int8View[0]    = int8View[idx++];  // [7]
    int16Int8View[1]    = int8View[idx++];  // [8]
    rtnObject.DOUBLE1   = int16View[0] / 10;
    int16Int8View[0]    = int8View[idx++];  // [9]
    int16Int8View[1]    = int8View[idx++];  // [10]
    rtnObject.DOUBLE2   = int16View[0] / 10;

    return rtnObject;
};

const encode_1Byte = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(4);
    let int8View = new Uint8Array(arrayBuffer);
    
    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]
    int8View[idx++] = dataObject.byte1;         // [3]

    return Array.from(int8View);
};

const decode_1Byte = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]
    rtnObject.byte1     = int8View[idx++];  // [3]

    return rtnObject;
};

const encode_3Byte = (dataObject) => {
    let idx = 0;
    
    let arrayBuffer = new ArrayBuffer(6);
    let int8View = new Uint8Array(arrayBuffer);
    
    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]
    int8View[idx++] = dataObject.byte1;         // [3]
    int8View[idx++] = dataObject.byte2;         // [4]
    int8View[idx++] = dataObject.byte3;         // [5]

    return Array.from(int8View);
};

const decode_3Byte = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]
    rtnObject.byte1     = int8View[idx++];  // [3]
    rtnObject.byte2     = int8View[idx++];  // [4]
    rtnObject.byte3     = int8View[idx++];  // [5]

    return rtnObject;
};

const decode_2Short = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int16Int8View[0]    = int8View[idx++];  // [3]
    int16Int8View[1]    = int8View[idx++];  // [4]
    rtnObject.INT1      = int16View[0];

    int16Int8View[0]    = int8View[idx++];  // [5]
    int16Int8View[1]    = int8View[idx++];  // [6]
    rtnObject.INT2      = int16View[0];

    return rtnObject;
};

const encode_2Double = (dataObject) => {
    let idx = 0;
    
    let arrayBuffer = new ArrayBuffer(7);
    let int8View = new Uint8Array(arrayBuffer);

    int8View[idx++] = dataObject.tokenCmd;  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int16View[0] = dataObject.double1 * 10;
    int8View[idx++] = int16Int8View[0];     // [3]
    int8View[idx++] = int16Int8View[1];     // [4]

    int16View[0] = dataObject.double2 * 10;
    int8View[idx++] = int16Int8View[0];     // [5]
    int8View[idx++] = int16Int8View[1];     // [6]

    return Array.from(int8View);
};

const decode_2Double = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int16Int8View[0]    = int8View[idx++];  // [3]
    int16Int8View[1]    = int8View[idx++];  // [4]
    rtnObject.double1   = int16View[0] / 10;
    
    int16Int8View[0]    = int8View[idx++];  // [5]
    int16Int8View[1]    = int8View[idx++];  // [6]
    rtnObject.double2   = int16View[0] / 10;

    return rtnObject;
};

const encode_1Stroke = (dataObject) => {
    //console.log(dataObject);

    let idx = 0;
    
    let pointArray = dataObject.stroke.StylusPoints;

    let arrayBuffer = new ArrayBuffer(15 + (pointArray.length * 5));
    let int8View = new Uint8Array(arrayBuffer);

    int8View[idx++] = dataObject.tokenCmd;  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    let parmData = {
        stroke      : dataObject.stroke,
        index       : idx,
        int8View    : int8View,
        mod         : dataObject.mode
    };

    encode_SubStrokeData(parmData);

    return Array.from(int8View);
};

const decode_1Stroke = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]
    
    let data = { int8View, index: idx };
    rtnObject.stroke = decode_SubStrokeData(data);

    return rtnObject;
};

const encode_NStroke = (dataObject) => {
    let idx = 0;
    let length = 3 + 2;     // CMD, CLASSID, PENID + length info

    dataObject.strokes.forEach(stroke => {
        length += 12 + (stroke.StylusPoints.length * 5);
    });

    let arrayBuffer = new ArrayBuffer(length);
    let int8View = new Uint8Array(arrayBuffer);

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int16View[0] = dataObject.strokes.length;
    int8View[idx++] = int16Int8View[0];         // [3]
    int8View[idx++] = int16Int8View[1];         // [4]

    let data = { stroke: null, index: idx, mod: null, int8View };

    dataObject.strokes.forEach(stroke => {
        data.stroke = stroke;
        data.index = idx;
        data.mod = stroke.DrawingAttributes.Color.A === 1 ? enDrawingMode.Pen : enDrawingMode.Marker;
        encode_SubStrokeData(data); // 실행 안되면 that 만들어서 해보기 by hjkim 20210901
        idx = data.index;
    });

    return Array.from(int8View);
};

const decode_NStroke = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int16Int8View[0] = int8View[idx++];     // [3]
    int16Int8View[1] = int8View[idx++];     // [4]
    let strokeCount = int16View[0];

    rtnObject.strokes = [];
    let data = { index: idx, int8View };

    for (let i = 0; i < strokeCount; i++) {
        data.index = idx;
        rtnObject.strokes.push(decode_SubStrokeData(data));
        idx = data.index;
        //console.log(`decode_NStroke idx[${idx}]`);
    }

    return rtnObject;
};

const encode_SubStrokeData = (dataObject) => {
    let idx = dataObject.index;

    let stroke = dataObject.stroke;
    let pointArrary = stroke.StylusPoints;
    let int8View = dataObject.int8View;

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    /** javascript와 c#의 rgba값 차이로 인해 값을 꼭 변환해서 보내야함 by hjkim 200704 */
    var color = stroke.DrawingAttributes.Color; 
    int8View[idx++] = Math.round(color.A * 255);    // [3]
    int8View[idx++] = Math.round(color.R);          // [4]
    int8View[idx++] = Math.round(color.G);          // [5]
    int8View[idx++] = Math.round(color.B);          // [6]

    if (dataObject.mod === enDrawingMode.Marker)
        int8View[idx++] = 1;                            // [7]
    else
        int8View[idx++] = 0;                            // [7]

    int8View[idx++] = 1;                                // [8] stroke.DrawingAttributes.FitToCurve

    let widthHeight = stroke.DrawingAttributes.lineWidth;

    int16View[0] = widthHeight * 10;
    int8View[idx++] = int16Int8View[0];                 // [9]
    int8View[idx++] = int16Int8View[1];                 // [10]
    
    int16View[0] = widthHeight * 10;
    int8View[idx++] = int16Int8View[0];                 // [11]
    int8View[idx++] = int16Int8View[1];                 // [12]
    
    int16View[0] = pointArrary.length;
    int8View[idx++] = int16Int8View[0];                 // [13]
    int8View[idx++] = int16Int8View[1];                 // [14]

    for (let i = 0; i < pointArrary.length; i++) {
        int16View[0] = pointArrary[i].x * 10;
        int8View[idx++] = int16Int8View[0];             // [15 + (i * 5) + 0]
        int8View[idx++] = int16Int8View[1];             // [15 + (i * 5) + 1]
        int16View[0] = pointArrary[i].y * 10;
        int8View[idx++] = int16Int8View[0];             // [15 + (i * 5) + 2]
        int8View[idx++] = int16Int8View[1];             // [15 + (i * 5) + 3]
        int8View[idx++] = 50;                           // [15 + (i * 5) + 4] PressureFactor
    }
    
    dataObject.index = idx;
};

const decode_SubStrokeData = (dataObject) => {
    let idx = dataObject.index;
    
    let int8View = dataObject.int8View;

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    let stroke = {};
    let drawingAttributes = {};
    let color = {};
    color.A = int8View[idx++];                // [3]
    color.R = int8View[idx++];                // [4]
    color.G = int8View[idx++];                // [5]
    color.B = int8View[idx++];                // [6]

    drawingAttributes.Color = color;

    drawingAttributes.IsHighlighter = int8View[idx++] === 0 ? false : true; // [7]
    drawingAttributes.FitToCurve = int8View[idx++] === 0 ? false : true;    // [8]

    int16Int8View[0] = int8View[idx++];             // [9]
    int16Int8View[1] = int8View[idx++];             // [10]
    drawingAttributes.Width = int16View[0] / 10;

    int16Int8View[0] = int8View[idx++];             // [11]
    int16Int8View[1] = int8View[idx++];             // [12]
    drawingAttributes.Height = int16View[0] / 10;

    drawingAttributes.lineWidth = (drawingAttributes.Width + drawingAttributes.Height) / 2;
    drawingAttributes.FitToCurve = true;

    int16Int8View[0] = int8View[idx++];             // [13]
    int16Int8View[1] = int8View[idx++];             // [14]
    let pointCount = int16View[0];

    let minPoints = {};
    let maxPoints = {};
    let points = [];

    for (let i = 0; i < pointCount; i++) {
        let point = {};

        int16Int8View[0] = int8View[idx++]          // [15 + (i * 5) + 0]
        int16Int8View[1] = int8View[idx++]          // [15 + (i * 5) + 1]
        point.x = int16View[0] / 10;
        int16Int8View[0] = int8View[idx++]          // [15 + (i * 5) + 2]
        int16Int8View[1] = int8View[idx++]          // [15 + (i * 5) + 3]
        point.y = int16View[0] / 10;
        point.PressureFactor = int8View[idx++];     // [15 + (i * 5) + 4]

        if (i === 0) {
            minPoints.x = point.x;
            minPoints.y = point.y;
            maxPoints.x = point.x;
            maxPoints.y = point.y;
        } else {
            if (point.x < minPoints.x) minPoints.x = point.x;
            if (point.y < minPoints.y) minPoints.y = point.y;
            if (point.x > maxPoints.x) maxPoints.x = point.x;
            if (point.y > maxPoints.y) maxPoints.y = point.y;
        }

        points.push(point);
    }

    stroke.StylusPoints = points;
    stroke.minPoints = minPoints;
    stroke.maxPoints = maxPoints;
    stroke.DrawingAttributes = drawingAttributes;

    dataObject.index = idx;

    return stroke;
};

const encode_FileName = (dataObject) => {
    let idx = 0;
    
    let encoder = new TextEncoder();
    let utf_str = encoder.encode(dataObject.filename);
    let fileSize = dataObject.fileSize;

    let arrayBuffer = new ArrayBuffer(utf_str.length + 10);
    let int8View = new Uint8Array(arrayBuffer);

    let int64Buff = new ArrayBuffer(8);
    let int64Int8View = new Uint8Array(int64Buff);
    let int64Int32View = new Int32Array(int64Buff);

    if (fileSize < Math.pow(2, 32)) {
        int64Int32View[0] = fileSize;
        int64Int32View[1] = 0;
    } else {
        int64Int32View[0] = fileSize & 0xFFFFFFFF;
        int64Int32View[1] = fileSize >> 32;
    }

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.owner;         // [1]

    for (let i = 0; i < 8; i++) {
        int8View[idx++] = int64Int8View[i];     // [2 + i]
    }

    for (let i = 0; i < utf_str.length; i++) {
        int8View[idx++] = utf_str[i];           // [10 + i]
    }

    return Array.from(int8View);
};

const decode_FileName = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    let int64Buff = new ArrayBuffer(8);
    let int64Int8View = new Uint8Array(int64Buff);
    let int64Int32View = new Int32Array(int64Buff);

    rtnObject.CMD   = int8View[idx++];      // [0]
    rtnObject.owner = int8View[idx++];      // [1]

    for (let i = 0; i < 8; i++) {
        int64Int8View[i] = int8View[idx++]; // [2 + i]
    }

    rtnObject.length = int64Int32View[1] === 0 ? int64Int32View[0] : int64Int32View[0] + int64Int32View[1] * Math.pow(2, 32);

    let decoder = new TextDecoder('utf-8');
    let utf_str = int8View.slice(idx++, int8View.length); // [10]

    rtnObject.FileName = decoder.decode(utf_str);

    return rtnObject;
};

const decode_FileNameWithoutOwner = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD   = int8View[idx++];                      // [0]

    let decoder = new TextDecoder('utf-8');
    let utf_str = int8View.slice(idx++, int8View.length);   // [1]

    rtnObject.FileName = decoder.decode(utf_str);

    return rtnObject;
};

const encode_FileTransfer = (dataObject) => {
    let idx = 0;
    
    let fileSize = dataObject.data.byteLength;

    let arrayBuffer = new ArrayBuffer(fileSize + 3);
    let int8View = new Uint8Array(arrayBuffer);
    let data = new Uint8Array(dataObject.data);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    for (let i = 0; i < fileSize; i++) {
        int8View[idx++] = data[i];              // [3 + i]
    }

    return Array.from(int8View);
};

const decode_FileTransfer = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    rtnObject.data = int8View.slice(idx++, int8View.length);    // [3 + fileSize]

    return rtnObject;
};

const encode_ChatText = (dataObject) => {
    let idx = 0;

    let encoder = new TextEncoder();
    let utf_str = encoder.encode(dataObject.text);

    let arrayBuffer = new ArrayBuffer(utf_str.length + 3);
    let int8View = new Uint8Array(arrayBuffer);

    int8View[idx++] = dataObject.tokenCmd;  // [0]
    int8View[idx++] = dataObject.emoGIDno;  // [1]
    int8View[idx++] = dataObject.emoMIDno;  // [2]

    for (let i = 0; i < utf_str.length; i++) {
        int8View[idx++] = utf_str[i];       // [3 + i]
    }

    return Array.from(int8View);
};

const decode_ChatText = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.emoGIDno  = int8View[idx++];  // [1]
    rtnObject.emoMIDno  = int8View[idx++];  // [2]

    let decoder = new TextDecoder('utf-8');
    let utf_str = int8View.slice(idx++, int8View.length);   // [3 + text length]
    
    rtnObject.text = decoder.decode(utf_str);

    return rtnObject;
};

const encode_String = (dataObject) => {
    let idx = 0;
    
    let encoder = new TextEncoder();
    let utf_str = encoder.encode(dataObject.text);

    let arrayBuffer = new ArrayBuffer(utf_str.length + 1);
    let int8View = new Uint8Array(arrayBuffer);

    int8View[idx++] = dataObject.tokenCmd;  // [0]

    for (let i = 0; i < utf_str.length; i++) {
        int8View[idx++] = utf_str[i];       // [1 + i]
    }

    return Array.from(int8View);
};

const decode_String = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD = int8View[idx++];    // [0]

    let decoder = new TextDecoder('utf-8');
    let utf_str = int8View.slice(idx++, int8View.length);   // [1 + text length]

    rtnObject.text = decoder.decode(utf_str);

    return rtnObject;
};

const encode_1Point = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(8);
    let int8View = new Uint8Array(arrayBuffer);

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int8View[idx++] = dataObject.tokenCmd;                  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int16View[0] = dataObject.SPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [3]
    int8View[idx++] = int16Int8View[1];                     // [4]
    int16View[0] = dataObject.SPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [5]
    int8View[idx++] = int16Int8View[1];                     // [6]
    int8View[idx++] = dataObject.SPT.PressureFactor * 100;  // [7]

    return Array.from(int8View);
};

const decode_1Point = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    rtnObject.SPT = {};
    int16Int8View[0] = int8View[idx++];     // [3]
    int16Int8View[1] = int8View[idx++];     // [4]
    rtnObject.SPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [5]
    int16Int8View[1] = int8View[idx++];     // [6]
    rtnObject.SPT.Y = int16View[0] / 10;
    rtnObject.SPT.PressureFactor = int8View[idx++] / 100;   // [7]

    return rtnObject;
};

const encode_2Point = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(13);
    let int8View = new Uint8Array(arrayBuffer);

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int8View[idx++] = dataObject.tokenCmd;                  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int16View[0] = dataObject.SPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [3]
    int8View[idx++] = int16Int8View[1];                     // [4]
    int16View[0] = dataObject.SPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [5]
    int8View[idx++] = int16Int8View[1];                     // [6]
    int8View[idx++] = dataObject.SPT.PressureFactor * 100;  // [7]

    int16View[0] = dataObject.EPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [8]
    int8View[idx++] = int16Int8View[1];                     // [9]
    int16View[0] = dataObject.EPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [10]
    int8View[idx++] = int16Int8View[1];                     // [11]
    int8View[idx++] = dataObject.EPT.PressureFactor * 100;  // [12]

    return Array.from(int8View);
};

const decode_2Point = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    rtnObject.SPT = {};
    int16Int8View[0] = int8View[idx++];     // [3]
    int16Int8View[1] = int8View[idx++];     // [4]
    rtnObject.SPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [5]
    int16Int8View[1] = int8View[idx++];     // [6]
    rtnObject.SPT.Y = int16View[0] / 10;
    rtnObject.SPT.PressureFactor = int8View[idx++] / 100;   // [7]

    rtnObject.EPT = {};
    int16Int8View[0] = int8View[idx++];     // [8]
    int16Int8View[1] = int8View[idx++];     // [9]
    rtnObject.EPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [10]
    int16Int8View[1] = int8View[idx++];     // [11]
    rtnObject.EPT.Y = int16View[0] / 10;
    rtnObject.EPT.PressureFactor = int8View[idx++] / 100;   // [12]

    return rtnObject;
};

const encode_3Point = (dataObject) => {
    let idx = 0;
    
    let arrayBuffer = new ArrayBuffer(18);
    let int8View = new Uint8Array(arrayBuffer);

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    int8View[idx++] = dataObject.tokenCmd;                  // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int16View[0] = dataObject.SPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [3]
    int8View[idx++] = int16Int8View[1];                     // [4]
    int16View[0] = dataObject.SPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [5]
    int8View[idx++] = int16Int8View[1];                     // [6]
    int8View[idx++] = dataObject.SPT.PressureFactor * 100;  // [7]

    int16View[0] = dataObject.MPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [8]
    int8View[idx++] = int16Int8View[1];                     // [9]
    int16View[0] = dataObject.MPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [10]
    int8View[idx++] = int16Int8View[1];                     // [11]
    int8View[idx++] = dataObject.MPT.PressureFactor * 100;  // [12]

    int16View[0] = dataObject.EPT.X * 10;
    int8View[idx++] = int16Int8View[0];                     // [13]
    int8View[idx++] = int16Int8View[1];                     // [14]
    int16View[0] = dataObject.EPT.Y * 10;
    int8View[idx++] = int16Int8View[0];                     // [15]
    int8View[idx++] = int16Int8View[1];                     // [16]
    int8View[idx++] = dataObject.EPT.PressureFactor * 100;  // [17]

    return Array.from(int8View);
};

const decode_3Point = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int16Buff = new ArrayBuffer(2);
    let int16Int8View = new Uint8Array(int16Buff);
    let int16View = new Int16Array(int16Buff);

    rtnObject.SPT = {};
    int16Int8View[0] = int8View[idx++];     // [3]
    int16Int8View[1] = int8View[idx++];     // [4]
    rtnObject.SPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [5]
    int16Int8View[1] = int8View[idx++];     // [6]
    rtnObject.SPT.Y = int16View[0] / 10;
    rtnObject.SPT.PressureFactor = int8View[idx++] / 100;   // [7]

    rtnObject.MPT = {};
    int16Int8View[0] = int8View[idx++];     // [8]
    int16Int8View[1] = int8View[idx++];     // [9]
    rtnObject.MPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [10]
    int16Int8View[1] = int8View[idx++];     // [11]
    rtnObject.MPT.Y = int16View[0] / 10;
    rtnObject.MPT.PressureFactor = int8View[idx++] / 100;   // [12]

    rtnObject.EPT = {};
    int16Int8View[0] = int8View[idx++];     // [13]
    int16Int8View[1] = int8View[idx++];     // [14]
    rtnObject.EPT.X = int16View[0] / 10;
    int16Int8View[0] = int8View[idx++];     // [15]
    int16Int8View[1] = int8View[idx++];     // [16]
    rtnObject.EPT.Y = int16View[0] / 10;
    rtnObject.EPT.PressureFactor = int8View[idx++] / 100;   // [17]

    return rtnObject;
};

const encode_1Integer = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(7);
    let int8View = new Uint8Array(arrayBuffer);

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int32View[0] = dataObject.INT1;
    int8View[idx++] = int32Int8View[0];         // [3]
    int8View[idx++] = int32Int8View[1];         // [4]
    int8View[idx++] = int32Int8View[2];         // [5]
    int8View[idx++] = int32Int8View[3];         // [6]

    return Array.from(int8View);
};

const decode_1Integer = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int32Int8View[0] = int8View[idx++];     // [3]
    int32Int8View[1] = int8View[idx++];     // [4]
    int32Int8View[2] = int8View[idx++];     // [5]
    int32Int8View[3] = int8View[idx++];     // [6]
    rtnObject.INT1 = int32View[0];

    return rtnObject;
};

const encode_1Integer1Byte = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(8);
    let int8View = new Uint8Array(arrayBuffer);

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int32View[0] = dataObject.INT1;
    int8View[idx++] = int32Int8View[0];         // [3]
    int8View[idx++] = int32Int8View[1];         // [4]
    int8View[idx++] = int32Int8View[2];         // [5]
    int8View[idx++] = int32Int8View[3];         // [6]

    int8View[idx++] = dataObject.BYTE1;         // [7]

    return Array.from(int8View);
};

const decode_1Integer1Byte = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int32Int8View[0] = int8View[idx++];     // [3]
    int32Int8View[1] = int8View[idx++];     // [4]
    int32Int8View[2] = int8View[idx++];     // [5]
    int32Int8View[3] = int8View[idx++];     // [6]
    rtnObject.INT1 = int32View[0];

    rtnObject.BYTE1 = int8View[idx++];      // [7]

    return rtnObject;
};

const encode_2Integer1Byte = (dataObject) => {
    let idx = 0;

    let arrayBuffer = new ArrayBuffer(12);
    let int8View = new Uint8Array(arrayBuffer);

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int32View[0] = dataObject.INT1;
    int8View[idx++] = int32Int8View[0];         // [3]
    int8View[idx++] = int32Int8View[1];         // [4]
    int8View[idx++] = int32Int8View[2];         // [5]
    int8View[idx++] = int32Int8View[3];         // [6]

    int32View[0] = dataObject.INT2;
    int8View[idx++] = int32Int8View[0];         // [7]
    int8View[idx++] = int32Int8View[1];         // [8]
    int8View[idx++] = int32Int8View[2];         // [9]
    int8View[idx++] = int32Int8View[3];         // [10]

    int8View[idx++] = dataObject.BYTE1;         // [11]

    return Array.from(int8View);
};

const decode_2Integer1Byte = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int32Int8View[0] = int8View[idx++];     // [3]
    int32Int8View[1] = int8View[idx++];     // [4]
    int32Int8View[2] = int8View[idx++];     // [5]
    int32Int8View[3] = int8View[idx++];     // [6]
    rtnObject.INT1 = int32View[0];

    int32Int8View[0] = int8View[idx++];     // [7]
    int32Int8View[1] = int8View[idx++];     // [8]
    int32Int8View[2] = int8View[idx++];     // [9]
    int32Int8View[3] = int8View[idx++];     // [10]
    rtnObject.INT2 = int32View[0];

    rtnObject.BYTE1 = int8View[idx++];      // [11]

    return rtnObject;
};

const decode_2IntegerWithFile = (int8View) => {
    let rtnObject = {};
    let idx = 0;

    rtnObject.CMD       = int8View[idx++];  // [0]
    rtnObject.CLASSID   = int8View[idx++];  // [1]
    rtnObject.PENID     = int8View[idx++];  // [2]

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int32Int8View[0] = int8View[idx++];     // [3]
    int32Int8View[1] = int8View[idx++];     // [4]
    int32Int8View[2] = int8View[idx++];     // [5]
    int32Int8View[3] = int8View[idx++];     // [6]
    rtnObject.INT1 = int32View[0];

    int32Int8View[0] = int8View[idx++];     // [7]
    int32Int8View[1] = int8View[idx++];     // [8]
    int32Int8View[2] = int8View[idx++];     // [9]
    int32Int8View[3] = int8View[idx++];     // [10]
    rtnObject.INT2 = int32View[0];

    rtnObject.data = int8View.slice(idx++, int8View.length);    // [11 + fileSize]

    return rtnObject;
};

const encode_2IntegerWithFile = (dataObject) => {
    let idx = 0;

    let fileSize = dataObject.data.byteLength;

    let arrayBuffer = new ArrayBuffer(fileSize + 11);
    let int8View = new Uint8Array(arrayBuffer);
    let data = new Uint8Array(dataObject.data);

    let int32Buff = new ArrayBuffer(4);
    let int32Int8View = new Uint8Array(int32Buff);
    let int32View = new Int32Array(int32Buff);

    int8View[idx++] = dataObject.tokenCmd;      // [0]
    int8View[idx++] = dataObject.CLASSID !== undefined ? dataObject.CLASSID : CLASSID;  // [1]
    int8View[idx++] = dataObject.PENID !== undefined ? dataObject.PENID : PENID;        // [2]

    int32View[0] = dataObject.INT1;
    int8View[idx++] = int32Int8View[0];         // [3]
    int8View[idx++] = int32Int8View[1];         // [4]
    int8View[idx++] = int32Int8View[2];         // [5]
    int8View[idx++] = int32Int8View[3];         // [6]

    int32View[0] = dataObject.INT2;
    int8View[idx++] = int32Int8View[0];         // [7]
    int8View[idx++] = int32Int8View[1];         // [8]
    int8View[idx++] = int32Int8View[2];         // [9]
    int8View[idx++] = int32Int8View[3];         // [10]

    for (let i = 0; i < fileSize; i++) {
        int8View[idx++] = data[i];              // [11 + i]
    }

    return Array.from(int8View);
};
import React from "react";

import { stCardItemType, stQuizDataKind } from "../../../../lib/ConstCommand";

import TextItem from "./TextItem";
import AudioItem from "./AudioItem";
import VideoItem from "./VideoItem";
import ImageItem from "./ImageItem";
import AnimatedMP3Item from "./AnimatedMP3Item";
import InkMediaItem from "./InkMediaItem";
import RoleScript from "./RoleScript";

const Item = ({ info, title }) => {
    switch (info.kind) {
        case stCardItemType.text:
        case stQuizDataKind.Text:
            return <TextItem text={info} />;

        case stCardItemType.audio:
        case stQuizDataKind.Audio:
            return <AudioItem audio={info} />;

        case stCardItemType.video:
        case stQuizDataKind.Video:
            return <VideoItem video={info} title={title} />;

        case stCardItemType.image:
        case stQuizDataKind.Image:
            return <ImageItem image={info} />;

        case stCardItemType.animatedMP3:
            return <AnimatedMP3Item animatedMP3={info} />;

        case stCardItemType.inkMedia:
            return <InkMediaItem inkMedia={info} />;

        case stCardItemType.roleScript:
            return <RoleScript role={info.role} script={info.script} />;

        default:
            return <></>;
    }
}

export default Item;
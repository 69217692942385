import React, { useState, useEffect, useRef } from "react";

import { decodeForWeb } from "../../../../lib/classTalk/Token";
import { stAttachmentType, stQuizDataType } from "../../../../lib/ConstCommand";

import InkPlayerDrawing from "./inkPlayer/InkPlayerDrawing";
import InkPlayerControl from "./inkPlayer/InkPlayerControl";

const BitConverter = {
    ToInt32: function (int8View, idx) {
        let int32Buff = new ArrayBuffer(4);
        let int32Int8View = new Uint8Array(int32Buff);
        let int32View = new Int32Array(int32Buff);

        for (let i = 0; i < 4; i++) {
            int32Int8View[i] = int8View[idx++];
        }

        return int32View[0];
    }
}

const InkMediaItem = ({ inkMedia }) => {
    const [inkData, setInkData] = useState(null);
    const [pathData, setPathData] = useState([]);

    const [curValue, setCurValue] = useState(0);
    const [maxValue, setMaxValue] = useState(1);

    const canvasRef = useRef(null);
    const audioRef = useRef(null);
    const controlRef = useRef(null);
    const timer = useRef(null);

    const isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (timer && timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }

            if (audioRef && audioRef.current) {
                if (!audioRef.current.paused) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }

                if (audioRef.current.src && audioRef.current.src !== "") {
                    URL.revokeObjectURL(audioRef.current.src);
                    audioRef.current.src = "";
                }

                audioRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (inkMedia !== undefined && inkMedia !== null) {
            if (inkMedia.data !== undefined && inkMedia.data !== null) {
                if (inkMedia.type === stAttachmentType.URL || inkMedia.type === stQuizDataType.URL) {
                    (async function fetchData() {
                        try {
                            const res = await fetch(inkMedia.data);
                            const arrayBuffer = res.arrayBuffer();
                            if (isMount.current) {
                                let int8View = new Uint8Array(arrayBuffer);
                                let index = 0;

                                // im3 file에서 background image 데이터를 가지고 온다
                                let image_length = BitConverter.ToInt32(int8View, index);
                                index += 4;

                                let image_blob = new Blob([new Uint8Array(int8View.subarray(index, image_length + index))], { type: "image/jpg" });
                                let image_objURL = URL.createObjectURL(image_blob);
                                if (canvasRef && canvasRef.current) {
                                    canvasRef.current.setCanvasBackground(image_objURL);
                                    // canvasRef.current.onload = function() {
                                    //     //cleanup.
                                    //     URL.revokeObjectURL(image_objURL);
                                    // }
                                }
                                index += image_length;

                                // im3 file에서 mp3 데이터를 가지고 온다
                                let audio_length = BitConverter.ToInt32(int8View, index);
                                index += 4;

                                let audio_blob = new Blob([new Uint8Array(int8View.subarray(index, audio_length + index))], { type: "audio/mp3" });
                                let audio_objURL = URL.createObjectURL(audio_blob);
                                //setAudioData(audio_objURL);
                                if (audioRef && audioRef.current) {
                                    audioRef.current.src = audio_objURL;
                                    // audioRef.currentaudioRef.current.onload = function () {
                                    //     //cleanup.
                                    //     URL.revokeObjectURL(audio_objURL);
                                    // }
                                }
                                index += audio_length;

                                // ink data file 데이터 가지고 온다
                                let ink_data_length = BitConverter.ToInt32(int8View, index);
                                index += 4;

                                let ink_data_buff = new Uint8Array(int8View.subarray(index, ink_data_length + index));
                                setInkData(ink_data_buff);
                                index += ink_data_length;
                            }
                        } catch (err) {
                            console.log("fetchData(InkMediaItem) - err => ", err);
                        }
                    })();
                } else {
                    console.log("inkMedia type is ", inkMedia.type);
                }
            }
        }
    }, [inkMedia]);

    useEffect(() => {
        if (inkData !== undefined && inkData !== null) {
            const thread_inkNoteData = (inkData) => {
                let index = 0;
                let list_data = [];

                let total_recording_time = BitConverter.ToInt32(inkData, index);
                //console.log("total recording time => ", total_recording_time);
                index += 4;

                while (true) {
                    try {
                        // Check EOF
                        if (index >= inkData.length) {
                            //console.log("EOF detected => " + index + " / " + inkData.length);
                            break;
                        }

                        // Get A Token
                        let token_length = BitConverter.ToInt32(inkData, index);
                        index += 4;

                        let time_to_delay = BitConverter.ToInt32(inkData, index);
                        index += 4;

                        let token_buff = new Uint8Array(inkData.subarray(index, token_length + index));
                        index += token_length;

                        let token = decodeForWeb(token_buff);
                        list_data.push({ time_to_delay, token });
                    } catch (err) {
                        console.log("thread_inkNoteData err => ", err);
                        break;
                    }
                }

                setPathData(list_data);
            }

            thread_inkNoteData(inkData);
        }
    }, [inkData]);

    const toggleInkMedia = () => {
        if (audioRef && audioRef.current) { // mp3 음악이 있는 경우
            if (audioRef.current.paused) {
                audioRef.current.play();
                setMaxValue(audioRef.current.duration);
                updateProgress();
            } else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
                setCurValue(0);
                if (timer && timer.current) {
                    clearTimeout(timer.current);
                    timer.current = null;
                }
            }
        } else { // 이런 경우가 있나..?
            // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
            if (canvasRef && canvasRef.current) {
                if (!canvasRef.current.played()) { // 음악이 없는 경우 잉크를 바로 재생
                    canvasRef.current.playInkMedia(pathData);
                    if (controlRef && controlRef.current) controlRef.current.play();
                    updateProgress();
                } else { // 음악이 없는 경우 잉크 재생 멈춤
                    canvasRef.current.pauseInkMedia();
                    if (controlRef && controlRef.current) controlRef.current.pause();
                    setCurValue(0);
                    if (timer && timer.current) {
                        clearTimeout(timer.current);
                        timer.current = null;
                    }
                }
            }
        }
    }

    const onPauseAudio = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (canvasRef && canvasRef.current) canvasRef.current.pauseInkMedia();
        if (controlRef && controlRef.current) controlRef.current.pause();
    }

    const onPlayAudio = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (canvasRef && canvasRef.current) canvasRef.current.playInkMedia(pathData);
        if (controlRef && controlRef.current) controlRef.current.play();
    }

    const onEndedAudio = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (canvasRef && canvasRef.current) canvasRef.current.pauseInkMedia();
        if (controlRef && controlRef.current) controlRef.current.pause();
    }

    const updateProgress = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (canvasRef && canvasRef.current) {
            if (!canvasRef.current.played()) {
                if (timer && timer.current) {
                    clearTimeout(timer.current);
                    timer.current = null;
                }
            }
        }

        if (curValue >= maxValue) {
            if (timer && timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
        }

        if (audioRef && audioRef.current) setCurValue(audioRef.current.currentTime);
        startTimer();

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // if (canvasRef && !canvasRef.current.played()) clearTimeout(timer.current);
        // if (curValue >= maxValue) clearTimeout(timer.current);

        // setCurValue(audioRef.current.currentTime);
        // startTimer();
        // 여기까지 기존 코드
    }

    const startTimer = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (canvasRef && canvasRef.current) {
            if (!canvasRef.current.played()) {
                if (timer && timer.current) {
                    clearTimeout(timer.current);
                    timer.current = null;
                }
            }
        }

        if (curValue >= maxValue) {
            if (timer && timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
        }

        if (timer && timer.current === null) {
            timer.current = setTimeout(() => updateProgress(), 300);
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // if (!canvasRef.current.played()) clearTimeout(timer.current);
        // if (curValue >= maxValue) clearTimeout(timer.current);

        // timer.current = setTimeout(() => updateProgress(), 300);
        // 여기까지 기존 코드 20230102 수정 by hjkim
    }

    return (
        <>
            <div className="inkMedia-item" type="item">
                <InkPlayerDrawing ref={canvasRef} />
                <progress value={curValue} max={maxValue} />
                <InkPlayerControl ref={controlRef} toggle={toggleInkMedia} />
            </div>
            <audio ref={audioRef} style={{ display: 'none' }} controlsList="nodownload" onPause={onPauseAudio} onPlay={onPlayAudio} onEnded={onEndedAudio} />
        </>
    );
}

export default InkMediaItem;
import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import createRequestThunk from "../lib/createRequestThunk";
import i18n from "../locales/config";

import ConstData from "../lib/ConstData";

import {
    check_sub_domain,
    change_sub_domain_logo,
    reset_user_login_status,
    reset_user_pw,
    get_manage_sub_domain_list,
    add_sub_domain_info,
    add_sub_domain_config_admin,
    remove_sub_domain_config_admin,
    add_sub_domain_create_admin,
    remove_sub_domain_create_admin,
    get_sub_domain_local_server_info,
    get_user_local_server_info,
    search_user,
    duplicate_check_users,
    create_users
} from "../lib/api/admin";

import {
    get_lite_server_list,
    create_lite_server,
    remove_lite_server,
    change_lite_server_info
} from "../lib/api/lite-local";

import {
    change_local_server_port_info,
    change_local_site_info,
    get_local_site_list,
    create_local_site,
    delete_local_site,
    class_close_on_local_site,
    class_open_on_local_site,
    create_local_server,
    remove_local_server
} from "../lib/api/local";

export const SUB_DOMAIN_STATE_INIT              = "admin/SUB_DOMAIN_INIT";
export const SUB_DOMAIN_INFO_INIT               = "admin/SUB_DOMAIN_INFO_INIT";

const SELECT_SUB_DOMAIN_INFO                    = "admin/SELECT_SUB_DOMAIN_INFO";
const SELECT_USER_INFO                          = "admin/SELECT_USER_INFO";

const FIND_USER                                 = "admin/FIND_USER";
const FIND_USER_SUCCESS                         = "admin/FIND_USER_SUCCESS";

const GET_MANAGE_SUB_DOMAIN_INFO                = "admin/GET_MANAGE_SUB_DOMAIN_INFO";
const GET_MANAGE_SUB_DOMAIN_INFO_SUCCESS        = "admin/GET_MANAGE_SUB_DOMAIN_INFO_SUCCESS";

const ADD_SUB_DOMAIN                            = "admin/ADD_SUB_DOMAIN";
const ADD_SUB_DOMAIN_SUCCESS                    = "admin/ADD_SUB_DOMAIN_SUCCESS";

const CHECK_SUB_DOMAIN                          = "admin/CHECK_SUB_DOMAIN";
const CHECK_SUB_DOMAIN_SUCCESS                  = "admin/CHECK_SUB_DOMAIN_SUCCESS";
const CHECK_DUPLICATE_SUB_DOMAIN                = "admin/CHECK_DUPLICATE_SUB_DOMAIN";
const CHECK_DUPLICATE_SUB_DOMAIN_SUCCESS        = "admin/CHECK_DUPLICATE_SUB_DOMAIN_SUCCESS";

const CHANGE_SUB_DOMAIN_LOGO                    = "admin/CHANGE_SUB_DOMAIN_LOGO";
const CHANGE_SUB_DOMAIN_LOGO_SUCCESS            = "admin/CHANGE_SUB_DOMAIN_LOGO_SUCCESS";

const RESET_USER_LOGIN_STATUS                   = "admin/RESET_USER_LOGIN_STATUS";
const RESET_USER_LOGIN_STATUS_SUCCESS           = "admin/RESET_USER_LOGIN_STATUS_SUCCESS";
const RESET_USER_PW                             = "admin/RESET_USER_PW";
const RESET_USER_PW_SUCCESS                     = "admin/RESET_USER_PW_SUCCESS";

const ADD_SUB_DOMAIN_CONFIG_ADMIN               = "admin/ADD_SUB_DOMAIN_CONFIG_ADMIN";
const ADD_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS       = "admin/ADD_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS";
const ADD_SUB_DOMAIN_CREATE_ADMIN               = "admin/ADD_SUB_DOMAIN_CREATE_ADMIN";
const ADD_SUB_DOMAIN_CREATE_ADMIN_SUCCESS       = "admin/ADD_SUB_DOMAIN_CREATE_ADMIN_SUCCESS";
const REMOVE_SUB_DOMAIN_CONFIG_ADMIN            = "admin/REMOVE_SUB_DOMAIN_CONFIG_ADMIN";
const REMOVE_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS    = "admin/REMOVE_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS";
const REMOVE_SUB_DOMAIN_CREATE_ADMIN            = "admin/REMOVE_SUB_DOMAIN_CREATE_ADMIN";
const REMOVE_SUB_DOMAIN_CREATE_ADMIN_SUCCESS    = "admin/REMOVE_SUB_DOMAIN_CREATE_ADMIN_SUCCESS";

const DUPLICATE_CHECK_USERS                     = "admin/DUPLICATE_CHECK_USERS";
const DUPLICATE_CHECK_USERS_SUCCESS             = "admin/DUPLICATE_CHECK_USERS_SUCCESS";
const CREATE_USERS                              = "admin/CREATE_USERS";
const CREATE_USERS_SUCCESS                      = "admin/CREATE_USERS_SUCCESS";

const SELECT_SUB_DOMAIN_CONFIG_INFO             = "admin/SELECT_SUB_DOMAIN_CONFIG_INFO";
const FALSE_TO_CHANGE_LOGO                      = "admin/FALSE_TO_CHANGE_LOGO";

const CHANGE_ALERT_MESSAGE                      = "admin/CHANGE_ALERT_MESSAGE";
const SET_ALERT_MESSAGE                         = "admin/SET_ALERT_MESSAGE";
const SET_ALERT_KIND_MESSAGE                    = "admin/SET_ALERT_KIND_MESSAGE";

export const getMyManageSubDomainList           = createRequestThunk(GET_MANAGE_SUB_DOMAIN_INFO, get_manage_sub_domain_list);

export const findUser                           = createRequestThunk(FIND_USER, search_user);

export const selectSubDomainInfo                = createAction(SELECT_SUB_DOMAIN_INFO);
export const selectUserInfo                     = createAction(SELECT_USER_INFO);

export const checkSubDomain                     = createRequestThunk(CHECK_SUB_DOMAIN, check_sub_domain);
export const duplicateCheckSubDomain            = createRequestThunk(CHECK_DUPLICATE_SUB_DOMAIN, check_sub_domain);
export const changeSubDomainLogo                = createRequestThunk(CHANGE_SUB_DOMAIN_LOGO, change_sub_domain_logo);
export const resetUserLoginStatus               = createRequestThunk(RESET_USER_LOGIN_STATUS, reset_user_login_status);
export const resetUserPW                        = createRequestThunk(RESET_USER_PW, reset_user_pw);

export const addSubDomainInfo                   = createRequestThunk(ADD_SUB_DOMAIN, add_sub_domain_info);
export const addSubDomainConfigAdmin            = createRequestThunk(ADD_SUB_DOMAIN_CONFIG_ADMIN, add_sub_domain_config_admin);
export const removeSubDomainConfigAdmin         = createRequestThunk(REMOVE_SUB_DOMAIN_CONFIG_ADMIN, remove_sub_domain_config_admin);
export const addSubDomainCreateAdmin            = createRequestThunk(ADD_SUB_DOMAIN_CREATE_ADMIN, add_sub_domain_create_admin);
export const removeSubDomainCreateAdmin         = createRequestThunk(REMOVE_SUB_DOMAIN_CREATE_ADMIN, remove_sub_domain_create_admin);

export const duplicateCheckUsers                = createRequestThunk(DUPLICATE_CHECK_USERS, duplicate_check_users);
export const createUsers                        = createRequestThunk(CREATE_USERS, create_users);

export const selectSubDomainConfigInfo          = createAction(SELECT_SUB_DOMAIN_CONFIG_INFO);
export const falseToChangeLogo                  = createAction(FALSE_TO_CHANGE_LOGO);

export const changeAlertMessage                 = (message, kind) => ({type : CHANGE_ALERT_MESSAGE, message, kind});
export const setAlertMessageString              = (message, kind) => ({type : SET_ALERT_KIND_MESSAGE, message, kind});
export const setMessageString                   = message => ({type : SET_ALERT_MESSAGE, message});

// * * * * * * * local server 관리용 * * * * * * * //
const CREATE_LOCAL_SERVER                       = "admin/CREATE_LOCAL_SERVER";
const CREATE_LOCAL_SERVER_SUCCESS               = "admin/CREATE_LOCAL_SERVER_SUCCESS";
const REMOVE_LOCAL_SERVER                       = "admin/REMOVE_LOCAL_SERVER";
const REMOVE_LOCAL_SERVER_SUCCESS               = "admin/REMOVE_LOCAL_SERVER_SUCCESS";

const GET_SUB_DOMAIN_LOCAL_SERVER_INFO          = "admin/GET_SUB_DOMAIN_LOCAL_SERVER_INFO";
const GET_SUB_DOMAIN_LOCAL_SERVER_INFO_SUCCESS  = "admin/GET_SUB_DOMAIN_LOCAL_SERVER_INFO_SUCCESS";
const GET_USER_LOCAL_SERVER_INFO                = "admin/GET_USER_LOCAL_SERVER_INFO";
const GET_USER_LOCAL_SERVER_INFO_SUCCESS        = "admin/GET_USER_LOCAL_SERVER_INFO_SUCCESS";
const CHANGE_LOCAL_SITE_INFO                    = "admin/CHANGE_LOCAL_SITE_INFO";
const CHANGE_LOCAL_SITE_INFO_SUCCESS            = "admin/CHANGE_LOCAL_SITE_INFO_SUCCESS";
const CHANGE_LOCAL_SERVER_PORT_INFO             = "admin/CHANGE_LOCAL_SERVER_PORT_INFO";
const CHANGE_LOCAL_SERVER_PORT_INFO_SUCCESS     = "admin/CHANGE_LOCAL_SERVER_PORT_INFO_SUCCESS";

const GET_LOCAL_SITE_LIST                       = "admin/GET_LOCAL_SITE_LIST";
const GET_LOCAL_SITE_LIST_SUCCESS               = "admin/GET_LOCAL_SITE_LIST_SUCCESS";
const CREATE_LOCAL_SITE                         = "admin/CREATE_LOCAL_SITE";
const CREATE_LOCAL_SITE_SUCCESS                 = "admin/CREATE_LOCAL_SITE_SUCCESS";
const DELETE_LOCAL_SITE                         = "admin/DELETE_LOCAL_SITE";
const DELETE_LOCAL_SITE_SUCCESS                 = "admin/DELETE_LOCAL_SITE_SUCCESS";
const CLASS_OPEN_ON_LOCAL_SITE                  = "admin/CLASS_OPEN_ON_LOCAL_SITE";
const CLASS_OPEN_ON_LOCAL_SITE_SUCCESS          = "admin/CLASS_OPEN_ON_LOCAL_SITE_SUCCESS";
const CLASS_CLOSE_ON_LOCAL_SITE                 = "admin/CLASS_CLOSE_ON_LOCAL_SITE";
const CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS         = "admin/CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS";

export const createLocalServer                  = createRequestThunk(CREATE_LOCAL_SERVER, create_local_server);
export const removeLocalServer                  = createRequestThunk(REMOVE_LOCAL_SERVER, remove_local_server);
export const getSubDomainLocalServerList        = createRequestThunk(GET_SUB_DOMAIN_LOCAL_SERVER_INFO, get_sub_domain_local_server_info);
export const getUserLocalServerList             = createRequestThunk(GET_USER_LOCAL_SERVER_INFO, get_user_local_server_info);
export const changeLocalSiteInfo                = createRequestThunk(CHANGE_LOCAL_SITE_INFO, change_local_site_info);
export const changeLocalServerPortInfo          = createRequestThunk(CHANGE_LOCAL_SERVER_PORT_INFO, change_local_server_port_info);

export const getLocalSiteList                   = createRequestThunk(GET_LOCAL_SITE_LIST, get_local_site_list);
export const createLocalSite                    = createRequestThunk(CREATE_LOCAL_SITE, create_local_site);
export const deleteLocalSite                    = createRequestThunk(DELETE_LOCAL_SITE, delete_local_site);
export const classOpenOnLocalSite               = createRequestThunk(CLASS_OPEN_ON_LOCAL_SITE, class_open_on_local_site);
export const classCloseOnLocalSite              = createRequestThunk(CLASS_CLOSE_ON_LOCAL_SITE, class_close_on_local_site);

// * * * * * * * ver.Lite local server 관리용 * * * * * * * //
const GET_LITE_SERVER_LIST                      = "admin/GET_LITE_SERVER_LIST";
const GET_LITE_SERVER_LIST_SUCCESS              = "admin/GET_LITE_SERVER_LIST_SUCCESS";

const CREATE_LITE_SERVER                        = "admin/CREATE_LITE_SERVER";
const CREATE_LITE_SERVER_SUCCESS                = "admin/CREATE_LITE_SERVER_SUCCESS";

const REMOVE_LITE_SERVER                        = "admin/REMOVE_LITE_SERVER";
const REMOVE_LITE_SERVER_SUCCESS                = "admin/REMOVE_LITE_SERVER_SUCCESS";

const CHANGE_LITE_SERVER_INFO                   = "admin/CHANGE_LITE_SERVER_INFO";
const CHANGE_LITE_SERVER_INFO_SUCCESS           = "admin/CHANGE_LITE_SERVER_INFO_SUCCESS";

export const createLiteServer                   = createRequestThunk(CREATE_LITE_SERVER, create_lite_server);
export const removeLiteServer                   = createRequestThunk(REMOVE_LITE_SERVER, remove_lite_server);
export const getLiteServerList                  = createRequestThunk(GET_LITE_SERVER_LIST, get_lite_server_list);
export const changeLiteServerInfo               = createRequestThunk(CHANGE_LITE_SERVER_INFO, change_lite_server_info);

// * * * * * * * MQ function * * * * * * * //
const MQ_MEDIA_SERVER_INFO                      = "admin/MQ_MEDIA_SERVER_INFO";

export const mqRcvMediaInfo                     = createAction(MQ_MEDIA_SERVER_INFO);

const initialState = {
    list_configSubDomain        : [],
    list_createSubDomain        : [],
    list_configLocalServer      : [],
    list_configLiteServer       : [],
    list_configLocalSite        : [],

    list_localSite              : [], // 로컬서버 정보 변경할 때 보여줄 간단한 local site 목록 ...23.11.09 by ykhan 

    media_info                  : null,

    searchResult                : [],

    selectedUserInfo            : {
        userSeq                 : -1,
        userID                  : "",
        userNickname            : "",
    },
    selectedSubDomainInfo       : {
        selectedIdx             : 0,
        subDomainSeq            : -1,
        subDomainName           : i18n.t("서브_도메인_없음", { ns: "admin" })
    },

    isStandardType              : false,
    isCheckedSubDomain          : false,
    isCorrectSubDomain          : false,

    isCheckSubDomainOK          : false,
    isAddSubDomainOK            : false,
    isChangeLogoOK              : false,

    alertKind                   : ConstData.ALERT_KIND.NONE,
    alertMessage                : ""
};

const admin = handleActions({
    [SUB_DOMAIN_STATE_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("SUB_DOMAIN_STATE_INIT - ", action.payload);

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                return {
                    ...state,
                    list_configSubDomain    : [],
                    list_createSubDomain    : [],

                    selectedUserInfo        : {
                        userSeq             : -1,
                        userID              : "",
                        userNickname        : ""
                    },
                    selectedSubDomainInfo   : {
                        selectedIdx         : 0,
                        subDomainSeq        : -1,
                        subDomainName       : i18n.t("서브_도메인_없음", { ns: "admin" })
                    },

                    isCheckedSubDomain      : false,
                    isCorrectSubDomain      : false,

                    isCheckSubDomainOK      : false,
                    isAddSubDomainOK        : false,
                    isChangeLogoOK          : false,

                    alertKind               : ConstData.ALERT_KIND.NONE,
                    alertMessage            : ""
                }
            }
        } else {
            console.log("SUB_DOMAIN_STATE_INIT - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [SUB_DOMAIN_INFO_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("SUB_DOMAIN_INFO_INIT - ", action.payload);

        if (isSuccessed) {
            const { list_configSubDomain, list_createSubDomain } = action.payload;
            let clist_configSubDomain = [];
            let clist_createSubDomain = [];
            let selConfigSubDomainInfo = {
                selectedIdx     : 0,
                subDomainSeq    : -1,
                subDomainName   : i18n.t("서브_도메인_없음", { ns: "admin" })
            };

            if (list_configSubDomain && list_configSubDomain.length > 0) {
                clist_configSubDomain = list_configSubDomain.map(row => ({
                    subDomainSeq    : row.SUB_DOMAIN_SEQ,
                    subDomainName   : row.SUB_DOMAIN_NM,
                    chatRoomCnt     : row.CT_ROOM_CNT,
                    liveCnt         : row.LIVE_CNT,
                    liveOnCnt       : row.LIVE_ON_CNT,
                    list_member     : row.list_member === undefined || row.list_member === null ? 
                                      [] : 
                                      row.list_member.map(memRow => ({
                                        userSeq       : memRow.USER_SEQ,
                                        userID        : memRow.USER_ID,
                                        userNickname  : memRow.USER_NICKNAME,
                                        userProfile   : memRow.USER_PROFILE,
                                        profileImgUrl : memRow.USER_PROFILE_IMG,
                                        userStatus    : memRow.MEMBER_STATUS,
                                        webUserStatus : memRow.WEB_MEMBER_STATUS,
                                        userLoginDT   : memRow.USER_LOGIN_DT,
                                        userSignUpDT  : memRow.USER_SIGNUP_DT, 
                                        isConfigAdmin : memRow.CONFIG_ADMIN_YN === "Y" ? true : false,
                                        isCreateAdmin : memRow.CREATE_ADMIN_YN === "Y" ? true : false,
                                      }))
                }));
            }

            if (list_createSubDomain && list_createSubDomain.length > 0) {
                clist_createSubDomain = list_createSubDomain.map(row => ({
                    subDomainSeq    : row.SUB_DOMAIN_SEQ,
                    subDomainName   : row.SUB_DOMAIN_NM,
                    memberCnt       : row.MEMBER_CNT,
                    chatRoomCnt     : row.CT_ROOM_CNT,
                    liveCnt         : row.LIVE_CNT,
                    liveOnCnt       : row.LIVE_ON_CNT,
                }));
            }

            if (clist_configSubDomain.length > 0) {
                selConfigSubDomainInfo = {
                    selectedIdx     : 0,
                    subDomainSeq    : clist_configSubDomain[0].subDomainSeq,
                    subDomainName   : clist_configSubDomain[0].subDomainName
                };
            }

            return {
                ...state,
                list_configSubDomain    : clist_configSubDomain,
                list_createSubDomain    : clist_createSubDomain,
                selectedSubDomainInfo   : selConfigSubDomainInfo,
            }
        } else {
            console.log("SUB_DOMAIN_INFO_INIT - isSuccessed => ", isSuccessed);

            return {
                ...state,
                selectedSubDomainInfo   : {
                    selectedIdx         : 0,
                    subDomainSeq        : -1,
                    subDomainName       : i18n.t("서브_도메인_없음", { ns: "admin" })
                },
                list_configSubDomain    : [],
                list_createSubDomain    : []
            }
        }
    },

    // for sub domain admin
    [GET_MANAGE_SUB_DOMAIN_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_MANAGE_SUB_DOMAIN_INFO - ", action.payload);

        if (isSuccessed) {
            const { list_configSubDomain, list_createSubDomain } = action.payload;
            let clist_configSubDomain = [];
            let clist_createSubDomain = [];
            let selConfigSubDomainInfo = {
                selectedIdx     : 0,
                subDomainSeq    : -1,
                subDomainName   : i18n.t("서브_도메인_없음", { ns: "admin" })
            };

            if (list_configSubDomain && list_configSubDomain.length > 0) {
                clist_configSubDomain = list_configSubDomain.map(row => ({
                    subDomainSeq    : row.SUB_DOMAIN_SEQ,
                    subDomainName   : row.SUB_DOMAIN_NM,
                    chatRoomCnt     : row.CT_ROOM_CNT,
                    liveCnt         : row.LIVE_CNT,
                    liveOnCnt       : row.LIVE_ON_CNT,
                    list_member     : row.list_member === undefined || row.list_member === null ? 
                                      [] : 
                                      row.list_member.map(memRow => ({
                                        userSeq         : memRow.USER_SEQ,
                                        userID          : memRow.USER_ID,
                                        userNickname    : memRow.USER_NICKNAME,
                                        userProfile     : memRow.USER_PROFILE,
                                        profileImgUrl   : memRow.USER_PROFILE_IMG,
                                        userStatus      : memRow.MEMBER_STATUS,
                                        webUserStatus   : memRow.WEB_MEMBER_STATUS,
                                        userLoginDT     : memRow.USER_LOGIN_DT,
                                        userSignUpDT    : memRow.USER_SIGNUP_DT, 
                                        isConfigAdmin   : memRow.CONFIG_ADMIN_YN === "Y" ? true : false,
                                        isCreateAdmin   : memRow.CREATE_ADMIN_YN === "Y" ? true : false,
                                      }))
                }));
            }

            if (list_createSubDomain && list_createSubDomain.length > 0) {
                clist_createSubDomain = list_createSubDomain.map(row => ({
                    subDomainSeq    : row.SUB_DOMAIN_SEQ,
                    subDomainName   : row.SUB_DOMAIN_NM,
                    memberCnt       : row.MEMBER_CNT,
                    chatRoomCnt     : row.CT_ROOM_CNT,
                    liveCnt         : row.LIVE_CNT,
                    liveOnCnt       : row.LIVE_ON_CNT,
                }));
            }

            if (clist_configSubDomain.length > 0) {
                selConfigSubDomainInfo = {
                    selectedIdx     : 0,
                    subDomainSeq    : clist_configSubDomain[0].subDomainSeq,
                    subDomainName   : clist_configSubDomain[0].subDomainName
                };
            }

            return {
                ...state,
                list_configSubDomain    : clist_configSubDomain,
                list_createSubDomain    : clist_createSubDomain,
                selectedSubDomainInfo   : selConfigSubDomainInfo,
                isChangeLogoOK          : false,
                alertMessage            : "",
                alertKind               : ConstData.ALERT_KIND.NONE,
            }
        } else {
            console.log("GET_MANAGE_SUB_DOMAIN_INFO - isSuccessed => ", isSuccessed);

            return {
                ...state,
                list_configSubDomain    : [],
                list_createSubDomain    : [],
                selectedSubDomainInfo   : {
                    selectedIdx         : 0,
                    subDomainSeq        : -1,
                    subDomainName       : i18n.t("서브_도메인_없음", { ns: "admin" })
                },
                isChangeLogoOK          : false,
                alertKind               : ConstData.ALERT_KIND.NONE,
                alertMessage            : ""
            }
        }
    },

    [FIND_USER_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("FIND_USER_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { list_user } = action.payload;
            let clist_user = [];

            if (list_user && list_user.length > 0) {
                clist_user = list_user.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userID          : row.USER_ID,
                    userNickname    : row.USER_NICKNAME,
                    userProfile     : row.USER_PROFILE,
                    profileImgUrl   : row.USER_PROFILE_IMG
                }));
            }

            return {
                ...state,
                searchResult        : clist_user
            }
        } else {
            console.log("FIND_USER_SUCCESS - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [SELECT_SUB_DOMAIN_INFO]: (state, action) => {
        const { userKind, subDomainSeq } = action.payload;
        //console.log("SELECT_SUB_DOMAIN_INFO - ", action.payload);

        let select_sub_domain_info = {
            selectedIdx     : 0,
            subDomainSeq    : -1,
            subDomainName   : ""
        };

        if (userKind === "hiclasstv") {
            const subDomain_info = state.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
            if (subDomain_info) {
                select_sub_domain_info = {
                    selectedIdx         : state.list_configSubDomain.indexOf(subDomain_info),
                    subDomainSeq        : subDomain_info.subDomainSeq,
                    subDomainName       : subDomain_info.subDomainName
                };
            }
        } else {
            const subDomain_info = state.list_createSubDomain.find(info => info.subDomainSeq === subDomainSeq);
            if (subDomain_info) {
                select_sub_domain_info = {
                    selectedIdx         : state.list_createSubDomain.indexOf(subDomain_info),
                    subDomainSeq        : subDomain_info.subDomainSeq,
                    subDomainName       : subDomain_info.subDomainName
                };
            }
        }

        return {
            ...state,
            selectedSubDomainInfo       : select_sub_domain_info
        }
    },

    [SELECT_USER_INFO]: (state, action) => {
        const { subDomainSeq, selUserSeq } = action.payload;
        //console.log("SELECT_USER_INFO - ", action.payload);

        let select_user_info = {
            userSeq         : -1,
            userID          : "",
            userNickname    : ""
        };

        const subDomain_info = state.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
        if (subDomain_info) {
            if (subDomain_info.list_member) {
                const user_info = subDomain_info.list_member.find(info => info.userSeq === selUserSeq);
                if (user_info) {
                    select_user_info = {
                        userSeq         : user_info.userSeq,
                        userID          : user_info.userID,
                        userNickname    : user_info.userNickname
                    };
                }
            }
        }

        return {
            ...state,
            selectedUserInfo : select_user_info
        }
    },

    [ADD_SUB_DOMAIN_SUCCESS]: (state, action) => {
        const { subDomainName } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("ADD_SUB_DOMAIN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                const { isAdminUpdateSuccess, subDomainSEQ } = action.payload;

                if (isAdminUpdateSuccess) {
                    if (subDomainSEQ >= 0) {
                        const newSubDomain_info = {
                            subDomainSeq    : subDomainSEQ,
                            subDomainName   : subDomainName,
                            chatRoomCnt     : 0, 
                            liveCnt         : 0,
                            liveOnCnt       : 0
                        };

                        return {
                            ...state,
                            list_createSubDomain    : state.list_createSubDomain.concat(newSubDomain_info),
                            isAddSubDomainOK        : true,
                            isCheckSubDomainOK      : false,
                            alertKind               : ConstData.ALERT_KIND.SUCCESS,
                            alertMessage            : i18n.t("서브_도메인_등록_성공", { ns: "admin" })
                        }
                    } else {
                        console.log("ADD_SUB_DOMAIN - subDomainSEQ => ", subDomainSEQ);

                        return {
                            ...state,
                            isAddSubDomainOK        : false,
                            alertKind               : ConstData.ALERT_KIND.DANGER,
                            alertMessage            : i18n.t("서브_도메인_등록_실패", { ns: "admin" }) + " (Code.201)"
                        }
                    }
                } else {
                    console.log("ADD_SUB_DOMAIN - isAdminUpdateSuccess, subDomainSEQ => ", action.payload);

                    return {
                        ...state,
                        isAddSubDomainOK        : false,
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        alertMessage            : i18n.t("서브_도메인_관리자_지정_실패", { ns: "admin" }) + " (Code.203)"
                    }
                }
            } else {
                console.log("ADD_SUB_DOMAIN - isAddSuccess => ", isAddSuccess);

                return {
                    ...state,
                    isAddSubDomainOK        : false, 
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("서브_도메인_등록_실패", { ns: "admin" }) + " (Code.202)"
                }
            }
        } else {
            console.log("ADD_SUB_DOMAIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                isAddSubDomainOK    : false, 
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : i18n.t("서브_도메인_등록_요청_실패", { ns: "admin" }) + " (Code.200)"
            }
        }
    },

    [CHANGE_SUB_DOMAIN_LOGO_SUCCESS]: (state, action) => {
        //const { subDomainSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CHANGE_SUB_DOMAIN_LOGO - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUploadSuccess } = action.payload;

            if (isUploadSuccess) {
                return {
                    ...state,
                    isChangeLogoOK   : true,
                    alertKind        : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage     : i18n.t("서브_도메인_로고_변경_성공", { ns: "admin" })
                }
            } else {
                console.log("CHANGE_SUB_DOMAIN_LOGO - isUploadSuccess => ", isUploadSuccess);

                return {
                    ...state,
                    isChangeLogoOK   : false,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("서브_도메인_로고_변경_실패", { ns: "admin" }) + " (Code.212)"
                }
            }
        } else {
            console.log("CHANGE_SUB_DOMAIN_LOGO - isSuccessed => ", isSuccessed);

            return {
                ...state,
                isChangeLogoOK   : false,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("서브_도메인_로고_변경_요청_실패", { ns: "admin" }) + " (Code.213)"
            }
        }
    },

    [SELECT_SUB_DOMAIN_CONFIG_INFO]: (state, action) => {
        const { subDomain_info } = action.payload;
        //console.log("SELECT_SUB_DOMAIN_CONFIG_INFO - ", action.payload);

        if (subDomain_info) {
            return {
                ...state,
                selectedSubDomainInfo   : subDomain_info
            }
        } else {
            return {
                ...state
            }
        }
    },

    [FALSE_TO_CHANGE_LOGO]: (state, action) => {
        //console.log("FALSE_TO_CHANGE_LOGO - ");

        return {
            ...state,
            isChangeLogoOK : false
        }
    },

    // sub domain check
    [CHECK_SUB_DOMAIN_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("CHECK_SUB_DOMAIN_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isExist, isStandardType } = action.payload;

            if (isExist) {
                return {
                    ...state, 
                    isCheckedSubDomain  : true,
                    isCorrectSubDomain  : true,
                    isStandardType      : isStandardType
                }
            } else {
                //console.log("CHECK_SUB_DOMAIN - isExist => ", isExist);

                return {
                    ...state,
                    isCheckedSubDomain  : true,
                    isCorrectSubDomain  : false,
                    isStandardType      : isStandardType
                }
            }
        } else {
            //console.log("CHECK_SUB_DOMAIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                isCheckedSubDomain  : true,
                isCorrectSubDomain  : false
            }
        }
    },

    [CHECK_DUPLICATE_SUB_DOMAIN_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("CHECK_DUPLICATE_SUB_DOMAIN_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                return {
                    ...state,
                    isCheckSubDomainOK      : false, 
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("서브_도메인명_사용_불가능", { ns: "admin" })
                }
            } else {
                return {
                    ...state,
                    isCheckSubDomainOK      : true, 
                    alertKind               : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage            : i18n.t("서브_도메인명_사용_가능", { ns: "admin" })
                }
            }
        } else {
            console.log("CHECK_DUPLICATE_SUB_DOMAIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                isCheckSubDomainOK      : false, 
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("서브_도메인명_확인_요청_실패", { ns: "admin" }) + " (Code.250)"
            }
        }
    },

    [RESET_USER_LOGIN_STATUS_SUCCESS]: (state, action) => {
        const { mode, subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("RESET_USER_LOGIN_STATUS_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isResetSuccess } = action.payload;

            if (isResetSuccess) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        if (subDomain_info.list_member) {
                            const member_info = subDomain_info.list_member.find(info => info.userSeq === selectedUserSeq);
                            if (member_info) {
                                if (mode === "web") {
                                    member_info.webUserStatus = ConstData.LOGIN_STATUS.LOG_OUT;
                                } else if (mode === "hiclass") {
                                    member_info.userStatus = ConstData.LOGIN_STATUS.LOG_OUT;
                                } else {
                                    console.log("RESET_USER_LOGIN_STATUS - mode => ", mode);
                                }
                            }
                        }
                    }
                });
            } else {
                console.log("RESET_USER_LOGIN_STATUS - isResetSuccess => ", isResetSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("멤버_상태_초기화_실패", { ns: "admin" }) + " (Code.301)"
                }
            }
        } else {
            console.log("RESET_USER_LOGIN_STATUS - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("멤버_상태_초기화_요청_실패", { ns: "admin" }) + " (Code.300)"
            }
        }
    },
    
    [RESET_USER_PW_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("RESET_USER_PW_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isResetSuccess } = action.payload;

            if (isResetSuccess) {
                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage     : i18n.t("멤버_비밀번호_초기화_성공", { ns: "admin" })
                }
            } else {
                console.log("RESET_USER_PW_SUCCESS - isResetSuccess => ", isResetSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("멤버_비밀번호_초기화_실패", { ns: "admin" }) + " (Code.303)"
                }
            }
        } else {
            console.log("RESET_USER_PW_SUCCESS - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("멤버_비밀번호_초기화_요청_실패", { ns: "admin" }) + " (Code.302)"
            }
        }
    },

    [ADD_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS]: (state, action) => {
        const { subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("ADD_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        if (subDomain_info.list_member) {
                            const member_info = subDomain_info.list_member.find(info => info.userSeq === selectedUserSeq);
                            if (member_info) {
                                member_info.isConfigAdmin = true;
                                console.log(i18n.t("서브_도메인_관리자_지정_성공", { ns: "admin" }));
                            }
                        }
                    }
                });
            } else {
                console.log("ADD_SUB_DOMAIN_CONFIG_ADMIN - isAddSuccess => ", isAddSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("서브_도메인_관리자_지정_실패", { ns: "admin" }) + " (Code.204)"
                }
            }
        } else {
            console.log("ADD_SUB_DOMAIN_CONFIG_ADMIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("서브_도메인_관리자_지정_요청_실패", { ns: "admin" }) + " (Code.205)"
            }
        }
    },

    [REMOVE_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS]: (state, action) => {
        const { subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_SUB_DOMAIN_CONFIG_ADMIN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        if (subDomain_info.list_member) {
                            const member_info = subDomain_info.list_member.find(info => info.userSeq === selectedUserSeq);
                            if (member_info) {
                                member_info.isConfigAdmin = false;
                                console.log(i18n.t("서브_도메인_관리자_해제_성공", { ns: "admin" }));
                            }
                        }
                    }
                });
            } else {
                console.log("REMOVE_SUB_DOMAIN_CONFIG_ADMIN - isRemoveSuccess => ", isRemoveSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("서브_도메인_관리자_해제_실패", { ns: "admin" }) + " (Code.207)"
                }
            }
        } else {
            console.log("REMOVE_SUB_DOMAIN_CONFIG_ADMIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("서브_도메인_관리자_해제_요청_실패", { ns: "admin" }) + " (Code.206)"
            }
        }
    },

    [ADD_SUB_DOMAIN_CREATE_ADMIN_SUCCESS]: (state, action) => {
        const { subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("ADD_SUB_DOMAIN_CREATE_ADMIN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;

            if (isAddSuccess) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        if (subDomain_info.list_member) {
                            const member_info = subDomain_info.list_member.find(info => info.userSeq === selectedUserSeq);
                            if (member_info) {
                                member_info.isCreateAdmin = true;
                            }
                        }
                    }
                });
            } else {
                console.log("ADD_SUB_DOMAIN_CREATE_ADMIN - isAddSuccess => ", isAddSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("서브_도메인_추가_권한_부여_실패", { ns: "admin" }) + " (Code.208)"
                }
            }
        } else {
            console.log("ADD_SUB_DOMAIN_CREATE_ADMIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("서브_도메인_추가_권한_부여_요청_실패", { ns: "admin" }) + " (Code.209)"
            }
        }
    },

    [REMOVE_SUB_DOMAIN_CREATE_ADMIN_SUCCESS]: (state, action) => {
        const { subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_SUB_DOMAIN_CREATE_ADMIN_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        if (subDomain_info.list_member) {
                            const member_info = subDomain_info.list_member.find(info => info.userSeq === selectedUserSeq);
                            if (member_info) {
                                member_info.isCreateAdmin = false;
                            }
                        }
                    }
                });
            } else {
                console.log("REMOVE_SUB_DOMAIN_CREATE_ADMIN - isRemoveSuccess => ", isRemoveSuccess);

                return {
                    ...state,
                    alertKind        : ConstData.ALERT_KIND.DANGER,
                    alertMessage     : i18n.t("서브_도메인_추가_권한_해제_실패", { ns: "admin" }) + " (Code.210)"
                }
            }
        } else {
            console.log("REMOVE_SUB_DOMAIN_CREATE_ADMIN - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("서브_도메인_추가_권한_해제_요청_실패", { ns: "admin" }) + " (Code.211)"
            }
        }
    },

    [DUPLICATE_CHECK_USERS_SUCCESS]: (state, action) => {
        const { subDomainSeq, list_createUser } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("DUPLICATE_CHECK_USERS - ", action.payload);

        if (isSuccessed) {
            const { list_results } = action.payload;

            if (list_results) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        subDomain_info.list_createUser = list_results.map((row) => ({
                            ...row,
                            isIdCheckOK: true,
                            isJoinSuccess: false,
                            userSeq: -1
                        }));
                    }
                });
            } else {
                console.log("DUPLICATE_CHECK_USERS - 이런 경우가 있나...? list_results => ", list_results);

                return {
                    ...state
                }
            }
        } else {
            console.log("DUPLICATE_CHECK_USERS - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("사용자_ID_중복_확인_요청_실패", { ns: "admin" }) + " (Code.972)"
            }
        }
    },

    [CREATE_USERS_SUCCESS]: (state, action) => {
        const { subDomainSeq, list_createUser, requestDate } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CREATE_USERS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { list_results } = action.payload;

            if (list_results) {
                return produce (state, draft => {
                    const subDomain_info = draft.list_configSubDomain.find(info => info.subDomainSeq === subDomainSeq);
                    if (subDomain_info) {
                        subDomain_info.list_createUser = list_results;

                        let filteredList_user = subDomain_info.list_createUser.filter(info => info.isJoinSuccess);
                        if (filteredList_user && filteredList_user.length > 0) {
                            subDomain_info.list_member = subDomain_info.list_member.concat(
                                                            filteredList_user.map(row => ({
                                                                userSeq         : row.userSeq,
                                                                userID          : row.userID,
                                                                userNickname    : row.userNickname,
                                                                userProfile     : row.userProfile,
                                                                profileImgUrl   : "1",
                                                                userStatus      : ConstData.USER_STATUS.LOG_OUT,
                                                                webUserStatus   : ConstData.USER_STATUS.LOG_OUT,
                                                                userLoginDT     : requestDate,
                                                                userSignUpDT    : requestDate,
                                                                isConfigAdmin   : false,
                                                                isCreateAdmin   : false,
                                                         })));
                        }
                    }
                });
            } else {
                console.log("CREATE_USERS - 이런 경우가 있나...? list_results => ", list_results);

                return {
                    ...state
                }
            }
        } else {
            console.log("CREATE_USERS - isSuccessed => ", isSuccessed);

            return {
                ...state,
                alertKind        : ConstData.ALERT_KIND.DANGER,
                alertMessage     : i18n.t("사용자_ID_생성_요청_실패", { ns: "admin" }) + " (Code.990)"
            }
        }
    },

    [CHANGE_ALERT_MESSAGE]: (state, action) => {
        const { kind, message } = action;
        //console.log("CHANGE_ALERT_MESSAGE - ", kind, message);

        if (kind === "subDomain-check") {
            return {
                ...state,
                isCheckSubDomainOK      : false,
                alertKind               : message === "" ? ConstData.ALERT_KIND.NONE : ConstData.ALERT_KIND.DANGER,
                alertMessage            : message
            }
        } else if (kind === "subDomain-add") {
            return {
                ...state,
                isAddSubDomainOK        : false,
                alertKind               : message === "" ? ConstData.ALERT_KIND.NONE : ConstData.ALERT_KIND.DANGER,
                alertMessage            : message
            }
        } else {
            console.log(`CHANGE_ALERT_MESSAGE - kind[${kind}], message[${message}]`);

            return {
                ...state
            }
        }
    },
    
    [SET_ALERT_MESSAGE]: (state, action) => {
        //console.log("SET_ALERT_MESSAGE - ", action.message);

        if (action.message !== "") {
            return {
                ...state,
                alertMessage        : action.message,
                alertKind           : ConstData.ALERT_KIND.DANGER
            }
        } else {
            return {
                ...state,
                alertMessage        : "",
                alertKind           : ConstData.ALERT_KIND.NONE
            }
        }
    },

    [SET_ALERT_KIND_MESSAGE]: (state, action) => {
        //console.log("SET_ALERT_KIND_MESSAGE - ", action.message);

        if (action.message !== "") {
            return {
                ...state,
                alertMessage        : action.message,
                alertKind           : ConstData.ALERT_KIND.DANGER
            }
        } else {
            return {
                ...state,
                alertMessage        : "",
                alertKind           : ConstData.ALERT_KIND.NONE
            }
        }
    },
    
    /**********************************************************/
    /////////////////////// LOCAL SERVER ///////////////////////
    /**********************************************************/

    [GET_SUB_DOMAIN_LOCAL_SERVER_INFO_SUCCESS]: (state, action) => {
        //const { subDomainSeq } = action.parms; 
        const { isSuccessed } = action.payload;
        console.log("GET_SUB_DOMAIN_LOCAL_SERVER_INFO - ", action.payload, action.parms);

        if (isSuccessed) {
            const { list_localServer, list_localSite } = action.payload;

            let clist_configLocalServer = [];
            let clist_localSite = [];

            if (list_localServer && list_localServer.length > 0) {
                clist_configLocalServer = list_localServer.map(row => ({
                    localSiteSeq        : row.LOCAL_SITE_SEQ,
                    localSiteName       : row.LOCAL_SITE_NAME,
                    localServerSeq      : row.LOCAL_SERVER_SEQ,
                    localServerName     : row.LOCAL_SERVER_NM,
                    ipAddress           : row.IP_ADDRESS,
                    serverStatus        : row.LOGIN_YN,
                    isPortOpened        : row.PORT_OPEN_YN === "Y" ? true : false,
                    authCode            : row.AUTH_CD,
                    portNumber          : row.PORT_NUM,
                    historyMemo         : row.HISTORY_MEMO
                }));

                if (list_localSite && list_localSite.length > 0) {
                    clist_localSite = list_localSite.map(row => ({
                        localSiteSeq    : row.LOCAL_SITE_SEQ,
                        localSiteName   : row.LOCAL_SITE_NAME
                    }));
                }
            }

            return {
                ...state,
                list_configLocalServer  : clist_configLocalServer,
                list_localSite          : clist_localSite
            }
        } else {
            console.log("GET_SUB_DOMAIN_LOCAL_SERVER_INFO - ", action.payload);

            return {
                ...state,
                list_configLocalServer : []
            }
        }
    },

    [GET_USER_LOCAL_SERVER_INFO_SUCCESS]: (state, action) => {
        //const { subDomainSeq, selectedUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("GET_USER_LOCAL_SERVER_INFO - ", action.payload, action.parms);

        if (isSuccessed) {
            const { list_localServer } = action.payload;

            let clist_configLocalServer = [];

            if (list_localServer && list_localServer.length > 0) {
                clist_configLocalServer = list_localServer.map(row => ({
                    localSiteSeq        : row.LOCAL_SITE_SEQ,
                    localSiteName       : row.LOCAL_SITE_NAME,
                    localServerSeq      : row.LOCAL_SERVER_SEQ,
                    localServerName     : row.LOCAL_SERVER_NM,
                    ipAddress           : row.IP_ADDRESS,
                    authCode            : row.AUTH_CD,
                    serverStatus        : row.LOGIN_YN,
                    isPortOpened        : row.PORT_OPEN_YN === "Y" ? true : false,
                    portNumber          : row.PORT_NUM,
                    historyMemo         : row.HISTORY_MEMO
                }));
            }

            return {
                ...state,
                list_configLocalServer  : clist_configLocalServer
            }
        } else {
            console.log("GET_USER_LOCAL_SERVER_INFO - ", action.payload);

            return {
                ...state,
                list_configLocalServer : []
            }
        }
    },

    [CREATE_LOCAL_SERVER_SUCCESS]: (state, action) => {
        const { serverName } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CREATE_LOCAL_SERVER - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isCreateSuccess } = action.payload;

            if (isCreateSuccess) {
                const { serverSEQ, authCode } = action.payload;
                const { localSiteSeq, localSiteName } = action.parms;

                const newServer_info = {
                    localSiteSeq,
                    localSiteName,
                    localServerSeq      : serverSEQ,
                    localServerName     : serverName,
                    ipAddress           : null,
                    authCode,
                    serverStatus        : "N",
                    isPortOpened        : false,
                    portNumber          : -1,
                    historyMemo         : null
                };

                return {
                    ...state,
                    list_configLocalServer  : state.list_configLocalServer.concat(newServer_info),
                    alertKind               : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage            : i18n.t("로컬_서버_등록_성공", { ns: "admin" })
                }
            } else {
                console.log("CREATE_LOCAL_SERVER_SUCCESS - isCreateSuccess => ", isCreateSuccess);

                return {
                    ...state,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("로컬_서버_등록_실패", { ns: "admin" }) + " (Code.AL202)"
                }
            }
        } else {
            console.log("CREATE_LOCAL_SERVER_SUCCESS - ", action.payload);

            return {
                ...state,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("로컬_서버_등록_요청_실패", { ns: "admin" }) + " (Code.AL201)"
            }
        }
    },

    [REMOVE_LOCAL_SERVER_SUCCESS]: (state, action) => {
        const { localServerSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_LOCAL_SERVER_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isDeleteSuccess } = action.payload;

            if (isDeleteSuccess) {
                return {
                    ...state,
                    list_configLocalServer  : state.list_configLocalServer.filter(info => info.localServerSeq !== localServerSeq),
                    alertKind               : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage            : i18n.t("로컬_서버_삭제_성공", { ns: "admin" })
                }
            } else {
                console.log("REMOVE_LOCAL_SERVER_SUCCESS - isDeleteSuccess => ", isDeleteSuccess);

                return {
                    ...state,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("로컬_서버_삭제_실패", { ns: "admin" }) + " (Code.AL204)"
                }
            }
        } else {
            console.log("REMOVE_LOCAL_SERVER_SUCCESS - ", action.payload);

            return {
                ...state,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("로컬_서버_삭제_요청_실패", { ns: "admin" }) + " (Code.AL203)"
            }
        }
    },

    [CHANGE_LOCAL_SITE_INFO_SUCCESS]: (state, action) => {
        //console.log("CHANGE_LOCAL_SITE_INFO_SUCCESS - ", action.payload, action.parms);
        const { localServerSeq, localSiteSeq, localSiteName } = action.parms;

        const { isSuccessed } = action.payload;
        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce (state, draft => {
                    const localServer_info = draft.list_configLocalServer.find(info => info.localServerSeq === localServerSeq);
                    if (localServer_info) {
                        localServer_info.localSiteSeq = localSiteSeq;
                        localServer_info.localSiteName = localSiteName;
                    }
                });
            } else {
                console.log("CHANGE_LOCAL_SITE_INFO_SUCCESS - isUpdateSuccess => ", isUpdateSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("CHANGE_LOCAL_SITE_INFO_SUCCESS - ", action.payload);
            
            return {
                ...state
            }
        }
    },

    [CHANGE_LOCAL_SERVER_PORT_INFO_SUCCESS]: (state, action) => {
        const { localServerSeq, status } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CHANGE_LOCAL_SERVER_PORT_INFO - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce (state, draft => {
                    const local_server_info = draft.list_configLocalServer.find(info => info.localServerSeq === localServerSeq);
                    if (local_server_info) {
                        local_server_info.isPortOpened = status;

                        if (status) {
                            const { portNumber, historyMemo } = action.parms;
                            const { historySEQ } = action.payload;

                            local_server_info.portNumber = portNumber;
                            local_server_info.historySeq = historySEQ;
                            local_server_info.historyMemo = historyMemo;
                        } else {
                            local_server_info.portNumber = -1;
                            local_server_info.historySeq = -1;
                            local_server_info.historyMemo = null;
                        }
                    }
                });
            } else {
                console.log("CHANGE_LOCAL_SERVER_PORT_INFO - isUpdateSuccess => ", isUpdateSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("CHANGE_LOCAL_SERVER_PORT_INFO - ", action.payload);

            return {
                ...state
            }
        }
    },

    [CLASS_OPEN_ON_LOCAL_SITE_SUCCESS]: (state, action) => {
        //console.log("CLASS_OPEN_ON_LOCAL_SITE_SUCCESS - ", action.payload);

        const { isSuccessed } = action.payload;
        const { localSiteSeq, openLocalSiteSeq } = action.parms;

        if (isSuccessed) {
            const { isAddSuccess } = action.payload;
            if (isAddSuccess) {
                return produce (state, draft => {
                    const local_site_info = draft.list_configLocalSite.find(info => info.localSiteSeq === localSiteSeq);
                    
                    if (local_site_info) {
                        let open_site_info = local_site_info.list_openableLocalSite.find(info => info.localSiteSeq === openLocalSiteSeq);
                        
                        if (open_site_info) {
                            local_site_info.list_openLocalSite.push(open_site_info);
                            local_site_info.list_openableLocalSite = local_site_info.list_openableLocalSite.filter(info => info.localSiteSeq !== openLocalSiteSeq);
                        }
                    }
                });
            } else {
                console.log("CLASS_OPEN_ON_LOCAL_SITE_SUCCESS - isAddSuccess => ", isAddSuccess);
                return {
                    ...state
                }
            }
        } else {
            console.log("CLASS_OPEN_ON_LOCAL_SITE_SUCCESS - isSuccessed => ", isSuccessed);
            return {
                ...state
            }
        }
    },

    [CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS]: (state, action) => {
        //console.log("CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS - ", action.payload);

        const { isSuccessed } = action.payload;
        const { localSiteSeq, openLocalSiteSeq } = action.parms;

        if (isSuccessed) {
            const { isDeleteSuccess } = action.payload;
            if (isDeleteSuccess) {
                return produce (state, draft => {
                    const local_site_info = draft.list_configLocalSite.find(info => info.localSiteSeq === localSiteSeq);
                    
                    if (local_site_info) {
                        let open_site_info = local_site_info.list_openLocalSite.find(info => info.localSiteSeq === openLocalSiteSeq);
                        
                        if (open_site_info) {
                            local_site_info.list_openableLocalSite.push(open_site_info);
                            local_site_info.list_openLocalSite = local_site_info.list_openLocalSite.filter(info => info.localSiteSeq !== openLocalSiteSeq);
                        }
                    }
                });
            } else {
                console.log("CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS - isDeleteSuccess => ", isDeleteSuccess);
                return {
                    ...state
                }
            }
        } else {
            console.log("CLASS_CLOSE_ON_LOCAL_SITE_SUCCESS - isSuccessed => ", isSuccessed);
            return {
                ...state
            }
        }
    },

    [GET_LOCAL_SITE_LIST_SUCCESS] : (state, action) => {
        //console.log("GET_LOCAL_SITE_LIST_SUCCESS - ", action.payload);
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { list_localSite } = action.payload;

            let clist_configLocalSite = [];

            if (list_localSite && list_localSite.length > 0) {
                clist_configLocalSite = list_localSite.map(row => ({
                    localSiteSeq            : row.LOCAL_SITE_SEQ,
                    localSiteName           : row.LOCAL_SITE_NAME,
                    list_openLocalSite      : row.list_openLocalSite === undefined || row.list_openLocalSite === null ?
                                                [] : row.list_openLocalSite.map(siteRow => ({
                                                    localSiteSeq    : siteRow.LOCAL_SITE_SEQ,
                                                    localSiteName   : siteRow.LOCAL_SITE_NAME
                                                })),
                    list_openableLocalSite  : row.list_openableLocalSite === undefined || row.list_openableLocalSite === null ?
                                                [] : row.list_openableLocalSite.map(siteRow => ({
                                                    localSiteSeq    : siteRow.LOCAL_SITE_SEQ,
                                                    localSiteName   : siteRow.LOCAL_SITE_NAME
                                                }))
                }));
            }

            return {
                ...state,
                list_configLocalSite    : clist_configLocalSite,
                alertMessage            : "",
                alertKind               : ConstData.ALERT_KIND.NONE
            }
        } else {
            console.log("GET_LOCAL_SITE_LIST_SUCCESS - ", action.payload);

            return {
                ...state,
                list_configLocalSite    : [],
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("로컬_사이트_목록_불러오기_요청_실패", { ns: "admin" }) + " (Code.AL201)"
            }

        }
    },

    [CREATE_LOCAL_SITE_SUCCESS]: (state, action) => {
        //console.log("CREATE_LOCAL_SITE_SUCCESS - ", action.payload, action.parms);
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isCreateSuccess } = action.payload;

            if (isCreateSuccess) {
                const { localSiteSEQ } = action.payload;
                const { localSiteName } = action.parms;

                if (localSiteSEQ && localSiteSEQ > 0) {
                    return produce (state, draft => {
                        draft.list_configLocalSite = draft.list_configLocalSite.map(info => ({
                            ...info,
                            list_openableLocalSite: info.list_openableLocalSite.concat({
                                localSiteSeq: localSiteSEQ,
                                localSiteName
                            })
                        }));

                        let new_local_site = {
                            localSiteSeq: localSiteSEQ,
                            localSiteName,
                            list_openLocalSite: [],
                            list_openableLocalSite: state.list_configLocalSite.map(info => ({
                                localSiteSeq: info.localSiteSeq,
                                localSiteName: info.localSiteName
                            }))
                        };

                        draft.list_configLocalSite.push(new_local_site);
                    });
                }
            } else {
                console.log("CREATE_LOCAL_SITE_SUCCESS - isCreateSuccess is ", action.payload);
                
                return {
                    ...state,
                }
            }
        }else {
            console.log("CREATE_LOCAL_SITE_SUCCESS - isSuccessed is ", action.payload);
            
            return {
                ...state,
            }
        }
    },

    [DELETE_LOCAL_SITE_SUCCESS]: (state, action) => {
        //console.log("DELETE_LOCAL_SITE_SUCCESS - ", action.payload, action.parms);
        const { isSuccessed } = action.payload;
        
        if (isSuccessed) {
            const { isDeleteSuccess } = action.payload;

            if (isDeleteSuccess) {
                const { localSiteSeq } = action.parms;

                return produce (state, draft => {
                    draft.list_configLocalSite = draft.list_configLocalSite.filter(info => info.localSiteSeq !== localSiteSeq);
                    draft.list_configLocalSite.forEach(info => {
                        info.list_openLocalSite = info.list_openLocalSite.filter(site => site.localSiteSeq !== localSiteSeq);
                        info.list_openableLocalSite = info.list_openableLocalSite.filter(site => site.localSiteSeq !== localSiteSeq);
                    });
                })
            } else {
                console.log("DELETE_LOCAL_SITE_SUCCESS - isDeleteSuccess is ", action.payload);
                
                return {
                    ...state,
                }
            }
        } else {
            console.log("DELETE_LOCAL_SITE_SUCCESS - isSuccessed is ", action.payload);
            
            return {
                ...state,
            }
        }
    },

    [GET_LITE_SERVER_LIST_SUCCESS]: (state, action) => {
        console.log("GET_LITE_SERVER_LIST_SUCCESS - ", action.payload);
        const { isSuccessed } = action.payload;
        
        if (isSuccessed) {
            const { list_liteServer } = action.payload;

            let clist_configLiteServer = [];

            if (list_liteServer && list_liteServer.length > 0) {
                clist_configLiteServer = list_liteServer.map(row => ({
                    liteLocalSiteSeq    : row.LITE_LCL_SITE_SEQ === null ? -1 : row.LITE_LCL_SITE_SEQ,
                    liteLocalSiteName   : row.LITE_LCL_SITE_NAME === null ? "-" : row.LITE_LCL_SITE_NAME,
                    liteServerSeq       : row.LITE_LCL_SVR_SEQ,
                    liteServerName      : row.LITE_LCL_SVR_NM === null ? "-" : row.LITE_LCL_SVR_NM,
                    liteServerMemo      : row.LITE_LCL_SVR_MEMO,
                    ipAddress           : row.IP_ADDRESS === null ? "-" : row.IP_ADDRESS,
                    authCode            : row.AUTH_CD,
                    initStatus          : row.INIT_YN,
                    publicStatus        : row.PUBLIC_YN
                }));
            } 

            return {
                ...state,
                list_configLiteServer: clist_configLiteServer
            }
        } else {
            console.log("GET_LITE_SERVER_LIST_SUCCESS - ", action.payload);
            
            return {
                ...state,
                list_configLiteServer: []
            }
        }
    },
    
    [CREATE_LITE_SERVER_SUCCESS]: (state, action) => {
        const { liteServerMemo } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CREATE_LITE_SERVER_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isCreateSuccess } = action.payload;
            
            if (isCreateSuccess) {
                const { liteServerSEQ, authCode } = action.payload;
                
                const newServer_info = {
                    liteLocalSiteSeq    : -1,
                    liteLocalSiteName   : "-",
                    liteServerSeq       : liteServerSEQ,
                    liteServerName      : "-",
                    liteServerMemo      : liteServerMemo,
                    ipAddress           : null,
                    authCode            : authCode,
                    initStatus          : "N",
                    publicStatus        : "N"
                };

                console.log("newServer_info - ", newServer_info);

                return {
                    ...state,
                    list_configLiteServer : state.list_configLiteServer.concat(newServer_info),
                    alertKind           : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage        : i18n.t("라이트_서버_등록_성공", { ns: "admin" })
                }
            } else {
                console.log("CREATE_LITE_SERVER_SUCCESS - isCreateSuccess => ", isCreateSuccess);
                
                return {
                    ...state,
                    alertKind           : ConstData.ALERT_KIND.DANGER,
                    alertMessage        : i18n.t("라이트_서버_등록_실패", { ns: "admin" }) + " (Code.ALL202)"
                }
            }
        } else {
            console.log("CREATE_LITE_SERVER_SUCCESS - isSuccessed is false");
            return {
                ...state,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("라이트_서버_등록_요청_실패", { ns: "admin" }) + " (Code.ALL201)"
            }
        }
    },

    [REMOVE_LITE_SERVER_SUCCESS]: (state, action) => {
        const { authCode } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isDeleteSuccess } = action.payload;

            if (isDeleteSuccess) {
                return {
                    ...state,
                    list_configLiteServer   : state.list_configLiteServer.filter(info => info.authCode !== authCode),
                    alertKind               : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage            : i18n.t("라이트_서버_삭제_성공", { ns: "admin" })
                }
            } else {
                console.log("REMOVE_LITE_SERVER_SUCCESS - isDeleteSuccess => ", isDeleteSuccess);

                return {
                    ...state,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("라이트_서버_삭제_실패", { ns: "admin" }) + " (Code.AL204)"
                }
            }
        } else {
            console.log("REMOVE_LITE_SERVER_SUCCESS - ", action.payload);

            return {
                ...state,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : i18n.t("라이트_서버_삭제_요청_실패", { ns: "admin" }) + " (Code.AL203)"
            }
        }
    },

    [CHANGE_LITE_SERVER_INFO_SUCCESS]: (state, action) => {        
        const { liteServerSeq, kind, liteServerMemo, initStatus } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CHANGE_LITE_SERVER_INFO_SUCCESS - ", kind, action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                if (kind === "modify") {
                    return produce (state, draft => {
                        const lite_server_info = draft.list_configLiteServer.find(info => info.liteServerSeq === liteServerSeq);
    
                        if (lite_server_info) {
                            lite_server_info.liteServerMemo = liteServerMemo;
                        } 
                    });
                } else if (kind === "init") {
                    return produce (state, draft => {
                        const lite_server_info = draft.list_configLiteServer.find(info => info.liteServerSeq === liteServerSeq);
    
                        if (lite_server_info) {
                            lite_server_info.initStatus = initStatus;
                        }
                    });
                } else {
                    return {
                        ...state
                    }
                }
            } else {
                console.log("CHANGE_LITE_SERVER_INFO_SUCCESS - isUpdateSuccess => ", isUpdateSuccess);
                
                return {
                    ...state
                }
            }
        } else {
            console.log("CHANGE_LITE_SERVER_INFO_SUCCESS - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_MEDIA_SERVER_INFO]: (state, action) => {
        const { roomInfo, liveInfo } = action.payload;
        console.log("MQ_MEDIA_SERVER_INFO - ", action.payload);
        /**
         * roomInfo : [{
         *      liveSeq,
         *      workerSeq,
         *      producer : {
         *          userSeq,
         *          isVideoSend, 
         *          isAudioSend, 
         *          isMicSend 
         *      },
         *      subRoomInfo : [
         *          {
         *              roomCode,
         *              isStarted, 
         *              comsumers : [
         *                  { userSeq, isVideoRcv, isAudioRcv, isMicRcv }
         *              ]
         *          }
         *      ]
         * }]
         * 
         * liveInfo : [{
         *      liveSeq,
         *      workerSeq,
         *      receiverSeq,
         *      sender : [
         *          {
         *              userSeq,
         *              producer : { isVideoSend, isAudioSend },
         *              receiver : { isVideoRcv, isAudioRcv },
         *              micReceiver : [
         *                  { userSeq, isVideoRcv, isAudioRcv }
         *              ]
         *          }
         *      ]
         * }]
         */

        let room_info = [];
        let live_info = [];

        if (roomInfo && roomInfo.length > 0) {
            room_info = roomInfo.map(room => ({
                liveSeq         : room.liveSeq,
                workerSeq       : room.workerSeq,
                producer        : room.producer === undefined || room.producer === null ?
                                    {
                                        userSeq     : -1,
                                        isVideoSend : false,
                                        isAudioSend : false,
                                        isMicSend   : false
                                    } :
                                    {
                                        userSeq     : room.producer.userSeq,
                                        isVideoSend : room.producer.isVideoSend,
                                        isAudioSend : room.producer.isAudioSend,
                                        isMicSend   : room.producer.isMicSend
                                    },
                subRoom_info    : room.subRoomInfo === undefined || room.subRoomInfo === null ? 
                                    [] :
                                    room.subRoomInfo.map(subRoom => ({
                                        roomCode    : subRoom.roomCode,
                                        isStarted   : subRoom.isStarted,
                                        consumers   : subRoom.comsumers === undefined || subRoom.comsumers === null ?
                                                        [] :
                                                        subRoom.comsumers.sort((a, b) => {
                                                            return a.userSeq - b.userSeq;
                                                        }).map(user => ({
                                                            userSeq     : user.userSeq,
                                                            isVideoRcv  : user.isVideoRcv,
                                                            isAudioRcv  : user.isAudioRcv,
                                                            isMicRcv    : user.isMicRcv
                                                        }))
                                    }))
            }));

            if (room_info && room_info.length > 0) {
                /* room_info.forEach(room => {
                    if (room.subRoom_info && room.subRoom_info.length > 0) {
                        room.subRoom_info.forEach(subRoom => {
                            if (subRoom.consumers && subRoom.consumers.length > 0) {
                                subRoom.consumers.sort((a, b) => {
                                    return a.userSeq - b.userSeq;
                                });
                            }
                        });
                    }
                }); */
            }
        }

        if (liveInfo && liveInfo.length > 0) {
            live_info = liveInfo.map(live => ({
                liveSeq         : live.liveSeq,
                workerSeq       : live.workerSeq,
                receiverSeq     : live.receiverSeq,
                sender          : live.sender === undefined || live.sender === null ?
                                    [] :
                                    live.sender.sort((a, b) => {
                                        return (a.producer && b.producer) ? a.producer.userSeq - b.producer.userSeq : 1;
                                    }).map(sender => ({
                                        userSeq     : sender.userSeq,
                                        producer    : sender.producer === undefined || sender.producer === null ? 
                                                        {
                                                            isVideoSend : false,
                                                            isAudioSend : false
                                                        } : 
                                                        {
                                                            userSeq     : sender.producer.userSeq,
                                                            isVideoSend : sender.producer.isVideoSend,
                                                            isAudioSend : sender.producer.isAudioSend
                                                        },
                                        /* receiver    : sender.receiver === undefined || sender.receiver === null ? 
                                                        {
                                                            userSeq     : live.receiverSeq,
                                                            isVideoRcv  : false,
                                                            isAudioRcv  : false
                                                        } :
                                                        {
                                                            userSeq     : sender.receiver.userSeq,
                                                            isVideoRcv  : sender.receiver.isVideoRcv,
                                                            isAudioRcv  : sender.receiver.isAudioRcv
                                                        }, */
                                        /* micReceiver : sender.micReceiver === undefined || sender.micReceiver === null ? 
                                                        [] :
                                                        sender.micReceiver.sort((a, b) => {
                                                            return a.userSeq - b.userSeq;
                                                        }).map(receiver => ({
                                                            userSeq     : receiver.userSeq,
                                                            isVideoRcv  : receiver.isVideoRcv,
                                                            isAudioRcv  : receiver.isAudioRcv
                                                        })), */
                                        receiver    :   [{
                                                            userSeq         : sender.receiver ? sender.receiver.userSeq : live.receiverSeq,
                                                            isVideoRcv      : sender.receiver ? sender.receiver.isVideoRcv : false,
                                                            isAudioRcv      : sender.receiver ? sender.receiver.isAudioRcv : false,
                                                            isRoomReceiver  : true,
                                                        }].concat(sender.micReceiver === undefined || sender.micReceiver === null ? 
                                                        [] : 
                                                        sender.micReceiver.sort((a, b) => {
                                                            return a.userSeq - b.userSeq;
                                                        }).map(receiver => ({
                                                            userSeq         : receiver.userSeq,
                                                            isVideoRcv      : receiver.isVideoRcv,
                                                            isAudioRcv      : receiver.isAudioRcv,
                                                            isRoomReceiver  : false,
                                                        })))
                                    }))
            }));

            if (live_info && live_info.length > 0) {
                /* live_info.forEach(live => {
                    if (live.sender && live.sender.length > 0) {
                        live.sender.sort((a, b) => {
                            return a.userSeq - b.userSeq;
                        });
                    }
                }); */
            }
        }

        /* if (roomInfo !== undefined && roomInfo !== null) {
            room_info = roomInfo.map(room => ({
                liveSeq         : room.liveSeq,
                workerSeq       : room.workerSeq,
                producer        : room.producer !== undefined && room.producer !== null ? 
                                    { userSeq         : room.producer.userSeq, 
                                    isVideoSend     : room.producer.isVideoSend, 
                                    isAudioSend     : room.producer.isAudioSend } : null,
                subRoom_info    : room.subRoomInfo !== undefined && room.subRoomInfo !== null ? 
                                    room.subRoomInfo.map(subRoom => ({
                                        roomCode      : subRoom.roomCode,
                                        isStarted     : subRoom.isStarted,
                                        consumers     : subRoom.comsumers !== undefined && subRoom.comsumers !== null ? 
                                                        subRoom.comsumers.map(user => ({
                                                            userSeq       : user.userSeq,
                                                            isVideoRcv    : user.isVideoRcv,
                                                            isAudioRcv    : user.isAudioRcv,
                                                            isMicRcv      : user.isMicRcv
                                                        })) : []
                                    })) : []
            }));
        } */
    
        /* if (liveInfo !== undefined && liveInfo !== null) {
            live_info = liveInfo.map(live => ({
                liveSeq         : live.liveSeq,
                receiverSeq     : live.receiverSeq,
                workerSeq       : live.workerSeq,
                sender          : live.sender !== undefined && live.sender !== null ?
                                    live.sender.map(info => ({
                                        userSeq       : info.userSeq,
                                        producer      : info.producer !== undefined && info.producer !== null ? 
                                                        { isVideoSend     : info.producer.isVideoSend,
                                                        isAudioSend     : info.producer.isAudioSend } : null,
                                        receiver      : info.receiver !== undefined && info.receiver !== null ?
                                                        { isVideoRcv      : info.receiver.isVideoRcv,
                                                        isAudioRcv      : info.receiver.isAudioRcv } : null
                                    })) : [],
            }));
        } */

        return {
            ...state,
            media_info  : { room_info, live_info }
        }
    },
}, initialState);

export default admin;
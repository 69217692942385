import React from "react";

import { ctMsgType } from "../../../lib/ConstCommand";
import { ctRcvKind } from "../../../lib/classTalk/CodeEnum";

import FileShareMessage from "./FileShareMessage";
import AudioMemoMessage from "./AudioMemoMessage";
import InkMemoMessage from "./InkMemoMessage";
import ThumbnailMessage from "./ThumbnailMessage";
import CardTemplate from "../../lecture/CardTemplate";
import TextMessage from "./TextMessage";
import ClassTalkProfileImage from "../../user/profile/ClassTalkProfileImage";

const RcvMessage = ({ indexKey, msgInfo, handleSelectUrl, handleSelectedFunc, handleClassTalkView }) => {
    switch (msgInfo.kind) {
        /* case ctMsgType.FileShare:
            return <FileShareMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.AudioMemo:
            return <AudioMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.InkMemo:
            return <ThumbnailMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />; */

        case ctMsgType.PageJpeg:
            return <ThumbnailMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} handleClassTalkView={handleClassTalkView} />;

        case ctMsgType.CardTemplate:
            return <CardTemplate cardInfo={msgInfo.cardInfo} handleSelectedFunc={handleSelectedFunc} handleClassTalkView={handleClassTalkView} />;

        case ctMsgType.Quiz:
            return <CardTemplate cardInfo={msgInfo.cardInfo} handleSelectedFunc={handleSelectedFunc} handleClassTalkView={handleClassTalkView} />;

        case ctMsgType.AudioMemo:
        case ctMsgType.FileShare:
        case ctMsgType.InkMemo:
            return (
                <>
                    {
                        msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                        <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
                    }
                    <RcvClassTalkMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
                </>
            );

        default:
            return <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;
    }
}

const RcvClassTalkMessage = ({ indexKey, msgInfo, handleSelectUrl, handleClassTalkView }) => {
    /* return (
        <>
        {
            msgInfo.text !== undefined && msgInfo.text !== null && msgInfo.text !== "" &&
                <TextMessage kind={msgInfo.kind} indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />
        }
        </>
    ); */

    switch (msgInfo.kind) {
        case ctMsgType.AudioMemo:
            return <AudioMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} handleClassTalkView={handleClassTalkView} />;

        case ctMsgType.FileShare:
            return <FileShareMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} />;

        case ctMsgType.InkMemo:
            return <InkMemoMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} handleClassTalkView={handleClassTalkView} />;

        default:
            return <></>;
    }
}

const RcvProfileImg = ({ msgInfo }) => {
    switch (msgInfo.rcvKind) {
        case ctRcvKind.rcvPentalk:
        case ctRcvKind.Card:
            return <img className="ct-profile-img watson" src="/image/watson/watson_avatar.png" alt="" />;

        case ctRcvKind.infoPentalk:
            return <img className="ct-profile-img" src="/images/hiclasstvIcon.png" alt="" />;

        default:
            return <ClassTalkProfileImage url={msgInfo.iconSeq} alt={msgInfo.rcvName} />;
    }
}

const ReceiveMessage = ({ indexKey, msgInfo, handleSelectUrl, handleSelectedClassTalkFunc }) => {

    const handleClassTalkView = () => {
        let classTalkMsg = document.getElementById("classTalkView");
        if (classTalkMsg) {
            const callback = () => {
                if (classTalkMsg) {
                    classTalkMsg.scrollTop = classTalkMsg.scrollHeight;
                }

                console.log(`(7) ImageItem - classTalkEL.scrollTop[${classTalkMsg.scrollTop}]`);
            };

            //const observer = new MutationObserver(callback);
            const observer = new ResizeObserver(callback);

            // const config = {
            //     attributes: true,
            //     childList: true,
            //     subtree: true,
            // };
            const config = {
                contentRect: true,
                target: true,
                contentBoxSize: true
            }

            if (classTalkMsg) {
                observer.observe(classTalkMsg, config);
                console.log(`(8) ImageItem - classTalkEL.scrollTop[${classTalkMsg.scrollTop}]`);
            }

            /* let mutations = observer.takeRecords();
            if (mutations.length > 0) {
                console.log("mutations.length => ", mutations.length);
                callback(mutations);
            } */
            // const { scrollHeight, scrollTop, clientHeight } = classTalkMsg;
            // console.log(`scrollHeight[${scrollHeight}], scrollTop[${scrollTop}], clientHeight[${clientHeight}], `);
            // console.log("classTalkEL.scrollTop => ", classTalkMsg.scrollTop);

            return () => {
                observer.disconnect();
            };
        }
    }

    return (
        <div className="d-flex w-100" style={{ padding: '2px' }}>
            <RcvProfileImg msgInfo={msgInfo} />
            <div className="d-flex flex-column w-100" style={{ padding: '2px' }}>
                <span className="ct-profile-nickname">{msgInfo.rcvName}</span>
                <RcvMessage indexKey={indexKey} msgInfo={msgInfo} handleSelectUrl={handleSelectUrl} handleSelectedFunc={handleSelectedClassTalkFunc} handleClassTalkView={handleClassTalkView} />
                {
                    msgInfo.emoticSeq > 0 && msgInfo.emoticSeq < 64 &&
                    // <img className="hiclasstv-classtalk-emo" src={require(`../images/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`)} alt="" />
                    <img className="hiclasstv-classtalk-emo" src={`/images/classtalk/emoticon/et${msgInfo.emoticSeq.toString().padStart(2, "0")}.png`} alt="" onLoad={handleClassTalkView} />
                }
            </div>
        </div>
    );
}

export default ReceiveMessage;
import React from "react";

import MainContainer from "../containers/MainContainer";
import WebContentTemplate from "../components/template/WebContentTemplate";
import ChatListContainer from "../containers/ChatListContainer";

const WebManageFriendPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (        
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <ChatListContainer
                    browserType={browserType}
                    routeProps={routeProps}
                    currentPageType={currentPageType}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebManageFriendPage;
import React from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ExitChatRoom = ({ selectedChatRoomInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                {t("팀업_알림_메시지_12", { ns: "chat" })}<br />
                {t("팀업_알림_메시지_13", { ns: "chat" })}
            </div>
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1" title={t("팀업_이름", { ns: "chat" })}>
                    [{t("팀업_이름", { ns: "chat" })}]
                </span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                        <b>{selectedChatRoomInfo.chatRoomName}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" title={t("예", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "EXIT", chatRoomSeq: selectedChatRoomInfo.chatRoomSeq })}>
                    {t("예", { ns: "common" })}
                </button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" title={t("아니오", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    {t("아니오", { ns: "common" })}
                </button>
            </div>
        </div>
    );
}

export default ExitChatRoom;
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const WebPassPolicy = ({ isModalOpened, togglePolicyModalOpened }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isModalOpened} size='lg' centered backdrop={true} toggle={() => togglePolicyModalOpened(false)}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end mb-3">
                    <button className="hiclasstv-btn p-1" title={t("닫기", { ns: "common" })} onClick={() => togglePolicyModalOpened(false)}>
                        <FaTimes className="d-flex m-1" />
                    </button>
                </div>
                <h3>
                    {t("이용권_환불_정책", { ns: "pass" })}
                </h3>
                <div className="d-flex w-100 px-2">
                    <div className="web-policy-box w-100 p-2 my-4 scroll-show">
                        <span>
                            <p className="last-p">
                                <b>{t("이용권_환불_정책_문구_1", { ns: "pass" })}</b>
                            </p>
                            <h3>{t("환불액", { ns: "pass" })}</h3>
                            <table className="table table-sm text-center">
                                <thead className="hiclasstv-grey">
                                    <tr>
                                        <td className="align-middle" rowSpan="2">{t("이용권_환불_정책_문구_2", { ns: "pass" })}</td>
                                        <td colSpan="2">{t("이용권_환불_정책_문구_3", { ns: "pass" })}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("이용권_환불_정책_문구_4", { ns: "pass" })}</td>
                                        <td>{t("이용권_환불_정책_문구_5", { ns: "pass" })}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <span>{t("이용권_환불_정책_문구_6", { ns: "pass" })}</span>
                                        </td>
                                        <td>
                                            <span>{t("이용권_환불_정책_문구_7", { ns: "pass" })}</span>
                                        </td>
                                        <td>
                                            <span>{t("이용권_환불_정책_문구_8", { ns: "pass" })}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </span>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default WebPassPolicy;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

import ko from "./ko/index";
import en from "./en/index";
import ja from "./ja/index";

const resource = {
    ko,
    en,
    ja
};

i18n
// .use(LanguageDetector)
.use(initReactI18next)
.init({
    // lng: "ko",
    // lng: "en",
    lng: navigator.language,
    fallbackLng: "ko",
    // fallbackLng: "en",
    debug: true,    // Enables useful output in the browser’s dev console.

    interpolation: {
        escapeValue: false,
    },

    resources: resource,

    // initImmediate: true
});

export default i18n;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';
import { enAdminTap } from '../../lib/ConstCommand';
import WebLocalServerList from './list/WebLocalServerList';
import WebConfigSearchBoxExceptUser from './menu/WebConfigSearchBoxExceptUser';
import WebAddLocalServer from './WebAddLocalServer';
import WebPagination from '../etc/WebPagination';

const WebLocalServerConfig = ({ selectedSubDomainInfo, list_localSite, list_configLocalServer, list_configSubDomain, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState((page - 1) * limit);
    const [blockNum, setBlockNum] = useState(0);

    const [isAddLocalServerOK, setIsAddLocalServerOK] = useState(false);

    useEffect(() => {
        //console.log('WebLocalServerConfig visible');
        
        return () => {
            //console.log('WebLocalServerConfig invisible');
        } 
    }, []);

    useEffect(() => {
        let offset = (page - 1) * limit;
        setOffset(offset);
    }, [page, limit]);

    useEffect(() => {
        setIsAddLocalServerOK(true);

        return () => {
            setPage(1);
            setOffset((page - 1) * limit);
        }
    }, [list_configLocalServer]);

    const onChangeIsAddLocalServerOK = () => {
        setIsAddLocalServerOK(!isAddLocalServerOK);
    }

    return (
        <div className="web-main admin list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />{t("로컬_서버", { ns: "admin" })}
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLocalServer">
                            <FaPlusCircle className="font-md mr-1" />{t("로컬_서버_등록", { ns: "admin" })}
                        </button>
                    </div>
                </div>
                <WebConfigSearchBoxExceptUser
                    adminTap={enAdminTap.LocalServer}
                    selectedSubDomainInfo={selectedSubDomainInfo}
                    list_configSubDomain={list_configSubDomain}
                    handleSearchBoxFunc={handleSelectedFunc}
                />
                <UncontrolledCollapse toggler="#addLocalServer" defaultOpen={false}>
                    <WebAddLocalServer 
                        selectedSubDomainInfo={selectedSubDomainInfo}
                        list_localSite={list_localSite}
                        isAddLocalServerOK={isAddLocalServerOK}
                        onChangeIsAddLocalServerOK={onChangeIsAddLocalServerOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebPagination
                    total={list_configLocalServer.length <= 0 ? 1 : list_configLocalServer.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    blockNum={blockNum}
                    setBlockNum={setBlockNum}
                />
                <WebLocalServerList
                    list_localSite={list_localSite}
                    // list_localServer={list_configLocalServer}
                    list_localServer={list_configLocalServer.slice(offset, offset + limit)}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};

export default WebLocalServerConfig;
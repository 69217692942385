import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaSearch, FaCircle } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';
import ConstData from '../../../lib/ConstData';

const SearchMyFriends = ({ list_friends, list_searchFriends, handleSearchValue, handleSelectedFriendFunc }) => {
    const { t } = useTranslation();

    const [isChangedSearchValue, setChangedSearchValue] = useState(false);
    const [isClickSearchIcon, setClickSearchIcon] = useState(false);
    const [lSearchValue, setSearchValue] = useState("");

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            setClickSearchIcon(true);
            handleSearchValue({keyword:lSearchValue});
        }
    }

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleSearchClick = () => {
        setClickSearchIcon(true);
        handleSearchValue({keyword:lSearchValue});
    }

    const onChangeSearchValue = (e) => {
        setChangedSearchValue(true);
        setClickSearchIcon(false);
        setSearchValue(e.target.value);
        handleSearchValue({keyword:e.target.value});
    }

    return(
        <div onClick={handlePreventClick}>
            <div className="input-area pt-1">
                <input type="text" className="search" placeholder={t("검색어_입력_메시지", { ns: "common" })} onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                <div className="input-group-append ml-1" title={t("검색", { ns: "common" })} onClick={handleSearchClick}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            {
                isClickSearchIcon === true ?
                    <div className="py-1 hiclasstv-lightgrey">
                        <span className="px-2 font-weight-bold grey-text">{t("친구", { ns: "common" })}</span>
                    </div> :
                    <></>
            }
            <div className="list-group" style={{overflow:'auto'}}>
                {
                    isChangedSearchValue === false ?
                        list_friends.length > 0 ?
                            list_friends.map(
                                friend => (
                                    <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={"search_" + friend.userSeq} onClick={() => handleSelectedFriendFunc({ selectedFunc: "FRIEND", friendSeq: friend.userSeq })}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                        </div>
                                        {
                                            friend.userStatus === ConstData.USER_STATUS.STUDYING ?
                                                <div className="d-flex area-10 ml-1">
                                                    <FaCircle className="d-flex font-md hiclasstv-yellow-text" />
                                                </div> :
                                            friend.userStatus === ConstData.USER_STATUS.LOG_IN ?
                                                <div className="d-flex area-10 ml-1">
                                                    <FaCircle className="d-flex font-md green-text" />
                                                </div> :
                                                <></>
                                        }
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                {t("검색_결과_없음_메시지", { ns: "common" })}
                            </div> :
                        list_searchFriends.length > 0 ?
                            list_searchFriends.map(
                                friend => (
                                    <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={"search_" + friend.userSeq} onClick={() => handleSelectedFriendFunc({ selectedFunc: "FRIEND", friendSeq: friend.userSeq })}>
                                        <div className="d-flex area-15 fit-content">
                                            <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                                        </div>
                                        <div className="text-area flex-column align-items-start">
                                            <span className="hiclass-text" title={friend.userNickname}><b>{friend.userNickname}</b></span>
                                            <span className="hiclass-text hiclasstv-darkgrey-text small" title={friend.userProfile}>{friend.userProfile}</span>
                                        </div>
                                        {
                                            friend.userStatus === ConstData.USER_STATUS.STUDYING ?
                                                <div className="d-flex area-10 ml-1">
                                                    <FaCircle className="d-flex font-md hiclasstv-yellow-text" />
                                                </div> :
                                            friend.userStatus === ConstData.USER_STATUS.LOG_IN ?
                                                <div className="d-flex area-10 ml-1">
                                                    <FaCircle className="d-flex font-md green-text" />
                                                </div> :
                                                <></>
                                        }
                                    </div>
                                )
                            ) :
                            <div className="view p-2 text-center grey-text">
                                {t("검색_결과_없음_메시지", { ns: "common" })}
                            </div>
                }
            </div>
        </div>
    );
}
export default SearchMyFriends;
import React from 'react';
import { useTranslation } from "react-i18next";
import Modal from 'reactstrap/lib/Modal';

const LiveAlertModalFinish = ({ live_info, isAlertModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    return(
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>{t("클래스", { ns: "live" })} [</span>
                        <div className="title">
                            <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                        </div>
                        <span>]{t("클래스_종료_안내_문구_2", { ns: "live" })}</span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2">[{t("클래스_진행자", { ns: "live" })}]</span>
                        <div className="text-area text-center">
                            <span className="hiclass-text area-70" title={live_info.teacher_info.userNickname}>
                                <b>{live_info.teacher_info.userNickname}</b>
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LIVE_ALERT_TOGGLE" })}>{t("확인", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
}
export default LiveAlertModalFinish;
import React, { useEffect, useState } from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";

const ConfigLocalLive = ({ selectedLiveInfo_liveSeq, selectedLiveInfo_mixedClassExistYn, selectedLiveInfo_mixedClassSeq, selectedLiveInfo, serverUserSeq, alertKind, alertMessage, handleSelectedConfigFunc }) => {
    const { t } = useTranslation();

    const [lAlertKind, setAlertKind] = useState(alertKind);
    const [lAlertMessage, setAlertMessage] = useState(alertMessage);

    const [lLiveName, setLiveName] = useState(selectedLiveInfo.liveName);
    const [lStatus, setStatus] = useState((selectedLiveInfo.mixedClassExistYn === undefined || selectedLiveInfo.mixedClassExistYn === "N") ? false : true);
    //const [lPublicYn, setPulicYn] = useState(selectedLiveInfo.livePublicYn === undefined ? "N" : selectedLiveInfo.livePublicYn);

    useEffect(() => {
        setAlertKind(alertKind);
        setAlertMessage(alertMessage);
    }, [alertKind, alertMessage]);

    useEffect(() => {
        // handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ" });
        /* if (selectedLiveInfo) {
            if (selectedLiveInfo.mixedClassExistYn === "Y" && selectedLiveInfo.mixedClassSeq >= 0 && selectedLiveInfo.liveSeq >= 0) {
                console.log("case - update_mixed_class_seq y");
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: selectedLiveInfo.mixedClassSeq });
            } else if (selectedLiveInfo.mixedClassExistYn === "N") {
                console.log("case - update_mixed_class_seq n");
                console.log(" liveSeq - ", selectedLiveInfo.liveSeq);
                console.log(" mixedClassSeq - ", selectedLiveInfo.mixedClassSeq);
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: null });
            } else {
                console.log("update_mixed_class_seq else");
            }
        } */
        /* if (selectedLiveInfo.mixedClassExistYn === "Y" && selectedLiveInfo.mixedClassSeq >= 0 && selectedLiveInfo.liveSeq >= 0) {
            console.log("case - update_mixed_class_seq y");
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: selectedLiveInfo.mixedClassSeq });
        } else if (selectedLiveInfo.mixedClassExistYn === "N") {
            console.log("case - update_mixed_class_seq n");
            console.log(" liveSeq - ", selectedLiveInfo.liveSeq);
            console.log(" mixedClassSeq - ", selectedLiveInfo.mixedClassSeq);
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: null });
        } else {
            console.log("update_mixed_class_seq else");
        } */

        /* if (selectedLiveInfo_liveSeq >= 0) {
            if (selectedLiveInfo_mixedClassExistYn === "Y") {
                console.log("case - update_mixed_class_seq y");
                console.log("liveSeq - ", selectedLiveInfo_liveSeq);
                console.log("mixedClassSeq - ", selectedLiveInfo_mixedClassSeq);
                if (selectedLiveInfo_mixedClassSeq >= 0) {
                    handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo_liveSeq, mixedClassSeq: selectedLiveInfo_mixedClassSeq });
                } else {
                    console.log("ConfigLocalLive - selectedLiveInfo_mixedClassSeq => ", selectedLiveInfo_mixedClassSeq);
                }
            } else if (selectedLiveInfo_mixedClassExistYn === "N") {
                console.log("case - update_mixed_class_seq n");
                console.log("liveSeq - ", selectedLiveInfo_liveSeq);
                console.log("mixedClassSeq - ", selectedLiveInfo_mixedClassSeq);
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo_liveSeq, mixedClassSeq: null });
            } else {
                console.log("ConfigLocalLive - selectedLiveInfo_mixedClassExistYn => ", selectedLiveInfo_mixedClassExistYn);
            }
        } else {
            console.log("ConfigLocalLive - selectedLiveInfo_liveSeq => ", selectedLiveInfo_liveSeq);
        } */
    }, [selectedLiveInfo_liveSeq, selectedLiveInfo_mixedClassExistYn, selectedLiveInfo_mixedClassSeq]);

    useEffect(() => {
        if (selectedLiveInfo.mixedClassExistYn === "Y") {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [selectedLiveInfo.mixedClassExistYn]);

    /* useEffect(() => {
        console.log("useEffect - ", selectedLiveInfo.liveSeq, selectedLiveInfo.mixedClassExistYn, selectedLiveInfo.mixedClassSeq);
        if (selectedLiveInfo.mixedClassExistYn === "Y" && selectedLiveInfo.mixedClassSeq >= 0 && selectedLiveInfo.liveSeq >= 0) {
            console.log("case - update_mixed_class_seq y");
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: selectedLiveInfo.mixedClassSeq });
        } else if (selectedLiveInfo.mixedClassExistYn === "N") {
            console.log("case - update_mixed_class_seq n");
            console.log(" liveSeq - ", selectedLiveInfo.liveSeq);
            console.log(" mixedClassSeq - ", selectedLiveInfo.mixedClassSeq);
            handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_CLASS_SEQ", liveSeq: selectedLiveInfo.liveSeq, mixedClassSeq: null });
        } else {
            console.log("update_mixed_class_seq else");
        }
    }, [selectedLiveInfo.liveSeq, selectedLiveInfo.mixedClassExistYn, selectedLiveInfo.mixedClassSeq]); */

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onChangeMixedClassStatus = (e) => {
        if (serverUserSeq > -1) {
            if (selectedLiveInfo.mixedClassExistYn === "Y") {
                console.log("old mixed class status Y");
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_EXIST_YN", liveSeq: selectedLiveInfo.liveSeq, liveName: selectedLiveInfo.liveName, status: false, mixedClassExistYn: "N" });
            } else {
                console.log("old mixed class status N");
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_EXIST_YN", liveSeq: selectedLiveInfo.liveSeq, liveName: selectedLiveInfo.liveName, status: true, mixedClassExistYn: "Y" });
            }
            // handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_EXIST_YN", liveSeq: selectedLiveInfo.liveSeq, liveName: selectedLiveInfo.liveName, status: selectedLiveInfo.mixedClassExistYn === "Y" ? "N" : "Y" });
        } else {
            handleSelectedConfigFunc({ selectedFunc: "MESSAGE", message: t("클래스_알림_메시지_7", { ns: "live" }) });
        }
    }

    const onChangeLivePublicYn = (e) => {
        // setPulicYn(e.target.value);
        console.log(selectedLiveInfo.livePublicYn);
        handleSelectedConfigFunc({ selectedFunc: "UPDATE_PULIC_YN", liveSeq: selectedLiveInfo.liveSeq, publicYn: selectedLiveInfo.livePublicYn === "Y" ? "N" : "Y" });
    }

    const handleSaveBroadcastLiveInfo = () => {
        if (serverUserSeq > -1) {
            if (lLiveName === "") {
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_LIVE_INFO", liveSeq: selectedLiveInfo.liveSeq, liveName: selectedLiveInfo.liveName, status: lStatus });
            } else {
                handleSelectedConfigFunc({ selectedFunc: "UPDATE_MIXED_LIVE_INFO", liveSeq: selectedLiveInfo.liveSeq, liveName: lLiveName, status: lStatus });
            }
        } else {
            handleSelectedConfigFunc({ selectedFunc: "MESSAGE", message: t("클래스_알림_메시지_7", { ns: "live" }) });
        }
    }

    const onClickModalClose = () => {
        handleSelectedConfigFunc({ selectedFunc: "MESSAGE", message: "" });
        handleSelectedConfigFunc({ selectedFunc: "NONE" });
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span title={t("로컬_클래스_설정", { ns: "live" })}>
                    <b>{t("로컬_클래스_설정", { ns: "live" })}</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: '0' }} title={t("닫기", { ns: "common" })} onClick={onClickModalClose}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex flex-column w-100">
                <div className="input-area">
                    <span className="input-label pr-2 small area-40" style={{ justifyContent: 'flex-start' }} title={t("믹스드_클래스", { ns: "live" })}>
                        {t("믹스드_클래스", { ns: "live" })}
                    </span>
                    <div className="input-data area-60">
                        <div className="custom-control custom-switch ml-1">
                            <input type="checkbox" className="custom-control-input" id="mixedClassStatus" /* checked={selectedLiveInfo.mixedClassExistYn === "Y" ? true : false} */ /* value={selectedLiveInfo.mixedClassExistYn === "Y" ? true : false} */ checked={lStatus} /* value={lStatus} */ onChange={onChangeMixedClassStatus} />
                            <label className="custom-control-label" htmlFor="mixedClassStatus" />
                        </div>
                    </div>
                </div>
                {
                    selectedLiveInfo.mixedClassUrl && selectedLiveInfo.mixedClassUrl !== "" &&
                    <div className="font-sm plus px-1">
                        {selectedLiveInfo.mixedClassUrl}
                    </div>
                }
            </div>
            <div className="input-area">
                <span className="input-label pr-2 small area-40" style={{ justifyContent: 'flex-start' }} title={t("클래스_공개_여부", { ns: "live" })}>
                    {t("클래스_공개_여부", { ns: "live" })}
                </span>
                <div className="input-data area-60">
                    <div className="custom-control custom-switch ml-1">
                        <input type="checkbox" className="custom-control-input" id="livePublicYnStatus" checked={selectedLiveInfo.livePublicYn === "Y" ? true : false} disabled={selectedLiveInfo.liveStatus === "Y" ? false : true} onChange={onChangeLivePublicYn} />
                        <label className="custom-control-label" htmlFor="livePublicYnStatus" />
                    </div>
                </div>
            </div>
            <Alerts kind={lAlertKind} message={lAlertMessage} style={{ marginTop: 0 }} />
        </div>
    );
}

export default ConfigLocalLive;
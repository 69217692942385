import React, { useState, useEffect } from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";

const CreateLive = ({ isCertifiedLiveCode, createdLiveCode, alertMessage, alertKind, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [liveCode, setLiveCode] = useState(t("클래스_코드", { ns: "live" }));
    const [liveName, setLiveName] = useState(t("클래스_알림_메시지_8", { ns: "live" }));
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lIsCertifiedLiveCode, setIsCertifiedLiveCode] = useState(isCertifiedLiveCode);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        setIsCertifiedLiveCode(isCertifiedLiveCode);
    }, [isCertifiedLiveCode]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        setLiveCode(createdLiveCode);
    }, [createdLiveCode]);

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            checkCreateLive();
        }
    }

    const onChangeLiveCode = (e) => {
        if (lIsCertifiedLiveCode) {
            handleSelectedFunc({ selectedFunc: "TOGGLE_CERTIFIED_CODE" });
        }

        let str = checkInputValue(e.target.value);
        if (str.length > 10) {
            str = str.substr(0, 10);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("클래스_알림_메시지_12", { ns: "live" }));
        }
        setLiveCode(str);
    }

    const onChangeLiveName = (e) => {
        let str = e.target.value;
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        if (str.length > 50) {
            str = str.substr(0, 50);
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("클래스_알림_메시지_9", { ns: "live" }));
        }

        setLiveName(str);
    }

    const checkCreateLive = () => {
        if (lIsCertifiedLiveCode) { // 클래스 코드 중복체크 또는 자동생성 성공한 경우
            if (liveName === "" || liveName === undefined || liveName === null) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("클래스_알림_메시지_8", { ns: "live" }));
            } else {
                handleSelectedFunc({ selectedFunc: "CREATE_OK", liveName: liveName, liveCode: liveCode });
            }
        } else { // 클래스 코드 중복체크 또는 자동생성을 하지 않은 경우
            if (liveCode === "" || liveCode === undefined || liveCode === null) {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("클래스_알림_메시지_10", { ns: "live" }));
            } else {
                setAlertKind(ConstData.ALERT_KIND.DANGER);
                setAlertMessage(t("클래스_알림_메시지_11", { ns: "live" }));
            }
        }
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(" ", "");
        convertStr = convertStr.replace(/[^a-z0-9@_.-]/gi, "");
        return convertStr;
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span title={t("클래스_생성", { ns: "live" })}>
                    <b>{t("클래스_생성", { ns: "live" })}</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: '0' }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="input-area mt-2">
                <span className="input-label pr-2 small area-35" title={t("클래스_이름", { ns: "live" })}>
                    <b>{t("클래스_이름", { ns: "live" })}</b>
                </span>
                <div className="input-data">
                    <input type="text" placeholder={liveName} onChange={onChangeLiveName} />
                </div>
            </div>
            {/* <div className="d-flex w-100 justify-content-end">
                <span className="input-description">{t("클래스_이름_구성_설명_1", { ns: "live" })}</span>
            </div>
            <div className="d-flex w-100 justify-content-end">
                <span className="input-description">{t("클래스_이름_구성_설명_2", { ns: "live" })}</span>
            </div> */}
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">{t("클래스_이름_구성_설명_1", { ns: "live" })}</span>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">{t("클래스_이름_구성_설명_2", { ns: "live" })}</span>
            </div>
            <div className="input-area">
                <span className="input-label pr-2 small area-35" title={t("클래스_코드", { ns: "live" })}>
                    <b>{t("클래스_코드", { ns: "live" })}</b>
                </span>
                <div className="input-data">
                    <input type="text" placeholder={liveCode} value={liveCode} onChange={onChangeLiveCode} onKeyPress={handleKeyPress} />
                    <div className="d-flex align-items-center fit-content">
                        <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "GET_CODE" })}>{t("자동생성", { ns: "live" })}</button>
                        <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 mx-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CHECK", liveCode: liveCode })} disabled={lIsCertifiedLiveCode === false && liveCode !== "" ? false : true}>{t("중복체크", { ns: "common" })}</button>
                    </div>
                </div>
            </div>
            {/* <div className="d-flex w-100 justify-content-end">
                <span className="input-description">{t("클래스_코드_구성_설명_1", { ns: "live" })}</span>
            </div>
            <div className="d-flex w-100 justify-content-end">
                <span className="input-description">{t("클래스_코드_구성_설명_2", { ns: "live" })}</span>
            </div> */}
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">{t("클래스_코드_구성_설명_1", { ns: "live" })}</span>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <span style={{ width: '35%', maxWidth: '80px' }}></span>
                <span className="input-description area-75">{t("클래스_코드_구성_설명_2", { ns: "live" })}</span>
            </div>
            <Alerts kind={lAlertKind} message={lAlertMessage} />
            <div className="d-flex justify-content-center align-items-center my-2">
                <button type="button" className="btn btn-sm small hiclasstv-blue white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CREATE_OK", liveName: liveName, liveCode: liveCode })} disabled={lIsCertifiedLiveCode === true && liveName !== "" ? false : true}>{t("생성", { ns: "common" })}</button>
            </div>

        </div>
    );
}

export default CreateLive;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import Alerts from "../../etc/list/Alerts";
import ConstData from "../../../lib/ConstData";

const CertifyResetAlertModal = ({ isModalOpened, alertKind, alertMessage, toggleModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lAlertKind, setAlertKind] = useState(alertKind);
    const [lAlertMessage, setAlertMessage] = useState(alertMessage);

    useEffect(() => {
        setAlertKind(alertKind);
        setAlertMessage(alertMessage);
    }, [alertKind, alertMessage]);

    return (
        <Modal isOpen={isModalOpened} backdrop={false} centered toggle={() => toggleModalOpened("cancel", false)}>
            <div className="card container-fluid">
                <div className="card-body">
                    {/* <div className="title-area text-center dark-grey-text">
                        <span>서버 사용자 인증 초기화</span>
                    </div> */}
                    <div className="content-area dark-gray-text small">
                        {/* <div className="text-area text-center">
                            <span>서버 사용자 인증 초기화 시</span>
                            <span>사용 중이던 믹스드 클래스 정보도</span>
                            <span>모두 초기화됩니다.</span>
                            <span>초기화를 진행하시겠습니까?</span>
                        </div> */}
                        <div className="text-area text-center">
                            <span>{t("서버인증_초기화_안내_문구_1", { ns: "user" })}</span>
                            <span>{t("서버인증_초기화_안내_문구_2", { ns: "user" })}</span>
                            <span>{t("서버인증_초기화_안내_문구_3", { ns: "user" })}</span>
                            <span>{t("서버인증_초기화_안내_문구_4", { ns: "user" })}</span>
                            <span>{t("서버인증_초기화_안내_문구_5", { ns: "user" })}</span>
                        </div>
                    </div>
                    {
                        lAlertMessage !== "" &&
                        <Alerts kind={lAlertKind} message={lAlertMessage} style={{ marginTop: 0, fontSize: '80%' }} />
                    }
                    <div className="d-flex justify-content-center">
                        {
                            lAlertKind === ConstData.ALERT_KIND.SUCCESS ?
                                <button type="button" className="btn btn-sm green red white-text m-0 mr-1 py-1 px-2" onClick={() => toggleModalOpened("done", false)}>
                                    {t("확인", { ns: "common" })}
                                </button> :
                                <>
                                    <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => toggleModalOpened("reset", true)}>
                                        {t("확인", { ns: "common" })}
                                    </button>
                                    <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => toggleModalOpened("cancel", false)}>
                                        {t("취소", { ns: "common" })}
                                    </button>
                                </>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CertifyResetAlertModal;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import PerformChattingInfo from '../../components/chat/PerformChattingInfo';
import AloneChattingInfo from '../../components/chat/AloneChattingInfo';

import { finishChatting } from '../../modules/chat';
import { toggleChattingLock, toggleTouchScreenLock } from '../../modules/live';

class PerformChattingContainer extends Component {

    handleSelectedChatFunc = ({ selectedFunc, chatRoomSeq, userSeq, status }) => {
        switch (selectedFunc) {
            case "FINISH":
                this.props.finishChatting({ chatRoomSeq, userSeq });
                break;

            case "TOGGLE_CHATTING_LOCK":
                this.props.toggleChattingLock({ kind: 'chattingLock', isTouchScreenLock: this.props.isTouchScreenLock, userSeq: this.props.userSeq, userNickname: this.props.userNickname });
                break;

            case "TOGGLE_TABLET_LOCK":
                this.props.toggleTouchScreenLock({ userSeq: this.props.userSeq, userNickname: this.props.userNickname });
                this.props.toggleChattingLock({ kind: 'tabletLock', isTouchScreenLock: this.props.isTouchScreenLock, userSeq: this.props.userSeq, userNickname: this.props.userNickname });
                break;

            default:
                this.props.handleSelectedFunc({ selectedFunc });
                break;
        }
    }

    render() {
        const { performChatRoomInfo } = this.props;

        return (
            performChatRoomInfo.chatRoomSeq > 0 ?
                <PerformChattingInfo
                    performChatRoomInfo={performChatRoomInfo}
                    handleSelectedChatFunc={this.handleSelectedChatFunc}
                /> :
                <AloneChattingInfo
                    isOnAirLive={this.props.isOnAirLive}
                    isChattingLock={this.props.isChattingLock}
                    isTouchScreenLock={this.props.isTouchScreenLock}
                    handleSelectedChatFunc={this.handleSelectedChatFunc}
                />
        );
    }
}

export default connect(
    ({ chat, live }) => ({ 
        isChattingLock              : live.isChattingLock,
        isTouchScreenLock           : live.isTouchScreenLock,

        performChatRoomInfo         : chat.performChatRoomInfo,
    }),
    {        
        finishChatting,
        toggleChattingLock,
        toggleTouchScreenLock
    }
)(PerformChattingContainer);
import React from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const DeleteChatRoomMember = ({ deleteChatRoomMemberInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            {
                deleteChatRoomMemberInfo.entryYN === "Y" ?
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        {t("팀업_알림_메시지_7", { ns: "chat" })}<br />
                        {t("팀업_알림_메시지_8", { ns: "chat" })}<br />
                        {t("팀업_알림_메시지_9", { ns: "chat" })}
                    </div> :
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        {t("팀업_알림_메시지_8", { ns: "chat" })}<br />
                        {t("팀업_알림_메시지_9", { ns: "chat" })}
                    </div>
            }
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1" title={t("삭제할_멤버", { ns: "chat" })}>
                    [{t("삭제할_멤버", { ns: "chat" })}]
                </span>
                <div className="text-area text-center area-80">
                    <div className="area-15 fit-content">
                        <CustomProfileImage url={deleteChatRoomMemberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={deleteChatRoomMemberInfo.userNickname} />
                    </div>
                    <span className="hiclass-text" title={deleteChatRoomMemberInfo.userNickname}>
                        <b>{deleteChatRoomMemberInfo.userNickname}</b>
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" title={t("예", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "MEMBER_DELETE", chatRoomSeq: deleteChatRoomMemberInfo.chatRoomSeq, memberSeq: deleteChatRoomMemberInfo.userSeq })}>
                    {t("예", { ns: "common" })}
                </button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" title={t("아니오", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    {t("아니오", { ns: "common" })}
                </button>
            </div>
        </div>
    );
}

export default DeleteChatRoomMember;
import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { FaTicketAlt, FaPlusCircle } from 'react-icons/fa';

import { term, termKind, kindType, prdtKind } from '../../lib/ConstCommand';

import "react-datepicker/dist/react-datepicker.css";

import WebRegistPass from './modal/WebRegistPass';
import WebUsePass from './modal/WebUsePass';
import CustomDatePicker from '../etc/button/CustomDatePicker';

import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';

const WebB2BPassInfo = ({ isCreator, list_configSubDomain, list_pchsPass, list_prdtPass, list_prdtKindCode, list_prdtScaleCode, list_prdtTargetCode, list_prdtTermCode, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    var msg1 = t("이용권_안내_문구_1", { ns: "pass" });
    var msg2 = t("이용권_안내_문구_2", { ns: "pass" });

    var msg3 = t("이용권_안내_문구_3", { ns: "pass" })
    var msg4 = t("이용권_안내_문구_4", { ns: "pass" });

    const [isRegistPassModalOpened, setIsRegistPassModalOpened] = useState(false);
    const [isUsePassModalOpened, setIsUsePassModalOpened] = useState(false);

    const [lUsePassInfo, setUsePassInfo] = useState(null);
    const [lSubDomainInfo, setSubDomainInfo] = useState([]);

    const [lTypeValue, setTypeValue] = useState('all');
    const [lTermValue, setTermValue] = useState('all');
    const [fromDT, setFromDT] = useState(new Date('2020-01-01'));
    const [toDT, setToDT] = useState(new Date());
    const fromDtRef = new createRef();
    const toDtRef = new createRef();

    const [selectedSubDomainOption, setSelectedSubDomainOption] = useState("");

    useEffect(() => {
        console.log('WebB2BPassInfo() init');
        handleSelectedFunc({selectedFunc:"GET_MANAGE_INFO"});
        
        setSelectedSubDomainOption({idx:0, subDomainSeq:-1, subDomainName: t("서브_도메인_없음", { ns: "admin" })});

        return () => {
            console.log('WebB2BPassInfo() component invisible');
        }
    }, []);

    useEffect(() => {
        setSubDomainInfo(list_configSubDomain);
    }, [list_configSubDomain]);

    useEffect(() => {
        if (lSubDomainInfo !== undefined && lSubDomainInfo !== null && lSubDomainInfo.length > 0) {
            setSelectedSubDomainOption({idx:0, subDomainSeq:lSubDomainInfo[0].subDomainSeq, subDomainName:lSubDomainInfo[0].subDomainName});
        }
    }, [lSubDomainInfo.length]);

    useEffect(() => {
        if (selectedSubDomainOption.subDomainSeq > 0) {
            handleLookupDataB2B({ term:lTermValue, kind:lTypeValue, subDomainSeq:selectedSubDomainOption.subDomainSeq });
        }
    }, [selectedSubDomainOption]);

    const selectSubDomain = (e) => {        
        setSelectedSubDomainOption({idx:e.target.value, subDomainSeq:lSubDomainInfo[e.target.value].subDomainSeq, subDomainName:lSubDomainInfo[e.target.value].subDomainName});
        console.log('selectSubDomain[' + e.target.value + "] ------------ ( subDomainSeq : " + lSubDomainInfo[e.target.value].subDomainSeq + ", subDomainName : " + lSubDomainInfo[e.target.value].subDomainName + ")");
    }

    const onChangeKindType = (e) => {
        setTypeValue(e.target.value);
    }

    const onChangeDatePicker = (kind, date) => {
        if (kind === 'from') {
            console.log('onChangeDatePicker(from)');
            setFromDT(date);
            setTermValue(null);
        } else {
            console.log('onChangeDatePicker(to)');
            setToDT(date);
            setTermValue(null);
        }
    }

    const onClickUsePass = (info) => {
        console.log('onClickUsePass => ', info);
        if (isUsePassModalOpened) {
            setUsePassInfo(null);
        } else {
            setUsePassInfo(info);
        }
        setIsUsePassModalOpened(!isUsePassModalOpened);
    }

    const onClickBtnTool = (value) => {
        console.log('onClickBtnTool() - value => ', value);

        if (value === termKind.ALL) {
            setTermValue('all');
            handleLookupDataB2B({ term:'all', kind:lTypeValue, subDomainSeq:selectedSubDomainOption.subDomainSeq });
        } else {
            let date = new Date();
            date.setMonth(toDT.getMonth() - value);
            
            let fromValue = format(date, 'yyyyMMdd');
            let toValue = format(toDT, 'yyyyMMdd');
            let termValue = fromValue + '-' + toValue;

            setFromDT(date);
            setTermValue(termValue);
            handleLookupDataB2B({ term:termValue, kind:lTypeValue, subDomainSeq:selectedSubDomainOption.subDomainSeq });
        }
    }

    const handleLookupData = ({ term, kind, subDomainSeq }) => {
        handleLookupDataB2B({ term, kind, subDomainSeq });
    }

    const handleLookupDataB2B = ({ term, kind, subDomainSeq }) => {
        if (lSubDomainInfo.length > 0) {
            let svcSeq = subDomainSeq;

            if (svcSeq === undefined || svcSeq === null) {
                if (lSubDomainInfo !== undefined && lSubDomainInfo !== null) {
                    svcSeq = lSubDomainInfo.subDomainSeq;
                }
            }
    
            if (svcSeq !== undefined && svcSeq !== null) {
                if (term === undefined || term === null) {
                    let fromValue = format(fromDT, 'yyyyMMdd');
                    let toValue = format(toDT, 'yyyyMMdd');
                    let termValue = fromValue + '-' + toValue;
                    handleSelectedFunc({ selectedFunc:"GET_PCHS_LIST", term:termValue, kind:kind.toLowerCase(), selSubDomainSeq:svcSeq });
                } else {
                    handleSelectedFunc({ selectedFunc:"GET_PCHS_LIST", term, kind:kind.toLowerCase(), selSubDomainSeq:svcSeq });
                }
            } else {
                console.log('handleLookupDataB2B().... svcSeq => ', svcSeq);
            }
        }        
    }

    const toggleUsePassModal = (isModalOpened) => {
        console.log('toggleUsePassModal() - ', isModalOpened);
        setIsUsePassModalOpened(isModalOpened);
    }

    const handleUsePassDate = ({ pchsSeq, startUseDT, endUseDT }) => {
        console.log('handleUsePassDate() - ', pchsSeq, startUseDT, endUseDT);
        let stdate = format(startUseDT, 'yyyy.MM.dd');
        let enddate = format(endUseDT, 'yyyy.MM.dd');
        handleSelectedFunc({ selectedFunc:"USE_PASS", pchsSeq, useDateInfo:{ startUseDT:stdate, endUseDT:enddate } });
        toggleUsePassModal(false);
    }

    const onClickRegistPass = (e) => {
        if (isRegistPassModalOpened) {
            
        } else {
            
        }
        setIsRegistPassModalOpened(!isRegistPassModalOpened);
    }

    const toggleRegistPassModal = (isModalOpened) => {
        console.log('toggleRegistPassModal() - ', isModalOpened);
        setIsRegistPassModalOpened(isModalOpened);
    }

    const handleRegistData = ({ kind, prdtSeq, userInfo, subDomainInfo, prdtInfo }) => {
        if (prdtSeq > 0) {
            let date = new Date();
            let pchsDate = format(date, 'yyyy-MM-dd');
            if (kind === 'B2C') {
                handleSelectedFunc({ selectedFunc:"REGIST_PASS_B2C", prdtSeq, selUserSeq:userInfo.userSeq, prdtInfo, pchsDate });
            } else {
                handleSelectedFunc({ selectedFunc:"REGIST_PASS_B2B", prdtSeq, selSubDomainSeq:subDomainInfo.subDomainSeq, prdtInfo, pchsDate });
            }
            toggleRegistPassModal(false);
        } else {
            console.log('handleRegistData() - 존재하지 않는 prdtSeq....');
        }
    }

    return (
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaTicketAlt className="font-lg mr-2" aria-hidden="true" />{t("이용권_구매_내역", { ns: "pass" })}
                        </span>
                        <button type="button" className="btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" hidden={isCreator !== undefined && isCreator !== null && isCreator === true} onClick={() => onClickRegistPass()}>
                            <FaPlusCircle className="font-md mr-1" />{t("이용권_등록", { ns: "pass" })}
                        </button>
                    </div>
                </div>
                <div className="px-2">
                    <div className="web-search-box">
                        <div className="web-search-option-area" style={{justifyContent:'unset'}}>
                            <div className="web-search-option">
                                <div className="web-search-select-label">
                                    <span><b>{t("서브_도메인", { ns: "admin" })}</b></span>
                                </div>
                                <div className="web-search-select-box">
                                    <select className="custom-select small my-1" style={{ width: '100%', fontSize: '0.9rem' }} value={selectedSubDomainOption.idx} onChange={selectSubDomain}>
                                        {
                                            lSubDomainInfo === undefined || lSubDomainInfo === null || lSubDomainInfo.length <= 0 ?
                                                <option value={-1}>
                                                    {t("서브_도메인_목록_없음", { ns: "admin" })}
                                                </option> :
                                                lSubDomainInfo.map(
                                                    (info, index) => (
                                                        <option value={index} key={"item-" + index}>{info.subDomainName}</option>
                                                    )
                                                ) 
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="web-search-option-area">
                            <div className="web-toolbar-menu toolbar-menu-btn" role="toolbar">
                                <div className="d-flex w-100 align-items-center justify-content-center">
                                    {
                                        term.map(
                                            (info, index) => (
                                                index === 0 ?
                                                    <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                        {t("전체", { ns: "pass" })}
                                                    </button> :
                                                    <button key={"tl-" + index} className="web-toolbar-item" value={info.value} onClick={() => onClickBtnTool(info.value)}>
                                                        {info.name}
                                                    </button>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className="web-toolbar-menu toolbar-menu-date">
                                <DatePicker
                                    locale={ko}
                                    ref={fromDtRef}
                                    selected={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('from', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={fromDtRef} dateInfo={fromDT} />}
                                />
                                <span className="mx-2">-</span>
                                <DatePicker
                                    locale={ko}
                                    ref={toDtRef}
                                    selected={toDT}
                                    minDate={fromDT}
                                    maxDate={new Date()}
                                    onChange={date => onChangeDatePicker('to', date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement='auto'
                                    customInput={<CustomDatePicker ref={toDtRef} dateInfo={toDT} />}
                                />
                            </div>
                            <div className="web-toolbar-menu">
                                <div className="web-search-option p-0">
                                    <select className="custom-select small" style={{ fontSize: '0.9rem' }} value={lTypeValue} onChange={onChangeKindType}>
                                        {
                                            kindType.length > 0 ?
                                                kindType.map(
                                                    (info, index) => (
                                                        <option value={info.value} key={"type-" + index}>{info.name}</option>
                                                    )
                                                ) :
                                                <option value='all'>{t("없음", { ns: "pass" })}</option>
                                        }
                                    </select>
                                </div>
                                <div className="web-search-option p-0">
                                    <button className="btn btn-sm btn-default" style={{ width: '100%', margin: '0 0 0 .375rem', fontSize: '.8rem' }} type="button" onClick={() => handleLookupData({ term: lTermValue, kind: lTypeValue, subDomainSeq: selectedSubDomainOption.subDomainSeq })}>
                                        {t("조회", { ns: "common" })}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column w-100">
                        {
                            list_pchsPass !== undefined && list_pchsPass !== null && list_pchsPass.length > 0 ? 
                                    <div className="d-flex table-wrapper">
                                        <table className="d-flex flex-column table table-sm mb-0 pass-list">
                                            <tbody>
                                                {
                                                    list_pchsPass.map(
                                                        info => (
                                                            <tr key={"pkey_" + info.pchsSeq} className="d-flex">
                                                                <td className="d-flex w-100">
                                                                    <div className="pass-info-item web-list-title pchsPass">
                                                                        {
                                                                            info.prdtInfo.kindCode === prdtKind.Purchase ?
                                                                                <div className="d-flex flex-column">
                                                                                    <span className="info-title">{`[${t("구매", { ns: "pass" })}] ${info.prdtInfo.name}`}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("대상", { ns: "pass" })} : {info.prdtInfo.termName + msg3}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("사용기한", { ns: "pass" })} : {msg1}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("서브_도메인_이용기한", { ns: "pass" })} : {t("이용권_안내_문구_5", { ns: "pass" }) + info.prdtInfo.termName}</span>
                                                                                </div> :
                                                                                <div className="d-flex flex-column">
                                                                                    <span className="info-title">{`[${t("프로모션", { ns: "promotion" })}] ${info.prdtInfo.name}`}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("대상", { ns: "pass" })} : {info.prdtInfo.termName + msg4}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("사용기한", { ns: "pass" })} : {msg2}</span>
                                                                                    <span style={{wordBreak:'keep-all', wordWrap:'break-word'}}>· {t("서브_도메인_이용기한", { ns: "pass" })} : {t("이용권_안내_문구_5", { ns: "pass" }) + info.prdtInfo.termName}</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="landscape-to-portrait web-list-item pchsPass">
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <span className="only-mobile">{t("구매일", { ns: "pass" })}</span>
                                                                            <span className="hiclass-num-text">{info.pchsDT}</span>
                                                                        </div>
                                                                        <div className="d-flex align-items-center justify-content-center pass-use-date">
                                                                            {
                                                                                info.useDtInfo.strtDT !== null && info.useDtInfo.endDT !== null ?
                                                                                    <div className="d-flex flex-column align-items-center w-100">
                                                                                        <span className="hiclass-text">{t("사용완료", { ns: "pass" })}</span>
                                                                                        <span className="hiclass-num-text">{info.useDtInfo.strtDT + "~" + info.useDtInfo.endDT}</span>
                                                                                    </div> :
                                                                                    <button type="button" className="btn btn-sm btn-default" onClick={() => onClickUsePass(info)}>{t("사용", { ns: "pass" })}</button>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div> : 
                                <div className="view m-3 text-center grey-text">
                                    {t("이용권_구매_내역_없음", { ns: "pass" })}
                                </div>
                        }
                    </div>
                </div>
            </div>
            <WebRegistPass
                isModalOpened={isRegistPassModalOpened}
                toggleModalOpened={toggleRegistPassModal}
                handleRegistData={handleRegistData}
                subDomainInfo={selectedSubDomainOption}
                list_subDomainInfo={list_configSubDomain}
                list_prdtPass={list_prdtPass}
                list_prdtKindCode={list_prdtKindCode}
                list_prdtScaleCode={list_prdtScaleCode}
                list_prdtTargetCode={list_prdtTargetCode}
                list_prdtTermCode={list_prdtTermCode}
            />
            <WebUsePass
                isModalOpened={isUsePassModalOpened}
                toggleModalOpened={toggleUsePassModal}
                handleUsePassDate={handleUsePassDate}
                usePchsInfo={lUsePassInfo}
            />
        </div>
    );
};
export default WebB2BPassInfo;
import React, { useState, useImperativeHandle, forwardRef } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

const InkPlayerControl = forwardRef(({ toggle }, ref) => {
    const [played, setPlayed] = useState(false);

    useImperativeHandle(ref, () => ({
        play: () => setPlayed(true),
        pause: () => setPlayed(false)
    }));

    return (
        <div className="control">
            {
                !played ?
                    <FaPlay onClick={toggle} /> :
                    <FaPause onClick={toggle} />
            }
        </div>
    );
});

export default InkPlayerControl;
import React from "react";
import { useTranslation } from "react-i18next";

const WebHiClassInfoHome = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column w-100 align-items-center" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100 flex-wrap" style={{ margin: '2rem 0' }}>
                <div className="d-flex justify-content-center align-items-center" style={{ width: '50%', minWidth: '5%', maxWidth: 'max-content' }}>
                    <img src="/images/info/main/logo.png" style={{ display: 'flex', width: '80%' }} alt="HiClass_Logo.png" />
                </div>
                <div className="d-flex flex-column justify-content-center" style={{ minWidth: '10%' }}>
                    <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                        <span>HiClassTV&nbsp;</span>
                        <span className="hiclasstv-blue-text">Pentalk</span>
                    </div>
                    <div className="d-flex text-center">
                        <span className="d-flex" style={{ fontSize: '1.4rem', fontWeight: '400', lineHeight: 'normal' }}>
                            {t("인포_홈_설명_1", { ns: "info" })}
                        </span>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ margin: '2rem 0' }}>
                <img src="/images/info/main/main01.png" className="w-100 h-100" style={{ objectFit: 'contain' }} alt="HiClassTV_Pentalk_Main01.png" />
            </div>
            <div className="d-flex align-items-center justify-content-center text-center my-2">
                <span className="dark-grey-text" style={{ fontSize: '1.1rem', fontWeight: '500' }}>
                    {t("인포_홈_설명_2", { ns: "info" })}{t("인포_홈_설명_2_1", { ns: "info" })}
                </span>
            </div>
            <div className="d-flex w-75" style={{ margin: '2rem 0' }}>
                <img src="/images/info/main/main02.png" className="w-100 h-100" style={{ objectFit: 'contain' }} alt="HiClassTV_Pentalk_Main02.png" />
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex flex-wrap">
                    {/*
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon01.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        {t("인포_홈_설명_3", { ns: "info" })}
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.15rem' }}>
                                        {t("인포_홈_설명_4", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    */}
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon02.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        {t("인포_홈_설명_5", { ns: "info" })}
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.05rem' }}>
                                        {t("인포_홈_설명_6", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon03.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        {t("인포_홈_설명_7", { ns: "info" })}
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.15rem' }}>
                                        {t("인포_홈_설명_8", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon05.png" style={{ width: '95%', height: '95%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        {t("인포_홈_설명_9", { ns: "info" })}
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.15rem' }}>
                                        {t("인포_홈_설명_10", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex col-md-6 my-5 justify-content-center p-0">
                        <div className="card h-100 mx-2">
                            <div className="card-body d-flex justify-content-center text-center" style={{ position: 'relative' }}>
                                <div className="info-circle">
                                    <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                        <img src="/images/info/main/icon04.png" style={{ width: '70%', height: '70%' }} alt="" />
                                    </span>
                                </div>
                                <div className="py-4 px-2">
                                    <span className="d-flex justify-content-center my-3" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                        {t("인포_홈_설명_11", { ns: "info" })}
                                    </span>
                                    <span className="d-flex justify-content-center my-2" style={{ fontSize: '1rem', fontWeight: '400', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.15rem' }}>
                                        {t("인포_홈_설명_12", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoHome;
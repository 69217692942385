import React, { useState, useEffect } from "react";

const TextItem = ({ text }) => {
    const [roleData, setRoleData] = useState(null);
    const [textData, setTextData] = useState(null);

    useEffect(() => {
        if (text !== undefined && text !== null) {
            if (text.type === "role-script") {
                setRoleData(text.data.role);
                setTextData(text.data.script);
            } else { // type is none
                setTextData(text.data);
            }
            // setTextData(text.data);
        }
    }, [text]);

    return (
        textData !== undefined && textData !== null ?
            roleData !== undefined && roleData !== null ?
                <span className="text-item" type="item">
                    <span className="font-weight-bold">
                        {roleData}
                    </span>
                    {" : " + textData}
                </span> :
                <span className="text-item" type="item">
                    {textData}
                </span> :
            <></>
    );
}

export default TextItem;
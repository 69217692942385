import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import Alerts from '../../etc/list/Alerts';

import ConstData from '../../../lib/ConstData';

const WebWarningAlertModal = ({ isModalOpened, warningKind, toggleModalOpened }) => {
    const { t } = useTranslation();

    const [lWarningKind, setWarningKind] = useState(warningKind);
    const [lAlertMessage, setAlertMessage] = useState("");
    //const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        if (warningKind === "RESET_USER_PW") {
            setAlertMessage(t("관리자_경고_문구_1", { ns: "admin" }));
        } else if (warningKind === "RESET_USER_STATUS") {
            setAlertMessage(t("관리자_경고_문구_2", { ns: "admin" }));
        } else if (warningKind === "SUB_DOMAIN_ADMIN") {
            setAlertMessage(t("관리자_경고_문구_3", { ns: "admin" }));
        } else if (warningKind === "SUB_DOMAIN_CREATE_ADMIN") {
            setAlertMessage(t("관리자_경고_문구_4", { ns: "admin" }));
        } else {
            console.log('WebWarningAlertModal() useEffect warningKind is ', warningKind);
        }
    }, [lWarningKind]);

    const onCloseModal = () => {
        setWarningKind("");
        setAlertMessage("");
        //setAlertKind(ConstData.ALERT_KIND.NONE);
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        ※ {t("경고", { ns: "admin" })} ※
                    </div>
                    <div className="content-area white dark-grey-text m-0">
                        <Alerts kind={ConstData.ALERT_KIND.DANGER} message={lAlertMessage} />
                    </div>
                    <button type="button" className="btn green white-text py-1 px-2" onClick={() => toggleModalOpened(false)}>{t("확인", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
}
export default WebWarningAlertModal;
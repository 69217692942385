import React from 'react';
import { useTranslation } from "react-i18next";
import { BsX } from 'react-icons/bs';

const LiveMemberLimit = ({ live_info, handleSelectedConfigFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                {t("클래스_멤버_수_초과_안내_문구_3", { ns: "live" })}<br />
                {t("클래스_멤버_수_초과_안내_문구_4", { ns: "live" })}<br />
                {t("클래스_멤버_수_초과_안내_문구_5", { ns: "live" })}
            </div>
            <div className="content-area dark-grey-text">
                <span className="small mb-1">[{t("클래스_이름", { ns: "live" })}]</span>
                <div className="text-area text-center area-80">
                    <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                </div>
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc: "NONE" })}>{t("닫기", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default LiveMemberLimit;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConstData from '../../lib/ConstData';

import {
    closeConfigModal,
    toggle_configLocalBroadcastLive,
    toggle_editBroadcastLive,
    toggle_editBroadcastLiveMember,
    toggle_removeBroadcastLive,
    toggle_removeBroadcastLiveMember,
    updateLiveName,
    removeLive,
    removeLiveMember,
    updateLiveMember,
    updatePublicYnForLocalLive,
    requestUpdateMixedClassExistYn,
    // updateMixedClassSeq
} from '../../modules/live';

import ToppedModal from '../../components/template/modal/ToppedModal';
import CenteredModal from '../../components/template/modal/CenteredModal';
import RightSideModal from '../../components/template/modal/RightSideModal';

import ConfigLiveListTitleTemplate from '../../components/template/title/ConfigLiveListTitleTemplate';
import ConfigMyBroadcastLiveList from '../../components/live/ConfigMyBroadcastLiveList';
import ConfigBroadcastLiveMember from '../../components/live/modal/ConfigBroadcastLiveMember';
import ConfigLocalLive from '../../components/live/modal/ConfigLocalLive';
import EditLive from '../../components/live/modal/EditLive';
import RemoveLive from '../../components/live/modal/RemoveLive';
import RemoveLiveMember from '../../components/live/modal/RemoveLiveMember';
import LiveMemberLimit from '../../components/live/modal/LiveMemberLimit';

class ConfigMyBroadcastLiveListContainer extends Component {

    handleSelectedConfigFunc = ({ selectedFunc, liveSeq, liveName, memberSeq, memberInfo, status, message, publicYn, mixedClassSeq }) => {
        const { userSeq, serverUserSeq } = this.props;

        switch (selectedFunc) {
            case "EDIT_LOCAL_LIVE":
                console.log("edit local live");
                this.props.toggle_configLocalBroadcastLive({ selectedFunc, liveSeq });
                break;

            case "UPDATE_MIXED_EXIST_YN":
                this.props.requestUpdateMixedClassExistYn({ selectedFunc, liveSeq, liveName, userSeq, mode: "hiclass", data: { status, serverUserSeq } });
                break;

            case "EDIT_TOGGLE":
                console.log("edit toggle");
                this.props.toggle_editBroadcastLive({ selectedFunc, liveSeq });
                break;

            case "UPDATE_LIVE_INFO":
                this.props.updateLiveName({ selectedFunc, liveSeq, liveName, userSeq, mode: "hiclass" });
                break;

            case "UPDATE_MIXED_LIVE_INFO":
                // this.props.updateLiveName({ selectedFunc, liveSeq, liveName, userSeq, mode: "hiclass", data: { status, serverUserSeq } });
                this.props.updateLiveName({ selectedFunc, liveSeq, liveName, userSeq, mode: "hiclass" });
                break;

            case "REMOVE_TOGGLE":
                this.props.toggle_removeBroadcastLive({ selectedFunc, liveSeq });
                break;

            case "REMOVE":
                this.props.removeLive({ selectedFunc, liveSeq, userSeq, mode: "hiclass" });
                break;

            case "EDIT_MEMBER_TOGGLE":
                this.props.toggle_editBroadcastLiveMember({ selectedFunc, liveSeq });
                break;

            case "MEMBER_ACCEPT":
                this.props.updateLiveMember({ liveSeq, userSeq: memberSeq, liveCertiYN: "Y", mode: "hiclass" });
                break;

            case "MEMBER_REJECT":
                this.props.updateLiveMember({ liveSeq, userSeq: memberSeq, liveCertiYN: "R", mode: "hiclass" });
                break;

            case "MEMBER_REMOVE_TOGGLE":
                this.props.toggle_removeBroadcastLiveMember({ selectedFunc, memberInfo })
                break;

            case "MEMBER_REMOVE":
                this.props.removeLiveMember({ liveSeq, userSeq: memberSeq, mode: "hiclass" });
                break;

            case "NONE":
                // this.props.handleSelectedFunc({ selectedFunc: "MESSAGE", message: "" });
                this.props.closeConfigModal({ selectedFunc });
                break;

            case "MESSAGE":
                this.props.handleSelectedFunc({ selectedFunc, message });
                break;

            case "UPDATE_PULIC_YN":
                this.props.updatePublicYnForLocalLive({ liveSeq, userSeq: this.props.userSeq, publicYn });
                break;

            /* case "UPDATE_MIXED_CLASS_SEQ":
                console.log(" liveSeq - ", liveSeq);
                console.log(" mixedClassSeq - ", mixedClassSeq);
                this.props.updateMixedClassSeq({ localLiveSeq: liveSeq, mixedClassSeq: mixedClassSeq });
                break; */

            default:
                break;
        }
    }

    render() {
        const { userSeq, serverUserSeq, list_live, selectedLiveInfo, selectedLiveMemberInfo,
            closedLive_Info } = this.props;
        const { handleSelectedFunc, alertKind, alertMessage } = this.props;
        const { handleSelectedConfigFunc } = this;

        return (
            <>
                {
                    this.props.isConfigLocalLiveModalOpened &&
                    <ToppedModal modalClose={handleSelectedConfigFunc}>
                        <ConfigLocalLive
                            selectedLiveInfo_liveSeq={selectedLiveInfo.liveSeq}
                            selectedLiveInfo_mixedClassExistYn={selectedLiveInfo.mixedClassExistYn}
                            selectedLiveInfo_mixedClassSeq={selectedLiveInfo.mixedClassSeq}
                            selectedLiveInfo={selectedLiveInfo}
                            serverUserSeq={serverUserSeq}
                            alertKind={alertKind}
                            alertMessage={alertMessage}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </ToppedModal>
                } {
                    this.props.isEditLiveModalOpened &&
                    <ToppedModal modalClose={handleSelectedConfigFunc}>
                        <EditLive
                            selectedLiveInfo={selectedLiveInfo}
                            serverUserSeq={serverUserSeq}
                            alertKind={alertKind}
                            alertMessage={alertMessage}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </ToppedModal>
                } {
                    this.props.isEditLiveMemberModalOpened &&
                    <RightSideModal modalClose={handleSelectedConfigFunc}>
                        <ConfigBroadcastLiveMember
                            selectedLiveInfo={selectedLiveInfo}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </RightSideModal>
                } {
                    this.props.isRemoveLiveModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <RemoveLive
                            selectedLiveInfo={selectedLiveInfo}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    this.props.isRemoveLiveMemberModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <RemoveLiveMember
                            selectedLiveMemberInfo={selectedLiveMemberInfo}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                } {
                    this.props.isLiveMemberLimitModalOpened &&
                    <CenteredModal modalClose={handleSelectedConfigFunc}>
                        <LiveMemberLimit
                            live_info={closedLive_Info}
                            handleSelectedConfigFunc={handleSelectedConfigFunc}
                        />
                    </CenteredModal>
                }
                <ConfigLiveListTitleTemplate
                    handleSelectedFunc={handleSelectedFunc}
                />
                <ConfigMyBroadcastLiveList
                    userSeq={userSeq}
                    list_live={list_live}
                    handleSelectedConfigFunc={handleSelectedConfigFunc}
                />
            </>
        )
    }
}

export default connect(
    ({ user, live }) => ({
        userSeq                         : user.userSeq,
        serverUserSeq                   : user.serverUserSeq,

        list_live                       : live.list_live,

        selectedLiveInfo                : live.selectedLiveInfo,
        selectedLiveMemberInfo          : live.selectedLiveMemberInfo,

        isEditLiveModalOpened           : live.isEditLiveModalOpened,
        isEditLiveMemberModalOpened     : live.isEditLiveMemberModalOpened,
        isConfigLocalLiveModalOpened    : live.isConfigLocalLiveModalOpened,
        isRemoveLiveModalOpened         : live.isRemoveLiveModalOpened,
        isRemoveLiveMemberModalOpened   : live.isRemoveLiveMemberModalOpened,
        isLiveMemberLimitModalOpened    : live.isLiveAlertModalMemberLimitOpened,

        closedLive_Info                 : live.closedPerformLiveInfo
    }),
    {
        closeConfigModal,

        toggle_configLocalBroadcastLive,
        toggle_editBroadcastLive,
        toggle_editBroadcastLiveMember,
        toggle_removeBroadcastLive,
        toggle_removeBroadcastLiveMember,

        updateLiveName,
        removeLive,
        updateLiveMember, 
        removeLiveMember,
        updatePublicYnForLocalLive,
        requestUpdateMixedClassExistYn,
        // updateMixedClassSeq
    }
)(ConfigMyBroadcastLiveListContainer);
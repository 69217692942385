import React, { useState, useEffect } from "react";
import { FiMinusSquare } from "react-icons/fi";
import { useTranslation } from "react-i18next";

import { stQuizItemType, stQuizDataKind, stQuizDataType } from "../../lib/ConstCommand";

import QuestionDataInput from "./question/QuestionDataInput";
import ConstData from "../../lib/ConstData";

const QuestionDataTypeInput = ({ kind, type, onChangeQuestionDataType }) => {
    const [quizDataType, setQuizDataType] = useState(type);

    useEffect(() => {
        setQuizDataType(type);
    }, [type]);

    const onChangeQuizDataType = (e) => {
        setQuizDataType(e.target.value);
        onChangeQuestionDataType(e.target.value);
    }

    switch (kind) {
        case stQuizDataKind.Image:
        case stQuizDataKind.Audio:
            return (
                <div className="input-group">
                    <span className="input-group-text border-0"></span>
                    <input type="radio" className="form-check-input" id="quizDataTypeFile" value={stQuizDataType.File} checked={quizDataType === stQuizDataType.File} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeFile">{stQuizDataType.File}</label>
                    <input type="radio" className="form-check-input" id="quizDataTypeURL" value={stQuizDataType.URL} checked={quizDataType === stQuizDataType.URL} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeURL">{stQuizDataType.URL}</label>
                </div>
            );

        case stQuizDataKind.Video:
            return (
                <div className="input-group">
                    <span className="input-group-text border-0"></span>
                    <input type="radio" className="form-check-input" id="quizDataTypeFile" value={stQuizDataType.File} checked={quizDataType === stQuizDataType.File} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeFile">{stQuizDataType.File}</label>
                    <input type="radio" className="form-check-input" id="quizDataTypeURL" value={stQuizDataType.URL} checked={quizDataType === stQuizDataType.URL} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeURL">{stQuizDataType.URL}</label>
                    <input type="radio" className="form-check-input" id="quizDataTypeIframe" value={stQuizDataType.Iframe} checked={quizDataType === stQuizDataType.Iframe} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeIframe">{stQuizDataType.Iframe}</label>
                    {/* <input type="radio" className="form-check-input" id="quizDataTypeObject" value={stQuizDataType.Object} checked={quizDataType === stQuizDataType.Object} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeObject">{stQuizDataType.Object}</label>
                    <input type="radio" className="form-check-input" id="quizDataTypeEmbed" value={stQuizDataType.Embed} checked={quizDataType === stQuizDataType.Embed} onChange={onChangeQuizDataType} />
                    <label className="form-check-label" htmlFor="quizDataTypeEmbed">{stQuizDataType.Embed}</label> */}
                </div>
            );

        default:
            return <></>;
    }
}

const QuizQuestionData = ({ index, quizQuestion_info, lQuestionUploadInfo, lAlertKind, lAlertMessage, setAlertKind, setAlertMessage, handleChangeQuestionData, handleRemoveQuestionData, handleQuestionFileUpload }) => {
    const { t } = useTranslation();

    //const [lQuestionItemIndex, setQuestionItemIndex] = useState(index);
    const [lQuestionDataIndex, setQuestionDataIndex] = useState(index);
    const [lQuestionKind, setQuestionKind] = useState(quizQuestion_info ? quizQuestion_info.questionKind : stQuizDataKind.Text);
    const [lQuestionDataType, setQuestionDataType] = useState(quizQuestion_info ? quizQuestion_info.questionDataType : stQuizDataType.None);
    const [lQuestionData, setQuestionData] = useState(quizQuestion_info ? quizQuestion_info.questionData : "");
    const [lUploadUrlYN, setUploadUrlYN] = useState(quizQuestion_info ? quizQuestion_info.uploadUrlYN : "N");
    const [lUploadPath, setUploadPath] = useState(quizQuestion_info ? quizQuestion_info.uploadPath : "");

    useEffect(() => {
        //setQuestionItemIndex(index);
        setQuestionDataIndex(index);

        if (quizQuestion_info) {
            if (quizQuestion_info.questionKind) {
                setQuestionKind(quizQuestion_info.questionKind);
            }

            if (quizQuestion_info.questionDataType) {
                setQuestionDataType(quizQuestion_info.questionDataType);
            }

            if (quizQuestion_info.questionData !== undefined && quizQuestion_info.questionData !== null) {
                setQuestionData(quizQuestion_info.questionData);
            }

            if (quizQuestion_info.uploadUrlYN) {
                setUploadUrlYN(quizQuestion_info.uploadUrlYN);
            }

            if (quizQuestion_info.uploadPath) {
                setUploadPath(quizQuestion_info.uploadPath);
            }
        }
    }, [index, quizQuestion_info]);

    /* useEffect(() => {
        //console.log(lQuestionDataIndex, lQuestionKind, lQuestionDataType, lQuestionData);
        handleChangeQuestionData({ 
            index: lQuestionDataIndex, 
            questionKind: lQuestionKind, 
            questionDataType: lQuestionDataType, 
            questionData: lQuestionData
        });
    }, [lQuestionDataIndex, lQuestionKind, lQuestionDataType, lQuestionData]); */

    const onChangeQuestionKind = (e) => {
        let questionKind = e.target.value;
        let questionDataType = stQuizDataType.None;
        let uploadUrlYN = "N";
        let uploadPath = "";

        switch (questionKind) {
            case stQuizDataKind.Text:
                questionDataType = stQuizDataType.None;
                break;

            case stQuizDataKind.Image:
            case stQuizDataKind.Audio:
            case stQuizDataKind.Video:
                questionDataType = stQuizDataType.File;
                break;

            default:
                questionDataType = stQuizDataType.None;
                break;
        }

        if (lQuestionKind !== questionKind && lQuestionData !== "") {
            setQuestionData("");
            setQuestionKind(questionKind);
            setQuestionDataType(questionDataType);
            setUploadUrlYN(uploadUrlYN);
            setUploadPath(uploadPath);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind,
                questionDataType,
                questionData: "",
                uploadUrlYN,
                uploadPath
            });
        } else {
            setQuestionKind(questionKind);
            setQuestionDataType(questionDataType);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind,
                questionDataType,
                questionData: lQuestionData,
                uploadUrlYN: lUploadUrlYN,
                uploadPath: lUploadPath
            });
        }
    }

    const onChangeQuestionDataType = (questionDataType) => {
        if (lQuestionDataType !== questionDataType && lQuestionData !== "") {
            setQuestionData("");
            setQuestionDataType(questionDataType);
            setUploadUrlYN("N");
            setUploadPath("");

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType,
                questionData: "",
                uploadUrlYN: "N",
                uploadPath: ""
            });
        } else {
            setQuestionDataType(questionDataType);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType,
                questionData: lQuestionData,
                uploadUrlYN: lUploadUrlYN,
                uploadPath: lUploadPath
            });
        }
    }

    const onChangeQuestionData = (e) => {
        if (e !== undefined && e.target !== undefined) {
            if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
                setAlertMessage("");
                setAlertKind(ConstData.ALERT_KIND.NONE);
            }

            let questionData = e.target.value;
            if (questionData.length > 100) {
                questionData = questionData.substr(0, 100);
                setAlertMessage(t("퀴즈_알림_메시지_24", { ns: "classTalk" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
            let uploadUrlYN = "N";
            let uploadPath = "";
            setQuestionData(questionData);
            setUploadUrlYN(uploadUrlYN);
            setUploadPath(uploadPath);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType: lQuestionDataType,
                questionData,
                uploadUrlYN,
                uploadPath
            });
        } else {
            let questionData = e;
            let uploadUrlYN = e !== "" ? "Y" : "N";
            setQuestionData(questionData);
            setUploadUrlYN(uploadUrlYN);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType: lQuestionDataType,
                questionData,
                uploadUrlYN,
                uploadPath: lUploadPath
            });
        }
    }

    const onClickRemoveQuestionData = () => {
        let quizQuestion_data = {
            questionKind: lQuestionKind,
            questionDataType: lQuestionDataType,
            questionData: lQuestionData,
            uploadUrlYN: lUploadUrlYN,
            uploadPath: lUploadPath
        };

        handleRemoveQuestionData(lQuestionDataIndex, quizQuestion_data);
    }

    const handleUploadPath = (uploadPath) => {
        if (lUploadPath !== uploadPath && uploadPath === "") {
            setQuestionData("");
            setUploadUrlYN("N");
            setUploadPath(uploadPath);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType: lQuestionDataType,
                questionData: "",
                uploadUrlYN: "N",
                uploadPath
            });
        } else {
            let uploadUrlYN = uploadPath !== "" ? "Y" : "N";
            let questionData = uploadPath !== "" ? lQuestionData : "";
            setQuestionData(questionData);
            setUploadPath(uploadPath);
            setUploadUrlYN(uploadUrlYN);

            handleChangeQuestionData({
                index: lQuestionDataIndex,
                questionKind: lQuestionKind,
                questionDataType: lQuestionDataType,
                questionData,
                uploadUrlYN,
                uploadPath
            });
        }
    }

    return (
        <>
            <QuestionDataTypeInput
                kind={lQuestionKind}
                type={lQuestionDataType}
                onChangeQuestionDataType={onChangeQuestionDataType}
            />
            <div className="data-area">
                <div className="input-group mb-2">
                    {
                        lQuestionDataIndex === 0 ?
                            <span className="input-group-text border-0">Q.</span> :
                            <div className="input-group-text border-0" title={t("질문_삭제", { ns: "classTalk" })} onClick={onClickRemoveQuestionData}>
                                <FiMinusSquare className="font-lg hiclasstv-coral-text" />
                            </div>
                    }
                    {
                        lQuestionUploadInfo && lQuestionUploadInfo.seq === lQuestionDataIndex ?
                            <QuestionDataInput
                                index={lQuestionDataIndex}
                                kind={lQuestionKind}
                                type={lQuestionDataType}
                                data={lQuestionData}
                                uploadUrlYN={lUploadUrlYN}
                                lQuestionUploadInfo={lQuestionUploadInfo}
                                lAlertKind={lAlertKind}
                                lAlertMessage={lAlertMessage}
                                setAlertKind={setAlertKind}
                                setAlertMessage={setAlertMessage}
                                onChangeQuestionData={onChangeQuestionData}
                                onChangeQuestionDataType={onChangeQuestionDataType}
                                handleUploadPath={handleUploadPath}
                                handleQuestionFileUpload={handleQuestionFileUpload}
                            /> :
                            <QuestionDataInput
                                index={lQuestionDataIndex}
                                kind={lQuestionKind}
                                type={lQuestionDataType}
                                data={lQuestionData}
                                uploadUrlYN={lUploadUrlYN}
                                lAlertKind={lAlertKind}
                                lAlertMessage={lAlertMessage}
                                setAlertKind={setAlertKind}
                                setAlertMessage={setAlertMessage}
                                onChangeQuestionData={onChangeQuestionData}
                                onChangeQuestionDataType={onChangeQuestionDataType}
                                handleUploadPath={handleUploadPath}
                                handleQuestionFileUpload={handleQuestionFileUpload}
                            />
                    }
                    <span className="input-group-text border-0">Type.</span>
                    <div className="select-wrapper" style={{ flex: '1 1 auto', width: 'fit-content' }}>
                        <select className="form-select" value={lQuestionKind} onChange={onChangeQuestionKind}>
                            {
                                stQuizItemType.map((info, index) => (
                                    <option key={"question-kind-option-item-" + index} value={info}>
                                        {info}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>
            </div>
            {/* <div className="input-group mb-2">
                {
                    lQuestionDataIndex === 0 ?
                        <span className="input-group-text border-0">Q.</span> :
                        <div className="input-group-text border-0" onClick={onClickRemoveQuestionData}>
                            <FiMinusSquare className="font-lg hiclasstv-coral-text" />
                        </div>
                }
                <QuestionDataInput
                    index={lQuestionDataIndex}
                    kind={lQuestionKind}
                    type={lQuestionDataType}
                    data={lQuestionData}
                    lAlertKind={lAlertKind}
                    lAlertMessage={lAlertMessage}
                    setAlertKind={setAlertKind}
                    setAlertMessage={setAlertMessage}
                    onChangeQuestionData={onChangeQuestionData}
                    onChangeQuestionDataType={onChangeQuestionDataType}
                />
                <span className="input-group-text border-0">Type.</span>
                <div className="select-wrapper" style={{ flex: '1 1 auto', width: 'fit-content' }}>
                    <select className="form-select" value={lQuestionKind} onChange={onChangeQuestionKind}>
                        {
                            stQuizItemType.map((info, index) => (
                                <option key={"question-kind-option-item-" + index} value={info}>
                                    {info}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div> */}
        </>
    );
}

export default QuizQuestionData;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';

import WebAddLocalSite from './WebAddLocalSite';
import WebLocalSiteList from './list/WebLocalSiteList';
import WebPagination from '../etc/WebPagination';

const WebLocalSiteConfig = ({ list_configLocalSite, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState((page - 1) * limit);
    const [blockNum, setBlockNum] = useState(0);

    const [isAddLocalSiteOK, setIsAddLocalSiteOK] = useState(false);

    useEffect(() => {
        handleSelectedFunc({ selectedFunc: "GET_LOCAL_SITE_LIST" });

        return () => {

        }
    }, []);

    useEffect(() => {
        let offset = (page - 1) * limit;
        setOffset(offset);
    }, [page, limit]);

    useEffect(() => {
        setIsAddLocalSiteOK(true);

        return () => {
            setPage(1);
            setOffset((page - 1) * limit);
        }
    }, [list_configLocalSite]);

    const onChangeIsAddLocalSiteOK = () => {
        setIsAddLocalSiteOK(!isAddLocalSiteOK);
    }

    return (
        <div className="web-main admin list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />{t("로컬_사이트", { ns: "admin" })}
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLocalSite">
                            <FaPlusCircle className="font-md mr-1" />{t("로컬_사이트_등록", { ns: "admin" })}
                        </button>
                    </div>
                </div>
                <UncontrolledCollapse toggler="#addLocalSite" defaultOpen={false}>
                    <WebAddLocalSite 
                        isAddLocalSiteOK={isAddLocalSiteOK}
                        onChangeIsAddLocalSiteOK={onChangeIsAddLocalSiteOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebPagination
                    total={list_configLocalSite.length <= 0 ? 1 : list_configLocalSite.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    blockNum={blockNum}
                    setBlockNum={setBlockNum}
                />
                <WebLocalSiteList
                    // list_localSite={list_configLocalSite}
                    list_localSite={list_configLocalSite.slice(offset, offset + limit)}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};

export default WebLocalSiteConfig;
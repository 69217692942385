import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

import { FaMailBulk } from 'react-icons/fa';

// import logo from '../../image/info/main/logo.png';

const Notice001 = ({ isModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    return (
        <Modal isOpen={isModalOpened} centered backdrop={false}>
            <div className="d-flex flex-column w-100 align-items-center" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
                <div className="d-flex justify-content-center align-items-center w-100 flex-wrap" style={{ margin: '2rem 0' }}>
                    <img src="/images/info/main/logo.png" style={{ width: '4.25rem', height: 'auto' }} alt="" />
                    <div className="d-flex flex-column justify-content-center mx-2">
                        <div className="d-flex flex-wrap justify-content-center" style={{ fontSize: '2.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span>{t("하이클래스_티브이", { ns: "common" })}</span>
                            <span className="hiclasstv-blue-text">Pentalk</span>
                        </div>
                        <div className="d-flex text-center">
                            <span className="d-flex" style={{ fontSize: '1rem', fontWeight: '400', lineHeight: 'normal' }}>
                                {t("인포_홈_설명_1", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-center" style={{ minWidth: '10%' }}>
                    <div className="d-flex justify-content-center align-items-center w-100 flex-wrap" style={{ margin: '2rem 0' }}>
                        <div className="d-flex text-center">
                            <span className="d-flex" style={{ fontSize: '1rem', fontWeight: '400', lineHeight: 'normal' }}>
                                {t("데모_시연_문구_1", { ns: "hiclasstv" })}
                            </span>
                        </div>
                        <div className="d-flex text-center">
                            <span className="d-flex" style={{ fontSize: '1rem', fontWeight: '400', lineHeight: 'normal' }}>
                                {t("데모_시연_문구_2", { ns: "hiclasstv" })}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center w-100 flex-wrap" style={{ margin: '2rem 0' }}>
                        <div className="d-flex text-center">
                            <FaMailBulk className="font-md hiclasstv-blue-text" aria-hidden="true" />
                            <span className="d-flex" style={{ margin: '0px 0px 0px 4px', fontSize: '1rem', fontWeight: '400', lineHeight: 'normal' }}>HiClassTV@easy.co.kr</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <button className="btn btn-danger btn-sm" type="button" onClick={() => handleSelectedFunc({ selectedFunc: "NO_SHOW" })}>
                            {t("오늘_하루_보지_않음", { ns: "hiclasstv" })}
                        </button>
                        <button className="btn btn-primary btn-sm" type="button" onClick={() => handleSelectedFunc({ selectedFunc: "CLOSE" })}>
                            {t("OK", { ns: "common" })}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Notice001;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FaFileImage } from 'react-icons/fa';

import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const WebAddSubDomain = ({ isCheckSubDomainOK, isAddSubDomainOK, alertMessage, alertKind, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [subDomainName, setSubDomainName] = useState("");
    const [imageData, setImageData] = useState({ image:null, filename:"" });

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        if (isAddSubDomainOK) {
            setSubDomainName("");
            setImageData({ image:null, filename:"" });
        }
    }, [isAddSubDomainOK]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            checkAddSubDomain();
        }
    }

    const onChangeSubDomainName = (e) => {
        if (isAddSubDomainOK) {
            handleSelectedFunc({selectedFunc:"CHANGE_ALERT_MESSAGE", kind:"subDomain-add", message:""});
        }

        if (isCheckSubDomainOK) {
            handleSelectedFunc({selectedFunc:"CHANGE_ALERT_MESSAGE", kind:"subDomain-check", message:"서브 도메인 이름이 변경되었습니다. 서브 도메인 중복체크를 다시 진행하세요."});
        }

        let str = checkInputValue(e.target.value);
        setSubDomainName(str);
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    const checkSubDomainName = () => {
        if (subDomainName === "" || subDomainName === undefined || subDomainName === null) {
            setAlertMessage(t("서브_도메인_이름_입력", { ns: "admin" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        } else {
            handleSelectedFunc({ selectedFunc: "CHECK_WEB_SUB_DOMAIN", subDomainName: subDomainName });
        }
    }

    const checkAddSubDomain = () => {
        if (isCheckSubDomainOK) { // 서브 도메인 중복체크 성공한 경우
            if (imageData.image !== undefined && imageData.image !== null && 
                imageData.filename !== undefined && imageData.filename !== null && imageData.filename !== "") {
                var blob = convertDataURIToBlob(imageData.image);
                handleSelectedFunc({ selectedFunc: "ADD_OK_WEB_SUB_DOMAIN", subDomainName: subDomainName, data:blob, filename:imageData.filename });
            } else {
                setAlertMessage(t("서브_도메인_로고_이미지_등록", { ns: "admin" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
        } else { // 서브 도메인 중복체크 하지 않은 경우
            if (subDomainName === "" || subDomainName === undefined || subDomainName === null) {
                setAlertMessage(t("서브_도메인_이름_입력", { ns: "admin" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                setAlertMessage(t("서브_도메인_이름_중복체크", { ns: "admin" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            }
        }
    }

    const handleChangeFile = (e) => {
        console.log('handleChangeFile : ', e.target.files);

        if (e.target.files !== undefined && e.target.files !== null) {
            if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
                if (e.target.files[0].size > 204800) {
                    setAlertMessage(t("서브_도메인_로고_이미지_사이즈", { ns: "admin" }));
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                } else {
                    if (e.target.files[0].name !== undefined && e.target.files[0].name !== null) {
                        let filename = e.target.files[0].name;
                        //console.log('filename selected => ', filename);
                        previewImage(e.target.files[0], filename);
                    } else {
                        //console.log("handleChangeFile 1 : cancel was pressed");
                        if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                            setAlertKind(ConstData.ALERT_KIND.NONE);
                            setAlertMessage("");
                        }
                    }
                }
            } else {
                //console.log("handleChangeFile 2 : cancel was pressed");
                if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                    setAlertKind(ConstData.ALERT_KIND.NONE);
                    setAlertMessage("");
                }
            }
        } else {
            //console.log("handleChangeFile 3 : cancel was pressed");
            if (lAlertKind === ConstData.ALERT_KIND.DANGER) {
                setAlertKind(ConstData.ALERT_KIND.NONE);
                setAlertMessage("");
            }
        }
    }

    const previewImage = (file, filename) => {
        if (file !== undefined && file !== null) {
            var reader = new FileReader();
            reader.onload = () => {
                //console.log('previewImage() onload called');
                //console.log('reader.result => ', reader.result);
                setImageData({ image:reader.result, filename:filename });
            };

            reader.readAsDataURL(file);
        }
    }

    const convertDataURIToBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        var bb = new Blob([ab], { "type" : mimeString });

        return bb;
    }

    return (
        <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
            <div className="card web-list-card border-primary">
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>{t("서브_도메인_이름", { ns: "admin" })}</b></span>
                    </div>
                    <div className="input-data">
                        <div className="d-flex input-group justify-content">
                            <input type="email" id="inputSubDomainName" value={subDomainName} className="d-flex flex-fill web-search-input mr-2" placeholder={t("서브_도메인_이름_입력", { ns: "admin" })} style={{width:'70%'}} onChange={onChangeSubDomainName} onKeyPress={handleKeyPress} />
                            <div className="d-flex" style={{ minWidth: 'max-content', verticalAlign: 'middle' }}>
                                <button type="button" className="d-flex btn btn-sm hiclasstv-blue white-text p-1 align-items-center m-0" style={{ width: 'max-content' }} onClick={checkSubDomainName} disabled={subDomainName !== "" ? false : true}>
                                    {t("중복체크", { ns: "common" })}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-area">
                    <div className="input-label font-md">
                        <span><b>{t("로고_이미지", { ns: "admin" })}</b></span>
                    </div>
                    <div className="input-data">
                        <label className="d-flex flex-column align-items-center justify-content-center w-100  m-0 button" htmlFor="logo_file" style={{ border: '1px solid grey', borderRadius:'0.5rem', height:'max-content', minHeight:'5.625rem' }}>
                            <span className="d-flex flex-column align-items-center">
                                {
                                    imageData.image !== null ?
                                        <img className="hiclasstv-image-preview" src={imageData.image} alt="" /> :
                                        <span className="hiclasstv-image-preview">
                                            <FaFileImage className="font-lg grey-text" aria-hidden="true" />
                                        </span>
                                }
                                <span className="hiclass-text font-xsm grey-text my-1 px-2">
                                    {imageData.image !== null ? imageData.filename : t("파일선택", { ns: "classTalk" })}
                                </span>
                            </span>
                        </label>
                        <input type="file" accept=".png" className="p-0" id="logo_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                    </div>
                </div>
                <Alerts kind={lAlertKind} message={lAlertMessage} />
                <div className="d-flex text-center mt-3">
                    <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-1" onClick={checkAddSubDomain} disabled={subDomainName !== "" ? false : true}>
                        <b>{t("생성", { ns: "common" })}</b>
                    </button>
                </div>
            </div>
        </div>
    );
}
export default WebAddSubDomain;
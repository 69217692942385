import React from "react";

import MainContainer from "../containers/MainContainer";
import WebContentTemplate from "../components/template/WebContentTemplate";
//import BroadcastLiveListContainer from "../containers/BroadcastLiveListContainer";
//import WatchingLiveListContainer from "../containers/WatchingLiveListContainer";
import LiveListContainer from "../containers/LiveListContainer";

const WebLiveListPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <LiveListContainer 
                    routeProps={routeProps}
                    browserType={browserType}
                    currentPageType={currentPageType}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebLiveListPage;
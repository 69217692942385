import React from "react";
import { useTranslation } from "react-i18next";

import { enMQType } from "../../../lib/classTalk/CodeEnum";

const FileShareMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    const { t } = useTranslation();

    const getBtnText = (type) => {
        switch (type) {
            case enMQType.Video:
                return t("비디오", { ns: "classTalk" });
    
            case enMQType.Audio:
                return t("오디오", { ns: "classTalk" });
    
            case enMQType.InkMedia:
                return t("잉크미디어", { ns: "classTalk" });
    
            default:
                return t("열기", { ns: "common" });
        }
    }

    return (
        <div className="d-flex align-items-center w-100" style={{ paddingTop: '0.25rem', paddingLeft: '0.25rem' }}>
            {
                msgInfo.isDownloading ?
                    <>
                        <progress value={msgInfo.progress} max="100" />
                        <span style={{ fontSize: '0.6rem' }}>&nbsp;{msgInfo.progress + "%"}</span>
                    </> :
                    msgInfo.isDownloaded ?
                        <button type="button" className="btn btn-sm mx-1 px-2 py-1 hiclasstv-blue" style={{ borderRadius: '0.5rem', color: 'white' }} onClick={() => handleSelectUrl("file_open", msgInfo.urlInfo, indexKey, msgInfo.type)}>
                            {getBtnText(msgInfo.type)}
                        </button> :
                        <button type="button" className="btn btn-sm mx-1 px-2 py-1 yellow" style={{ borderRadius: '0.5rem' }} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo, indexKey)}>
                            {t("다운로드", { ns: "info" })}
                        </button>
            }
        </div>
    );
}

export default FileShareMessage;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MDBNavItem } from "mdbreact";

import { enPassTap } from "../../../lib/ConstCommand";

const WebHeaderPassMenu = ({ isMobile, isPassPage, hiclasstvInfo, tossAndHandleSelectedFunc }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (isMobile) {
            let navbar = document.getElementById("navbar");
            let totalWidth = navbar.offsetWidth;
            let baseWidth = navbar.parentElement.offsetWidth;

            let offset = -1;

            if (isPassPage) {
                switch (hiclasstvInfo.currentPassTap) {
                    case enPassTap.PRDT_LIST:
                        offset = document.getElementById("tool_pass").offsetLeft;
                        break;

                    case enPassTap.PCHS_LIST:
                        offset = document.getElementById("tool_pass_pchs").offsetLeft;
                        break;

                    default:
                        break;
                }
            } else {
                offset = offset = document.getElementById("tool_pass").offsetLeft;
            }

            let position = -1;

            if (totalWidth - offset < baseWidth) {
                position = totalWidth - baseWidth;
            } else {
                position = offset - 12;
            }

            navbar.parentElement.scrollTo(position, 0);
        }
    }, [isMobile, isPassPage, hiclasstvInfo]);

    return (
        isMobile === true ?
            <>
                { // 이니시스 서비스 당분간 사용하지 않으므로 주석 처리 ... by hjkim 20240722
                /* <li className="nav_item">
                    <span className={((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PRDT_LIST) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PRDT_LIST")}>{t("이용권_구매", { ns: "pass" })}</span>
                </li> */}
                <li className="nav_item" id="tool_pass_pchs">
                    <span className={((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PCHS_LIST) ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PCHS_LIST")}>{t("내_이용권_조회", { ns: "pass" })}</span>
                </li>
            </> :
            <>
                { // 이니시스 서비스 당분간 사용하지 않으므로 주석 처리 ... by hjkim 20240722
                /* <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PRDT_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PRDT_LIST")}>
                        <b>{t("이용권_구매", { ns: "pass" })}</b>
                    </button>
                </MDBNavItem> */}
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.PCHS_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_PCHS_LIST")}>
                        <b>{t("내_이용권_조회", { ns: "pass" })}</b>
                    </button>
                </MDBNavItem>
                <MDBNavItem className="mx-2">
                    <button className={"hiclasstv-btn-transparent my-0" + ((isPassPage && hiclasstvInfo.currentPassTap === enPassTap.REFUND_LIST) ? " hiclasstv-darkblue-text" : " white-text")} style={{ width: 'max-content' }} onClick={() => tossAndHandleSelectedFunc("SHOW_PASS_REFUND_LIST")}>
                        <b>{t("이용권_환불", { ns: "pass" })}</b>
                    </button>
                </MDBNavItem>
            </>
    );
}

export default WebHeaderPassMenu;
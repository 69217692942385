import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";

import CenteredModal from '../template/modal/CenteredModal';
import SelectStudio from './modal/SelectStudio';
import AlertStudio from './modal/AlertStudio';

const getCount = (list_array) => {
    return list_array.length;
};

const StudioList = ({ list_studio, list_live, userSeq, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [selectedStudio, setSelectedStudio] = useState(null);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isAlertModalOpened, setIsAlertModalOpened] = useState(false);

    const handleRequestStudio = useCallback(() => {
        console.log('handleRequestStudio : ', isModalOpened, selectedStudio, list_studio);
        if (isModalOpened) {
            if (selectedStudio !== null) {
                const studio_info = list_studio.find(info => info.studioSeq === selectedStudio.studioSeq);
                if (studio_info !== undefined && studio_info !== null) {
                    if (studio_info.assignedUserSeq === -1) {
                        if (studio_info.list_display !== undefined && studio_info.list_display !== null) {
                            handleSelectedFunc({ selectedFunc: "REQUEST_CON", studioSeq: studio_info.studioSeq });
                        }
                    }
                }
            }
        }
    }, [isModalOpened, selectedStudio, list_studio]);

    useEffect(() => {
        handleRequestStudio();
    }, [handleRequestStudio]);

    const handleFunc = ({ selectedFunc, studio_info }) => {
        console.log('handleFunc - ', selectedFunc, studio_info);
        switch (selectedFunc) {
            case "CANCEL_STUDIO_CON" :
                handleSelectedFunc({ selectedFunc: "CANCEL_REQUEST", studioSeq: studio_info.studioSeq });
                setSelectedStudio(null);
                setIsModalOpened(false);
                break;
            
            case "STUDIO_CON" :
                if (studio_info.assignedUserSeq === -1) {
                    setSelectedStudio(studio_info);
                    setIsModalOpened(true);
                } else {
                    setSelectedStudio(studio_info);
                    setIsAlertModalOpened(true);
                    //alert('다른 사용자가 사용 중인 스튜디오입니다. ' + studio_info.assignedUserSeq);
                }
                break;

            case "CONFIRM_ALERT" :
                setSelectedStudio(null);
                setIsAlertModalOpened(false);
                break;

            case "DIS_CON_STUDIO" :
                handleSelectedFunc({ selectedFunc: "CLEAR_ASSIGN", studioSeq: studio_info.studioSeq });
                setSelectedStudio(null);
                setIsAlertModalOpened(false);
                break;
            
            default :
                console.log('handleFunc() - selectedFunc:', selectedFunc);
                break;
        }
    };

    return (
        list_studio.length > 0 ? 
            <>
                {
                    isModalOpened &&
                    <CenteredModal modalClose={() => setIsModalOpened(false)}>
                        <SelectStudio
                            studio_info={selectedStudio}
                            handleFunc={handleFunc}
                        />
                    </CenteredModal>
                }
                {
                    isAlertModalOpened &&
                    <CenteredModal modalClose={() => setIsAlertModalOpened(false)}>
                        <AlertStudio 
                            userSeq={userSeq}
                            studio_info={selectedStudio}
                            list_live={list_live}
                            handleFunc={handleFunc}
                        />
                    </CenteredModal>
                }
                <div className="list-group" style={{ minHeight:'10%', height: '50%', maxHeight: 'max-content', overflow: 'auto' }}>
                    <div className="title-area sub white-text" style={{ backgroundColor: '#3A3A3A' }}>
                        <b>{t("스튜디오_목록", { ns: "studio" })}</b>&nbsp;({getCount(list_studio)})
                    </div>
                    <div className="list-group scroll-show-active light" style={{ overflow: 'auto' }}>
                        {
                            list_studio.map(studio => (
                                <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={'studio_' + studio.studioSeq} onClick={() => handleFunc({ selectedFunc: "STUDIO_CON", studio_info: studio })} >
                                    <div className="text-area flex-column align-items-start">
                                        <div className="text-area justify-content-start">
                                            <span className="hiclass-text" title={studio.studioName}><b>{studio.studioName}</b></span>
                                            <span className="hiclass-text hiclasstv-deepDarkgrey-text">&nbsp;({getCount(studio.list_display)})</span>
                                        </div>
                                        <div className="hiclass-text hiclasstv-darkgrey-text small">
                                            {getCount(studio.list_display) + ' Display'}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/* <div className="title-area sub hiclasstv-blue white-text">
                        <b>클래스 목록</b>
                    </div> */}
                </div>
            </>:
        <></>
    );
};

export default StudioList;
import React from 'react';
import { useTranslation } from "react-i18next";

const ServicePolicy = () => {
    const { t } = useTranslation();

    return (
        <span>
            <p className="last-p"><b>{t("이용약관_0_설명_1", { ns: "policy" })}</b></p>
            <p className="last-p"><b>{t("이용약관_0_설명_2", { ns: "policy" })}</b></p>
            <p className="last-p">{t("이용약관_0_설명_3", { ns: "policy" })}</p>
            <p className="last-p"><b>{t("이용약관_0_설명_4", { ns: "policy" })}</b> {t("이용약관_0_설명_5", { ns: "policy" })}</p>

            <h3>1. {t("이용약관_1_정의", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_1_정의_1", { ns: "policy" })}<a href="https://HiClass.tv" target="_blank">https://HiClass.tv</a>{t("이용약관_1_정의_2", { ns: "policy" })}<br />
                <b>{t("이용약관_1_정의_3", { ns: "policy" })}</b>{t("이용약관_1_정의_4", { ns: "policy" })}<br />
                <b>{t("이용약관_1_정의_5", { ns: "policy" })}</b>{t("이용약관_1_정의_6", { ns: "policy" })}<br />
                <b>{t("이용약관_1_정의_7", { ns: "policy" })}</b>{t("이용약관_1_정의_8", { ns: "policy" })}<br />
                <b>{t("이용약관_1_정의_9", { ns: "policy" })}</b>{t("이용약관_1_정의_11", { ns: "policy" })}<br />
                <b>{t("이용약관_1_정의_12", { ns: "policy" })}</b>{t("이용약관_1_정의_13", { ns: "policy" })}</p>

            <h3>2. {t("이용약관_2_서비스", { ns: "policy" })}</h3>
            <p>{t("이용약관_2_서비스_1", { ns: "policy" })}</p>
            <ul className="last-p">
                <li>
                    <span className="sub-title">a. {t("이용약관_2_서비스_2", { ns: "policy" })}</span><br />
                    {t("이용약관_2_서비스_3", { ns: "policy" })}
                </li>
            </ul>

            <h3>3. {t("이용약관_3_서비스_사용_및_회원의_책임", { ns: "policy" })}</h3>
            <p>{t("이용약관_3_서비스_사용_및_회원의_책임_1", { ns: "policy" })}</p>
            <ul className="last-p">
                <li>
                    <span className="sub-title">a. {t("이용약관_3_서비스_사용_및_회원의_책임_2", { ns: "policy" })}</span><br />
                    {t("이용약관_3_서비스_사용_및_회원의_책임_3", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">b. {t("이용약관_3_서비스_사용_및_회원의_책임_4", { ns: "policy" })}</span><br />
                    {t("이용약관_3_서비스_사용_및_회원의_책임_5", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">c. {t("이용약관_3_서비스_사용_및_회원의_책임_6", { ns: "policy" })}</span><br />
                    {t("이용약관_3_서비스_사용_및_회원의_책임_7", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">d. {t("이용약관_3_서비스_사용_및_회원의_책임_8", { ns: "policy" })}</span><br />
                    {t("이용약관_3_서비스_사용_및_회원의_책임_9", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">e. {t("이용약관_3_서비스_사용_및_회원의_책임_10", { ns: "policy" })}</span><br />
                    {t("이용약관_3_서비스_사용_및_회원의_책임_11", { ns: "policy" })}
                </li>
            </ul>

            <h3>4. {t("이용약관_4_최종_사용자에_대한_책임", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_4_최종_사용자에_대한_책임_1", { ns: "policy" })}</p>

            <h3>5. {t("이용약관_5_콘텐츠에_대한_HiClassTV의_의무", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_5_콘텐츠에_대한_HiClassTV의_의무_1", { ns: "policy" })}</p>

            <h3>6. {t("이용약관_6_요금_및_취소", { ns: "policy" })}</h3>
            <p><b>{t("이용약관_6_요금_및_취소_1", { ns: "policy" })}</b>{t("이용약관_6_요금_및_취소_2", { ns: "policy" })}</p>
            <p className="last-p"><b>{t("이용약관_6_요금_및_취소_3", { ns: "policy" })}</b>{t("이용약관_6_요금_및_취소_4", { ns: "policy" })}</p>

            <h3>7. {t("이용약관_7_해지", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_7_해지_1", { ns: "policy" })}</p>

            <h3>8. {t("이용약관_8_소유권", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_8_소유권_1", { ns: "policy" })}</p>

            <h3>9. {t("이용약관_9_저작권", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_9_저작권_1", { ns: "policy" })}</p>

            <h3>10. {t("이용약관_10_보증_금지", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_10_보증_금지_1", { ns: "policy" })}</p>

            <h3>11. {t("이용약관_11_배상", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_11_배상_1", { ns: "policy" })}</p>

            <h3>12. {t("이용약관_12_책임의_제한", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_12_책임의_제한_1", { ns: "policy" })}</p>

            <h3>13. {t("이용약관_13_중재", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_13_중재_1", { ns: "policy" })}</p>

            <h3>14. {t("이용약관_14_개인정보보호", { ns: "policy" })}</h3>
            <p className="last-p">{t("이용약관_14_개인정보보호_1", { ns: "policy" })}</p>

            <h3>15. {t("이용약관_15_기타사항", { ns: "policy" })}</h3>
            <ul>
                <li>
                    <span className="sub-title">15.1 {t("이용약관_15_기타사항_1", { ns: "policy" })}</span><br />
                    {t("이용약관_15_기타사항_2", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">15.2 {t("이용약관_15_기타사항_3", { ns: "policy" })}</span><br />
                    {t("이용약관_15_기타사항_4", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">15.3 {t("이용약관_15_기타사항_5", { ns: "policy" })}</span><br />
                    {t("이용약관_15_기타사항_6", { ns: "policy" })}
                </li>
                <li>
                    <span className="sub-title">15.4 {t("이용약관_15_기타사항_7", { ns: "policy" })}</span><br />
                    {t("이용약관_15_기타사항_8", { ns: "policy" })}
                </li>
            </ul>
        </span>
    )
}

export default ServicePolicy;
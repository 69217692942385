import React from 'react';
import { useTranslation } from "react-i18next";
import { BsX } from 'react-icons/bs';

import CustomProfileImage from '../../user/profile/CustomProfileImage';

const RemoveLiveMember = ({ selectedLiveMemberInfo, handleSelectedConfigFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" onClick={() => handleSelectedConfigFunc({ selectedFunc: "MEMBER_REMOVE_TOGGLE" })}>
                    <BsX />
                </span>
            </div>
            {
                selectedLiveMemberInfo.entryYN === "Y" ?
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        {t("클래스_멤버_삭제_안내_문구_2", { ns: "live" })}<br />
                        {t("클래스_멤버_삭제_안내_문구_3", { ns: "live" })}<br />
                        {t("클래스_멤버_삭제_안내_문구_4", { ns: "live" })}
                    </div> :
                    <div className="d-flex text-center my-2 dark-grey-text small">
                        {t("클래스_멤버_삭제_안내_문구_3", { ns: "live" })}<br />
                        {t("클래스_멤버_삭제_안내_문구_4", { ns: "live" })}
                    </div>
            }
            <div className="content-area dark-grey-text my-0">
                <span className="small mb-1">[{t("삭제할_멤버", { ns: "chat" })}]</span>
                <div className="text-area text-center area-80">
                    <div className="area-15 fit-content">
                        <CustomProfileImage url={selectedLiveMemberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={selectedLiveMemberInfo.userNickname} />
                    </div>
                    <span className="hiclass-text" title={selectedLiveMemberInfo.userNickname}><b>{selectedLiveMemberInfo.userNickname}</b></span>
                </div>                
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc:"MEMBER_REMOVE", liveSeq:selectedLiveMemberInfo.liveSeq, memberSeq:selectedLiveMemberInfo.userSeq })}>{t("예", { ns: "common" })}</button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" onClick={() => handleSelectedConfigFunc({ selectedFunc:"MEMBER_REMOVE_TOGGLE"})}>{t("아니오", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default RemoveLiveMember;
import React, { useState, useEffect, useRef } from "react";
import { FcPicture, FcMusic, FcStart } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { useTranslation } from "react-i18next";

import { stQuizDataKind, stQuizDataType, stQuizFileUploadActionKind } from "../../../lib/ConstCommand";

import ConstData from "../../../lib/ConstData";

const getCurrentFilePath = (filePath) => {
    if (filePath.startsWith("quiz/")) {
        return filePath;
    } else {
        console.log(filePath);
        if (filePath.startsWith("https://") || filePath.startsWith("http://")) {
            let indexOfFirst = filePath.indexOf("quiz/");
            console.log("The index of the first quiz/ from the beginning is ", indexOfFirst);
            let newFilePath = filePath.slice(indexOfFirst);
            console.log("newFilePath => ", newFilePath);
            return newFilePath;
        } else {
            console.log("이런 경우가 있나..?ㅁ? filePath => ", filePath);
            return filePath;
        }
    }
}

const QuestionDataInput = ({ index, kind, type, data, uploadUrlYN, lQuestionUploadInfo, lAlertKind, lAlertMessage, setAlertKind, setAlertMessage, onChangeQuestionData, onChangeQuestionDataType, handleUploadPath, handleQuestionFileUpload }) => {
    const { t } = useTranslation();

    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [lSelectedFileInfo, setSelectedFile] = useState({
        fileName: "", fileExt: "", fileSize: "", filePath: ""
    });
    /* const [lAlertKind, setAlertKind] = useState();
    const [lAlertMessage, setAlertMessage] = useState(""); */

    const fileInputRef = useRef(null);

    const maximumFileSize = 1572864000;

    useEffect(() => {
        return () => {
            if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.value = null;
                fileInputRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (lQuestionUploadInfo !== undefined && lQuestionUploadInfo !== null) {
            if (lQuestionUploadInfo.kind === "upload") {
                setIsUploadSuccess(true);
                setSelectedFile(info => ({
                    ...info,
                    filePath: lQuestionUploadInfo.path
                }));

                handleUploadPath(lQuestionUploadInfo.path);
            } else if (lQuestionUploadInfo.kind === "remove") {
                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = null;
                }

                setSelectedFile({
                    fileName: "",
                    fileExt: "",
                    fileSize: "",
                    filePath: ""
                });
                //onChangeQuestionData("");
                handleUploadPath("");
            }
        }
    }, [lQuestionUploadInfo]);

    useEffect(() => {
        if (data instanceof File) {
            let filename = data.name;
            let filesize = data.size;

            setSelectedFile({
                fileName: filename,
                fileExt: "",
                fileSize: filesize,
                filePath: ""
            });
        } else {
            if (uploadUrlYN === "Y") {
                let arr = data.split("/");
                let arrLength = arr.length;
                let fileName = arr[arrLength - 1];
                //console.log(fileName);

                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = null;
                }

                setSelectedFile({
                    fileName,
                    fileExt: "",
                    fileSize: "",
                    filePath: data
                });
            } else {
                if (fileInputRef && fileInputRef.current) {
                    fileInputRef.current.value = null;
                }

                setSelectedFile({
                    fileName: "",
                    fileExt: "",
                    fileSize: "",
                    filePath: ""
                });
            }
        }
    }, [index, kind, type, data, uploadUrlYN]);

    const convertFileSize = (fileSize) => {
        if (fileSize < 1024) { // (1KB==1,024B)

        } else if (fileSize < (1024 * 1024)) { // (1MB==1,024KB==1,048,576B)

        }
    }

    const handleChangeFile = (file, filename, filesize) => {
        if (file) {
            /* let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setSelectedFile({
                    fileName: filename,
                    fileExt: "",
                    fileSize: filesize,
                    filePath: ""
                });

                onChangeQuestionData(reader.result);
            } */

            setSelectedFile({
                fileName: filename,
                fileExt: "",
                fileSize: filesize,
                filePath: ""
            });

            onChangeQuestionData(file);
            handleQuestionFileUpload("UPLOAD_QUIZ_FILE", { seq: index, kind: stQuizFileUploadActionKind.QuestionFileUpload, file, filename });
        }
    }

    const onChangeSelectedFileInfo = (e) => {
        if (e.target.files && e.target.files[0]) {
            if (e.target.files[0].size > maximumFileSize) {
                setAlertMessage(t("파일_업로드_크기_알림_메시지", { ns: "classTalk" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);
            } else {
                if (e.target.files[0].name) {
                    let filename = e.target.files[0].name;
                    let filesize = e.target.files[0].size;
                    handleChangeFile(e.target.files[0], filename, filesize);
                } else {
                    if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
                        setAlertKind(ConstData.ALERT_KIND.NONE);
                        setAlertMessage("");
                    }
                }
            }
        }
    }

    const onClickRemoveSelectedFile = () => {
        /* if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = null;
        }

        setSelectedFile({
            fileName: "",
            fileExt: "",
            fileSize: "",
            filePath: ""
        });

        onChangeQuestionData(""); */

        let filePath = getCurrentFilePath(lSelectedFileInfo.filePath);
        handleQuestionFileUpload("REMOVE_QUIZ_FILE", { seq: index, kind: stQuizFileUploadActionKind.RemoveQuestionFile, queryKind: "question", filePath });
    }

    switch (kind) {
        case stQuizDataKind.Text:
            return <input type="text" className="form-control" value={data} onChange={onChangeQuestionData} />;

        case stQuizDataKind.Image:
            return (
                type !== stQuizDataType.File ?
                    uploadUrlYN === "N" ?
                        <input type="text" className="form-control" value={data} onChange={onChangeQuestionData} /> :
                        <>
                            <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                            </span>
                            <label className="file-input-label">
                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                    <FcPicture className="font-ml plus" aria-hidden="true" />
                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                </span>
                            </label>
                        </> :
                    <>
                        {
                            data === "" ?
                                <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                    <span className="hiclass-text" /* className="input-group-text border-0" */>
                                        <MdAttachFile className="font-md plus grey-text" style={{ transform: 'rotate(45deg)' }} aria-hidden="true" />
                                        <span className="font-xsm grey-text ml-1" title={t("파일선택", { ns: "classTalk" })}>
                                            {t("파일선택", { ns: "classTalk" })}
                                        </span>
                                    </span>
                                </label> :
                                <>
                                    <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                        <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                                    </span>
                                    <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                        {
                                            isUploadSuccess ?
                                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                                    <FcPicture className="font-ml plus" aria-hidden="true" />
                                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                                </span> :
                                                <span className="hiclass-text">
                                                    <span className="font-xsm grey-text ml-1" title={t("파일_업로드_중", { ns: "classTalk" })}>
                                                        {t("파일_업로드_중", { ns: "classTalk" })}
                                                    </span>
                                                </span>
                                        }
                                        {/* <span className="input-group-text border-0">
                                            {lSelectedFileInfo.fileSize}
                                        </span> */}
                                    </label>
                                </>
                        }
                        <input
                            id={"question-file-input-" + index}
                            ref={fileInputRef}
                            type="file"
                            accept=".jpg, .jpeg, .gif, .png, .bmp, .svg" /* accept="image/*" */
                            className="form-control"
                            style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }}
                            onChange={onChangeSelectedFileInfo}
                        />
                    </>
            );

        case stQuizDataKind.Audio:
            return (
                type !== stQuizDataType.File ?
                    uploadUrlYN === "N" ?
                        <input type="text" className="form-control" value={data} onChange={onChangeQuestionData} /> :
                        <>
                            <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                            </span>
                            <label className="file-input-label">
                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                    <FcMusic className="font-ml plus" aria-hidden="true" />
                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                </span>
                            </label>
                        </> :
                    <>
                        {
                            data === "" ?
                                <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                    <span className="hiclass-text" /* className="input-group-text border-0" */>
                                        <MdAttachFile className="font-md plus grey-text" style={{ transform: 'rotate(45deg)' }} aria-hidden="true" />
                                        <span className="font-xsm grey-text ml-1" title={t("파일선택", { ns: "classTalk" })}>
                                            {t("파일선택", { ns: "classTalk" })}
                                        </span>
                                    </span>
                                </label> :
                                <>
                                    <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                        <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                                    </span>
                                    <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                        {
                                            isUploadSuccess ?
                                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                                    <FcMusic className="font-ml plus" aria-hidden="true" />
                                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                                </span> :
                                                <span className="hiclass-text">
                                                    <span className="font-xsm grey-text ml-1" title={t("파일_업로드_중", { ns: "classTalk" })}>
                                                        {t("파일_업로드_중", { ns: "classTalk" })}
                                                    </span>
                                                </span>
                                        }
                                        {/* <span className="input-group-text border-0">
                                            {lSelectedFileInfo.fileSize}
                                        </span> */}
                                    </label>
                                </>
                        }
                        <input
                            id={"question-file-input-" + index}
                            ref={fileInputRef}
                            type="file"
                            accept=".mp3, .wav, .wma, .ogg" /* accept="audio/*" */
                            className="form-control"
                            style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }}
                            onChange={onChangeSelectedFileInfo}
                        />
                    </>
            );

        case stQuizDataKind.Video:
            return (
                type !== stQuizDataType.File ?
                    uploadUrlYN === "N" ?
                        <input type="text" className="form-control" value={data} onChange={onChangeQuestionData} /> :
                        <>
                            <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                            </span>
                            <label className="file-input-label">
                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                    {/* <MdOutlineMusicVideo className="font-ml plus hiclasstv-coral-text" aria-hidden="true" /> */}
                                    <FcStart className="font-ml plus" aria-hidden="true" />
                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                </span>
                            </label>
                        </> :
                    <>
                        {
                            data === "" ?
                                <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                    <span className="hiclass-text" /* className="input-group-text border-0" */>
                                        <MdAttachFile className="font-md plus grey-text" style={{ transform: 'rotate(45deg)' }} aria-hidden="true" />
                                        <span className="font-xsm grey-text ml-1" title={t("파일선택", { ns: "classTalk" })}>
                                            {t("파일선택", { ns: "classTalk" })}
                                        </span>
                                    </span>
                                </label> :
                                <>
                                    <span className="input-group-text" style={{ borderRight: 'none' }} title={t("파일삭제", { ns: "classTalk" })} onClick={onClickRemoveSelectedFile}>
                                        <IoMdClose className="font-md plus grey-text" aria-hidden="true" />
                                    </span>
                                    <label className="file-input-label" htmlFor={"question-file-input-" + index}>
                                        {
                                            isUploadSuccess ?
                                                <span className="hiclass-text" /* className="input-group-text border-0" */>
                                                    {/* <MdOutlineMusicVideo className="font-ml plus hiclasstv-coral-text" aria-hidden="true" /> */}
                                                    <FcStart className="font-ml plus" aria-hidden="true" />
                                                    <span className="font-xsm grey-text ml-1">{lSelectedFileInfo.fileName}</span>
                                                </span> :
                                                <span className="hiclass-text">
                                                    <span className="font-xsm grey-text ml-1" title={t("파일_업로드_중", { ns: "classTalk" })}>
                                                        {t("파일_업로드_중", { ns: "classTalk" })}
                                                    </span>
                                                </span>
                                        }
                                        {/* <span className="input-group-text border-0">
                                            {lSelectedFileInfo.fileSize}
                                        </span> */}
                                    </label>
                                </>
                        }
                        <input
                            id={"question-file-input-" + index}
                            ref={fileInputRef}
                            type="file"
                            accept=".mp4, .wmv, .mov, .avi" /* accept="video/*" */
                            className="form-control"
                            style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }}
                            onChange={onChangeSelectedFileInfo}
                        />
                    </>
            );

        default:
            return <input type="text" className="form-control" value={data} onChange={onChangeQuestionData} />;
    }
}

export default QuestionDataInput;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { enManagePage, enWebPage } from "../../../lib/ConstCommand";
import ConstData from "../../../lib/ConstData";

import WebHeaderPassMenu from "./WebHeaderPassMenu";
import WebHeaderInfoMenu from "./WebHeaderInfoMenu";
import WebHeaderManageMenu from "./WebHeaderManageMenu";

const WebHeaderMenu = ({ isMobile, isStandardType, isHiClassDomain, isMediasoupAdmin, isConfigAdmin, isCreateAdmin, isLoginYn, hiclasstvInfo, currentPageType, currentManagePageType, tossSelectedFunc, goToAloneChatting }) => {
    const { t } = useTranslation();
    
    let history = useHistory();

    const [isPassMenuOpened, setIsPassMenuOpened] = useState(currentPageType === enWebPage.Pass ? true : false);
    const [isInfoMenuOpened, setIsInfoMenuOpened] = useState(currentPageType === enWebPage.Info ? true : false);
    const [isManageMenuOpened, setIsManageMenuOpened] = useState(currentPageType === enWebPage.Manage ? true : false);

    const onClickMenu = (kind) => {
        console.log("WebHeaderMenu onClickMenu - ", kind);
        if (kind === "pass") {
            setIsPassMenuOpened(!isPassMenuOpened);
            setIsInfoMenuOpened(false);
            setIsManageMenuOpened(false);
        } else if (kind === "info") {
            setIsInfoMenuOpened(!isInfoMenuOpened);
            setIsPassMenuOpened(false);
            setIsManageMenuOpened(false);
        } else if (kind === "manage") {
            setIsManageMenuOpened(!isManageMenuOpened);
            setIsPassMenuOpened(false);
            setIsInfoMenuOpened(false);
        }
    }

    const tossPassMenuSelectedFunc = (selectedFunc) => {
        tossSelectedFunc(selectedFunc);
        if (currentPageType !== enWebPage.Pass) {
            history.push("/pass");
        }
    }

    const tossInfoMenuSelectedFunc = (selectedFunc) => {
        tossSelectedFunc(selectedFunc);
        if (currentPageType !== enWebPage.Info) {
            history.push("/info");
        }
    }

    const tossManageMenuSelectedFunc = (selectedFunc) => {
        console.log("tossManageMenuSelectedFunc - ", selectedFunc);
        tossSelectedFunc(selectedFunc);
        if (currentPageType !== enWebPage.Manage || currentManagePageType !== enManagePage.SubDomainInfo) {
            history.push("/admin");
        }
    }

    return (
        <>
            <li className="nav_item" id="tool_main">
                <span className={(currentPageType === enWebPage.Home ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/main") }}>{t("MAIN", { ns: "hiclasstv" })}</span>
            </li>
            { // ** 임시 ** 현재 사용 안 하므로 주석 처리 (for GS인증 시험) by hjkim 20230912
                /* <li className="nav_item" id="tool_friend">
                    <span className={(currentPageType === enWebPage.Friend ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/list_friend") }}>FRIEND</span>
                </li> */
            }
            <li className="nav_item">
                <span className={(currentPageType === enWebPage.LiveClassTalk ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => goToAloneChatting()}>{t("CHATTING", { ns: "chat" })}</span>
            </li>
            <li className="nav_item" id="tool_live">
                <span className={(currentPageType === enWebPage.Live ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/list_live") }}>{t("CLASS", { ns: "live" })}</span>
            </li>
            { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                /* <li className="nav_item" id="tool_studio">
                    <span className={(currentPageType === enWebPage.Studio ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/list_studio") }}>{t("SUTDIO", { ns: "studio" })}</span>
                </li> */
            }
            { // ** 임시 ** 현재 사용 안 하므로 주석 처리 by ykhan 20201210 
                /* !ConstData.IS_LOCAL_VERSION && isStandardType &&
                <li className="nav_item" id="tool_promotion">
                    <span className={(currentPageType === enWebPage.Promotion ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/promotion") }}>{t("PROMOTION", { ns: "promotion" })}</span>
                </li> */
            }
            {
                (isHiClassDomain || isConfigAdmin || isCreateAdmin) &&
                <>
                    <li className="nav_item" id="tool_pass">
                        <span className={(currentPageType === enWebPage.Pass ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => onClickMenu("pass")}>{t("PASS", { ns: "pass" })}</span>
                    </li>
                    {
                        isPassMenuOpened &&
                        <div className="d-flex menu-category">
                            <WebHeaderPassMenu
                                isMobile={isMobile}
                                isPassPage={currentPageType === enWebPage.Pass}
                                isLoginYn={isLoginYn}
                                hiclasstvInfo={hiclasstvInfo}
                                tossAndHandleSelectedFunc={tossPassMenuSelectedFunc}
                            />
                        </div>
                    }
                </>
            }
            <li className="nav_item" id="tool_download">
                <span className={(currentPageType === enWebPage.Download ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => { history.push("/download") }}>{t("DOWNLOAD", { ns: "info" })}</span>
            </li>
            <li className="nav_item" id="tool_info">
                <span className={(currentPageType === enWebPage.Info ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => onClickMenu("info")}>{t("INFO", { ns: "info" })}</span>
            </li>
            {
                isInfoMenuOpened &&
                <div className="d-flex menu-category">
                    <WebHeaderInfoMenu
                        isMobile={isMobile}
                        isInfoPage={currentPageType === enWebPage.Info}
                        isLoginYn={isLoginYn}
                        hiclasstvInfo={hiclasstvInfo}
                        tossAndHandleSelectedFunc={tossInfoMenuSelectedFunc}
                    />
                </div>
            } {
                (isConfigAdmin || isCreateAdmin) &&
                <>
                    <li className="nav_item" id="tool_manage">
                        <span className={(currentPageType === enWebPage.Manage ? "hiclasstv-darkblue-text" : "white-text")} onClick={() => onClickMenu("manage")}>{t("MANAGE", { ns: "admin" })}</span>
                    </li>
                    {
                        isManageMenuOpened &&
                        <div className="d-flex menu-category">
                            <WebHeaderManageMenu
                                isMobile={isMobile}
                                isAdminPage={currentPageType === enWebPage.Manage}
                                isConfigAdmin={isConfigAdmin}
                                isHiClassDomain={isHiClassDomain}
                                isMediasoupAdmin={isMediasoupAdmin}
                                hiclasstvInfo={hiclasstvInfo}
                                tossAndHandleSelectedFunc={tossManageMenuSelectedFunc}
                            />
                        </div>
                    }
                </>
            }
        </>
    );
}

export default WebHeaderMenu;
import React from "react";
import MainTemplate from "../components/template/MainTemplate";
import MainContainer from "../containers/MainContainer";
import FriendListContainer from "../containers/FriendListContainer"
import ContentTemplate from "../components/template/ContentTemplate";

const FriendListPage = ({ cookies, browserType, selectMainTap, routeProps }) => {
    return (
        <MainTemplate>
            <MainContainer 
                browserType={browserType} 
                cookies={cookies} 
                routeProps={routeProps}
            >
                <ContentTemplate selectMainTap={selectMainTap}>
                    <FriendListContainer 
                        routeProps={routeProps}
                        browserType={browserType} 
                    />
                </ContentTemplate>
            </MainContainer>
        </MainTemplate>
    );
};

export default FriendListPage;
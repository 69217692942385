import React, { Component } from "react";
import { connect } from "react-redux";

import PerformLiveContainer from "./live/PerformLiveContainer";

import LiveAlertModal from "../components/live/modal/LiveAlertModal";
import LiveAlertModalFinish from "../components/live/modal/LiveAlertModalFinish";
import LiveGuestAlertModal from "../components/live/modal/LiveGuestAlertModal";
import LiveMediaAlertModal from "../components/live/modal/LiveMediaAlertModal";
import LiveMixedAlertModal from "../components/live/modal/LiveMixedAlertModal";
import LivePullScreenAlertModal from "../components/live/modal/LivePullScreenAlertModal";

import {
    changeMyCamViewInput,
    changeHandsUpVideoInput,
    changeImageSize
} from "../lib/HandsUpClient";

import {
    closeLiveAlertModal,
    toggleLiveGuestAlertModal,
    toggleLiveMixedAlertModal,
    toggleLivePullScreenAlert,
} from "../modules/live";

import {
    toggle_classTalk
} from "../modules/classTalkLive";

class LiveContainer extends Component {

    state = {
        kind                        : "",
        mediaDevice_info            : {},
        isLiveMediaAlertModalOpened : false
    }

    componentDidMount() {
        let liveArea = document.getElementById("live-area");

        if (liveArea !== undefined && liveArea !== null) {
            //alert(root.style.touchAction);
            liveArea.style.touchAction = "none";
        }

        let isMobile = (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2));

        if (isMobile) {
            let div = document.getElementById("live-area");

            if (div !== undefined && div !== null) {
                if (window.screen.orientation !== undefined && window.screen.orientation !== null) {
                    if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                        div.classList.add("portrait");
                    }
                } else {
                    div.classList.add("ios");
                }
            }

            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);

            window.addEventListener("orientationchange", this.updateOrientation);
            window.addEventListener("resize", this.updateLiveAreaHeight);
        }/*  else {
            window.addEventListener("beforeunload", (event) => {
                event.preventDefault();
                //event.returnValue = "";

                console.log("beforeunload");

                const { cookies } = this.props;
                let newDate = new Date(Date.now());
                newDate.setDate(newDate.getDate() - 1);
                cookies.set("liveSeq", cookies.get("liveSeq"), { path: "/", expires: newDate });
                cookies.set("userID", cookies.get("userID"), { path: "/", expires: newDate });
                cookies.set("userSeq", cookies.get("userSeq"), { path: "/", expires: newDate });
                cookies.set("viewKind", cookies.get("viewKind"), { path: "/", expires: newDate });
            });
        } */
    }

    componentDidUpdate(prevProps) {
        // console.log("LiveContainer-componentDidUpdate this.props.performLiveInfo => ", this.props.performLiveInfo);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", function() {

        });
    }

    getBackgroundHeight = () => {
        let div = document.getElementById("live-area");

        if (div !== undefined && div !== null) {
            return div.clientHeight + "px";
        }
    }

    updateOrientation = () => {
        let div = document.getElementById("live-area");

        if (div !== undefined && div !== null) {
            if (div.classList.contains("ios")) {
                if (window.screen !== undefined && window.screen !== null) {
                    let vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty("--vh", `${vh}px`);

                    if (window.innerWidth < window.innerHeight) {   //portrait
                        changeImageSize(false);
                        //alert("ios 세로");
                        //alert(`세로 - vh[${vh}]`);
                    } else {                                        //landscape
                        changeImageSize(true);
                        //alert("ios 가로");
                        //alert(`가로 - vh[${vh}]`);
                    }
                }
            } else {
                if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                    div.classList.add("portrait");
                    //alert("android 세로");
                    changeImageSize(true);
                } else {
                    div.classList.remove("portrait");
                    //alert("android 가로");
                    changeImageSize(false);
                }
            }
        }
    }

    updateLiveAreaHeight = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    handleSelectedFunc = ({ selectedFunc, status, kind, mediaDevice_info }) => {
        switch (selectedFunc) {
            case "LIVE_ALERT_TOGGLE":
                this.props.closeLiveAlertModal();
                break;

            case "LIVE_GUEST_ALERT_TOGGLE":
                this.props.toggleLiveGuestAlertModal({ isModalOpened: false });
                break;

            case "LIVE_MIXED_ALERT_TOGGLE":
                this.props.toggleLiveMixedAlertModal({ isModalOpened: false });
                break;

            case "LIVE_PULL_SCREEN_TOGGLE":
                this.props.toggleLivePullScreenAlert({ isModalOpened: false });
                break;

            case "TOGGLE_CLASS_TALK":
                this.props.toggle_classTalk({ visibility: status });
                break;

            case "OPEN_LIVE_MEDIA_ALERT":
                this.setState({ kind });
                this.setState({ mediaDevice_info });
                this.setState({ isLiveMediaAlertModalOpened: true });
                break;

            case "CLOSE_LIVE_MEDIA_ALERT":
                if (this.state.kind === "myCam") {
                    changeMyCamViewInput(this.props.performLiveInfo.liveSeq, this.props.userSeq, false);
                } else {
                    changeHandsUpVideoInput(this.props.performLiveInfo.liveSeq, this.props.userSeq);
                }
                this.setState({ isLiveMediaAlertModalOpened: false });
                this.setState({ mediaDevice_info: {} });
                this.setState({ kind: "" });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <>
                <div id="touchLockGuard">
                    <span className="img-touchLock"></span>
                </div>
                <div id="full-area">
                    <LiveAlertModal
                        live_info={this.props.closedLive_info}
                        isAlertModalOpened={this.props.isLiveAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <LiveAlertModalFinish
                        live_info={this.props.closedLive_info}
                        isAlertModalOpened={this.props.isLiveAlertFinishModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <LiveGuestAlertModal
                        live_info={this.props.performLiveInfo}
                        isAlertModalOpened={this.props.isLiveGuestAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <LiveMixedAlertModal
                        live_info={this.props.performLiveInfo}
                        isAlertModalOpened={this.props.isLiveMixedAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <LiveMediaAlertModal
                        kind={this.state.kind}
                        mediaDevice_info={this.state.mediaDevice_info}
                        isAlertModalOpened={this.state.isLiveMediaAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <LivePullScreenAlertModal
                        isAlertModalOpened={this.props.isLivePullScreenAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                    <PerformLiveContainer
                        routeProps={this.props.routeProps}
                        cookies={this.props.cookies}
                        isJoinLive={this.props.isJoinLive}
                        performLiveInfo={this.props.performLiveInfo}
                        isLiveGuestAlertModalOpened={this.props.isLiveGuestAlertModalOpened}
                        isLiveMixedAlertModalOpened={this.props.isLiveMixedAlertModalOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                </div>
            </>
        );
    }
}

export default connect(
    ({ live, user }) => ({ 
        userSeq                     : user.userSeq,
        userNickname                : user.userNickname,
        mySubDomainSeq              : user.mySubDomainSeq,

        isLiveGuestAlertModalOpened : live.isLiveGuestAlertModalOpened,
        isLiveAlertModalOpened      : live.isLiveAlertModalOpened,
        isLiveAlertFinishModalOpened : live.isLiveAlertFinishModalOpened,
        //isLiveMediaAlertModalOpened : live.isLiveMediaAlertModalOpened,
        isLiveMixedAlertModalOpened : live.isLiveMixedAlertModalOpened,
        isLiveReJoinModalOpened     : live.isLiveReJoinModalOpened,
        isLivePullScreenAlertModalOpened : live.isLivePullScreenAlertModalOpened,

        performLiveInfo             : live.performLiveInfo,
        closedLive_info             : {
            liveName                : live.closedPerformLiveInfo.liveName,
            teacher_info            : {
                userSeq             : live.closedPerformLiveInfo.teacher_info === undefined || live.closedPerformLiveInfo.teacher_info === null ? 
                                      user.userSeq : live.closedPerformLiveInfo.teacher_info.userSeq,
                userNickname        : live.closedPerformLiveInfo.teacher_info === undefined || live.closedPerformLiveInfo.teacher_info === null ? 
                                      user.userNickname : live.closedPerformLiveInfo.teacher_info.userNickname
            }
        },
    }),
    {
        closeLiveAlertModal,

        toggle_classTalk,

        toggleLiveGuestAlertModal,
        toggleLiveMixedAlertModal,
        toggleLivePullScreenAlert
    }
)(LiveContainer);
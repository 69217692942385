import React from "react";
import MainTemplate from "../components/template/MainTemplate";
import MainContainer from "../containers/MainContainer";
import ContentTemplate from "../components/template/ContentTemplate";
import RemoconContainer from "../containers/RemoconContainer";

const ChatListPage = ({ browserType, cookies, routeProps, selectMainTap }) => {
    return (
        <MainTemplate>
            <MainContainer  browserType={browserType} cookies={cookies} routeProps={routeProps}>
                <ContentTemplate selectMainTap={selectMainTap}>
                    <RemoconContainer routeProps={routeProps} browserType={browserType} />
                </ContentTemplate>
            </MainContainer>
        </MainTemplate>
    );
};

export default ChatListPage;
import React, { useEffect, useState, useRef } from "react";
import { AudioConfig, SpeechConfig, PronunciationAssessmentConfig, PronunciationAssessmentResult, SpeechRecognizer, PronunciationAssessmentGradingSystem, PronunciationAssessmentGranularity } from "microsoft-cognitiveservices-speech-sdk";
import Recorder from "recorder-js";
import { useTranslation } from "react-i18next";

import { enRecordStatus, stCardItemType } from "../../../lib/ConstCommand";

import PronunciationResultItem from "./item/PronunciationResultItem";
import Item from "./item/Item";

// 참고 :
// https://docs.microsoft.com/en-us/javascript/api/overview/azure/speech-service?view=azure-node-latest

const useRecorder = ({ onRecordingCallback }) => {
    const [isBlocked, setBlocked] = useState(true);
    const [status, setStatus] = useState(enRecordStatus.Ready);

    const recorder = useRef(null);
    const audioStream = useRef(null);

    useEffect(() => {
        return () => {
            if (recorder && recorder.current) {
                recorder.current.stop()
                    .then(() => {
                        if (audioStream && audioStream.current) {
                            audioStream.current.getAudioTracks()[0].stop();
                            audioStream.current = null;
                        }
                        recorder.current = null;
                    })
                    .catch((err) => {
                        recorder.current = null;
                    });
            }
        }
    }, []);

    useEffect(() => {
        if (!isBlocked) {
            if (status === enRecordStatus.Ready) {
                startRecording();
            } else if (status === enRecordStatus.Retry) {
                tryGetPermission();
            }
        }
    }, [isBlocked, status]);

    const tryGetPermission = async () => {
        if (status !== enRecordStatus.Ready && status !== enRecordStatus.Retry) {
            return;
        }

        if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
            if (navigator.getUserMedia) {
                navigator.getUserMedia({ audio: true },
                    (stream) => {
                        audioStream.current = stream;
                        let AudioContext = window.AudioContext || window.webkitAudioContext;
                        let audioCtx = new AudioContext();
                        recorder.current = new Recorder(audioCtx);
                        recorder.current.init(stream);
                        setBlocked(false);
                        if (status === enRecordStatus.Retry) {
                            setStatus(enRecordStatus.Ready);
                        }
                    },
                    (err) => {
                        console.log("navigator.getUserMedia err => ", err);
                        setBlocked(true);
                    }
                );
            }
        } else {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream) => {
                    audioStream.current = stream;
                    let AudioContext = window.AudioContext || window.webkitAudioContext;
                    let audioCtx = new AudioContext();
                    recorder.current = new Recorder(audioCtx);
                    recorder.current.init(stream);
                    setBlocked(false);
                    if (status === enRecordStatus.Retry) {
                        setStatus(enRecordStatus.Ready);
                    }
                })
                .catch((err) => {
                    console.log("navigator.getUserMedia err => ", err);
                    setBlocked(true);
                });
        }
    }

    const startRecording = () => {
        if (!isBlocked) {
            if (recorder.current !== undefined && recorder.current !== null) {
                recorder.current.start()
                    .then(() => {
                        setStatus(enRecordStatus.Recording);
                    })
                    .catch((err) => {
                        console.log("startRecording err => ", err);
                    });
            }
        }
    }

    const stopRecording = () => {
        if (!isBlocked && status === enRecordStatus.Recording) {
            if (recorder && recorder.current !== undefined && recorder.current !== null) {
                recorder.current.stop()
                    .then(({ blob, buffer }) => {
                        if (audioStream.current !== undefined && audioStream.current !== null) {
                            audioStream.current.getAudioTracks()[0].stop(); // audioTrack을 stop해야 마이크가 꺼진다..
                            audioStream.current = null;
                        }

                        let reader = new FileReader();
                        reader.readAsArrayBuffer(blob);
                        reader.onload = () => {
                            onRecordingCallback(reader.result);
                            setStatus(enRecordStatus.Finish);
                            recorder.current = null;
                        };
                    })
                    .catch((err) => {
                        console.log("stopRecording err => ", err);
                        recorder.current = null;
                        setStatus(enRecordStatus.Ready);
                    });
            }
        }
    }

    const cancelRecording = () => {
        if (!isBlocked && status === enRecordStatus.Recording) {
            if (recorder && recorder.current !== undefined && recorder.current !== null) {
                recorder.current.stop()
                    .then(() => {
                        if (audioStream.current !== undefined && audioStream.current !== null) {
                            audioStream.current.getAudioTracks()[0].stop();
                            audioStream.current = null;
                        }

                        setStatus(enRecordStatus.Ready);
                        recorder.current = null;
                    })
                    .catch((err) => {
                        console.log("cancelRecording err => ", err);
                        recorder.current = null;
                        setStatus(enRecordStatus.Ready);
                    });
            }
        }
    }

    const retryRecording = () => {
        setStatus(enRecordStatus.Retry);
    }

    return {
        status,
        tryGetPermission,
        startRecording,
        stopRecording,
        cancelRecording,
        retryRecording
    }
}

const PronunciationCard = ({ cardInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const recognizer = useRef(null);
    const audioConfig = useRef(null);
    const speechConfig = useRef(null);
    const pronunciationAssessmentConfig = useRef(null);

    const [subscriptionKey, setSubscriptionKey] = useState(null);
    const [serviceRegion, setServiceRegion] = useState(null);
    const [pronunciationInfo, setPronunciationInfo] = useState(null);
    const [pronunciationResult, setPronunciationResult] = useState(null);
    const [extraItemInfo, setExtraItemInfo] = useState(null);

    const { status, tryGetPermission, stopRecording, retryRecording, cancelRecording } = useRecorder({
        onRecordingCallback: (buffer) => {
            if (buffer !== undefined && buffer !== null) {
                audioConfig.current = AudioConfig.fromWavFileInput(Buffer.from(buffer)); // arrayBuffer를 Buffer로 변환
                recognizer.current = new SpeechRecognizer(speechConfig.current, audioConfig.current);
                pronunciationAssessmentConfig.current.applyTo(recognizer.current);
                recognizer.current.recognizeOnceAsync(
                    (result) => {
                        if (result.privText !== undefined) {
                            let pronunciationAssessmentResult = PronunciationAssessmentResult.fromResult(result);
                            let pronunciationScore = pronunciationAssessmentResult.pronunciationScore;
                            //let wordLevelResult = pronunciationAssessmentResult.detailResult.Words;

                            setPronunciationResult(pronunciationAssessmentResult);

                            if (pronunciationInfo) {
                                handleSelectedFunc({ selectedFunc: "SEND_MSG_WATSON", msgText: pronunciationScore.toString() });

                                if (pronunciationScore >= pronunciationInfo.passScore) {
                                    handleSelectedFunc({ selectedFunc: "SET_PASS_POSSIBLE", data: { cardCode: cardInfo.code } });
                                }
                            }
                        } else { // 틀린 단어를 말했거나 말을 하지 않아서 결과가 없는 경우...

                        }
                    },
                    (err) => {
                        console.log("recognizeOnceAsync err => ", err);
                    }
                );
            }
        }
    });

    useEffect(() => {
        return () => {
            //cancelRecording();
        }
    }, []);

    useEffect(() => {
        if (cardInfo.data) {
            const pronunciation_info = cardInfo.data.find(info => info.kind === stCardItemType.pronunciation);
            if (pronunciation_info) {
                setSubscriptionKey(pronunciation_info.subscriptionKey);
                setServiceRegion(pronunciation_info.serviceRegion);
                setPronunciationInfo({
                    word: pronunciation_info.word,
                    pronunciation: pronunciation_info.pronunciation,
                    lang: pronunciation_info.lang,
                    passScore: pronunciation_info.passScore
                });
            }

            const extra_item_info = cardInfo.data.find(info => info.kind !== stCardItemType.pronunciation);
            if (extra_item_info) {
                console.log(extra_item_info);
                setExtraItemInfo(extra_item_info);
            }
        }
    }, [cardInfo.data]);

    useEffect(() => {
        if (pronunciationInfo) {
            if (subscriptionKey && serviceRegion && pronunciationInfo.word && pronunciationInfo.lang) {
                speechConfig.current = SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
                speechConfig.current.speechRecognitionLanguage = pronunciationInfo.lang;

                pronunciationAssessmentConfig.current = new PronunciationAssessmentConfig(
                    pronunciationInfo.word,
                    PronunciationAssessmentGradingSystem.HundredMark,
                    PronunciationAssessmentGranularity.Word, true
                );
            }
        }
    }, [subscriptionKey, serviceRegion, pronunciationInfo]);

    const retryPronunciation = () => {
        setPronunciationResult(null);
        retryRecording();
    }

    return (
        <>
            {
                extraItemInfo &&
                <Item info={extraItemInfo} />
            }
            {
                pronunciationInfo ?
                    <div className="pronun-card" type="item">
                        {
                            status === enRecordStatus.Ready ?
                                <button className="btn hiclasstv-blue" type="item" title={t("녹음_시작", { ns: "classTalk" })} onClick={() => tryGetPermission()}>
                                    {t("발음_녹음_시작", { ns: "classTalk" })}
                                </button> :
                            status === enRecordStatus.Recording ?
                                <button className="btn red" title={t("녹음_종료", { ns: "classTalk" })} type="item" onClick={() => stopRecording()}>
                                    {t("발음_녹음_종료", { ns: "classTalk" })}
                                </button> :
                            status === enRecordStatus.Finish ?
                                <button className="btn hiclasstv-dark-blue" type="item" title={t("다시_도전", { ns: "classTalk" })} onClick={() => retryPronunciation()}>
                                    {t("발음_다시_도전", { ns: "classTalk" })}
                                </button> :
                            <></>
                        }
                        {
                            pronunciationInfo.word && pronunciationInfo.word !== "" &&
                            <span className="text-item" type="item">{pronunciationInfo.word}</span>
                        }
                        {
                            pronunciationInfo.pronunciation && pronunciationInfo.pronunciation !== "" &&
                            <span className="text-item hiclasstv-blue-text">{"[" + pronunciationInfo.pronunciation + "]"}</span>
                        }
                        {
                            pronunciationResult &&
                            <PronunciationResultItem result={pronunciationResult} passScore={pronunciationInfo.passScore} />
                        }
                    </div> :
                    <></>
            }
        </>
    );
}

export default PronunciationCard;
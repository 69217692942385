import React, { Component } from "react";
import { connect } from "react-redux";

import ConstData from "../../../lib/ConstData";

import { enTokenCMD } from "../../../lib/classTalk/CodeEnum";
import { ctWebActionType } from "../../../lib/ConstCommand";
import { getMySmallGroupSendKey } from "../../../lib/RabbitmqClient";

import PerformWatchingLiveInfo from "../../../components/live/PerformWatchingLiveInfo";
import CenteredModalInLive from "../../../components/template/modal/CenteredModalInLive";
import RightSideModalInLive from "../../../components/template/modal/RightSideModalInLive";
import ClassTalkLive from "../../../components/live/ClassTalkLive";
import InkMemoWindow from "../../../components/classtalk/inkMemo/InkMemoWindow";
import WatchingLiveMember from "../../../components/live/modal/WatchingLiveMember";
import WebWhisperModal from "../../../components/whisper/WebWhisperModal";

import { sendQuizAnswer } from "../../../modules/quiz";

import {
    toggle_classTalk,
    appendSendMessage,
    appendSendAudioMemo,
    appendSendInkMemo,
    appendSendFileShare,
    emoticonSelectedToggle,
    emoticonSelectedCancel,
    addFunctionOpenedToggle,
    selectEmoticonSeq,
    selectFunctionSeq,
    inkMemoOpenedToggle,
    inkMemoClosedToggle,
    inkMemo_changeTool,
    voiceMemoClosedToggle,
    toggleFileShareMessage,
    openWhisperModal,
    respondWhisper,
    closeWhisperModal,
    webIsWhisperMode,
    setPassPossible
} from "../../../modules/classTalkLive";

import {
    closeWatchLiveModal,
    changeLiveConnect,
    changeLiveConnectStatus,
    changeLiveSoundStatus,
    changeLiveStatusInfo
} from "../../../modules/live";

class ClassTalkContainer extends Component {

    handleSelectedClassTalkFunc = ({ selectedFunc, emoticonSeq, func, msgText, data, dataUrl, filename, fileURL, idx, status, tool }) => {
        const { userSeq, userNickname, profileImgUrl } = this.props;

        switch (selectedFunc) {
            case "SEND_QUIZ_ANSWER":
                //console.log("SEND_QUIZ_ANSWER - ", data);
                this.props.sendQuizAnswer({ userNickname, data, userSeq }); //profileImgUrl 포함
                break;

            case "SET_PASS_POSSIBLE":
                this.props.setPassPossible({ data });
                break;

            // --------------------------- Emoticon --------------------------- //
            case "EMOTICON_TOGGLE":
                this.props.emoticonSelectedToggle();
                break;

            case "EMOTICON_SELECT":
                this.props.selectEmoticonSeq(emoticonSeq);
                break;

            case "EMOTICON_CANCLE":
                this.props.emoticonSelectedCancel();
                break;

            // --------------------------- Send Message --------------------------- //
            case "SEND_MSG":
                this.props.appendSendMessage({ msgText, userNickname, profileImgUrl });
                break;

            case "SEND_VOICE_MEMO":
                //this.props.appendSendAudioMemo({ mode:"web", liveSeq:performLiveInfo.liveSeq, data, teacherSeq : performLiveInfo.teacher_info.userSeq, filename, userSeq, userNickname, command:enTokenCMD.chat_Voice });
                this.props.appendSendAudioMemo({ selectedFunc, data, url: fileURL, userNickname, userSeq });
                break;

            // --------------------------- Classtalk func --------------------------- //
            case "FUNC_TOGGLE":
                this.props.addFunctionOpenedToggle();
                break;

            case "FUNC_SELECT":
                this.props.selectFunctionSeq(func);

                switch (func) {
                    case ctWebActionType.Whisper:
                        this.props.webIsWhisperMode({ web_isWhisper: !this.props.web_isWhisper });
                        break;

                    case ctWebActionType.InkMemo:
                        this.props.inkMemoOpenedToggle();
                        break;

                    case ctWebActionType.VoiceMemo:
                        break;

                    case ctWebActionType.FileShare:
                        let sendData = {
                            userSeq,
                            userNickname,
                            data,
                            mode: "web",
                            filename,
                            liveSeq: this.props.performLiveInfo.liveSeq,
                            teacherSeq: this.props.performLiveInfo.teacher_info.userSeq,
                            command: enTokenCMD.ct_UploadAndShareFiles,
                            isWhisper: this.props.isWhisperModalOpened || this.props.web_isWhisper,
                            smallGroupKey: getMySmallGroupSendKey(),
                            isMixedClassMember: this.props.isMixedClassMember ? "Y" : "N",
                            mixedClassStatus: this.props.performLiveInfo.mixedClassStatus === "Y" ? "Y" : "N"
                        };

                        if (this.props.isMixedClassMember) {
                            let localServerSeq = this.props.mixedClassInfo.localServerSeq;
                            let localLiveSeq = this.props.mixedClassInfo.localLiveSeq;
                            let localTeacherSeq = this.props.mixedClassInfo.localTeacherSeq;

                            sendData = {
                                ...sendData,
                                localServerSeq,
                                localLiveSeq,
                                localTeacherSeq
                            };
                        }

                        /* this.props.appendSendFileShare({ 
                            userSeq, 
                            userNickname, 
                            data, 
                            mode: "web", 
                            filename, 
                            liveSeq: this.props.performLiveInfo.liveSeq, 
                            teacherSeq: this.props.performLiveInfo.teacher_info.userSeq, 
                            command: enTokenCMD.ct_UploadAndShareFiles, 
                            isWhisper: this.props.isWhisperModalOpened || this.props.web_isWhisper,
                            smallGroupKey: getMySmallGroupSendKey(),
                            isMixedClassMember: this.props.isMixedClassMember,
                            mixedClassStatus: this.props.performLiveInfo.mixedClassStatus === "Y" ? "Y" : "N"
                        }); */

                        this.props.appendSendFileShare(sendData);
                        break;

                    default:
                        break;
                }
                break;

            case "CLOSE_VOICE_MEMO":
                this.props.voiceMemoClosedToggle();
                break;

            case "INK_MEMO":
                if (!this.props.isLiveChattingLocked) this.props.inkMemoOpenedToggle({ selectedFunc, fileURL });
                break;

            case "FILE_DOWNLOAD":
                console.log("FILE_DOWNLOAD -- ", fileURL, idx);
                this.handleFileDownload(fileURL, idx);
                break;

            case "FILE_OPEN":
                console.log("FILE_OPEN -- ", fileURL);
                if (fileURL !== undefined && fileURL !== null) {
                    window.open(fileURL);
                }
                break;

            case "PAGE_JPEG":
                break;

            case "INK_MEMO_TOOL":
                this.props.inkMemo_changeTool(tool);
                break;

            case "SEND_INK_MEMO":
                this.props.appendSendInkMemo({ data, url: dataUrl, userSeq, userNickname });
                break;

            case "CLOSE_INK_MEMO":
                this.props.inkMemoClosedToggle();
                break;

            case "OPEN_WHISPER":
                this.props.openWhisperModal({ data, idx });
                break;

            case "RESPOND_WHISPER":
                this.props.respondWhisper({ msgText, userNickname, profileImgUrl });
                break;

            case "CLOSE_WHISPER_MODAL":
                this.props.closeWhisperModal();
                break;

            case "LIVE_CON":
                this.props.changeLiveConnect({ isConnect: status });
                break;

            case "LIVE_CON_STATUS":
                this.props.changeLiveConnectStatus({ status });
                break;

            case "LIVE_SOUND_STATUS":
                this.props.changeLiveSoundStatus({ status });
                break;

            case "LIVE_RECON":
                this.props.changeLiveStatusInfo({ kind: "video" });
                break;

            case "NONE":
                this.props.closeWatchLiveModal();
                break;

            default:
                this.props.handleSelectedLiveFunc({ selectedFunc });
                break;
        }
    }

    handleFileDownload = async (url, idx) => {
        // 기존 코드---------여기부터
        /* const filename = url.substr(url.lastIndexOf("/") + 1);
        let link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();

        this.props.toggleFileShareMessage({ idx: idx, isClickedBtn: true }); */
        // 기존 코드---------여기까지 ... by hjkim 20221021
        try {
            console.log("handleFileDownload - file download function start");
            const requestUrl = new URL(url, ConstData.MAIN_HOST_ADD);
            console.log("handleFileDownload - requestUrl => ", requestUrl);
            const response = await fetch(requestUrl, { method: "GET" });
            console.log("handleFileDownload - fetch get success");
            const blob = await response.blob();
            console.log("handleFileDownload - response.blob success");
            const objUrl = window.URL.createObjectURL(blob);
            console.log("handleFileDownload - URL.createObjectURL(blob) success");

            const filename = url.substr(url.lastIndexOf("/") + 1);
            const link = document.createElement("a");
            document.body.appendChild(link);        // createObjectURL로 생성한 url은 document 내에서만 유효하므로... by hjkim 20221021
            link.download = filename;               // download 속성을 줘야 a tag 클릭 시 다운로드 이벤트가 발생... by hjkim 20221021
            link.href = objUrl;
            console.log("handleFileDownload - file download try link click");
            link.click();
            console.log("handleFileDownload - file download started");

            document.body.removeChild(link);        // clean up - 쓰임을 다한 a tag document 에서 삭제... by hjkim 20221021
            window.URL.revokeObjectURL(objUrl);     // clean up - 쓰임을 다한 objUrl 객체 삭제... by hjkim 20221021
            console.log("handleFileDownload - removeChild(link), URL.revokeObjectURL(objUrl)");

            this.props.toggleFileShareMessage({ idx: idx, isClickedBtn: true });
            console.log("handleFileDownload - file download function finish");
        } catch (err) {
            console.log("handleFileDownload() - err => ", err);
        }
    }

    render() {
        const { userSeq } = this.props;
        const { performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo } = this.props;
        const { classTalkVisibility } = this.props;
        const { handleSelectedClassTalkFunc } = this;

        return (
            <div id="classtalk" className={(performLiveStatusInfo.isConnect ? " connect" : "")} style={{ visibility: `${classTalkVisibility}` }}>
                {
                    this.props.isLiveMemberModalOpened &&
                    <RightSideModalInLive
                        modalClose={handleSelectedClassTalkFunc}
                        style={{ margin: '5px', visibility: 'inherit' }}
                    >
                        <WatchingLiveMember
                            style={{ visibility: 'inherit' }}
                            userSeq={userSeq}
                            performLiveInfo={performLiveInfo}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </RightSideModalInLive>
                } {
                    this.props.isWhisperModalOpened &&
                    <CenteredModalInLive
                        modalClose={handleSelectedClassTalkFunc}
                        style={{ margin: '5px', visibility: `${classTalkVisibility}` }}
                    >
                        <WebWhisperModal
                            openInfo={this.props.openInfo}
                            msgInfo={this.props.whisperMsgInfo}
                            isLiveChattingLocked={this.props.isLiveChattingLocked}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </CenteredModalInLive>
                } {
                    !this.props.isLiveChattingLocked && this.props.isInkMemoOpened &&
                    <CenteredModalInLive
                        modalClose={handleSelectedClassTalkFunc}
                        style={{ margin: '5px', visibility: `${classTalkVisibility}` }}
                    >
                        <InkMemoWindow
                            style={{ visibility: `${classTalkVisibility}` }}
                            toolMode={this.props.toolMode}
                            inkMemoFile={this.props.inkMemoFile}
                            handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                        />
                    </CenteredModalInLive>
                }
                <PerformWatchingLiveInfo
                    isGuestYn={this.props.isGuestYn}
                    broadcastOpenYN={this.props.broadcastOpenYN}
                    performLiveInfo={performLiveInfo}
                    handleSelectedLiveFunc={this.props.handleSelectedLiveFunc}
                />
                <ClassTalkLive
                    classTalkVisibility={classTalkVisibility}
                    web_isWhisper={this.props.web_isWhisper}
                    openInfo={this.props.isWhisperModalOpened ? this.props.tmpOpenInfo : this.props.openInfo}
                    isLiveChattingLocked={this.props.isLiveChattingLocked}
                    classTalkMsg={this.props.classTalkMsg}
                    handsUpYN={performLiveInfo.handsUpYN}
                    myHandsUpYN={performHandsUpStatusInfo.myHandsUpYN}
                    connectStatus={performLiveStatusInfo.connectStatus}
                    isConnectedOnScreenApp={this.props.isConnectedOnScreenApp}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ classTalkLive, user, live, hiclasstv }) => ({
        // userNickname                : user.userNickname,
        profileImgUrl               : user.profileImgUrl,
        isMixedClassMember          : live.isMixedClassMember,

        mixedClassInfo              : live.mixedClassInfo,

        isInkMemoOpened             : classTalkLive.isInkMemoOpened,
        inkMemoFile                 : classTalkLive.inkMemoFile,        
        toolMode                    : classTalkLive.toolMode,

        classTalkMsg                : classTalkLive.receivedMsg,

        web_isWhisper               : classTalkLive.web_isWhisper,
        whisperMsgInfo              : classTalkLive.whisperMsgInfo,

        isLiveMemberModalOpened     : live.isLiveMemberModalOpened,

        isLiveChattingLocked        : live.isLiveChattingLocked,

        tmpOpenInfo     : {
            isEmoticonSelected      : false,
            selectEmoticonSeq       : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : false,
            audioStatus             : "STOPED"
        },
        openInfo        : {
            isEmoticonSelected      : classTalkLive.isEmoticonSelected,
            selectedEmoticonSeq     : classTalkLive.selectedEmoticonSeq,
            isAddFunctionOpened     : classTalkLive.isAddFunctionOpened,
            isAudioOpened           : classTalkLive.isAudioOpened,
            audioStatus             : classTalkLive.audioStatus,
        },

        isConnectedOnScreenApp      : hiclasstv.isConnectedOnScreenApp,
    }),
    {
        toggle_classTalk,
        inkMemoOpenedToggle,
        inkMemoClosedToggle,
        inkMemo_changeTool,
        closeWatchLiveModal,
        emoticonSelectedToggle,
        emoticonSelectedCancel,
        addFunctionOpenedToggle,

        selectEmoticonSeq,
        selectFunctionSeq,

        appendSendMessage,
        appendSendAudioMemo,
        appendSendInkMemo,
        appendSendFileShare,

        openWhisperModal,
        respondWhisper,
        closeWhisperModal,
        webIsWhisperMode,

        voiceMemoClosedToggle,
        toggleFileShareMessage,
        setPassPossible,

        changeLiveConnect,
        changeLiveConnectStatus,
        changeLiveSoundStatus,
        changeLiveStatusInfo,

        sendQuizAnswer
    }
)(ClassTalkContainer);
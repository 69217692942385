import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

const LiveAlertModalMemberLimit = ({ live_info, isLiveAlertMemberLimitOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    return(
        <Modal isOpen={isLiveAlertMemberLimitOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>{t("클래스", { ns: "live" })} [</span>
                        <div className="title wmax-20">
                            <span className="hiclass-text" title={live_info.liveName}><b>{live_info.liveName}</b></span>
                        </div>
                        <span>]{t("클래스_멤버_수_초과_안내_문구_1", { ns: "live" })}</span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <div className="text-area text-center">
                            <span>
                                {t("클래스_멤버_수_초과_안내_문구_2", { ns: "live" })}
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "LIVE_ALERT_TOGGLE" })}>{t("확인", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
}
export default LiveAlertModalMemberLimit;
import React from 'react';
import { useTranslation } from "react-i18next";
import ConstData from '../../../lib/ConstData';

import CustomProfileImage from '../profile/CustomProfileImage';

const WebMyProfileWindow = ({ userInfo, handleProfileModalFunc }) => {
    const { t } = useTranslation();

    return (
        <div id="myProfile" className="dropdown-menu profile">
            <div className="d-flex flex-column align-items-center p-2">
                <div className="landscape-to-portrait">
                    <div className="list-row" style={{ minWidth: '30%' }}>
                        <CustomProfileImage url={userInfo.profileImgUrl} style={{ width: '5rem', height: '5rem' }} alt={userInfo.userNickname} />
                    </div>
                    <div className="list-row flex-column align-items-start" style={{ marginLeft: '.5rem' }}>
                        <span className="font-ml font-weight-bold">
                            {userInfo.userNickname}
                        </span>
                        <span className="hiclasstv-darkgrey-text">
                            {userInfo.userEmail}
                        </span>
                    </div>
                </div>
                <div className="d-flex align-items-center w-100 py-2 my-2">
                    <span className="green white-text px-2 py-1 rounded font-sm mr-2">{t("사용중", { ns: "user" })}</span>
                    <span className="font-md">{userInfo.usingPassInfo.serviceName} - {userInfo.usingPassInfo.passProductName}</span>
                </div>
                {
                    !ConstData.IS_LOCAL_VERSION &&
                    <>
                        <div className="dropdown-divider w-100" />
                        <button type="button" className="btn btn-md outline outline-hiclasstv-grey no-effect" style={{ borderRadius: '2rem' }} title={t("프로필_수정", { ns: "user" })} onClick={() => handleProfileModalFunc({ selectedFunc: "MODIFY_TOGGLE" })}>
                            {t("프로필_수정", { ns: "user" })}
                        </button>
                    </>
                }
                <div className="dropdown-divider w-100" />
                <button type="button" className="btn btn-md btn-red white-text" title={t("로그아웃", { ns: "user" })} onClick={() => handleProfileModalFunc({ selectedFunc: "LOGOUT" })}>
                    <b>{t("Logout", { ns: "user" })}</b>
                </button>
            </div>
        </div>
    )
}

export default WebMyProfileWindow;
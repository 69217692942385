export default function createMultiAction(type, kind, afterType) {

    return parm => (dispatch, getState) => {
        dispatch({
            type,
            payload: parm
        });

        if (kind === "chat") {
            const { chat } = getState();
            const { nextPhaseData } = chat;
            //const {mySendKey, myBindKey, myChatSendKey, myChatBindKey} = chat;
            const { myHandsUpSendKey, myHandsUpBindKeyArr, myTeamUpSendKey, myTeamUpBindKeyArr } = chat;
    
            if (nextPhaseData !== null && nextPhaseData !== "") {
                dispatch({
                    type: afterType,
                    rbmq_info: {
                        myHandsUpSendKey: myHandsUpSendKey,
                        myHandsUpBindKeyArr: myHandsUpBindKeyArr,
                        myTeamUpSendKey: myTeamUpSendKey,
                        myTeamUpBindKeyArr: myTeamUpBindKeyArr
                    },
                    payload: nextPhaseData
                });
            }
        } else if (kind === "live") {
            const { live } = getState();
            const { nextPhaseData } = live;
            //const {mySendKey, myBindKey, myChatSendKey, myChatBindKey} = live;
            const { myHandsUpSendKey, myHandsUpBindKeyArr } = live;

            if (nextPhaseData !== null && nextPhaseData !== "") {
                dispatch({
                    type: afterType,
                    rbmq_info: {
                        myHandsUpSendKey: myHandsUpSendKey,
                        myHandsUpBindKeyArr: myHandsUpBindKeyArr,
                    },
                    payload: nextPhaseData
                });
            }
        } else if (kind === "quiz") {
            const { quiz } = getState();
            const { nextPhaseData } = quiz;

            if (nextPhaseData !== null && nextPhaseData !== "") {
                dispatch({
                    type: afterType,
                    payload: { nextPhaseData },
                    parms: parm
                });
            }
        }
    }
}
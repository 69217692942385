import React, { Component } from "react";
import { connect } from "react-redux";
import { enWebPage } from "../lib/ConstCommand";

import { toggleNoticeModal } from "../modules/hiclasstv";

import WebHeader from "../components/etc/WebHeader";
import WebPolicy from "../components/etc/WebPolicy";
import WebAppPolicy from "../components/etc/WebAppPolicy";

const isMobile = (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2));

class PolicyContainer extends Component {
    handleSelectedFunc = ({ selectedFunc }) => {
        const { toggleNoticeModal } = this.props;

        switch (selectedFunc) {
            case "REFRESH_HOME":
                toggleNoticeModal({ status: true });
                break;

            default:
                break;
        }
    }

    render() {
        const { userKind, isLoginYn, currentPageType } = this.props;
        const { handleSelectedFunc } = this;

        return (
            <div className="main-template web">
                <div className="header-title">
                    <WebHeader
                        userKind={userKind}
                        isLoginYn={isLoginYn}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </div>
                <div className={"d-flex flex-column main-container" + (isMobile ? " mobile" : "")}>
                    <div className="content-template-web">
                        <div className="d-flex flex-column align-items-center w-100 h-100 overflow-auto scroll-show" style={{ maxHeight: 'calc(100vh - 120px)' }}>
                            {
                                currentPageType === enWebPage.Policy ?
                                    <WebPolicy /> :
                                    <WebAppPolicy />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    ({ user }) => ({
        userKind        : user.userKind,
        isLoginYn       : user.isLoginYn
    }),
    {
        toggleNoticeModal
    }
)(PolicyContainer);
import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { FaUserFriends } from 'react-icons/fa';

import WebListArrowButton from '../etc/button/WebListArrowButton';
import WebConfigFriendList from './list/WebConfigFriendList';

const WebConfigMyFriendList = ({ list_friends, list_rcvRequestFriends, list_sendRequestFriends, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [isRcvOpened, setIsRcvOpened] = useState(false);
    const [isSendOpened, setIsSendOpened] = useState(false);
    const [isFriendsOpened, setIsFriendsOpened] = useState(false);

    useEffect(() => {
        if (list_friends.length > 0) {
            setIsFriendsOpened(true);
        }
    }, [list_friends]);

    useEffect(() => {
        if (list_rcvRequestFriends.length > 0) {
            setIsRcvOpened(true);
        }
    }, [list_rcvRequestFriends]);

    useEffect(() => {
        if (list_sendRequestFriends.length > 0) {
            setIsSendOpened(true);
        }
    }, [list_sendRequestFriends]);

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="m-0 p-0">
                    <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaUserFriends className="font-lg mr-2" aria-hidden="true" />{t("나에게_요청한_사용자")} ({list_rcvRequestFriends === undefined || list_rcvRequestFriends === null ? 0 : list_rcvRequestFriends.length})
                        </span>
                        <WebListArrowButton toggle_id={'rcvFriendList'} list_array={list_rcvRequestFriends} isOpened={isRcvOpened} />
                    </div>
                    <UncontrolledCollapse toggler="#rcvFriendList" defaultOpen={true} onEntering={() => setIsRcvOpened(true)} onExiting={() => setIsRcvOpened(false)}>
                        <WebConfigFriendList kind={'rcv'} rowKey={'rcvFriend-'} message={t("신규_요청_내역_없음")} list_friends={list_rcvRequestFriends} handleSelectedFunc={handleSelectedFunc} />
                    </UncontrolledCollapse>
                </div>
                <div className="mt-4 p-0">
                    <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaUserFriends className="font-lg mr-2" aria-hidden="true" />{t("내가_요청한_사용자")} ({list_sendRequestFriends === undefined || list_sendRequestFriends === null ? 0 : list_sendRequestFriends.length})
                        </span>
                        <WebListArrowButton toggle_id={'sendFriendList'} list_array={list_sendRequestFriends} isOpened={isSendOpened} />
                    </div>
                    <UncontrolledCollapse toggler="#sendFriendList" defaultOpen={true} onEntering={() => setIsSendOpened(true)} onExiting={() => setIsSendOpened(false)}>
                        <WebConfigFriendList kind={'send'} rowKey={'sendFriend-'} message={t("신규_요청_내역_없음")} list_friends={list_sendRequestFriends} handleSelectedFunc={handleSelectedFunc} />
                    </UncontrolledCollapse>
                </div>
                <div className="mt-4 p-0">
                    <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaUserFriends className="font-lg mr-2" aria-hidden="true" />{t("친구", { ns: "friend" })} ({list_friends === undefined || list_friends === null ? 0 : list_friends.length})
                        </span>
                        <WebListArrowButton toggle_id={'friendList'} list_array={list_friends} isOpened={isFriendsOpened} />
                    </div>
                    <UncontrolledCollapse toggler="#friendList" defaultOpen={true} onEntering={() => setIsFriendsOpened(true)} onExiting={() => setIsFriendsOpened(false)}>
                        <WebConfigFriendList kind={'my'} rowKey={'myFriend-'} message={t("친구_목록_없음", { ns: "friend" })} list_friends={list_friends} handleSelectedFunc={handleSelectedFunc} />
                    </UncontrolledCollapse>
                </div>
            </div>
        </div>
    );
}

export default WebConfigMyFriendList;
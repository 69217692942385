import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";

import ConstData from "../../../../../lib/ConstData";
import { enTokenCMD, enDrawingMode } from "../../../../../lib/classTalk/CodeEnum";

const InkPlayerDrawing = forwardRef(({ }, ref) => {
    const [played, setPlayed] = useState(false);
    const [list_data, setListData] = useState([]);
    const [index, setIndex] = useState(-1);
    const [playingStartTime, setPlayingStartTime] = useState(-1);

    const [mode, setMode] = useState(enDrawingMode.Pen);
    const [color, setColor] = useState("rgb(255, 0, 0)");
    const [width, setWidth] = useState(0);

    const pathRef = useRef(null);

    const backgroundCanvasRef = useRef(null);
    const mainCanvasRef = useRef(null);
    const mainCtxRef = useRef(null);

    const [pathList, setPathList] = useState([]);
    //const [undoList, setUndoList] = useState([]);
    //const [redoList, setRedoList] = useState([]);

    const timeout = useRef(null);

    useImperativeHandle(ref, () => ({
        played: () => played,
        playInkMedia: data => play(data),
        pauseInkMedia: () => pause(),
        setCanvasBackground: imageData => setBackground(imageData)
    }));

    useEffect(() => {
        return () => {
            if (played || (timeout && timeout.current)) {
                // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
                if (timeout && timeout.current) {
                    clearTimeout(timeout.current);
                    timeout.current = null;
                }

                setPlayed(false);
                setIndex(-1);
                setPlayingStartTime(-1);
                clear();

                // 여기부터 기존 코드 20230102 수정 by hjkim
                // clearTimeout(timeout.current);
                // timeout.current = null;

                // setPlayed(false);
                // setIndex(-1);
                // setPlayingStartTime(-1);
                // clear();
                // 여기까지 기존 코드
            }
        }
    }, []);

    /** 기존 while & time out */
    /* useEffect(() => {
        if (!played) return;
        if (playingStartTime < 0) return;
        if (list_data.length <= 0) return;
        if (index < 0 || index >= list_data.length) return;

        let token = list_data[index].token;
        let time_to_delay = list_data[index].time_to_delay;

        while (true) {
            if (time_to_delay <= Date.now() - playingStartTime) {
                break;
            }
        }

        onPlayInkMedia(token);
        const timeout = setTimeout(() => setIndex(index + 1), 1);

        return () => {
            clearTimeout(timeout);
        }
    }, [playingStartTime, played, index, list_data]); */

    useEffect(() => {
        if (!played || playingStartTime < 0) return;
        if (list_data.length <= 0) return;
        if (index < 0 || index >= list_data.length - 1) return;

        const inkPlayer = (startIdx) => {
            let i;

            timeout.current = setTimeout(() => {
                if (!played || playingStartTime < 0) clearTimeout(timeout.current);
                if (startIdx < 0 || startIdx >= list_data.length - 1) clearTimeout(timeout.current);

                for (i = startIdx; i < list_data.length; i++) {
                    if (list_data[i].time_to_delay <= Date.now() - playingStartTime) {
                        onPlayInkMedia(list_data[i].token);
                    } else {
                        break;
                    }
                }

                if (i === startIdx) {
                    inkPlayer(i);
                } else {
                    timeout.current = setTimeout(() => setIndex(i), 10);
                }
            }, 10);
        }

        inkPlayer(index);

        return () => {
            if (timeout && timeout.current) {
                clearTimeout(timeout.current);
                timeout.current = null;
            }
        }
    }, [playingStartTime, played, index, list_data]);

    const setBackground = (imageData) => {
        if (imageData !== undefined && imageData !== null) {
            let img = new Image();
            img.src = imageData;
            img.onload = () => {
                // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
                URL.revokeObjectURL(imageData);
                if (backgroundCanvasRef && backgroundCanvasRef.current) {
                    const context = backgroundCanvasRef.current.getContext("2d");
                    context.drawImage(img, 0, 0, backgroundCanvasRef.current.width, backgroundCanvasRef.current.height);
                }

                // 여기부터 기존 코드 20230102 수정 by hjkim
                // URL.revokeObjectURL(imageData);
                // const context = backgroundCanvasRef.current.getContext("2d");
                // context.drawImage(img, 0, 0, backgroundCanvasRef.current.width, backgroundCanvasRef.current.height);
                // 여기까지 기존 코드
            };
        }
    }

    const play = (data) => {
        if (played) return;
        setPlayed(true);
        setIndex(0);
        setListData(data);
        setPlayingStartTime(Date.now());
    }

    const pause = () => {
        if (!played) return;
        setPlayed(false);
        setIndex(-1);
        setPlayingStartTime(-1);
        clear();
    }

    const onPlayInkMedia = (token) => {
        switch (token.CMD) {
            case enTokenCMD.Ink_SDn:
                init_inkDraw(token.SPT);
                break;

            case enTokenCMD.Ink_SM:
                move_inkDraw(token.SPT);
                break;

            case enTokenCMD.Ink_SDM:
                //console.log("double move - ", token.SPT, token.EPT);
                break;

            case enTokenCMD.Ink_SUp:
                finish_inkDraw(token.SPT);
                break;

            case enTokenCMD.Ink_Clear:
                clear();
                break;

            case enTokenCMD.Ink_Erase1:
                erase_inkStroke(token.SPT, token.EPT);
                break;

            case enTokenCMD.Ink_Mode:
                setMode(token.byte1);
                break;

            case enTokenCMD.Ink_Color:
                let r = token.byte1;
                let g = token.byte2;
                let b = token.byte3;
                setColor(`rgb(${r}, ${g}, ${b})`);
                break;

            case enTokenCMD.Ink_Tip:
                let w = (token.double1 + token.double2) / 2;
                setWidth(w);
                break;

            case enTokenCMD.Ink_RemoveStrokes:
                break;

            default:
                //console.log("onPlayInkMedia token.CMD => ", token.CMD);
                break;
        }
    }

    const convertPDF2View = (pointX, pointY) => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (mainCanvasRef && mainCanvasRef.current) {
            let scaleX;
            let scaleY;

            if (mainCanvasRef.current.width < mainCanvasRef.current.height) {
                // 세로문서
                scaleX = mainCanvasRef.current.width / ConstData.SCREEN_RATE.HEIGHT;
                scaleY = scaleX;
            } else {
                // 가로문서
                scaleX = mainCanvasRef.current.width / ConstData.SCREEN_RATE.WIDTH;
                scaleY = mainCanvasRef.current.height / ConstData.SCREEN_RATE.HEIGHT;
            }

            let x = Math.floor(pointX * scaleX);
            let y = Math.floor(pointY * scaleY);

            return { x, y };
        } else {
            console.log("convertPDF2View - mainCanvasRef => ", mainCanvasRef);

            return null;
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // let scaleX;
        // let scaleY;

        // if (mainCanvasRef.current.width < mainCanvasRef.current.height) {
        //     // 세로문서
        //     scaleX = mainCanvasRef.current.width / ConstData.SCREEN_RATE.HEIGHT;
        //     scaleY = scaleX;
        // } else {
        //     // 가로문서
        //     scaleX = mainCanvasRef.current.width / ConstData.SCREEN_RATE.WIDTH;
        //     scaleY = mainCanvasRef.current.height / ConstData.SCREEN_RATE.HEIGHT;
        // }

        // let x = Math.floor(pointX * scaleX);
        // let y = Math.floor(pointY * scaleY);

        // return { x, y };
        // 여기까지 기존 코드
    }

    const init_inkDraw = (point) => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (pathRef) {
            if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
                pathRef.current = new Path2D();
                pathRef.current.strokeStyle = color;
                pathRef.current.globalAlpha = mode === enDrawingMode.Pen ? 1 : 0.5;
                pathRef.current.lineWidth = width;

                let data = [];
                data.push({ x: point.X, y: point.Y });
                pathRef.current.data = data;

                let cp = convertPDF2View(point.X, point.Y);
                if (cp !== undefined && cp !== null) {
                    pathRef.current.moveTo(cp.x, cp.y);
                    pathRef.current.lineTo(cp.x, cp.y);
                    pathRef.current.prePoint = cp;
                    pathList.push(pathRef.current);

                    redraw();
                }
            }
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
        //     pathRef.current = new Path2D();
        //     pathRef.current.strokeStyle = color;
        //     pathRef.current.globalAlpha = mode === enDrawingMode.Pen ? 1 : 0.5;
        //     pathRef.current.lineWidth = width;

        //     let data = [];
        //     data.push({ x: point.X, y: point.Y });
        //     pathRef.current.data = data;

        //     let cp = convertPDF2View(point.X, point.Y);
        //     pathRef.current.moveTo(cp.x, cp.y);
        //     pathRef.current.lineTo(cp.x, cp.y);
        //     pathRef.current.prePoint = cp;
        //     pathList.push(pathRef.current);

        //     redraw();
        // }
        // 여기까지 기존 코드
    }

    const move_inkDraw = (point) => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (pathRef && pathRef.current) {
            if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
                let data = pathRef.current.data;
                data.push({ x: point.X, y: point.Y });
                pathRef.current.data = data;

                let prePoint = pathRef.current.prePoint;
                let cp = convertPDF2View(point.X, point.Y);
                if (cp !== undefined && cp !== null) {
                    pathRef.current.moveTo(cp.x, cp.y);
                    pathRef.current.quadraticCurveTo(prePoint.x, prePoint.y, cp.x, cp.y);
                    pathRef.current.prePoint = cp;

                    redraw();
                }
            }
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // if (pathRef.current) {
        //     if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
        //         let data = pathRef.current.data;
        //         data.push({ x: point.X, y: point.Y });
        //         pathRef.current.data = data;

        //         let cp = convertPDF2View(point.X, point.Y);
        //         let prePoint = pathRef.current.prePoint;
        //         pathRef.current.moveTo(cp.x, cp.y);
        //         pathRef.current.quadraticCurveTo(prePoint.x, prePoint.y, cp.x, cp.y);
        //         pathRef.current.prePoint = cp;

        //         redraw();
        //     }
        // }
        // 여기까지 기존 코드
    }

    const doubleMove_inkDraw = () => {

    }

    const finish_inkDraw = (point) => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (pathRef && pathRef.current) {
            if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
                let data = pathRef.current.data;
                data.push({ x: point.X, y: point.Y });
                pathRef.current.data = data;

                let prePoint = pathRef.current.prePoint;
                let cp = convertPDF2View(point.X, point.Y);
                if (cp !== undefined && cp !== null) {
                    pathRef.current.moveTo(cp.x, cp.y);
                    pathRef.current.quadraticCurveTo(prePoint.x, prePoint.y, cp.x, cp.y);
                    pathRef.current.closePath();

                    redraw();

                    pathRef.current = null;
                }
            }
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // if (pathRef.current) {
        //     if (mode === enDrawingMode.Pen || mode === enDrawingMode.Marker) {
        //         let data = pathRef.current.data;
        //         data.push({ x: point.X, y: point.Y });
        //         pathRef.current.data = data;

        //         let cp = convertPDF2View(point.X, point.Y);
        //         let prePoint = pathRef.current.prePoint;
        //         pathRef.current.moveTo(cp.x, cp.y);
        //         pathRef.current.quadraticCurveTo(prePoint.x, prePoint.y, cp.x, cp.y);
        //         pathRef.current.closePath();

        //         redraw();

        //         pathRef.current = null;
        //     }
        // }
        // 여기까지 기존 코드
    }

    const erase_inkStroke = (spoint, epoint) => {
        if (pathList === undefined || pathList === null) return;

        let idx = -1;

        pathList.forEach((item, index) => {
            if (item.data[0].x === spoint.X && item.data[0].y === spoint.Y) {
                if (item.data[item.data.length - 1].x === epoint.X && item.data[item.data.length - 1].y === epoint.Y) {
                    idx = index;
                    return;
                }
            }
        });

        if (idx > -1) {
            pathList.splice(idx, 1);
            redraw();
        }
    }

    const clear = () => {
        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (mainCanvasRef && mainCanvasRef.current) {
            if (mainCtxRef) {
                mainCtxRef.current = mainCanvasRef.current.getContext("2d");
                mainCtxRef.current.clearRect(0, 0, mainCanvasRef.current.width, mainCanvasRef.current.height);
                setPathList([]);
            }
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // mainCtxRef.current = mainCanvasRef.current.getContext("2d");
        // mainCtxRef.current.clearRect(0, 0, mainCanvasRef.current.width, mainCanvasRef.current.height);
        // setPathList([]);
        // 여기까지 기존 코드
    }

    const redraw = () => {
        if (pathList === undefined || pathList === null) return;

        // 혹시 모를 오류 방지를 위해 수정... by hjkim 20230102 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
        if (mainCanvasRef && mainCanvasRef.current) {
            if (mainCtxRef) {
                mainCtxRef.current = mainCanvasRef.current.getContext("2d");
                mainCtxRef.current.clearRect(0, 0, mainCanvasRef.current.width, mainCanvasRef.current.height);

                pathList.forEach(item => {
                    mainCtxRef.current.beginPath();
                    mainCtxRef.current.lineJoin = "round";
                    mainCtxRef.current.lineCap = "round";
                    mainCtxRef.current.strokeStyle = item.strokeStyle;
                    mainCtxRef.current.globalAlpha = item.globalAlpha;
                    mainCtxRef.current.lineWidth = item.lineWidth;
                    mainCtxRef.current.stroke(item);
                });
            }
        }

        // 여기부터 기존 코드 20230102 수정 by hjkim
        // mainCtxRef.current = mainCanvasRef.current.getContext("2d");
        // mainCtxRef.current.clearRect(0, 0, mainCanvasRef.current.width, mainCanvasRef.current.height);

        // pathList.forEach(item => {
        //     mainCtxRef.current.beginPath();
        //     mainCtxRef.current.lineJoin = "round";
        //     mainCtxRef.current.lineCap = "round";
        //     mainCtxRef.current.strokeStyle = item.strokeStyle;
        //     mainCtxRef.current.globalAlpha = item.globalAlpha;
        //     mainCtxRef.current.lineWidth = item.lineWidth;
        //     mainCtxRef.current.stroke(item);
        // });
        // 여기까지 기존 코드
    }

    return (
        <div className="draw-area">
            <canvas
                className="w-100 h-100"
                ref={backgroundCanvasRef}
                resize="true"
                style={{ position: 'absolute' }}
            />
            <canvas
                className="w-100 h-100"
                ref={mainCanvasRef}
                resize="true"
                style={{ position: 'absolute' }}
            />
        </div>
    );
});

export default InkPlayerDrawing;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const QnA001 = ({ isOpened, onChangeOpenedFlag }) => {
    const { t } = useTranslation();

    useEffect(() => {
        let content = document.getElementById("qna01");

        if (isOpened) {
            content.style.maxHeight = content.scrollHeight + "px";
        } else {
            content.style.maxHeight = null;
        }
    }, [isOpened]);

    return (
        <div className="accordion">
            <div className="accordion-title" onClick={() => onChangeOpenedFlag(0)}>
                <span className="hiclasstv-deepDarkgrey-text button">
                    <b>1. {t("FAQ_질문_1", { ns: "info" })}</b>
                </span>
            </div>
            <div className="accordion-content hiclasstv-darkgrey-text" id="qna01">
                <span className="d-flex" style={{ minWidth: 'max-content' }}>A.&nbsp;</span>
                <p>
                    {t("FAQ_공통_답변_감사", { ns: "info" })}<br />
                    {t("FAQ_질문_1_답변_1", { ns: "info" })}<br />
                    {t("FAQ_질문_1_답변_2", { ns: "info" })}<br />
                    <b>{t("FAQ_공통_답변_전화", { ns: "info" })}</b><br />
                    <b>{t("FAQ_공통_답변_메일", { ns: "info" })}</b>
                </p>
            </div>
        </div>
    );
}

export default QnA001;
import React from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { ctActionType } from "../../../lib/ConstCommand";

const FileDirRemoveModal = ({ handleSelectedClassTalkFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex text-center my-2 dark-grey-text small">
                {t("팀업_알림_메시지_14", { ns: "chat" })}<br />
                {t("팀업_알림_메시지_15", { ns: "chat" })}<br />
                {t("팀업_알림_메시지_16", { ns: "chat" })}
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" title={t("예", { ns: "common" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.FileClear })}>
                    {t("예", { ns: "common" })}
                </button>
                <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" title={t("아니오", { ns: "common" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE" })}>
                    {t("아니오", { ns: "common" })}
                </button>
            </div>
        </div>
    );
}

export default FileDirRemoveModal;
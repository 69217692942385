import React from 'react';
import { useTranslation } from "react-i18next";

import { FaBars, FaHome } from 'react-icons/fa';

import { getEntryMemberCount } from '../../lib/func/ExportFunction';

const PerformWatchingLiveInfo = ({ isGuestYn, broadcastOpenYN, performLiveInfo, handleSelectedLiveFunc }) => {
    const { t } = useTranslation();
    
    return (
        performLiveInfo.liveSeq >= 0 ?
            <div className="title-area live main px-2" style={{ backgroundColor: '#2874ff' }}>
                <div className="d-flex text-area justify-content-start full">
                    {
                        isGuestYn === 'Y' ? 
                            broadcastOpenYN === 'Y' && performLiveInfo.liveStatus === 'Y' && 
                            <span className="d-flex align-middle green-text px-1 mr-2 small" title={t("방송중", { ns: "live" })} style={{ width: '100%', maxWidth: 'max-content', backgroundColor: 'rgba(255, 255, 255, 0.8)', paddingTop: '2px', paddingBottom: '2px', border: 'solid 1px white', borderRadius: '1rem' }}><b>{t("ONAIR", { ns: "live" })}</b></span> :
                            performLiveInfo.liveStatus === 'Y' && 
                            <span className="d-flex align-middle green-text px-1 mr-2 small" title={t("방송중", { ns: "live" })} style={{ width: '100%', maxWidth: 'max-content', backgroundColor: 'rgba(255, 255, 255, 0.8)', paddingTop: '2px', paddingBottom: '2px', border: 'solid 1px white', borderRadius: '1rem' }}><b>{t("ONAIR", { ns: "live" })}</b></span>
                    }
                    <span className="hiclass-text white-text" title={performLiveInfo.liveName}>
                        <b>{performLiveInfo.liveName}</b>
                    </span>
                    <span className="hiclass-text hiclasstv-yellow-text">
                        <b>&nbsp;{getEntryMemberCount('handsUp', performLiveInfo.list_member)}</b>&nbsp;/&nbsp;{performLiveInfo.list_member.length}
                    </span>
                </div>
                <div className="d-flex area-40 align-items-center ml-1 fit-content">
                    <button type="button" className="btn btn-md red white-text p-1 no-transition" onClick={() => handleSelectedLiveFunc({ selectedFunc: "LIVE_FINISH" })}>
                        {t("끝내기", { ns: "live" })}
                    </button>
                    <button type="button" className="d-flex p-1 rounded no-effect no-transition" title={t("더_보기", { ns: "classTalk" })} onClick={() => handleSelectedLiveFunc({ selectedFunc: "LIVE_MENU" })}>
                        <FaBars className="d-flex font-md white-text" />
                    </button>
                </div>
            </div> :
            <div className="title-area live main justify-content-start px-2" style={{ backgroundColor: '#2874ff' }}>
                <div className="d-flex area-10">
                    <button type="button" className="d-flex p-1 rounded no-effect no-transition" title="Home" onClick={() => handleSelectedLiveFunc({ selectedFunc: "LIVE_FINISH" })}>
                        <FaHome className="d-flex font-md white-text" />
                    </button>
                </div>
                <span className="hiclass-text white-text">
                    <b>{t("나와의_채팅", { ns: "chat" })}</b>
                </span>
            </div>
    );
}
export default PerformWatchingLiveInfo;
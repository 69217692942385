import React, { useEffect, useState } from "react";

import ClassTalkMassages from "../classtalk/ClassTalkMessages";
import EmoticItem from "../classtalk/emoticon/EmoticItem";
import EmoticList from "../classtalk/emoticon/EmoticList";
import UserPanelBox from "../classtalk/UserPanelBox";
import AudioRecord from "../classtalk/voiceMemo/AudioRecord";
import InputGroupBox from "../classtalk/InputGroupBox";
import FunctionList from "../classtalk/FunctionList";

const ClassTalk = ({ activeLive, openInfo, isFileDownloadingNow, isTeamUpOpened, isHandsupOpened, classTalkMsg, handleSelectedClassTalkFunc }) => {

    const [isPanelWindowOpened, setIsPanelWindowOpened] = useState(false);
    const [isQuizWindowOpened, setIsQuizWindowOpened] = useState(false);

    const [handsupMember, setHandsupMember] = useState([]);

    useEffect(() => {
        const list_member = activeLive.list_member;
        if (list_member !== undefined && list_member !== null) {
            initMembersInfo(list_member);
        }
    }, [activeLive.list_member]);

    const initMembersInfo = (list_member) => {
        let arr = list_member.map((value, idx) => {
            let member = list_member[idx];

            return value = {
                userSeq: member.userSeq,
                userNickname: member.userNickname,
                userJoinStatus: member.userJoinStatus,
                profileImgUrl: member.profileImgUrl,
                isSelected: false
            }
        });

        setHandsupMember(arr);
    }

    const togglePanelWindow = () => {
        setIsPanelWindowOpened(!isPanelWindowOpened);

        if (!isPanelWindowOpened) {
            // 패널 아이콘 클릭 시, function list 창 닫으려면 주석 풀기
            // handleSelectedClassTalkFunc({ selectedFunc: "FUNC_TOGGLE" });
        }
    }

    const onHandleSelectHandsupMember = (userSeq) => {
        let arr = handsupMember.map((member) => {
            let value = member.userSeq === userSeq ? {
                userSeq: member.userSeq,
                userNickname: member.userNickname,
                userJoinStatus: member.userJoinStatus,
                profileImgUrl: member.profileImgUrl,
                isSelected: !member.isSelected
            } : member;

            return value;
        });

        setHandsupMember(arr);
    }

    const onHandleClassTalkFunc = ({ selectedFunc, func, msgText, data, fileURL, filename }) => {
        let selectedMember = handsupMember.filter(member => member.isSelected).map(info => {
            return {
                userSeq: info.userSeq,
                userNickname: info.userNickname,
                profileImgUrl: info.profileImgUrl,
            }
        });

        handleSelectedClassTalkFunc({ selectedFunc, func, msgText, data, memberSeqList: selectedMember, fileURL, filename });
        initMembersInfo(handsupMember);
    }

    return (
        <div className="d-flex flex-column white" style={{ minHeight: '50%', height: '100%' }}>
            <ClassTalkMassages
                activeLive={activeLive}
                classTalkMsg={classTalkMsg}
                handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
            />
            {
                openInfo.selectedEmoticonSeq > -1 &&
                <EmoticItem
                    selIndex={openInfo.selectedEmoticonSeq}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
            {
                openInfo.isEmoticonSelected &&
                <EmoticList handleSelectedClassTalkFunc={handleSelectedClassTalkFunc} />
            }
            {
                isPanelWindowOpened &&
                <UserPanelBox
                    list_member={handsupMember}
                    togglePanelWindow={togglePanelWindow}
                    onHandleSelectHandsupMember={onHandleSelectHandsupMember}
                />
            }
            {
                openInfo.isAudioOpened ?
                    <AudioRecord
                        onHandleClassTalkFunc={onHandleClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    /> :
                    <InputGroupBox
                        selIndex={openInfo.selectedEmoticonSeq}
                        onHandleClassTalkFunc={onHandleClassTalkFunc}
                        handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                    />
            }
            {
                openInfo.isAddFunctionOpened &&
                <FunctionList
                    activeLive={activeLive}
                    isWhisper={false}
                    isChatting={true}
                    isFileDownloadingNow={isFileDownloadingNow}
                    isPanelWindowOpened={isPanelWindowOpened}
                    isTeamUpOpened={isTeamUpOpened}
                    isHandsupOpened={isHandsupOpened}
                    isActiveLive={activeLive.liveSeq > -1 ? true : false}
                    togglePanelWindow={togglePanelWindow}
                    onHandleClassTalkFunc={onHandleClassTalkFunc}
                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                />
            }
        </div>
    );
}

export default ClassTalk;
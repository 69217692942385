import React, { useEffect } from "react";

import LinkItem from "./item/LinkItem";

const LinkCard = ({ cardInfo }) => {

    return (
        cardInfo.data !== undefined && cardInfo.data !== null ?
            <div>
                <LinkItem info={cardInfo.data} />
            </div> :
            <></>
    )
}

export default LinkCard;
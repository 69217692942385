import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';

import { FaSearch, FaAngleLeft } from 'react-icons/fa';

import { term, publishStatus, termKind } from '../../lib/ConstCommand';

const WebPromotionInfo = ({ selectedPromotion, list_request, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const [durationOption, setDurationOption] = useState(term.map(option => ({...option})));
    const [publishOption, setPublishOption] = useState(publishStatus.map(option => ({...option})));
    const [itemOption, setItemOption] = useState([]);
    //const [showOption, setShowOption] = useState([{code:0, label:"15개씩"}, {code:1, label:"30개씩"}, {code:2, label:"50개씩"}]);

    const [selectedDurationOption, setSelectedDurationOption] = useState("");
    const [selectedPublishOption, setSelectedPublishOption] = useState("");
    const [selectedItemOption, setSelectedItemOption] = useState("");
    //const [selectedShowCountOption, setSelectedShowCountOption] = useState({idx:0, value:showOption[0].value});

    const [searchKeyword, setSearchKeyword] = useState("");
    const [isClearSearchCondition, setIsClearSearchCondition] = useState(false);

    useEffect(() => {
        setSelectedDurationOption({idx:0, value:durationOption[0].value});
        setSelectedPublishOption({idx:0, value:publishOption[0].value});
        setSelectedItemOption({idx:0, kind:"", itemSeq:0, itemName:""});
    }, []);

    useEffect(() => {
        let listArr = [];

        if (selectedPromotion.list_item !== undefined && selectedPromotion.list_item !== null && selectedPromotion.list_item.length > 0) {
            listArr = selectedPromotion.list_item.map(
                item => ({
                    ...item,
                    kind:"item",
                })
            );
        }
        
        if (selectedPromotion.isRequiredLicenseYN === "Y") {
            listArr.push({itemSeq:-1, kind:"license", itemName:"License Key"});
        }

        if (selectedPromotion.isRequiredEmailYN === "Y") {
            listArr.push({itemSeq:-1, kind:"email", itemName:"e-mail"});
        }

        setItemOption(listArr);
    }, [selectedPromotion]);

    useEffect(() => {
        if (itemOption !== undefined && itemOption !== null && itemOption.length > 0) {
            setSelectedItemOption({idx:0, kind:itemOption[0].kind, itemSeq:itemOption[0].itemSeq, itemName:itemOption[0].itemName});
        }

        clearSearchOption();
    }, [itemOption.length]);

    useEffect(() => {
        if (isClearSearchCondition === false) {
            requestSearch();
        } else {
            setIsClearSearchCondition(false);
        }
    }, [selectedDurationOption, selectedPublishOption]);

    useEffect(() => {
        if (isClearSearchCondition === true) {
            setSelectedDurationOption({idx:0, value:durationOption[0].value});
            setSelectedPublishOption({idx:0, value:publishOption[0].value});
            setSelectedItemOption({idx:0, kind:itemOption[0].kind, itemSeq:itemOption[0].itemSeq, itemName:itemOption[0].itemName});
        }
    }, [isClearSearchCondition]);    

    const calculateDuration = (option) => {        
        let today =  new Date(Date.now());
        let sumDays = 0;

        let currentMonth = today.getMonth() + 1;

        if (option % 12 === 0) {
            switch(option) {
                case 12:
                    if (today.getFullYear() % 4 === 0) {
                        if (today.getFullYear() % 100 === 0) {
                            console.log("100으로 나누어 떨어짐 - 평년");
                            sumDays = 365;
                        } else {
                            console.log("4로 나누어 떨어짐 - 윤년");
                            sumDays = 366;
                        }
                    } else {
                        console.log("평년입니다");
                        sumDays = 365;
                    }
                    break;

                case 24:
                    break;

                default:
                        break;
            }
        } else {
            let durationMonth = [];

            for (let i = 1; i <= option; i++) {
                durationMonth.push(currentMonth - i);
            }

            durationMonth.forEach(
                month => {
                    switch (month) {
                        case 2:
                            if (today.getFullYear() % 4 === 0) {
                                if (today.getFullYear() % 100 === 0) {
                                    console.log("100으로 나누어 떨어짐 - 평년");
                                    sumDays += 28;
                                } else {
                                    console.log("4로 나누어 떨어짐 - 윤년");
                                    sumDays += 29;
                                }
                            } else {
                                console.log("평년입니다");
                                sumDays += 28;
                            }
                            break;

                        case 1:
                        case 3:
                        case 5:
                        case 7:
                        case 8:
                        case 10:
                        case 12:
                            sumDays += 31;
                            break;

                        case 4:
                        case 6:
                        case 9:
                        case 11:
                            sumDays += 30;
                            break;

                        default:
                            break;
                    }
                }
            );
        } 

        return (format(new Date(new Date().setDate(today.getDate() - sumDays)), 'yyyyMMdd') + "-" + format(today, 'yyyyMMdd'));
    }

    const requestSearch = () => {
        let term;

        if (selectedDurationOption.value === termKind.ALL) {
            term = "all";
        } else {
            term = calculateDuration(selectedDurationOption.value);
        }

        if (searchKeyword === "") {
            //console.log("[기간] 또는 [발급 여부] 검색");
            handleSelectedFunc({
                selectedFunc: "GET_PROMOTION_REQUEST_LIST",
                promotionSeq: selectedPromotion.promotionSeq,
                info: {
                    term    : term,
                    issue   : selectedPublishOption.value,
                    item    : "all",
                    keyword : ""
                }
            });
        } else {
            switch(selectedItemOption.kind) {
                case "item" :
                    //console.log(selectedItemOption.itemName + " 선택 - 검색어 : " + searchKeyword);
                    handleSelectedFunc({
                        selectedFunc: "GET_PROMOTION_REQUEST_LIST",
                        promotionSeq: selectedPromotion.promotionSeq,
                        info:{
                            term    : term,
                            issue   : selectedPublishOption.value,
                            item    : selectedItemOption.itemSeq,
                            keyword : searchKeyword
                        }
                    });
                    break;

                case "email":
                    //console.log("e-mail 선택 - 검색어 : " + searchKeyword);
                    handleSelectedFunc({
                        selectedFunc: "GET_PROMOTION_REQUEST_LIST",
                        promotionSeq: selectedPromotion.promotionSeq,
                        info:{
                            term    : term,
                            issue   : selectedPublishOption.value,
                            item    : "emailAdd",
                            keyword : searchKeyword
                        }
                    });
                    break;

                case "license":
                    //console.log("license 선택 - 검색어 : " + searchKeyword);
                    handleSelectedFunc({
                        selectedFunc: "GET_PROMOTION_REQUEST_LIST",
                        promotionSeq: selectedPromotion.promotionSeq,
                        info:{
                            term    : term,
                            issue   : selectedPublishOption.value,
                            item    : "licenseCode",
                            keyword : searchKeyword
                        }
                    });
                    break;

                default:
                    break;
            }            
        }
    }

    const selectDurationOption = (e) => {
        setSelectedDurationOption({idx:e.target.value, value:durationOption[e.target.value].value});
        //console.log("durationOption[" + e.target.value + "] ------------ " + durationOption[e.target.value].value);
    }

    const selectPublishOption = (e) => {
        setSelectedPublishOption({idx:e.target.value, value:publishOption[e.target.value].value});
        //console.log("publishOption[" + e.target.value + "] ------------ " + durationOption[e.target.value].value);
    }
    
    const selectItemOption = (e) => {
        setSelectedItemOption({idx:e.target.value, kind:itemOption[e.target.value].kind, itemSeq:itemOption[e.target.value].itemSeq, itemName:itemOption[e.target.value].itemName});
        //console.log("itemOption[" + e.target.value + "] ---------------- ( kind : " + itemOption[e.target.value].kind + ", seq : " + itemOption[e.target.value].itemSeq + " )");
    }

    const onChangeSearchValue = (e) => {
        setSearchKeyword(e.target.value);
    }

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            handleSearchClick();
        }
    }

    const handleSearchClick = () => {
        requestSearch();
    }
    
    const clearSearchOption = () => {
        setSearchKeyword("");
        setIsClearSearchCondition(true);

        handleSelectedFunc({
            selectedFunc:"GET_PROMOTION_REQUEST_LIST", 
            promotionSeq:selectedPromotion.promotionSeq, 
            info:{
                term    : durationOption[0].value, 
                issue   : publishOption[0].value, 
                item    : "all",
                keyword : ""
            }
        });
    }

    const selectShowOption = (e) => {
        console.log(e.target.value);
        //setSelectedShowIdx(e.target.value);
    }

    return (
        <div className="web-main info-area">
            <div className="card p-2">
                <div className="d-flex my-2">
                    <button type="button" className="d-flex hiclasstv-btn b-none rounded align-items-center py-1 black-text" title={t("프로모션_목록_보기", { ns: "promotion" })} onClick={() => handleSelectedFunc({selectedFunc:"GOTO_PROMOTION_LIST"})}>
                        <FaAngleLeft className="d-flex m-1" />
                        <span className="small">{t("목록으로", { ns: "admin" })}</span>
                    </button>
                </div>
                <div className="d-flex flex-column border-bottom">
                    <span className="hiclasstv-blue-text font-xlg font-weight-bold my-2">
                        {selectedPromotion.promotionName}
                    </span>
                    <span className="d-flex my-1">
                        <div className="d-flex area-20">
                            <span className="font-md plus">ㆍ {t("프로모션_기간", { ns: "promotion" })}</span>
                        </div>
                        <b>{selectedPromotion.promotionStart_YMD} ~ {selectedPromotion.promotionEnd_YMD}</b>
                    </span>
                    <span className="d-flex my-1 mb-2">
                        <div className="d-flex area-20">
                            <span className="font-md plus">ㆍ {t("신청자_현황", { ns: "promotion" })}</span>
                        </div>
                        <span className="mr-3">{t("코드_발급_대기자", { ns: "promotion" })}&nbsp;<b>{selectedPromotion.cnt_request - selectedPromotion.cnt_issued}&nbsp;</b></span>|
                        <span className="mx-3">{t("코드_발급_완료", { ns: "promotion" })}&nbsp;<b>{selectedPromotion.cnt_issued}&nbsp;</b></span>|
                        <span className="ml-3">{t("TOTAL", { ns: "promotion" })}&nbsp;<b>{selectedPromotion.cnt_request}&nbsp;</b></span>
                    </span>
                </div>
                <div className="d-flex w-100 justify-content-around my-2">
                    <div className="d-flex align-items-center justify-content-center mx-2">
                        <span className="dark-grey-text small">{t("항목", { ns: "promotion" })}&nbsp;({list_request !== undefined && list_request !== null ? list_request.length : 0})</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex mx-1">
                            <select className="custom-select small my-1" style={{ fontSize: '0.8rem' }} value={selectedDurationOption.idx}  onChange={selectDurationOption}>
                                {
                                    durationOption.map(
                                        (info, index) => (
                                            <option value={index} key={"duration-" + index}>{info.name}</option>
                                        )
                                    )
                                }
                            </select>
                        </div>
                        {
                            selectedPromotion.isHasCouponYN === "Y" &&
                            <div className="d-flex mx-1">
                                <select className="custom-select small my-1" style={{ fontSize: '0.8rem' }} value={selectedPublishOption.idx} onChange={selectPublishOption}>
                                    {
                                        publishOption.map(
                                            (info, index) => (
                                                <option value={index} key={"publish-" + index}>{info.name}</option>
                                            )
                                        )
                                    }
                                </select>
                            </div>
                        }
                        <div className="d-flex mx-1">
                            <select className="custom-select small my-1" style={{ fontSize: '0.8rem' }} value={selectedItemOption.idx} onChange={selectItemOption}>
                                {
                                    itemOption.map(
                                        (info, index) => (
                                            <option value={index} key={"item-" + index}>{info.itemName}</option>
                                        )
                                    )
                                }
                            </select>
                        </div>
                        <div className="d-flex mx-1" style={{minWidth:'max-content'}}>
                            <div className="input-group align-items-center justify-content-center">
                                <input type="text" className="d-flex flex-fill web-search-input m-1 px-2 py-1 white" value={searchKeyword} style={{ fontSize: '0.8rem' }} placeholder={t("검색어_입력_메시지", { ns: "common" })} onChange={onChangeSearchValue} onKeyPress={handleKeyPress} />
                                <button className="d-flex hiclasstv-btn b-none" onClick={() => handleSearchClick()}>
                                    <FaSearch className="d-flex m-1" />
                                </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-center ml-2" style={{width:'50%', maxWidth:'max-content'}}>
                                <span className="button grey-text small" title={t("검색_조건_초기화", { ns: "promotion" })} onClick={() => clearSearchOption()}>
                                    {t("초기화", { ns: "common" })}
                                </span>
                            </div>
                        </div>
                    </div>
                    { /* 목록 개수 지정
                    <div className="d-flex mx-2" style={{ width: '10%', minWidth: 'max-content' }}>
                        <select className="custom-select small my-1" style={{ fontSize: '0.8rem' }} value={selectedShowCountOption.idx} onChange={selectShowOption}>
                            {
                                showOption.map(
                                    (info, index) => (
                                        <option value={index} key={"search-" + index}>{info.label}</option>
                                    )
                                )
                            }
                        </select>
                    </div>
                    */}
                </div>
                <table className="d-flex flex-column table-sm sub-list table-hover flex-list text-center mb-0 b-top">
                    <thead>
                        <tr className="d-flex justify-content-around">
                            {
                                selectedPromotion.list_item.map(
                                    item => (
                                        item.itemName === t("구매처", { ns: "promotion" }) ?
                                        <th className="area-15" key={"column" + item.itemSeq}><span><b>{item.itemName}</b></span></th> :
                                        <th className="area-10" key={"column" + item.itemSeq}><span><b>{item.itemName}</b></span></th>
                                    )
                                )
                            }
                            {
                                selectedPromotion.isRequiredLicenseYN === "Y" &&
                                <th className="area-20"><span><b>License Key</b></span></th>
                            }
                            {
                                selectedPromotion.isRequiredEmailYN === "Y" &&
                                <th className="area-20"><span><b>e-mail</b></span></th>
                            }
                            {
                                selectedPromotion.isHasCouponYN === "Y" &&
                                <th className="area-10"></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            selectedPromotion.cnt_request > 0 ?
                                list_request !== undefined && list_request !== null && list_request.length > 0 ?
                                    list_request.map(
                                        request => (
                                            <tr key={request.requestSeq} className="d-flex justify-content-around align-items-center">
                                                {
                                                    selectedPromotion.list_item.map(
                                                        (item, index) => (
                                                            item.itemName === t("구매처", { ns: "promotion" }) ?
                                                            <td className="d-flex justify-content-center b-none area-15" key={"member-item" + item.itemSeq}>
                                                                <span>{request.list_item[index]}</span>
                                                                {/*<span>{(request.list_item.find(rItem => rItem.itemSeq === item.itemSeq)).itemInfo}</span>*/}
                                                            </td> :
                                                            <td className="d-flex justify-content-center b-none area-10" key={"member-item" + item.itemSeq}>
                                                                <span>{request.list_item[index]}</span>
                                                                {/*<span>{(request.list_item.find(rItem => rItem.itemSeq === item.itemSeq)).itemInfo}</span>*/}
                                                            </td>
                                                        )
                                                    )
                                                }
                                                {
                                                    selectedPromotion.isRequiredLicenseYN === "Y" &&
                                                    <td className="d-flex justify-content-center b-none area-20"><span>{request.requestLicense}</span></td>
                                                }
                                                {
                                                    selectedPromotion.isRequiredEmailYN === "Y" &&
                                                    <td className="d-flex justify-content-center b-none area-20"><span>{request.requestEmail}</span></td>
                                                }
                                                {
                                                    selectedPromotion.isHasCouponYN === "Y" &&
                                                    <td className="d-flex justify-content-center b-none area-10">
                                                        {
                                                            request.isPublishedCouponCodeYN === "Y" ?
                                                                <span className="grey-text">{t("발급완료", { ns: "promotion" })}</span> :
                                                                <button type="button" className="btn btn-sm hiclasstv-yellow white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "PUBLISH_PROMOTION_COUPON_CODE", promotionSeq: selectedPromotion.promotionSeq, requestSeq: request.requestSeq })}><b>{t("코드_발급", { ns: "promotion" })}</b></button>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    ) :
                                    <tr className="d-flex w-100">
                                        <td className="d-flex w-100 p-0">
                                            <div className="d-flex w-100 view m-3 justify-content-center grey-text font-md">
                                                {t("검색_조건_참여_고객_없음", { ns: "promotion" })}
                                            </div>
                                        </td>
                                    </tr> :
                                <tr className="d-flex w-100">
                                    <td className="d-flex w-100 p-0 white">
                                        <div className="d-flex w-100 view white m-3 justify-content-center grey-text font-md">
                                            {t("참여_고객_없음", { ns: "promotion" })}
                                        </div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default WebPromotionInfo;
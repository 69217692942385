import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FaSitemap } from 'react-icons/fa';

import ConstData from '../../lib/ConstData';

import WebUserLoginResetAlertModal from './modal/WebUserLoginResetAlertModal';
import WebUserPWResetAlertModal from './modal/WebUserPWResetAlertModal';
import WebSubDomainConfigAdminUserAlertModal from './modal/WebSubDomainConfigAdminUserAlertModal';
import WebSubDomainCreateAdminUserAlertModal from './modal/WebSubDomainCreateAdminUserAlertModal';
import WebWarningAlertModal from './modal/WebWarningAlertModal';
import WebListArrowButton from '../etc/button/WebListArrowButton';
import WebConfigSubDomainList from './list/WebConfigSubDomainList';

const WebMyConfigSubDomainList = ({ userSeq, userKind, isStandardType, list_subDomainInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();
    let history = useHistory();

    const [isOpened, setIsOpened] = useState(false);

    const [isUserResetStatusModalOpened, setIsUserResetStatusModalOpened] = useState(false);
    const [isPWResetStatusModalOpened, setIsPWResetStatusModalOpened] = useState(false);
    const [isConfigAdminModalOpened, setIsConfigAdminModalOpened] = useState(false);
    const [isCreateAdminModalOpened, setIsCreateAdminModalOpened] = useState(false);
    const [isWarningAlertOpened, setIsWarningAlertOpened] = useState(false);
    const [lWarningKind, setWarningKind] = useState("");

    const [selectedMemberInfo, setSelectedMemberInfo] = useState({ mode: "", subDomainSeq: -1, subDomainName: "", userSeq: -1, userNickname: "", userProfile: "", profileImgUrl: "", isConfigAdmin: false, isCreateAdmin: false });

    useEffect(() => {
        handleSelectedFunc({ selectedFunc: "GET_MANAGE_INFO" });

        return () => {
            //console.log('WebMyConfigSubDomainList() component invisible');
        }
    }, []);

    useEffect(() => {
        if (list_subDomainInfo.length > 0) {
            setIsOpened(true);
        }
    }, [list_subDomainInfo]);

    const toggleModalOpened = (isModalOpened, mode, subDomainSeq, memberInfo, userStatus) => {
        if (userStatus !== ConstData.LOGIN_STATUS.LOG_OUT) {
            if (mode === "web" && memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
                console.log('웹에서 자기 자신을 로그아웃할 수 없음!!!!');
                toggleWarningAlertModalOpened(true, "RESET_USER_STATUS");
            } else {
                if (isModalOpened) {
                    setSelectedMemberInfo({
                        mode, subDomainSeq, subDomainName: "", userSeq: memberInfo.userSeq, userNickname: memberInfo.userNickname,
                        userProfile: memberInfo.userProfile, profileImgUrl: memberInfo.profileImgUrl, isConfigAdmin: memberInfo.isConfigAdmin
                    });
                } else {
                    setSelectedMemberInfo({ mode: "", subDomainSeq: -1, subDomainName: "", userSeq: -1, userNickname: "", userProfile: "", profileImgUrl: "", isConfigAdmin: false });
                }

                setIsUserResetStatusModalOpened(isModalOpened);
            }
        } else {
            console.log('toggleModalOpened() userStatus is LOG_OUT');
        }
    }

    const togglePWModalOpened = (isModalOpened, subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            console.log('자기 자신의 비밀번호는 회원정보 수정을 이용해야함!!!!');
            toggleWarningAlertModalOpened(true, "RESET_USER_PW");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    mode: "", subDomainSeq, subDomainName: "", userSeq: memberInfo.userSeq, userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile, profileImgUrl: memberInfo.profileImgUrl, isConfigAdmin: memberInfo.isConfigAdmin
                });
            } else {
                setSelectedMemberInfo({ mode: "", subDomainSeq: -1, subDomainName: "", userSeq: -1, userNickname: "", userProfile: "", profileImgUrl: "", isConfigAdmin: false });
            }

            setIsPWResetStatusModalOpened(isModalOpened);
        }
    }

    const toggleCreateSubDomainAdminModalOpened = (isModalOpened, subDomainSeq, subDomainName, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            toggleWarningAlertModalOpened(true, "SUB_DOMAIN_CREATE_ADMIN");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    mode: "", subDomainSeq, subDomainName, userSeq: memberInfo.userSeq, userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile, profileImgUrl: memberInfo.profileImgUrl, isCreateAdmin: memberInfo.isCreateAdmin
                });
            } else {
                setSelectedMemberInfo({ mode: "", subDomainSeq: -1, subDomainName: "", userSeq: -1, userNickname: "", userProfile: "", profileImgUrl: "", isCreateAdmin: false });
            }

            setIsCreateAdminModalOpened(isModalOpened);
        }
    }

    const toggleSubDomainAdminModalOpened = (isModalOpened, subDomainSeq, subDomainName, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.userSeq === userSeq) {
            toggleWarningAlertModalOpened(true, "SUB_DOMAIN_ADMIN");
        } else {
            if (isModalOpened) {
                setSelectedMemberInfo({
                    mode: "", subDomainSeq, subDomainName, userSeq: memberInfo.userSeq, userNickname: memberInfo.userNickname,
                    userProfile: memberInfo.userProfile, profileImgUrl: memberInfo.profileImgUrl, isConfigAdmin: memberInfo.isConfigAdmin
                });
            } else {
                setSelectedMemberInfo({ mode: "", subDomainSeq: -1, subDomainName: "", userSeq: -1, userNickname: "", userProfile: "", profileImgUrl: "", isConfigAdmin: false });
            }

            setIsConfigAdminModalOpened(isModalOpened);
        }
    }

    const toggleWarningAlertModalOpened = (isModalOpened, warningKind) => {
        if (isModalOpened) {
            setWarningKind(warningKind);
        } else {
            setWarningKind("");
        }

        setIsWarningAlertOpened(isModalOpened);
    }

    const onClickResetUserStatus = (mode, subDomainSeq, userSeq) => {
        handleSelectedFunc({ selectedFunc: "RESET_USER_STATUS", selectedUserSeq: userSeq, subDomainSeq, mode });
        setIsUserResetStatusModalOpened(false);
    }

    const onClickResetUserPW = (subDomainSeq, userSeq, password) => {
        handleSelectedFunc({ selectedFunc: "RESET_USER_PW", selectedUserSeq: userSeq, subDomainSeq, password });
        setIsPWResetStatusModalOpened(false);
    }

    const onClickConfigAdminIcon = (subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.isConfigAdmin) {
            handleSelectedFunc({ selectedFunc: "REMOVE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "ADD_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        }

        setIsConfigAdminModalOpened(false);
    }

    const onClickCreateAdminIcon = (subDomainSeq, memberInfo) => {
        if (memberInfo !== undefined && memberInfo !== null && memberInfo.isCreateAdmin) {
            handleSelectedFunc({ selectedFunc: "REMOVE_CREATE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "ADD_CREATE_SUB_DOMAIN_ADMIN", subDomainSeq, selectedUserSeq: memberInfo.userSeq });
        }

        setIsCreateAdminModalOpened(false);
    }

    const goToPassListPage = ({ subDomainSeq }) => {
        if (subDomainSeq === 1) {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "USER", subDomainSeq, selectedUserSeq: userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "SUB_DOMAIN", subDomainSeq });
        }
    }

    const goToConfigPage = ({ subDomain_info }) => {
        if (subDomain_info !== undefined && subDomain_info !== null) {
            // handleSelectedFunc({ selectedFunc: "CONFIG_SUB_DOMAIN", subDomain_info });
            history.push(`/admin/subdomain/${subDomain_info.subDomainSeq}`);
        }
    }

    const goToConfigMemberPage = ({ subDomain_info }) => {
        if (subDomain_info !== undefined && subDomain_info !== null) {
            // handleSelectedFunc({ selectedFunc: "CONFIG_SUB_DOMAIN_MEMBER", subDomain_info });
            history.push(`/admin/subdomain/${subDomain_info.subDomainSeq}/member`);
        }
    }

    return (
        <div className="web-main admin list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <span className="d-flex white-text mx-1">
                            <FaSitemap className="font-lg mr-2" aria-hidden="true" />{t("관리대상_서브_도메인_목록", { ns: "admin" })}
                        </span>
                    </div>
                    <WebListArrowButton toggle_id={'myConfigSubDomainList'} list_array={list_subDomainInfo} isOpened={isOpened} />
                </div>
                <div className="px-2">
                    <UncontrolledCollapse toggler="#myConfigSubDomainList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                        <WebConfigSubDomainList
                            userKind={userKind}
                            isStandardType={isStandardType}
                            list_subDomain={list_subDomainInfo}
                            goToConfigPage={goToConfigPage}
                            goToConfigMemberPage={goToConfigMemberPage}
                            goToPassListPage={goToPassListPage}
                            toggleModalOpened={toggleModalOpened}
                            togglePWModalOpened={togglePWModalOpened}
                            toggleSubDomainAdminModalOpened={toggleSubDomainAdminModalOpened}
                            toggleCreateSubDomainAdminModalOpened={toggleCreateSubDomainAdminModalOpened}
                        />
                    </UncontrolledCollapse>
                </div>
            </div>
            {
                isUserResetStatusModalOpened &&
                <WebUserLoginResetAlertModal
                    isModalOpened={isUserResetStatusModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleModalOpened}
                    onClickResetUserStatus={onClickResetUserStatus}
                />
            }
            {
                isPWResetStatusModalOpened &&
                <WebUserPWResetAlertModal
                    isModalOpened={isPWResetStatusModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={togglePWModalOpened}
                    onClickResetUserPW={onClickResetUserPW}
                />
            }
            {
                isConfigAdminModalOpened &&
                <WebSubDomainConfigAdminUserAlertModal
                    isModalOpened={isConfigAdminModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleSubDomainAdminModalOpened}
                    onClickAdminIcon={onClickConfigAdminIcon}
                />
            }
            {
                isCreateAdminModalOpened &&
                <WebSubDomainCreateAdminUserAlertModal
                    isModalOpened={isCreateAdminModalOpened}
                    selectedMemberInfo={selectedMemberInfo}
                    toggleModalOpened={toggleCreateSubDomainAdminModalOpened}
                    onClickAdminIcon={onClickCreateAdminIcon}
                />
            }
            {
                isWarningAlertOpened &&
                <WebWarningAlertModal
                    isModalOpened={isWarningAlertOpened}
                    warningKind={lWarningKind}
                    toggleModalOpened={toggleWarningAlertModalOpened}
                />
            }
        </div>
    );
}
export default WebMyConfigSubDomainList;
import React from 'react';
import { useTranslation } from "react-i18next";

const ConfigLiveListTitleTemplate = ({handleSelectedFunc}) => {
    const { t } = useTranslation();

    return(
        <div className="title-area main white-text" style={{ backgroundColor: '#2874ff' }}>
            <div className="d-flex align-items-center" style={{ position: 'absolute', left: '0' }} onClick={() => handleSelectedFunc({ selectedFunc: "CONFIG" })}>
                <span className="button px-2"><b>{t("닫기", { ns: "common" })}</b></span>
            </div>
            <div className="d-flex align-items-center">
                <b>{t("클래스_관리", { ns: "live" })}</b>
            </div>
            <div />
        </div>
    );
}

export default ConfigLiveListTitleTemplate;
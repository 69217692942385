import React, { useState, useEffect } from 'react';
import { UncontrolledCollapse } from 'reactstrap';
import { useTranslation } from "react-i18next";

import { FaUserAstronaut, FaIdBadge, FaSistrix } from 'react-icons/fa';

import DefaultProfileImage from '../user/profile/DefaultProfileImage';
import ConstData from '../../lib/ConstData';
import Alerts from '../etc/list/Alerts';

const WebSearchFriendWindow = ({ searchResult, searchResultId, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const [lUserSelectArr, setUserSelectArr] = useState("");
    const [isSearchFriendOpened, setSearchFriendOpened] = useState(false);
    const [isSearchFriendSuccess, setSearchFriendSuccess] = useState(false);
    const [searchFunc, setSearchFunc] = useState("NONE");
    const [searchValue, setSearchValue] = useState("");

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    useEffect(() => {
        let userArr = searchResult.map(list => ({userSeq: list.userSeq, isSelect: false}));
        setUserSelectArr(userArr);
    }, [searchResult]);

    useEffect(() => {
        let userArr = searchResultId.map(list => ({userSeq: list.userSeq, isSelect: false}));
        setUserSelectArr(userArr);
    }, [searchResultId]);

    useEffect(() => {
        setUserSelectArr("");
    }, [searchFunc]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            handleSearchClick();
        }
    }

    const searchWindowOpen = () => {
        console.log("search open window");
        setSearchFriendOpened(!isSearchFriendOpened);
    }

    const addByKeyword = () => {
        console.log("add by keyword");

        if(searchFunc === "KEYWORD") {
            setSearchFunc("NONE");
            searchResult = [];
        } else {
            setSearchFunc("KEYWORD");
            setSearchValue("");
            searchResultId = [];
            setSearchFriendSuccess(false);
        }
    }

    const addByID = () => {
        if(searchFunc === "ID") {
            setSearchFunc("NONE");
            searchResultId = [];
        } else {
            setSearchFunc("ID");
            setSearchValue("");
            searchResult = [];
            setSearchFriendSuccess(false);
        }
    }
    
    const onChangeSelectValue = (e) => {
        const { id, checked } = e.target;

        if (lUserSelectArr && lUserSelectArr.length > 0) {
            const arr = lUserSelectArr.map(
                (info) => {
                    // eslint-disable-next-line eqeqeq
                    let value = info.userSeq == id ? { userSeq : info.userSeq, isSelect : checked } : info;
                    return value;
                }
            );
            setUserSelectArr(arr);
        }
    }

    const onChangeSearchValue = (e) => {
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
        setSearchFriendSuccess(false);
        setSearchValue(e.target.value);
    }

    const onClickApply = (selectedFunc) => {
        let arr = [];

        if (lUserSelectArr !== undefined && lUserSelectArr !== null && lUserSelectArr.length > 0) {
            lUserSelectArr.forEach(element => {
                if(element.isSelect) {
                    arr.push(element.userSeq);
                }
            });

            handleSelectedFunc({selectedFunc:selectedFunc, friendUserSEQList:arr, mode:"web"});
        }
    }

    const handleSearchClick = () => {
        if (searchValue === "") {
            setAlertKind(ConstData.ALERT_KIND.DANGER);
            setAlertMessage(t("검색어_입력_메시지", { ns: "common"}));
        } else {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");

            switch(searchFunc) {
                case "KEYWORD" :
                    setSearchFriendSuccess(true);
                    handleSelectedFunc({selectedFunc:"KEYWORD", searchValue:searchValue, mode:"web"});
                    break;
    
                case "ID" :
                    setSearchFriendSuccess(true);
                    handleSelectedFunc({selectedFunc:"ID", searchValue:searchValue, mode:"web" });
                    break;
    
                default : break;
            }
        }        
    }
    
    return (
        <div className="web-main">
            <div className="d-flex flex-column w-100">
                <div className="text-right">
                    <button type="button" className={"outline bold outline-hiclasstv-blue py-2 px-3 m-1" + (isSearchFriendOpened === true ? " active" : "")} id="searchUserToggle" onTouchEnd={()=>searchWindowOpen()} onClick={() => searchWindowOpen()}>
                        <b>{t("사용자검색", { ns: "friend" })}</b>
                    </button>
                </div>
                <UncontrolledCollapse toggler="#searchUserToggle" defaultOpen={false}>
                    <div className="card web-list-card friend border-primary py-3">
                        {
                            <div className="web-list-card justify-content-around">
                                <button type="button" className={"outline bold outline-hiclasstv-blue d-flex flex-column align-items-center p-2" + (searchFunc === "KEYWORD" ? " active" : "")} style={{borderRadius:'0.3rem', width:'35%'}} id="searchKeyword" onClick={() => addByKeyword()}>
                                    <FaUserAstronaut className="font-xlg my-1" aria-hidden="true" />
                                    <span>
                                        <b>{t("키워드로_검색", { ns: "friend" })}</b>
                                    </span>
                                </button>
                                <button type="button" className={"outline bold outline-hiclasstv-blue d-flex flex-column align-items-center p-2" + (searchFunc === "ID" ? " active" : "")} style={{borderRadius:'0.3rem', width:'35%'}} id="searchID" onClick={() => addByID()}>
                                    <FaIdBadge className="font-xlg my-1" aria-hidden="true" />
                                    <span>
                                        <b>{t("ID로_검색", { ns: "friend" })}</b>
                                    </span>
                                </button>
                            </div>
                        } {
                            searchFunc !== "NONE" ?
                                <div className="web-list-card friend flex-column w-100 p-0" style={{maxWidth:'700px'}}>
                                    <div className="d-flex input-group justify-content-center pb-3">
                                        <input type="text" id="inputSearchValue" className="d-flex flex-fill web-search-input mr-2" placeholder={t("검색어_입력_메시지", { ns: "common"})} onChange={onChangeSearchValue} value={searchValue} onKeyPress={handleKeyPress} />
                                        <button type="button" className="outline bold outline-hiclasstv-blue px-2 py-1" onClick={() => handleSearchClick(searchFunc)} >
                                            <FaSistrix className="d-flex font-md" />
                                        </button>
                                    </div>
                                    <Alerts kind={lAlertKind} message={lAlertMessage} />
                                    {
                                        isSearchFriendSuccess === true ?
                                            <>
                                                {
                                                    searchFunc === "KEYWORD" ?
                                                        searchResult !== undefined && searchResult !== null ?
                                                            <>
                                                                {
                                                                    searchResult.length > 0 ?
                                                                        <>
                                                                            {
                                                                                searchResult.map(
                                                                                    user => (
                                                                                        <div className="d-flex w-100 p-2" key={user.userSeq}>
                                                                                            <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                                                                                                <DefaultProfileImage url={user.profileImgUrl} alt={user.userNickname} />
                                                                                            </div>
                                                                                            <div className="d-flex px-2 full">
                                                                                                <div className="d-flex flex-column text-area">
                                                                                                    <span className="hiclass-text" title={user.userNickname}><b>{user.userNickname}</b></span>
                                                                                                    <span className="hiclass-text small grey-text" title={user.userProfile}>{user.userProfile}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center justify-content-center area-20 fit-content">
                                                                                                {
                                                                                                    user.isCanApply === "Y" ?
                                                                                                        <div className="custom-control custom-checkbox">
                                                                                                            <input type="checkbox" className="d-flex custom-control-input" id={user.userSeq} onChange={onChangeSelectValue} />
                                                                                                            <label className="custom-control-label" htmlFor={user.userSeq}></label>
                                                                                                        </div> :
                                                                                                        <div className="custom-control custom-checkbox">
                                                                                                            <input type="checkbox" className="d-flex custom-control-input" id={"disable_" + user.userSeq} checked={false} disabled />
                                                                                                            <label className="custom-control-label" htmlFor={"disable_" + user.userSeq}></label>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                )
                                                                            }
                                                                            <div className="d-flex justify-content-center py-2">
                                                                                <button type="button" className="btn btn-md green white-text" onClick={() => onClickApply("APPLY")}>
                                                                                    <b>{t("신청", { ns: "common" })}</b>
                                                                                </button>
                                                                            </div>
                                                                        </> :
                                                                        <div className="view m-3 text-center grey-text">
                                                                            {t("검색_결과_없음_메시지", { ns: "common" })}
                                                                        </div>
                                                                }
                                                            </> :
                                                            <div className="view m-3 text-center grey-text">
                                                                {t("검색_결과_없음_메시지", { ns: "common" })}
                                                            </div> :
                                                    searchFunc === "ID" ?
                                                        searchResultId !== undefined && searchResultId !== null && searchResultId.length > 0 ?
                                                            <>
                                                                {
                                                                    searchResultId.map(
                                                                        user => (
                                                                            <div className="d-flex w-100 px-2" key={user.userSeq}>
                                                                                <div className="d-flex align-items-center justify-content-center area-15 fit-content">
                                                                                    <DefaultProfileImage url={user.profileImgUrl} alt={user.userNickname} />
                                                                                </div>
                                                                                <div className="d-flex px-2 full">
                                                                                    <div className="d-flex flex-column text-area">
                                                                                    <span className="hiclass-text" title={user.userNickname}><b>{user.userNickname}</b></span>
                                                                                    <span className="hiclass-text small grey-text" title={user.userProfile}>{user.userProfile}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex align-items-center justify-content-end area-20 fit-content">
                                                                                    {
                                                                                        user.isCanApply === "Y" ?
                                                                                            <button type="button" className="btn btn-sm green white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "APPLY", friendUserSEQList: [user.userSeq], mode: "web" })}>
                                                                                                {t("신청", { ns: "common" })}
                                                                                            </button> :
                                                                                            <span className="green-text small px-1"><b>{t("친구", { ns: "friend" })}</b></span>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                }                                                                            
                                                            </> :
                                                            <div className="view m-3 text-center grey-text">
                                                                {t("검색_결과_없음_메시지", { ns: "common" })}
                                                            </div> :
                                                    <></>
                                                }
                                            </> :
                                            <></>
                                    }
                                </div> :
                                <></>
                        }
                    </div>
                </UncontrolledCollapse>                
            </div>
        </div>
    );
}

export default WebSearchFriendWindow;
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaServer, FaPlusCircle } from 'react-icons/fa';
import { UncontrolledCollapse } from 'reactstrap';
import WebLiteServerList from './list/WebLiteServerList';
import WebAddLiteServer from './WebAddLiteServer';
import WebPagination from '../etc/WebPagination';

const WebLiteServerConfig = ({ selectedSubDomainInfo, list_configLiteServer, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState((page - 1) * limit);
    const [blockNum, setBlockNum] = useState(0);

    const [isAddLiteServerOK, setIsAddLiteServerOK] = useState(false);

    useEffect(() => {
        //console.log('WebLiteLocalServerConfig visible');

        handleSelectedFunc({ selectedFunc: "GET_LITE_SERVER_LIST" });
        
        return () => {
            //console.log('WebLiteLocalServerConfig invisible');
        }
    }, []);

    useEffect(() => {
        let offset = (page - 1) * limit;
        setOffset(offset);
    }, [page, limit]);

    useEffect(() => {
        setIsAddLiteServerOK(true);

        return () => {
            setPage(1);
            setOffset((page - 1) * limit);
        }
    }, [list_configLiteServer]);

    const onChangeIsAddLiteServerOK = () => {
        setIsAddLiteServerOK(!isAddLiteServerOK);
    }

    return (
        <div className="web-main admin list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaServer className="font-lg mr-2" aria-hidden="true" />{t("라이트_로컬_서버", { ns: "admin" })}
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addLiteLocalServer">
                            <FaPlusCircle className="font-md mr-1" />{t("라이트_로컬_서버_등록", { ns: "admin" })}
                        </button>
                    </div>
                </div>
                <UncontrolledCollapse toggler="#addLiteLocalServer" defaultOpen={false}>
                    <WebAddLiteServer 
                        selectedSubDomainInfo={selectedSubDomainInfo}
                        isAddLiteServerOK={isAddLiteServerOK}
                        onChangeIsAddLiteServerOK={onChangeIsAddLiteServerOK}
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <WebPagination
                    total={list_configLiteServer.length <= 0 ? 1 : list_configLiteServer.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    blockNum={blockNum}
                    setBlockNum={setBlockNum}
                />
                <WebLiteServerList
                    // list_liteServer={list_configLiteServer}
                    list_liteServer={list_configLiteServer.slice(offset, offset + limit)}
                    handleSelectedFunc={handleSelectedFunc}
                />
            </div>
        </div>
    )
};
export default WebLiteServerConfig;
import React from "react";
import { FaRegWindowClose } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const EmoticItem = ({ selIndex, handleSelectedClassTalkFunc }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-center p-2" style={{ width: '100%', backgroundColor: '#ffe066' }}>
            {/* <img src={require(`../images/et${selIndex.toString().padStart(2,"0")}.png`)} alt="" /> */}
            <img src={`/images/classtalk/emoticon/et${selIndex.toString().padStart(2, "0")}.png`} alt="" />
            <span className="ml-2" data-toggle="tooltip" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "EMOTICON_CANCLE" })}>
                <FaRegWindowClose className="font-md" style={{ color: '#343a40' }} />
            </span>
        </div>
    );
}

export default EmoticItem;
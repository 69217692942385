import { createAction, handleActions } from "redux-actions"
import produce from "immer";
import createRequestThunk from "../lib/createRequestThunk";
import i18n from "../locales/config";

import { enTokenCMD, ctRcvKind, enMQType, ctBubbleKind } from "../lib/classTalk/CodeEnum";
import { ctActionType, ctMsgType, stCardType, stQnAItemType } from "../lib/ConstCommand";
import ConstData from "../lib/ConstData";

import {
    callEoForSendAction,
    callEoForSendWhisperAction
} from "../lib/EoBrowserSendUtil";

import {
    clear_download_files,
    get_download_file_list,
    upload_file
} from "../lib/api/file";

export const TALK_STATE_INIT                    = "classTalk/TALK_STATE_INIT";

export const APPEND_QUIZ_MESSAGE                = "classTalk/APPEND_QUIZ_MESSAGE";
export const APPEND_ALERT_MESSAGE_WITH_RESTAPI  = "classTalk/APPEND_ALERT_MESSAGE_WITH_RESTAPI";
export const APPEND_ALERT_MESSAGE               = "classTalk/APPEND_ALERT_MESSAGE";

const APPEND_SEND_MESSAGE                       = "classTalk/APPEND_SEND_MESSAGE";
const APPEND_SEND_AUDIO_MEMO                    = "classTalk/APPEND_SEND_AUDIO_MEMO";
const APPEND_SEND_INK_MEMO_FROM_HICLASS         = "classTalk/APPEND_SEND_INK_MEMO_FROM_HICLASS";
const APPEND_SEND_FILE_SHARE                    = "classTalk/APPEND_SEND_FILE_SHARE";
const APPEND_SEND_FILE_SHARE_SUCCESS            = "classTalk/APPEND_SEND_FILE_SHARE_SUCCESS";
const APPEND_RECEIVE_MESSAGE                    = "classTalk/APPEND_RECEIVE_MESSAGE";
const APPEND_MESSAGE_FROM_HICLASS               = "classTalk/APPEND_MESSAGE_FROM_HICLASS";

export const appendSendMessage                  = createAction(APPEND_SEND_MESSAGE);
export const appendSendAudioMemo                = createAction(APPEND_SEND_AUDIO_MEMO);
export const appendSendInkMemoFromHiClass       = createAction(APPEND_SEND_INK_MEMO_FROM_HICLASS);
export const appendSendFileShare                = createRequestThunk(APPEND_SEND_FILE_SHARE, upload_file);
export const appendReceiveMessage               = createAction(APPEND_RECEIVE_MESSAGE);
export const appendMessageFromHiclass           = createAction(APPEND_MESSAGE_FROM_HICLASS);

const RECEIVE_QUIZ_ANSWER                       = "classTalk/RECEIVE_QUIZ_ANSWER";
const SET_PASS_POSSIBLE                         = "classTalk/SET_PASS_POSSIBLE";
const SHOW_QUIZ_RESPONSE_INFO                   = "classTalk/SHOW_QUIZ_RESPONSE_INFO";
const SHOW_QUIZ_RESPONSE_RESULT                 = "classTalk/SHOW_QUIZ_RESPONSE_RESULT";
const QUIZ_RESPONSE_FINISH                      = "classTalk/QUIZ_RESPONSE_FINISH";

const GET_DOWNLOAD_FILE_LIST                    = "classTalk/GET_DOWNLOAD_FILE_LIST";
const GET_DOWNLOAD_FILE_LIST_SUCCESS            = "classTalk/GET_DOWNLOAD_FILE_LIST_SUCCESS";
const RECEIVE_DOWNLOAD_FILE_ACTION_RESULT       = "classTalk/RECEIVE_DOWNLOAD_FILE_ACTION_RESULT";
const CLEAR_DOWNLOAD_FILES                      = "classTalk/CLEAR_DOWNLOAD_FILES";
const CLEAR_DOWNLOAD_FILES_SUCCESS              = "classTalk/CLEAR_DOWNLOAD_FILES_SUCCESS";

export const receiveQuizAnswer                  = createAction(RECEIVE_QUIZ_ANSWER);
export const setPassPossible                    = createAction(SET_PASS_POSSIBLE);
export const showQuizResponseInfo               = createAction(SHOW_QUIZ_RESPONSE_INFO);
export const showQuizResponseResult             = createAction(SHOW_QUIZ_RESPONSE_RESULT);
export const quizResponseFinish                 = createAction(QUIZ_RESPONSE_FINISH);

export const getDownloadFileList                = createRequestThunk(GET_DOWNLOAD_FILE_LIST, get_download_file_list);
export const rcvDownloadFileActionResult        = createAction(RECEIVE_DOWNLOAD_FILE_ACTION_RESULT);
export const clearDownloadFiles                 = createRequestThunk(CLEAR_DOWNLOAD_FILES, clear_download_files);

const EMOTICON_TOGGLE_OPENED                    = "classTalk/EMOTICON_TOGGLE_OPENED";
const EMOTICON_SELECTED_CANCEL                  = "classTalk/EMOTICON_SELECT_CANCEL";
const SELECT_EMOTICON_SEQ                       = "classTalk/SELECT_EMOTICON_SEQ";

const ADD_FUNCTION_TOGGLE_OPENED                = "classTalk/ADD_FUNCTION_TOGGLE_OPENED";
const SELECT_FUNCTION_SEQ                       = "classTalk/SELECT_FUNCTION_SEQ";

export const emoticonSelectedToggle             = createAction(EMOTICON_TOGGLE_OPENED);
export const emoticonSelectedCancel             = createAction(EMOTICON_SELECTED_CANCEL);
export const selectEmoticonSeq                  = createAction(SELECT_EMOTICON_SEQ);

export const addFunctionOpenedToggle            = createAction(ADD_FUNCTION_TOGGLE_OPENED);
export const selectFunctionSeq                  = createAction(SELECT_FUNCTION_SEQ);

// function menu
const VOICE_MEMO_TOGGLE_CLOSED                  = "classTalk/VOICE_MEMO_TOGGLE_CLOSED";
const INK_MEMO_TOGGLE_OPENED                    = "classTalk/INK_MEMO_TOGGLE_OPENED";

const FILE_SHARE_TOGGLE                         = "classTalk/FILE_SHARE_TOGGLE";
const FILE_OPEN_TOGGLE                          = "classTalk/FILE_OPEN_TOGGLE";
const FILE_DIR_REMOVE_TOGGLE                    = "classTalk/FILE_DIR_REMOVE_TOGGLE";
const FILE_ACTION_RESULT                        = "classTalk/FILE_ACTION_RESULT";
const UPDATE_FILE_DOWNLOADING                   = "classTalk/UPDATE_FILE_DOWNLOADING";
const UPDATE_FILE_DOWNLOADING_FOR_DIR           = "classTalk/UPDATE_FILE_DOWNLOADING_FOR_DIR";

export const voiceMemoClosedToggle              = createAction(VOICE_MEMO_TOGGLE_CLOSED);
export const inkMemoOpenedToggle                = createAction(INK_MEMO_TOGGLE_OPENED);

export const fileShareToggle                    = createAction(FILE_SHARE_TOGGLE);
export const fileOpenToggle                     = createAction(FILE_OPEN_TOGGLE);
export const fileDirRemoveToggle                = createAction(FILE_DIR_REMOVE_TOGGLE);
export const fileActionResult                   = createAction(FILE_ACTION_RESULT);
export const updateFileDownloading              = createAction(UPDATE_FILE_DOWNLOADING);
export const updateFileDownloadingForDir        = createAction(UPDATE_FILE_DOWNLOADING_FOR_DIR);

const IMG_THUMBNAIL_CLICK                       = "classTalk/IMG_THUMBNAIL_CLICK";

export const imgThumbnailClick                  = createAction(IMG_THUMBNAIL_CLICK);

const OPEN_WHISPER_POPUP                        = "classTalk/OPEN_WHISPER_POPUP";
const SEND_WHISPER_INIT_DATA                    = "classTalk/SEND_WHISPER_INIT_DATA";
const RECEIVE_WHISPER_MESSAGE                   = "classTalk/RECEIVE_WHISPER_MESSAGE";

export const openWhisperPopup                   = createAction(OPEN_WHISPER_POPUP);
export const sendWhisperInitData                = createAction(SEND_WHISPER_INIT_DATA);
export const appendReceiveWhisperMessage        = createAction(RECEIVE_WHISPER_MESSAGE);

export const CLOSE_CLASSTALK_MENU_MODAL         = "calssTalk/CLOSE_CLASSTALK_MENU_MODAL";

export const closeClasstalkMenuModal            = createAction(CLOSE_CLASSTALK_MENU_MODAL);

const initialState = {
    isEmoticonSelected          : false,
    selectedEmoticonSeq         : -1,
    selectedFunctionSeq         : -1,
    isAudioOpened               : false,
    audioStatus                 : "STOPED",
    
    isAddFunctionOpened         : false,
    isInkMemoOpened             : false, // ink memo 창

    isQuizWindowOpened          : false, // Quiz 창
    isQuizResponseInfoOpened    : false,
    isQuizResponseResultOpened  : false,

    isFileDirRemoveModalOpened  : false,
    isFileDownloadingNow        : false,

    responseInfo                : "",

    receivedMsg                 : [],
    whisperMsg                  : [],

    isInkOn                     : false,    // footer menu
};

const classTalk = handleActions({
    [TALK_STATE_INIT]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected          : false,
            selectedEmoticonSeq         : -1,
            selectedFunctionSeq         : -1,
            isAudioOpened               : false,
            audioStatus                 : "STOPED",

            isAddFunctionOpened         : false,
            isInkMemoOpened             : false, // ink memo 창

            isQuizWindowOpened          : false, // Quiz 창
            isQuizResponseInfoOpened    : false,
            isQuizResponseResultOpened  : false,

            responseInfo                : "",

            receivedMsg                 : [],
            whisperMsg                  : [],

            isInkOn                     : false,    // footer menu
        }
    },

    // ======================================================= Quiz / Card
    [APPEND_QUIZ_MESSAGE]: (state, action) => {
        const { nextPhaseData } = action.payload;
        const { msgText, information, userNickname } = nextPhaseData;
        console.log("APPEND_QUIZ_MESSAGE - ", action.payload);

        callEoForSendAction("quiz", {
            kind        : "quiz",
            action      : "sendQuizInfo",
            information : { ...information, msgIndex: state.receivedMsg.length },
            userNickname
        }, "");

        if (msgText !== undefined && msgText !== null && msgText !== "") {
            return produce (state, draft => {
                draft.receivedMsg = draft.receivedMsg.concat(msgText);
            });
        } else {
            return {
                ...state
            }
        }
    },

    [RECEIVE_QUIZ_ANSWER]: (state, action) => {
        const { quiz_info, userSeq } = action.payload;
        console.log("RECEIVE_QUIZ_ANSWER - ", action.payload);

        return produce (state, draft => {
            const dialog = draft.receivedMsg[quiz_info.msgIndex];
            if (dialog && dialog.kind === ctBubbleKind.SendMsg && dialog.msgInfo.kind === stCardType.Quiz && dialog.msgInfo.cardInfo.seq === quiz_info.cardSeq) {
                const card_info = dialog.msgInfo.cardInfo;

                if (card_info && card_info.response) {
                    const response_info = card_info.response.find(info => info.userSeq === userSeq);
                    
                    if (!response_info) {
                        let response = {
                            userSeq,
                            answer: quiz_info.answer
                        }

                        card_info.response.push(response);
                    } else {
                        console.log("이미 response가 있음 - ", response_info);
                    }
                }
            }
        });
    },

    [SET_PASS_POSSIBLE]: (state, action) => {
        const { data } = action.payload;
        const { cardCode } = data;
        console.log("SET_PASS_POSSIBLE - ", action.payload);

        return produce (state, draft => {
            const msg_info = draft.receivedMsg.find(info => info.kind === ctBubbleKind.RcvMsg && info.msgInfo.kind === "card_template" && info.msgInfo.cardInfo.code === cardCode && info.msgInfo.cardInfo.isCurrent);
            if (msg_info) {
                const card_info = msg_info.msgInfo.cardInfo;
                if (card_info && card_info.isCurrent) {
                    card_info.isPossiblePass = true;
                }
            }
        });
    },

    [SHOW_QUIZ_RESPONSE_INFO]: (state, action) => {
        const { idx } = action.payload;
        console.log("SHOW_QUIZ_RESPONSE_INFO - ", action.payload);

        if (state.receivedMsg[idx].msgInfo.kind === ctMsgType.Quiz) {
            let card_info = state.receivedMsg[idx].msgInfo.cardInfo;
            
            if (card_info.type === stCardType.Quiz) {
                let quiz_info = card_info.data;

                if (quiz_info.data[0].type === stQnAItemType.Question) {
                    let isShortForm = quiz_info.data[0].exYN === "Y" ? false : true;

                    let response_info = card_info.response;
                    if (!isShortForm) {                // 객관식
                        let list_example = "";

                        if (quiz_info.data[2].type === stQnAItemType.Example) {
                            list_example = quiz_info.data[2].data.map(({ kind, type, data, ...rest }) => rest);

                            response_info.forEach(info => {
                                list_example.forEach(ex => {
                                    if (info.answer.no === ex.no) {
                                        if (ex.count) ex.count++;
                                        else ex.count = 1;
                                    } else {
                                        if (!ex.count) ex.count = 0;
                                    }
                                });
                            });
                        }

                        return {
                            ...state,
                            isQuizResponseInfoOpened : true,
                            responseInfo : {
                                idx,
                                quiz_info,
                                list_example,
                                list_targetMember: card_info.list_member,
                                responseCount: response_info.length
                            }
                        }
                    } else {                        // 주관식
                        return {
                            ...state,
                            isQuizResponseInfoOpened : true,
                            responseInfo : {
                                idx,
                                quiz_info,
                                list_targetMember: card_info.list_member,
                                responseCount: response_info.length,
                            }
                        }
                    }
                } 
            }
        }

        console.log("SHOW_QUIZ_RESPONSE_INFO - err) 예기치 못한 상황");
        return {
            ...state,
        }
    },

    [SHOW_QUIZ_RESPONSE_RESULT]: (state, action) => {
        const { idx } = action.payload;
        console.log("SHOW_QUIZ_RESPONSE_RESULT - ", action.payload);

        if (state.receivedMsg[idx].msgInfo.kind === ctMsgType.Quiz) {
            let card_info = state.receivedMsg[idx].msgInfo.cardInfo;
            //console.log(card_info);

            if (card_info.type === stCardType.Quiz) {
                let quiz_info = card_info.data;
                if (quiz_info.data[0].type === stQnAItemType.Question) {

                    return {
                        ...state,
                        responseInfo : {
                            quiz_info,
                            list_targetMember: card_info.list_member,
                            response : card_info.response
                        },
                        isQuizResponseInfoOpened : false,
                        isQuizResponseResultOpened : true
                    }
                } else {
                    return {
                        ...state,
                        responseInfo : "",
                        list_targetMember: card_info.list_member,
                        isQuizResponseInfoOpened : false,
                        isQuizResponseResultOpened : true
                    }
                }
            }
        }

        return {
            ...state,
        }
    },

    [QUIZ_RESPONSE_FINISH]: (state, action) => {
        const { liveSeq, idx, quizSeq, userNickname, list_member } = action.payload;
        console.log("QUIZ_RESPONSE_FINISH - ", action.payload);

        callEoForSendAction("quiz", {
            kind        : "quiz",
            action      : "quizResponseFinish",
            information : { liveSeq, idx, quizSeq, list_member },
            userNickname
        }, "");

        /* return {
            state,
        } */

        return produce(state, draft => {
            console.log("idx - ", idx);
            const rcvMsg = draft.receivedMsg[idx];
            console.log("rcvMsg = draft.receivedMsg[idx] - ", draft.receivedMsg[idx]);
            if (rcvMsg !== undefined && rcvMsg !== null) {
                console.log("rcvMsg !== undefined && rcvMsg !== null");
                const msgInfo = rcvMsg.msgInfo;
                if (msgInfo !== undefined && msgInfo !== null) {
                    console.log("msgInfo !== undefined && msgInfo !== null");
                    const cardInfo = msgInfo.cardInfo;
                    console.log("const cardInfo = msgInfo.cardInfo");
                    if (cardInfo !== undefined && cardInfo !== null) {
                        console.log("cardInfo !== undefined && cardInfo !== null");
                        cardInfo.isFinishedQuiz = true;
                        console.log("cardInfo.isFinishedQuiz = true");
                    }
                }
            }
        });
    },

    [GET_DOWNLOAD_FILE_LIST_SUCCESS]: (state, action) => {
        const { teacherSeq, liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("GET_DOWNLOAD_FILE_LIST_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { list_files } = action.payload;

            if (list_files && list_files.length > 0) {
                let list_newFiles = list_files.map(row => {
                    return ConstData.MAIN_HOST_ADD + ConstData.DOWNLOAD_DIR + row;
                    // return ConstData.DOWNLOAD_DIR + row;
                });

                console.log("list_newFiles => ", list_newFiles);

                const { list_member } = action.payload;

                if (list_member && list_member.length > 0) {
                    let clist_member = list_member.map(memRow => ({
                        userSeq           : memRow.USER_SEQ,
                        userNickname      : memRow.USER_NICKNAME,
                        userProfile       : memRow.USER_PROFILE,
                        profileImgUrl     : memRow.USER_PROFILE_IMG,
                    }));

                    console.log("clist_member => ", clist_member);

                    const actionData = {
                        list_fileURL: list_newFiles,
                        list_member: clist_member
                    };

                    // 여기서 c# 함수 호출하기!
                    callEoForSendAction("fileDownloadDir", actionData);
                }

                return {
                    ...state,
                    receivedMsg: state.receivedMsg.concat(
                        { 
                            kind    : ctBubbleKind.RcvInfo, 
                            msgInfo : { 
                                rcvKind     : ctRcvKind.infoPentalk,
                                //kind        : "file_share",
                                rcvName     : "Pentalk",
                                emoticSeq   : state.selectedEmoticonSeq,
                                text        : i18n.t("공유파일_다운로드_시작", { ns: "classTalk" }),
                                urlInfo     : ""
                            }
                        }
                    ),
                    isFileDownloadingNow    : true
                }
            } else {
                console.log("다운로드 가능한 파일 X");

                return {
                    ...state,
                    receivedMsg: state.receivedMsg.concat(
                        { 
                            kind    : ctBubbleKind.RcvInfo, 
                            msgInfo : { 
                                rcvKind     : ctRcvKind.infoPentalk,
                                rcvName     : "Pentalk",
                                emoticSeq   : state.selectedEmoticonSeq,
                                text        : i18n.t("공유파일_없음", { ns: "classTalk" }),
                                urlInfo     : ""
                            }
                        }
                    ),
                    isFileDownloadingNow    : false
                }
            }
        } else {
            return {
                ...state,
                receivedMsg: state.receivedMsg.concat(
                    { 
                        kind    : ctBubbleKind.RcvInfo, 
                        msgInfo : { 
                            rcvKind     : ctRcvKind.infoPentalk,
                            rcvName     : "Pentalk",
                            emoticSeq   : state.selectedEmoticonSeq,
                            text        : i18n.t("공유파일_다운로드_요청_실패", { ns: "classTalk" }),
                            urlInfo     : ""
                        }
                    }
                ),
                isFileDownloadingNow    : false
            }
        }
    },

    [RECEIVE_DOWNLOAD_FILE_ACTION_RESULT]: (state, action) => {
        const { actionKind } = action.payload;
        console.log("RECEIVE_DOWNLOAD_FILE_ACTION_RESULT - ", action.payload);

        if (actionKind === "fileDownloadFinish") {
            return {
                ...state,
                receivedMsg: state.receivedMsg.concat(
                    { 
                        kind    : ctBubbleKind.Info, 
                        msgInfo : { 
                            rcvKind     : ctRcvKind.rcvPentalk,
                            rcvName     : "Pentalk",
                            emoticSeq   : state.selectedEmoticonSeq,
                            text        : i18n.t("공유파일_다운로드_완료", { ns: "classTalk" }),
                            urlInfo     : ""
                        }
                    }
                ),
                isFileDownloadingNow    : false
            }
        } else if (actionKind === "fileDownloadCanceled") {
            return {
                ...state,
                receivedMsg: state.receivedMsg.concat(
                    { 
                        kind    : ctBubbleKind.Info, 
                        msgInfo : { 
                            rcvKind     : ctRcvKind.rcvPentalk,
                            rcvName     : "Pentalk",
                            emoticSeq   : state.selectedEmoticonSeq,
                            text        : i18n.t("공유파일_다운로드_취소", { ns: "classTalk" }),
                            urlInfo     : ""
                        }
                    }
                ),
                isFileDownloadingNow    : false
            }
        } else {
            return {
                ...state,
                isFileDownloadingNow    : false
            }
        }
    },

    [CLEAR_DOWNLOAD_FILES_SUCCESS]: (state, action) => {
        const { teacherSeq, liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CLEAR_DOWNLOAD_FILES_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                console.log("다운로드 폴더 삭제 완료");

                return {
                    ...state,
                    isFileDirRemoveModalOpened  : false,
                    receivedMsg: state.receivedMsg.concat(
                        { 
                            kind    : ctBubbleKind.Info, 
                            msgInfo : { 
                                rcvKind     : ctRcvKind.rcvPentalk,
                                rcvName     : "Pentalk",
                                emoticSeq   : state.selectedEmoticonSeq,
                                text        : i18n.t("공유파일_폴더_삭제_완료", { ns: "classTalk" }),
                                urlInfo     : ""
                            } 
                        }
                    ),
                }
            } else {
                console.log("다운로드 폴더 삭제 실패");

                return {
                    ...state,
                    isFileDirRemoveModalOpened  : false,
                    receivedMsg: state.receivedMsg.concat(
                        { 
                            kind    : ctBubbleKind.Info, 
                            msgInfo : { 
                                rcvKind     : ctRcvKind.rcvPentalk,
                                rcvName     : "Pentalk",
                                emoticSeq   : state.selectedEmoticonSeq,
                                text        : i18n.t("공유파일_폴더_삭제_실패", { ns: "classTalk" }),
                                urlInfo     : ""
                            } 
                        }
                    ),
                }
            }
        } else {
            return {
                ...state,
                isFileDirRemoveModalOpened  : false,
                receivedMsg: state.receivedMsg.concat(
                    { 
                        kind    : ctBubbleKind.Info, 
                        msgInfo : { 
                            rcvKind     : ctRcvKind.rcvPentalk,
                            rcvName     : "Pentalk",
                            emoticSeq   : state.selectedEmoticonSeq,
                            text        : i18n.t("공유파일_폴더_삭제_요청_실패", { ns: "classTalk" }),
                            urlInfo     : ""
                        } 
                    }
                ),
            }
        }
    },

    // ======================================================= ClassTalk

    [APPEND_SEND_MESSAGE]: (state, action) => {
        const { msgText, userNickname, memberSeqList } = action.payload;
        console.log("APPEND_SEND_MESSAGE - ", action.payload);

        let bubbleKind = ctBubbleKind.SendMsg;
        let rcvKind = ctRcvKind.Teacher;
        let newMsgInfo = {
            kind    : bubbleKind,
            msgInfo : { 
                rcvKind,
                text        : msgText, 
                emoticSeq   : state.selectedEmoticonSeq, 
                urlInfo     : ""
            }
        };

        if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
            bubbleKind = ctBubbleKind.SendWhisper;
            rcvKind = ctRcvKind.sendWhisper;
            callEoForSendAction("message_talk", { text: msgText, emoticSeq: state.selectedEmoticonSeq, userNickname }, JSON.stringify(memberSeqList.map(info => info.userSeq)));

            return produce (state, draft => {
                memberSeqList.forEach(member => {
                    const whisper_info = draft.whisperMsg.find(info => info.stdSeq === member.userSeq);

                    if (whisper_info) {
                        whisper_info.msg.push(newMsgInfo);
                    } else {
                        draft.whisperMsg.push({
                            stdSeq      : member.userSeq,
                            stdNickname : member.userNickname,
                            profileImg  : member.profileImgUrl,
                            msg         : [newMsgInfo]
                        });
                    }
                });

                draft.receivedMsg     = draft.receivedMsg.concat({
                    kind    : bubbleKind,
                    msgInfo : { 
                        rcvKind,
                        text        : msgText, 
                        emoticSeq   : state.selectedEmoticonSeq, 
                        urlInfo     : "" ,
                        data        : memberSeqList
                    }
                });
                draft.isEmoticonSelected  = false;
                draft.selectedEmoticonSeq = -1;
                draft.isAddFunctionOpened = false;
            });
        } else {
            callEoForSendAction("message_talk", { text: msgText, emoticSeq: state.selectedEmoticonSeq, userNickname }, "");

            return {
                ...state,
                receivedMsg     : state.receivedMsg.concat({
                    kind    : bubbleKind,
                    msgInfo : { 
                        rcvKind     : rcvKind,
                        text        : msgText, 
                        emoticSeq   : state.selectedEmoticonSeq, 
                        urlInfo     : "" ,
                        data        : memberSeqList
                    }
                }),
                isEmoticonSelected  : false,
                selectedEmoticonSeq : -1,
                isAddFunctionOpened : false
            }
        }
    },

    [APPEND_SEND_AUDIO_MEMO]: (state, action) => {
        const { userNickname, userSeq, url, data, memberSeqList } = action.payload;
        console.log("APPEND_SEND_AUDIO_MEMO - ", action.payload);

        let bubbleKind = ctBubbleKind.SendMsg;
        let rcvKind = ctRcvKind.Teacher;
        let newMsgInfo = {
            kind: bubbleKind,
            msgInfo: {
                rcvKind,
                kind: "audio_memo",
                text: i18n.t("음성메모", { ns: "classTalk" }),
                urlInfo: url
            }
        };

        if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
            bubbleKind = ctBubbleKind.SendWhisper;
            rcvKind = ctRcvKind.sendWhisper;
            callEoForSendAction("audio_memo", { voiceData: data, userSeq, userNickname }, JSON.stringify(memberSeqList.map(info => info.userSeq)));

            return produce (state, draft => {
                memberSeqList.forEach(member => {
                    const whisper_info = draft.whisperMsg.find(info => info.stdSeq === member.userSeq);

                    if (whisper_info) {
                        whisper_info.msg.push(newMsgInfo);
                    } else {
                        draft.whisperMsg.push({
                            stdSeq      : member.userSeq,
                            stdNickname : member.userNickname,
                            profileImg  : member.profileImgUrl,
                            msg         : [newMsgInfo]
                        });
                    }
                });

                draft.receivedMsg = draft.receivedMsg.concat({
                    kind: bubbleKind,
                    msgInfo: {
                        rcvKind,
                        kind: "audio_memo",
                        text: i18n.t("음성메모", { ns: "classTalk" }),
                        urlInfo: url,
                        data: memberSeqList
                    }
                });
                
                draft.isAudioOpened       = false;
                draft.isAddFunctionOpened = false;
            });
        } else {
            callEoForSendAction("audio_memo", { voiceData:data, userSeq, userNickname }, "");
            
            return {
                ...state,
                receivedMsg         : state.receivedMsg.concat({
                    kind            : bubbleKind,
                    msgInfo         : { 
                        rcvKind,
                        kind        : "audio_memo", 
                        text        : i18n.t("음성메모", { ns: "classTalk" }), 
                        urlInfo     : url,
                        data        : memberSeqList
                    }
                }),
                isAudioOpened       : false,
                isAddFunctionOpened : false,
            }
        }
    },

    [APPEND_SEND_INK_MEMO_FROM_HICLASS]: (state, action) => {
        const { imgDataUrl, isWhisper, memberSeqList } = action.payload;
        console.log("APPEND_SEND_INK_MEMO_FROM_HICLASS - ", action.payload);

        let bubbleKind = ctBubbleKind.SendMsg;
        let rcvKind = ctRcvKind.Teacher;
        let msgInfo = {
            kind : bubbleKind,
            msgInfo : {
                rcvKind,
                kind: "ink_memo",
                text: i18n.t("잉크메모", { ns: "classTalk" }),
                urlInfo: imgDataUrl
            }
        };
        
        if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
            bubbleKind = ctBubbleKind.SendWhisper;
            rcvKind = ctRcvKind.sendWhisper;

            return produce (state, draft => {
                memberSeqList.forEach(member => {
                    const whisper_info = draft.whisperMsg.find(info => info.stdSeq === Number(member.userSeq));

                    if (whisper_info) {
                        whisper_info.msg.push(msgInfo);
                    } else {
                        draft.whisperMsg.push({
                            stdSeq      : Number(member.userSeq),
                            stdNickname : member.userNickname,
                            profileImg  : member.profileImgUrl,
                            msg         : [msgInfo]
                        });
                    }
                });

                if (!isWhisper) {
                    draft.receivedMsg = draft.receivedMsg.concat({
                        kind: bubbleKind,
                        msgInfo: {
                            ...msgInfo,
                            data: memberSeqList,
                            rcvKind
                        }
                    });
                    draft.isInkMemoOpened = false;
                    draft.toolMode = "PEN";
                    draft.inkMemoFile = "";
                    draft.isAddFunctionOpened = false;
                }
            });
        } else {
            return {
                ...state,
                receivedMsg         : state.receivedMsg.concat(msgInfo),
                isInkMemoOpened     : false,
                toolMode            : "PEN",
                inkMemoFile         : "",
                isAddFunctionOpened : false
            }
        }
    },

    [APPEND_SEND_FILE_SHARE_SUCCESS]: (state, action) => {
        const { filename, userNickname, memberSeqList } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("APPEND_SEND_FILE_SHARE_SUCCESS - ", action.payload);
        console.log(action.parms);

        if (isSuccessed) {
            const { isUploadSuccess } = action.payload;
            
            if (isUploadSuccess) {
                const { path } = action.payload;

                let bubbleKind = ctBubbleKind.SendMsg;
                let rcvKind = ctRcvKind.Teacher;
                let newMsgInfo = {
                    kind : bubbleKind,
                    msgInfo : {
                        rcvKind,
                        kind    : "file_share",
                        text    : filename + i18n.t("파일_전송_완료_알림", { ns: "classTalk" }),
                        urlInfo : path
                    }
                };

                if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
                    bubbleKind = ctBubbleKind.SendWhisper;
                    rcvKind = ctRcvKind.sendWhisper;

                    return produce (state, draft => {
                        memberSeqList.forEach(member => {
                            const whisper_info = draft.whisperMsg.find(info => info.stdSeq === member.userSeq);
                            callEoForSendAction("whisperFileShare", { filename, userNickname }, JSON.stringify(memberSeqList.map(info => info.userSeq)));
                            
                            if (whisper_info) {
                                whisper_info.msg.push(newMsgInfo);
                            } else {
                                draft.whisperMsg.push({
                                    stdSeq      : member.userSeq,
                                    stdNickname : member.userNickname,
                                    profileImg  : member.profileImgUrl,
                                    msg         : [newMsgInfo]
                                });
                            }
                        });

                        draft.receivedMsg = draft.receivedMsg.concat({
                            kind: bubbleKind,
                            msgInfo: {
                                rcvKind,
                                kind    : "file_share",
                                text    : filename + i18n.t("파일_전송_완료_알림", { ns: "classTalk" }),
                                urlInfo : path,
                                data    : memberSeqList
                            }
                        });
                        draft.isAddFunctionOpened = false;
                    });
                } else {
                    return {
                        ...state,
                        receivedMsg         : state.receivedMsg.concat(newMsgInfo),
                        isAddFunctionOpened : false
                    }
                }
            } else {
                console.log("isUploadSuccess is ", isUploadSuccess);
                return {
                    ...state,
                }
            }
        } else {
            console.log("isSuccessed is ",isSuccessed);
            return {
                ...state
            }
        }
    },

    [APPEND_RECEIVE_MESSAGE]: (state, action) => {
        const msgInfo = action.payload;
        console.log("APPEND_RECEIVE_MESSAGE - ", action.payload);

        return {
            ...state,
            receivedMsg: state.receivedMsg.concat(
                { kind: ctBubbleKind.RcvMsg, msgInfo }
            )
        }
    }, 

    [APPEND_MESSAGE_FROM_HICLASS]: (state, action) => {
        const { msgInfo } = action.payload;
        console.log("APPEND_MESSAGE_FROM_HICLASS - ", action.payload);

        return {
            ...state,
            receivedMsg: state.receivedMsg.concat(msgInfo)
        }
    },

    [APPEND_ALERT_MESSAGE_WITH_RESTAPI]: (state, action) => {
        const { msgText } = action.parms;
        console.log("APPEND_ALERT_MESSAGE_WITH_RESTAPI - ", action.parms);

        return {
            ...state,
            receivedMsg: state.receivedMsg.concat(
                { 
                    kind    : ctBubbleKind.Info, 
                    msgInfo : { 
                        rcvKind     : ctRcvKind.rcvPentalk,
                        rcvName     : "Pentalk",
                        emoticSeq   : state.selectedEmoticonSeq,
                        text        : msgText,
                        urlInfo     : ""
                    } 
                }
            ),
            isEmoticonSelected : false,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [APPEND_ALERT_MESSAGE]: (state, action) => {
        const msgText = action.payload;
        console.log("APPEND_ALERT_MESSAGE - chat => ", action.payload);

        return {
            ...state,
            receivedMsg: state.receivedMsg.concat(
                { 
                    kind    : ctBubbleKind.Info, 
                    msgInfo : { 
                        rcvKind     : ctRcvKind.rcvPentalk,
                        rcvName     : "Pentalk",
                        emoticSeq   : state.selectedEmoticonSeq,
                        text        : msgText,
                        urlInfo     : ""
                    } 
                }
            ),
            isEmoticonSelected : false,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [EMOTICON_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected  : !state.isEmoticonSelected,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [EMOTICON_SELECTED_CANCEL]: (state, action) => {
        return {
            ...state,
            selectedEmoticonSeq : -1,
            isAddFunctionOpened : false,
        }
    },

    [SELECT_EMOTICON_SEQ]: (state, action) => {
        const selectedEmoticonSeq = action.payload;

        return {
            ...state,
            isEmoticonSelected  : false,
            selectedEmoticonSeq : selectedEmoticonSeq,
            isAddFunctionOpened : false,
        }
    },

    [SELECT_FUNCTION_SEQ]: (state, action) => {
        //const selectedFunctionSeq = action.payload;
        const { func, chatRoomSeq, userSeq, userNickname, mode, memberSeqList } = action.payload;
        console.log("SELECT_FUNCTION_SEQ - ", action.payload);

        let preSelectedFuncSeq = state.selectedFunctionSeq;
        let preQuizWindowOpenedStatus = state.isQuizWindowOpened;
        
        if (func === ctActionType.InkMemo) {
            const actionData = {
                userNickname,
                userSeq,
                command     : enTokenCMD.chat_Memo,
                isWhisper   : false
            }

            if (memberSeqList !== undefined && memberSeqList !== null && memberSeqList.length > 0) {
                //callEoForSendAction("memoTalk", actionData, JSON.stringify(memberSeqList.map(info => info.userSeq)));
                callEoForSendAction("memoTalk", actionData, JSON.stringify(memberSeqList));
            } else {
                callEoForSendAction("memoTalk", actionData, "");
            }

        } else if (func === ctActionType.FileShare) {
            
        } else if (func === ctActionType.PullScreen) {
            const actionData = {
                mode,
                chatRoomSeq,
                userNickname,
                teacherSeq  : userSeq,
                userSeq     : 0,
                command     : enTokenCMD.page_GetArtifact,
                restAPIURL  : ConstData.REST_API_HOST_ADD + "/upload"
            }

            callEoForSendAction("getArtifact", actionData);
        } else if (func === ctActionType.Quiz) {
            preQuizWindowOpenedStatus = !state.isQuizWindowOpened;
        } 

        return {
            ...state,
            selectedFunctionSeq     : preSelectedFuncSeq,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : false,
            isAudioOpened           : func === ctActionType.VoiceMemo,
            isQuizWindowOpened      : preQuizWindowOpenedStatus
        }
    },

    [ADD_FUNCTION_TOGGLE_OPENED]: (state, action) => {
        return {
            ...state,
            isEmoticonSelected      : false,
            selectedEmoticonSeq     : -1,
            isAddFunctionOpened     : !state.isAddFunctionOpened
        }
    },

    // 음성톡
    [VOICE_MEMO_TOGGLE_CLOSED]: (state, action) => {
        return {
            ...state,
            isAudioOpened : false
        }
    },

    // 메모톡
    [INK_MEMO_TOGGLE_OPENED]: (state, action) => {
        const {selectedFunc, userNickname, userSeq } = action.payload;
        console.log("INK_MEMO_TOGGLE_OPENED : ", selectedFunc);

        if(selectedFunc !== undefined && selectedFunc !== null) { // ink memo 말풍선 클릭한 경우
            const { imgUrl } = action.payload;
            const actionData = {
                userNickname,
                userSeq,
                command     : enTokenCMD.chat_Memo,
                inkURL      : imgUrl,
                isWhisper   : false
            }

            callEoForSendAction("inkMemo", actionData, "");

            return {
                ...state
            }
        } else { 
            console.log("이런경우가 있나..?");
            
            return {
                ...state
            }
        }
    },

    [FILE_SHARE_TOGGLE]: (state, action) => {
        const { fileURL, idx } = action.payload;
        console.log(`FILE_SHARE_TOGGLE - idx[${idx}], fileURL[${fileURL}]`);

        const actionData = {
            fileURL,
            filename : fileURL.substr(fileURL.lastIndexOf("/") + 1),
            idx
        };

        callEoForSendAction("fileShare", actionData);

        return {
            ...state,
        }
    },

    [FILE_OPEN_TOGGLE]: (state, action) => {
        const { fileURL, fileType } = action.payload;
        console.log(`FILE_OPEN_TOGGLE - fileType[${fileType}], fileURL[${fileURL}]`);

        const actionData = {
            filename : fileURL.substr(fileURL.lastIndexOf("/") + 1),
            fileType
        };

        callEoForSendAction("fileOpen", actionData);

        return {
            ...state,
        }
    },

    [FILE_DIR_REMOVE_TOGGLE]: (state, action) => {
        const { isModalOpened } = action.payload;
        console.log(`FILE_DIR_REMOVE_TOGGLE - isModalOpened[${isModalOpened}]`);

        return {
            ...state,
            isFileDirRemoveModalOpened  : isModalOpened
        }
    },

    [FILE_ACTION_RESULT]: (state, action) => {
        const { idx } = action.payload;
        console.log("FILE_ACTION_RESULT - ", idx);

        return produce(state, draft => {
            const rcvMsg = draft.receivedMsg[idx];
            if (rcvMsg !== undefined && rcvMsg !== null) {
                if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                    rcvMsg.msgInfo.isDownloading = false;
                    rcvMsg.msgInfo.isDownloaded = true;
                    //rcvMsg.msgInfo.downUrlInfo = fileURL;
                    const fileURL = rcvMsg.msgInfo.urlInfo;

                    const ext = fileURL.substring(fileURL.lastIndexOf("."), fileURL.length);
                    switch (ext) {
                        case ".mp4" :
                        case ".wmv" :
                            rcvMsg.msgInfo.type = enMQType.Video;
                            break;

                        case ".mp3" :
                        case ".wma" :
                            rcvMsg.msgInfo.type = enMQType.Audio;
                            break;

                        case ".im3" :
                            rcvMsg.msgInfo.type = enMQType.InkMedia;
                            break;

                        default:
                            rcvMsg.msgInfo.type = enMQType.Files;
                            break;
                    }
                    
                    rcvMsg.msgInfo.text = i18n.t("파일_다운로드_완료_알림", { ns: "classTalk" });
                }
            }
        });
    },

    [UPDATE_FILE_DOWNLOADING]: (state, action) => {
        const { idx, progress } = action.payload;
        console.log("UPDATE_FILE_DOWNLOADING");

        return produce(state, draft => {
            const rcvMsg = draft.receivedMsg[idx];
            if (rcvMsg !== undefined && rcvMsg !== null) {
                if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                    rcvMsg.msgInfo.isDownloading = true;
                    rcvMsg.msgInfo.isDownloaded = false;
                    rcvMsg.msgInfo.kind = "file_share"; // change kind
                    //rcvMsg.msgInfo.text = i18n.t("파일_다운로드_중_알림", { ns: "classTalk" });
                    rcvMsg.msgInfo.progress = progress;
                }
            }
        });
    },

    [UPDATE_FILE_DOWNLOADING_FOR_DIR]: (state, action) => {
        const { progress } = action.payload;
        console.log("UPDATE_FILE_DOWNLOADING_FOR_DIR - progress => ", progress);

        return produce(state, draft => {
            const idx = draft.receivedMsg.length - 1;
            const rcvMsg = draft.receivedMsg[idx];
            if (rcvMsg !== undefined && rcvMsg !== null) {
                if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                    rcvMsg.msgInfo.isDownloading = true;
                    rcvMsg.msgInfo.isDownloaded = false;
                    if (rcvMsg.msgInfo.kind !== "file_share") rcvMsg.msgInfo.kind = "file_share"; // change kind
                    //rcvMsg.msgInfo.text = i18n.t("파일_다운로드_중_알림", { ns: "classTalk" });
                    rcvMsg.msgInfo.progress = progress;
                }
            }
        });
    },

    [IMG_THUMBNAIL_CLICK]: (state, action) => {
        const { imgDataUrl, idx } = action.payload;
        const { receivedMsg } = state;
        console.log("IMG_THUMBNAIL_CLICK - msg idx => ", idx);

        const rcvMsg = receivedMsg[idx];
        if (rcvMsg !== undefined && rcvMsg !== null) {
            console.log("rcvMsg is not null");
            if (rcvMsg.msgInfo !== undefined && rcvMsg.msgInfo !== null) {
                const userSeq = rcvMsg.msgInfo.rcvUserSeq;
                const userNickname = rcvMsg.msgInfo.rcvName;
                console.log("rcvMsg rcvUserSeq => ", userSeq);
                console.log("rcvMsg rcvName => ", userNickname);

                const actionData = {
                    userSeq,
                    userNickname,
                    imgDataUrl
                };
    
                callEoForSendAction("showImage", actionData);
            }
        }

        return {
            ...state,
        }
    },

    [OPEN_WHISPER_POPUP]: (state, action) => {
        const { memberSeqList } = action.payload;
        console.log("OPEN_WHISPER_POPUP - ", action.payload);

        console.log(state.whisperMsg);

        if (memberSeqList && memberSeqList.length > 0) {
            callEoForSendWhisperAction("openWhisperPopup", memberSeqList.map(info => info.userSeq));
        }

        return {
            ...state,
        }
    },

    [SEND_WHISPER_INIT_DATA]: (state, action) => {
        const { stdSeq, userInfo } = action.payload;
        console.log("SEND_WHISPER_INIT_DATA - ", action.payload);

        let whisper_info = state.whisperMsg.find(info => info.stdSeq === Number(stdSeq));
        console.log(whisper_info);
        if (whisper_info) {
            callEoForSendWhisperAction("sendInitData", stdSeq, { kind:"whisper-popup", data: { kind: "sendInitData", data: { data: whisper_info, userInfo } }});
        }
        
        return {
            ...state
        }
    },

    [RECEIVE_WHISPER_MESSAGE]: (state, action) => {
        const { kind, stdSeq, userNickname, profileImgUrl, msgInfo } = action.payload;
        console.log("RECEIVE_WHISPER_MESSAGE - ", action.payload);

        return produce (state, draft => {
            const whisper_info = draft.whisperMsg.find(info => info.stdSeq === Number(stdSeq));
            if (whisper_info) {
                let newMsgInfo = {
                    kind,
                    msgInfo
                };
                whisper_info.msg.push(newMsgInfo);
            } else {
                console.log("기존에 이 학생이랑 귓속말 whisper msg 주고받은 거 없어용 - push whisperMsg");
                let newMsgInfo = {
                    kind,
                    msgInfo
                };
                draft.whisperMsg.push({
                    stdSeq      : Number(stdSeq),
                    stdNickname : userNickname,
                    profileImg  : profileImgUrl,
                    msg         : [newMsgInfo]
                });
            }
        });
    },

    [CLOSE_CLASSTALK_MENU_MODAL]: (state, action) => {
        return {
            ...state,
            selectFunctionSeq           : -1,
            isEmoticonSelected          : false,
            selectEmoticonSeq           : -1,
            isAddFunctionOpened         : false,
            isAudioOpened               : false,
            isQuizWindowOpened          : false,
            isQuizResponseInfoOpened    : false,
            isQuizResponseResultOpened  : false,
        
        }
    },
}, initialState);

export default classTalk;
import React, { Component } from "react";
import { connect } from "react-redux";

import FriendListTitleTemplate from "../../components/template/title/FriendListTitleTemplate";
import MyFriendList from "../../components/friend/MyFriendList";
import WebMyFriendList from "../../components/friend/WebMyFriendList";
import WebConfigMyFriendList from "../../components/friend/WebConfigMyFriendList";

import WebSearchFriendWindow from "../../components/friend/WebSearchFriendWindow";

import { enBrowserType, enWebPage } from "../../lib/ConstCommand";

class MyFriendListContainer extends Component {

    render() {
        const { browserType, currentPageType, selectedFunc } = this.props;
        const { userInfo, list_friends, list_rcvRequestFriends, list_sendRequestFriends, searchResult, searchResultId,
            handleSelectedFunc, handleSelectedAudioInfo, handleSelectedFriendFunc } = this.props;

        return (
            browserType === enBrowserType.HiClass ?
                <>
                    <FriendListTitleTemplate
                        countRecommendFriends={list_rcvRequestFriends.length}
                        handleSelectedAudioInfo={handleSelectedAudioInfo}
                        handleSelectedFunc={handleSelectedFunc}
                        selectedFunc={selectedFunc}
                    />
                    <MyFriendList
                        userInfo={userInfo}
                        list_friends={list_friends}
                        handleSelectedFriendFunc={handleSelectedFriendFunc}
                    />
                </> :
                currentPageType === enWebPage.Home ?
                    <WebMyFriendList
                        userInfo={userInfo}
                        list_friends={list_friends}
                        handleSelectedFriendFunc={handleSelectedFriendFunc}
                    /> :
                    <div className="d-flex flex-column align-items-center w-100 h-100 overflow-auto">
                        <WebSearchFriendWindow
                            userInfo={userInfo}
                            searchResult={searchResult}
                            searchResultId={searchResultId}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                        <WebConfigMyFriendList
                            list_friends={list_friends}
                            list_rcvRequestFriends={list_rcvRequestFriends}
                            list_sendRequestFriends={list_sendRequestFriends}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </div>
        );
    }
}

export default connect(
    ({ user, friend }) => ({
        userInfo    : {
            userSeq             : user.userSeq,
            userID              : user.userID,
            passWD              : user.passWD,
            passConfirm         : user.passConfirm,
            phoneNumber         : user.phoneNumber,
            confirmNumber       : user.confirmNumber,
            userNickname        : user.userNickname,
            userEmail           : user.userEmail,
            userProfile         : user.userProfile,
            profileImgUrl       : user.profileImgUrl,
            isPhoneTestStart    : user.isPhoneTestStart,
            isPhoneTestOK       : user.isPhoneTestOK,
        },
        list_friends            : friend.list_friends,
        list_rcvRequestFriends  : friend.list_rcvRequestFriends,
        list_sendRequestFriends : friend.list_sendRequestFriends,

        searchResult            : friend.web_searchResult,
        searchResultId          : friend.web_searchResultId
    }),
    {

    }
)(MyFriendListContainer);
import React, { Component } from "react";
import { connect } from "react-redux";

import ConstData from "../../lib/ConstData";
import { enWebPage } from "../../lib/ConstCommand";
import { enTokenCMD } from "../../lib/classTalk/CodeEnum";

import ClassTalkContainer from "./classTalk/ClassTalkContainer";
import LiveMediaSoupRcv from "../../components/live/performLiveLayer/LiveMediaSoupRcv";
import LiveDrawingCanvas from "../../components/live/performLiveLayer/LiveDrawingCanvas";
import LiveDrawingTool from "../../components/live/performLiveLayer/LiveDrawingTool";
import LiveBroadcastDateCount from "../../components/live/performLiveLayer/LiveBroadcastDateCount";
import LiveControlTool from "../../components/live/performLiveLayer/LiveControlTool";

import {
    disconnectLive,
    xmitCmd_AndroidMessage
} from "../../lib/RabbitmqClient";

import {
    stopHandsUpMode,
    changeViewKind,
    changeMyCamViewInput,
    changeHandsUpVideoInput,
    updateReconnMode,
    setHandsUpMicOn,
    setHandsUpMicOff,
    setIsMixedClassMember
} from "../../lib/HandsUpClient";

import {
    changeDrawingMode,
    changeToolColor,
    changeToolThick,
    undoDrawingStroke,
    redoDrawingStroke,
    clearDrawingCanvas,
    mqSendInkStroke,
    changePerformLiveOffsetValue,
    setUndoStatus,
    setRedoStatus,
    setDrawingStatus,
    undoRedoSuccess,
    clickElement
} from "../../modules/classTalkLive";

import {
    finishLive,
    finishMyBroadcastLive,
    handsUpOn,
    handsUpOff,
    toggleIsNeedGoToHome,
    toggle_showWatchLiveMember,
    requestFinishMixedClassLive
} from "../../modules/live";
import { setVolume } from "../../lib/MediaSoupClient";

class PerformLiveContainer extends Component {

    state = {
        isUpdate                : false,

        classTalkVisibility     : "hidden",
        teacherViewVisibility   : "hidden",
        myCamViewVisibility     : "none",
        optionVisibility        : (ConstData.IS_LOCAL_VERSION ? "hidden" : "none"),
        publicClassVisibility   : (ConstData.IS_LOCAL_VERSION ? "flex" : "none"),
        viewKind                : this.props.cookies.get("viewKind") === undefined || this.props.cookies.get("viewKind") === "" ? 
                                  (ConstData.IS_LOCAL_VERSION || this.props.isMixedClassMember) ? 
                                  "SCREEN" : "CAMERA" : this.props.cookies.get("viewKind"),

        isFullScreenMode        : false,
        isRetryConnect          : false,
        isMyCamMode             : false,
        isChangeViewKind        : false
    }

    componentDidMount() {
        //console.log(`PerformLiveContainer componentDidMount - viewKind[${this.state.viewKind}]`);
        setIsMixedClassMember(this.props.isMixedClassMember);
        changeViewKind(this.state.viewKind);
        // console.log("PerformLiveContainer DidMount() - onHandleCookies call");
        this.onHandleCookies("set", "liveSeq", this.props.performLiveInfo.liveSeq);
        this.onHandleCookies("set", "userID", this.props.userID);
        this.onHandleCookies("set", "userSeq", this.props.userSeq);
        this.onHandleCookies("set", "userNickname", this.props.userNickname);
        this.onHandleCookies("set", "viewKind", this.state.viewKind);
        // this.props.onHandleCookies("set", "viewKind", this.state.viewKind);
        let isMobile = (navigator.userAgent.toLowerCase().indexOf("android") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 && navigator.maxTouchPoints > 2));
        if (!isMobile) {
            window.addEventListener('beforeunload', this.onBeforeunload);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("======PerformLiveContainer-componentDidUpdate======");
        //console.log(`prevState.isUpdate[${prevState.isUpdate}] isUpdate[${this.state.isUpdate}]`);
        //console.log("======================================================");
        if (prevProps.isNeedGoToHome !== this.props.isNeedGoToHome && this.props.isNeedGoToHome === true) {
            console.log("PerformLiveContainer DidUpdate(3) - invalid liveSeq. go to web main page.");
            this.props.routeProps.history.push("/main");
            this.props.toggleIsNeedGoToHome(false);
        } else {
            if (prevProps.isGotoWebLive !== this.props.isGotoWebLive && this.props.isGotoWebLive === false) {
                // console.log("PerformLiveContainer DidUpdate() - onHandleCookies call");
                this.onHandleCookies("delete", "liveSeq", this.props.cookies.get("liveSeq"));
                this.onHandleCookies("delete", "userID", this.props.cookies.get("userID"));
                this.onHandleCookies("delete", "userSeq", this.props.cookies.get("userSeq"));
                this.onHandleCookies("delete", "userNickname", this.props.cookies.get("userNickname"));
                this.onHandleCookies("delete", "viewKind", this.props.cookies.get("viewKind"));

                if (this.props.userLoginPageType === enWebPage.Home) {
                    console.log("PerformLiveContainer DidUpdate() - go to web main page.");
                    this.props.routeProps.history.push("/main");
                } else if (this.props.userLoginPageType === enWebPage.LiveQRLogin) {
                    // console.log("PerformLiveContainer DidUpdate() - qrLiveInfo => ", this.props.qrLiveInfo);
                    if (this.props.qrLiveInfo && this.props.qrLiveInfo.liveSeq > 0) {
                        console.log("PerformLiveContainer DidUpdate(1) - go to web qr login page.");
                        this.props.routeProps.history.push(`/main-qrLogin/${this.props.qrLiveInfo.liveSeq}`);
                        window.location.reload();
                        // window.location.replace(`/main-qrLogin/${this.props.qrLiveInfo.liveSeq}`);
                    } else {
                        // 아마도 3시간이 경과했거나 이리저리 여러번 왔다갔다 해서 쿠키가 지워진 경우 ... by hjkim 20230403
                        if (ConstData.IS_LOCAL_VERSION) { // local version 인 경우 1001 로 이동 ... by hjkim 20230403
                            console.log("PerformLiveContainer DidUpdate(2) - go to 1001 live web qr login page.");
                            this.props.routeProps.history.push("/main-qrLogin/1001");
                            window.location.reload();
                            // window.location.replace("/main-qrLogin/1001");
                        } else {
                            // console.log("PerformLiveContainer DidUpdate(3) - invalid liveSeq. toggle need go to home.");
                            // this.props.toggleIsNeedGoToHome(true);
                            this.props.routeProps.history.push("/main");
                            window.location.reload();
                        }
                    }
                } else if (this.props.userLoginPageType === enWebPage.MixedClassLogin) {
                    if (this.props.mixedClassInfo.mixedClassSeq > 0 && this.props.mixedClassInfo.liveSeq > 0) {
                        console.log("PerformLiveContainer DidUpdate(4) - go to web mixed login page.");
                        this.props.routeProps.history.push(`/mixed_login/${this.props.mixedClassInfo.mixedClassSeq}`);
                        window.location.reload();
                        // window.location.replace(`/mixed_login/${this.props.mixedClassInfo.mixedClassSeq}`);
                    } else {
                        console.log("PerformLiveContainer DidUpdate(5) - invalid liveSeq. toggle need go to home.");
                        // this.props.toggleIsNeedGoToHome(true);
                        this.props.routeProps.history.push("/main");
                        window.location.reload();
                    }
                } else {
                    console.log("PerformLiveContainer DidUpdate() - 이런 경우가 있나요?ㅁ? 어디로 가야 하나요..? 일단 메인으로 갈까나..");
                    this.props.routeProps.history.push("/main");
                    // window.location.reload();
                }
            }
        }

        const { performLiveInfo, isMixedClassMember } = this.props;

        if (this.state.isUpdate && performLiveInfo.liveSeq === -1) {
            console.log("PerformLiveContainer DidUpdate() - go to main (for alone chatting).");
            this.props.routeProps.history.push("/main");
        }

        if (prevProps.performLiveInfo.handsUpYN !== performLiveInfo.handsUpYN && performLiveInfo.handsUpYN === "N") {
            if (ConstData.IS_LOCAL_VERSION || this.props.isMixedClassMember) { // local server이거나 mixed class member인 경우 viewKind 기본값은 screen ... by hjkim 20221007
                console.log("PerformLiveContainer DidUpdate() - setState and saveCookies SCREEN");
                this.setState({ viewKind: "SCREEN" });
                this.onHandleCookies("set", "viewKind", "SCREEN");
                // this.props.onHandleCookies("set", "viewKind", "SCREEN");
            } else { // cloud server인 경우 viewKind 기본값은 camera ... by hjkim 20221007
                console.log("PerformLiveContainer DidUpdate() - setState and saveCookies CAMERA");
                this.setState({ viewKind: "CAMERA" });
                this.onHandleCookies("set", "viewKind", "CAMERA");
                // this.props.onHandleCookies("set", "viewKind", "CAMERA");
            }
        }

        if (prevProps.isWhisperModalOpened !== this.props.isWhisperModalOpened && this.props.isWhisperModalOpened) {
            //this.setState({ classTalkVisibility: "visible" });
        }

        if (isMixedClassMember) {
            if (this.props.performLiveInfo.liveSeq > -1) {
                // console.log(`prevProps.performLiveInfo.mixedClassStatus[${prevProps.performLiveInfo.mixedClassStatus}], this.props.performLiveInfo.mixedClassStatus[${this.props.performLiveInfo.mixedClassStatus}]`);
                if (prevProps.performLiveInfo.mixedClassStatus !== this.props.performLiveInfo.mixedClassStatus && this.props.performLiveInfo.mixedClassStatus === "N") {
                    window.removeEventListener("beforeunload", this.onBeforeunload);
                    this.onHandleCookies("delete", "liveSeq", this.props.cookies.get("liveSeq"));
                    this.onHandleCookies("delete", "userID", this.props.cookies.get("userID"));
                    this.onHandleCookies("delete", "userSeq", this.props.cookies.get("userSeq"));
                    this.onHandleCookies("delete", "userNickname", this.props.cookies.get("userNickname"));
                    this.onHandleCookies("delete", "viewKind", this.props.cookies.get("viewKind"));
    
                    console.log("PerformLiveContainer DidUpdate(5) - go to web mixed login page.");
                    this.props.routeProps.history.push(`/mixed_login/${this.props.mixedClassInfo.mixedClassSeq}`);
                    window.location.reload();
                }
            }
        }
    }

    componentWillUnmount() {
        const { userSeq, performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo } = this.props;

        if (performLiveInfo.liveSeq > -1) { // live에 입장한 case by hjkim 20220609
            if (performLiveInfo.liveStatus === "Y" && performLiveStatusInfo.connectStatus === "on") {
                console.log("PerformLiveContainer - need to call disconnectLive");
                disconnectLive();
            }

            if (performLiveInfo.handsUpYN === "Y" && performHandsUpStatusInfo.connectStatus !== "disable") {
                console.log("PerformLiveContainer - need to call stopHandsUpMode");
                stopHandsUpMode(performLiveInfo.liveSeq, userSeq, false, true);
            }

            this.handleSelectedLiveFunc({ selectedFunc: "LIVE_FINISH" });
        } else { // 나와의 채팅 or 강의참여인 case by hjkim 20220609
            this.handleSelectedLiveFunc({ selectedFunc: "GO_TO_HOME" });
        }
    }

    onHandleCookies = (kind, name, value) => {
        const { cookies } = this.props;
        // console.log(`onHandleCookies - kind[${kind}], name[${name}], value[${value}]`);

        let newDate = new Date(Date.now());

        if (kind === "set") {
            newDate.setHours(newDate.getHours() + 3);
        } else if (kind === "delete") {
            newDate.setDate(newDate.getDate() - 1);
        }

        cookies.set(name, value, { path: "/", expires: newDate });
    }

    onBeforeunload = (event) => {
        event.preventDefault();
        //event.returnValue = "";

        console.log('beforeunload');

        const { cookies } = this.props;
        let newDate = new Date(Date.now());
        newDate.setDate(newDate.getDate() - 1);
        cookies.set('liveSeq', cookies.get('liveSeq'), { path: '/', expires: newDate });
        cookies.set('userID', cookies.get('userID'), { path: '/', expires: newDate });
        cookies.set('userSeq', cookies.get('userSeq'), { path: '/', expires: newDate });
        cookies.set('viewKind', cookies.get('viewKind'), { path: '/', expires: newDate });
    }

    handleControlToolFunc = ({ target, value }) => {
        switch (target) {
            case "classTalkVisibility":
                this.setState({ classTalkVisibility: value });
                break;

            case "teacherViewVisibility":
                this.setState({ teacherViewVisibility: value });
                break;

            case "myCamViewVisibility":
                this.setState({ myCamViewVisibility: value });
                break;

            case "optionVisibility":
                this.setState({ optionVisibility: value });
                break;

            case "publicClassVisibility":
                this.setState({ publicClassVisibility: value });
                break;

            case "viewKind":
                this.setState({ viewKind: value });
                this.onHandleCookies("set", "viewKind", value);
                // this.props.onHandleCookies("set", "viewKind", value);
                break;

            case "isFullScreenMode":
                this.setState({ isFullScreenMode: value });
                break;

            case "isRetryConnect":
                this.setState({ isRetryConnect: false });
                break;

            default:
                break;
        }
    }

    handleSelectedLiveFunc = ({ selectedFunc, data }) => {
        const { userSeq, userNickname, mySubDomainSeq, performLiveInfo, performHandsUpStatusInfo } = this.props;
        const { classTalkVisibility, teacherViewVisibility, myCamViewVisibility, isFullScreenMode, optionVisibility, publicClassVisibility } = this.state;

        //console.log(selectedFunc);

        switch (selectedFunc) {
            case "LIVE_MENU":
                this.props.toggle_showWatchLiveMember();
                break;

            case "LIVE_FINISH":
                window.removeEventListener("beforeunload", this.onBeforeunload);
                if (performLiveInfo.liveSeq === -1) {
                    console.log("LIVE_FINISH case 2");
                    this.setState({ isUpdate: true });
                } else {
                    if (performLiveInfo.teacher_info.userSeq === userSeq) { // my broadcast live
                        console.log("LIVE_FINISH case 3");
                        xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                        this.props.finishMyBroadcastLive({ liveSeq: performLiveInfo.liveSeq, userSeq });
                    } else {
                        if (this.props.isMixedClassMember) {
                            console.log("LIVE_FINISH case 4");
                            //this.props.logOut({ userSeq, mode: "mixed-web", liveSeq: performLiveInfo.liveSeq, isClickLogout: true });
                            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                            this.props.requestFinishMixedClassLive({
                                liveSeq: this.props.mixedClassInfo.liveSeq,
                                userSeq: this.props.userSeq,
                                // userSeq: this.props.mixedClassInfo.localStudentSeq,
                                mixedClassSeq: this.props.mixedClassInfo.mixedClassSeq
                            });

                            // window.location = "/main";
                            this.props.routeProps.history.push(`/mixed_login/${this.props.mixedClassInfo.mixedClassSeq}`);
                            window.location.reload();
                        } else {
                            console.log("LIVE_FINISH case 5");
                            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                            this.props.finishLive({ liveSeq: performLiveInfo.liveSeq, userSeq });
                        }
                    }
                    updateReconnMode(false);
                }
                break;

            case "GO_TO_HOME":
                window.removeEventListener("beforeunload", this.onBeforeunload);
                if (performLiveInfo.liveSeq === -1) {
                    this.setState({ isUpdate: true });
                } else {
                    if (performLiveInfo.teacher_info.userSeq === userSeq) { // my broadcast live
                        xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                        this.props.finishMyBroadcastLive({ liveSeq: performLiveInfo.liveSeq, userSeq });
                    } else {
                        if (this.props.isMixedClassMember) {
                            //this.props.logOut({ userSeq, mode: "mixed-web", liveSeq: performLiveInfo.liveSeq, isClickLogout: true });
                            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                            window.location = "/main";
                        } else {
                            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL, { userSeq, userNickname });
                            this.props.finishLive({ liveSeq: performLiveInfo.liveSeq, userSeq });
                        }
                    }
                    updateReconnMode(false);
                }
                break;

            ////////////////////////////////////////////////////////////////
            /////////////////////// Control Toolbar ////////////////////////
            ////////////////////////////////////////////////////////////////

            case "TOGGLE_CLASSTALK":
                if (classTalkVisibility === "visible") {
                    this.setState({ classTalkVisibility: "hidden" });
                } else {
                    this.setState({ classTalkVisibility: "visible" });
                }
                break;

            case "TOGGLE_TEACHER_VIEW":
                if (teacherViewVisibility === "visible") {
                    this.setState({ teacherViewVisibility: "hidden" });
                } else {
                    this.setState({ teacherViewVisibility: "visible" });
                }
                break;

            case "TOGGLE_MYCAM_VIEW":
                if (myCamViewVisibility === "flex") {
                    //this.setState({ myCamViewVisibility : "none" });
                    changeMyCamViewInput(this.props.performLiveInfo.liveSeq, this.props.userSeq, false);
                } else {
                    //this.setState({ myCamViewVisibility : "flex" });
                    changeMyCamViewInput(this.props.performLiveInfo.liveSeq, this.props.userSeq, true);
                }
                break;

            case "TOGGLE_OPTION_VIEW":
                if (optionVisibility === "flex") {
                    this.setState({ optionVisibility: "hidden" });
                } else {
                    this.setState({ optionVisibility: "flex" });
                }
                break;

            case "TOGGLE_PUBLIC_CLASS":
                if (publicClassVisibility === "flex") {
                    this.setState({ optionVisibility: "hidden", publicClassVisibility : "hidden" });
                    setVolume(1);
                } else {
                    this.setState({ optionVisibility: "hidden", publicClassVisibility : "flex" });
                    setVolume(0);
                }
                break;

            case "CHANGE_VIEW_SIZE":
                //this.setState({ isFullScreenMode: data });
                this.setState({ isFullScreenMode: !isFullScreenMode });
                break;

            case "CHANGE_VIEW_KIND":
                this.setState({ myCamViewVisibility: "none" });
                changeHandsUpVideoInput(this.props.performLiveInfo.liveSeq, this.props.userSeq);
                break;

            case "PROC_MIC":
                if (this.props.performHandsUpStatusInfo.micStatus === "off") {
                    setHandsUpMicOn(this.props.performLiveInfo.liveSeq, this.props.userSeq);
                } else if (this.props.performHandsUpStatusInfo.micStatus === "mute" || this.props.performHandsUpStatusInfo.micStatus === "listen")
                    setHandsUpMicOff(this.props.performLiveInfo.liveSeq, this.props.userSeq);
                break;

            case "PROC_CONN":
                if (this.props.performHandsUpStatusInfo.connectStatus === "disconnect") {
                    if (this.props.performLiveInfo.handsUpYN === "Y") {
                        this.setState({ isRetryConnect: true });
                    }
                }
                break;

            case "HANDS_UP":
                if (performLiveInfo.handsUpYN === "Y") {    // handsUp 모드일 때만 동작하도록...
                    if (performHandsUpStatusInfo.myHandsUpYN === "Y") {
                        this.props.handsUpOff({ teacherSeq: performLiveInfo.teacher_info.userSeq, liveSeq: performLiveInfo.liveSeq, userSeq, userNickname });
                    } else {
                        this.props.handsUpOn({ teacherSeq: performLiveInfo.teacher_info.userSeq, liveSeq: performLiveInfo.liveSeq, userSeq, userNickname });
                    }
                }
                break;

            case "MEDIA_ALERT":
                if (data !== undefined && data !== null) {
                    this.props.handleSelectedFunc({ selectedFunc: "OPEN_LIVE_MEDIA_ALERT", kind: data.kind, mediaDevice_info: data.mediaDevice_info });
                }
                break;

            default:
                this.props.handleSelectedFunc({ selectedFunc });
                break;
        }
    }

    handleSelectedDrawingToolFunc = ({ selectedFunc, data, tool, flag }) => {
        const { userSeq, userNickname } = this.props;

        switch (selectedFunc) {
            ////////////////////////////////////////////////////////////////
            /////////////////////// Drawing Canvas /////////////////////////
            ////////////////////////////////////////////////////////////////

            case "SEND_STROKE":
                //console.log("send stroke - ", data);
                this.props.mqSendInkStroke({ stroke: data, userNickname, userSeq });
                break;

            case "SET_OFFSET":
                this.props.changePerformLiveOffsetValue({ offset: data.offset });
                break;

            case "CLEAR_SUCCESS":
                this.props.clearDrawingCanvas({ selectedFunc });
                break;

            case "UNDO_STATUS":
                this.props.setUndoStatus(flag);
                break;

            case "REDO_STATUS":
                this.props.setRedoStatus(flag);
                break;

            case "UNDO_REDO_SUCCESS":
                this.props.undoRedoSuccess();
                break;

            ////////////////////////////////////////////////////////////////
            ////////////////////// Drawing Toolbar /////////////////////////
            ////////////////////////////////////////////////////////////////

            case "CHANGE_MODE":
                //console.log("change mode");
                this.props.changeDrawingMode({ selectedFunc, tool, userSeq });
                break;

            case "CHANGE_COLOR":
                this.props.changeToolColor({ selectedFunc, tool, userSeq });
                break;

            case "CHANGE_THICK":
                this.props.changeToolThick({ selectedFunc, tool, userSeq });
                break;

            case "CLEAR":
                this.props.clearDrawingCanvas({ selectedFunc });
                break;

            case "UNDO":
                this.props.undoDrawingStroke({ selectedFunc });
                break;

            case "REDO":
                this.props.redoDrawingStroke({ selectedFunc });
                break;

            case "SET_DRAWING_STATUS":
                this.props.setDrawingStatus({ isDrawingInkStroke: data.isDrawingInkStroke });
                break;

            default:
                break;
        }
    }

    render() {
        const { routeProps, isJoinLive } = this.props;

        const { isGuestYn, userSeq, userNickname, mySubDomainSeq } = this.props;

        const { joinLiveInfo, performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo, smallGroupInfo } = this.props;
        const { drawingMode, tipSize, tipColor, isClearInkStroke, rcvPath, rcvStroke, rcvStrokes, androidPenPath } = this.props;
        const { isUndo, isRedo, isPossibleUndo, isPossibleRedo } = this.props;

        return (
            <div id="live-area" className="live-entire-area">
                <div className="live-layer-area">
                    <LiveMediaSoupRcv
                        isMixedClassMember={this.props.isMixedClassMember}
                        userSeq={userSeq}
                        userLoginPageType={this.props.userLoginPageType}
                        drawingMode={drawingMode}
                        performLiveInfo={performLiveInfo}
                        performLiveStatusInfo={performLiveStatusInfo}
                        performHandsUpStatusInfo={performHandsUpStatusInfo}
                        smallGroupInfo={smallGroupInfo}
                        teacherViewVisibility={this.state.teacherViewVisibility}
                        myCamViewVisibility={this.state.myCamViewVisibility}
                        isRbmqServerConnected={this.props.isRbmqServerConnected}
                        isReadyLiveConsume={this.props.isReadyLiveConsume}
                        isRetryConnect={this.state.isRetryConnect}
                        isFullScreenMode={this.state.isFullScreenMode}
                        isGuestYn={isGuestYn}
                        broadcastOpenYN={joinLiveInfo.broadcastOpenYN}
                        isLiveGuestAlertModalOpened={this.props.isLiveGuestAlertModalOpened}
                        isLiveMixedAlertModalOpened={this.props.isLiveMixedAlertModalOpened}
                        handleSelectedLiveFunc={this.handleSelectedLiveFunc}
                        handleControlToolFunc={this.handleControlToolFunc}
                    />
                    <LiveDrawingCanvas
                        rcvPath={rcvPath}
                        androidPenPath={androidPenPath}
                        rcvStroke={rcvStroke}
                        rcvStrokes={rcvStrokes}
                        drawingMode={drawingMode}
                        tipSize={tipSize}
                        tipColor={tipColor}
                        isClearInkStroke={isClearInkStroke}
                        handleSelectedDrawingToolFunc={this.handleSelectedDrawingToolFunc}
                    />
                    <ClassTalkContainer
                        routeProps={routeProps}
                        isJoinLive={isJoinLive}
                        isGuestYn={isGuestYn}
                        isWhisperModalOpened={this.props.isWhisperModalOpened}
                        classTalkVisibility={this.state.classTalkVisibility}
                        userSeq={userSeq}
                        userNickname={userNickname}
                        mySubDomainSeq={mySubDomainSeq}
                        broadcastOpenYN={joinLiveInfo.broadcastOpenYN}
                        performLiveInfo={performLiveInfo}
                        performLiveStatusInfo={performLiveStatusInfo}
                        performHandsUpStatusInfo={performHandsUpStatusInfo}
                        handleSelectedLiveFunc={this.handleSelectedLiveFunc}
                    />
                    <LiveDrawingTool
                        drawingMode={drawingMode}
                        isDrawingInkStroke={this.props.isDrawingInkStroke}
                        isPossibleUndo={isPossibleUndo}
                        isPossibleRedo={isPossibleRedo}
                        handleSelectedDrawingToolFunc={this.handleSelectedDrawingToolFunc}
                    />
                    {
                        (joinLiveInfo && joinLiveInfo.liveSeq > 0 && joinLiveInfo.visibility === "visible") &&
                        <LiveBroadcastDateCount
                            broadcastDateTime={joinLiveInfo.broadcastDateTime}
                        />
                    }
                </div>
                <LiveControlTool
                    userNickname={userNickname}
                    performLiveInfo={performLiveInfo}
                    performLiveStatusInfo={performLiveStatusInfo}
                    performHandsUpStatusInfo={performHandsUpStatusInfo}
                    classTalkVisibility={this.state.classTalkVisibility}
                    teacherViewVisibility={this.state.teacherViewVisibility}
                    myCamViewVisibility={this.state.myCamViewVisibility}
                    optionVisibility={this.state.optionVisibility}
                    publicClassVisibility={this.state.publicClassVisibility}
                    isFullScreenMode={this.state.isFullScreenMode}
                    viewKind={this.state.viewKind}
                    handleSelectedLiveFunc={this.handleSelectedLiveFunc}
                />
            </div>
        );
    }
}

export default connect(
    ({ user, live, classTalkLive, hiclasstv }) => ({ 
        isGuestYn                   : user.isGuestYn,

        userSeq                     : user.userSeq,
        userID                      : user.userID,
        userNickname                : user.userNickname,
        mySubDomainSeq              : user.mySubDomainSeq,

        userLoginPageType           : user.userLoginPageType,

        isRbmqServerConnected       : user.isRbmqServerConnected,

        isNeedGoToHome              : live.isNeedGoToHome,
        isGotoWebLive               : live.isGotoWebLive,

        joinLiveInfo                : live.joinLiveInfo,
        performLiveStatusInfo       : live.performLiveStatusInfo,
        performHandsUpStatusInfo    : live.performHandsUpStatusInfo,
        isMixedClassMember          : live.isMixedClassMember,
        mixedClassInfo              : live.mixedClassInfo,
        isReadyLiveConsume          : live.isReadyLiveConsume,

        smallGroupInfo              : live.smallGroupInfo,

        drawingMode                 : classTalkLive.drawingMode,
        tipSize                     : classTalkLive.tipSize,
        tipColor                    : classTalkLive.tipColor,
        isUndo                      : classTalkLive.isUndo,
        isRedo                      : classTalkLive.isRedo,
        isClearInkStroke            : classTalkLive.isClearInkStroke,
        isDrawingInkStroke          : classTalkLive.isDrawingInkStroke,

        rcvPath                     : {
            rcvCmd                  : classTalkLive.rcvCmd,
            rcvMode                 : classTalkLive.rcvMode,
            rcvColor                : classTalkLive.rcvColor,
            rcvWidth                : classTalkLive.rcvWidth,
            rcvPoint1               : classTalkLive.rcvPoint1,
            rcvPoint2               : classTalkLive.rcvPoint2, 
        },
        rcvStroke                   : classTalkLive.rcvStroke,
        rcvStrokes                  : classTalkLive.rcvStrokes,

        androidPenPath              : classTalkLive.androidPenPath,

        performLiveImgViewSize      : classTalkLive.performLiveImgViewSize,

        isPossibleUndo              : classTalkLive.isPossibleUndo,
        isPossibleRedo              : classTalkLive.isPossibleRedo,

        isWhisperModalOpened        : classTalkLive.isWhisperModalOpened,

        qrLiveInfo                  : hiclasstv.qrLiveInfo,
    }),
    {
        toggleIsNeedGoToHome,
        toggle_showWatchLiveMember,

        handsUpOn, 
        handsUpOff,
        finishLive,
        finishMyBroadcastLive,

        changeDrawingMode,
        changeToolColor,
        changeToolThick,
        undoDrawingStroke,
        redoDrawingStroke,

        mqSendInkStroke,
        changePerformLiveOffsetValue,
        clearDrawingCanvas,
        setUndoStatus,
        setRedoStatus,
        setDrawingStatus,
        undoRedoSuccess,

        clickElement,
        requestFinishMixedClassLive
    }
)(PerformLiveContainer);
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const WebRefundAlert = ({ isModalOpened, refundType, refundPrice, pchs_info, toggleModalOepened, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const onClickAccept = () => {
        if (refundType === 'REFUND') {
            toggleModalOepened(false);
            handleSelectedFunc({ selectedFunc: 'REFUND_PASS', pchsSeq: pchs_info.pchsSeq });
        } else if (refundType === 'P-REFUND') {
            toggleModalOepened(false);
            handleSelectedFunc({ selectedFunc: 'P_REFUND_PASS', pchsSeq: pchs_info.pchsSeq, price : refundPrice });
        } else {
            // 환불 불가
        }
    }
    
    return (
        <Modal isOpen={isModalOpened} centered toggle={() => toggleModalOepened(false)}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end mb-3">
                    <button className="hiclasstv-btn p-1" title={t("닫기", { ns: "common" })} onClick={() => toggleModalOepened(false)}>
                        <FaTimes className="d-flex m-1" />
                    </button>
                </div>
                <h3>
                    {t("이용권_환불_신청", { ns: "pass" })}
                </h3>
                {
                    refundType !== 'None' ? 
                    <div className="d-flex flex-column text-center w-100 px-2">
                        <span>
                            {t("이용권_환불_신청_문구_1", { ns: "pass" })}
                        </span>
                        <span><b>{refundPrice + t("원", { ns: "pass" })}</b></span>
                        <span>
                            {t("이용권_환불_신청_문구_2", { ns: "pass" })}
                        </span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={onClickAccept}>{t("예", { ns: "common" })}</button>
                    </div> :
                    <div className="d-flex flex-column text-center w-100 px-2">
                        <span>
                            {t("이용권_환불_신청_문구_3", { ns: "pass" })}
                        </span>
                        <button type="button" className="btn btn-danger btn-sm" onClick={() => toggleModalOepened(false)}>{t("예", { ns: "common" })}</button>
                    </div>
                }
            </div>
        </Modal>
    );
};

export default WebRefundAlert;
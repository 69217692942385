import React, { useState, useEffect } from "react";
import { BsX } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const DeleteChatRoom = ({ userSeq, selectedChatRoomInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lSelectedChatRoomMembers, setSelectedChatRoomMembers] = useState([]);
    const [lSelectedChatRoomEntryMembers, setSelectedChatRoomEntryMembers] = useState([]);

    useEffect(() => {
        if (userSeq >= 0 && selectedChatRoomInfo.list_member !== undefined && selectedChatRoomInfo.list_member !== null) {
            const list_member = selectedChatRoomInfo.list_member.filter(info => info.userSeq !== userSeq);
            setSelectedChatRoomMembers(list_member);
        }
    }, [userSeq, selectedChatRoomInfo.list_member]);

    useEffect(() => {
        if (lSelectedChatRoomMembers.length > 0) {
            const list_entryMember = lSelectedChatRoomMembers.filter(info => info.entryYN === "Y");
            setSelectedChatRoomEntryMembers(list_entryMember);
        }
    }, [lSelectedChatRoomMembers]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            {
                lSelectedChatRoomEntryMembers.length > 0 ?
                    <>
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            {t("팀업_알림_메시지_3", { ns: "chat" })}<br />
                            {t("팀업_알림_메시지_4", { ns: "chat" })}
                        </div>
                        <div className="content-area dark-grey-text">
                            <span className="small mb-1" title={t("팀업_이름", { ns: "chat" })}>
                                [{t("팀업_이름", { ns: "chat" })}]
                            </span>
                            <div className="text-area text-center mb-3 area-80">
                                <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                                    <b>{selectedChatRoomInfo.chatRoomName}</b>
                                </span>
                            </div>
                            <span className="small mb-1" title={t("참여_중인_멤버", { ns: "chat" })}>
                                [{t("참여_중인_멤버", { ns: "chat" })}]
                            </span>
                            {
                                lSelectedChatRoomEntryMembers.map(member => (
                                    <div key={"member-" + member.userSeq} className="text-area text-center area-80">
                                        <div className="area-15 fit-content">
                                            <CustomProfileImage url={member.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={member.userNickname} />
                                        </div>
                                        <span className="hiclass-text" title={member.userNickname}>
                                            <b>{member.userNickname}</b>
                                        </span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                                {t("닫기", { ns: "common" })}
                            </button>
                        </div>
                    </> :
                    <>
                        <div className="d-flex text-center my-2 dark-grey-text small">
                            {t("팀업_알림_메시지_5", { ns: "chat" })}<br />
                            {t("팀업_알림_메시지_6", { ns: "chat" })}
                        </div>
                        <div className="content-area dark-grey-text my-0">
                            <span className="small mb-1" title={t("팀업_이름", { ns: "chat" })}>
                                [{t("팀업_이름", { ns: "chat" })}]
                            </span>
                            <div className="text-area text-center mb-3 area-80">
                                <span className="hiclass-text" title={selectedChatRoomInfo.chatRoomName}>
                                    <b>{selectedChatRoomInfo.chatRoomName}</b>
                                </span>
                            </div>
                            <span className="small mb-1" title={t("팀업_멤버", { ns: "chat" })}>
                                [{t("팀업_멤버", { ns: "chat" })}]
                            </span>
                            {
                                lSelectedChatRoomMembers.length > 1 ?
                                    <div className="text-area text-center area-80">
                                        <span className="hiclass-text" title={lSelectedChatRoomMembers.map(mbr => mbr.userNickname).join(" | ")}>
                                            <b>{lSelectedChatRoomMembers[0].userNickname}</b>
                                        </span>
                                        <span className="hiclass-text ml-1" title={`${t("외", { ns: "common" })} ${lSelectedChatRoomMembers.length - 1}${t("명", { ns: "common" })}`}>
                                            {t("외", { ns: "common" })} {lSelectedChatRoomMembers.length - 1}{t("명", { ns: "common" })}
                                        </span>
                                    </div> :
                                    <div className="text-area text-center area-80">
                                        <span className="hiclass-text" title={lSelectedChatRoomMembers.map(mbr => mbr.userNickname)}>
                                            <b>{lSelectedChatRoomMembers.map(mbr => mbr.userNickname)}</b>
                                        </span>
                                    </div>
                            }
                        </div>
                        <div className="d-flex justify-content-center my-2">
                            <button type="button" className="btn btn-sm small red white-text m-0 mr-1 py-1 px-2" title={t("예", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "DELETE", chatRoomSeq: selectedChatRoomInfo.chatRoomSeq })}>
                                {t("예", { ns: "common" })}
                            </button>
                            <button type="button" className="btn btn-sm small green white-text m-0 ml-1 py-1 px-2" title={t("아니오", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                                {t("아니오", { ns: "common" })}
                            </button>
                        </div>
                    </>
            }
        </div>
    );
}

export default DeleteChatRoom;
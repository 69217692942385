import axiosClient from "./axiosClient";
import https from "https";

import ConstData from "../ConstData";

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    };
}

export const search_user = ({ userSeq, keyWord, kind, userKind }) => {
    // 관리자 계정은 제외하고 return
    let url = `/user?kind=${kind}&keyword=${keyWord}`;

    if (userSeq && userSeq >= 0) {
        url += `&userSEQ=${userSeq}`;
    }

    if (userKind) {
        url += `&userKind=${userKind}`;
    }

    return axiosClient.get(url, options);
}

export const check_sub_domain = ({ subDomainName }) => {
    //console.log("api check_sub_domain => ", subDomainName);
    return axiosClient.get(`/sub-domain?kind=check-sub-domain&subDomainName=${subDomainName}`, options);
}

export const change_sub_domain_logo = ({ subDomainSeq, subDomainName, data, filename }) => {
    var form = new FormData();
    form.append("image", data, filename);
    return axiosClient.put(`/sub-domain/admin-upload/${subDomainSeq}?kind=change-logo&subDomainName=${subDomainName}`, form, options);
}

export const reset_user_login_status = ({ userSeq, subDomainSeq, selectedUserSeq, mode }) => {
    return axiosClient.delete(`/sub-domain/admin/${subDomainSeq}/${selectedUserSeq}?kind=reset-login&mode=${mode}`, options);
}

export const reset_user_pw = ({ userSeq, subDomainSeq, selectedUserSeq, password }) => {
    return axiosClient.put(`/sub-domain/admin/${subDomainSeq}?kind=reset-pw`, {        //payload 형식
        selectedUserSEQ: selectedUserSeq,
        resetPW: password
    }, options);
}

export const add_sub_domain_info = ({ userSeq, subDomainName, data, filename }) => {
    var form = new FormData();
    form.append("image", data, filename);
    return axiosClient.post(`/sub-domain/admin-upload/${userSeq}?kind=add-sub-admin&subDomainName=${subDomainName}`, form, options);
}

export const add_sub_domain_config_admin = ({ userSeq, subDomainSeq, selectedUserSeq }) => {
    return axiosClient.post(`/sub-domain/admin/${userSeq}?kind=add-configAdmin`, {        //payload 형식
        subDomainSEQ: subDomainSeq,
        selectedUserSEQ: selectedUserSeq
    }, options);
}

export const remove_sub_domain_config_admin = ({ userSeq, subDomainSeq, selectedUserSeq }) => {
    return axiosClient.delete(`/sub-domain/admin/${subDomainSeq}/${selectedUserSeq}?kind=remove-configAdmin&seq=${userSeq}`, options);
}

export const add_sub_domain_create_admin = ({ userSeq, subDomainSeq, selectedUserSeq }) => {
    return axiosClient.post(`/sub-domain/admin/${userSeq}?kind=add-createAdmin`, {        //payload 형식
        subDomainSEQ: subDomainSeq,
        selectedUserSEQ: selectedUserSeq
    }, options);
}

export const remove_sub_domain_create_admin = ({ userSeq, subDomainSeq, selectedUserSeq }) => {
    return axiosClient.delete(`/sub-domain/admin/${subDomainSeq}/${selectedUserSeq}?kind=remove-createAdmin&seq=${userSeq}`, options);
}

// 아래 API 결과값에서 admin 계정은 제외하고 return 해주기.
export const get_manage_sub_domain_list_origin = ({ userSeq, subDomainName }) => {
    return axiosClient.get(`/sub-domain/admin/${userSeq}?kind=all`, options);
}

export const get_manage_sub_domain_list = ({ userSeq }) => {
    //console.log(`api get_manage_sub_domain_list - userSeq[${userSeq}]`);
    return axiosClient.get(`/sub-domain/admin/${userSeq}?kind=all-list`, options);
}

export const get_manage_sub_domain_info = ({ subDomainSeq }) => {
    return axiosClient.get(`/sub-domain/admin?kind=info&subDomainSEQ=${subDomainSeq}`, options);
}

export const get_sub_domain_local_server_info = ({ subDomainSeq }) => {
    //console.log(`api get_sub_domain_local_server_info - subDomainSeq[${subDomainSeq}]`);
    return axiosClient.get(`/sub-domain/admin-local/${subDomainSeq}?kind=config-list-admin`, options);
}

export const get_user_local_server_info = ({ subDomainSeq, selectedUserSeq }) => {
    //console.log(`api get_user_local_server_info - subDomainSeq[${subDomainSeq}], selectedUserSeq[${selectedUserSeq}]`);
    return axiosClient.get(`/sub-domain/admin-local/${subDomainSeq}/${selectedUserSeq}?kind=config-list-user`, options);
}

export const duplicate_check_users = ({ subDomainSeq, list_createUser }) => {
    console.log(`api duplicate_check_users - subDomainSeq[${subDomainSeq}], list_createUser => `, list_createUser);
    return axiosClient.post(`/sub-domain/admin-user?kind=check`, {
        subDomainSEQ: subDomainSeq,
        list_createUser
    }, options);
}

export const create_users = ({ subDomainSeq, list_createUser }) => {
    console.log(`api create_users - subDomainSeq[${subDomainSeq}], list_createUser => `, list_createUser);
    return axiosClient.post(`/sub-domain/admin-user?kind=create`, {
        subDomainSEQ: subDomainSeq,
        list_createUser
    }, options);
}
import React, { Component } from "react";
import { connect } from "react-redux";

import { enManagePage } from "../../lib/ConstCommand";

import UserUpdate from "../../components/user/UserUpdate";
import WebUserInfoBlock from "../../components/user/WebUserInfoBlock";
import WebHeaderBigMenu from "../../components/etc/menu/WebHeaderBigMenu";

import {
    changeShowPassKind,
    changeShowInfoKind,
    changeShowAdminKind,
    changeShowMenuKind
} from "../../modules/hiclasstv";

import {
    logInToggle,
    logOut,
    userInfoToggle,
    userUpdate,
    phoneCheckSend,
    phoneCheckConfirm,
    falseToPhoneCheckOk,
    falseToPhoneCheckSend,
    emailCheckSend,
    emailCheckConfirm,
    falseToEmailCheckOk,
    falseToEmailCheckSend,
    setMessageString,
    changeAlertMessage
} from "../../modules/user";

class UserInfoContainer extends Component {

    handleSelectedFunc = ({ selectedFunc, message, phoneNumber, userEmail, confirmNumber, modifyInfo, kind }) => {
        switch (selectedFunc) {
            case "NONE":
                break;

            case "SHOW_HEADER_BIG_MENU":
            case "CLOSE_HEADER_BIG_MENU":
                this.props.changeShowMenuKind({ selectedFunc });
                break;

            case "LOGIN_TOGGLE":
                this.props.logInToggle();
                break;

            case "LOGOUT":
                this.props.logOut({
                    userSeq: this.props.userInfo.userSeq,
                    mode: "web",
                    list_friends: this.props.list_friends,
                    isClickLogout: true
                });
                break;

            case "MODIFY_TOGGLE":
                this.props.changeShowMenuKind({ selectedFunc });
                this.props.userInfoToggle({ kind: "update" });
                break;

            case "MODIFY":
                this.props.userUpdate({
                    ...modifyInfo,
                    userSeq: this.props.userInfo.userSeq,
                    mode: "web",
                    // password: modifyInfo.password,
                    // phoneNumber: modifyInfo.phoneNumber,
                    // userNickname: modifyInfo.userNickname,
                    // userEmail: modifyInfo.userEmail,
                    // userExtraCode: modifyInfo.userExtraCode,
                    // userProfile: modifyInfo.userProfile,
                    // profileImgUrl: modifyInfo.profileImgUrl,
                });
                break;

            case "FALSE_TO_PHONE_CHECK_SEND":
                this.props.falseToPhoneCheckSend({ message });
                break;

            case "FLASE_TO_PHONE_CHECK_OK":
                this.props.falseToPhoneCheckOk({ message });
                break;

            case "PHONE_CHECK":
                this.props.phoneCheckSend({ phoneNumber });
                break;

            case "PHONE_CHECK_CONFIRM":
                this.props.phoneCheckConfirm({
                    certiCodeSeq: this.props.certiCodeSeq,
                    confirmNumber
                });
                break;

            case "EMAIL_CHECK":
                this.props.emailCheckSend({ userEmail });
                break;

            case "EMAIL_CHECK_CONFIRM":
                this.props.emailCheckConfirm({
                    certiCodeSeq: this.props.emailCertiCodeSeq,
                    confirmNumber
                });
                break;

            case "FLASE_TO_EMAIL_CHECK_OK":
                this.props.falseToEmailCheckOk({ message });
                break;

            case "FALSE_TO_EMAIL_CHECK_SEND":
                this.props.falseToEmailCheckSend({ message });
                break;

            case "MESSAGE":
                this.props.setMessageString(message);
                break;

            case "CHANGE_ALERT_MESSAGE":
                this.props.changeAlertMessage(message, kind);
                break;

            case "SHOW_PASS_PRDT_LIST":
            case "SHOW_PASS_PCHS_LIST":
            case "SHOW_PASS_REFUND_LIST":
                this.props.changeShowPassKind({ selectedFunc });
                break;

            case "GOTO_PROMOTION":
            case "SHOW_HOME":
            case "SHOW_EDUTECH":
            case "SHOW_CONTENTS":
            case "SHOW_GREEN_SMART":
            case "SHOW_DOWNLOAD":
            case "SHOW_QNA":
            case "SHOW_LINK":
                this.props.changeShowInfoKind({ selectedFunc });
                break;

            case "SHOW_ADMIN_HOME":
            case "SHOW_ADMIN_LOCAL_SERVER":
            case "SHOW_ADMIN_LOCAL_SITE":
            case "SHOW_ADMIN_LITE_SERVER":
            case "SHOW_ADMIN_STUDIO":
            case "SHOW_ADMIN_MEDIA":
                if (this.props.currentManagePageType !== enManagePage.SubDomainInfo) {
                    this.props.routeProps.history.push("/admin");
                }
                this.props.changeShowAdminKind({ selectedFunc });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <>
                {
                    this.props.isShowAllMenu &&
                    <WebHeaderBigMenu
                        userInfo={this.props.userInfo}
                        isStandardType={this.props.isStandardType}
                        isConfigAdmin={this.props.isConfigAdmin}
                        isCreateAdmin={this.props.isCreateAdmin}
                        isHiClassDomain={this.props.userKind === "hiclasstv"}
                        isMediasoupAdmin={this.props.userKind === "hiclasstv" || this.props.userKind === "local"}
                        currentPageType={this.props.currentPageType}
                        hiclasstvInfo={this.props.hiclasstvInfo}
                        handleSelectedFunc={this.handleSelectedFunc}
                        goToAloneChatting={this.props.goToAloneChatting}
                    />
                }
                {
                    this.props.isInfoOpened &&
                    <UserUpdate
                        userInfo={this.props.userInfo}
                        userInfoAlertKind={this.props.userInfoAlertKind}
                        userInfoMessage={this.props.userInfoMessage}
                        isInfoOpened={this.props.isInfoOpened}
                        handleSelectedFunc={this.handleSelectedFunc}
                    />
                }
                <WebUserInfoBlock
                    userInfo={this.props.userInfo}
                    isLoginYn={this.props.isLoginYn}
                    isStandardType={this.props.isStandardType}
                    isConfigAdmin={this.props.isConfigAdmin}
                    isCreateAdmin={this.props.isCreateAdmin}
                    isHiClassDomain={this.props.userKind === "hiclasstv"}
                    isMediasoupAdmin={this.props.userKind === "hiclasstv" || this.props.userKind === "local"}
                    hiclasstvInfo={this.props.hiclasstvInfo}
                    currentPageType={this.props.currentPageType}
                    currentManagePageType={this.props.currentManagePageType}
                    handleSelectedFunc={this.handleSelectedFunc}
                    goToAloneChatting={this.props.goToAloneChatting}
                />
            </>
        );
    }
}

export default connect(
    ({ admin, user, hiclasstv, friend }) => ({
        isShowAllMenu           : hiclasstv.isShowAllMenu,

        hiclasstvInfo           : {
            currentPassTap      : hiclasstv.currentPassTap,
            currentInfoTap      : hiclasstv.currentInfoTap,
            currentAdminTap     : hiclasstv.currentAdminTap
        },

        userInfo                : {
            userSeq             : user.userSeq,
            userID              : user.userID,
            passWD              : user.passWD,
            passConfirm         : user.passConfirm,
            phoneNumber         : user.phoneNumber,
            confirmNumber       : user.confirmNumber,
            userNickname        : user.userNickname,
            userEmail           : user.userEmail,
            userExtraCode       : user.userExtraCode,
            userProfile         : user.userProfile,
            profileImgUrl       : user.profileImgUrl,
            usingPassInfo       : user.usingPassInfo,
            isIdCheckOK         : user.isIdCheckOK,    
            isPhoneTestStart    : user.isPhoneTestStart,
            isPhoneTestOK       : user.isPhoneTestOK,
            isEmailTestStart    : user.isEmailTestStart,
            isEmailTestOK       : user.isEmailTestOK,
        },

        isStandardType          : admin.isStandardType,
        isConfigAdmin           : user.isConfigAdmin,
        isCreateAdmin           : user.isCreateAdmin,
        userKind                : user.userKind,

        isInfoOpened            : user.isUpdateInfoOpened,
        userInfoAlertKind       : user.userInfoAlertKind,
        userInfoMessage         : user.userInfoMessage,

        certiCodeSeq            : user.certiCodeSeq,
        emailCertiCodeSeq       : user.emailCertiCodeSeq,

        list_friends            : friend.list_friends,
    }),
    {
        logInToggle,
        logOut,
        userInfoToggle,
        userUpdate,
        setMessageString,
        changeAlertMessage,
        phoneCheckSend,
        phoneCheckConfirm,
        emailCheckSend,
        emailCheckConfirm,
        falseToPhoneCheckOk,
        falseToPhoneCheckSend,
        falseToEmailCheckOk,
        falseToEmailCheckSend,
        changeShowPassKind,
        changeShowInfoKind,
        changeShowAdminKind,
        changeShowMenuKind,
    }
) (UserInfoContainer);
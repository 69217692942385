import React from "react";
import { useTranslation } from "react-i18next";

const WebPassRefundList = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex justify-content-center my-3">
            <span style={{ fontWeight: 'bold' }}>
                {t("현재_지원하지_않는_기능입니다", { ns: "pass" })}
            </span>
        </div>
    );
}

export default WebPassRefundList;
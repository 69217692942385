import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import i18n from "../locales/config";

import createRequestThunk from "../lib/createRequestThunk";
import createMultiAction from "../lib/createMultiAction";

import ConstData from "../lib/ConstData";
import tossPhaseAction from "../lib/tossPhaseAction";

import { ctMsgType, stCardType } from "../lib/ConstCommand";
import { ctBubbleKind, ctRcvKind } from "../lib/classTalk/CodeEnum";

import { callEoForSendAction } from "../lib/EoBrowserSendUtil";
import { xmitCmd_QuizAnswer } from "../lib/RabbitmqClient";

import { get_quiz_list, get_quiz_info, create_quiz, edit_quiz, delete_quiz, upload_quiz_file, remove_quiz_file } from "../lib/api/quiz";

import { APPEND_QUIZ_MESSAGE, CLOSE_CLASSTALK_MENU_MODAL } from "./classTalk";
import { APPEND_QUIZ_MESSAGE_LIVE, CHANGE_QUIZ_MESSAGE_LIVE } from "./classTalkLive";

// ============================== [ quiz func ] =============================== //
const GET_QUIZ_LIST                     = "quiz/GET_QUIZ_LIST";
const GET_QUIZ_LIST_SUCCESS             = "quiz/GET_QUIZ_LIST_SUCCESS";
const GET_QUIZ_INFO                     = "quiz/GET_QUIZ_INFO";
const GET_QUIZ_INFO_SUCCESS             = "quiz/GET_QUIZ_INFO_SUCCESS";

const CREATE_QUIZ                       = "quiz/CREATE_QUIZ";
const CREATE_QUIZ_SUCCESS               = "quiz/CREATE_QUIZ_SUCCESS";
const EDIT_QUIZ                         = "quiz/EDIT_QUIZ";
const EDIT_QUIZ_SUCCESS                 = "quiz/EDIT_QUIZ_SUCCESS";
const REMOVE_QUIZ                       = "quiz/REMOVE_QUIZ";
const REMOVE_QUIZ_SUCCESS               = "quiz/REMOVE_QUIZ_SUCCESS";

const UPLOAD_QUIZ_FILE                  = "quiz/UPLOAD_QUIZ_FILE";
const UPLOAD_QUIZ_FILE_SUCCESS          = "quiz/UPLOAD_QUIZ_FILE_SUCCESS";
const REMOVE_QUIZ_FILE                  = "quiz/REMOVE_QUIZ_FILE";
const REMOVE_QUIZ_FILE_SUCCESS          = "quiz/REMOVE_QUIZ_FILE_SUCCESS";
const INIT_UPLOAD_INFO                  = "quiz/INIT_UPLOAD_INFO";

const SEND_QUIZ                         = "quiz/SEND_QUIZ";
const SEND_QUIZ_ANSWER                  = "quiz/SEND_QUIZ_ANSWER";

export const getQuizList                = createRequestThunk(GET_QUIZ_LIST, get_quiz_list);
export const getQuizInfo                = createRequestThunk(GET_QUIZ_INFO, get_quiz_info);

export const createQuiz                 = createRequestThunk(CREATE_QUIZ, create_quiz);
export const editQuiz                   = createRequestThunk(EDIT_QUIZ, edit_quiz);
export const removeQuiz                 = createRequestThunk(REMOVE_QUIZ, delete_quiz);

export const uploadQuizFile             = createRequestThunk(UPLOAD_QUIZ_FILE, upload_quiz_file);
export const removeQuizFile             = createRequestThunk(REMOVE_QUIZ_FILE, remove_quiz_file);
export const initUploadInfo             = createAction(INIT_UPLOAD_INFO);

export const sendQuiz                   = createMultiAction(SEND_QUIZ, "quiz", [APPEND_QUIZ_MESSAGE]);
export const sendQuizAnswer             = createAction(SEND_QUIZ_ANSWER);
// ============================================================================ //

// ============================= [ toggle func ] ============================== //
const CLOSE_QUIZ_MODAL                  = "quiz/CLOSE_QUIZ_MODAL";

const OPEN_CREATE_QUIZ_MODAL            = "quiz/OPEN_CREATE_QUIZ_MODAL";
const OPEN_EDIT_QUIZ_MODAL              = "quiz/OPEN_EDIT_QUIZ_MODAL";
const TOGGLE_DELETE_QUIZ                = "quiz/TOGGLE_DELETE_QUIZ";
const TOGGLE_MOVE_URL_OK                = "quiz/TOGGLE_MOVE_URL_OK";

const RCV_CLOSE_CREATE_QUIZ_MODAL       = "quiz/RCV_CLOSE_CREATE_QUIZ_MODAL";
const RCV_CLOSE_EDIT_QUIZ_MODAL         = "quiz/RCV_CLOSE_EDIT_QUIZ_MODAL";
const RCV_QUIZ_CREATE_DONE              = "quiz/RCV_QUIZ_CREATE_DONE";
const RCV_QUIZ_EDIT_DONE                = "quiz/RCV_QUIZ_EDIT_DONE";
const RCV_QUIZ_REMOVE_DONE              = "quiz/RCV_QUIZ_REMOVE_DONE";

const FALSE_TO_CREATE_QUIZ_OK           = "quiz/FALSE_TO_CREATE_QUIZ_OK";

export const closeQuizModal             = tossPhaseAction(CLOSE_QUIZ_MODAL, [CLOSE_CLASSTALK_MENU_MODAL]);

export const openCreateQuizModal        = createAction(OPEN_CREATE_QUIZ_MODAL);
//export const openEditQuizModal          = tossPhaseAction(OPEN_EDIT_QUIZ_MODAL, [CLOSE_CLASSTALK_MENU_MODAL]);
export const openEditQuizModal          = createAction(OPEN_EDIT_QUIZ_MODAL);
export const toggleDeleteQuiz           = createAction(TOGGLE_DELETE_QUIZ);
export const toggleMoveUrlOk            = createAction(TOGGLE_MOVE_URL_OK);

export const rcvCloseCreateQuizModal    = createAction(RCV_CLOSE_CREATE_QUIZ_MODAL);
export const rcvCloseEditQuizModal      = createAction(RCV_CLOSE_EDIT_QUIZ_MODAL);
export const rcvQuizCreateDone          = createRequestThunk(GET_QUIZ_LIST, get_quiz_list, [RCV_QUIZ_CREATE_DONE]);
export const rcvQuizEditDone            = createRequestThunk(GET_QUIZ_LIST, get_quiz_list, [RCV_QUIZ_EDIT_DONE]);
export const rcvQuizRemoveDone          = createRequestThunk(GET_QUIZ_LIST, get_quiz_list, [RCV_QUIZ_REMOVE_DONE]);

export const falseToCreateQuizOk        = createAction(FALSE_TO_CREATE_QUIZ_OK); 
// ============================================================================ //

// ============================= [ MQ function ] ============================== //
const MQ_RCV_QUIZ                       = "quiz/MQ_RCV_QUIZ";
const MQ_RCV_RESPONSE_FINISH_QUIZ       = "quiz/MQ_RCV_RESPONSE_FINISH_QUIZ";

export const mqRcvQuiz                  = createMultiAction(MQ_RCV_QUIZ, "quiz", [APPEND_QUIZ_MESSAGE_LIVE]);
export const mqRcvResponseFinishQuiz    = createMultiAction(MQ_RCV_RESPONSE_FINISH_QUIZ, "quiz", [CHANGE_QUIZ_MESSAGE_LIVE]);
// ============================================================================ //

const initialState = {
    isQuizListMode                  : true,

    isQuizResponseResultMode        : false,

    list_quiz                       : [],
    quiz_info                       : {},
    modalQuiz_info                  : {
        quizSeq                     : -1,
        quizTitle                   : "",
        questionData                : [],
        answerExampleYN             : "",
        answerExampleSeq            : -1,
        answerData                  : {
            answerKind: "",
            answerType: "",
            answerData: ""
        },
        exampleData                 : [],
        congratulationImageData     : []
    },
    upload_info                     : null,

    isCreateQuizModalOpened         : false,
    isEditQuizModalOpened           : false,
    isDeleteQuizModalOpened         : false,

    isMoveUrlOK                     : false,
    isCreateQuizOK                  : false,

    msgText                         : "",

    alertKind                       : ConstData.ALERT_KIND.NONE,
    alertMessage                    : ""
};

const quiz = handleActions({
    [GET_QUIZ_LIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_QUIZ_LIST_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { quizList } = action.payload;

            let clist_quiz = [];
            if (quizList) {
                clist_quiz = quizList.map(row => ({
                    quizSeq     : row.QUIZ_SEQ,
                    quizTitle   : row.QUIZ_TITL
                }));
            }

            return {
                ...state,
                isQuizListMode  : true,
                list_quiz       : clist_quiz,
                quiz_info       : "",
                modalQuiz_info  : "",
                alertKind       : ConstData.ALERT_KIND.NONE,
                alertMessage    : ""
            }
        } else {
            return {
                ...state,
                isQuizListMode  : true,
                list_quiz       : [],
                quiz_info       : "",
                modalQuiz_info  : "",
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_목록_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [GET_QUIZ_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("GET_QUIZ_INFO_SUCCESS - ", action.payload);

        if (isSuccessed) {
            let cQuiz_info = "";
            const { quizInfo } = action.payload;
            const { quizSeq } = action.parms;

            if (quizInfo) {
                cQuiz_info = quizInfo;
                cQuiz_info.quizSeq = quizSeq;
            }

            return {
                ...state,
                isQuizListMode      : false,
                //list_quiz           : [],
                quiz_info           : cQuiz_info,
                modalQuiz_info      : cQuiz_info
            }
        } else {
            return {
                ...state,
                isQuizListMode      : false,
                //list_quiz           : [],
                quiz_info           : "",
                modalQuiz_info      : "",
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : i18n.t("퀴즈_정보_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [CREATE_QUIZ_SUCCESS]: (state, action) => {
        const { userSeq/* , modalQuiz_info */ } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("CREATE_QUIZ_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { insertId } = action.payload;

            if (insertId > -1) {
                const modalQuizInfo = {
                    quizSeq         : insertId,
                    answerData      : { 
                        answerKind  : "",
                        answerType  : "",
                        answerData  : ""
                    }
                };

                callEoForSendAction("quizCreateDone", {
                    kind: "quiz-popup",
                    actionKind: "create-done",
                    quizSeq: modalQuizInfo.quizSeq,
                    userSeq
                });

                console.log("----------");

                /* if (modalQuiz_info.quizTitle) {
                    modalQuizInfo.quizTitle = modalQuiz_info.quizTitle;
                }

                if (modalQuiz_info.questionData) {
                    modalQuizInfo.questionData = modalQuiz_info.questionData;
                }

                if (modalQuiz_info.answerExampleYN) {
                    modalQuizInfo.answerExampleYN = modalQuiz_info.answerExampleYN;
                }

                if (modalQuiz_info.answerExampleSeq !== undefined) {
                    modalQuizInfo.answerExampleSeq = modalQuiz_info.answerExampleSeq;
                }

                if (modalQuiz_info.answerKind) {
                    modalQuizInfo.answerData.answerKind = modalQuiz_info.answerKind;
                }

                if (modalQuiz_info.answerType) {
                    modalQuizInfo.answerData.answerType = modalQuiz_info.answerType;
                }

                if (modalQuiz_info.answerData) {
                    modalQuizInfo.answerData.answerData = modalQuiz_info.answerData;
                }

                if (modalQuiz_info.exampleData) {
                    modalQuizInfo.exampleData = modalQuiz_info.exampleData;
                }

                if (modalQuiz_info.congratulationImageData) {
                    modalQuizInfo.congratulationImageData = modalQuiz_info.congratulationImageData;
                }

                if (modalQuiz_info.congratulationImageType) {
                    modalQuizInfo.congratulationImageType = modalQuiz_info.congratulationImageType;
                } */

                /* return {
                    ...state,
                    modalQuiz_info  : modalQuizInfo,
                    //list_quiz       : state.list_quiz.concat(modalQuizInfo),
                    alertKind       : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage    : i18n.t("퀴즈_추가_성공", { ns: "classTalk" })
                } */

                return produce (state, draft => {
                    draft.modalQuiz_info.quizSeq = modalQuizInfo.quizSeq;
                    //draft.list_quiz = state.list_quiz.concat(modalQuizInfo);
                    draft.alertKind = ConstData.ALERT_KIND.SUCCESS;
                    draft.alertMessage = i18n.t("퀴즈_추가_성공", { ns: "classTalk" });
                });
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("퀴즈_추가_실패", { ns: "classTalk" })
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_추가_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [EDIT_QUIZ_SUCCESS]: (state, action) => {
        const { userSeq, modalQuiz_info } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("EDIT_QUIZ_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                callEoForSendAction("quizEditDone", {
                    kind: "quiz-popup",
                    actionKind: "edit-done",
                    quizSeq: modalQuiz_info.quizSeq,
                    userSeq
                });

                return produce (state, draft => {
                    const newQuiz_info = draft.list_quiz.find(info => info.quizSeq === modalQuiz_info.quizSeq);
                    if (newQuiz_info) {
                        /* if (modalQuiz_info.quizTitle) {
                            newQuiz_info.quizTitle = modalQuiz_info.quizTitle;
                        }

                        if (modalQuiz_info.questionData) {
                            newQuiz_info.questionData = modalQuiz_info.questionData;
                        }

                        if (modalQuiz_info.answerExampleYN) {
                            newQuiz_info.answerExampleYN = modalQuiz_info.answerExampleYN;
                        }

                        if (modalQuiz_info.answerExampleSeq !== undefined) {
                            newQuiz_info.answerExampleSeq = modalQuiz_info.answerExampleSeq;
                        }

                        if (modalQuiz_info.answerKind) {
                            newQuiz_info.answerData.answerKind = modalQuiz_info.answerKind;
                        }

                        if (modalQuiz_info.answerType) {
                            newQuiz_info.answerData.answerType = modalQuiz_info.answerType;
                        }

                        if (modalQuiz_info.answerData) {
                            newQuiz_info.answerData.answerData = modalQuiz_info.answerData;
                        }

                        if (modalQuiz_info.exampleData) {
                            newQuiz_info.exampleData = modalQuiz_info.exampleData;
                        }

                        if (modalQuiz_info.congratulationImageData) {
                            newQuiz_info.congratulationImageData = modalQuiz_info.congratulationImageData;
                        }

                        if (modalQuiz_info.congratulationImageType) {
                            newQuiz_info.congratulationImageType = modalQuiz_info.congratulationImageType;
                        } */

                        draft.alertKind = ConstData.ALERT_KIND.SUCCESS;
                        draft.alertMessage = i18n.t("퀴즈_정보_수정_성공", { ns: "classTalk" });
                    }
                });
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("퀴즈_정보_수정_실패", { ns: "classTalk" })
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_정보_수정_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [REMOVE_QUIZ_SUCCESS]: (state, action) => {
        const { userSeq, quizSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("REMOVE_QUIZ_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                if (state.modalQuiz_info.quizSeq === quizSeq) {
                    callEoForSendAction("quizRemoveDone", {
                        kind: "quiz-popup",
                        actionKind: "remove-done",
                        quizSeq,
                        userSeq
                    });

                    return {
                        ...state,
                        //list_quiz       : state.list_quiz.filter(info => info.quizSeq !== quizSeq),
                        alertKind       : ConstData.ALERT_KIND.SUCCESS,
                        alertMessage    : i18n.t("퀴즈_삭제_성공", { ns: "classTalk" })
                    }
                } else {
                    return {
                        ...state
                    }
                }
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("퀴즈_삭제_실패", { ns: "classTalk" })
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_삭제_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [UPLOAD_QUIZ_FILE_SUCCESS]: (state, action) => {
        //const { userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("UPLOAD_QUIZ_FILE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUploadSuccess } = action.payload;

            if (isUploadSuccess) {
                const { kind, seq } = action.parms;
                const { path } = action.payload;

                console.log(ConstData.MAIN_HOST_ADD + ConstData.DOWNLOAD_DIR + path);

                return {
                    ...state,
                    upload_info     : {
                        kind        : kind,
                        seq         : seq,
                        path        : ConstData.MAIN_HOST_ADD + ConstData.DOWNLOAD_DIR + path
                    },
                    alertKind       : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage    : i18n.t("퀴즈_파일_업로드_성공", { ns: "classTalk" })
                }
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("퀴즈_파일_업로드_실패", { ns: "classTalk" })
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_파일_업로드_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [REMOVE_QUIZ_FILE_SUCCESS]: (state, action) => {
        //const { userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("REMOVE_QUIZ_FILE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                const { kind, seq } = action.parms;

                return {
                    ...state,
                    upload_info     : {
                        kind        : kind,
                        seq         : seq,
                        path        : ""
                    },
                    alertKind       : ConstData.ALERT_KIND.SUCCESS,
                    alertMessage    : i18n.t("퀴즈_파일_삭제_성공", { ns: "classTalk" })
                }
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("퀴즈_파일_삭제_실패", { ns: "classTalk" })
                }
            }
        } else {
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("퀴즈_파일_삭제_요청_실패", { ns: "classTalk" })
            }
        }
    },

    [INIT_UPLOAD_INFO]: (state, action) => {
        console.log("INIT_UPLOAD_INFO");

        return {
            ...state,
            upload_info     : null,
            alertKind       : ConstData.ALERT_KIND.NONE,
            alertMessage    : ""
        }
    },

    [SEND_QUIZ]: (state, action) => {
        console.log("SEND_QUIZ - ", action.payload);
        const { userNickname, data, list_member, quizSeq } = action.payload;

        let msgText = {
            kind : ctBubbleKind.SendMsg,
            msgInfo: {
                kind    : stCardType.Quiz,
                rcvKind : ctRcvKind.Card,
                rcvName : userNickname,
                cardInfo: {
                    isFinishedQuiz  : false,
                    code            : -1,
                    seq             : quizSeq,
                    type            : stCardType.Quiz,
                    visibility      : "visible",
                    todo            : [],
                    data,
                    list_member,
                    response        : []
                }
            }
        }

        return {
            ...state,
            isQuizListMode: true,
            nextPhaseData : {
                msgText, userNickname, isFinishedQuiz: false, information: { quizSeq, data }
            }
        }
    },

    [SEND_QUIZ_ANSWER]: (state, action) => {
        const { data, userNickname, userSeq } = action.payload;
        //console.log("SEND_QUIZ_ANSWER - ", data);

        xmitCmd_QuizAnswer("quiz", "sendQuizAnswer", { userNickname, data, userSeq });

        return {
            ...state,
        }
    },

    /**********************************************************/
    /////////////////////////// 모 달 //////////////////////////
    /**********************************************************/

    [CLOSE_QUIZ_MODAL]: (state, action) => {
        //console.log("CLOSE_QUIZ_MODAL - ", action.payload);

        return {
            ...state,
            isQuizListMode : true
        }
    },

    [OPEN_CREATE_QUIZ_MODAL]: (state, action) => {
        const { quizSeq, userSeq } = action.payload;
        console.log("OPEN_CREATE_QUIZ_MODAL - ", action.payload);

        callEoForSendAction("quizOpen", { quizSeq, userSeq });

        return {
            ...state
        }
    },

    [OPEN_EDIT_QUIZ_MODAL]: (state, action) => {
        const { quizSeq, userSeq } = action.payload;
        console.log("OPEN_EDIT_QUIZ_MODAL - ", action.payload);

        callEoForSendAction("quizOpen", { quizSeq, userSeq });

        return {
            ...state,
            isQuizListMode          : true,
            isEditQuizModalOpened   : true
        }
    },

    [TOGGLE_DELETE_QUIZ]: (state, action) => {
        console.log("TOGGLE_DELETE_QUIZ - ", action.payload);

        return {
            ...state
        }
    },

    [RCV_CLOSE_EDIT_QUIZ_MODAL]: (state, action) => {
        //const { quizSeq, userSeq } = action.payload;
        console.log("RCV_CLOSE_EDIT_QUIZ_MODAL - ", action.payload);

        return {
            ...state,
            isQuizListMode          : true,
            isEditQuizModalOpened   : false
        }
    },

    [RCV_QUIZ_CREATE_DONE]: (state, action) => {
        console.log("RCV_QUIZ_CREATE_DONE - ", action.payload);

        return {
            ...state
        }
    },

    [RCV_QUIZ_EDIT_DONE]: (state, action) => {
        console.log("RCV_QUIZ_EDIT_DONE - ", action.payload);

        return {
            ...state
        }
    },

    [RCV_QUIZ_REMOVE_DONE]: (state, action) => {
        console.log("RCV_QUIZ_REMOVE_DONE - ", action.payload);

        return {
            ...state,
            modalQuiz_info          : "",
            isQuizListMode          : true,
            isEditQuizModalOpened   : false
        }
    },

    [TOGGLE_MOVE_URL_OK]: (state, action) => {
        const { isMoveUrlOK } = action.payload;
        console.log("TOGGLE_MOVE_URL_OK - ", action.payload);

        return {
            ...state,
            isMoveUrlOK             : isMoveUrlOK
        }
    },

    [FALSE_TO_CREATE_QUIZ_OK]: (state, action) => {
        console.log("FALSE_TO_CREATE_QUIZ_OK");

        return {
            ...state,
            isCreateQuizOK          : false
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_RCV_QUIZ]: (state, action) => { //학생이 선생님으로부터 퀴즈를 수신하는 부분.
        const { userNickname, data, quizSeq, msgIndex, iconSeq } = action.payload;
        //console.log("MQ_RCV_QUIZ - ", action.payload);

        let msgText = {
            kind : ctBubbleKind.RcvMsg,
            msgInfo: {
                kind    : ctMsgType.Quiz,
                rcvKind : ctRcvKind.Teacher,
                rcvName : userNickname,
                iconSeq, 
                cardInfo: {
                    isCurrent       : true,
                    isPossiblePass  : false,
                    isFinishedQuiz  : false,
                    idx             : msgIndex,
                    code            : -1,
                    seq             : quizSeq,
                    type            : stCardType.Quiz,
                    visibility      : "visible",
                    data            : data.data,
                    todo            : [],
                }
            }
        }

        return {
            ...state,
            nextPhaseData : { msgText }
        }
    },

    // 선생님이 퀴즈 응답 더이상 받지 않겠다고 학생들에게 보내는 경우 학생들이 수신하는 부분
    [MQ_RCV_RESPONSE_FINISH_QUIZ]: (state, action) => {
        const { liveSeq, quizSeq, idx } = action.payload;
        console.log("MQ_RCV_RESPONSE_FINISH_QUIZ - ", action.payload);

        return {
            ...state,
            nextPhaseData: { liveSeq, quizSeq, idx }
        }
    },
}, initialState);

export default quiz;
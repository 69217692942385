import React from 'react';
import { useTranslation } from "react-i18next";
import { FaExclamationTriangle, FaChevronLeft, FaCircle, FaRocketchat, FaSms } from 'react-icons/fa';
// import teamUpImg from '../../image/liveTool/blue_teamup.png';

import CustomProfileImage from '../../user/profile/CustomProfileImage';
import ConstData from '../../../lib/ConstData';

const FriendProfileWindow = ({ friend_info, performChatRoomInfo, handleSelectedFriendFunc }) => {
    const { t } = useTranslation();
    
    return (
        <div className="profile">
            {
                performChatRoomInfo.chatRoomSeq !== -1 &&
                <div className="py-4">
                    <FaExclamationTriangle className="font-lg red-text" />
                    <div className="d-flex flex-column text-center mt-2">
                        <span className="red-text"><b>{t("팀업_알림_메시지_20", { ns: "chat" })}</b></span>
                        <span className="blue-grey-text small">{t("팀업_알림_메시지_21", { ns: "chat" })}</span>
                        <span className="hiclasstv-blue white-text font-weight-bold p-2 mt-2 rounded" title={t("현재_참여_중인_팀업", { ns: "chat"}) + " [" + performChatRoomInfo.chatRoomName + "]"}>
                            [{t("참여_중", { ns: "chat"})}] {performChatRoomInfo.chatRoomName}
                        </span>
                    </div>
                </div>
            }
            <div>
                <div className="d-flex align-items-center justify-content-center position-absolute" style={{ left: '0' }}>
                    <button type="button" className="no-effect rounded" title={t("이전", { ns: "common"})} onClick={() => handleSelectedFriendFunc({ selectedFunc: "NONE" })}>
                        <FaChevronLeft className="font-md" aria-hidden="true" />
                    </button>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center area-80">
                    <div className="position-relative px-2">
                        <CustomProfileImage url={friend_info.profileImgUrl} style={{ width: '5rem' }} alt={friend_info.userNickname} />
                        <div className="position-absolute" style={{ right: '0', bottom: '0' }}>
                            {
                                friend_info.userStatus === ConstData.USER_STATUS.LOG_IN &&
                                <FaCircle className="font-lg green-text" aria-hidden="true" />
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center text-center my-2 w-100">
                        <span className="hiclass-text font-weight-bold" title={friend_info.userNickname}><b>{friend_info.userNickname}</b></span>
                        <span className="hiclasstv-deepDarkgrey-text small" title={friend_info.userProfile}>{friend_info.userProfile}</span>
                    </div>
                    <div className="justify-content-around m-1 w-100" style={{ display: 'none' }}> {/*"d-flex"*/}
                        <button type="button" className="no-effect rounded area-40" onClick={() => handleSelectedFriendFunc({ selectedFunc: "CREATE", friend_info: friend_info })}>
                            <FaRocketchat className="font-xlg hiclasstv-blue-text mb-2" />
                            {t("1:1_팀업", { ns: "chat" })}
                        </button>
                        {
                            friend_info.userStatus !== ConstData.USER_STATUS.LOG_OUT ?
                                <button type="button" className="no-effect rounded area-40" onClick={() => handleSelectedFriendFunc({ selectedFunc: "CALL", friend_info: friend_info })}>
                                    <FaSms className="font-xlg hiclasstv-blue-text mb-2" />
                                    {t("로그인_재촉", { ns: "friend"})}
                                </button> :
                                <button type="button" className="no-effect rounded area-40" onClick={() => handleSelectedFriendFunc({ selectedFunc: "CREATE_TALK", friend_info: friend_info, type: "live" })}>
                                    <img src="/images/liveTool/blue_teamup.png" aria-hidden="true" style={{ width: '2rem', height: '2rem', marginBottom: '.5rem' }} alt="" />
                                    {t("팀업", { ns: "chat" })}
                                </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FriendProfileWindow;
import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const RemoveUrlAlertModal = ({ isModalOpened, handleAction_info, handleRemoveUrlAlertModal }) => {
    const { t } = useTranslation();

    const [lFileName, setFileName] = useState("");

    useEffect(() => {
        if (handleAction_info !== undefined && handleAction_info !== null) {
            if (handleAction_info.file_info !== undefined && handleAction_info.file_info !== null) {
                let filePath = handleAction_info.file_info.filePath;
                if (filePath && filePath !== "") {
                    let arr = filePath.split("/");
                    let arrLength = arr.length;
                    let fileName = arr[arrLength - 1];
                    console.log(fileName);
                    setFileName(fileName);
                }
            }
        }
    }, [handleAction_info]);

    return (
        <Modal isOpen={isModalOpened} size="sm" wrapClassName="quiz-modal" /* style={{ justifyContent: 'center' }} */ centered backdrop={false}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleRemoveUrlAlertModal(false)}>
                    <IoMdClose />
                </span>
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex text-center my-2 dark-grey-text small">
                    {t("퀴즈_알림_메시지_9", { ns: "classTalk" })}<br />
                    {t("퀴즈_알림_메시지_9_1", { ns: "classTalk" })}<br />
                    {t("퀴즈_알림_메시지_9_2", { ns: "classTalk" })}
                </div>
                <div className="content-area dark-grey-text mt-2 mb-0">
                    <span className="small mb-1">[{t("파일이름", { ns: "classTalk" })}]</span>
                    <div className="text-area text-center area-80">
                        <span className="hiclass-text" title={lFileName}>
                            <b>{lFileName}</b>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center my-2">
                    <button type="button" className="btn modal-btn modal-btn-danger" title={t("예", { ns: "common" })} onClick={() => handleRemoveUrlAlertModal(true)}>
                        {t("예", { ns: "common" })}
                    </button>
                    <button type="button" className="btn modal-btn modal-btn-success" title={t("아니오", { ns: "common" })} onClick={() => handleRemoveUrlAlertModal(false)}>
                        {t("아니오", { ns: "common" })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default RemoveUrlAlertModal;
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse } from 'reactstrap';
import { FaLaptop, FaPlusCircle } from 'react-icons/fa';

import ConstData from '../../lib/ConstData';

import Alerts from '../etc/list/Alerts';
import WebListArrowButton from '../etc/button/WebListArrowButton';
import WebWatchLiveList from './list/WebWatchLiveList';

const WebMyWatchingLiveList = ({ isGuestYn, broadcastOpenYN, list_watchLive, list_requestedWatchLive, alertKind, alertMessage, searchLiveInfo, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lSearchLiveInfo, setSearchLive] = useState({});
    const [liveCode, setLiveCode] = useState("");
    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (list_watchLive.length > 0) {
            setIsOpened(true);
        }
    }, [list_watchLive]);

    useEffect(() => {
        setSearchLive(searchLiveInfo);
    }, [searchLiveInfo]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter') {
            checkSearchLive();
        }
    }

    const onChangeLiveCode = (e) => {
        if (alertKind !== ConstData.ALERT_KIND.NONE) {
            handleSelectedFunc({selectedFunc:"CANCEL"});
        }

        let str = checkInputValue(e.target.value);
        setLiveCode(str);
    }

    const checkSearchLive = () => {
        if (liveCode === "" || liveCode === undefined || liveCode === null) {
            setAlertMessage(t("클래스_알림_메시지_16", { ns: "live" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        } else {
            handleSelectedFunc({selectedFunc:"SEARCH", liveCode:liveCode});
        }
    }

    const checkInputValue = (str) => {
        let convertStr = str.replace(/[^a-z0-9@_.-]/gi, '');
        return convertStr;
    }

    const onCloseAddLive = (e) => {
        setSearchLive({});
        setLiveCode("");
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaLaptop className="font-lg mr-2" aria-hidden="true" />{t("참여_클래스", { ns: "live" })}
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addWatchLive">
                            <FaPlusCircle className="font-md mr-1" />{t("클래스_추가", { ns: "live" })}
                        </button>
                    </div>
                    <WebListArrowButton toggle_id={'watchLiveList'} list_array={list_watchLive} isOpened={isOpened} />
                </div>
                <UncontrolledCollapse toggler="#addWatchLive" defaultOpen={false} onExiting={onCloseAddLive}>
                    <div className="d-flex py-3 align-items-center justify-content-center border-bottom">
                        <div className="card web-list-card border-primary">
                            <div className="input-area mt-0">
                                <div className="input-label">
                                    <span><b>{t("클래스_코드", { ns: "live" })}</b></span>
                                </div>
                                <div className="input-data">
                                    <div className="d-flex input-group justify-content-center">
                                        <input type="text" id="searchLiveCode" value={liveCode} className="d-flex flex-fill web-search-input" placeholder={t("클래스_알림_메시지_10", { ns: "live" })} onChange={onChangeLiveCode} onKeyPress={handleKeyPress} />
                                    </div>
                                </div>
                            </div>
                            <Alerts kind={lAlertKind} message={lAlertMessage} />
                            <div className="d-flex text-center mt-3">
                                <button type="button" className="btn btn-md hiclasstv-blue white-text m-0 px-4 py-2" onClick={checkSearchLive} disabled={liveCode !== "" ? false : true}>
                                    <b>{t("검색", { ns: "common" })}</b>
                                </button>
                            </div>
                            {
                                lSearchLiveInfo !== undefined && lSearchLiveInfo !== null && lSearchLiveInfo.liveSeq >= 0 ?
                                <div className="card web-list-card border-none grey lighten-3 mt-4 justify-content-center" style={{ width:'90%' }}>
                                    <div className="d-flex flex-column py-2 w-100">
                                        <div className="input-area transparent">
                                            <span className="input-label area-30">
                                                {t("클래스_이름", { ns: "live" })} |
                                            </span>
                                            <div className="input-data area-70">
                                                <div className="text-area">
                                                    <span className="hiclass-text" title={lSearchLiveInfo.liveName}>
                                                        <b>{lSearchLiveInfo.liveName}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-area transparent">
                                            <div className="input-label area-30">
                                                {t("진행자", { ns: "live" })} |
                                            </div>
                                            <div className="input-data area-70">
                                                <div className="text-area">
                                                    <span className="hiclass-text" title={lSearchLiveInfo.teacherNickname}>
                                                        <b>{lSearchLiveInfo.teacherNickname}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        lSearchLiveInfo.isCanApply ? 
                                            lSearchLiveInfo.liveCertiYN === null ?
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <button type="button" className="btn btn-md py-1 px-2 hiclasstv-blue white-text" onClick={() => handleSelectedFunc({ selectedFunc: "APPLY", liveSeq: lSearchLiveInfo.liveSeq, teacherSeq: lSearchLiveInfo.teacherSeq })}>
                                                        {t("신청", { ns: "common" })}
                                                    </button>
                                                    <button type="button" className="btn btn-md py-1 px-2 red white-text" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL" })}>
                                                        {t("취소", { ns: "common" })}
                                                    </button>
                                                </div> :
                                                <div>
                                                    <button type="button" className="btn btn-md py-1 px-2 hiclasstv-blue white-text" onClick={() => handleSelectedFunc({ selectedFunc: "RE_APPLY", liveSeq: lSearchLiveInfo.liveSeq, teacherSeq: lSearchLiveInfo.teacherSeq })}>
                                                        {t("신청", { ns: "common" })}
                                                    </button>
                                                    <button type="button" className="btn btn-md py-1 px-2 red white-text" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL" })}>
                                                        {t("취소", { ns: "common" })}
                                                    </button>
                                                </div> :
                                            lSearchLiveInfo.liveCertiYN === null ? 
                                                <div className="d-flex  flex-column align-items-center justify-content-center">
                                                    <span className="red-text small"><b>{t("신청불가", { ns: "live" })}</b></span>
                                                    <span className="grey-text small"><b>{t("클래스_알림_메시지_17", { ns: "live" })}</b></span>
                                                </div> :
                                            lSearchLiveInfo.liveCertiYN === 'Y' ?
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="green-text small"><b>{t("승인완료", { ns: "live" })}</b></span>
                                                </div> :
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <span className="grey-text small"><b>{t("승인대기중", { ns: "live" })}</b></span>
                                                </div>
                                    }
                                </div> :
                                <></>
                            }
                        </div>
                    </div>
                </UncontrolledCollapse>
                <div className="px-2">
                    <UncontrolledCollapse toggler="#watchLiveList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                        <WebWatchLiveList
                            isGuestYn={isGuestYn}
                            broadcastOpenYN={broadcastOpenYN}
                            list_watchLive={list_watchLive}
                            list_requestedWatchLive={list_requestedWatchLive}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </UncontrolledCollapse>
                </div>
            </div>
        </div>
    );
}
export default WebMyWatchingLiveList;
import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const create_web = ({ chatRoomName, creatorSEQ, userArr }) => {
    const userSEQList = userArr.filter(user => user.userSeq !== creatorSEQ).map(user => user.userSeq);
    //console.log('api create web chatRoom : ', userSEQList);

    return axiosClient.post(`/chatting?kind=create&mode=web`, {
        chatRoomName, creatorSEQ, userSEQList
    }, options);
};

export const create = ({ chatRoomName, creatorSEQ, userArr, isWithTalk, isWithEntry }) => {
    if (userArr !== undefined && userArr !== null && userArr.length > 1) {
        const userSEQList = userArr.filter(user => user.userSeq !== creatorSEQ).map(user => user.userSeq);
        //console.log('api create chatRoom with : ', userSEQList);

        return axiosClient.post(`/chatting?kind=create&mode=hiclass`, {
            chatRoomName, creatorSEQ, userSEQList, isWithTalk, isWithEntry
        }, options);
    } else {
        //console.log('api create chatRoom with me');

        return axiosClient.post(`/chatting?kind=create&mode=hiclass`, {
            chatRoomName, creatorSEQ, isWithEntry
        }, options);
    }
};

export const update_name = ({ chatRoomSeq, userSeq, chatRoomName }) => {
    //console.log('api update_name : [' + chatRoomSeq + '] ' + chatRoomName);

    return axiosClient.put(`/chatting/${chatRoomSeq}/${userSeq}?kind=name`, {
        chatRoomName
    }, options);
};

export const update_type = ({ chatRoomSeq, userSeq, chatRoomType }) => {
    //console.log('api update_type : [' + chatRoomSeq + '] ' + chatRoomType);

    return axiosClient.put(`/chatting/${chatRoomSeq}/${userSeq}?kind=type`, {
        chatRoomType
    }, options);
};

export const join = ({ chatRoomSeq, userSeq }) => {
    //console.log('api join : ' + chatRoomSeq + ' / userSEQ : ' + userSeq);

    return axiosClient.post(`/chatting/${chatRoomSeq}/${userSeq}`, {}, options);
};

export const finish = ({ chatRoomSeq, userSeq }) => {
    //console.log('api chatting finish ) chatRoomSEQ : ' + chatRoomSeq + ' / userSEQ : ' + userSeq);

    return axiosClient.delete(`/chatting/${chatRoomSeq}/${userSeq}?kind=finish`, options);
};

export const exit = ({ chatRoomSeq, userSeq }) => {
    //console.log('api chatRoom exit ) chatRoomSEQ : ' + chatRoomSeq + ' / userSEQ : ' + userSeq);

    return axiosClient.delete(`/chatting/${chatRoomSeq}/${userSeq}?kind=exit`, options);
};

export const remove_chat = ({ chatRoomSeq, userSeq }) => {
    //console.log('api chatRoom remove ) chatRoomSEQ : ' + chatRoomSeq);
    return axiosClient.delete(`/chatting/${chatRoomSeq}/${userSeq}?kind=remove`, options);
};

export const get_inviteable_list = ({ chatRoomSeq, userSeq }) => {
    return axiosClient.get(`/chatting/${chatRoomSeq}/${userSeq}`, options);
};

export const invite_chat_member = ({ chatRoomSeq, userSeq, userSEQList }) => {
    return axiosClient.post(`/chatting/${chatRoomSeq}?kind=invite-member`, {
        userSEQ: userSeq,
        userSEQList: userSEQList
    }, options);
};

export const remove_chat_member = ({ chatRoomSeq, userSeq, removeUserSeq }) => {
    //console.log('api chat room member remove ) chatRoomSEQ : ' + chatRoomSeq);
    return axiosClient.delete(`/chatting/${chatRoomSeq}/${userSeq}/${removeUserSeq}?kind=remove-member`, options);
};
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaTimes, FaSearch } from 'react-icons/fa';

import ConstData from '../../../lib/ConstData';

const FindIdPasswordWindow = ({ isFindIdPasswordOpened, findIdPasswordOption, findIdPasswordStep, handleSelectedFunc, children }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isFindIdPasswordOpened} backdrop={false}>
            <div className="card p-2 align-items-center justify-content-center">
                <div className="title-area justify-content-end text-right mb-3 p-0">
                    <button type="button" className="hiclasstv-btn p-1" title={t("닫기", { ns: "user" })} onClick={() => handleSelectedFunc({selectedFunc:"CLOSE", mode:'web'})}>
                        <FaTimes className="d-flex m-1" />
                    </button>
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                    <div className="d-flex align-items-center font-lg font-weight-bold blue-text">
                        <FaSearch className="d-flex mr-3" />
                        {
                            findIdPasswordOption === ConstData.FIND_OPTION.START ? 
                                t("ID/비밀번호_찾기", { ns: "user" }) : 
                            findIdPasswordOption === ConstData.FIND_OPTION.ID ?
                                t("ID_찾기", { ns: "user" }) :
                            findIdPasswordOption === ConstData.FIND_OPTION.PW ?  
                                <>
                                    {
                                        findIdPasswordStep === ConstData.FIND_STEP.RESET_PW ?
                                            t("비밀번호_재설정", { ns: "user" }) :
                                            t("비밀번호_찾기", { ns: "user" })
                                    }
                                </> :
                            <></>
                        }
                    </div>
                </div>
                {children}
            </div>
        </Modal>
    );
}

export default FindIdPasswordWindow;
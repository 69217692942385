/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";

const WebHiClassInfoContents = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column justify-content-center">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                            <span className="hiclasstv-blue-text">{t("인포_컨텐츠_설명_1", { ns: "info" })}&nbsp;</span>
                            <span className="hiclasstv-darkblue-text">{t("인포_컨텐츠_설명_2", { ns: "info" })}</span>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mt-2 mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents01.png" alt="contents01.png" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        {t("인포_컨텐츠_설명_3", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        {t("인포_컨텐츠_설명_4", { ns: "info" })}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents02.png" alt="contents02.png" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents03.png" alt="contents03.png" />
                                </div>
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        {t("인포_컨텐츠_설명_5", { ns: "info" })}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 w-100">
                            <div className="d-flex flex-wrap mb-2">
                                <div className="d-flex align-items-center col-md-6 py-3">
                                    <span className="d-flex" style={{ fontSize: '1rem' }}>
                                        {t("인포_컨텐츠_설명_6", { ns: "info" })}
                                    </span>
                                </div>
                                <div className="d-flex align-items-center col-md-6">
                                    <img className="w-100" src="/images/info/contents/contents04.png" alt="contents04.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassInfoContents;
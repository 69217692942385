/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import { FaTimes, FaLock } from "react-icons/fa";
import { MDBInput } from "mdbreact";

import ConstData from "../../lib/ConstData";

import Alerts from "../etc/list/Alerts";
import { isDevVersion } from "../../lib/func/ExportFunction";

const WebUserLogin = ({ isLogInOpened, userKind, handleSelectedFunc, userInfo, loginMessage, alertKind, getCookiesInfoHandle }) => {
    const { t } = useTranslation();

    const [userID, setUserID] = useState(userInfo.userID);
    const [password, setPassword] = useState(userInfo.passWD);
    const [userNickname, setUserNickname] = useState(userInfo.userNickname);
    const [liveStudentNickname, setLiveStudentNickname] = useState(userInfo.userNickname);
    const [autoLogin, setAutoLogin] = useState(false);

    const [errorMsg, setErrorMsg] = useState(loginMessage);
    const [errorKind, setErrorKind] = useState(alertKind);

    useEffect(() => {
        setErrorKind(alertKind);
        setErrorMsg(loginMessage);
    }, [alertKind, loginMessage]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            bt_handleLogin();
        }
    }

    const onChangeUserId = (e) => {
        //let convertStr = e.target.value.replace(/[^a-z0-9_]/gi, '');
        if (errorMsg !== "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
        }
        setUserID(e.target.value);
    }

    const onChangePassword = (e) => {
        if (errorMsg !== "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
        }
        setPassword(e.target.value);
    }

    const onChangeUserNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: t("유효성_검사_닉네임_글자수오류", { ns: "user" }), kind: "danger" });
        } else {
            if (errorMsg !== "") {
                handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
            }
        }
        setUserNickname(str);
    }

    const onChangeLiveStudentNickname = (e) => {
        let str = e.target.value;
        str = str.replace(' ', '');
        if (str.length > 15) {
            str = str.substr(0, 15);
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message:  t("유효성_검사_닉네임_글자수오류", { ns: "user" }), kind: "danger" });
        } else {
            if (errorMsg !== "") {
                handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
            }
        }
        setLiveStudentNickname(str);
    }

    const onChangeAutoLogin = (e) => {
        setAutoLogin(e.target.checked);
    }

    const onClickSignUp = (e) => {
        // local version 은 회원 가입 지원하지 않기로 함 ... by hjkim 20230908
        // if (ConstData.IS_LOCAL_VERSION) {
        //     handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" });
        // } else {
            if (userKind === 'hiclasstv') {
                const userSubDomain = window.location.hostname.split(".");
                if (isDevVersion(userSubDomain)) {
                    handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" });
                } else {
                    // handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "현재 기존 고객을 대상으로 서비스를 제공하고 있어 신규 회원가입을 받지 않습니다. (21년 4월 초 오픈 예정)", kind: "danger" });
                    handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: t("회원가입_문의_알림", { ns: "user" }), kind: "danger" });
                }
            } else {
                handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" });
            } 
        // }
        /* if (userKind === 'hiclasstv') {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "현재 기존 고객을 대상으로 서비스를 제공하고 있어 신규 회원가입을 받지 않습니다. (21년 4월 초 오픈 예정)", kind: "danger" });
        } else {
            handleSelectedFunc({ selectedFunc: "REGIST_TOGGLE" });
        } */
    }

    const bt_handleLogin = () => {
        if (userID === "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: t("유효성_검사_ID_입력오류", { ns: "user" }), kind: "danger" });
            return;
        }

        if (password === "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: t("유효성_검사_비밀번호_입력오류", { ns: "user" }), kind: "danger" });
            return;
        }

        if (ConstData.IS_LOCAL_VERSION) {
            if (liveStudentNickname === "") {
                console.log("live 용 임시 닉네임 입력 안 함 - liveStudentNickname => ", liveStudentNickname);

                /* handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "닉네임을 입력해 주세요.", kind: "danger" });
                return; */
            } else {
                console.log("live 용 임시 닉네임 입력 함 - liveStudentNickname => ", liveStudentNickname);
            }
        }

        if (errorMsg !== "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
        }

        handleSelectedFunc({
            selectedFunc: "LOGIN",
            loginInfo: ({ userID, password, userNickname: liveStudentNickname, isAutoLogin: autoLogin }),
            mode: "web"
        });

        /* if (userID === "") {
            handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "아이디를 입력해 주세요.", kind: "danger" });
        } else {
            if (password === "") {
                handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "비밀번호를 입력해 주세요.", kind: "danger" });
            } else {
                handleSelectedFunc({ selectedFunc: "L_MESSAGE", message: "", kind: "none" });
                handleSelectedFunc({
                    selectedFunc: "LOGIN",
                    loginInfo: ({ userID, password, isAutoLogin: autoLogin }),
                    mode: "web"
                });
            }
        } */
    }

    const onCloseModal = () => {
        setUserID(userInfo.userID);
        setPassword(userInfo.passWD);
        setUserNickname(userInfo.userNickname);
    }

    return (
        <Modal isOpen={isLogInOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid p-0 m-0">
                <div className="card-body" style={{ paddingTop: '.75rem', paddingBottom: '.5rem', fontSize: '0.9rem' }}>
                    <div className="d-flex w-100 justify-content-end align-items-center p-0 mb-2">
                        <button type="button" className="hiclasstv-button p-1" title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "LOGIN_TOGGLE" })}>
                            <FaTimes className="d-flex m-1" />
                        </button>
                    </div>
                    {/* <div className="d-flex title-area w-100 justify-content-end text-right mb-3">
                        <button type="button" className="hiclasstv-btn p-0" title="닫기" onClick={() => handleSelectedFunc({ selectedFunc: "LOGIN_TOGGLE" })}>
                            <FaTimes className="d-flex m-1 black-text" />
                        </button>
                    </div> */}
                    <div className="d-flex w-100 justify-content-center align-items-center blue-text">
                        <FaLock className="font-xlg mr-2" data-toggle="tooltip" title={t("로그인", { ns: "user" }) + ":"} />
                        <h2 className="m-0">{t("LogIn", { ns: "user" })}:</h2>
                        {/* <span className="font-xlg blue-text">Log In:</span> */}
                    </div>
                    <div className="d-flex flex-column area-90 justify-content-center align-items-center my-2">
                        {
                            /* <div className="form-check justify-content-end">
                                <div className="md-form">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="defaultCheck" checked={autoLogin} onChange={onChangeAutoLogin} />
                                        <label className="custom-control-label" htmlFor="defaultCheck">Auto Login</label>
                                    </div>
                                </div>
                            </div> */
                        }
                        <MDBInput type="text" id="inputID" className="input-form-control" containerClass="input-form" label={t("yourID", { ns: "user" })} labelClass="input-form-label" placeholder={t("yourID", { ns: "user" })} onChange={onChangeUserId} value={userID} onKeyPress={handleKeyPress} />
                        <MDBInput type="password" id="inputPw" className="input-form-control" containerClass="input-form" label={t("yourPW", { ns: "user" })} labelClass="input-form-label" placeholder={t("yourPW", { ns: "user" })} onChange={onChangePassword} value={password} onKeyPress={handleKeyPress} />
                    </div>
                    {
                        ConstData.IS_LOCAL_VERSION &&
                        <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                            <div className="d-flex align-items-center py-2 area-90">
                                <MDBInput type="text" id="inputNn" className="input-form-control" containerClass="input-form m-0" label={t("클래스용_닉네임", { ns: "user" })} labelClass="input-form-label" placeholder={t("유효성_검사_닉네임_입력오류", { ns: "user" })} onChange={onChangeLiveStudentNickname} value={liveStudentNickname} onKeyPress={handleKeyPress} />
                            </div>
                            <div className="d-flex area-95 justify-content-start">
                                <span className="input-description info">· {t("닉네임_구성_설명", { ns: "user" })}</span>
                            </div>
                            <div className="d-flex area-95 justify-content-start">
                                <span className="input-description info">· {t("클래스용_닉네임_설명_1", { ns: "user" })}</span>
                            </div>
                            <div className="d-flex area-95 justify-content-start">
                                <span className="input-description notice">· {t("클래스용_닉네임_설명_2", { ns: "user" })}</span>
                            </div>
                            <div className="d-flex flex-column area-95 justify-content-center align-items-start">
                                <span className="input-description title">
                                    {t("클래스용_닉네임_설명_3", { ns: "user" })}
                                </span>
                            </div>
                        </div>
                    }
                    {
                        errorMsg !== '' &&
                        <div className="d-flex w-100 justify-content-center align-items-center mt-0 mb-2">
                            <Alerts kind={errorKind} message={errorMsg} style={{ width: '90%' }} />
                        </div>
                    }
                    <div className="d-flex w-100 justify-content-center align-items-center mb-2">
                        <button className="btn btn-md btn-primary px-4" onClick={() => bt_handleLogin()}>
                            {t("Login", { ns: "user" })}
                        </button>
                    </div>
                    {
                        !ConstData.IS_LOCAL_VERSION &&  // local version 은 회원 가입 지원하지 않기로 함 ... by hjkim 20230908
                        <div className="modal-footer flex-column align-items-end pt-2 pb-0 px-3">
                            <p>
                                {t("Not_a_member", { ns: "user" })}
                                <a className="font-weight-bold hiclasstv-blue-text" onClick={onClickSignUp}> Sign Up</a>
                            </p>
                            <p>
                                {t("Forgot", { ns: "user" })}
                                <a className="font-weight-bold" onClick={() => handleSelectedFunc({ selectedFunc: 'FIND_TOGGLE', mode: 'web' })}> <u>{t("ID/Password", { ns: "user" })}</u></a>
                            </p>
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default WebUserLogin;
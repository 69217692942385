import React from "react";
import NotFound from "../components/etc/NotFound";

const NotFoundPage = ({ routeProps }) => {
    return (
        <div className='d-flex w-100 py-5'>
            <NotFound routeProps={routeProps}/>
        </div>
    );
};

export default NotFoundPage;
import React from "react";
import { FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import ConstData from "../../lib/ConstData";

import ChatTitle from "./ChatTitle";

const AloneChattingInfo = ({ isOnAirLive, isChattingLock, isTouchScreenLock, handleSelectedChatFunc }) => {
    const { t } = useTranslation();

    const onChangeAndroidTabletLock = () => {
        handleSelectedChatFunc({ selectedFunc: "TOGGLE_TABLET_LOCK" });
    }

    const onChangeChattingLock = (e) => {
        // setIsChattingLocked(e.target.checked);
        // handleSelectedChatFunc({ selectedFunc: "TOGGLE_CHATTING_LOCK", status: !isChattingLocked });
        handleSelectedChatFunc({ selectedFunc: "TOGGLE_CHATTING_LOCK" });
    }

    return (
        <div className="title-area main justify-content-between" style={{ backgroundColor: '#2874ff' }}>
            <div className="text-area fit-content justify-content-start" style={{ minWidth: '40%' }}>
                <ChatTitle isLocalVersion={ConstData.IS_LOCAL_VERSION} />
            </div>
            <div className="d-flex align-items-center justify-content-end mt-1 white-text font-xsm" /* style={{ visibility: 'collapse' }} */>
                <span title={t("태블릿_잠금", { ns: "classTalk" })}>
                    {t("태블릿_잠금", { ns: "classTalk" })}
                </span>
                <div className="custom-control custom-switch ml-1">
                    <input type="checkbox" className="custom-control-input" id="tabletLock" checked={isTouchScreenLock} onChange={onChangeAndroidTabletLock} />
                    <label className="custom-control-label" htmlFor="tabletLock" />
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-1 white-text font-xsm">
                <span title={t("채팅방_잠금", { ns: "classTalk" })}>
                    {t("채팅방_잠금", { ns: "classTalk" })}
                </span>
                <div className="custom-control custom-switch ml-1">
                    <input type="checkbox" className="custom-control-input" id="chattingLock" checked={isChattingLock} disabled={isTouchScreenLock} onChange={onChangeChattingLock} />
                    <label className="custom-control-label" htmlFor="chattingLock" />
                </div>
            </div>
            {/* <div className="d-flex custom-control custom-switch pl-2">
                <input type="checkbox" className="custom-control-input" id="frozenChat" checked={isFrozenChat} onChange={onChangeFrozenChat} disabled={false} />
                <label className="d-flex custom-control-label align-items-center white-text font-sm" title={t("채팅_잠금", { ns: "classTalk" })} htmlFor="frozenChat">
                    {t("채팅_잠금", { ns: "classTalk" })}
                </label>
            </div> */}
            <div className="d-flex area-10">
                <button type="button" className="d-flex p-1 rounded" title={t("더_보기", { ns: "classTalk" })} onClick={() => handleSelectedChatFunc({ selectedFunc: "LIST_LIVE" })}>
                    <FaBars className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default AloneChattingInfo;
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { FaPlusCircle } from "react-icons/fa";

import { format } from "date-fns";

import ConstData from "../../../lib/ConstData";

import WebCreateUserInfo from "./WebCreateUserInfo";
import Alerts from "../../etc/list/Alerts";

const checkStringValidation = (value) => {
    /* if (value !== undefined && value !== null && value !== "") return true;
    else return false; */
    return value !== undefined && value !== null && value !== "";
}

const checkNumberValidation = (value) => {
    /* if (value !== undefined && value !== null && value > -1) return true;
    else return false; */
    return value !== undefined && value !== null && value > -1;
}

const checkPasswordPattern = (str) => {
    let pattern1 = /[0-9]/;                         // 숫자
    let pattern2 = /[a-zA-Z]/;			            // 문자
    let pattern3 = /[~!@#$%^&*()_+|<>?:{}]/;        // 특수문자

    if (!pattern1.test(str) || !pattern2.test(str) || !pattern3.test(str) || str.length < 8) {
        // alert("비밀번호는 8자리 이상 문자, 숫자, 특수문자로 구성하여야 합니다.");
        return false;
    } else {
        return true;
    }
}

const checkBooleanValidation = (value) => {
    return value !== undefined && value !== null && typeof value === "boolean";
}

const checkStringLengthValidation = (value, min, max) => {
    return value.length >= min && value.length <= max;
}

const WebCreateUsers = forwardRef(({ subDomainSeq, handleSelectedFunc }, ref) => {
    const { t } = useTranslation();

    const [list_createUser, setListCreateUser] = useState([
        { userID: "", userPW: "", userNickname: "", userProfile: "", isIdCheckOK: false, isJoinSuccess: false, isExist: false, userSeq: -1, errMsg: "" }
    ]);

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    useImperativeHandle(ref, () => ({
        updateCreateUsers: (list_user) => handleCreateUsers(list_user)
    }));

    const handleCreateUsers = (list_user) => {
        let handleList_createUser = [];
        let list_createUserCopy = list_createUser;

        for (let i = 0; i < list_createUserCopy.length; i++) {
            let createUser_info = list_createUserCopy[i];
            let user_info = list_user[i];
            if (createUser_info && user_info) {
                if (checkStringValidation(user_info.userID)) createUser_info.userID = user_info.userID;
                if (checkStringValidation(user_info.userPW)) createUser_info.userPW = user_info.userPW;
                if (checkStringValidation(user_info.userNickname)) createUser_info.userNickname = user_info.userNickname;
                if (checkStringValidation(user_info.userProfile)) createUser_info.userProfile = user_info.userProfile;
                if (checkBooleanValidation(user_info.isIdCheckOK)) createUser_info.isIdCheckOK = user_info.isIdCheckOK;
                if (checkBooleanValidation(user_info.isJoinSuccess)) createUser_info.isJoinSuccess = user_info.isJoinSuccess;
                if (checkBooleanValidation(user_info.isExist)) createUser_info.isExist = user_info.isExist;
                if (checkNumberValidation(user_info.userSeq)) createUser_info.userSeq = user_info.userSeq;
                if (checkStringValidation(user_info.errMsg)) createUser_info.errMsg = user_info.errMsg;

                handleList_createUser.push(createUser_info);
            }
        }

        console.log("list_createUser => ", list_createUser);
        console.log("list_createUserCopy => ", list_createUserCopy);
        console.log("handleList_createUser => ", handleList_createUser);

        setListCreateUser(handleList_createUser);
    }

    const handleFunc = ({ selectedFunc, data }) => {
        switch (selectedFunc) {
            case "delete-info": {
                const { idx } = data;
                let filteredList_user = list_createUser.filter((info, index) => index !== idx);
                console.log("filteredList_user => ", filteredList_user);
                setListCreateUser(filteredList_user);
            } break;

            case "alert":
                handleAlertMessage(data);
                break;

            case "update-info": {
                const { createUser_info } = data;
                // console.log("update-info:) data => ", data);
                let changedList_user = list_createUser.map((info, idx) => {
                    if (idx !== createUser_info.index) return info;
                    else {
                        return {
                            userID: createUser_info.userID,
                            userPW: createUser_info.userPW,
                            userNickname: createUser_info.userNickname,
                            userProfile: createUser_info.userProfile,
                            isJoinSuccess: false,
                            userSeq: -1,
                            errMsg: ""
                        };
                    }
                });
                // console.log("changedList_user => ", changedList_user);
                setListCreateUser(changedList_user);
            } break;

            default:
                break;
        }
    }

    const handleAlertMessage = ({ alertKind, alertMessage }) => {
        if (alertKind !== lAlertKind) {
            setAlertKind(alertKind);
        }

        if (alertMessage !== lAlertMessage) {
            setAlertMessage(alertMessage);
        }
    }

    const onClickAddNewUser = () => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        let newUser_data = {
            userID: "",
            userPW: "",
            userNickname: "",
            userProfile: "",
            isIdCheckOK: false,
            isJoinSuccess: false,
            isExist: false,
            userSeq: -1,
            errMsg: ""
        };
        setListCreateUser(current => [...current, newUser_data]);
    }

    const onClickDuplicateCheck = () => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        let check_info = {};

        if (checkNumberValidation(subDomainSeq)) {
            check_info.subDomainSeq = subDomainSeq;
        }

        if (list_createUser && list_createUser.length > 0) {
            check_info.list_createUser = [];

            for (let i = 0; i < list_createUser.length; i++) {
                let createUser_info = list_createUser[i];
                let isValidData = true;
                let errMsg = "";

                if (!checkStringValidation(createUser_info.userID)) {
                    isValidData = false;
                    errMsg = t("ID", { ns: "user" });
                } else {
                    if (!checkStringLengthValidation(createUser_info.userID, 4, 16)) {
                        isValidData = false;
                        errMsg = t("ID", { ns: "user" });
                    }
                }

                if (!isValidData) {
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    setAlertMessage(`${i + 1} ${t("번째_사용자", { ns: "admin" })} ${errMsg} ${t("정보를_확인해_주세요", { ns: "admin" })} (Code.CH187)`);
                    return;
                } else {
                    check_info.list_createUser.push({
                        userID: createUser_info.userID
                    });
                }
            }
        }

        console.log("check_info => ", check_info);

        handleSelectedFunc({ selectedFunc: "DUPLICATE_CHECK_USERS", data: check_info });
    }

    const onClickCreateUsers = () => {
        let create_info = {};

        if (checkNumberValidation(subDomainSeq)) {
            create_info.subDomainSeq = subDomainSeq;
        }

        if (list_createUser && list_createUser.length > 0) {
            create_info.list_createUser = [];

            for (let i = 0; i < list_createUser.length; i++) {
                let createUser_info = list_createUser[i];
                let isValidData = true;
                let saveCreateUser_info = {};
                let errMsg = "";

                if (!checkStringValidation(createUser_info.userID)) {
                    isValidData = false;
                    errMsg = t("ID", { ns: "user" });
                } else {
                    if (!checkStringLengthValidation(createUser_info.userID, 4, 16)) {
                        isValidData = false;
                        errMsg = t("ID", { ns: "user" });
                    } else {
                        saveCreateUser_info.userID = createUser_info.userID;
                    }
                }

                /* if (!checkPasswordPattern(createUser_info.userPW)) {
                    isValidData = false;
                } else {
                    saveCreateUser_info.userPW = createUser_info.userPW;
                } */

                if (!checkStringValidation(createUser_info.userPW)) {
                    isValidData = false;
                    if (errMsg !== "") errMsg += ", ";
                    errMsg += t("비밀번호", { ns: "user" });
                } else {
                    if (!checkStringLengthValidation(createUser_info.userPW, 4, 16)) {
                        isValidData = false;
                        if (errMsg !== "") errMsg += ", ";
                        errMsg += t("비밀번호", { ns: "user" });
                    } else {
                        saveCreateUser_info.userPW = createUser_info.userPW;
                    }
                }

                if (!checkStringValidation(createUser_info.userNickname)) {
                    isValidData = false;
                    if (errMsg !== "") errMsg += ", ";
                    errMsg += t("닉네임", { ns: "user" });
                } else {
                    if (!checkStringLengthValidation(createUser_info.userNickname, 0, 15)) {
                        isValidData = false;
                        if (errMsg !== "") errMsg += ", ";
                        errMsg += t("닉네임", { ns: "user" });
                    } else {
                        saveCreateUser_info.userNickname = createUser_info.userNickname;
                    }
                }

                if (checkStringValidation(createUser_info.userProfile)) {
                    if (!checkStringLengthValidation(createUser_info.userProfile, 0, 30)) {
                        isValidData = false;
                        if (errMsg !== "") errMsg += ", ";
                        errMsg += t("프로필", { ns: "user" });
                    } else {
                        saveCreateUser_info.userProfile = createUser_info.userProfile;
                    }
                }

                if (!isValidData) {
                    setAlertKind(ConstData.ALERT_KIND.DANGER);
                    setAlertMessage(`${i + 1} ${t("번째_사용자", { ns: "admin" })} ${errMsg} ${t("정보를_확인해_주세요", { ns: "admin" })} (Code.CT276)`);
                    return;
                } else {
                    create_info.list_createUser.push(saveCreateUser_info);
                }
            }
        }

        let requestDate = new Date(Date.now());
        create_info.requestDate = format(requestDate, 'yyyy-MM-dd HH:mm:ss');

        console.log("create_info => ", create_info);

        handleSelectedFunc({ selectedFunc: "CREATE_USERS", data: create_info });
    }

    return (
        <div className="content-area">
            <div className="input-area">
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '5%', maxWidth: '5%' }}>
                </div>
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '20%', maxWidth: '20%' }}>
                    {/* <div className="only-web w-100">
                        <span><b>사용자 아이디</b></span>
                    </div> */}
                    <span className="only-web"><b>{t("사용자", { ns: "admin" })} {t("ID", { ns: "user" })}</b></span>
                    <span className="only-mobile"><b>{t("ID", { ns: "user" })}</b></span>
                </div>
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '15%', maxWidth: '15%' }}>
                    {/* <div className="only-web w-100">
                        <span><b>사용자 비밀번호</b></span>
                    </div> */}
                    <span className="only-web"><b>{t("사용자", { ns: "admin" })} {t("비밀번호", { ns: "user" })}</b></span>
                    <span className="only-mobile"><b>{t("비밀번호", { ns: "user" })}</b></span>
                </div>
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '20%', maxWidth: '20%' }}>
                    {/* <div className="only-web w-100">
                        <span><b>사용자 닉네임</b></span>
                    </div> */}
                    <span className="only-web"><b>{t("사용자", { ns: "admin" })} {t("닉네임", { ns: "user" })}</b></span>
                    <span className="only-mobile"><b>{t("닉네임", { ns: "user" })}</b></span>
                </div>
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '25%', maxWidth: '25%' }}>
                    {/* <div className="only-web w-100">
                        <span><b>사용자 프로필</b></span>
                    </div> */}
                    <span className="only-web"><b>{t("사용자", { ns: "admin" })} {t("프로필", { ns: "user" })}</b></span>
                    <span className="only-mobile"><b>{t("프로필", { ns: "user" })}</b></span>
                </div>
                <div className="input-label justify-content-center align-items-center font-md" style={{ width: '15%', maxWidth: '15%' }}>
                    {/* <div className="only-web w-100">
                        <span><b>상태</b></span>
                    </div> */}
                    {/* <span className="only-web"><b>상태</b></span>
                    <span className="only-mobile"><b>상태</b></span> */}
                    <span><b>{t("상태", { ns: "common" })}</b></span>
                </div>
            </div>
            {/* <div className="input-area">
                <div className="input-data flex-column">
                    {
                        list_createUser.length > 0 &&
                        list_createUser.map((info, idx) => (
                            <WebCreateUserInfo
                                key={"create-user-" + idx}
                                idx={idx}
                                createUser_info={info}
                                handleFunc={handleFunc}
                            />
                        ))
                    }
                </div>
            </div> */}
            <div className="d-flex flex-column w-100">
                {
                    list_createUser.length > 0 &&
                    list_createUser.map((info, idx) => (
                        <WebCreateUserInfo
                            key={"create-user-" + idx}
                            idx={idx}
                            createUser_info={info}
                            handleFunc={handleFunc}
                        />
                    ))
                }
            </div>
            <div className="d-flex w-100 my-1" style={{ justifyContent: 'flex-end' }}>
                <div className="add-btn-area" style={{ width: '95%' }}>
                    <button type="button" className="hiclasstv-btn-transparent hiclasstv-light-coral-text p-0" onClick={onClickAddNewUser}>
                        <FaPlusCircle className="font-lg" />
                        <span className="font-weight-bold ml-2">{t("추가", { ns: "common" })}</span>
                    </button>
                </div>
            </div>
            <Alerts kind={lAlertKind} message={lAlertMessage} />
            <div className="add-btn-area" style={{ justifyContent: 'center' }}>
                <button type="button" className="btn btn-md hiclasstv-blue white-text my-0 mx-1 px-4 py-1" onClick={onClickDuplicateCheck}>
                    <span className="font-weight-bold ml-2">{t("ID", { ns: "user" })} {t("중복체크", { ns: "common" })}</span>
                </button>
                <button type="button" className="btn btn-md hiclasstv-blue white-text my-0 mx-1 px-4 py-1" onClick={onClickCreateUsers}>
                    <FaPlusCircle className="font-lg" />
                    <span className="font-weight-bold ml-2">{t("사용자", { ns: "admin" })} {t("생성", { ns: "common" })}</span>
                </button>
            </div>
        </div>
    );
});

export default WebCreateUsers;
import React from "react";
import { FaBars } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { getEntryMemberCount, getMemberCount } from "../../lib/func/ExportFunction";

const PerformChattingInfo = ({ isOnAirLive, performChatRoomInfo, handleSelectedChatFunc }) => {
    const { t } = useTranslation();

    return (
        <div className="title-area main" style={{ backgroundColor: '#2874ff' }}>
            <div className="text-area justify-content-start">
                <span className="hiclass-text white-text" title={performChatRoomInfo.chatRoomName}>
                    {performChatRoomInfo.chatRoomName}
                </span>
                <span className="hiclass-text hiclasstv-yellow-text">
                    <b>&nbsp;{getEntryMemberCount("teamUp", performChatRoomInfo.list_member)}</b>/{getMemberCount(performChatRoomInfo.list_member)}&nbsp;&nbsp;
                </span>
            </div>
            <div className="d-flex area-10">
                {/* <div className="d-flex align-items-center m-0 p-0">
                    <button type="button" className="btn btn-sm red white-text mx-1 px-2 py-1" style={{ borderRadius: '0.5rem', width: 'max-content' }} onClick={() => handleSelectedChatFunc({ selectedFunc: "FINISH", chatRoomSeq: performChatRoomInfo.chatRoomSeq })}>
                        {t("종료", { ns: "common" })}
                    </button>
                </div> */}
                <button type="button" className="d-flex p-1 rounded" title={t("더_보기", { ns: "classTalk" })} onClick={() => handleSelectedChatFunc({ selectedFunc: "CHAT_MENU" })}>
                    <FaBars className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default PerformChattingInfo;
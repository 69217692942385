import React from "react";

import MainContainer from "../containers/MainContainer";
import WebContentTemplate from "../components/template/WebContentTemplate";
import WebStudioContainer from "../containers/etc/WebStudioContainer";

const WebStudioPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <WebStudioContainer
                    browserType={browserType}
                    routeProps={routeProps}
                    currentPageType={currentPageType}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebStudioPage;
import io from "socket.io-client";

import { enRbmqError } from "./ConstCommand";

export class WebClient {
    #ipAdd = null;
    #socket = null;
    #isRBMQ_Connected = false;
    onConnectionLost = null;
    onMessageArrived = null;

    #subOnSuccess = null;
    #subInvocationContext = null;
    #subOnFailure = null;

    #unSubOnSuccess = null;
    #unSubInvocationContext = null;
    #unSubOnFailure = null;

    constructor(ipAdd) {
        this.#ipAdd = ipAdd;
    }

    connect = (option) => {
        const { onSuccess, onFailure } = option;
        let isConnected = true;

        if (this.#socket === null) {
            isConnected = false;
            let protocol = window.location.protocol;
            if (protocol === "https:") {
                this.#socket = io("https://" + this.#ipAdd, { path: "/virtualClass" });
            } else {
                this.#socket = io("http://" + this.#ipAdd + ":8080", { path: "/virtualClass" });
            }

            this.#socket.on("connect", () => {
                console.log("rbmq-connect rcv (1)");
                this.#socket.emit("rbmq-connect");
            });

            this.#socket.on("disconnect", (reason) => {
                console.log("WebClient disconnect => ", reason);
                if (reason === "io client disconnect") {
                    this.onConnectionLost({ errorCode: enRbmqError.OK, errorMessage: reason });
                } else if (reason === "io server disconnect") {
                    this.onConnectionLost({ errorCode: enRbmqError.SOCKET_CLOSE, errorMessage: reason });
                } else if (reason === "ping timeout") {
                    this.onConnectionLost({ errorCode: enRbmqError.PING_TIMEOUT, errorMessage: reason });
                } else if (reason === "transport close") {
                    this.onConnectionLost({ errorCode: enRbmqError.TRANSPORT_CLOSE, errorMessage: reason });
                } else if (reason === "transport error") {
                    this.onConnectionLost({ errorCode: enRbmqError.TRANSPORT_ERROR, errorMessage: reason });
                } else {
                    this.onConnectionLost({ errorCode: enRbmqError.SOCKET_ERROR, errorMessage: reason });
                }
            });

            this.#socket.on("messageArrve", (message) => {
                //const { destinationName } = message;
                //console.log("WebClient-messageArrve-", message);
                this.onMessageArrived(message);
            });

            this.#socket.on("connect_error", (err) => {
                console.log("WebClient connect_error => ", err);
                if (err instanceof Error) {
                    onFailure({ errorCode: enRbmqError.CONNACK_RETURNCODE, errorMessage: err.message });
                } else {
                    onFailure({ errorCode: enRbmqError.SOCKET_ERROR, errorMessage: err });
                }
            });
        }

        this.onConnectionLost = option.onConnectionLost;

        this.#socket.on("rbmq-connect", () => {
            console.log("rbmq-connect rcv (2)");
            this.#isRBMQ_Connected = true;
            onSuccess();
        });

        if (isConnected && !this.#isRBMQ_Connected) {
            this.#socket.emit("rbmq-connect");
        }
    }

    subscribe = (bindKey, subscribeOptions) => {
        // const { invocationContext, onSuccess, onFailure } = subscribeOptions;
        this.#subOnSuccess = subscribeOptions.onSuccess;
        this.#subOnFailure = subscribeOptions.onFailure;
        this.#subInvocationContext = subscribeOptions.invocationContext;
        // console.log("---subscribe---bindKey:", bindKey);
        // console.log("---subscribe---(1)invocationContext:", invocationContext);
        // console.log("---subscribe---(1)subInvocationContext:", this.#subInvocationContext);

        this.#socket.on("subscribe-error", (reason) => {
            this.#subOnFailure({ errorCode: enRbmqError.SUBSCRIBE_TIMEOUT, errorMessage: reason });
        });

        this.#socket.on("subscribe", (option) => {
            console.log("---subscribe---(2)option => ", option, " (2)subInvocationContext => ", this.#subInvocationContext);
            // console.log("---subscribe---(2)option:", option);
            const { context } = option;
            // console.log("---subscribe---(2)subInvocationContext:", this.#subInvocationContext);

            let msg = {};
            if (this.#subInvocationContext !== undefined) {
                msg = { invocationContext: this.#subInvocationContext };
            }

            this.#subOnSuccess(msg);
        });

        console.log(`---subscribe---bindKey[${bindKey}], (1)invocationContext => `, subscribeOptions.invocationContext, " (1)subInvocationContext => ", this.#subInvocationContext);
        this.#socket.emit("subscribe", bindKey, { context: this.#subInvocationContext });
    }

    subscribeArr = (bindKeyArr, subscribeOptions) => {
        // const { invocationContext, onSuccess, onFailure } = subscribeOptions;
        this.#subOnSuccess = subscribeOptions.onSuccess;
        this.#subOnFailure = subscribeOptions.onFailure;
        this.#subInvocationContext = subscribeOptions.invocationContext;
        // console.log("---subscribeArr---bindKey:", bindKey);
        // console.log("---subscribeArr---(1)invocationContext:", invocationContext);
        // console.log("---subscribeArr---(1)subInvocationContext:", this.#subInvocationContext);

        this.#socket.on("subscribeArr-error", (reason) => {
            this.#subOnFailure({ errorCode: enRbmqError.SUBSCRIBE_TIMEOUT, errorMessage: reason });
        });

        this.#socket.on("subscribeArr", (option) => {
            console.log("---subscribeArr---(2)option => ", option, " (2)subInvocationContext => ", this.#subInvocationContext);
            // console.log("---subscribeArr---(2)option:", option);
            const { context } = option;
            // console.log("---subscribeArr---(2)subInvocationContext:", this.#subInvocationContext);

            let msg = {};
            if (this.#subInvocationContext !== undefined) {
                msg = { invocationContext: this.#subInvocationContext };
            }

            this.#subOnSuccess(msg);
        });

        console.log("---subscribeArr---bindKeyArr => ,", bindKeyArr , " (1)invocationContext => ", subscribeOptions.invocationContext, " (1)subInvocationContext => ", this.#subInvocationContext);
        this.#socket.emit("subscribeArr", bindKeyArr, { context: this.#subInvocationContext });
    }

    unsubscribe = (bindKey, unsubsribeOptions) => {
        // const { invocationContext, onSuccess, onFailure } = unsubsribeOptions;
        this.#unSubOnSuccess = unsubsribeOptions.onSuccess;
        this.#unSubOnFailure = unsubsribeOptions.onFailure;
        this.#unSubInvocationContext = unsubsribeOptions.invocationContext;
        // console.log("---unsubscribe---bindKey:", bindKey);
        // console.log("---unsubscribe---(1)invocationContext:", invocationContext);
        // console.log("---unsubscribe---(1)unSubInvocationContext:", this.#unSubInvocationContext);

        this.#socket.on("unsubscribe-error", (reason) => {
            this.#unSubOnFailure({ errorCode: enRbmqError.UNSUBSCRIBE_TIMEOUT, errorMessage: reason });
        });

        this.#socket.on("unsubscribe", (option) => {
            console.log("---unsubscribe---(2)option => ", option, " (2)unSubInvocationContext => ", this.#unSubInvocationContext);
            // console.log("---unsubscribe---(2)option:", option);
            const { context } = option;
            // console.log("---unsubscribe---(2)unSubInvocationContext:", this.#unSubInvocationContext);

            let msg = {};
            if (this.#unSubInvocationContext !== undefined) {
                msg = { invocationContext: context };
            }

            this.#unSubOnSuccess(msg);
        });

        console.log(`---unsubscribe---bindKey[${bindKey}], (1)invocationContext => `, unsubsribeOptions.invocationContext, " (1)unSubInvocationContext => ", this.#unSubInvocationContext);
        this.#socket.emit("unsubscribe", bindKey, { context: this.#unSubInvocationContext });
    }

    unsubscribeArr = (bindKeyArr, unsubsribeOptions) => {
        // const { invocationContext, onSuccess, onFailure } = unsubsribeOptions;
        this.#unSubOnSuccess = unsubsribeOptions.onSuccess;
        this.#unSubOnFailure = unsubsribeOptions.onFailure;
        this.#unSubInvocationContext = unsubsribeOptions.invocationContext;
        // console.log("---unsubscribeArr---bindKey:", bindKey);
        // console.log("---unsubscribeArr---(1)invocationContext:", invocationContext);
        // console.log("---unsubscribeArr---(1)unSubInvocationContext:", this.#unSubInvocationContext);

        this.#socket.on("unsubscribeArr-error", (reason) => {
            this.#unSubOnFailure({ errorCode: enRbmqError.UNSUBSCRIBE_TIMEOUT, errorMessage: reason });
        });

        this.#socket.on("unsubscribeArr", (option) => {
            console.log("---unsubscribeArr---(2)option => ", option, " (2)unSubInvocationContext => ", this.#unSubInvocationContext);
            // console.log("---unsubscribeArr---(2)option:", option);
            const { context } = option;
            // console.log("---unsubscribeArr---(2)unSubInvocationContext:", this.#unSubInvocationContext);

            let msg = {};
            if (this.#unSubInvocationContext !== undefined) {
                msg = { invocationContext: context };
            }

            this.#unSubOnSuccess(msg);
        });

        console.log("---unsubscribeArr---bindKeyArr => ,", bindKeyArr, " (1)invocationContext => ", unsubsribeOptions.invocationContext, " (1)unSubInvocationContext => ", this.#unSubInvocationContext);
        this.#socket.emit("unsubscribeArr", bindKeyArr, { context: this.#unSubInvocationContext });
    }

    send = message => {
        this.#socket.emit("sendMessage", message);
    }

    disconnect = () => {
        this.#socket.on("rbmqDisconnect", () => {
            console.log("---rbmqDisconnect--- after");
            this.#isRBMQ_Connected = false;
            this.#socket.disconnect();
        });

        this.#socket.emit("rbmqDisconnect");
    }
}

export class Message {
    destinationName;
    sendData;

    constructor(routingKey, sendData) {
        this.destinationName = routingKey;
        this.sendData = sendData;
    }
}
import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowDownUpLock } from "react-icons/fa6";

import ConstData from "../../lib/ConstData";

import { getEntryMemberCount } from '../../lib/func/ExportFunction';

const ConfigMyBroadcastLiveList = ({ list_live, handleSelectedConfigFunc }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div className="list-group scroll-show-active light" style={{ height: '100%', overflow: 'auto' }}>
            {
                list_live.length > 0 ?
                    list_live.map(
                        live => (
                            <div className="d-flex list-group-hitem list-group-hitem-action p-2 justify-content-center align-items-center" key={live.liveSeq} title={t("클릭_시_멤버_조회", { ns: "live" })} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_MEMBER_TOGGLE", liveSeq: live.liveSeq })}>
                                <div className="text-area flex-column align-items-start">
                                    <div className="text-area justify-content-start">
                                        <span className="hiclass-text" title={live.liveName}><b>{live.liveName}</b></span>
                                        <span className="hiclass-text hiclasstv-deepDarkgrey-text">&nbsp;{getEntryMemberCount('handsUp', live.list_member)}</span>
                                    </div>
                                    <div className="hiclass-text hiclasstv-darkgrey-text small">
                                        {t("참여자", { ns: "live" })}&nbsp;{live.list_member.length}{t("명", { ns: "common" })}&nbsp;&nbsp;|&nbsp;&nbsp;CODE [<b>{live.liveCode}</b>]
                                    </div>
                                </div>
                                {
                                    ConstData.IS_LOCAL_VERSION ?
                                        <div className="d-flex area-40 ml-2" onClick={handlePreventClick}>
                                            <div className="d-flex flex-column area-30" onClick={handlePreventClick}>
                                                <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-1" title={t("로컬_클래스_설정", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_LOCAL_LIVE", liveSeq: live.liveSeq })}>
                                                    <FaArrowDownUpLock className="d-flex font-md" />
                                                </button>
                                            </div>
                                            <div className="d-flex flex-column area-70 ml-1" onClick={handlePreventClick}>
                                                <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-2" title={t("클래스_정보_수정", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_TOGGLE", liveSeq: live.liveSeq })}>
                                                    {t("수정", { ns: "common" })}
                                                </button>
                                                <button type="button" className="btn btn-sm red white-text m-0 mt-1 py-1 px-2" title={t("클래스_삭제", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "REMOVE_TOGGLE", liveSeq: live.liveSeq })}>
                                                    {t("삭제", { ns: "common" })}
                                                </button>
                                            </div>
                                        </div> :
                                        <div className="d-flex flex-column area-20 ml-2" onClick={handlePreventClick}>
                                            <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-2" title={t("클래스_정보_수정", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_TOGGLE", liveSeq: live.liveSeq })}>
                                                {t("수정", { ns: "live" })}
                                            </button>
                                            <button type="button" className="btn btn-sm red white-text m-0 mt-1 py-1 px-2" title={t("클래스_삭제", { ns: "live" })} style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "REMOVE_TOGGLE", liveSeq: live.liveSeq })}>
                                                {t("삭제", { ns: "common" })}
                                            </button>
                                        </div>
                                }
                                {/* <div className="d-flex area-40 ml-2" onClick={handlePreventClick}>
                                    {
                                        ConstData.IS_LOCAL_VERSION && 
                                        <div className="d-flex flex-column area-30" onClick={handlePreventClick}>
                                            <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-1" title="로컬 클래스 설정" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_LOCAL_LIVE", liveSeq: live.liveSeq })}>
                                                <FaArrowDownUpLock className="d-flex font-md" />
                                            </button>
                                        </div>
                                    }
                                    <div className="d-flex flex-column area-70 ml-1" onClick={handlePreventClick}>
                                        <button type="button" className="btn btn-sm grey white-text m-0 py-1 px-2" title="클래스 정보 수정" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "EDIT_TOGGLE", liveSeq: live.liveSeq })}>
                                            수정
                                        </button>
                                        <button type="button" className="btn btn-sm red white-text m-0 mt-1 py-1 px-2" title="클래스 삭제" style={{ borderRadius: '1rem' }} onClick={() => handleSelectedConfigFunc({ selectedFunc: "REMOVE_TOGGLE", liveSeq: live.liveSeq })}>
                                            삭제
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                        )
                    ) :
                    <div className="view p-2 text-center grey-text">
                        {t("클래스_알림_메시지_4", { ns: "live" })}
                    </div>
            }
        </div>
    );
}

export default ConfigMyBroadcastLiveList;
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';

const LiveMediaAlertModal = ({ kind, mediaDevice_info, isAlertModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isAlertModalOpened} centered backdrop={false}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <div className="d-flex flex-column">
                            <span>
                                {t("클래스_알림_메시지_13", { ns: "live" })}
                            </span>
                            {
                                kind === 'myCam' ? 
                                    <span className="small my-2">{t("클래스_알림_메시지_14", { ns: "live" })}</span> :
                                    <span className="small my-2">{t("클래스_알림_메시지_15", { ns: "live" })}</span>
                            }
                        </div>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-2">[{t("기기이름", { ns: "live" })}]</span>
                        <div className="text-area text-center">
                            <span className="hiclass-text area-70" title={mediaDevice_info.label}>
                                <b>{mediaDevice_info.label}</b>
                            </span>
                        </div>
                    </div>
                    <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "CLOSE_LIVE_MEDIA_ALERT" })}>{t("확인", { ns: "common" })}</button>
                </div>
            </div>
        </Modal>
    );
};

export default LiveMediaAlertModal;
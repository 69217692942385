import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import HiclassTV from "./HiclassTV";
// import jQuery from "jquery";

import "./locales/config";

var hiclasstv = ReactDOM.render(React.createElement(HiclassTV), document.getElementById("root"));
// console.log(hiclasstv);
window.hiclasstv = hiclasstv;
window.sendRawDataWithRoutinKey = hiclasstv.sendRawDataWithRoutinKey;
window.sendedDataFromHiClass = hiclasstv.sendedDataFromHiClass;
window.sendWhisperData = hiclasstv.sendWhisperData;
// window.Kakao.init("dc9610043a925d57af30398314d3c552");
// window.$ = window.jQuery= jQuery;

serviceWorker.unregister();
import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import i18n from "../locales/config";

import ConstData from '../lib/ConstData';

import createRequestThunk from '../lib/createRequestThunk';
import createRequestMultiThunk from '../lib/createRequestMultiThunk';
import createMultiAction from '../lib/createMultiAction';
import tossPhaseAction from '../lib/tossPhaseAction';

import { sendHandsUpOn, sendHandsUpOff, sendP2PSoundOn, sendP2PSoundOff } from '../lib/RabbitmqClient';

import {
    callEoForUpdateHandsUpWindowStatus,
    callEoForUpdateGroupStudyWindowStatus,
    callEoForUpdateHandsUpStatus,
    callEoForUpdateBroadcastStatus,
    callEoForSendAction,
    callEoForSendMediaScreen,
    callEoAddLiveMember,
    callEoForCreateMixedClass,
    callEoForDeleteMixedClass,
    callEoForUpdateMixedClassStatus,
    callEoForSendMixedClassMailLink,
    callEoForSendMessage
} from '../lib/EoBrowserSendUtil';

import { getDefaultDirectKey, getHandsUpBindKey, getHandsUpSendKey } from '../lib/rbmq/rbmqKey';

import {
    stop_live, notify_live_broadcast, notify_live_broadcastWithGuest, join_live, finish_live,
    apply_live, reapply_live, create_live, remove_live, update_live_name, update_live_groupLimit,
    live_on, live_off, change_handsup_active_status, update_live_member, remove_live_member,
    create_code, check_code, certify_code, broadcast_open, request_join_mixed_class_live,
    request_finish_mixed_class_live, notify_logout_to_login_live_members
} from '../lib/api/live';

import { update_mixed_class_exist_yn, get_mixed_class_mail_info, update_mixed_class_seq, update_mixed_class_info_for_live } from '../lib/api/mixedClass';

import { enTokenCMD } from '../lib/classTalk/CodeEnum';
import { addTimer, cancelTimer } from '../lib/TimeOutAlert';

//import { START_ALONE_CHATTING } from './chat';
import { INIT_ALL_CHECK_STATUS, FINISH_HICLASSTV_STATE_INIT } from './hiclasstv';
import { APPEND_ALERT_MESSAGE_WITH_RESTAPI } from './classTalk';
import { TALK_LIVE_STATE_INIT, APPEND_ALERT_LIVE_MESSAGE, FINISH_LIVE_STATE_INIT }  from './classTalkLive';

export const LIVE_BROADCAST_INIT        = "live/LIVE_BROADCAST_INIT";
export const LIVE_WATCHING_INIT         = "live/LIVE_WATCHING_INIT";
export const LIVE_STATE_INIT            = "live/LIVE_STATE_INIT";
export const LIVE_INIT_FOR_END_STUDIO   = "live/LIVE_INIT_FOR_END_STUDIO";
export const LIVE_WATCHING_INIT_FOR_MIXED_CLASS = "live/LIVE_WATCHING_INIT_FOR_MIXED_CLASS";
export const LIVE_WATCHING_INIT_FOR_SMART_TV = "live/LIVE_WATCHING_INIT_FOR_SMART_TV";
export const LIVE_WATCHING_STATE_INIT_FOR_SMART_TV = "live/LIVE_WATCHING_STATE_INIT_FOR_SMART_TV";

// ============================== [ live func ] =============================== //
const CREATE_LIVE                       = "live/CREATE_LIVE";
const CREATE_LIVE_SUCCESS               = "live/CREATE_LIVE_SUCCESS";

const LIVE_CODE_CREATE                  = "live/LIVE_CODE_CREATE";
const LIVE_CODE_CREATE_SUCCESS          = "live/LIVE_CODE_CREATE_SUCCESS";
const LIVE_CODE_CHECK                   = "live/LIVE_CODE_CHECK";
const LIVE_CODE_CHECK_SUCCESS           = "live/LIVE_CODE_CHECK_SUCCESS";
const LIVE_CODE_CERTIFY                 = "live/LIVE_CODE_CERTIFY";
const LIVE_CODE_CERTIFY_SUCCESS         = "live/LIVE_CODE_CERTIFY_SUCCESS";
const CLOSE_LIVE_CODE                   = "live/CLOSE_LIVE_CODE";
const CHANGE_FALSE_CERTIFIED_LIVECODE   = "live/CHANGE_FALSE_CERTIFIED_LIVECODE";
const FALSE_TO_CERTIFIED_LIVECODE       = "live/FALSE_TO_CERTIFIED_LIVECODE";

const UPDATE_LIVE_NAME                  = "live/UPDATE_LIVE_NAME";
const UPDATE_LIVE_NAME_SUCCESS          = "live/UPDATE_LIVE_NAME_SUCCESS";
const UPDATE_LIVE_GROUP_LIMIT           = "live/UPDATE_LIVE_GROUP_LIMIT";
const UPDATE_LIVE_GROUP_LIMIT_SUCCESS   = "live/UPDATE_LIVE_GROUP_LIMIT_SUCCESS";

const REMOVE_LIVE                       = "live/REMOVE_LIVE";
const REMOVE_LIVE_SUCCESS               = "live/REMOVE_LIVE_SUCCESS";

const REMOVE_LIVE_MEMBER                = "live/REMOVE_LIVE_MEMBER";
const REMOVE_LIVE_MEMBER_SUCCESS        = "live/REMOVE_LIVE_MEMBER_SUCCESS";

const UPDATE_LIVE_MEMBER                = "live/UPDATE_LIVE_MEMBER";
const UPDATE_LIVE_MEMBER_SUCCESS        = "live/UPDATE_LIVE_MEMBER_SUCCESS";

const NOTIFY_LIVE_BROADCAST             = "live/NOTIFY_LIVE_BROADCAST";
const NOTIFY_LIVE_BROADCAST_SUCCESS     = "live/NOTIFY_LIVE_BROADCAST_SUCCESS";
const NOTIFY_LIVE_BROADCAST_WITH_GUEST  = "live/NOTIFY_LIVE_BROADCAST_WITH_GUEST";
const NOTIFY_LIVE_BROADCAST_WITH_GUEST_SUCCESS = "live/NOTIFY_LIVE_BROADCAST_WITH_GUEST_SUCCESS";
const NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS = "live/NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS";
const NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS_SUCCESS = "live/NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS_SUCCESS";

const LIVE_ON                           = "live/LIVE_ON";
const LIVE_ON_SUCCESS                   = "live/LIVE_ON_SUCCESS";
const LIVE_OFF                          = "live/LIVE_OFF";
const LIVE_OFF_SUCCESS                  = "live/LIVE_OFF_SUCCESS";

const BROADCAST_ON                      = "live/BROADCAST_ON";
const BROADCAST_ON_SUCCESS              = "live/BROADCAST_ON_SUCCESS";

//const START_LIVE                        = "live/START_LIVE";
//const START_LIVE_SUCCESS                = "live/START_LIVE_SUCCESS";
const STOP_LIVE                         = "live/STOP_LIVE";
const STOP_LIVE_SUCCESS                 = "live/STOP_LIVE_SUCCESS";

const APPLY_LIVE                        = "live/APPLY_LIVE";
const APPLY_LIVE_SUCCESS                = "live/APPLY_LIVE_SUCCESS";

const REAPPLY_LIVE                      = "live/REAPPLY_LIVE";
const REAPPLY_LIVE_SUCCESS              = "live/REAPPLY_LIVE_SUCCESS";

export const JOIN_LIVE                  = "live/JOIN_LIVE";
const JOIN_LIVE_SUCCESS                 = "live/JOIN_LIVE_SUCCESS";
export const JOIN_MY_BROADCAST_LIVE     = "live/JOIN_MY_BROADCAST_LIVE";
const JOIN_MY_BROADCAST_LIVE_SUCCESS    = "live/JOIN_MY_BROADCAST_LIVE_SUCCESS";
const REQUEST_JOIN_MIXED_CLASS_LIVE     = "live/REQUEST_JOIN_MIXED_CLASS_LIVE";
const REQUEST_JOIN_MIXED_CLASS_LIVE_SUCCESS = "live/REQUEST_JOIN_MIXED_CLASS_LIVE_SUCCESS";
const JOIN_MIXED_CLASS_LIVE             = "live/JOIN_MIXED_CLASS_LIVE";

export const FINISH_LIVE                = "live/FINISH_LIVE";
const FINISH_LIVE_SUCCESS               = "live/FINISH_LIVE_SUCCESS";
export const FINISH_MY_BROADCAST_LIVE   = "live/FINISH_MY_BROADCAST_LIVE";
const FINISH_MY_BROADCAST_LIVE_SUCCESS  = "live/FINISH_MY_BROADCAST_LIVE_SUCCESS";
const REQUEST_FINISH_MIXED_CLASS_LIVE   = "live/REQUEST_FINISH_MIXED_CLASS_LIVE";
const REQUEST_FINISH_MIXED_CLASS_LIVE_SUCCESS = "live/REQUEST_FINISH_MIXED_CLASS_LIVE_SUCCESS";
const FINISH_MIXED_CLASS_LIVE           = "live/FINISH_MIXED_CLASS_LIVE";

const CLEAR_PERFORM_LIVE_INFO           = "live/CLEAR_PERFORM_LIVE_INFO";

const CHANGE_LIVE_STATUS_INFO           = "live/CHANGE_LIVE_STATUS_INFO";

export const createLive                 = createRequestThunk(CREATE_LIVE, create_live);
export const removeLive                 = createRequestThunk(REMOVE_LIVE, remove_live);
export const removeLiveMember           = createRequestThunk(REMOVE_LIVE_MEMBER, remove_live_member);
export const updateLiveMember           = createRequestThunk(UPDATE_LIVE_MEMBER, update_live_member);
export const updateLiveName             = createRequestThunk(UPDATE_LIVE_NAME, update_live_name);
export const updateLiveGroupLimit       = createRequestThunk(UPDATE_LIVE_GROUP_LIMIT, update_live_groupLimit);
export const notifyLiveBroadcast        = createRequestThunk(NOTIFY_LIVE_BROADCAST, notify_live_broadcast);
export const notifyLiveBroadcastWithGuest = createRequestThunk(NOTIFY_LIVE_BROADCAST_WITH_GUEST, notify_live_broadcastWithGuest);
export const notifyLogoutToLoginLiveMembers = createRequestThunk(NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS, notify_logout_to_login_live_members);
export const createCode                 = createRequestThunk(LIVE_CODE_CREATE, create_code);
export const checkCode                  = createRequestThunk(LIVE_CODE_CHECK, check_code);
export const certifyCode                = createRequestThunk(LIVE_CODE_CERTIFY, certify_code);
export const cancelCertifyCode          = createAction(CLOSE_LIVE_CODE);
export const changeFalseToCertifiedLiveCode = createAction(CHANGE_FALSE_CERTIFIED_LIVECODE);
export const falseToCertifiedLiveCode   = createAction(FALSE_TO_CERTIFIED_LIVECODE);

export const onAirLive                  = createRequestThunk(LIVE_ON, live_on, [APPEND_ALERT_MESSAGE_WITH_RESTAPI]);
export const offTheAirLive              = createRequestThunk(LIVE_OFF, live_off, [APPEND_ALERT_MESSAGE_WITH_RESTAPI]);

export const onBroadcast                = createRequestThunk(BROADCAST_ON, broadcast_open, [BROADCAST_ON]);

//export const startLive                  = createRequestThunk(START_LIVE, start_live, [START_ALONE_CHATTING, APPEND_ALERT_MESSAGE_WITH_RESTAPI]);
export const stopLive                   = createRequestThunk(STOP_LIVE, stop_live); 

export const applyLive                  = createRequestThunk(APPLY_LIVE, apply_live);
export const reapplyLive                = createRequestThunk(REAPPLY_LIVE, reapply_live);
export const joinLive                   = createRequestMultiThunk(JOIN_LIVE, join_live, "live", [TALK_LIVE_STATE_INIT]);
export const joinMyBroadcastLive        = createRequestMultiThunk(JOIN_MY_BROADCAST_LIVE, join_live, "live", [TALK_LIVE_STATE_INIT]);
export const requestJoinMixedClassLive  = createRequestThunk(REQUEST_JOIN_MIXED_CLASS_LIVE, request_join_mixed_class_live);
export const joinMixedClassLive         = createAction(JOIN_MIXED_CLASS_LIVE);
export const finishLive                 = createRequestThunk(FINISH_LIVE, finish_live, [FINISH_LIVE_STATE_INIT, FINISH_HICLASSTV_STATE_INIT]);
export const finishMyBroadcastLive      = createRequestThunk(FINISH_MY_BROADCAST_LIVE, finish_live, [FINISH_LIVE_STATE_INIT, FINISH_HICLASSTV_STATE_INIT]);
export const requestFinishMixedClassLive = createRequestThunk(REQUEST_FINISH_MIXED_CLASS_LIVE, request_finish_mixed_class_live);
export const finishMixedClassLive       = createAction(FINISH_MIXED_CLASS_LIVE);
export const clearPerformLiveInfo       = createAction(CLEAR_PERFORM_LIVE_INFO);

export const changeLiveStatusInfo       = createAction(CHANGE_LIVE_STATUS_INFO);
// ============================================================================ //

// ============================= [ handsup func ] ============================= //
const HANDS_UP_ON                       = "live/HANDS_UP_ON";
const HANDS_UP_OFF                      = "live/HANDS_UP_OFF";

const HANDS_UP_ACTIVE                   = "live/HANDS_UP_ACTIVE";
const HANDS_UP_ACTIVE_SUCCESS           = "live/HANDS_UP_ACTIVE_SUCCESS";
const HANDS_UP_INACTIVE                 = "live/HANDS_UP_INACTIVE";
const HANDS_UP_INACTIVE_SUCCESS         = "live/HANDS_UP_INACTIVE_SUCCESS";

// const P2P_SOUND_ON                      = "live/P2P_SOUND_ON";
// const P2P_SOUND_OFF                     = "live/P2P_SOUND_OFF";

const SMALL_GROUP_STOP                  = "live/SMALL_GROUP_STOP";

const UPDATE_HANDSUP_MEMBER_STATUS      = "live/UPDATE_HANDSUP_MEMBER_STATUS";

export const activeHandsUp              = createRequestThunk(HANDS_UP_ACTIVE, change_handsup_active_status, [INIT_ALL_CHECK_STATUS]);
export const inactiveHandsUp            = createRequestThunk(HANDS_UP_INACTIVE, change_handsup_active_status);

export const handsUpOn                  = createAction(HANDS_UP_ON);
export const handsUpOff                 = createAction(HANDS_UP_OFF);

// export const p2pSoundOn                 = createAction(P2P_SOUND_ON);
// export const p2pSoundOff                = createAction(P2P_SOUND_OFF);

export const smallGroupStop             = createAction(SMALL_GROUP_STOP);

export const updateHandsupMemberStatus  = createAction(UPDATE_HANDSUP_MEMBER_STATUS);
// ============================================================================ //

// ========================= [ web rcv-media status ] ========================= //
const CHANGE_LIVE_CONNECT               = "live/CHANGE_LIVE_CONNECT";
const CHANGE_LIVE_CONNECT_STATUS        = "live/CHANGE_LIVE_CONNECT_STATUS";
const CHANGE_LIVE_SOUND_STATUS          = "live/CHANGE_LIVE_SOUND_STATUS";
const CHANGE_LIVE_MIC_STATUS            = "live/CHANGE_LIVE_MIC_STATUS";

const CHANGE_HANDS_UP_STATUS_INFO       = "live/CHANGE_HANDS_UP_STATUS_INFO";

export const changeLiveConnect          = createAction(CHANGE_LIVE_CONNECT);
export const changeLiveConnectStatus    = createAction(CHANGE_LIVE_CONNECT_STATUS);
export const changeLiveSoundStatus      = createAction(CHANGE_LIVE_SOUND_STATUS);
export const changeLiveMicStatus        = createAction(CHANGE_LIVE_MIC_STATUS);

export const changeHandsUpStatusInfo    = createAction(CHANGE_HANDS_UP_STATUS_INFO);
// ============================================================================ //

// =============================== [ Remocon ] ================================ //
const REMOCON_HANDSUP_SELECT            = "live/REMOCON_HANDSUP_SELECT";
const REMOCON_HANDSUP_INK_STATUS        = "live/REMOCON_HANDSUP_INK_STATUS";
const REMOCON_HANDSUP_SOUND_STATUS      = "live/REMOCON_HANDSUP_SOUND_STATUS";
const REMOCON_HANDSUP_SCREEN_STATUS     = "live/REMOCON_HANDSUP_SCREEN_STATUS";
const REMOCON_HANDSUP_PULL_SCREEN       = "live/REMOCON_HANDSUP_PULL_SCREEN";
const REMOCON_HANDSUP_PUSH_SCREEN       = "live/REMOCON_HANDSUP_PUSH_SCREEN";

export const remoconHandsupSelect       = createAction(REMOCON_HANDSUP_SELECT);
export const remoconHandsupInkStatus    = createAction(REMOCON_HANDSUP_INK_STATUS);
export const remoconHandsupSoundStatus  = createAction(REMOCON_HANDSUP_SOUND_STATUS);
export const remoconHandsupScreenStatus = createAction(REMOCON_HANDSUP_SCREEN_STATUS);
export const remoconHandsupPullScreen   = createAction(REMOCON_HANDSUP_PULL_SCREEN);
export const remoconHandsupPushScreen   = createAction(REMOCON_HANDSUP_PUSH_SCREEN);
// ============================================================================ //

// ============================= [ toggle func ] ============================== //
const HANDSUP_OPEN_TOGGLE                       = "live/HANDSUP_OPEN_TOGGLE";
const OPEN_GROUP_STUDY_WINDOW                   = "live/OPEN_GROUP_STUDY_WINDOW";
const CLOSE_GROUP_STUDY_WINDOW                  = "live/CLOSE_GROUP_STUDY_WINDOW";

const TOGGLE_READY_LIVE_CONSUME                 = "live/TOGGLE_READY_LIVE_CONSUME";
const TOGGLE_BROADCAST_TIME_NOTIFY              = "live/TOGGLE_BROADCAST_TIME_NOTIFY";
const TOGGLE_NEED_GOTO_HOME                     = "live/TOGGLE_NEED_GOTO_HOME";
const TOGGLE_INVALID_BROADCAST_KEY_MODAL        = "live/TOGGLE_INVALID_BROADCAST_KEY_MODAL";
const TOGGLE_LIVE_GUEST_ALERT_MODAL             = "live/TOGGLE_LIVE_GUEST_ALERT_MODAL";
const TOGGLE_LIVE_MEDIA_ALERT_MODAL             = "live/TOGGLE_LIVE_MEDIA_ALERT_MODAL";
const TOGGLE_LIVE_MIXED_ALERT_MODAL             = "live/TOGGLE_LIVE_MIXED_ALERT_MODAL";
const TOGGLE_LIVE_PULL_SCREEN_ALERT             = "live/TOGGLE_PULL_SCREEN_ALERT";
const TOGGLE_CHATTING_LOCK                      = "live/TOGGLE_CHATTING_LOCK";
const TOGGLE_TOUCH_SCREEN_LOCK                  = "live/TOGGLE_TOUCH_SCREEN_LOCK";

const TOGGLE_MODAL_LIVE_ALERT                   = "live/TOGGLE_MODAL_LIVE_ALERT";
const TOGGLE_SHOW_WATCH_LIVE_MEMBER             = "live/TOGGLE_SHOW_WATCH_LIVE_MEMBER";
const TOGGLE_SEARCH_BROADCAST_LIVE_LIST         = "live/TOGGLE_SEARCH_BROADCAST_LIVE_LIST";
const TOGGLE_SEND_MIXED_CLASS_LINK              = "live/TOGGLE_SEND_MIXED_CLASS_LINK";
const TOGGLE_NOTIFY_LIVE_BROADCAST              = "live/TOGGLE_NOTIFY_LIVE_BROADCAST";
const TOGGLE_CREATE_BROADCAST_LIVE              = "live/TOGGLE_CREATE_BROADCAST_LIVE";
const TOGGLE_SHOW_BROADCAST_LIVE_MEMBER         = "live/TOGGLE_SHOW_BROADCAST_LIVE_MEMBER";
const TOGGLE_CONFIG_BROADCAST_LIVE              = "live/TOGGLE_CONFIG_BROADCAST_LIVE";
const TOGGLE_CONFIG_LOCAL_BROADCAST_LIVE        = "live/TOGGLE_CONFIG_LOCAL_BROADCAST_LIVE";
const TOGGLE_EDIT_BROADCAST_LIVE                = "live/TOGGLE_EDIT_BROADCAST_LIVE";
const TOGGLE_EDIT_BROADCAST_LIVE_MEMBER         = "live/TOGGLE_EDIT_BROADCAST_LIVE_MEMBER";
const TOGGLE_REMOVE_BROADCAST_LIVE              = "live/TOGGLE_REMOVE_BROADCAST_LIVE";
const TOGGLE_REMOVE_BROADCAST_LIVE_MEMBER       = "live/TOGGLE_REMOVE_BROADCAST_LIVE_MEMBER";
const TOGGLE_OFFAIR_BROADCAST_LIVE              = "live/TOGGLE_OFFAIR_BROADCAST_LIVE";

const CLOSE_MODAL_WATCH_LIVE                    = "live/CLOSE_MODAL_WATCH_LIVE";
const CLOSE_MODAL_BROADCAST_LIVE                = "live/CLOSE_MODAL_BROADCAST_LIVE";
const CLOSE_MODAL_CONFIG_BROADCAST_LIVE         = "live/CLOSE_MODAL_CONFIG_BROADCAST_LIVE";
const CLOSE_MODAL_LIVE_ALERT                    = "live/CLOSE_MODAL_LIVE_ALERT";
const CLOSE_MODAL_ERROR_ALERT                   = "live/CLOSE_MODAL_ERROR_ALERT";

export const handsupOpenToggle                  = createAction(HANDSUP_OPEN_TOGGLE);
export const openGroupStudyWindow               = createAction(OPEN_GROUP_STUDY_WINDOW);
export const closeGroupStudyWindow              = createAction(CLOSE_GROUP_STUDY_WINDOW);

export const toggleReadyLiveConsume             = createAction(TOGGLE_READY_LIVE_CONSUME);
export const toggleBroadcastTimeNotify          = createAction(TOGGLE_BROADCAST_TIME_NOTIFY);
export const toggleIsNeedGoToHome               = createAction(TOGGLE_NEED_GOTO_HOME);
export const toggleInvalidBroadcastKeyModal     = createAction(TOGGLE_INVALID_BROADCAST_KEY_MODAL);
export const toggleLiveGuestAlertModal          = createAction(TOGGLE_LIVE_GUEST_ALERT_MODAL);
export const toggleLiveMediaAlertModal          = createAction(TOGGLE_LIVE_MEDIA_ALERT_MODAL);
export const toggleLiveMixedAlertModal          = createAction(TOGGLE_LIVE_MIXED_ALERT_MODAL);
export const toggleLivePullScreenAlert          = createAction(TOGGLE_LIVE_PULL_SCREEN_ALERT);
export const toggleChattingLock                 = createAction(TOGGLE_CHATTING_LOCK);
export const toggleTouchScreenLock              = createAction(TOGGLE_TOUCH_SCREEN_LOCK);

export const toggle_modalLiveAlert              = createAction(TOGGLE_MODAL_LIVE_ALERT);
export const toggle_searchBroadcastLiveList     = createAction(TOGGLE_SEARCH_BROADCAST_LIVE_LIST);
export const toggle_sendMixedClassLink          = createAction(TOGGLE_SEND_MIXED_CLASS_LINK);
export const toggle_createBroadcastLive         = createAction(TOGGLE_CREATE_BROADCAST_LIVE);
export const toggle_notifyLiveBroadcast         = createAction(TOGGLE_NOTIFY_LIVE_BROADCAST);
export const toggle_showWatchLiveMember         = createAction(TOGGLE_SHOW_WATCH_LIVE_MEMBER);
export const toggle_showBroadcastLiveMember     = createAction(TOGGLE_SHOW_BROADCAST_LIVE_MEMBER);
export const toggle_configBroadcastLiveList     = createAction(TOGGLE_CONFIG_BROADCAST_LIVE);
export const toggle_configLocalBroadcastLive    = createAction(TOGGLE_CONFIG_LOCAL_BROADCAST_LIVE);
export const toggle_editBroadcastLive           = createAction(TOGGLE_EDIT_BROADCAST_LIVE);
export const toggle_editBroadcastLiveMember     = createAction(TOGGLE_EDIT_BROADCAST_LIVE_MEMBER);
export const toggle_removeBroadcastLive         = createAction(TOGGLE_REMOVE_BROADCAST_LIVE);
export const toggle_removeBroadcastLiveMember   = createAction(TOGGLE_REMOVE_BROADCAST_LIVE_MEMBER);
export const toggle_offAirBroadcastLive         = createAction(TOGGLE_OFFAIR_BROADCAST_LIVE);

export const closeWatchLiveModal                = createAction(CLOSE_MODAL_WATCH_LIVE);
export const closeModal                         = createAction(CLOSE_MODAL_BROADCAST_LIVE);
export const closeConfigModal                   = createAction(CLOSE_MODAL_CONFIG_BROADCAST_LIVE);
export const closeLiveAlertModal                = createAction(CLOSE_MODAL_LIVE_ALERT);
export const closeErrorAlertModal               = createAction(CLOSE_MODAL_ERROR_ALERT);
// ============================================================================ //

// ============================= [ Mixed Class ] ============================== //
const REQUEST_UPDATE_MIXED_CLASS_EXIST_YN   = "live/REQUEST_UPDATE_MIXED_CLASS_EXIST_YN";
const UPDATE_MIXED_CLASS_EXIST_YN_RESULT    = "live/UPDATE_MIXED_CLASS_EXIST_YN_RESULT";
// const UPDATE_MIXED_CLASS_EXIST_YN           = "live/UPDATE_MIXED_CLASS_EXIST_YN";
// const UPDATE_MIXED_CLASS_EXIST_YN_SUCCESS   = "live/UPDATE_MIXED_CLASS_EXIST_YN_SUCCESS";
const UPDATE_MIXED_CLASS_INFO_FOR_LIVE      = "live/UPDATE_MIXED_CLASS_INFO_FOR_LIVE";
const UPDATE_MIXED_CLASS_INFO_FOR_LIVE_SUCCESS = "live/UPDATE_MIXED_CLASS_INFO_FOR_LIVE_SUCCESS";
const REQUEST_UPDATE_MIXED_CLASS_STATUS     = "live/REQUEST_UPDATE_MIXED_CLASS_STATUS";
const UPDATE_MIXED_CLASS_STATUS_RESULT      = "live/UPDATE_MIXED_CLASS_STATUS_RESULT";
const GET_MIXED_CLASS_MAIL_INFO             = "live/GET_MIXED_CLASS_MAIL_INFO";
const GET_MIXED_CLASS_MAIL_INFO_SUCCESS     = "live/GET_MIXED_CLASS_MAIL_INFO_SUCCESS";
const SEND_MIXED_CLASS_MAIL_RESULT          = "live/SEND_MIXED_CLASS_MAIL_RESULT";
const UPDATE_MIXED_CLASS_MEMBER_LIST        = "live/UPDATE_MIXED_CLASS_MEMBER_LIST";
// const UPDATE_MIXED_CLASS_SEQ                = "live/UPDATE_MIXED_CLASS_SEQ";
// const UPDATE_MIXED_CLASS_SEQ_SUCCESS        = "live/UPDATE_MIXED_CLASS_SEQ_SUCCESS";
export const LIVE_SERVER_USER_AUTH_RESET_RESULT = "live/LIVE_SERVER_USER_AUTH_RESET_RESULT";

export const requestUpdateMixedClassExistYn = createAction(REQUEST_UPDATE_MIXED_CLASS_EXIST_YN);
export const updateMixedClassExistYnResult  = createAction(UPDATE_MIXED_CLASS_EXIST_YN_RESULT);
// export const updateMixedClassExistYn        = createRequestThunk(UPDATE_MIXED_CLASS_EXIST_YN, update_mixed_class_exist_yn);
export const updateMixedClassInfoForLive    = createRequestThunk(UPDATE_MIXED_CLASS_INFO_FOR_LIVE, update_mixed_class_info_for_live);
export const requestUpdateMixedClassStatus  = createAction(REQUEST_UPDATE_MIXED_CLASS_STATUS);
export const updateMixedClassStatusResult   = createAction(UPDATE_MIXED_CLASS_STATUS_RESULT);
export const getMixedClassMailInfo          = createRequestThunk(GET_MIXED_CLASS_MAIL_INFO, get_mixed_class_mail_info);
export const sendMixedClassMailResult       = createAction(SEND_MIXED_CLASS_MAIL_RESULT);
export const updateMixedClassMemberList     = createAction(UPDATE_MIXED_CLASS_MEMBER_LIST);
// export const updateMixedClassSeq            = createRequestThunk(UPDATE_MIXED_CLASS_SEQ, update_mixed_class_seq);
// ============================================================================ //

// ========================== [ Local RelayAction ] =========================== //
const UPDATE_LOCAL_LIVE_PUBLIC_YN                               = "live/UPDATE_LOCAL_LIVE_PUBLIC_YN";

const RCV_RESULTS_FOR_LOCAL_LIVE_ON_FROM_CLOUD                  = "live/RCV_RESULTS_FOR_LOCAL_LIVE_ON_FROM_CLOUD";
const RCV_RESULTS_FOR_LOCAL_LIVE_OFF_FROM_CLOUD                 = "live/RCV_RESULTS_FOR_LOCAL_LIVE_OFF_FROM_CLOUD";
const RCV_RESULTS_FOR_UPDATE_LOCAL_LIVE_PUBLIC_YN_FROM_CLOUD    = "live/RCV_RESULTS_FOR_UPDATE_LOCAL_LIVE_PUBLIC_YN_FROM_CLOUD";

export const updatePublicYnForLocalLive                         = createAction(UPDATE_LOCAL_LIVE_PUBLIC_YN);

export const rcvResultsForLocalLiveOnFromCloud                  = createAction(RCV_RESULTS_FOR_LOCAL_LIVE_ON_FROM_CLOUD);
export const rcvResultsForLocalLiveOffFromCloud                 = createAction(RCV_RESULTS_FOR_LOCAL_LIVE_OFF_FROM_CLOUD);
export const rcvResultsForUpdateLocalLivePublicYnFromCloud      = createAction(RCV_RESULTS_FOR_UPDATE_LOCAL_LIVE_PUBLIC_YN_FROM_CLOUD);
// ============================================================================ //

// ================================= [ etc ] ================================== //
const SEARCH_LIVE_LIST                  = "live/SEARCH_LIVE_LIST";
const FALSE_TO_INIT_TIMER               = "live/FALSE_TO_INIT_TIMER";
const SET_ALERT_MESSAGE                 = "live/SET_ALERT_MESSAGE";

export const searchLiveListResult       = createAction(SEARCH_LIVE_LIST);
export const flaseToInitTimer           = createAction(FALSE_TO_INIT_TIMER);
export const setMessageString           = message => ({ type: SET_ALERT_MESSAGE, message });
// ============================================================================ //

// ============================== [ smart tv ] ================================ //
const REQUEST_CLOSE_MODAL               = "live/REQUEST_CLOSE_MODAL";
const FALSE_TO_REQUEST_CLOSE_MODAL      = "live/FALSE_TO_REQUEST_CLOSE_MODAL";
const UPDATE_SCREEN_SEQ                 = "live/UPDATE_SCREEN_SEQ";
const FALSE_TO_SMART_TV_ALERT_MODAL     = "live/FALSE_TO_SMART_TV_ALERT_MODAL";

export const requestCloseModal          = createAction(REQUEST_CLOSE_MODAL);
export const falseToRequestCloseModal   = createAction(FALSE_TO_REQUEST_CLOSE_MODAL);
export const updateScreenSeq            = createAction(UPDATE_SCREEN_SEQ);
export const falseToSmartTVAlertModal   = createAction(FALSE_TO_SMART_TV_ALERT_MODAL);
// ============================================================================ //

// ============================= [ MQ function ] ============================== //
const MQ_LIVE_JOIN                      = "live/MQ_LIVE_JOIN";
const MQ_LIVE_EXIT                      = "live/MQ_LIVE_EXIT";

const MQ_LIVE_ON                        = "live/MQ_LIVE_ON";
const MQ_LIVE_OFF                       = "live/MQ_LIVE_OFF";
const MQ_BROADCAST_ON                   = "live/MQ_BROADCAST_ON";
const MQ_BROADCAST_OFF                  = "live/MQ_BROADCAST_OFF";

const MQ_LIVE_CREATE                    = "live/MQ_LIVE_CREATE";
const MQ_LIVE_REMOVE                    = "live/MQ_LIVE_REMOVE";
const MQ_LIVE_APPLY                     = "live/MQ_LIVE_APPLY";
const MQ_LIVE_APPROVE                   = "live/MQ_LIVE_APPROVE";
const MQ_LIVE_EDIT_NAME                 = "live/MQ_LIVE_EDIT_NAME";
const MQ_LIVE_UPDATE_BROADCAST_DATE     = "live/MQ_LIVE_UPDATE_BROADCAST_DATE";

const MQ_LIVE_MEMBER_ADD                = "live/MQ_LIVE_MEMBER_ADD";
const MQ_LIVE_MEMBER_APPROVE            = "live/MQ_LIVE_MEMBER_APPROVE";
const MQ_LIVE_MEMBER_REJECT             = "live/MQ_LIVE_MEMBER_REJECT";
const MQ_LIVE_MEMBER_REJECTED           = "live/MQ_LIVE_MEMBER_REJECTED";
const MQ_LIVE_MEMBER_REMOVE             = "live/MQ_LIVE_MEMBER_REMOVE";
const MQ_LIVE_MEMBER_REMOVED            = "live/MQ_LIVE_MEMBER_REMOVED";
const MQ_UPDATE_LIVE_MEMBER_INFO        = "live/MQ_UPDATE_LIVE_MEMBER_INFO";
const MQ_UPDATE_LIVE_MEMBER_NICKNAME    = "live/MQ_UPDATE_LIVE_MEMBER_NICKNAME";

//const MQ_PERFORM_CHATROOM              = "live/MQ_PERFORM_CHATROOM";
const MQ_LIVE_CHAT_MSG                  = "live/MQ_LIVE_CHAT_MSG";

const MQ_HANDS_UP_START                 = "live/MQ_HANDS_UP_START";
const MQ_HANDS_UP_STOP                  = "live/MQ_HANDS_UP_STOP";
const MQ_HANDS_UP_ON                    = "live/MQ_HANDS_UP_ON";
const MQ_HANDS_UP_OFF                   = "live/MQ_HANDS_UP_OFF";
const MQ_HANDS_UP_ACTIVE                = "live/MQ_HANDS_UP_ACTIVE";
const MQ_HANDS_UP_INACTIVE              = "live/MQ_HANDS_UP_INACTIVE";

const MQ_P2P_START                      = "live/MQ_P2P_START";
const MQ_P2P_STOP                       = "live/MQ_P2P_STOP";
const MQ_P2P_SOUND_ON                   = "live/MQ_P2P_SOUND_ON";
const MQ_P2P_SOUND_OFF                  = "live/MQ_P2P_SOUND_OFF";
const MQ_P2P_RESTART                    = "live/MQ_P2P_RESTART";

const MQ_SMALL_GROUP_START              = "live/MQ_SMALL_GROUP_START";
const MQ_SMALL_GROUP_STOP               = "live/MQ_SMALL_GROUP_STOP";

//const MQ_CHANGE_CAMERA_VIEW_MODE        = "live/MQ_CHANGE_CAMERA_VIEW_MODE";
//const MQ_CHANGE_SCREEN_VIEW_MODE        = "live/MQ_CHANGE_SCREEN_VIEW_MODE";

const MQ_LIVE_INFO_INIT                 = "live/MQ_LIVE_INFO_INIT";

const MQ_CHATTING_LOCK                  = "live/MQ_CHATTING_LOCK";
const MQ_TOUCH_SCREEN_LOCK              = "live/MQ_TOUCH_SCREEN_LOCK";

const MQ_RCV_SMART_TV_BIND_INFO         = "live/MQ_RCV_SMART_TV_BIND_INFO";

const MQ_START_MIXED_CLASS              = "live/MQ_START_MIXED_CLASS";
const MQ_STOP_MIXED_CLASS               = "live/MQ_STOP_MIXED_CLASS";

export const mqJoinLive                 = createAction(MQ_LIVE_JOIN);
export const mqExitLive                 = createAction(MQ_LIVE_EXIT);

export const mqLiveOn                   = createAction(MQ_LIVE_ON);
export const mqLiveOff                  = createAction(MQ_LIVE_OFF);

export const mqBroadcastOn              = createAction(MQ_BROADCAST_ON);
export const mqBroadcastOff             = createAction(MQ_BROADCAST_OFF);

export const mqCreateLive               = createAction(MQ_LIVE_CREATE);
export const mqRemoveLive               = createAction(MQ_LIVE_REMOVE);
export const mqApplyLive                = createAction(MQ_LIVE_APPLY);
export const mqApproveLive              = createAction(MQ_LIVE_APPROVE);
export const mqEditLiveName             = createAction(MQ_LIVE_EDIT_NAME);
export const mqUpdateBroadcastDate      = createAction(MQ_LIVE_UPDATE_BROADCAST_DATE);

export const mqAddLiveMember            = createMultiAction(MQ_LIVE_MEMBER_ADD, "live", APPEND_ALERT_LIVE_MESSAGE);
export const mqApproveLiveMember        = createMultiAction(MQ_LIVE_MEMBER_APPROVE, "live", APPEND_ALERT_LIVE_MESSAGE);
export const mqRejectLiveMember         = createAction(MQ_LIVE_MEMBER_REJECT);
export const mqRejectedLiveMember       = createAction(MQ_LIVE_MEMBER_REJECTED);
export const mqRemoveLiveMember         = createMultiAction(MQ_LIVE_MEMBER_REMOVE, "live", APPEND_ALERT_LIVE_MESSAGE);
export const mqRemovedLiveMember        = createAction(MQ_LIVE_MEMBER_REMOVED);
export const mqUpdateLiveMemberInfo     = createAction(MQ_UPDATE_LIVE_MEMBER_INFO);
export const mqUpdateLiveMemberNickname = createAction(MQ_UPDATE_LIVE_MEMBER_NICKNAME);

//export const mqPerfomChatRoom          = createAction(MQ_PERFORM_CHATROOM);
export const mqLiveChatMsg              = createMultiAction(MQ_LIVE_CHAT_MSG, "live");

export const mqHandsUpStart             = createAction(MQ_HANDS_UP_START);
export const mqHandsUpStop              = createAction(MQ_HANDS_UP_STOP);
export const mqHandsUpOn                = createAction(MQ_HANDS_UP_ON);
export const mqHandsUpOff               = createAction(MQ_HANDS_UP_OFF);
export const mqHandsUpActive            = createAction(MQ_HANDS_UP_ACTIVE);
export const mqHandsUpInactive          = createAction(MQ_HANDS_UP_INACTIVE);

export const mqP2PStart                 = createAction(MQ_P2P_START);
export const mqP2PStop                  = createAction(MQ_P2P_STOP);
export const mqP2PSoundOn               = createAction(MQ_P2P_SOUND_ON);
export const mqP2PSoundOff              = createAction(MQ_P2P_SOUND_OFF);
export const mqP2PRestart               = createAction(MQ_P2P_RESTART);

export const mqSmallGroupStart          = createAction(MQ_SMALL_GROUP_START);
export const mqSmallGroupStop           = createAction(MQ_SMALL_GROUP_STOP);

//export const mqChangeCameraView         = createAction(MQ_CHANGE_CAMERA_VIEW_MODE);
//export const mqChangeScreenView         = createAction(MQ_CHANGE_SCREEN_VIEW_MODE);

export const mqLiveInfoInit             = createAction(MQ_LIVE_INFO_INIT);

export const mqChattingLock             = createAction(MQ_CHATTING_LOCK);
export const mqTouchScreenLock          = createAction(MQ_TOUCH_SCREEN_LOCK);

export const mqRcvSmartTVBindInfo       = createAction(MQ_RCV_SMART_TV_BIND_INFO);

export const mqStartMixedClass          = createAction(MQ_START_MIXED_CLASS);
export const mqStopMixedClass           = createAction(MQ_STOP_MIXED_CLASS);
// ============================================================================ //

const initialState = {
    isNeedGoToHome                  : false,
    isInvalidBroadcastKeyModalOpened: false,
    isInvalidRoute                  : false,

    isOnAirLive                     : false,
    isOpenedHandsUp                 : false,
    isOpenedGroupStudy              : false,

    isReadyLiveConsume              : false,

    list_live                       : [],               // 내가 만든 클래스 리스트
    list_watchLive                  : [],               // 내가 볼 수 있는 클래스 리스트
    list_requestedWatchLive         : [],               // 내가 요청한 클래스 리스트
    list_robotLive                  : [],               // 내가 만든 로봇 클래스 리스트

    list_searchLive                 : [],
    
    /** for studio */
    list_studio                     : [],
    connectedStudioInfo             : null,
    /************* */
    
    isLiveMemberModalOpened         : false,

    /** for hiclass */
    /**
     *  openedLiveInfo = {
     *      liveSeq, liveName, list_member
     *  }
     */
    openedLiveInfo                  : {},

    selectedLiveInfo                : {},
    selectedLiveMemberInfo          : {},

    /** for web */
    /**
     *  performLiveInfo = {
     *      liveSeq, liveName, liveStatus, handsUpYN, handsUpSelectYN,
     *      viewKind, list_member, teacher_info : { userSeq, userNickname, userProfile, profileImgUrl, teamUpSeq(기존 roomSeq) },
     *  }
     * 
     *  performLiveStatusInfo = {
     *      isConnect, connectStatus, videoStatus, soundStatus, micStatus
     *  }
     * 
     *  performHandsUpStatusInfo = {
     *      myHandsUpYN, connectStatus, micStatus
     *  }
     */
    performLiveInfo                 : {
        isBroadcastLive             : false,
        isUsablePass                : false,
        liveSeq                     : -1,
        liveName                    : "",
        liveStatus                  : "N",
        handsUpYN                   : "N",
        handsUpSelectYN             : "N",
        //viewKind                    : "CAMERA",
        list_member                 : [],
        mixedClassStatus            : "N",
        teacher_info                : {
            userSeq                 : -1,
            userNickname            : "",
            userProfile             : "",
            profileImgUrl           : "",
            userGateWay             : ""
        }
    },
    performLiveStatusInfo           : {
        isConnect                   : false,
        connectStatus               : "disable",
        videoStatus                 : "disable",
        soundStatus                 : "disable",
        micStatus                   : "disable"
    },
    performHandsUpStatusInfo        : {
        myHandsUpYN                 : "N",
        connectStatus               : "disable",
        micStatus                   : "disable",
    },

    smallGroupInfo                  : {
        status                      : false,
        list_member                 : [],
    },

    joinLiveInfo                    : {},

    isGotoWebLive                   : false,
    
    closedPerformLiveInfo           : {
        liveName                    : "",
        teacher_info                : {
            userSeq                 : -1,
            userNickname            : "",
            userProfile             : "",
            profileImgUrl           : ""
        }
    },

    isShowingBroadcastTimeNotify    : false,
    notifyInfo                      : {},

    web_alertMessage                : "",
    web_alertKind                   : ConstData.ALERT_KIND.NONE,
    web_searchLiveInfo              : {},
    web_createdLiveCode             : "",
    web_isCertifiedLiveCode         : false, // 중복체크용
    web_isCreateLiveSuccess         : false, // 생성체크용

    alertMessage                    : "",
    alertKind                       : ConstData.ALERT_KIND.NONE,
    searchLiveInfo                  : {},
    createdLiveCode                 : "",
    isCertifiedLiveCode             : false,

    searchInfoAlertMessage          : "",
    searchInfoAlertKind             : ConstData.ALERT_KIND.NONE,

    selectedFunc                    : "NONE",
    isSearchLiveListModalOpened     : false,    // ━━━━━━━┓
    isCreateLiveModalOpened         : false,    //        ┃
    isSendMixedClassLinkModalOpened : false,    //        ┃
    isNotifyLiveBroadcastModalOpened: false,    //        ┣━ live list
    isOffAirLiveModalOpened         : false,    //        ┃
    isShowLiveMemberModalOpened     : false,    // ━━━━━━━┛
    isConfigLiveListModalOpened     : false,    // ━━━━━━━┓
    isConfigLocalLiveModalOpened    : false,    //        ┃
    isEditLiveModalOpened           : false,    //        ┃
    isEditLiveMemberModalOpened     : false,    //        ┣━ config live list 
    isRemoveLiveModalOpened         : false,    //        ┃
    isRemoveLiveMemberModalOpened   : false,    // ━━━━━━━┛
    isErrorAlertModalOpened         : false,

    isLiveAlertModalOpened              : false,
    isLiveAlertFinishModalOpened        : false,
    isLiveAlertRemoveModalOpened        : false,
    isLiveAlertModalRemoveMemberOpened  : false,
    isLiveAlertModalMemberLimitOpened   : false,
    isLiveAlertModalExpiredPassOpened   : false,
    isLiveGuestAlertModalOpened         : false,
    isLiveMediaAlertModalOpened         : false,
    isLiveMixedAlertModalOpened         : false,
    isLiveReJoinModalOpened             : false,
    
    isLivePullScreenAlertModalOpened    : false,

    isNeedInitTimer                     : true,

    modalAlertLiveInfo              : {
        liveSeq : -1,
        liveName : ""
    },

    nextPhaseData                   : "",

    myHandsUpBindKeyArr             : [],
    myHandsUpSendKey                : null,

    /** for mixed class */
    isMixedClassMember              : false,
    serverLiveSeq                   : -1,
    serverTeacherSeq                : -1,
    mixedClassInfo                  : {
        isUsablePass                : false,
        mixedClassSeq               : -1,
        serverLiveSeq               : -1,
        serverTeacherSeq            : -1,
        localLiveSeq                : -1,
        localTeacherSeq             : -1,
        localServerSeq              : -1,
    },

    /** for smart tv */
    smartTVLiveInfo                 : {
        liveSeq                     : -1,
        liveName                    : null,
        liveStatus                  : "N",
        teacher_info                : {
            userSeq                 : -1,
            userNickname            : "",
            userProfile             : "",
            profileImgUrl           : ""
        },
        groupLimit                  : 4,
        list_member                 : []
    },
    smartTVBindInfo                 : {
        bindKeyArr                  : [],
        startSeqOfSmartTV           : -1,
        serverGateWay               : ""
    },
    isRequestCloseModal             : false,
    isSmartTVAlertModalOpened       : false,

    // =========== [ for lock ] =========== //
    /****************************************/
    ///////////// PENTALK TEACHER ////////////
    /****************************************/
    isChattingLock                  : false,    // class talk lock for teacher (use in pentalk)
    isTouchScreenLock               : false,    // android tablet lock for teacher (use in pentalk)

    /****************************************/
    /////////////// WEB STUDENT //////////////
    /****************************************/
    isLiveChattingLocked            : false,    // class talk lock for student (use in web browser)
    isTouchScreenLocked             : false,    // android tablet lock for student (use in web browser)
    // ==================================== //

    // ==== [ for Local RelayAction ] ===== //
    livePublicYn                    : "N",      // local live 공개 여부 (default value = "N")
    // ==================================== //
};

const live = handleActions({
    [LIVE_BROADCAST_INIT]: (state, action) => {
        const { isSuccessed } = action.payload;
        
        if (isSuccessed) { // 데이터를 가져온 경우
            const { isLoginSuccess } = action.payload;
            //console.log(action.payload);

            if(isLoginSuccess) {
                const { list_live, user_info } = action.payload;

                let clist_live = [];

                if (list_live !== undefined && list_live !== null) {
                    clist_live = list_live.map(row => ({
                        liveSeq             : row.LIVE_SEQ,
                        liveName            : row.LIVE_NAME,
                        liveStatus          : row.LIVE_STATUS,
                        liveCode            : row.LIVE_CODE,
                        liveGroupStudy      : false,
                        handsUpYN           : row.HANDS_UP_STATUS,
                        selectStatus        : row.HANDS_UP_ACTIVE,
                        userGateWay         : row.gateWay,
                        groupLimit          : row.GROUP_LIMIT_NUM,
                        robotLiveYN         : row.ROBOT_LIVE_YN,
                        mixedClassSeq       : row.MIXED_CLASS_SEQ,
                        mixedClassExistYn   : row.MIXED_EXST_YN,
                        mixedClassStatus    : "N",
                        mixedClassUrl       : row.MIXED_EXST_YN === "Y" ?
                                              ConstData.CLOUD_HOST_ADD + "/mixed_login/" + row.MIXED_CLASS_SEQ : null,
                        livePublicYn        : "N",
                        startSeqOfSmartTV   : 900,
                        binders             : null,
                        isInkSwitch         : true,
                        isVoiceSwitch       : true,
                        isScreenSwitch      : true,
                        isPushSwitch        : true,
                        isPullSwitch        : true,
                        list_member         : row.list_member === undefined || row.list_member === null ?
                                              [] :
                                              row.list_member.map(memRow => ({
                                                  userSeq           : memRow.USER_SEQ,
                                                  userNickname      : memRow.USER_NICKNAME,
                                                  userProfile       : memRow.USER_PROFILE,
                                                  profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                  userJoinStatus    : memRow.ENTRY_YN,
                                                  liveCertiYN       : memRow.LIVE_CERTI_YN,
                                                  isInkON           : true,
                                                  isSoundON         : false,
                                                  isScreenON        : true,
                                                  isSelect          : false,
                                                  isPullScreen      : true,
                                                  isPushScreen      : true,
                                              })),
                        list_waitMember     : row.list_waitMember === undefined || row.list_waitMember === null ?
                                              [] :
                                              row.list_waitMember.map(memRow => ({
                                                  userSeq           : memRow.USER_SEQ, 
                                                  userNickname      : memRow.USER_NICKNAME,
                                                  userProfile       : memRow.USER_PROFILE,
                                                  profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                  userJoinStatus    : memRow.ENTRY_YN,
                                                  liveCertiYN       : memRow.LIVE_CERTI_YN
                                              })),
                        list_broadcast      : row.list_broadcast === undefined || row.list_broadcast === null ?
                                              [] :
                                              row.list_broadcast.map(brdcstRow => ({
                                                  broadcastSeq      : brdcstRow.BRDCST_SEQ,
                                                  broadcastDateTime : { strt: brdcstRow.STRT_DTTM, end: brdcstRow.END_DTTM },
                                                  broadcastOpenYN   : brdcstRow.OPEN_YN,
                                                  list_guest        : brdcstRow.list_guest === undefined || brdcstRow.list_guest === null ?
                                                                      [] :
                                                                      brdcstRow.list_guest.map(memRow => ({
                                                                          userSeq           : memRow.USER_SEQ,
                                                                          userNickname      : memRow.USER_NICKNAME,
                                                                          profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                                          userJoinStatus    : memRow.ENTRY_YN,
                                                                          isInkON           : true,
                                                                          isSoundON         : false,
                                                                          isScreenON        : true,
                                                                          isSelect          : false,
                                                                          isPullScreen      : true,
                                                                          isPushScreen      : true,
                                                                          isGuest           : true
                                                                      }))
                                              }))
                    }));
                }

                const { list_robotLive } = action.payload;
                //console.log('list_robotLive => ', list_robotLive);

                let clist_robotLive = [];

                if (list_robotLive !== undefined && list_robotLive !== null) {
                    clist_robotLive = list_robotLive.map(row => ({
                        liveSeq             : row.LIVE_SEQ,
                        liveName            : row.LIVE_NAME,
                        liveStatus          : row.LIVE_STATUS,
                        liveCode            : row.LIVE_CODE,
                        liveGroupStudy      : false,
                        handsUpYN           : row.HANDS_UP_STATUS,
                        selectStatus        : row.HANDS_UP_ACTIVE,
                        userGateWay         : row.gateWay,
                        groupLimit          : row.GROUP_LIMIT_NUM,
                        robotLiveYN         : row.ROBOT_LIVE_YN,
                        mixedClassSeq       : row.MIXED_CLASS_SEQ,
                        mixedClassExistYn   : row.MIXED_EXST_YN,
                        mixedClassStatus    : "N", 
                        mixedClassUrl       : row.MIXED_EXST_YN === "Y" ?
                                              ConstData.CLOUD_HOST_ADD + "/mixed_login/" + row.MIXED_CLASS_SEQ : null,
                        livePublicYn        : "N",
                        startSeqOfSmartTV   : 900,
                        binders             : null,
                        isInkSwitch         : true,
                        isVoiceSwitch       : true,
                        isScreenSwitch      : true,
                        isPushSwitch        : true,
                        isPullSwitch        : true,
                        list_member         : row.list_member === undefined || row.list_member === null ?
                                              [] :
                                              row.list_member.map(memRow => ({
                                                  userSeq           : memRow.USER_SEQ,
                                                  userNickname      : memRow.USER_NICKNAME,
                                                  userProfile       : memRow.USER_PROFILE,
                                                  profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                  userJoinStatus    : memRow.ENTRY_YN,
                                                  liveCertiYN       : memRow.LIVE_CERTI_YN,
                                                  isInkON           : true,
                                                  isSoundON         : false,
                                                  isScreenON        : true,
                                                  isSelect          : false,
                                                  isPullScreen      : true,
                                                  isPushScreen      : true,
                                              })),
                        list_waitMember     : row.list_waitMember === undefined || row.list_waitMember === null ?
                                              [] :
                                              row.list_waitMember.map(memRow => ({
                                                  userSeq           : memRow.USER_SEQ, 
                                                  userNickname      : memRow.USER_NICKNAME,
                                                  userProfile       : memRow.USER_PROFILE,
                                                  profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                  userJoinStatus    : memRow.ENTRY_YN,
                                                  liveCertiYN       : memRow.LIVE_CERTI_YN
                                              })),
                    }));
                }

                const selectedLive_info = clist_live.find(info => info.selectStatus === "Y");
                if (selectedLive_info !== undefined && selectedLive_info !== null) {
                    callEoForUpdateHandsUpStatus(user_info.USER_SEQ, selectedLive_info.liveSeq, selectedLive_info.list_member, selectedLive_info.selectStatus, selectedLive_info.handsUpYN, selectedLive_info.groupLimit, selectedLive_info.startSeqOfSmartTV, selectedLive_info.binders);
                }

                return {
                    ...state,
                    list_live           : clist_live,
                    list_robotLive      : clist_robotLive,
                    openedLiveInfo      : selectedLive_info === undefined || selectedLive_info === null ? 
                                          {} :
                                          {
                                              liveSeq       : selectedLive_info.liveSeq,
                                              liveName      : selectedLive_info.liveName,
                                              list_member   : selectedLive_info.list_member === undefined || selectedLive_info.list_member === null ? 
                                                              [] :
                                                              selectedLive_info.list_member
                                          }
                }
            } else {
                return {
                    ...state
                }
            }
        } else { // 데이터를 못가져온 경우
            return {
                ...state
            }
        }
    },

    [LIVE_WATCHING_INIT]: (state, action) => {
        const { kind } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('LIVE_WATCHING_INIT - ', action.payload);
        //console.log(action.parms);

        if (isSuccessed) { // 데이터를 가져온 경우
            const { isLoginSuccess } = action.payload;

            if(isLoginSuccess) {
                const { list_watchLive, list_requestedWatchLive } = action.payload;

                let clist_watchLive = [];
                let clist_requestedWatchLive = [];

                if (list_watchLive !== undefined && list_watchLive !== null) {
                    clist_watchLive = list_watchLive.map(row => ({
                        liveSeq             : row.LIVE_SEQ,
                        liveName            : row.LIVE_NAME,
                        liveStatus          : row.LIVE_STATUS,
                        handsUpYN           : row.HANDS_UP_STATUS,
                        handsUpSelectYN     : row.HANDS_UP_ACTIVE,
                        robotLiveYN         : row.ROBOT_LIVE_YN,
                        mixedClassStatus    : "N",
                        list_member         : row.list_member === undefined || row.list_member === null ?
                                              [] :
                                              row.list_member.map(memRow => ({
                                                  userSeq           : memRow.USER_SEQ,
                                                  userNickname      : memRow.USER_NICKNAME,
                                                  userProfile       : memRow.USER_PROFILE,
                                                  profileImgUrl     : memRow.USER_PROFILE_IMG,
                                                  userJoinStatus    : memRow.ENTRY_YN
                                              })),
                        teacher_info        : {
                            userSeq         : row.LIVE_TEACHER_SEQ,
                            userNickname    : row.USER_NICKNAME,
                            userProfile     : row.USER_PROFILE,
                            profileImgUrl   : row.USER_PROFILE_IMG,
                            userGateWay     : row.gateWay,
                        },
                    }));
                }

                if (list_requestedWatchLive !== undefined && list_requestedWatchLive !== null) {
                    clist_requestedWatchLive = list_requestedWatchLive.map(row => ({
                        liveSeq             : row.LIVE_SEQ,
                        liveName            : row.LIVE_NAME,
                        liveStatus          : row.LIVE_STATUS,
                        handsUpYN           : row.HANDS_UP_STATUS,
                        handsUpSelectYN     : row.HANDS_UP_ACTIVE,
                        robotLiveYN         : row.ROBOT_LIVE_YN,
                        liveCertiYN         : row.LIVE_CERTI_YN,
                        mixedClassStatus    : "N",
                        list_member         : [],
                        teacher_info        : {
                            userSeq         : row.LIVE_TEACHER_SEQ,
                            userNickname    : row.USER_NICKNAME,
                            userProfile     : row.USER_PROFILE,
                            profileImgUrl   : row.USER_PROFILE_IMG,
                            userGateWay     : row.gateWay
                        },
                    }));
                }

                let join_live_info = {
                    liveSeq : -1,
                    broadcastDateTime : { strt: "", end: "" }
                };
                
                //const { mixedClassTokenKey } = action.parms;
                //if (mixedClassTokenKey && mixedClassTokenKey !== "") {
                if (state.isMixedClassMember) {
                    const { joinLiveSeq, localLiveSeq } = action.payload;
                    join_live_info = {
                        liveSeq : localLiveSeq
                    };

                    return {
                        ...state,
                        //isMixedClassMember          : true,
                        list_watchLive              : clist_watchLive,
                        list_requestedWatchLive     : clist_requestedWatchLive,
                        joinLiveInfo                : join_live_info,
                        serverLiveSeq               : joinLiveSeq,
                        isLiveGuestAlertModalOpened : kind && kind === "guest" ? true : false,
                        isLiveMixedAlertModalOpened : true,
                    }
                } else {
                    const { broadcastKey } = action.parms;
                    if (broadcastKey && broadcastKey !== "") {
                        const { joinLiveSeq, joinLiveBroadcastTime } = action.payload;
                        join_live_info = {
                            liveSeq : joinLiveSeq,
                            broadcastDateTime : { 
                                strt: joinLiveBroadcastTime, 
                                end: "" 
                            }
                        };
                    }

                    const { guestLoginKey } = action.parms;
                    if (guestLoginKey && guestLoginKey !== "") {
                        const { joinLiveSeq, joinLiveBroadcastTime, broadcastOpenYN } = action.payload;
                        join_live_info = {
                            liveSeq             : joinLiveSeq,
                            visibility          : broadcastOpenYN === "N" ? "visible" : "invisible",
                            broadcastDateTime   : { 
                                strt: joinLiveBroadcastTime, 
                                end: "" 
                            },
                            broadcastOpenYN
                        };
                    }

                    return {
                        ...state,
                        list_watchLive              : clist_watchLive,
                        list_requestedWatchLive     : clist_requestedWatchLive,
                        joinLiveInfo                : join_live_info,
                        isLiveGuestAlertModalOpened : kind && kind === "guest" ? true : false,
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        } else { // 데이터를 못가져온 경우
            return {
                ...state
            }
        }
    },

    [LIVE_STATE_INIT]: (state, action) => {
        const { userSeq, mode } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isLogoutSuccess } = action.payload;

            if (isLogoutSuccess) {
                if (mode && mode === "hiclass") {
                    const list_onAirLive = state.list_live.filter(info => info.liveStatus === "Y");
                    if (list_onAirLive) {
                        const list_openedLive = list_onAirLive.filter(info => info.handsUpYN === "Y");
                        if (list_openedLive) {
                            list_openedLive.forEach(live_info => {
                                if (live_info.list_member) {
                                    callEoForUpdateHandsUpStatus(userSeq, live_info.liveSeq, live_info.list_member, "N", "N", null, live_info.startSeqOfSmartTV, live_info.binders);
                                }
                            });
                        }
                    }
                }

                return {
                    ...state,
                    isNeedGoToHome                  : false,

                    isOnAirLive                     : false,
                    isOpenedGroupStudy              : false,
                    isOpenedHandsUp                 : false,

                    joinLiveInfo                    : {},

                    list_live                       : [],
                    list_watchLive                  : [],
                    list_requestedWatchLive         : [],
                    list_searchLive                 : [],

                    openedLiveInfo                  : {},

                    selectedLiveInfo                : {},
                    selectedLiveMemberInfo          : {},

                    performLiveInfo                 : {
                        isBroadcastLive             : false,
                        isUsablePass                : false,
                        liveSeq                     : -1,
                        liveName                    : "",
                        liveStatus                  : "N",
                        handsUpYN                   : "N",
                        handsUpSelectYN             : "N",
                        list_member                 : [],
                        mixedClassStatus            : "N",
                        teacher_info                : {
                            userSeq                 : -1,
                            userNickname            : "",
                            userProfile             : "",
                            profileImgUrl           : "",
                            userGateWay             : ""
                        }
                    },
                    performLiveStatusInfo           : {
                        isConnect                   : false,
                        connectStatus               : "disable",
                        videoStatus                 : "disable",
                        soundStatus                 : "disable",
                        micStatus                   : "disable"
                    },
                    performHandsUpStatusInfo        : {
                        myHandsUpYN                 : "N",
                        connectStatus               : "disable",
                        micStatus                   : "disable"
                    },

                    smallGroupInfo                  : {
                        status                      : false,
                        list_member                 : [],
                    },

                    isGotoWebLive                   : false,

                    web_alertMessage                : "",
                    web_alertKind                   : ConstData.ALERT_KIND.NONE,
                    web_searchLiveInfo              : {},
                    web_createdLiveCode             : "",
                    web_isCertifiedLiveCode         : false,

                    alertMessage                    : "",
                    alertKind                       : ConstData.ALERT_KIND.NONE,
                    searchLiveInfo                  : {},
                    createdLiveCode                 : "",
                    isCertifiedLiveCode             : false,

                    searchInfoAlertMessage          : "",
                    searchInfoAlertKind             : ConstData.ALERT_KIND.NONE,

                    selectedFunc                    : "NONE",
                    isSearchLiveListModalOpened     : false,    // ━━━━━━━┓
                    isCreateLiveModalOpened         : false,    //        ┃
                    isSendMixedClassLinkModalOpened : false,    //        ┃
                    isNotifyLiveBroadcastModalOpened: false,    //        ┣━ live list
                    isOffAirLiveModalOpened         : false,    //        ┃
                    isShowLiveMemberModalOpened     : false,    // ━━━━━━━┛
                    isConfigLiveListModalOpened     : false,    // ━━━━━━━┓
                    isConfigLocalLiveModalOpened    : false,    //        ┃
                    isEditLiveModalOpened           : false,    //        ┃
                    isEditLiveMemberModalOpened     : false,    //        ┣━ config live list 
                    isRemoveLiveModalOpened         : false,    //        ┃
                    isRemoveLiveMemberModalOpened   : false,    // ━━━━━━━┛
                    isErrorAlertModalOpened         : false,

                    isLiveAlertModalOpened              : false,
                    isLiveAlertFinishModalOpened        : false,
                    isLiveAlertRemoveModalOpened        : false,
                    isLiveAlertModalRemoveMemberOpened  : false,
                    isLiveAlertModalMemberLimitOpened   : false,
                    isLiveAlertModalExpiredPassOpened   : false,
                    isLiveGuestAlertModalOpened         : false,
                    isLiveMediaAlertModalOpened         : false,
                    isLiveMixedAlertModalOpened         : false,
                    isLiveReJoinModalOpened             : false,

                    nextPhaseData                   : "",

                    myHandsUpBindKeyArr             : [],
                    myHandsUpSendKey                : null,

                    isMixedClassMember              : false,
                    serverLiveSeq                   : -1,
                    serverTeacherSeq                : -1,
                    mixedClassInfo                  : {
                        isUsablePass                : false,
                        mixedClassSeq               : -1,
                        serverLiveSeq               : -1,
                        serverTeacherSeq            : -1,
                        localLiveSeq                : -1,
                        localTeacherSeq             : -1,
                        localServerSeq              : -1
                    },

                    smartTVLiveInfo                 : {
                        liveSeq                     : -1,
                        liveName                    : null,
                        liveStatus                  : "N",
                        teacher_info                : {
                            userSeq                 : -1,
                            userNickname            : "",
                            userProfile             : "",
                            profileImgUrl           : ""
                        },
                        groupLimit                  : 4,
                        list_member                 : []
                    },
                    smartTVBindInfo                 : {
                        bindKeyArr                  : [],
                        startSeqOfSmartTV           : -1,
                        serverGateWay               : ""
                    },
                    isRequestCloseModal             : false,
                    isSmartTVAlertModalOpened       : false,

                    isChattingLock                  : false,
                    isTouchScreenLock        		: false,
                    isLiveChattingLocked            : false,
                    isTouchScreenLocked      		: false,
                }
            } else {
                return {
                    ...state,
                }
            }
        } else {
            return {
                ...state,
            }
        }
    },

    [LIVE_INIT_FOR_END_STUDIO]: (state, action) => {
        const { list_live } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log('LIVE_INIT_FOR_END_STUDIO');

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce(state, draft => {
                    list_live.forEach(liveSeq => {
                        //console.log('liveSeq - ', liveSeq);
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            if (live_info.list_member) {
                                live_info.list_member.forEach(member => {
                                    member.userJoinStatus = "N";
                                });
                            }
                            live_info.liveStatus = "N";
                            live_info.liveGroupStudy = "N";
                            live_info.handsUpYN = "N";
                            live_info.selectStatus = "N";

                            let liveName = live_info.liveName;
                            if (liveName.length > 8) {
                                liveName = liveName.substring(0, 6) + "...";
                            }
                            draft.nextPhaseData = i18n.t("클래스", { ns: "live" }) + " [" + liveName + "] OFF";
                        }

                        const searchLive_info = draft.list_searchLive.find(info => info.liveSeq === liveSeq);
                        if (searchLive_info) {
                            if (searchLive_info.list_member) {
                                searchLive_info.list_member.forEach(member => {
                                    member.userJoinStatus = "N";
                                });
                            }

                            searchLive_info.liveStatus       = "N";
                            searchLive_info.liveGroupStudy   = "N";
                            searchLive_info.handsUpYN        = "N";
                            searchLive_info.selectStatus     = "N";  //searchLive_info.liveHandsUpActive = "N";
                        }

                        if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                            if (draft.selectedLiveInfo.list_member) {
                                draft.selectedLiveInfo.list_member.forEach(member => {
                                    member.userJoinStatus = "N";
                                });
                            }
                        }
                    });

                    draft.isOnAirLive = false;
                    draft.isOffAirLiveModalOpened = false;
                });
            } else {
                //console.log('LIVE_INIT_FOR_END_STUDIO - isUpdateSuccess is ', isUpdateSuccess);
                return {
                    ...state
                }
            }
        } else {
            //console.log('IVE_INIT_FOR_END_STUDIO - isSuccessed is ', isSuccessed);
            return {
                ...state
            }
        }
    },

    [LIVE_WATCHING_INIT_FOR_MIXED_CLASS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log('LIVE_WATCHING_INIT_FOR_MIXED_CLASS - ', action.payload);

        if (isSuccessed) {
            const { isAuthSuccess } = action.payload;

            if (isAuthSuccess) {
                const { mixedClass_info, isUsablePass } = action.payload;

                return {
                    ...state,
                    isMixedClassMember      : true,
                    // isUsablePass            : isUsablePass,
                    mixedClassInfo          : {
                        isUsablePass        : isUsablePass,
                        mixedClassSeq       : mixedClass_info.mixedClassSeq,
                        serverLiveSeq       : mixedClass_info.serverLiveSeq,
                        serverTeacherSeq    : mixedClass_info.serverTeacherUserSeq,
                        localLiveSeq        : mixedClass_info.localLiveSeq,
                        localTeacherSeq     : mixedClass_info.localTeacherUserSeq,
                        localServerSeq      : mixedClass_info.localServerSeq
                    }
                }
            } else {
                return {
                    ...state,
                    isAuthSuccess       : false,
                    isInvalidRoute      : true
                }
            }
        } else {
            return {
                ...state,
                isAuthSuccess       : false,
                isInvalidRoute      : true
            }
        }
    },

    [CLEAR_PERFORM_LIVE_INFO]: (state, action) => {
        //console.log("CLEAR_PERFORM_LIVE_INFO");
        
        return {
            ...state,
            //joinLiveInfo                    : {},
            performLiveInfo                 : {
                isBroadcastLive             : false,
                isUsablePass                : false,
                liveSeq                     : -1,
                liveName                    : "",
                liveStatus                  : "N",
                handsUpYN                   : "N",
                handsUpSelectYN             : "N",
                list_member                 : [],
                mixedClassStatus            : "N",
                teacher_info                : {
                    userSeq                 : -1,
                    userNickname            : "",
                    userProfile             : "",
                    profileImgUrl           : "",
                    userGateWay             : ""
                }
            },
            /* performLiveStatusInfo           : {
                isConnect                   : false,
                connectStatus               : "disable",
                videoStatus                 : "disable",
                soundStatus                 : "disable",
                micStatus                   : "disable"
            },
            performHandsUpStatusInfo        : {
                myHandsUpYN                 : "N",
                connectStatus               : "disable",
                micStatus                   : "disable"
            }, */
            isGotoWebLive                   : false,

            isChattingLock                  : false,
            isTouchScreenLock        		: false,
            isLiveChattingLocked            : false,
            isTouchScreenLocked      		: false,
        }
    },

    [SEARCH_LIVE_LIST]: (state, action) => {
        const { result } = action.payload;

        return {
            ...state,
            list_searchLive : result
        }
    },

    [CREATE_LIVE_SUCCESS]: (state, action) => {
        const { mode, liveName, liveCode, robotLiveYN } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("CREATE_LIVE_SUCCESS");

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist === true) { // 중복된 코드! 클래스 생성 실패
                if(mode === "hiclass") {
                    return  {
                        ...state,
                        alertMessage            : i18n.t("클래스_코드_사용_불가능", { ns: "live" }),
                        alertKind               : ConstData.ALERT_KIND.DANGER
                    }    
                } else {
                    return {
                        ...state,
                        web_alertMessage        : i18n.t("클래스_코드_사용_불가능", { ns: "live" }),
                        web_alertKind           : ConstData.ALERT_KIND.DANGER,
                        web_isCertifiedLiveCode : false
                    }
                }
            } else {
                const { isCreateSuccess } = action.payload;

                if (isCreateSuccess) {
                    const { liveSEQ } = action.payload;

                    if (liveSEQ >= 0) {
                        const live_info = {
                            liveSeq         : liveSEQ,
                            liveName        : liveName,
                            liveStatus      : "N",
                            liveCode        : liveCode,
                            liveGroupStudy  : "N",
                            handsUpYN       : "N",
                            selectStatus    : "N",      // isSelected      : "N",
                            isInkSwitch     : true,
                            isVoiceSwitch   : true,
                            isScreenSwitch  : true,
                            isPushSwitch    : true,
                            isPullSwitch    : true,
                            list_member     : [],
                            list_waitMember : [],
                            list_broadcast  : []
                        };

                        if(mode === "hiclass") {
                            return {
                                ...state,
                                selectedFunc            : "NONE",
                                list_live               : state.list_live.concat(live_info),
                                alertMessage            : "",
                                alertKind               : ConstData.ALERT_KIND.NONE,
                                createdLiveCode         : "",
                                isCertifiedLiveCode     : false,
                                isCreateLiveModalOpened : false
                            }
                        } else {
                            if (robotLiveYN === "Y") {
                                return {
                                    ...state,
                                    list_robotLive          : state.list_robotLive.concat(live_info),
                                    web_alertMessage        : i18n.t("클래스_생성_성공", { ns: "live" }),
                                    web_alertKind           : ConstData.ALERT_KIND.SUCCESS,
                                    web_createdLiveCode     : "",
                                    web_isCertifiedLiveCode : false,
                                    web_isCreateLiveSuccess : true,
                                }
                            } else {
                                return {
                                    ...state,
                                    list_live               : state.list_live.concat(live_info),
                                    web_alertMessage        : i18n.t("클래스_생성_성공", { ns: "live" }),
                                    web_alertKind           : ConstData.ALERT_KIND.SUCCESS,
                                    web_createdLiveCode     : "",
                                    web_isCertifiedLiveCode : false,
                                    web_isCreateLiveSuccess : true,
                                }
                            }
                        }
                    } else {
                        //console.log('CREATE_LIVE - liveSEQ is ', liveSEQ);
                        return {
                            ...state,
                        }
                    }
                } else {
                    if(mode === "hiclass") {
                        return {
                            ...state,
                            alertKind               : ConstData.ALERT_KIND.DANGER,
                            alertMessage            : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV141.8]",
                            isCertifiedLiveCode     : false,
                            isCreateLiveModalOpened : false
                        }
                    } else {
                        return {
                            ...state,
                            web_alertKind           : ConstData.ALERT_KIND.DANGER,
                            web_alertMessage        : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV142.6]",
                            web_isCertifiedLiveCode : false,
                        }
                    }
                }
            }
        } else {
            if (mode === "hiclass") {
                return {
                    ...state,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("클래스_생성_요청_실패", { ns: "live" }) + " [Code.LV143.7]",
                    isCertifiedLiveCode : false,
                    isCreateLiveModalOpened : false
                }
            } else {
                return {
                    ...state,
                    web_alertKind           : ConstData.ALERT_KIND.DANGER,
                    web_alertMessage        : i18n.t("클래스_생성_요청_실패", { ns: "live" }) + " [Code.LV144.5]",
                    web_isCertifiedLiveCode : false,
                }
            }
        }
    },

    [UPDATE_LIVE_NAME_SUCCESS]: (state, action) => {
        // const { liveSeq, liveName, userSeq, data } = action.parms;
        const { liveSeq, liveName } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("UPDATE_LIVE_NAME_SUCCESS - ", action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                /* if (data !== undefined && data !== null) { // local - mixed class live
                    const { status, serverUserSeq } = data;

                    if (status) {
                        callEoForCreateMixedClass(serverUserSeq, userSeq, liveSeq);
                    } else {
                        callEoForDeleteMixedClass(userSeq, liveSeq);
                    }

                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);

                        if (live_info !== undefined && live_info !== null) {
                            live_info.liveName = liveName;
                        }

                        draft.selectedLiveInfo.liveName = live_info.liveName;
                        //draft.isEditLiveModalOpened = false;
                    });
                } else {                                        // real
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info !== undefined && live_info !== null) {
                            live_info.liveName = liveName;
                        }
                        draft.selectedLiveInfo.liveName = live_info.liveName;
                        draft.isEditLiveModalOpened = false;
                    });
                } */

                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info !== undefined && live_info !== null) {
                        live_info.liveName = liveName;
                    }
                    draft.selectedLiveInfo.liveName = live_info.liveName;
                    draft.isEditLiveModalOpened = false;
                });
            } else {
                //console.log('UPDATE_LIVE_NAME - isUpdateSuccess is ', isUpdateSuccess);
                return {
                    ...state
                }
            }
        } else {
            //console.log('UPDATE_LIVE_NAME - isSuccessed is ', isSuccessed);
            return {
                ...state
            }
        }
    },

    [UPDATE_LIVE_GROUP_LIMIT_SUCCESS]: (state, action) => {
        const { liveSeqArr, groupLimit } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("UPDATE_LIVE_GROUP_LIMIT_SUCCESS : ", liveSeqArr, groupLimit);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce(state, draft => {
                    liveSeqArr.forEach(seq => {
                        const live_info = draft.list_live.find(info => info.liveSeq === seq);
                        if (live_info) {
                            live_info.groupLimit = groupLimit;
                        }
                    })
                });
            } else {
                //console.log('UPDATE_LIVE_GROUP_LIMIT - isUpdateSuccess is ', isUpdateSuccess);
                return {
                    ...state,
                }
            }
        } else {
            //console.log('UPDATE_LIVE_GROUP_LIMIT - isSuccessed is ', isSuccessed);
            return {
                ...state
            }
        }
    },

    [NOTIFY_LIVE_BROADCAST_SUCCESS]: (state, action) => {
        //console.log("NOTIFY_LIVE_BROADCAST_SUCCESS - ", action.payload);
        const { liveSeq, data } = action.parms;
        const { startDate, endDate } = data;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isBroadcastExist } = action.payload;

            if (isBroadcastExist) {
                //console.log('NOTIFY_LIVE_BROADCAST_SUCCESS - isBroadcastExist is ', isBroadcastExist);
                let alert_msg = i18n.t("클래스_시간_설정_중복", { ns: "live" });

                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : alert_msg,
                }
            } else {
                const { isCreateSuccess } = action.payload;

                if (isCreateSuccess) { //broadcast 정보 생성 성공
                    const { broadcastSEQ, isNotifySuccess } = action.payload;

                    if (isNotifySuccess) {
                        console.log('notify for member -- isNotifySuccess is ', isNotifySuccess);
                        let broadcast_info = {
                            broadcastSeq      : broadcastSEQ,
                            broadcastDateTime : { strt: startDate, end: endDate },
                            broadcastOpenYN   : "N",
                            list_guest        : []
                        };

                        addTimer(liveSeq, broadcast_info);

                        return produce(state, draft => {
                            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                            if (live_info) {
                                if (live_info.list_broadcast) {
                                    live_info.list_broadcast.push(broadcast_info);
                                }
                            }

                            draft.selectedFunc = 'NONE';
                            draft.alertKind = ConstData.ALERT_KIND.NONE;
                            draft.alertMessage = "";
                            draft.isNotifyLiveBroadcastModalOpened = false;
                        });
                    } else {
                        let alert_msg = i18n.t("클래스_방송_알림_오류", { ns: "live" });

                        return {
                            ...state,
                            alertKind       : ConstData.ALERT_KIND.DANGER,
                            alertMessage    : alert_msg,
                        }
                    }
                } else {
                    let alert_msg = i18n.t("클래스_방송_알림_실패", { ns: "live" });

                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : alert_msg,
                    }
                }
            }
        } else {
            let alert_msg = i18n.t("클래스_방송_알림_요청_실패", { ns: "live" });

            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : alert_msg,
            }
        }
    },

    [NOTIFY_LIVE_BROADCAST_WITH_GUEST_SUCCESS]: (state, action) => {
        //console.log("NOTIFY_LIVE_BROADCAST_WITH_GUEST_SUCCESS - ", action.payload);
        const { liveSeq, data } = action.parms;
        const { startDate, endDate } = data;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isBroadcastExist } = action.payload;

            if (isBroadcastExist) {
                //console.log('NOTIFY_LIVE_BROADCAST_WITH_GUEST_SUCCESS - isBroadcastExist is ', isBroadcastExist);
                let alert_msg = i18n.t("클래스_시간_설정_중복", { ns: "live" });

                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : alert_msg,
                }
            } else {
                const { isCreateSuccess } = action.payload;

                if (isCreateSuccess) { //broadcast 정보 생성 성공
                    const { broadcastSEQ, list_guest, isInviteSuccess } = action.payload;

                    if (isInviteSuccess) {
                        const { isNotifySuccess } = action.payload;

                        if (isNotifySuccess) {
                            let broadcast_info = {
                                broadcastSeq      : broadcastSEQ,
                                broadcastDateTime : { strt: startDate, end: endDate },
                                broadcastOpenYN   : "N",
                                list_guest        : list_guest.map(memRow => ({
                                    userSeq         : memRow.USER_SEQ,
                                    userNickname    : memRow.USER_NICKNAME,
                                    profileImgUrl   : memRow.USER_PROFILE_IMG,
                                    userJoinStatus  : memRow.ENTRY_YN,
                                    isInkON         : true,
                                    isSoundON       : false,
                                    isScreenON      : true,
                                    isSelect        : false,
                                    isPullScreen    : true,
                                    isPushScreen    : true
                                }))
                            };

                            const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                            if (live_info) {
                                if (live_info.liveStatus === "Y") {
                                    addTimer(liveSeq, broadcast_info);
                                }
                            }

                            return produce(state, draft => {
                                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                                if (live_info) {
                                    if (live_info.list_broadcast) {
                                        live_info.list_broadcast.push(broadcast_info);
                                    }
                                }

                                draft.selectedFunc = 'NONE';
                                draft.alertKind = ConstData.ALERT_KIND.NONE;
                                draft.alertMessage = "";
                                draft.isNotifyLiveBroadcastModalOpened = false;
                            });
                        } else {
                            let alert_msg = i18n.t("클래스_방송_알림_오류", { ns: "live" });

                            return {
                                ...state,
                                alertKind       : ConstData.ALERT_KIND.DANGER,
                                alertMessage    : alert_msg,
                            }
                        }
                    } else {
                        let alert_msg = i18n.t("클래스_초대_게스트_초과", { ns: "live" });

                        return {
                            ...state,
                            alertKind       : ConstData.ALERT_KIND.DANGER,
                            alertMessage    : alert_msg,
                        }
                    }
                } else {
                    let alert_msg = i18n.t("클래스_방송_알림_실패", { ns: "live" });

                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : alert_msg,
                    }
                }
            }
        } else {
            let alert_msg = i18n.t("클래스_방송_알림_요청_실패", { ns: "live" });

            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : alert_msg,
            }
        }
    },

    [NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("NOTIFY_LOGOUT_TO_LOGIN_LIVE_MEMBERS_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { results } = action.payload;

            if (results) {
                const list_results = results.list_results;

                if (results.list_results && results.list_results.length > 0) {
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            if (live_info.list_member) {
                                /* const matchingUsers = list_results.filter(resultsMember =>
                                    live_info.list_member.some(member => member.userSeq === resultsMember.userSeq)
                                ); */
                                /* const matchingUsers = live_info.list_member.filter(member => 
                                    list_results.some(resultsMember => resultsMember.userSeq === member.userSeq)
                                ); */
                                /* const allUsers = live_info.list_member.every(member => 
                                    list_results.some(resultsMember => resultsMember.userSeq === member.userSeq)
                                ); */

                                // console.log("matchingUsers => ", matchingUsers);
                                // console.log("allUsers => ", allUsers);

                                /* if (matchingUsers) {
                                    matchingUsers.forEach(member => {
                                        console.log("member.userJoinStatus = 'N'!!");
                                        member.userJoinStatus = "N";
                                    });
                                } */

                                /* if (allUsers) {
                                    allUsers.forEach(member => {
                                        console.log("member.userJoinStatus = 'N'!!");
                                        member.userJoinStatus = "N";
                                    });
                                } */

                                const new_list_member = live_info.list_member.map(member => ({
                                    ...member,
                                    userJoinStatus: member.userJoinStatus === "N" ? member.userJoinStatus :
                                                    list_results.some(resultsMember => resultsMember.userSeq === member.userSeq) ? 
                                                    "N" : "Y"
                                }));

                                console.log("new_list_member => ", new_list_member);

                                live_info.list_member = new_list_member;

                                callEoForUpdateHandsUpStatus(userSeq, live_info.liveSeq, live_info.list_member, "Y", live_info.handsUpYN, null, live_info.startSeqOfSmartTV, live_info.binders);
                            }
                        }
                    });
                } else {
                    console.log("results.list_results => ", results.list_results);
                let alert_msg = i18n.t("클래스_멤버_로그아웃_실패", { ns: "live" });

                    return {
                        ...state,
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        alertMessage            : alert_msg,
                        isLiveAlertModalOpened  : true,
                    }
                }
            } else {
                console.log("results => ", results);
                let alert_msg = i18n.t("클래스_멤버_로그아웃_실패", { ns: "live" });

                return {
                    ...state,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : alert_msg,
                    isLiveAlertModalOpened  : true,
                }
            }
        } else {
            let alert_msg = i18n.t("클래스_멤버_로그아웃_요청_실패", { ns: "live" });

            return {
                ...state,
                alertKind               : ConstData.ALERT_KIND.DANGER,
                alertMessage            : alert_msg,
                isLiveAlertModalOpened  : true,
            }
        }
    },

    /* [START_LIVE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("START_LIVE_SUCCESS");

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return {
                    ...state,
                }
            } else {
                //console.log("isUpdateSuccess is ... ", isUpdateSuccess);
                return {
                    ...state
                }
            }
        } else {
            //console.log("isSuccessed is ", isSuccessed);
            return {
                ...state
            }
        }
    }, */

    [STOP_LIVE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        //console.log("STOP_LIVE_SUCCESS");

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce(state, draft => {
                    draft.isOnAirLive = false;
                    draft.list_live.forEach(live_info => {
                        live_info.liveStatus = "N";
                        live_info.liveGroupStudy = "N";
                        live_info.handsUpYN = "N";
                        live_info.selectStatus = "N";
                        live_info.startSeqOfSmartTV = 900;  // 라이브 종료시 count 초기화
                        live_info.binders = null;
                    });
                });
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [LIVE_ON_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq, groupLimit } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("LIVE_ON_SUCCESS isSuccessed - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                callEoForSendMediaScreen("updateLiveStatus", { userSeq, liveSeq, status:true, groupLimit });

                if (ConstData.IS_LOCAL_VERSION) {
                    const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        console.log("liveName is => ", live_info.liveName);
                        callEoForSendMediaScreen("relayAction", { action: "liveStart", localLiveSEQ: liveSeq, localUserSEQ: userSeq, liveName: live_info.liveName });
                    }
                }

                const { isHandsUpUpdateSuccess, isExist, activeLiveSeq, list_member } = action.payload;

                if (isHandsUpUpdateSuccess) {
                    if (isExist) { // 기존 active 클래스가 존재하는 경우 -> inactive 로 변경 알림
                        const live_info = state.list_live.find(info => info.liveSeq === activeLiveSeq);
                        if (live_info) {
                            if (live_info.list_member) {
                                callEoForUpdateHandsUpStatus(userSeq, activeLiveSeq, live_info.list_member, "N", live_info.handsUpYN, null, live_info.startSeqOfSmartTV, live_info.binders);
                            }
                        }
                    }

                    const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            callEoForUpdateHandsUpStatus(userSeq, liveSeq, live_info.list_member, "Y", "Y", (groupLimit === undefined ? null : groupLimit), live_info.startSeqOfSmartTV, live_info.binders); 
                        }
                    }
                }

                return produce(state, draft => {
                    if (isHandsUpUpdateSuccess && isExist && activeLiveSeq >= 0) { // 기존 active 클래스가 존재하는 경우 -> inactive 로 변경
                        const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                        if (live_info) {
                            live_info.selectStatus = "N";
                        }
                    }

                    if (list_member) {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            live_info.liveStatus = "Y";

                            if (isHandsUpUpdateSuccess) {
                                live_info.selectStatus = "Y";
                                live_info.handsUpYN = "Y";
                            }

                            live_info.groupLimit = groupLimit !== undefined && groupLimit !== null ? groupLimit : live_info.groupLimit;
                            live_info.list_member = list_member.map(memRow => ({
                                userSeq           : memRow.USER_SEQ,
                                userNickname      : memRow.USER_NICKNAME,
                                userProfile       : memRow.USER_PROFILE,
                                profileImgUrl     : memRow.USER_PROFILE_IMG,
                                userJoinStatus    : memRow.ENTRY_YN,
                                liveCertiYN       : memRow.LIVE_CERTI_YN,
                                isInkON           : true,
                                isSoundON         : true, //isSoundON         : false,
                                isScreenON        : true,
                                isSelect          : true, //isSelect          : false,
                                isPullScreen      : true,
                                isPushScreen      : true,
                            }));
                        }

                        const searchLive_info = draft.list_searchLive.find(info => info.liveSeq === liveSeq);
                        if(searchLive_info) {
                            searchLive_info.liveStatus = "Y";

                            if (isHandsUpUpdateSuccess) {
                                searchLive_info.selectStatus = "Y";
                                searchLive_info.handsUpYN = "Y";
                            }

                            searchLive_info.groupLimit = groupLimit !== undefined && groupLimit !== null ? groupLimit : searchLive_info.groupLimit;
                            searchLive_info.list_member = list_member.map(memRow => ({
                                userSeq           : memRow.USER_SEQ,
                                userNickname      : memRow.USER_NICKNAME,
                                userProfile       : memRow.USER_PROFILE,
                                profileImgUrl     : memRow.USER_PROFILE_IMG,
                                userJoinStatus    : memRow.ENTRY_YN,
                                liveCertiYN       : memRow.LIVE_CERTI_YN,
                                isInkON           : true,
                                isSoundON         : true, //isSoundON         : false,
                                isScreenON        : true,
                                isSelect          : true, //isSelect          : false,
                                isPullScreen      : true,
                                isPushScreen      : true,
                            }));
                        }

                        draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, 0, 0);
                        draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, 0);

                        draft.isOnAirLive = true;
                    }
                });

                /*if (list_member) {
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            live_info.liveStatus = "Y";
                            live_info.groupLimit = groupLimit !== undefined && groupLimit !== null ? groupLimit : live_info.groupLimit;
                            live_info.list_member = list_member.map(memRow => ({
                                userSeq           : memRow.USER_SEQ,
                                userNickname      : memRow.USER_NICKNAME,
                                userProfile       : memRow.USER_PROFILE,
                                profileImgUrl     : memRow.USER_PROFILE_IMG,
                                userJoinStatus    : memRow.ENTRY_YN,
                                liveCertiYN       : memRow.LIVE_CERTI_YN,
                                isInkON           : true,
                                isSoundON         : false,
                                isScreenON        : true,
                                isSelect          : false,
                                isPullScreen      : true,
                                isPushScreen      : true,
                            }));
                        }

                        const searchLive_info = draft.list_searchLive.find(info => info.liveSeq === liveSeq);
                        if(searchLive_info) {
                            searchLive_info.liveStatus = "Y";
                            searchLive_info.groupLimit = groupLimit !== undefined && groupLimit !== null ? groupLimit : searchLive_info.groupLimit;
                            live_info.list_member = list_member.map(memRow => ({
                                userSeq           : memRow.USER_SEQ,
                                userNickname      : memRow.USER_NICKNAME,
                                userProfile       : memRow.USER_PROFILE,
                                profileImgUrl     : memRow.USER_PROFILE_IMG,
                                userJoinStatus    : memRow.ENTRY_YN,
                                liveCertiYN       : memRow.LIVE_CERTI_YN,
                                isInkON           : true,
                                isSoundON         : false,
                                isScreenON        : true,
                                isSelect          : false,
                                isPullScreen      : true,
                                isPushScreen      : true,
                            }));
                        }

                        draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, 0, 0);
                        draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, 0);
                        
                        draft.isOnAirLive = true;
                    });
                } else {
                    console.log("이런 경우도 있나요..?");

                    return {
                        ...state,
                    }
                }*/
            } else {
                const { isUsedMixedClass } = action.payload;
                //console.log("LIVE_ON - isUpdateSuccess is (1)... ", isUpdateSuccess);
                if (isUsedMixedClass) {
                    return {
                        ...state,
                        alertMessage            : i18n.t("클래스_오픈_실패_믹스드_클래스", { ns: "live" }),
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        isErrorAlertModalOpened : true
                    }
                } else {
                    return {
                        ...state,
                        alertMessage            : i18n.t("클래스_오픈_요청_실패", { ns: "live" }),
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        isErrorAlertModalOpened : true
                    }
                }
            }
        } else {
            //console.log("LIVE_ON - isSuccessed is ... ", isSuccessed);
            return {
                ...state,
                alertMessage            : i18n.t("클래스_오픈_요청_실패", { ns: "live" }),
                alertKind               : ConstData.ALERT_KIND.DANGER,
                isErrorAlertModalOpened : true
            }
        }
    },

    [LIVE_OFF_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq, serverUserSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("LIVE_OFF_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;
            const { isExist } = action.payload;

            if (isUpdateSuccess) {
                callEoForSendMediaScreen("updateLiveStatus", { userSeq, liveSeq, status : false });

                if (ConstData.IS_LOCAL_VERSION) {
                    callEoForSendMediaScreen("relayAction", { action: "liveEnd", localLiveSEQ: liveSeq, localUserSEQ: userSeq });
                }

                const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    if (live_info.selectStatus === "Y") {
                        callEoForUpdateHandsUpStatus(userSeq, liveSeq, null, "N", "N", null, live_info.startSeqOfSmartTV, live_info.binders);
                    }

                    if (live_info.mixedClassExistYn === "Y") {
                        if (live_info.mixedClassStatus === "Y") {
                            callEoForUpdateMixedClassStatus('stop', serverUserSeq, userSeq, liveSeq);
                        }
                    }

                    cancelTimer(live_info.liveSeq);
                }

                return produce(state, draft => {
                    if(isExist === false) {
                        draft.isOnAirLive = false; // 마지막 Live OffAir
                    }

                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            //live_info.list_member.forEach(member => {
                            //    member.userJoinStatus = "N";
                            //});

                            live_info.list_member = live_info.list_member.filter(info => !info.isGuest);
                        }

                        live_info.liveStatus        = "N";
                        live_info.liveGroupStudy    = "N";
                        live_info.handsUpYN         = "N";
                        live_info.selectStatus      = "N";  // live_info.liveHandsUpActive = "N";
                        live_info.livePublicYn      = "N";
                        live_info.mixedClassStatus  = "N";  // 라이브 종료시 mixed class status 초기화
                        live_info.startSeqOfSmartTV = 900;  // 라이브 종료시 count 초기화
                        live_info.binders           = null;

                        let liveName = live_info.liveName;
                        if (liveName.length > 8) {
                            liveName = liveName.substring(0, 6) + "...";
                        }
                        draft.nextPhaseData = "클래스 [" + liveName + "] OFF";
                    }

                    const searchLive_info = draft.list_searchLive.find(info => info.liveSeq === liveSeq);
                    if(searchLive_info !== undefined && searchLive_info !== null) {
                        if (searchLive_info.list_member !== undefined && searchLive_info.list_member !== null) {
                            searchLive_info.list_member.forEach(member => {
                                member.userJoinStatus = "N";
                            });
                        }

                        searchLive_info.liveStatus          = "N";
                        searchLive_info.liveGroupStudy      = "N";
                        searchLive_info.handsUpYN           = "N";
                        searchLive_info.selectStatus        = "N";  //searchLive_info.liveHandsUpActive = "N";
                        searchLive_info.livePublicYn        = "N";
                        searchLive_info.mixedClassStatus    = "N";  // 라이브 종료시 mixed class status 초기화
                        searchLive_info.startSeqOfSmartTV   = 900;  // 라이브 종료시 count 초기화
                        searchLive_info.binders             = null;
                    }

                    if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                        if (draft.selectedLiveInfo.list_member !== undefined && draft.selectedLiveInfo.list_member !== null) {
                            draft.selectedLiveInfo.list_member.forEach(member => {
                                member.userJoinStatus = "N";
                            });
                        }
                    }

                    draft.isOffAirLiveModalOpened = false;
                });
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [BROADCAST_ON_SUCCESS]: (state, action) => {
        const { broadcastSeq, liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("BROADCAST_ON - ", action.payload);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                let liveInfo = state.list_live.find(info => info.liveSeq === liveSeq);

                if (liveInfo) {
                    let broadcastInfo = liveInfo.list_broadcast.find(info => info.broadcastSeq === broadcastSeq);

                    if (broadcastInfo) {
                        if (broadcastInfo.list_guest && broadcastInfo.list_guest.length > 0) {
                            callEoForUpdateBroadcastStatus(liveSeq, broadcastInfo.list_guest, "Y");
                        }
                    }
                }

                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);

                    if (live_info) {
                        const broadcast_info = live_info.list_broadcast.find(info => info.broadcastSeq === broadcastSeq);

                        if (broadcast_info) {
                            broadcast_info.broadcastOpenYN = "Y";

                            if (broadcast_info.list_guest && broadcast_info.list_guest.length > 0) {
                                live_info.list_member = live_info.list_member.concat(broadcast_info.list_guest);
                            }
                        }
                    }
                });
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    [REMOVE_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_LIVE_SUCCESS - ", action.payload);

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return produce(state, draft => {
                    draft.list_live = draft.list_live.filter(info => info.liveSeq !== liveSeq);
                    draft.list_searchLive = draft.list_searchLive.filter(info => info.liveSeq !== liveSeq);

                    if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                        draft.isEditLiveModalOpened = false;
                        draft.isEditLiveMemberModalOpened = false;
                        draft.isRemoveLiveModalOpened = false;
                        draft.isRemoveLiveMemberModalOpened = false;
                        draft.selectedLiveInfo = {};
                    }
                });
            } else {
                //console.log("REMOVE_LIVE -  isRemoveSuccess is ", isRemoveSuccess);
                
                return {
                    ...state
                }
            }
        } else {
            //console.log("REMOVE_LIVE -  isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [REMOVE_LIVE_MEMBER_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REMOVE_LIVE_MEMBER_SUCCESS");

        if (isSuccessed) {
            const { isRemoveSuccess } = action.payload;

            if (isRemoveSuccess) {
                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            live_info.list_member = live_info.list_member.filter(info => info.userSeq !== userSeq);
                        }
                    }

                    if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                        if (draft.selectedLiveInfo.list_member) {
                            draft.selectedLiveInfo.list_member = draft.selectedLiveInfo.list_member.filter(info => info.userSeq !== userSeq);
                            draft.isRemoveLiveMemberModalOpened = false;
                            draft.isRemoveLiveModalOpened = false;
                        }
                    }
                });
            } else {
                //console.log("REMOVE_LIVE_MEMBER - isRemoveSuccess is ", isRemoveSuccess);

                return {
                    ...state
                }
            }
        } else {
            //console.log("REMOVE_LIVE_MEMBER - isSuccessed is ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [UPDATE_LIVE_MEMBER_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq, liveCertiYN } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("UPDATE_LIVE_MEMBER_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                // return produce 활용해서 callEoAddLiveMember C#으로 던지기
                // HandsUp 창 UI 및 addLiveMember 기능 구현
                if (liveCertiYN === "Y") {
                    const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info && live_info.list_waitMember) {
                        const member_info = live_info.list_waitMember.find(info => info.userSeq === userSeq);
                        if (member_info) {
                            const newMember_info = {
                                ...member_info,
                                liveCertiYN     : liveCertiYN,
                                isInkON         : true,
                                isSoundON       : true,
                                isScreenON      : true,
                                isSelect        : false,
                                isPullScreen    : true,
                                isPushScreen    : true
                            };

                            callEoAddLiveMember(liveSeq, newMember_info);
                        }
                    }
                }

                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info && live_info.list_waitMember) {
                        if (liveCertiYN === "Y") {
                            const member_info = live_info.list_waitMember.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.liveCertiYN = liveCertiYN;
                                const newMember_info = {
                                    ...member_info,
                                    isInkON         : true,
                                    isSoundON       : true,
                                    isScreenON      : true,
                                    isSelect        : false,
                                    isPullScreen    : true,
                                    isPushScreen    : true,
                                };

                                if (live_info.list_member) {
                                    live_info.list_member.push(newMember_info);
                                } else {
                                    live_info.list_member = [];
                                    live_info.list_member.push(newMember_info);
                                }
                            }

                            live_info.list_waitMember = live_info.list_waitMember.filter(info => info.userSeq !== userSeq);
                        }
                    }

                    if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                        if (draft.selectedLiveInfo.list_waitMember) {
                            if (liveCertiYN === "Y") {
                                const member_info = draft.selectedLiveInfo.list_waitMember.find(info => info.userSeq === userSeq);
                                if (member_info) {
                                    member_info.liveCertiYN = liveCertiYN;
                                    const newMember_info = {
                                        ...member_info,
                                        isInkON         : true,
                                        isSoundON       : true,
                                        isScreenON      : true,
                                        isSelect        : false,
                                        isPullScreen    : true,
                                        isPushScreen    : true,
                                    };

                                    if (draft.selectedLiveInfo.list_member) {
                                        draft.selectedLiveInfo.list_member.push(newMember_info);
                                    } else {
                                        draft.selectedLiveInfo.list_member = [];
                                        draft.selectedLiveInfo.list_member.push(newMember_info);
                                    }
                                }
                            }

                            draft.selectedLiveInfo.list_waitMember = draft.selectedLiveInfo.list_waitMember.filter(info => info.userSeq !== userSeq);
                        }
                    }
                });
            } else {
                const { isCanAdd } = action.payload;

                if (!isCanAdd) {
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            if (live_info.teacher_info) {
                                draft.closedPerformLiveInfo.teacher_info = live_info.teacher_info;
                            } else {
                                draft.closedPerformLiveInfo.teacher_info = {
                                    userSeq: -1,
                                    userNickname: "",
                                    userProfile: "",
                                    profileImgUrl: ""
                                };
                            }

                            draft.closedPerformLiveInfo.liveName = live_info.liveName;
                            draft.isLiveAlertModalMemberLimitOpened = true;
                        }
                    });
                } else {
                    //console.log("UPDATE_LIVE_MEMBER - isCanAdd => ", isCanAdd);
                    return {
                        ...state
                    }
                }
            }
        } else {
            //console.log("UPDATE_LIVE_MEMBER - isSuccessed => ", isSuccessed);
            return {
                ...state
            }
        }
    },

    [LIVE_CODE_CHECK_SUCCESS]: (state, action) => {
        const { mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("LIVE_CODE_CHECK_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) { // 이미 존재하는 코드
                if (mode === "hiclass") {
                    return {
                        ...state,
                        isCertifiedLiveCode     : false,
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        alertMessage            : i18n.t("클래스_코드_사용_불가능", { ns: "live" }),
                    }
                } else {
                    return {
                        ...state,
                        web_isCertifiedLiveCode : false,
                        web_alertKind           : ConstData.ALERT_KIND.DANGER,
                        web_alertMessage        : i18n.t("클래스_코드_사용_불가능", { ns: "live" }),
                    }
                }
                
            } else { // 사용 가능한 코드
                if (mode === "hiclass") {
                    return {
                        ...state,
                        isCertifiedLiveCode     : true,
                        alertKind               : ConstData.ALERT_KIND.SUCCESS,
                        alertMessage            : i18n.t("클래스_코드_사용_가능", { ns: "live" }),
                    }
                } else {
                    return {
                        ...state,
                        web_isCertifiedLiveCode : true,
                        web_alertKind           : ConstData.ALERT_KIND.SUCCESS,
                        web_alertMessage        : i18n.t("클래스_코드_사용_가능", { ns: "live" }),
                    }
                }                    
            }
        } else {
            if (mode === "hiclass") {
                return {
                    ...state,
                    isCertifiedLiveCode     : false,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("클래스_코드_조회_실패", { ns: "live" }),
                }
            } else {
                return {
                    ...state,
                    web_isCertifiedLiveCode : false,
                    web_alertKind           : ConstData.ALERT_KIND.DANGER,
                    web_alertMessage        : i18n.t("클래스_코드_조회_실패", { ns: "live" }),
                }
            }                
        }
    },

    [LIVE_CODE_CREATE_SUCCESS]: (state, action) => {
        const { mode } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("LIVE_CODE_CREATE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { liveCode } = action.payload;

            if (liveCode) {     // 생성된 코드 리턴하기.... 
                if (mode === "hiclass") {
                    return {
                        ...state,
                        createdLiveCode         : liveCode,
                        isCertifiedLiveCode     : true,
                        alertKind               : ConstData.ALERT_KIND.NONE,
                        alertMessage            : "",
                    }
                } else {
                    return {
                        ...state,
                        web_createdLiveCode     : liveCode,
                        web_isCertifiedLiveCode : true,
                        web_alertKind           : ConstData.ALERT_KIND.NONE,
                        web_alertMessage        : "",
                    }
                }
            } else {
                if (mode === "hiclass") {
                    return {
                        ...state,
                        isCertifiedLiveCode     : false,
                        alertKind               : ConstData.ALERT_KIND.DANGER,
                        alertMessage            : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV242.3]",
                    }
                } else {
                    return {
                        ...state,
                        web_isCertifiedLiveCode : false,
                        web_alertKind           : ConstData.ALERT_KIND.DANGER,
                        web_alertMessage        : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV243.0]",
                    }
                }
            }
        } else {
            if (mode === "hiclass") {
                return {
                    ...state,
                    isCertifiedLiveCode     : false,
                    alertKind               : ConstData.ALERT_KIND.DANGER,
                    alertMessage            : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV244.0]",
                }
            } else {
                return {
                    ...state,
                    web_isCertifiedLiveCode : false,
                    web_alertKind           : ConstData.ALERT_KIND.DANGER,
                    web_alertMessage        : i18n.t("클래스_생성_실패", { ns: "live" }) + " [Code.LV244.7]",
                }
            }
        }
    },

    [LIVE_CODE_CERTIFY_SUCCESS]: (state, action) => {
        const { liveCode, userSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isExist } = action.payload;

            if (isExist) {
                const { live_info } = action.payload;

                if (live_info) {
                    const searchLive_info = {
                        liveSeq                 : live_info.LIVE_SEQ,
                        liveName                : live_info.LIVE_NAME,
                        liveStatus              : live_info.LIVE_STATUS,
                        liveCode                : liveCode,
                        teacherSeq              : live_info.LIVE_TEACHER_SEQ,
                        teacherNickname         : live_info.USER_NICKNAME,
                        teacherProfile          : live_info.USER_PROFILE,
                        teacherProfileImgUrl    : live_info.USER_PROFILE_IMG,
                        isCanApply              : live_info.LIVE_TEACHER_SEQ === userSeq ? false : live_info.isCanApply, // 내가 만든 클래스인지 체크하기 위해... by hjkim 20220324
                        liveCertiYN             : live_info.liveCertiYN
                    };

                    return {
                        ...state,
                        searchLiveInfo                  : searchLive_info,
                        searchInfoAlertKind             : ConstData.ALERT_KIND.NONE,
                        searchInfoAlertMessage          : "",
                    }
                } else { // 일치하는 데이터가 없는 경우....
                    return {
                        ...state,
                        searchLiveInfo                  : {},
                        searchInfoAlertKind             : ConstData.ALERT_KIND.DANGER,
                        searchInfoAlertMessage          : i18n.t("클래스_코드_일치_정보_없음", { ns: "live" }),
                    }
                }
            } else { // 일치하는 데이터가 없는 경우....
                return {
                    ...state,
                    searchLiveInfo                  : {},
                    searchInfoAlertKind             : ConstData.ALERT_KIND.DANGER,
                    searchInfoAlertMessage          : i18n.t("클래스_코드_일치_정보_없음", { ns: "live" }),
                }
            }
        } else {
            console.log("LIVE_CODE_CERTIFY - isSuccessed => ", isSuccessed);

            return {
                ...state,
                searchLiveInfo                  : {},
                searchInfoAlertKind             : ConstData.ALERT_KIND.DANGER,
                searchInfoAlertMessage          : i18n.t("클래스_코드_조회_실패", { ns: "live" }) + " [Code.LV250.6]",
            }
        }
    },

    [CLOSE_LIVE_CODE]: (state, action) => {
        //console.log("CLOSE_LIVE_CODE");

        return {
            ...state,
            searchLiveInfo                      : {},
            searchInfoAlertKind                 : ConstData.ALERT_KIND.NONE,
            searchInfoAlertMessage              : "",
        }
    },

    [APPLY_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isApplySuccess } = action.payload;

            if (isApplySuccess) {
                return produce(state, draft => {
                    if (draft.searchLiveInfo.liveSeq === liveSeq) {
                        const newLive_info = {
                            liveSeq             : draft.searchLiveInfo.liveSeq,
                            liveName            : draft.searchLiveInfo.liveName,
                            liveStatus          : draft.searchLiveInfo.liveStatus,
                            liveCertiYN         : "N",
                            teacher_info        : {
                                userSeq         : draft.searchLiveInfo.teacherSeq,
                                userNickname    : draft.searchLiveInfo.teacherNickname,
                                userProfile     : draft.searchLiveInfo.teacherProfile,
                                profileImgUrl   : draft.teacherProfileImgUrl
                            },
                            list_member         : []
                        };

                        draft.list_requestedWatchLive.push(newLive_info);
                        draft.searchLiveInfo = {};
                        draft.searchInfoAlertKind = ConstData.ALERT_KIND.SUCCESS;
                        draft.searchInfoAlertMessage = i18n.t("학생_클래스_등록_요청_성공", { ns: "live" });
                    }
                });
            } else {
                console.log("APPLY_LIVE - isApplySuccess => ", isApplySuccess);

                return {
                    ...state,
                    searchInfoAlertKind             : ConstData.ALERT_KIND.DANGER,
                    searchInfoAlertMessage          : i18n.t("학생_클래스_등록_요청_실패", { ns: "live" }),
                }
            }
        } else {
            console.log("APPLY_LIVE - isSuccessed => ", isSuccessed);

            return {
                ...state,
                searchInfoAlertKind             : ConstData.ALERT_KIND.DANGER,
                searchInfoAlertMessage          : i18n.t("학생_클래스_등록_요청_실패", { ns: "live" }),
            }
        }
    },

    [REAPPLY_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("REAPPLY_LIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isApplySuccess } = action.payload;

            if (isApplySuccess) {
                return produce(state, draft => {
                    const live_info = draft.list_requestedWatchLive.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.liveCertiYN = "N";
                    }
                });
            } else {
                console.log("REAPPLY_LIVE - isApplySuccess => ", isApplySuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("REAPPLY_LIVE - isSuccessed => ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [JOIN_MY_BROADCAST_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq, liveName, userSeq, userNickname, userProfile, profileImgUrl } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isUsablePass } = action.payload;
            if (isUsablePass) {
                const { isEntrySuccess } = action.payload;

                if (isEntrySuccess) {
                    const { teacherRoomSeq, handsUpStatus, handsUpActive } = action.payload;

                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            if (live_info.liveStatus === "Y") {
                                draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                            }

                            if (live_info.teacher_info) {
                                live_info.teacher_info.userSeq = userSeq;
                                live_info.teacher_info.userNickname = userNickname;
                                live_info.teacher_info.userProfile = userProfile;
                                live_info.teacher_info.profileImgUrl = profileImgUrl;
                            } else {
                                live_info.teacher_info = { userSeq, userNickname, userProfile, profileImgUrl };
                            }

                            if (live_info.userGateWay) {
                                live_info.teacher_info.userGateWay = live_info.userGateWay;
                            } else {
                                live_info.teacher_info.userGateWay = "";
                            }

                            draft.performLiveInfo = {
                                isBroadcastLive : true,
                                isUsablePass    : isUsablePass,
                                liveSeq         : live_info.liveSeq,
                                liveName        : live_info.liveName,
                                liveStatus      : live_info.liveStatus,
                                handsUpYN       : handsUpStatus,
                                handsUpSelectYN : handsUpActive,
                                robotLiveYN     : live_info.robotLiveYN,
                                list_member     : live_info.list_member === undefined || live_info.list_member === null ? 
                                                  [] :
                                                  live_info.list_member,
                                mixedClassStatus: live_info.mixedClassStatus,
                                teacher_info    : live_info.teacher_info,
                            };

                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                            draft.isGotoWebLive = true;
                        } else {
                            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                            if (robotLive_info) {
                                if (robotLive_info.liveStatus === "Y") {
                                    draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                                    draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                                }

                                if (robotLive_info.teacher_info) {
                                    robotLive_info.teacher_info.userSeq = userSeq;
                                    robotLive_info.teacher_info.userNickname = userNickname;
                                    robotLive_info.teacher_info.userProfile = userProfile;
                                    robotLive_info.teacher_info.profileImgUrl = profileImgUrl;
                                } else {
                                    robotLive_info.teacher_info = { userSeq, userNickname, userProfile, profileImgUrl };
                                }

                                if (robotLive_info.userGateWay) {
                                    robotLive_info.teacher_info.userGateWay = robotLive_info.userGateWay;
                                } else {
                                    robotLive_info.teacher_info.userGateWay = "";
                                }

                                draft.performLiveInfo = {
                                    isBroadcastLive : true,
                                    isUsablePass    : isUsablePass,
                                    liveSeq         : robotLive_info.liveSeq,
                                    liveName        : robotLive_info.liveName,
                                    liveStatus      : robotLive_info.liveStatus,
                                    handsUpYN       : handsUpStatus,
                                    handsUpSelectYN : handsUpActive,
                                    robotLiveYN     : robotLive_info.robotLiveYN,
                                    list_member     : robotLive_info.list_member === undefined || robotLive_info.list_member === null ? 
                                                      [] :
                                                      robotLive_info.list_member,
                                    mixedClassStatus: robotLive_info.mixedClassStatus,
                                    teacher_info    : robotLive_info.teacher_info,
                                };

                                draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                                draft.isGotoWebLive = true;
                            }
                        }
                    });
                } else {
                    console.log("JOIN_MY_BROADCAST_LIVE - isEntrySuccess => ", isEntrySuccess);

                    return {
                        ...state
                    }
                }
            } else {
                console.log("JOIN_MY_BROADCAST_LIVE - my pass is expired");

                return {
                    ...state,
                    modalAlertLiveInfo : {
                        liveSeq : liveSeq,
                        liveName : liveName
                    },
                    isLiveAlertModalExpiredPassOpened : true
                }
            }
        } else {
            console.log("JOIN_MY_BROADCAST_LIVE - ", action.payload);

            return {
                ...state
            }
        }
    },

    [JOIN_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq, liveName } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("JOIN_LIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUsablePass } = action.payload;

            console.log(`JOIN_LIVE - isUsablePass[${isUsablePass}]`);
            if (isUsablePass) {
                const { isEntrySuccess } = action.payload;

                if (isEntrySuccess) {
                    const { handsUpStatus, handsUpActive } = action.payload;

                    return produce(state, draft => {
                        const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                        if (watchLive_info) {
                            if (watchLive_info.list_member) {
                                const member_info = watchLive_info.list_member.find(info => info.userSeq === userSeq);
                                if (member_info) {
                                    const { userNickname } = action.parms;

                                    member_info.userJoinStatus = "Y";
                                    member_info.userNickname = userNickname;
                                }
                            }

                            if (watchLive_info.liveStatus === "Y") {
                                if (watchLive_info.teacher_info) {
                                    let teacherSeq = watchLive_info.teacher_info.userSeq;
                                    draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                                    draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                                }
                            }

                            draft.performLiveInfo = {
                                isBroadcastLive : false,
                                isUsablePass    : isUsablePass,
                                liveSeq         : watchLive_info.liveSeq,
                                liveName        : watchLive_info.liveName,
                                liveStatus      : watchLive_info.liveStatus,
                                handsUpYN       : handsUpStatus,
                                handsUpSelectYN : handsUpActive,
                                robotLiveYN     : watchLive_info.robotLiveYN,
                                list_member     : watchLive_info.list_member === undefined || watchLive_info.list_member === null ? 
                                                  [] : 
                                                  watchLive_info.list_member,
                                mixedClassStatus: watchLive_info.mixedClassStatus,
                                teacher_info    : watchLive_info.teacher_info,
                            };

                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                            draft.isGotoWebLive = true;

                            /* if (watchLive_info.teacher_info) {
                                if (watchLive_info.liveStatus === "Y") {
                                    let teacherSeq = watchLive_info.teacher_info.userSeq;
                                    draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                                    draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                                }

                                if (watchLive_info.list_member) {
                                    const member_info = watchLive_info.list_member.find(info => info.userSeq === userSeq);
                                    if (member_info) {
                                        member_info.userJoinStatus = "Y";
                                    }
                                }

                                draft.performLiveInfo = {
                                    isBroadcastLive : false,
                                    isUsablePass    : isUsablePass,
                                    liveSeq         : watchLive_info.liveSeq,
                                    liveName        : watchLive_info.liveName,
                                    liveStatus      : watchLive_info.liveStatus,
                                    handsUpYN       : handsUpStatus,
                                    handsUpSelectYN : handsUpActive,
                                    robotLiveYN     : watchLive_info.robotLiveYN,
                                    teacher_info    : watchLive_info.teacher_info,
                                    list_member     : watchLive_info.list_member === undefined || watchLive_info.list_member === null ? 
                                                      [] : 
                                                      watchLive_info.list_member
                                };

                                draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                                draft.isGotoWebLive = true;
                            } */
                        } else { // for using cookie mode by hjkim 20220825
                            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                            if (live_info) {
                                if (live_info.liveStatus === "Y") {
                                    draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                                    draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                                }

                                if (live_info.teacher_info) {
                                    const { userNickname, userProfile, profileImgUrl } = action.parms;

                                    live_info.teacher_info.userSeq = userSeq;
                                    live_info.teacher_info.userNickname = userNickname;
                                    live_info.teacher_info.userProfile = userProfile;
                                    live_info.teacher_info.profileImgUrl = profileImgUrl;
                                } else {
                                    const { userNickname, userProfile, profileImgUrl } = action.parms;

                                    live_info.teacher_info = {
                                        userSeq: userSeq,
                                        userNickname: userNickname,
                                        userProfile: userProfile,
                                        profileImgUrl: profileImgUrl
                                    };
                                }

                                if (live_info.userGateWay) {
                                    live_info.teacher_info.userGateWay = live_info.userGateWay;
                                } else {
                                    live_info.teacher_info.userGateWay = "";
                                }

                                draft.performLiveInfo = {
                                    isBroadcastLive : true,
                                    isUsablePass    : isUsablePass,
                                    liveSeq         : live_info.liveSeq,
                                    liveName        : live_info.liveName,
                                    liveStatus      : live_info.liveStatus,
                                    handsUpYN       : handsUpStatus,
                                    handsUpSelectYN : handsUpActive,
                                    robotLiveYN     : live_info.robotLiveYN,
                                    list_member     : live_info.list_member === undefined || live_info.list_member === null ? 
                                                      [] :
                                                      live_info.list_member,
                                    mixedClassStatus: live_info.mixedClassStatus,
                                    teacher_info    : live_info.teacher_info,
                                };

                                draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                                draft.isGotoWebLive = true;
                            }
                        }
                    });
                } else {
                    console.log("JOIN_LIVE_SUCCESS - isEntrySuccess => ", isEntrySuccess);

                    return {
                        ...state
                    }
                }
            } else {
                console.log("JOIN_LIVE_SUCCESS - teacher pass is expired");

                return {
                    ...state,
                    isLiveAlertModalExpiredPassOpened   : true,
                    modalAlertLiveInfo                  : {
                        liveSeq                         : liveSeq,
                        liveName                        : liveName
                    }
                }
            }
        } else {
            console.log("JOIN_LIVE_SUCCESS - ", action.payload);

            return {
                ...state
            }
        }
    },

    [REQUEST_JOIN_MIXED_CLASS_LIVE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("REQUEST_JOIN_MIXED_CLASS_LIVE - ", action.payload);

        if (isSuccessed) {
            const { isRequestSuccess } = action.payload;

            return {
                ...state
            }
        } else {
            return {
                ...state
            }
        }
    },

    [JOIN_MIXED_CLASS_LIVE]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("JOIN_MIXED_CLASS_LIVE - ", action.payload);

        if (isSuccessed) {
            console.log(`mixedClassInfo.isUsablePass[${state.mixedClassInfo.isUsablePass}]`);
            // if (state.isUsablePass) {
            const { isUsablePass } = action.payload;
            if (isUsablePass) {
                const { isEntrySuccess } = action.payload;

                if (isEntrySuccess) {
                    const { handsUpStatus, handsUpActive, userSeq } = action.payload;
                    const liveSeq = state.mixedClassInfo.localLiveSeq;
                    const serverLiveSeq = state.mixedClassInfo.serverLiveSeq;
                    let teacherSeq = state.mixedClassInfo.serverTeacherSeq;

                    return produce(state, draft => {
                        // mixed class 의 live_info는 어떻게 하는게 맞는가..?ㅁ?... by hjkim 20220110
                        const live_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                        if (live_info) {
                            console.log("live_info.liveStatus => ", live_info.liveStatus);
                            if (live_info.liveStatus === "Y") {
                                //let teacherSeq = live_info.teacher_info.userSeq;
                                //draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                                //draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                                draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                            }

                            live_info.mixedClassStatus = "Y";

                            if (live_info.list_member) {
                                const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                                if (member_info) {
                                    member_info.userJoinStatus = "Y";
                                }
                            }

                            draft.performLiveInfo = {
                                isBroadcastLive : false,
                                isUsablePass    : isUsablePass,
                                liveSeq         : serverLiveSeq,
                                liveName        : live_info.liveName,
                                liveStatus      : live_info.liveStatus,
                                handsUpYN       : handsUpStatus,
                                handsUpSelectYN : handsUpActive,
                                robotLiveYN     : live_info.robotLiveYN,
                                list_member     : live_info.list_member === undefined || live_info.list_member === null ? 
                                                  [] : live_info.list_member,
                                mixedClassStatus: live_info.mixedClassStatus,
                                teacher_info    : {
                                    ...live_info.teacher_info,
                                    userSeq     : teacherSeq
                                },
                            };

                            draft.mixedClassInfo.isUsablePass = isUsablePass;

                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                            draft.isGotoWebLive = true;
                        }
                    });
                } else {
                    console.log("JOIN_MIXED_CLASS_LIVE - isEntrySuccess => ", isEntrySuccess);

                    return {    
                        ...state
                    }
                }
            } else {
                console.log("JOIN_MIXED_CLASS_LIVE - teacher pass is expired");

                const localLiveSeq = state.mixedClassInfo.localLiveSeq;
                const serverLiveSeq = state.mixedClassInfo.serverLiveSeq;
                const live_info = state.list_watchLive.find(info => info.liveSeq === localLiveSeq);
                if (live_info) {
                    const info = {
                        liveSeq     : serverLiveSeq,
                        liveName    : live_info.liveName
                    };

                    return {
                        ...state,
                        isLiveAlertModalExpiredPassOpened   : true,
                        modalAlertLiveInfo                  : info
                    }
                } else {
                    console.log("JOIN_MIXED_CLASS_LIVE - live_info => ", live_info);

                    return {
                        ...state
                    }
                }
            }
        } else {
            console.log("JOIN_MIXED_CLASS_LIVE - ", action.payload);

            return {
                ...state
            }
        }
    },

    [FINISH_MY_BROADCAST_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("FINISH_MY_BROADCAST_LIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return produce(state, draft => {
                    if (draft.performLiveInfo.liveSeq === liveSeq) {
                        draft.performLiveInfo = {
                            isBroadcastLive             : false,
                            isUsablePass                : false,
                            liveSeq                     : -1,
                            liveName                    : "",
                            liveStatus                  : "N",
                            handsUpYN                   : "N",
                            handsUpSelectYN             : "N",
                            robotLiveYN                 : "N",
                            list_member                 : [],
                            mixedClassStatus            : "N",
                            teacher_info                : {
                                userSeq                 : -1,
                                userNickname            : "",
                                userProfile             : "",
                                profileImgUrl           : "",
                                userGateWay             : ""
                            }
                        };

                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                        draft.isGotoWebLive = false;

                        draft.isLiveChattingLocked = false;
                        draft.isTouchScreenLocked = false;
                    }
                });
            } else {
                console.log("FINISH_MY_BROADCAST_LIVE - ", isFinishSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("FINISH_MY_BROADCAST_LIVE_SUCCESS - ", isSuccessed);

            return {
                ...state
            }
        }
    },

    [FINISH_LIVE_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("FINISH_LIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return produce(state, draft => {
                    if (draft.performLiveInfo.liveSeq === liveSeq) {
                        draft.performLiveInfo = {
                            isBroadcastLive             : false,
                            isUsablePass                : false,
                            liveSeq                     : -1,
                            liveName                    : "",
                            liveStatus                  : "N",
                            handsUpYN                   : "N",
                            handsUpSelectYN             : "N",
                            robotLiveYN                 : "N",
                            list_member                 : [],
                            mixedClassStatus            : "N",
                            teacher_info                : {
                                userSeq                 : -1,
                                userNickname            : "",
                                userProfile             : "",
                                profileImgUrl           : "",
                                userGateWay             : ""
                            }
                        };

                        // draft.joinLiveInfo = {};

                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                        draft.isGotoWebLive = false;
                        draft.smallGroupInfo            = {
                            status                      : false,
                            list_member                 : []
                        };

                        draft.isLiveChattingLocked = false;
                        draft.isTouchScreenLocked = false;
                    }

                    const live_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                            }
                        }
                    }
                });
            } else {
                console.log("FINISH_LIVE_SUCCESS - isFinishSuccess => ", isFinishSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("FINISH_LIVE_SUCCESS - ", action.payload);

            return {
                ...state
            }
        }
    },

    [FINISH_MIXED_CLASS_LIVE]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("FINISH_MIXED_CLASS_LIVE - ", action.payload);

        if (isSuccessed) {
            const { isFinishSuccess } = action.payload;

            if (isFinishSuccess) {
                return produce(state, draft => {
                    draft.performLiveInfo = {
                        isBroadcastLive             : false,
                        isUsablePass                : false,
                        liveSeq                     : -1,
                        liveName                    : "",
                        liveStatus                  : "N",
                        handsUpYN                   : "N",
                        handsUpSelectYN             : "N",
                        robotLiveYN                 : "N",
                        list_member                 : [],
                        mixedClassStatus            : "N",
                        teacher_info                : {
                            userSeq                 : -1,
                            userNickname            : "",
                            userProfile             : "",
                            profileImgUrl           : "",
                            userGateWay             : ""
                        }
                    };

                    // draft.joinLiveInfo = {};

                    draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                    draft.isGotoWebLive = false;
                    draft.smallGroupInfo            = {
                        status                      : false,
                        list_member                 : []
                    };

                    draft.isLiveChattingLocked = false;
                    draft.isTouchScreenLocked = false;

                    /* const live_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                            }
                        }
                    } */
                });
            } else {
                console.log("FINISH_MIXED_CLASS_LIVE - isFinishSuccess => ", isFinishSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("FINISH_MIXED_CLASS_LIVE - ", action.payload);

            return {
                ...state
            }
        }
    },

    [REQUEST_FINISH_MIXED_CLASS_LIVE_SUCCESS]: (state, action) => {
        return {
            ...state
        }
    },

    /**********************************************************/
    /////////////////// 핸즈업 & 모둠학습 ///////////////////////
    /**********************************************************/

    [OPEN_GROUP_STUDY_WINDOW]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log("OPEN_GROUP_STUDY_WINDOW - ", action.payload);

        callEoForUpdateGroupStudyWindowStatus(userSeq, "Y");

        return {
            ...state,
            isOpenedGroupStudy : true
        }
    },

    [CLOSE_GROUP_STUDY_WINDOW]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log("CLOSE_GROUP_STUDY_WINDOW - ", action.payload);

        callEoForUpdateGroupStudyWindowStatus(userSeq, "N");

        return {
            ...state,
            isOpenedGroupStudy : false
        }
    },

    [CHANGE_LIVE_STATUS_INFO]: (state, action) => {
        const { kind, status } = action.payload;
        //console.log("CHANGE_LIVE_STATUS_INFO - ", action.payload);

        if (kind === "connect") { // 제일 처음 consume 이벤트 발생해야 할 때 사용...
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : true,
                    connectStatus       : "on",
                    videoStatus         : "off",
                    soundStatus         : "off", // 나중에 audioStatus로 이름 변경하자...
                    micStatus           : "off"
                }
            }
        } else if (kind === "disconnect") {
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : false,
                    connectStatus       : status,
                    videoStatus         : status,
                    soundStatus         : status,
                    micStatus           : status
                }
            }
        } else if (kind === "unconnectable")  {
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : false,
                    connectStatus       : status,
                    videoStatus         : "disable",
                    soundStatus         : "disable",
                    micStatus           : "disable",
                }
            }
        } else if (kind === "video") {
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : state.performLiveStatusInfo.isConnect,
                    connectStatus       : state.performLiveStatusInfo.connectStatus,
                    videoStatus         : status,
                    soundStatus         : state.performLiveStatusInfo.soundStatus,
                    micStatus           : state.performLiveStatusInfo.micStatus
                }
            }
        } else if (kind === "audio") {
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : state.performLiveStatusInfo.isConnect,
                    connectStatus       : state.performLiveStatusInfo.connectStatus,
                    videoStatus         : state.performLiveStatusInfo.videoStatus,
                    soundStatus         : status,
                    micStatus           : state.performLiveStatusInfo.micStatus
                }
            }
        } else if (kind === "mic") {
            return {
                ...state,
                performLiveStatusInfo   : {
                    isConnect           : state.performLiveStatusInfo.isConnect,
                    connectStatus       : state.performLiveStatusInfo.connectStatus,
                    videoStatus         : state.performLiveStatusInfo.videoStatus,
                    soundStatus         : state.performLiveStatusInfo.soundStatus,
                    micStatus           : status
                }
            }
        } else {
            console.log("CHANGE_LIVE_STATUS_INFO - kind => ", kind);

            return {
                ...state
            }
        }
    },

    [CHANGE_HANDS_UP_STATUS_INFO]: (state, action) => {
        const { kind, status } = action.payload;
        //console.log("CHANGE_HANDS_UP_STATUS_INFO - ", action.payload);

        if (kind === "init") {
            return {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : state.performHandsUpStatusInfo.myHandsUpYN,
                    connectStatus   : "init",
                    micStatus       : state.performHandsUpStatusInfo.micStatus
                }
            }
        } else if (kind === "connect") {
            return {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : state.performHandsUpStatusInfo.myHandsUpYN,
                    connectStatus   : "connect",
                    micStatus       : state.performHandsUpStatusInfo.micStatus
                }
            }
        } else if (kind === "disconnect") {
            return {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : state.performHandsUpStatusInfo.myHandsUpYN,
                    connectStatus   : "disconnect",
                    micStatus       : "disable"
                }
            }
        } else if (kind === "disable") {
            return  {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : "N",
                    connectStatus   : "disable",
                    micStatus       : "disable"
                }
            }
        } else if (kind === "mic") {
            return {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : state.performHandsUpStatusInfo.myHandsUpYN,
                    connectStatus   : state.performHandsUpStatusInfo.connectStatus,
                    micStatus       : status
                }
            }
        } else if (kind === "mic-mode") {
            return {
                ...state,
                performHandsUpStatusInfo : {
                    myHandsUpYN     : state.performHandsUpStatusInfo.myHandsUpYN,
                    connectStatus   : state.performHandsUpStatusInfo.connectStatus,
                    micStatus       : status
                }
            }
        } else {
            console.log("CHANGE_HANDS_UP_STATUS_INFO - kind => ", kind);

            return {
                ...state
            }
        }
    },

    [HANDSUP_OPEN_TOGGLE]: (state, action) => {
        const { userSeq } = action.payload;
        //console.log("HANDSUP_OPEN_TOGGLE - ", action.payload);

        if (state.isOpenedHandsUp) {
            callEoForUpdateHandsUpWindowStatus(userSeq, "N");
        } else {
            callEoForUpdateHandsUpWindowStatus(userSeq, "Y");
        }

        return {
            ...state,
            isOpenedHandsUp : !state.isOpenedHandsUp
        }
    },

    [HANDS_UP_ACTIVE_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq, groupLimit } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("HANDS_UP_ACTIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess, isExist, activeLiveSeq } = action.payload;

            if (isUpdateSuccess) {
                if (isExist) { // 기존 active 클래스가 존재하는 경우 -> inactive 로 변경 알림
                    const live_info = state.list_live.find(info => info.liveSeq === activeLiveSeq);
                    if (live_info) {
                        if (live_info.list_member) {
                            callEoForUpdateHandsUpStatus(userSeq, activeLiveSeq, live_info.list_member, "N", live_info.handsUpYN, null, live_info.startSeqOfSmartTV, live_info.binders);
                        }
                    }
                }

                const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        //callEoForUpdateHandsUpStatus(userSeq, liveSeq, live_info.list_member, "Y", "Y", (groupLimit === undefined ? null : groupLimit)); 
                        callEoForUpdateHandsUpStatus(userSeq, liveSeq, live_info.list_member, "Y", live_info.handsUpYN, (groupLimit === undefined ? null : groupLimit), live_info.startSeqOfSmartTV, live_info.binders); 
                    }
                }

                return produce(state, draft => {
                    if (isExist && activeLiveSeq >= 0) { // 기존 active 클래스가 존재하는 경우 -> inactive 로 변경
                        const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                        if (live_info) {
                            live_info.selectStatus = "N";
                        }
                    }

                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.selectStatus = "Y";
                        //live_info.handsUpYN = "Y";

                        draft.openedLiveInfo = {
                            liveSeq         : live_info.liveSeq,
                            liveName        : live_info.liveName,
                            list_member     : live_info.list_member === undefined || live_info.list_member === null ? 
                                              [] : 
                                              live_info.list_member.map(member => ({
                                                ...member,
                                                isInkON       : true,
                                                isSoundON     : true,
                                                isScreenON    : true,
                                                isSelect      : true,
                                                isPullScreen  : true,
                                                isPushScreen  : true
                                              }))
                        };
                    }
                });
            } else {
                console.log("HANDS_UP_ACTIVE - isUpdateSuccess => ", isUpdateSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("HANDS_UP_ACTIVE - ", action.payload);

            return {
                ...state
            }
        }
    },

    [HANDS_UP_INACTIVE_SUCCESS]: (state, action) => {
        const { liveSeq, userSeq } = action.parms;
        const { isSuccessed } = action.payload;
        //console.log("HANDS_UP_INACTIVE_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        //console.log("unselect live_info, live_info.list_member ok");
                        callEoForUpdateHandsUpStatus(userSeq, liveSeq, live_info.list_member, "N", live_info.handsUpYN, null, live_info.startSeqOfSmartTV, live_info.binders);
                    }
                }

                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.selectStatus = "N";
                    }

                    if (draft.openedLiveInfo.liveSeq === liveSeq) {
                        draft.openedLiveInfo = {};
                    }
                });
            } else {
                console.log("HANDS_UP_INACTIVE - isUpdateSuccess => ", isUpdateSuccess);

                return {
                    ...state
                }
            }
        } else {
            console.log("HANDS_UP_INACTIVE - ", action.payload);

            return {
                ...state
            }
        }
    },

    [HANDS_UP_ON]: (state, action) => {
        const { teacherSeq, liveSeq, userSeq, userNickname } = action.payload;
        //console.log("HANDS_UP_ON - ", action.payload);

        // send to teacher
        sendHandsUpOn(teacherSeq, liveSeq, userSeq, userNickname);

        /** 깜빡이는 효과를 주세요..... */
        return produce(state, draft => {
            if (draft.performLiveInfo.liveSeq === liveSeq) {
                draft.performHandsUpStatusInfo.myHandsUpYN = "Y";
            }
        });
    },

    [HANDS_UP_OFF]: (state, action) => {
        const { teacherSeq, liveSeq, userSeq, userNickname } = action.payload;
        //console.log("HANDS_UP_OFF - ", action.payload);

        // send to teacher
        sendHandsUpOff(teacherSeq, liveSeq, userSeq, userNickname);

        /** 깜빡이는 효과를 꺼주세요..... */
        return produce(state, draft => {
            if (draft.performLiveInfo.liveSeq === liveSeq) {
                draft.performHandsUpStatusInfo.myHandsUpYN = "N";
            }
        });
    },

    /* [P2P_SOUND_ON]: (state, action) => {
        const { teacherSeq, liveSeq, userSeq, userNickname } = action.payload;
        //console.log("P2P_SOUND_ON - ", action.payload);

        // send to teacher
        //console.log("P2P_SOUND_ON - sendP2PSoundOn");
        sendP2PSoundOn(teacherSeq, liveSeq, userSeq, userNickname);

        // 소리 아이콘을 바꿔주세요...
        return {
            ...state
        }
    },

    [P2P_SOUND_OFF]: (state, action) => {
        const { teacherSeq, liveSeq, userSeq, userNickname } = action.payload;
        //console.log("P2P_SOUND_OFF - ", action.payload);

        // send to teacher
        //console.log("P2P_SOUND_OFF - sendP2PSoundOff");
        sendP2PSoundOff(teacherSeq, liveSeq, userSeq, userNickname);

        // 소리 아이콘을 바꿔주세요...
        return {
            ...state
        }
    }, */

    [SMALL_GROUP_STOP]: (state, action) => {
        //console.log("SMALL_GROUP_STOP");

        return {
            ...state,
            smallGroupInfo  : { status: false, list_member: [] }
        }
    },

    /**********************************************************/
    ////////////////////////// 리모콘 //////////////////////////
    /**********************************************************/
    
    // from HiClass
    [UPDATE_HANDSUP_MEMBER_STATUS]: (state, action) => {
        const { command } = action.payload;
        //console.log("UPDATE_HANDSUP_MEMBER_STATUS - ", action.payload);

        switch (command) {
            case "sound": break;

            case "screen": break;

            case "select": break;

            default:
                break;
        }

        return {
            ...state
        }
    },

    [REMOCON_HANDSUP_SELECT]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_SELECT - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "p2p",
                command: "handsupSelect",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isSelect = isSelect;
                            }
                        });
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isSelect = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "handsupSelect",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isSelect = isSelect;
                        }
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isSelect = isSelect;
                        }
                    }
                }
            });
        }
    },

    [REMOCON_HANDSUP_INK_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_INK_STATUS - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "handsup",
                command: "inkEnable",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isInkON = isSelect;
                            }
                        });
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    live_info.isInkSwitch = isSelect;

                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isInkON = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "inkEnable",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isInkON = isSelect;
                        }
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.memberSeq);
                        if (member_info) {
                            member_info.isInkON = isSelect;
                        }
                    }
                }
            });
        }
    },

    [REMOCON_HANDSUP_SOUND_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_SOUND_STATUS - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "handsup",
                command: "soundControl",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                console.log("draft.openedLiveInfo.liveSeq => ", draft.openedLiveInfo.liveSeq);
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isSoundON = isSelect;
                            }
                        });
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    live_info.isVoiceSwitch = isSelect;
                    console.log("live_info.isVoiceSwitch => ", live_info.isVoiceSwitch);

                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isSoundON = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "soundControl",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isSoundON = isSelect;
                        }
                    }
                }

                const live_info = draft.list_live.find(live => live.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isSoundON = isSelect;
                        }
                    }
                }
            });
        }
    },

    [REMOCON_HANDSUP_SCREEN_STATUS]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_SCREEN_STATUS - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "handsup",
                command: "screenControl",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isScreenON = isSelect;
                            }
                        });
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    live_info.isScreenSwitch = isSelect;

                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isScreenON = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "screenControl",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isScreenON = isSelect;
                        }
                    }
                }

                const live_info = draft.list_live.find(live => live.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isScreenON = isSelect;
                        }
                    }
                }
            });
        }
    },
            
    [REMOCON_HANDSUP_PULL_SCREEN]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_PULL_SCREEN - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "handsup",
                command: "pullScreen",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isPullScreen = isSelect;
                            }
                        });

                        draft.openedLiveInfo.isPullSwitch = isSelect;
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    live_info.isPullSwitch = isSelect;

                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isPullScreen = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "pullScreen",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isPullScreen = isSelect;
                        }

                        draft.openedLiveInfo.isPullSwitch = isSelect;
                    }
                }

                const live_info = draft.list_live.find(live => live.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isPullScreen = isSelect;
                        }
                    }

                    live_info.isPullSwitch = isSelect;
                }
            });
        }
    },
    
    [REMOCON_HANDSUP_PUSH_SCREEN]: (state, action) => {
        const { selectedFunc, memberSeq, memberSeqList, isSelect, activeLiveSeq } = action.payload;
        console.log("REMOCON_HANDSUP_PUSH_SCREEN - ", action.payload);

        if (selectedFunc === "HANDSUP_LIST") {
            const actionData = {
                target: "handsup",
                command: "pushScreen",
                isAll: true,
                userSeq: -1,
                isSelect: isSelect
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isPushScreen = isSelect;
                            }
                        });
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === activeLiveSeq);
                if (live_info) {
                    live_info.isPushSwitch = isSelect;

                    if (live_info.list_member) {
                        memberSeqList.forEach(info => {
                            const member_info = live_info.list_member.find(member => member.userSeq === info.userSeq);
                            if (member_info) {
                                member_info.isPushScreen = isSelect;
                            }
                        });
                    }
                }
            });
        } else {
            const actionData = {
                target: "handsup",
                command: "pushScreen",
                isAll: false,
                userSeq: memberSeq,
                isSelect: isSelect,
            };

            callEoForSendAction("remocon_info", actionData);

            return produce(state, draft => {
                if (draft.openedLiveInfo.liveSeq >= 0 && draft.openedLiveInfo.liveSeq === activeLiveSeq) {
                    if (draft.openedLiveInfo.list_member) {
                        const member_info = draft.openedLiveInfo.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isPushScreen = isSelect;
                        }
                    }
                }

                const live_info = draft.list_live.find(live => live.liveSeq === activeLiveSeq);
                if (live_info) {
                    if (live_info.list_member) {
                        const member_info = live_info.list_member.find(member => member.userSeq === memberSeq);
                        if (member_info) {
                            member_info.isPushScreen = isSelect;
                        }
                    }
                }
            });
        }
    },

    /**********************************************************/
    ///////////////////// change false goto ////////////////////
    /**********************************************************/

    [CHANGE_FALSE_CERTIFIED_LIVECODE]: (state, action) => {
        const { mode } = action.payload;
        //console.log("CHANGE_FALSE_CERTIFIED_LIVECODE - ", action.payload);

        if (mode === "hiclass") {
            return {
                ...state,
                isCertifiedLiveCode     : false,
                alertKind               : ConstData.ALERT_KIND.NONE,
                alertMessage            : ""
            }
        } else {
            return {
                ...state,
                web_isCertifiedLiveCode : false,
                web_alertKind           : ConstData.ALERT_KIND.NONE,
                web_alertMessage        : ""
            }
        }
    },

    [FALSE_TO_CERTIFIED_LIVECODE]: (state, action) => {
        const { mode } = action.payload;
        //console.log("FALSE_TO_CERTIFIED_LIVECODE - ", action.payload);

        if (mode === "hiclass") {
            return {
                ...state,
                isCertifiedLiveCode     : false,
                alertKind               : ConstData.ALERT_KIND.NONE,
                alertMessage            : ""
            }
        } else {
            return {
                ...state,
                web_isCertifiedLiveCode : false,
                web_alertKind           : ConstData.ALERT_KIND.NONE,
                web_alertMessage        : ""
            }
        }
    },

    /**********************************************************/
    /////////////////////////// 알 림 ///////////////////////////
    /**********************************************************/
    
    [TOGGLE_BROADCAST_TIME_NOTIFY]: (state, action) => {
        const { isShowing, notifyInfo } = action.payload;
        //console.log("TOGGLE_BROADCAST_TIME_NOTIFY - ", action.payload);

        return {
            ...state,
            isShowingBroadcastTimeNotify : isShowing,
            notifyInfo                   : notifyInfo
        }
    },

    /**********************************************************/
    ////////////////////// 모 달 & 상태 값 //////////////////////
    /**********************************************************/

    [TOGGLE_READY_LIVE_CONSUME]: (state, action) => {
        const { isReadyLiveConsume } = action.payload;
        console.log("TOGGLE_READY_LIVE_CONSUME - ", action.payload);

        return {
            ...state,
            isReadyLiveConsume  : isReadyLiveConsume
        }
    },

    [TOGGLE_NEED_GOTO_HOME]: (state, action) => {
        //console.log("TOGGLE_NEED_GOTO_HOME - ", action.payload);

        if (action.payload !== undefined && action.payload !== null) {
            const isNeedGoToHome = action.payload;

            if (isNeedGoToHome) {
                return {
                    ...state,
                    isNeedGoToHome                      : true,
                    isInvalidBroadcastKeyModalOpened    : true,
                    alertKind                           : ConstData.ALERT_KIND.DANGER,
                    alertMessage                        : i18n.t("잘못된_경로", { ns: "live" })
                }
            } else {
                return {
                    ...state,
                    isNeedGoToHome                      : false
                }
            }
        } else {
            console.log("TOGGLE_NEED_GOTO_HOME - ", action.payload);

            return {
                ...state
            }
        }
    },

    [TOGGLE_INVALID_BROADCAST_KEY_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;
        //console.log("TOGGLE_INVALID_BROADCAST_KEY_MODAL - ", action.payload);

        if (isModalOpened) {
            return {
                ...state,
                isInvalidBroadcastKeyModalOpened    : true,
                alertKind                           : state.alertKind,
                alertMessage                        : state.alertMessage
            }
        } else {
            return {
                ...state,
                isInvalidBroadcastKeyModalOpened    : false,
                alertKind                           : ConstData.ALERT_KIND.NONE,
                alertMessage                        : ""
            }
        }
    },

    [TOGGLE_LIVE_GUEST_ALERT_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;
        //console.log("TOGGLE_LIVE_GUEST_ALERT_MODAL - ", action.payload);

        return {
            ...state,
            isLiveGuestAlertModalOpened    : isModalOpened
        }
    },

    [TOGGLE_LIVE_MEDIA_ALERT_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;
        //console.log("TOGGLE_LIVE_MEDIA_ALERT_MODAL - ", action.payload);

        return {
            ...state,
            isLiveMediaAlertModalOpened     : isModalOpened
        }
    },

    [TOGGLE_LIVE_MIXED_ALERT_MODAL]: (state, action) => {
        const { isModalOpened } = action.payload;
        //console.log("TOGGLE_LIVE_MIXED_ALERT_MODAL - ", action.payload);

        return {
            ...state,
            isLiveMixedAlertModalOpened     : isModalOpened
        }
    },

    [CLOSE_MODAL_BROADCAST_LIVE]: (state, action) => {
        console.log("CLOSE_MODAL_BROADCAST_LIVE - ", action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveInfo                    : {},
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
            alertKind                           : ConstData.ALERT_KIND.NONE,
            alertMessage                        : ""
        }
    },

    [CLOSE_MODAL_LIVE_ALERT]: (state, action) => {
        //console.log("CLOSE_MODAL_LIVE_ALERT - ");

        return {
            ...state,
            isLiveAlertModalOpened              : false,
            isLiveAlertFinishModalOpened        : false,
            isLiveAlertRemoveModalOpened        : false,
            isLiveAlertModalRemoveMemberOpened  : false,
            isLiveAlertModalMemberLimitOpened   : false,
            isLiveAlertModalExpiredPassOpened   : false,
            closedPerformLiveInfo               : {
                liveName                        : "",
                teacher_info                    : {
                    userSeq                     : -1,
                    userNickname                : "",
                    userProfile                 : "",
                    profileImgUrl               : ""
                }
            },
            modalAlertLiveInfo                  : {
                liveSeq                         : -1,
                liveName                        : ""
            }
        }
    },

    [CLOSE_MODAL_ERROR_ALERT]: (state, action) => {
        //console.log("CLOSE_MODAL_ERROR_ALERT - ");

        return {
            ...state,
            isErrorAlertModalOpened : false,
            alertKind               : ConstData.ALERT_KIND.NONE,
            alertMessage            : ""
        }
    },

    [CLOSE_MODAL_WATCH_LIVE]: (state, action) => {
        //console.log("CLOSE_MODAL_WATCH_LIVE - ");

        return {
            ...state,
            isLiveMemberModalOpened : false
        }
    },

    [TOGGLE_SEARCH_BROADCAST_LIVE_LIST]: (state, action) => {
        //console.log("TOGGLE_SEARCH_BROADCAST_LIVE_LIST - ", action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            isSearchLiveListModalOpened         : !state.isSearchLiveListModalOpened,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_NOTIFY_LIVE_BROADCAST]: (state, action) => {
        //console.log("TOGGLE_NOTIFY_LIVE_BROADCAST - ", action.payload);

        return {
            ...state,             
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : !state.isNotifyLiveBroadcastModalOpened,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
            alertKind                           : ConstData.ALERT_KIND.NONE,
            alertMessage                        : ""
        }            
    },
    
    [TOGGLE_SEND_MIXED_CLASS_LINK]: (state, action) => {
        //console.log("TOGGLE_SEND_MIXED_CLASS_LINK - ", action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : !state.isSendMixedClassLinkModalOpened,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
            alertKind                           : ConstData.ALERT_KIND.NONE,
            alertMessage                        : ""
        }
    },

    [TOGGLE_CREATE_BROADCAST_LIVE]: (state, action) => {
        //console.log("TOGGLE_CREATE_BROADCAST_LIVE - ", action.payload);

        return {
            ...state,
            createdLiveCode                     : "",                
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : !state.isCreateLiveModalOpened,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
            alertKind                           : ConstData.ALERT_KIND.NONE,
            alertMessage                        : ""
        }
    },

    [TOGGLE_MODAL_LIVE_ALERT]: (state, action) => {
        const { liveSeq, liveName } = action.payload;
        //console.log("TOGGLE_MODAL_LIVE_ALERT - ", action.payload);

        return {
            ...state,
            isLiveAlertModalExpiredPassOpened   : true,
            modalAlertLiveInfo                  : {
                liveSeq                         : liveSeq,
                liveName                        : liveName
            }
        }
    },

    [TOGGLE_SHOW_WATCH_LIVE_MEMBER]: (state, action) => {
        //console.log("TOGGLE_SHOW_WATCH_LIVE_MEMBER - ", action.payload);

        return {
            ...state,
            isLiveMemberModalOpened     : !state.isLiveMemberModalOpened
        }
    },

    [TOGGLE_SHOW_BROADCAST_LIVE_MEMBER]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("TOGGLE_SHOW_BROADCAST_LIVE_MEMBER - ", action.payload);

        return {
            ...state,
            selectedLiveInfo                    : state.list_live.find(live => live.liveSeq === liveSeq),
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : !state.isShowLiveMemberModalOpened,
            isConfigLiveListModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [CLOSE_MODAL_CONFIG_BROADCAST_LIVE]: (state, action) => {
        console.log("CLOSE_MODAL_CONFIG_BROADCAST_LIVE - ", action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveInfo                    : {},
            selectedLiveMemberInfo              : {},
            isLiveAlertModalMemberLimitOpened   : false,
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_CONFIG_BROADCAST_LIVE]: (state, action) => {
        console.log("TOGGLE_CONFIG_BROADCAST_LIVE - ", action.payload);

        return {
            ...state,
            selectedFunc                        : "NONE",
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : !state.isConfigLiveListModalOpened,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_CONFIG_LOCAL_BROADCAST_LIVE]: (state, action) => {
        const { liveSeq } = action.payload;
        console.log("TOGGLE_CONFIG_LOCAL_BROADCAST_LIVE - ", action.payload);

        const live_info = state.list_live.find(live => live.liveSeq === liveSeq);
        console.log("live_info => ", live_info);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveInfo                    : {
                ...live_info,
                // livePublicYn                    : state.livePublicYn
            },
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : state.isConfigLiveListModalOpened,
            isConfigLocalLiveModalOpened        : !state.isConfigLocalLiveModalOpened,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_EDIT_BROADCAST_LIVE]: (state, action) => {
        const { liveSeq } = action.payload;
        console.log("TOGGLE_EDIT_BROADCAST_LIVE - ", action.payload);

        if (ConstData.IS_LOCAL_VERSION) {
            const live_info = state.list_live.find(live => live.liveSeq === liveSeq);

            return {
                ...state,
                selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
                // selectedLiveInfo                 : state.list_live.find(live => live.liveSeq === liveSeq),
                selectedLiveInfo                    : {
                    ...live_info,
                    // livePublicYn                 : state.livePublicYn
                },
                isSearchLiveListModalOpened         : false,
                isCreateLiveModalOpened             : false,
                isSendMixedClassLinkModalOpened     : false,
                isNotifyLiveBroadcastModalOpened    : false,
                isOffAirLiveModalOpened             : false,
                isShowLiveMemberModalOpened         : false,
                isConfigLiveListModalOpened         : state.isConfigLiveListModalOpened, //false,
                isConfigLocalLiveModalOpened        : false, //!state.isConfigLocalLiveModalOpened,
                isEditLiveModalOpened               : !state.isEditLiveModalOpened,
                isEditLiveMemberModalOpened         : false,
                isRemoveLiveModalOpened             : false,
                isRemoveLiveMemberModalOpened       : false,
                isErrorAlertModalOpened             : false,
            }
        } else {
            return {
                ...state,
                selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
                selectedLiveInfo                    : state.list_live.find(live => live.liveSeq === liveSeq),
                isSearchLiveListModalOpened         : false,
                isCreateLiveModalOpened             : false,
                isSendMixedClassLinkModalOpened     : false,
                isNotifyLiveBroadcastModalOpened    : false,
                isOffAirLiveModalOpened             : false,
                isShowLiveMemberModalOpened         : false,
                isConfigLiveListModalOpened         : state.isConfigLiveListModalOpened,
                isConfigLocalLiveModalOpened        : false,
                isEditLiveModalOpened               : !state.isEditLiveModalOpened,
                isEditLiveMemberModalOpened         : false,
                isRemoveLiveModalOpened             : false,
                isRemoveLiveMemberModalOpened       : false,
                isErrorAlertModalOpened             : false,
            }
        }
    },

    [TOGGLE_EDIT_BROADCAST_LIVE_MEMBER]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("TOGGLE_EDIT_BROADCAST_LIVE_MEMBER - ", action.payload);

        return {
            ...state,
            selectedFunc                        : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveInfo                    : state.list_live.find(live => live.liveSeq === liveSeq),
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : state.isConfigLiveListModalOpened,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : !state.isEditLiveMemberModalOpened,
            isRemoveLiveModalOpened             : false,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_REMOVE_BROADCAST_LIVE]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("TOGGLE_REMOVE_BROADCAST_LIVE - ", action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveInfo                : state.list_live.find(live => live.liveSeq === liveSeq),
            isSearchLiveListModalOpened         : false,
            isCreateLiveModalOpened             : false,
            isSendMixedClassLinkModalOpened     : false,
            isNotifyLiveBroadcastModalOpened    : false,
            isOffAirLiveModalOpened             : false,
            isShowLiveMemberModalOpened         : false,
            isConfigLiveListModalOpened         : state.isConfigLiveListModalOpened,
            isConfigLocalLiveModalOpened        : false,
            isEditLiveModalOpened               : false,
            isEditLiveMemberModalOpened         : false,
            isRemoveLiveModalOpened             : !state.isRemoveLiveModalOpened,
            isRemoveLiveMemberModalOpened       : false,
            isErrorAlertModalOpened             : false,
        }
    },

    [TOGGLE_REMOVE_BROADCAST_LIVE_MEMBER]: (state, action) => {
        const { memberInfo } = action.payload;
        //console.log("TOGGLE_REMOVE_BROADCAST_LIVE_MEMBER - ", action.payload);

        return {
            ...state,
            selectedFunc                    : action.payload === undefined ? "NONE" : action.payload,
            selectedLiveMemberInfo          : memberInfo,
            isRemoveLiveMemberModalOpened   : !state.isRemoveLiveMemberModalOpened
        }
    },

    [TOGGLE_OFFAIR_BROADCAST_LIVE]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("TOGGLE_OFFAIR_BROADCAST_LIVE - ", action.payload);

        if (liveSeq > -1) {
            return produce(state, draft => {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    draft.selectedLiveInfo = live_info;
                    draft.isOffAirLiveModalOpened = !draft.isOffAirLiveModalOpened
                }
            });
        } else {
            return {
                ...state,
                isOffAirLiveModalOpened         : !state.isOffAirLiveModalOpened
            }
        }
    },

    [TOGGLE_LIVE_PULL_SCREEN_ALERT]: (state, action) => {
        const { isModalOpened } = action.payload;
        //console.log("TOGGLE_LIVE_PULL_SCREEN_ALERT - ", action.payload);

        return {
            ...state,
            isLivePullScreenAlertModalOpened    : isModalOpened
        }
    },

    [TOGGLE_CHATTING_LOCK]: (state, action) => {
        const { kind, isTouchScreenLock, userSeq, userNickname } = action.payload;
        console.log("TOGGLE_CHATTING_LOCK - ", action.payload);

        let status = false;

        if (kind === 'chattingLock') {
            if (state.isChattingLock) {
                callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'chattingUnlock', {
                    kind: 'live',
                    action: 'chatting-lock',
                    information: { userSeq, isLiveChattingLocked: status, liveSeq: -1 },
                    userNickname
                });
            } else {
                status = true;

                callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'chattingLock', {
                    kind: 'live',
                    action: 'chatting-lock',
                    information: { userSeq, isLiveChattingLocked: status, liveSeq: -1 },
                    userNickname
                });
            }
        } else {
            if (isTouchScreenLock) {
                callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'chattingUnlock', {
                    kind: 'live',
                    action: 'chatting-lock',
                    information: { userSeq, isLiveChattingLocked: status, liveSeq: -1 },
                    userNickname
                });
            } else {
                status = true;

                callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'chattingLock', {
                    kind: 'live',
                    action: 'chatting-lock',
                    information: { userSeq, isLiveChattingLocked: status, liveSeq: -1 },
                    userNickname
                });
            }
        }

        return {
            ...state,
            isChattingLock  : status
        }
    },

    [TOGGLE_TOUCH_SCREEN_LOCK]: (state, action) => {
        const { userSeq, userNickname } = action.payload;
        console.log("TOGGLE_TOUCH_SCREEN_LOCK - ", action.payload);

        if (state.isTouchScreenLock) {
            callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'touchScreenUnLock', {
                kind: 'android',
                action: 'ct_TouchScreenUnLock',
                information: { tokenCmd: enTokenCMD.NULL, userSeq, liveSeq: -1, isTouchScreenLocked: false },
                userNickname
            });
        } else {
            callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'touchScreenLock', {
                kind: 'android',
                action: 'ct_TouchScreenLock',
                information: { tokenCmd: enTokenCMD.NULL, userSeq, liveSeq: -1, isTouchScreenLocked: true },
                userNickname
            });
        }

        return {
            ...state,
            isTouchScreenLock        : !state.isTouchScreenLock
        }
    },

    [FALSE_TO_INIT_TIMER]: (state, action) => {
        //console.log("FALSE_TO_INIT_TIMER - ");

        return {
            ...state,
            isNeedInitTimer    : false
        }
    },

    /**********************************************************/
    ///////////////////////// 페이지 처리 /////////////////////////
    /**********************************************************/

    [SET_ALERT_MESSAGE]: (state, action) => {
        //console.log("SET_ALERT_MESSAGE - ", action.message);

        if (action.message !== "") {
            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : action.message
            }
        } else {
            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.NONE,
                alertMessage        : ""
            }
        }
    },

    /**********************************************************/
    /////////////////////// Mixed Class ////////////////////////
    /**********************************************************/
    [REQUEST_UPDATE_MIXED_CLASS_EXIST_YN]: (state, action) => {
        const { liveSeq, userSeq, data } = action.payload;
        console.log("REQUEST_UPDATE_MIXED_CLASS_EXIST_YN - ", action.payload);
    
        if (data !== undefined && data !== null) { // local - mixed class live
            const { status, serverUserSeq } = data;

            /* if (status === "Y") {
                console.log("callEoForCreateMixedClass call");
                callEoForCreateMixedClass(serverUserSeq, userSeq, liveSeq);
            } else if (status === "N") {
                console.log("callEoForDeleteMixedClass call");
                callEoForDeleteMixedClass(userSeq, liveSeq);
            } else {
                console.log("status is ", status);
            } */

            if (status) {
                console.log("callEoForCreateMixedClass call");
                callEoForCreateMixedClass(serverUserSeq, userSeq, liveSeq);
            } else{
                console.log("callEoForDeleteMixedClass call");
                callEoForDeleteMixedClass(userSeq, liveSeq);
            }
        } else {
            console.log("data is null or undefined");
        }

        return {
            ...state,
        }
    },

    [UPDATE_MIXED_CLASS_EXIST_YN_RESULT]: (state, action) => { // create/delete mixed class 실패했을 경우
        const { kind, isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_EXIST_YN_RESULT - ", action.payload);

        if (isSuccessed) {
            // const { isCreateSuccess } = action.payload;
            const { errMsg } = action.payload;

            if (errMsg && errMsg !== "") {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : errMsg + " [Code.LV470.7]"
                }
            } else {
                // const { kind } = action.payload;

                if (kind === "create") {
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : i18n.t("믹스드_클래스_생성_실패", { ns: "live" }) + " [Code.LV471.6]",
                    }
                } else {
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : i18n.t("믹스드_클래스_삭제_실패", { ns: "live" }) + " [Code.LV472.2]",
                    }
                }
            }
        } else {
            let message = "";

            if (kind === "create") {
                message = i18n.t("믹스드_클래스_생성_요청_실패", { ns: "live" });
            } else {
                message = i18n.t("믹스드_클래스_삭제_요청_실패", { ns: "live" });
            }

            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : message + i18n.t("믹스드_클래스_요청_실패", { ns: "live" }) + " [Code.LV473.9]" 
            }
        }
    },

    [UPDATE_MIXED_CLASS_INFO_FOR_LIVE_SUCCESS]: (state, action) => { // create/delete mixed class 성공했을 경우
        const { localLiveSeq, mixedClassExistYn, mixedClassSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_INFO_FOR_LIVE - ", action.payload);
        console.log("action.parms - ", action.parms);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === localLiveSeq);
                    if (live_info) {
                        live_info.mixedClassExistYn = mixedClassExistYn;
                        live_info.mixedClassSeq = mixedClassSeq;

                        if (mixedClassExistYn === "Y") {
                            let mixedClassUrl = ConstData.CLOUD_HOST_ADD + "/mixed_login/" + mixedClassSeq;
                            console.log(mixedClassUrl);
                            live_info.mixedClassUrl = mixedClassUrl;
                        } else {
                            live_info.mixedClassUrl = null;
                        }
                    }

                    if (draft.selectedLiveInfo && draft.selectedLiveInfo.liveSeq === localLiveSeq) {
                        draft.selectedLiveInfo.mixedClassExistYn = mixedClassExistYn;
                        draft.selectedLiveInfo.mixedClassSeq  = mixedClassSeq;

                        if (mixedClassExistYn === "Y") {
                            let mixedClassUrl = ConstData.CLOUD_HOST_ADD + "/mixed_login/" + mixedClassSeq;
                            console.log(mixedClassUrl);
                            draft.selectedLiveInfo.mixedClassUrl = mixedClassUrl;
                        } else {
                            draft.selectedLiveInfo.mixedClassUrl = null;
                        }
                    }

                    draft.alertKind = ConstData.ALERT_KIND.NONE;
                    draft.alertMessage = "";
                });
            } else {
                if (mixedClassExistYn === "Y") {
                    console.log("mixedClassExistYn === Y");
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : i18n.t("믹스드_클래스_생성_실패", { ns: "live" }) + " [Code.LV478.3]",
                    }
                } else {
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : i18n.t("믹스드_클래스_삭제_실패", { ns: "live" }) + " [Code.LV478.9]",
                    }
                }
            }
        } else  { // mixedClass create or delete failed
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : i18n.t("믹스드_클래스_작업_요청_실패", { ns: "live" }) + " [Code.LV479.8]"
            }
        }
    },

    /* [UPDATE_MIXED_CLASS_EXIST_YN_SUCCESS]: (state, action) => { // create/delete mixed class 성공했을 경우
        const { localLiveSeq, mixedClassExistYn, mixedClassSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_EXIST_YN - ", action.payload);
        console.log("action.parms - ", action.parms);

        if (isSuccessed) {
            console.log("isSuccessed - true");

            const { isUpdateSuccess } = action.payload;
            console.log("isUpdateSuccess - ");

            if (isUpdateSuccess) {
                console.log("isUpdateSuccess - true");
                if (mixedClassExistYn === "Y") {
                    console.log("mixedClassExistYn - Y");
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === localLiveSeq);
                        if (live_info) {
                            console.log("live_info - liveSeq === localLiveSeq === " , localLiveSeq);
                            live_info.mixedClassExistYn = mixedClassExistYn;
                            console.log("live_info.mixedClassExistYn = mixedClassExistYn 아마도 Y ", mixedClassExistYn);
                            live_info.mixedClassSeq = mixedClassSeq;
                            console.log("live_info.mixedClassSeq = mixedClassSeq", mixedClassSeq);
                        }

                        if (draft.selectedLiveInfo && draft.selectedLiveInfo.liveSeq === localLiveSeq) {
                            draft.selectedLiveInfo.mixedClassExistYn = mixedClassExistYn;
                            console.log("draft.mixedClassExistYn = mixedClassExistYn 아마도 Y ", mixedClassExistYn);
                            draft.selectedLiveInfo.mixedClassSeq  = mixedClassSeq;
                            console.log("draft.mixedClassSeq = mixedClassSeq", mixedClassSeq);
                        }

                        //draft.isConfigLocalLiveModalOpened = false;
                    });
                } else {
                    console.log("mixedClassExistYn - N (else)");
                    return produce(state, draft => {
                        const live_info = draft.list_live.find(info => info.liveSeq === localLiveSeq);
                        if (live_info) {
                            console.log("live_info - liveSeq === localLiveSeq === " , localLiveSeq);
                            live_info.mixedClassExistYn = mixedClassExistYn;
                            console.log("live_info.mixedClassExistYn = mixedClassExistYn 아마도 N ", mixedClassExistYn);
                            live_info.mixedClassSeq = mixedClassSeq;
                            console.log("live_info.mixedClassSeq = mixedClassSeq", mixedClassSeq);
                        }

                        if (draft.selectedLiveInfo && draft.selectedLiveInfo.liveSeq === localLiveSeq) {
                            draft.selectedLiveInfo.mixedClassExistYn = mixedClassExistYn;
                            console.log("draft.selectedLiveInfo.mixedClassExistYn = mixedClassExistYn 아마도 N ", mixedClassExistYn);
                            draft.selectedLiveInfo.mixedClassSeq = mixedClassSeq;
                            console.log("draft.selectedLiveInfo.mixedClassSeq = mixedClassSeq", mixedClassSeq);
                        }

                        //draft.isConfigLocalLiveModalOpened = false;
                    });
                }
            } else {
                console.log("isUpdateSuccess - false");
                if (mixedClassExistYn === "Y") {
                    console.log("mixedClassExistYn === Y");
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : "믹스드 클래스 생성에 실패하였습니다. [Code.LV486.7]",
                    }
                } else {
                    return {
                        ...state,
                        alertKind       : ConstData.ALERT_KIND.DANGER,
                        alertMessage    : "믹스드 클래스 삭제에 실패하였습니다. [Code.LV487.3]",
                    }
                }
            }
        } else  { // mixedClass create or delete failed
            return {
                ...state,
                alertKind       : ConstData.ALERT_KIND.DANGER,
                alertMessage    : "오류가 발생하여 요청하신 작업을 완료하지 못했습니다.<br>다시 시도해 주세요."
                                  + "<br>같은 현상이 반복될 경우<br>서버 관리자에게 문의하시기 바랍니다. [Code.LV488.2]"
            }
        }
    }, */

    [REQUEST_UPDATE_MIXED_CLASS_STATUS]: (state, action) => {
        const { kind, serverUserSeq, userSeq, liveSeq } = action.payload;
        console.log("REQUEST_UPDATE_MIXED_CLASS_STATUS - ", action.payload);

        callEoForUpdateMixedClassStatus(kind, serverUserSeq, userSeq, liveSeq);

        return {
            ...state
        }
    },

    [UPDATE_MIXED_CLASS_STATUS_RESULT]: (state, action) => {
        const { kind, isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_STATUS_RESULT - ", action.payload);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                const { localLiveSEQ, serverLiveSEQ } = action.payload;

                return produce(state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === localLiveSEQ);
                    if (live_info) {
                        if (kind === "startResult") {
                            live_info.mixedClassStatus = "Y";
                        } else {
                            live_info.mixedClassStatus = "N";
                        }
                        /* if (serverLiveSEQ > 0) {
                            live_info.mixedClassStatus = "Y";
                        } else {
                            live_info.mixedClassStatus = "N";
                        } */
                    }

                    draft.alertKind = ConstData.ALERT_KIND.NONE;
                    draft.alertMessage = "";
                });
            } else {
                const { isCanStart } = action.payload;

                if (!isCanStart) {
                    const { errMsg } = action.payload;

                    if (errMsg && errMsg !== "") {
                        return {
                            ...state,
                            isErrorAlertModalOpened     : true,
                            alertKind                   : ConstData.ALERT_KIND.DANGER,
                            alertMessage                : errMsg + " [Code.LV493.2]"
                        }
                    } else {
                        let message = "";

                        if (kind === "startResult") {
                            message = i18n.t("믹스드_클래스_시작_실패_클라우드", { ns: "live" }) + " [Code.LV493.8]";
                        } else {
                            message = i18n.t("믹스드_클래스_멈춤_요청_실패", { ns: "live" }) + " [Code.LV494.0]";
                        }

                        return {
                            ...state,
                            isErrorAlertModalOpened     : true,
                            alertKind                   : ConstData.ALERT_KIND.DANGER,
                            alertMessage                : message
                        }
                    }
                } else {
                    let message = "";

                    if (kind === "startResult") {
                        message = i18n.t("믹스드_클래스_시작_실패", { ns: "live" });
                    } else {
                        message = i18n.t("믹스드_클래스_멈춤_실패", { ns: "live" });
                    }

                    return {
                        ...state,
                        isErrorAlertModalOpened     : true,
                        alertKind                   : ConstData.ALERT_KIND.DANGER,
                        alertMessage                : message + i18n.t("믹스드_클래스_요청_실패", { ns: "live" }) + " [Code.LV496.3]"
                    }
                }
            }
        } else {
            let message = "";

            if (kind === "startResult") {
                message = i18n.t("믹스드_클래스_시작_요청_실패", { ns: "live" });
            } else {
                message = i18n.t("믹스드_클래스_멈춤_요청_실패", { ns: "live" });
            }

            return {
                ...state,
                isErrorAlertModalOpened     : true,
                alertKind                   : ConstData.ALERT_KIND.DANGER,
                alertMessage                : message + i18n.t("믹스드_클래스_요청_실패", { ns: "live" }) + " [Code.LV498.1]"
            }
        }
    },

    [GET_MIXED_CLASS_MAIL_INFO_SUCCESS]: (state, action) => {
        const { localLiveSeq, liveName, userNickname } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("GET_MIXED_CLASS_MAIL_INFO_SUCCESS - ", action.payload, action.parms);

        if (isSuccessed) {
            const { list_member } = action.payload;

            if (list_member && list_member.length > 0) {
                let clist_member = list_member.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userNickname    : row.USER_NICKNAME,
                    userEmail       : row.USER_EMAIL
                }));

                callEoForSendMixedClassMailLink(localLiveSeq, liveName, userNickname, clist_member);
            }

            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.NONE,
                alertMessage        : i18n.t("메일_전송_중", { ns: "live" })
            }
        } else {
            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : i18n.t("메일_전송_실패", { ns: "live" }) + " [Code.LV501.3]",
            }
        }
    },

    [SEND_MIXED_CLASS_MAIL_RESULT]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("SEND_MIXED_CLASS_MAIL_RESULT - ", action.payload);

        if (isSuccessed) {
            const { isNotifySuccess } = action.payload;

            if (isNotifySuccess) {
                return {
                    ...state,
                    selectedFunc                    : "NONE",
                    isSendMixedClassLinkModalOpened : false,
                    alertKind                       : ConstData.ALERT_KIND.NONE,
                    alertMessage                    : ""
                }
            } else {
                return {
                    ...state,
                    alertKind       : ConstData.ALERT_KIND.DANGER,
                    alertMessage    : i18n.t("메일_전송_실패", { ns: "live" }) + " [Code.LV503.7]",
                }
            }
        } else {
            return {
                ...state,
                alertKind           : ConstData.ALERT_KIND.DANGER,
                alertMessage        : i18n.t("메일_전송_실패", { ns: "live" }) + " [Code.LV504.4]",
            }
        }
    },

    [UPDATE_MIXED_CLASS_MEMBER_LIST]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_MEMBER_LIST - ", action.payload);

        if (isSuccessed) {
            const { live_info, list_member } = action.payload;

            let liveInfo = {
                liveSeq : -1,
                liveName : ""
            };

            let list_qrMember = [];
            let list_qrLoginMember = [];

            if (live_info) {
                liveInfo = {
                    liveSeq : live_info.LIVE_SEQ,
                    liveName : live_info.LIVE_NAME
                };

                let teacher_info = {
                    userSeq         : live_info.LIVE_TEACHER_SEQ,
                    userID          : live_info.USER_ID,
                    userNickname    : live_info.USER_NICKNAME
                };

                liveInfo.teacher_info = teacher_info;
                // list_qrMember.push(teacher_info);
            }

            if (list_member) {
                list_qrLoginMember = list_qrMember.concat(list_member.map(row => ({
                    userSeq         : row.USER_SEQ,
                    userID          : row.USER_ID,
                    userNickname    : row.USER_NICKNAME,
                    userJoinStatus  : row.ENTRY_YN,
                })));
            }

            return {
                ...state,
                mixedClassInfo      : {
                    ...state.mixedClassInfo,
                    liveSeq         : liveInfo.liveSeq,
                    liveName        : liveInfo.liveName,
                    teacher_info    : liveInfo.teacher_info,
                    list_member     : list_qrLoginMember
                }
            }
        } else {
            console.log("GET_USER_LIST_BY_QR_LOGIN - isSuccessed => ", isSuccessed);

            return  {
                ...state,
                isInvalidRoute : true,
                mixedClassInfo      : {
                    ...state.mixedClassInfo,
                    list_member     : []
                }
            }
        }
    },

    /* [UPDATE_MIXED_CLASS_SEQ_SUCCESS]: (state, action) => {
        const { localLiveSeq } = action.parms;
        const { mixedClassSeq } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("UPDATE_MIXED_CLASS_SEQ_SUCCESS - ", action.parms, action.payload);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                return produce (state, draft => {
                    const live_info = draft.list_live.find(info => info.liveSeq === localLiveSeq);
                    if (live_info) {
                        if (live_info.mixedClassExistYn === "Y") {
                            let mixedClassUrl = ConstData.CLOUD_HOST_ADD + "/mixed_login/" + mixedClassSeq;
                            console.log(mixedClassUrl);
                            live_info.mixedClassUrl = mixedClassUrl;
                        } else {
                            live_info.mixedClassUrl = null;
                        }
                    }

                    if (draft.selectedLiveInfo && draft.selectedLiveInfo.liveSeq === localLiveSeq) {
                        if (draft.selectedLiveInfo.mixedClassExistYn === "Y") {
                            let mixedClassUrl = ConstData.CLOUD_HOST_ADD + "/mixed_login/" + mixedClassSeq;
                            console.log(mixedClassUrl);
                            draft.selectedLiveInfo.mixedClassUrl = mixedClassUrl;
                        }
                    }
                });
            } else {
                return {
                    ...state,
                }
            } 
        } else {
            return {
                ...state,
            }
        }
    }, */

    [LIVE_SERVER_USER_AUTH_RESET_RESULT]: (state, action) => {
        const { isSuccessed } = action.payload;
        console.log("LIVE_SERVER_USER_AUTH_RESET_RESULT - ", action.payload);

        if (isSuccessed) {
            const { isUpdateSuccess } = action.payload;

            if (isUpdateSuccess) {
                const { isLiveUpdateSuccess } = action.payload;

                if (isLiveUpdateSuccess) {
                    return produce (state, draft => {
                        if (draft.list_live) {
                            draft.list_live.forEach(live_info => {
                                if (live_info) {
                                    if (live_info.mixedClassExistYn === "Y") {
                                        live_info.mixedClassExistYn = "N";
                                    }

                                    if (live_info.mixedClassSeq >= 0) {
                                        live_info.mixedClassSeq = -1;
                                    }
                                }
                            });
                        }
                    });
                } else {
                    return {
                        ...state
                    }
                }
            } else {
                return {
                    ...state
                }
            }
        } else {
            return {
                ...state
            }
        }
    },

    /**********************************************************/
    //////////////////// Local RelayAction /////////////////////
    /**********************************************************/

    [UPDATE_LOCAL_LIVE_PUBLIC_YN]: (state, action) => {
        const { liveSeq, userSeq, publicYn } = action.payload;
        console.log("UPDATE_LOCAL_LIVE_PUBLIC_YN - ", action.payload);
        callEoForSendMediaScreen("relayAction", { action: "liveUpdatePublicYN", localLiveSEQ: liveSeq, localUserSEQ: userSeq, publicYn: publicYn });

        /* return {
            ...state,
            livePublicYn: publicYn,
        } */

        return produce (state, draft => {
            if (draft.selectedLiveInfo && draft.selectedLiveInfo.liveSeq === liveSeq) {
                draft.selectedLiveInfo.livePublicYn = publicYn;
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.livePublicYn = publicYn;
            }

            draft.publicYn = publicYn;
            draft.alertKind = ConstData.ALERT_KIND.NONE;
            draft.alertMessage = "";
        });
    },

    [RCV_RESULTS_FOR_LOCAL_LIVE_ON_FROM_CLOUD]: (state, action) => {
        console.log("RCV_RESULTS_FOR_LOCAL_LIVE_ON_FROM_CLOUD - ", action.payload);

        return {
            ...state,
        }
    },

    [RCV_RESULTS_FOR_LOCAL_LIVE_OFF_FROM_CLOUD]: (state, action) => {
        console.log("RCV_RESULTS_FOR_LOCAL_LIVE_OFF_FROM_CLOUD - ", action.payload);

        return {
            ...state,
        }
    },

    [RCV_RESULTS_FOR_UPDATE_LOCAL_LIVE_PUBLIC_YN_FROM_CLOUD]: (state, action) => {
        console.log("RCV_RESULTS_FOR_UPDATE_LOCAL_LIVE_PUBLIC_YN_FROM_CLOUD - ", action.payload);

        return {
            ...state,
        }
    },

    /**********************************************************/
    ///////////////////////// SMART TV /////////////////////////
    /**********************************************************/

    [LIVE_WATCHING_INIT_FOR_SMART_TV]: (state, action) => {
        const { kind } = action.parms;
        const { isSuccessed } = action.payload;
        console.log("LIVE_WATCHING_INIT_FOR_SMART_TV - ", action.payload, action.parms);

        if (isSuccessed) { // 데이터를 가져온 경우
            const { isLoginSuccess } = action.payload;

            if(isLoginSuccess) {
                const { live_info } = action.payload;

                if (live_info) {
                    let list_member = [];

                    if (kind && kind === "group-smart-tv-login") {
                        list_member = action.parms.list_member;
                    }

                    return {
                        ...state,
                        smartTVLiveInfo         : {
                            liveSeq             : live_info.LIVE_SEQ,
                            liveName            : live_info.LIVE_NAME,
                            liveStatus          : "Y",
                            teacher_info        : {
                                userSeq         : live_info.LIVE_TEACHER_SEQ,
                                userNickname    : live_info.USER_NICKNAME,
                                userProfile     : live_info.USER_PROFILE,
                                profileImgUrl   : live_info.USER_PROFILE_IMG
                            },
                            groupLimit          : live_info.GROUP_LIMIT_NUM ? live_info.GROUP_LIMIT_NUM : 4,
                            list_member         : list_member
                        }
                    }
                } else {
                    console.log("LIVE_WATCHING_INIT_FOR_SMART_TV - live_info => ", live_info);

                    return {
                        ...state
                    }
                }
                /* const { live_info } = action.payload;

                if (live_info) {
                    return {
                        ...state,
                        smartTVLiveInfo         : {
                            liveSeq             : live_info.LIVE_SEQ,
                            liveName            : live_info.LIVE_NAME,
                            liveStatus          : "Y",
                            teacher_info        : {
                                userSeq         : live_info.LIVE_TEACHER_SEQ,
                                userNickname    : live_info.USER_NICKNAME,
                                userProfile     : live_info.USER_PROFILE,
                                profileImgUrl   : live_info.USER_PROFILE_IMG
                            },
                            groupLimit          : live_info.GROUP_LIMIT_NUM,
                            list_member         : []
                        }
                    }
                } else {
                    console.log("LIVE_WATCHING_INIT_FOR_SMART_TV - live_info => ", live_info);

                    return {
                        ...state
                    }
                } */

                /* if (kind && kind === "group-smart-tv-login") {
                    const { live_info } = action.payload;

                    if (live_info) {
                        return {
                            ...state,
                            smartTVLiveInfo         : {
                                liveSeq             : live_info.LIVE_SEQ,
                                liveName            : live_info.LIVE_NAME,
                                liveStatus          : "Y",
                                teacher_info        : {
                                    userSeq         : live_info.LIVE_TEACHER_SEQ,
                                    userNickname    : live_info.USER_NICKNAME,
                                    userProfile     : live_info.USER_PROFILE,
                                    profileImgUrl   : live_info.USER_PROFILE_IMG
                                },
                                groupLimit          : live_info.GROUP_LIMIT_NUM ? live_info.GROUP_LIMIT_NUM : 4,
                                list_member         : action.parms.list_member
                            }
                        }
                    } else {
                        console.log("LIVE_WATCHING_INIT_FOR_SMART_TV - live_info => ", live_info);
    
                        return {
                            ...state
                        }
                    }
                } else {
                    const { live_info } = action.payload;

                    if (live_info) {
                        return {
                            ...state,
                            smartTVLiveInfo         : {
                                liveSeq             : live_info.LIVE_SEQ,
                                liveName            : live_info.LIVE_NAME,
                                liveStatus          : "Y",
                                teacher_info        : {
                                    userSeq         : live_info.LIVE_TEACHER_SEQ,
                                    userNickname    : live_info.USER_NICKNAME,
                                    userProfile     : live_info.USER_PROFILE,
                                    profileImgUrl   : live_info.USER_PROFILE_IMG
                                },
                                groupLimit          : live_info.GROUP_LIMIT_NUM ? live_info.GROUP_LIMIT_NUM : 4,
                                list_member         : []
                            }
                        }
                    } else {
                        console.log("LIVE_WATCHING_INIT_FOR_SMART_TV - live_info => ", live_info);
    
                        return {
                            ...state
                        }
                    }
                } */
            } else {
                return {
                    ...state
                }
            }
        } else { // 데이터를 못가져온 경우
            return {
                ...state
            }
        }
    },

    [LIVE_WATCHING_STATE_INIT_FOR_SMART_TV]: (state, action) => {
        //console.log("LIVE_WATCHING_STATE_INIT_FOR_SMART_TV - ");

        return {
            ...state,
            isRequestCloseModal             : false,
            isSmartTVAlertModalOpened       : false,
            smartTVLiveInfo                 : {
                liveSeq                     : -1,
                liveName                    : null,
                liveStatus                  : "N",
                teacher_info                : {
                    userSeq                 : -1,
                    userNickname            : "",
                    userProfile             : "",
                    profileImgUrl           : ""
                },
                groupLimit                  : 4,
                list_member                 : []
            },
            smartTVBindInfo                 : {
                bindKeyArr                  : [],
                startSeqOfSmartTV           : -1,
                serverGateWay               : ""
            }
        }
    },

    [REQUEST_CLOSE_MODAL]: (state, action) => {
        //const { userSeq, userNickname } = action.payload;
        //console.log("REQUEST_CLOSE_MODAL - ");

        return {
            ...state,
            isRequestCloseModal     : true
        }
    },

    [FALSE_TO_REQUEST_CLOSE_MODAL]: (state, action) => {
        //console.log("FALSE_TO_REQUEST_CLOSE_MODAL - ");

        return {
            ...state,
            isRequestCloseModal     : false
        }
    },

    [UPDATE_SCREEN_SEQ]: (state, action) => {
        const { smartTVInfo } = action.payload;
        const { liveSeq, startSeqOfSmartTV, binders } = smartTVInfo;
        //console.log("UPDATE_SCREEN_SEQ - ");

        return produce(state, draft => {
            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.startSeqOfSmartTV = startSeqOfSmartTV;
                live_info.binders = binders ? binders : null;
            }
        });
    },

    [FALSE_TO_SMART_TV_ALERT_MODAL]: (state, action) => {
        console.log("FALSE_TO_SMART_TV_ALERT_MODAL - ");

        return {
            ...state,
            isSmartTVAlertModalOpened   : false
        }
    },

    /**********************************************************/
    /////////////////////////// RBMQ ///////////////////////////
    /**********************************************************/

    [MQ_LIVE_JOIN]: (state, action) => {
        const { liveSeq, userSeq, user_info } = action.payload;
        console.log("MQ_LIVE_JOIN - ", action.payload);

        /*******************************************/
        ////////////// PENTALK TEACHER //////////////
        /*******************************************/
        let broadcastSeq = -1;
        const live_info = state.list_live.find(info => info.liveSeq === liveSeq);
        if (live_info) {
            if (live_info.liveStatus === "Y") {
                if (state.isChattingLock) {
                    let sendKey = getDefaultDirectKey(userSeq, 0);
                    callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'chattingLock', {
                        kind: 'live',
                        action: 'chatting-lock',
                        information: { liveSeq, userSeq: user_info.userSeq, isLiveChattingLocked: true },
                        userNickname: user_info.userNickname
                    }, sendKey);
                }

                if (state.isTouchScreenLock) {
                    let sendKey = getDefaultDirectKey(userSeq, 0); // for android app but remember web student
                    callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'touchScreenLock', {
                        kind: 'android',
                        action: 'ct_TouchScreenLock',
                        information: { tokenCmd: enTokenCMD.NULL, userSeq: user_info.userSeq, liveSeq, isTouchScreenLocked: true },
                        userNickname: user_info.userNickname
                    }, sendKey);

                    sendKey = getDefaultDirectKey(userSeq, 3); // for android app
                    callEoForSendMessage(enTokenCMD.ct_SendToWebJSonInfo, 'touchScreenLock', {
                        kind: 'android',
                        action: 'ct_TouchScreenLock',
                        information: { tokenCmd: enTokenCMD.NULL, userSeq: user_info.userSeq, liveSeq, isTouchScreenLocked: true },
                        userNickname: user_info.userNickname
                    }, sendKey);
                }
            }

            if (live_info.list_broadcast) {
                live_info.list_broadcast.forEach(brdcst_info => {
                    if (brdcst_info.list_guest) {
                        const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                        if (member_info) {
                            broadcastSeq = brdcst_info.broadcastSeq;
                        }
                    }
                });
            }
        }

        return produce(state, draft => {
            /*******************************************/
            //////////////// WEB STUDENT ////////////////
            /*******************************************/
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.performLiveInfo.list_member) {
                            const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "Y";
                                let nickname = member_info.userNickname;
                                if (nickname.length > 8) {
                                    nickname = nickname.substring(0, 6) + "...";
                                }
                                draft.nextPhaseData = nickname + i18n.t("클래스_참여_알림", { ns: "live" });
                            }
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    if (draft.performLiveInfo.list_member) {
                        const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info) {
                            member_info.userJoinStatus = "Y";
                            let nickname = member_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + "...";
                            }
                            draft.nextPhaseData = nickname + i18n.t("클래스_참여_알림", { ns: "live" });
                        }
                    }
                }
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                if (watchLive_info.list_member) {
                    const member_info = watchLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "Y";
                    }
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_member) {
                    const member_info = robotLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "Y";
                    }
                }
            }

            /*******************************************/
            ////////////// PENTALK TEACHER //////////////
            /*******************************************/
            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "Y";
                        let nickname = member_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + "...";
                        }
                        draft.nextPhaseData = nickname + i18n.t("클래스_참여_알림", { ns: "live" });
                    }
                }

                if (live_info.list_broadcast) {
                    console.log('broadcastSEQ is ', broadcastSeq);
                    live_info.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "Y";
                            }
                        }
                    });
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_member) {
                    const member_info = draft.openedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "Y";
                    }
                }

                if (draft.openedLiveInfo.list_broadcast) {
                    draft.openedLiveInfo.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "Y";
                            }
                        }
                    });
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_member) {
                    const member_info = draft.selectedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "Y";
                    }
                }

                if (draft.selectedLiveInfo.list_broadcast) {
                    draft.selectedLiveInfo.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "Y";
                            }
                        }
                    });
                }
            }
        });
    },

    [MQ_LIVE_EXIT]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_EXIT - ", action.payload);

        return produce(state, draft => {
            /*******************************************/
            //////////////// WEB STUDENT //////////////// 
            /*******************************************/
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.performLiveInfo.list_member) {
                            const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                                let nickname = member_info.userNickname;
                                if (nickname.length > 8) {
                                    nickname = nickname.substring(0, 6) + "...";
                                }
                                draft.nextPhaseData = nickname + i18n.t("클래스_퇴장_알림", { ns: "live" });
                            }
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    if (draft.performLiveInfo) {
                        if (draft.performLiveInfo.list_member) {
                            const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                                let nickname = member_info.userNickname;
                                if (nickname.length > 8) {
                                    nickname = nickname.substring(0, 6) + "...";
                                }
                                draft.nextPhaseData = nickname + i18n.t("클래스_퇴장_알림", { ns: "live" });
                            }
                        }
                    }
                }
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                if (watchLive_info.list_member) {
                    const member_info = watchLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "N";
                    }
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_member) {
                    const member_info = robotLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "N";
                    }
                }
            }

            /*******************************************/
            ////////////// PENTALK TEACHER ////////////// 
            /*******************************************/
            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "N";
                        let nickname = member_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + "...";
                        }
                        draft.nextPhaseData = nickname + i18n.t("클래스_퇴장_알림", { ns: "live" });
                    }
                }

                if (live_info.list_broadcast) {
                    live_info.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                            }
                        }
                    });
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_member) {
                    const member_info = draft.openedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "N";
                    }
                }

                if (draft.openedLiveInfo.list_broadcast) {
                    draft.openedLiveInfo.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                            }
                        }
                    });
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_member) {
                    const member_info = draft.selectedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userJoinStatus = "N";
                    }
                }

                if (draft.selectedLiveInfo.list_broadcast) {
                    draft.selectedLiveInfo.list_broadcast.forEach(brdcst_info => {
                        if (brdcst_info.list_guest) {
                            const member_info = brdcst_info.list_guest.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userJoinStatus = "N";
                            }
                        }
                    });
                }
            }
        });
    },

    /* [MQ_PERFORM_CHATROOM]: (state, action) => {
        const { roomSeq, teacherSeq, userSeq } = action.payload;
        console.log(`MQ_PERFORM_CHATROOM - roomSeq[${roomSeq}], teacherSeq[${teacherSeq}], userSeq[${userSeq}]`);

        return produce(state, draft => {
            if (draft.performLiveInfo.liveSeq !== -1) {
                if (roomSeq > -1 && roomSeq < 65535) {
                    draft.performLiveInfo.teamUpSeq = roomSeq;
                } else {
                    draft.performLiveInfo.teamUpSeq = -1;
                }
            }
        });
    }, */

    [MQ_LIVE_ON]: (state, action) => {
        const { liveSeq, userSeq, isLiveChattingLocked, isTouchScreenLocked } = action.payload;
        //console.log("MQ_LIVE_ON - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.liveStatus = "Y";
                        live_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                        live_info.selectStatus = "Y"; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = live_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = live_info.handsUpYN; // for handsup status ... by hjkim 20250110
                            draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus; // for hansup active ... by hjkim 20250110

                            if (draft.performLiveInfo.teacher_info) { // teacher_info 있는 경우....
                                let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                                draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                            } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                                draft.myHandsUpSendKey = getHandsUpSendKey(draft.mixedClassInfo.serverTeacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(draft.mixedClassInfo.serverTeacherSeq, userSeq);
                            }

                            if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                                draft.isLiveAlertModalOpened = false;
                                draft.isLiveAlertFinishModalOpened = false;
                                draft.isLivePullScreenAlertModalOpened = false;
                                draft.closedPerformLiveInfo = {
                                    liveName: "",
                                    teacher_info: {
                                        userSeq: -1,
                                        userNickname: "",
                                        userProfile: "",
                                        profileImgUrl: ""
                                    }
                                };
                            }

                            if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                            draft.isLiveChattingLocked = isLiveChattingLocked;
                            draft.isTouchScreenLocked = isTouchScreenLocked;
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.liveStatus = "Y";
                        watchLive_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                        watchLive_info.handsUpSelectYN = "Y"; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = watchLive_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN; // for handsup status ... by hjkim 20250110
                            draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN; // for hansup active ... by hjkim 20250110

                            if (draft.performLiveInfo.teacher_info) { // 참여 중인 클래스
                                let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                                draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                            } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                                draft.myHandsUpSendKey = getHandsUpSendKey(draft.mixedClassInfo.serverTeacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(draft.mixedClassInfo.serverTeacherSeq, userSeq);
                            }

                            if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                                draft.isLiveAlertModalOpened = false;
                                draft.isLiveAlertFinishModalOpened = false;
                                draft.isLivePullScreenAlertModalOpened = false;
                                draft.closedPerformLiveInfo = {
                                    liveName: "",
                                    teacher_info: {
                                        userSeq: -1,
                                        userNickname: "",
                                        userProfile: "",
                                        profileImgUrl: ""
                                    }
                                };
                            }

                            if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                            draft.isLiveChattingLocked = isLiveChattingLocked;
                            draft.isTouchScreenLocked = isTouchScreenLocked;
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.liveStatus = "Y";
                        robotLive_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                        robotLive_info.selectStatus = "Y"; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = robotLive_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN; // for handsup status ... by hjkim 20250110
                            draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus; // for hansup active ... by hjkim 20250110

                            if (draft.performLiveInfo.teacher_info) { // teacher_info 있는 경우....
                                let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                                draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                            } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                                draft.myHandsUpSendKey = getHandsUpSendKey(draft.mixedClassInfo.serverTeacherSeq, draft.mixedClassInfo.serverLiveSeq, userSeq);
                                draft.myHandsUpBindKeyArr = getHandsUpBindKey(draft.mixedClassInfo.serverTeacherSeq, userSeq);
                            }

                            if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                                draft.isLiveAlertModalOpened = false;
                                draft.isLiveAlertFinishModalOpened = false;
                                draft.isLivePullScreenAlertModalOpened = false;
                                draft.closedPerformLiveInfo = {
                                    liveName: "",
                                    teacher_info: {
                                        userSeq: -1,
                                        userNickname: "",
                                        userProfile: "",
                                        profileImgUrl: ""
                                    }
                                };
                            }

                            if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                            draft.isLiveChattingLocked = isLiveChattingLocked;
                            draft.isTouchScreenLocked = isTouchScreenLocked;
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.liveStatus = "Y";
                    live_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                    live_info.selectStatus = "Y"; // for hansup active ... by hjkim 20250110

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = live_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = live_info.handsUpYN; // for handsup status ... by hjkim 20250110
                        draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.teacher_info) { // teacher_info 있는 경우....
                            let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                            draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                        } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                            draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                        }

                        if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                            draft.isLiveAlertModalOpened = false;
                            draft.isLiveAlertFinishModalOpened = false;
                            draft.isLivePullScreenAlertModalOpened = false;
                            draft.closedPerformLiveInfo = {
                                liveName: "",
                                teacher_info: {
                                    userSeq: -1,
                                    userNickname: "",
                                    userProfile: "",
                                    profileImgUrl: ""
                                }
                            };
                        }

                        if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                        draft.isLiveChattingLocked = isLiveChattingLocked;
                        draft.isTouchScreenLocked = isTouchScreenLocked;
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.liveStatus = "Y";
                    watchLive_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                    watchLive_info.handsUpSelectYN = "Y"; // for hansup active ... by hjkim 20250110

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = watchLive_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN; // for handsup status ... by hjkim 20250110
                        draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.teacher_info) { // 멤버인 클래스
                            let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                            draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                        } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                            draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                        }

                        if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                            draft.isLiveAlertModalOpened = false;
                            draft.isLiveAlertFinishModalOpened = false;
                            draft.isLivePullScreenAlertModalOpened = false;
                            draft.closedPerformLiveInfo = {
                                liveName: "",
                                teacher_info: {
                                    userSeq: -1,
                                    userNickname: "",
                                    userProfile: "",
                                    profileImgUrl: ""
                                }
                            };
                        }

                        if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                        draft.isLiveChattingLocked = isLiveChattingLocked;
                        draft.isTouchScreenLocked = isTouchScreenLocked;
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.liveStatus = "Y";
                    robotLive_info.handsUpYN = "Y"; // for handsup status ... by hjkim 20250110
                    robotLive_info.selectStatus = "Y"; // for hansup active ... by hjkim 20250110

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = robotLive_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN; // for handsup status ... by hjkim 20250110
                        draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus; // for hansup active ... by hjkim 20250110

                        if (draft.performLiveInfo.teacher_info) { // teacher_info 있는 경우....
                            let teacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                            draft.myHandsUpSendKey = getHandsUpSendKey(teacherSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(teacherSeq, userSeq);
                        } else { // 내가 생성한 클래스에 Root로 조인하는 경우
                            draft.myHandsUpSendKey = getHandsUpSendKey(userSeq, liveSeq, userSeq);
                            draft.myHandsUpBindKeyArr = getHandsUpBindKey(userSeq, userSeq);
                        }

                        if (draft.isLiveAlertFinishModalOpened || draft.isLiveAlertModalOpened || draft.isLivePullScreenAlertModalOpened) {
                            draft.isLiveAlertModalOpened = false;
                            draft.isLiveAlertFinishModalOpened = false;
                            draft.isLivePullScreenAlertModalOpened = false;
                            draft.closedPerformLiveInfo = {
                                liveName: "",
                                teacher_info: {
                                    userSeq: -1,
                                    userNickname: "",
                                    userProfile: "",
                                    profileImgUrl: ""
                                }
                            };
                        }

                        if (isLiveChattingLocked) draft.isLiveMemberModalOpened = false;

                        draft.isLiveChattingLocked = isLiveChattingLocked;
                        draft.isTouchScreenLocked = isTouchScreenLocked;
                    }
                }
            }
        });
    },

    [MQ_LIVE_OFF]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        // console.log("MQ_LIVE_OFF - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.liveStatus = "N";
                        live_info.handsUpYN = "N";
                        live_info.selectStatus = "N";
                        live_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = live_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                            draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                            draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                                draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                            } else {
                                draft.closedPerformLiveInfo.liveName = live_info.liveName;
                            }

                            if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                            } else {
                                if (live_info.teacher_info && live_info.teacher_info !== "") {
                                    draft.closedPerformLiveInfo.teacher_info = live_info.teacher_info;
                                }
                            }

                            if (draft.joinLiveInfo.liveSeq === liveSeq) {
                                if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                    draft.isLiveAlertFinishModalOpened = true;
                                    draft.isLivePullScreenAlertModalOpened = false;
                                }
                            } else {
                                draft.isLiveAlertFinishModalOpened = true;
                                draft.isLivePullScreenAlertModalOpened = false;
                            }

                            draft.smallGroupInfo = {
                                status: false,
                                list_member: []
                            };

                            draft.myHandsUpSendKey = null;
                            draft.myHandsUpBindKeyArr = [];

                            draft.isLiveChattingLocked = false;
                            draft.isTouchScreenLocked = false;
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.liveStatus = "N";
                        watchLive_info.handsUpYN = "N";
                        watchLive_info.handsUpSelectYN = "N";
                        watchLive_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = watchLive_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                            draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                            draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                                draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                            } else {
                                draft.closedPerformLiveInfo.liveName = watchLive_info.liveName;
                            }

                            if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                            } else {
                                if (watchLive_info.teacher_info && watchLive_info.teacher_info !== "") {
                                    draft.closedPerformLiveInfo.teacher_info = watchLive_info.teacher_info;
                                }
                            }

                            if (draft.joinLiveInfo.liveSeq === liveSeq) {
                                if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                    draft.isLiveAlertFinishModalOpened = true;
                                    draft.isLivePullScreenAlertModalOpened = false;
                                }
                            } else {
                                draft.isLiveAlertFinishModalOpened = true;
                                draft.isLivePullScreenAlertModalOpened = false;
                            }

                            draft.smallGroupInfo = {
                                status: false,
                                list_member: []
                            };

                            draft.myHandsUpSendKey = null;
                            draft.myHandsUpBindKeyArr = [];

                            draft.isLiveChattingLocked = false;
                            draft.isTouchScreenLocked = false;
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.liveStatus = "N";
                        robotLive_info.handsUpYN = "N";
                        robotLive_info.selectStatus = "N";
                        robotLive_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.liveStatus = robotLive_info.liveStatus;
                            draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                            draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                            draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                                draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                            } else {
                                draft.closedPerformLiveInfo.liveName = robotLive_info.liveName;
                            }

                            if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                            } else {
                                if (robotLive_info.teacher_info && robotLive_info.teacher_info !== "") {
                                    draft.closedPerformLiveInfo.teacher_info = robotLive_info.teacher_info;
                                }
                            }

                            if (draft.joinLiveInfo.liveSeq === liveSeq) {
                                if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                    draft.isLiveAlertFinishModalOpened = true;
                                    draft.isLivePullScreenAlertModalOpened = false;
                                }
                            } else {
                                draft.isLiveAlertFinishModalOpened = true;
                                draft.isLivePullScreenAlertModalOpened = false;
                            }

                            draft.smallGroupInfo = {
                                status: false,
                                list_member: []
                            };

                            draft.myHandsUpSendKey = null;
                            draft.myHandsUpBindKeyArr = [];

                            draft.isLiveChattingLocked = false;
                            draft.isTouchScreenLocked = false;
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.liveStatus = "N";
                    live_info.handsUpYN = "N";
                    live_info.selectStatus = "N";
                    live_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = live_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                        draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                        draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                            draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                        } else {
                            draft.closedPerformLiveInfo.liveName = live_info.liveName;
                        }

                        if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                            draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                        } else {
                            if (live_info.teacher_info && live_info.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = live_info.teacher_info;
                            }
                        }

                        if (draft.joinLiveInfo.liveSeq === liveSeq) {
                            if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                draft.isLiveAlertFinishModalOpened = true;
                                if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                            }
                        } else {
                            draft.isLiveAlertFinishModalOpened = true;
                            if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                        }

                        draft.smallGroupInfo = {
                            status: false,
                            list_member: []
                        };

                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];

                        draft.isLiveChattingLocked = false;
                        draft.isTouchScreenLocked = false;
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.liveStatus = "N";
                    watchLive_info.handsUpYN = "N";
                    watchLive_info.handsUpSelectYN = "N";
                    watchLive_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = watchLive_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                        draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                        draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                            draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                        } else {
                            draft.closedPerformLiveInfo.liveName = watchLive_info.liveName;
                        }

                        if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                            draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                        } else {
                            if (watchLive_info.teacher_info && watchLive_info.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = watchLive_info.teacher_info;
                            }
                        }

                        if (draft.joinLiveInfo.liveSeq === liveSeq) {
                            if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                draft.isLiveAlertFinishModalOpened = true;
                                if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                            }
                        } else {
                            draft.isLiveAlertFinishModalOpened = true;
                            if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                        }

                        draft.smallGroupInfo = {
                            status: false,
                            list_member: []
                        };

                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];

                        draft.isLiveChattingLocked = false;
                        draft.isTouchScreenLocked = false;
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.liveStatus = "N";
                    robotLive_info.handsUpYN = "N";
                    robotLive_info.selectStatus = "N";
                    robotLive_info.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        draft.performLiveInfo.liveStatus = robotLive_info.liveStatus;
                        draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                        draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                        draft.performLiveInfo.mixedClassStatus = "N";   // 라이브 종료시 mixed class status 초기화
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        if (draft.performLiveInfo.liveName && draft.performLiveInfo.liveName !== "") {
                            draft.closedPerformLiveInfo.liveName = draft.performLiveInfo.liveName;
                        } else {
                            draft.closedPerformLiveInfo.liveName = robotLive_info.liveName;
                        }

                        if (draft.performLiveInfo.teacher_info && draft.performLiveInfo.teacher_info !== "") {
                            draft.closedPerformLiveInfo.teacher_info = draft.performLiveInfo.teacher_info;
                        } else {
                            if (robotLive_info.teacher_info && robotLive_info.teacher_info !== "") {
                                draft.closedPerformLiveInfo.teacher_info = robotLive_info.teacher_info;
                            }
                        }

                        if (draft.joinLiveInfo.liveSeq === liveSeq) {
                            if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                draft.isLiveAlertFinishModalOpened = true;
                                if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                            }
                        } else {
                            draft.isLiveAlertFinishModalOpened = true;
                            if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                        }

                        draft.smallGroupInfo = {
                            status: false,
                            list_member: []
                        };

                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];

                        draft.isLiveChattingLocked = false;
                        draft.isTouchScreenLocked = false;
                    }
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                draft.openedLiveInfo.liveSeq = -1;
                draft.openedLiveInfo.liveName = "";
                draft.openedLiveInfo.list_member = [];
            }

            if (draft.smartTVLiveInfo.liveSeq === liveSeq) {
                draft.smartTVLiveInfo.liveStatus = "N";
            }
        });
    },

    [MQ_BROADCAST_ON]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_BROADCAST_ON - ", action.payload);

        return produce(state, draft => {
            if (draft.joinLiveInfo && draft.joinLiveInfo.liveSeq === liveSeq) {
                draft.joinLiveInfo.visibility = "invisible";
                draft.joinLiveInfo.broadcastOpenYN = "Y";
            }
        });
    },

    [MQ_BROADCAST_OFF]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_BROADCAST_OFF - ", action.payload);

        return produce(state, draft => {
            if (draft.joinLiveInfo && draft.joinLiveInfo.liveSeq === liveSeq) {
                draft.joinLiveInfo.visibility = "invisible";
                draft.joinLiveInfo.broadcastOpenYN = "N";
            }
        });
    },

    [MQ_LIVE_REMOVE]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_REMOVE - ", action.payload);

        const closedLiveInfo = {
            liveName            : "",
            teacher_info        : { 
                userSeq         : -1, 
                userNickname    : "", 
                userProfile     : "", 
                profileImgUrl   : "" 
            }
        };

        if (state.isMixedClassMember) {
            if (state.mixedClassInfo.localLiveSeq === liveSeq) {
                if (state.performLiveInfo.liveSeq === state.mixedClassInfo.serverLiveSeq) {
                    if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                        closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                    }
                }
            }
        } else {
            if (state.performLiveInfo.liveSeq === liveSeq) {
                closedLiveInfo.liveName = state.performLiveInfo.liveName;
                if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                    closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                }
            }
        }

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        draft.performLiveInfo = {
                            isBroadcastLive             : false,
                            isUsablePass                : false,
                            liveSeq                     : -1,
                            liveName                    : "",
                            liveStatus                  : "N",
                            handsUpYN                   : "N",
                            handsUpSelectYN             : "N",
                            list_member                 : [],
                            mixedClassStatus            : "N",
                            teacher_info                : {
                                userSeq                 : -1,
                                userNickname            : "",
                                userProfile             : "",
                                profileImgUrl           : ""
                            }
                        };

                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                        draft.closedPerformLiveInfo = closedLiveInfo;
                        draft.isLiveAlertRemoveModalOpened = true;
                        draft.isGotoWebLive = false;

                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];

                        // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo 초기화 아마 자동으로 될텐데... 주석처리.. by hjkim 20210217
                        /* draft.performHandsUpStatusInfo = {
                            myHandsUpYN     : "N",
                            connectStatus   : "disable",
                            micStatus       : "disable"
                        } */
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    draft.performLiveInfo = {
                        isBroadcastLive             : false,
                        isUsablePass                : false,
                        liveSeq                     : -1,
                        liveName                    : "",
                        liveStatus                  : "N",
                        handsUpYN                   : "N",
                        handsUpSelectYN             : "N",
                        list_member                 : [],
                        mixedClassStatus            : "N",
                        teacher_info                : { 
                            userSeq                 : -1,
                            userNickname            : "",
                            userProfile             : "",
                            profileImgUrl           : "" 
                        }
                    };

                    draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                    draft.closedPerformLiveInfo = closedLiveInfo;
                    draft.isLiveAlertRemoveModalOpened = true;
                    draft.isGotoWebLive = false;

                    draft.myHandsUpSendKey = null;
                    draft.myHandsUpBindKeyArr = [];

                    // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo 초기화 아마 자동으로 될텐데... 주석처리.. by hjkim 20210217
                    /* draft.performHandsUpStatusInfo = {
                        myHandsUpYN     : "N",
                        connectStatus   : "disable",
                        micStatus       : "disable"
                    } */
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                draft.openedLiveInfo.liveSeq = -1;
                draft.openedLiveInfo.liveName = "";
                draft.openedLiveInfo.list_member = [];
                // or
                //draft.openedLiveInfo = {};
            }

            draft.list_live = draft.list_live.filter(info => info.liveSeq !== liveSeq);
            draft.list_watchLive = draft.list_watchLive.filter(info => info.liveSeq !== liveSeq);
            draft.list_requestedWatchLive = draft.list_requestedWatchLive.filter(info => info.liveSeq !== liveSeq);
            draft.list_robotLive = draft.list_robotLive.filter(info => info.liveSeq !== liveSeq);
        });
    },

    [MQ_LIVE_CREATE]: (state, action) => {
        const { live_info } = action.payload;
        const { liveSeq, liveName, liveStatus, liveCode, robotLiveYN } = live_info;
        //console.log("MQ_LIVE_CREATE - ", action.payload);

        if (robotLiveYN === "Y") {
            return {
                ...state,
                list_robotLive          : state.list_robotLive.concat({
                    liveSeq             : liveSeq,
                    liveName            : liveName,
                    liveStatus          : liveStatus,
                    liveCode            : liveCode,
                    liveGroupStudy      : false,
                    handsUpYN           : "N",
                    selectStatus        : "N",
                    userGateWay         : "",
                    groupLimit          : null,
                    robotLiveYN         : robotLiveYN,
                    mixedClassExistYn   : "N",
                    mixedClassStatus    : "N",
                    startSeqOfSmartTV   : 900,
                    binders             : null,
                    isInkSwitch         : true,
                    isVoiceSwitch       : true,
                    isScreenSwitch      : true,
                    isPushSwitch        : true,
                    isPullSwitch        : true,
                    list_member         : [],
                    list_waitMember     : []
                })
            }
        } else {
            return {
                ...state,
                list_live               : state.list_live.concat({
                    liveSeq             : liveSeq,
                    liveName            : liveName,
                    liveStatus          : liveStatus,
                    liveCode            : liveCode,
                    liveGroupStudy      : false,
                    handsUpYN           : "N",
                    selectStatus        : "N",
                    userGateWay         : "",
                    groupLimit          : null,
                    robotLiveYN         : robotLiveYN,
                    mixedClassExistYn   : "N",
                    mixedClassStatus    : "N",
                    startSeqOfSmartTV   : 900,
                    binders             : null,
                    isInkSwitch         : true,
                    isVoiceSwitch       : true,
                    isScreenSwitch      : true,
                    isPushSwitch        : true,
                    isPullSwitch        : true,
                    list_member         : [],
                    list_waitMember     : []
                })
            }
        }
    },

    [MQ_LIVE_EDIT_NAME]: (state, action) => {
        const { edit_info } = action.payload;
        const { liveSeq, newLiveName } = edit_info;
        //console.log("MQ_LIVE_EDIT_NAME - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        draft.performLiveInfo.liveName = newLiveName;
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    draft.performLiveInfo.liveName = newLiveName;
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                draft.openedLiveInfo.liveName = newLiveName;
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                draft.selectedLiveInfo.liveName = newLiveName;
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.liveName = newLiveName;
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                watchLive_info.liveName = newLiveName;
            }

            const requestedWatchLive_info = draft.list_requestedWatchLive.find(info => info.liveSeq === liveSeq);
            if (requestedWatchLive_info) {
                requestedWatchLive_info.liveName = newLiveName;
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                robotLive_info.liveName = newLiveName;
            }
        });
    },

    [MQ_LIVE_UPDATE_BROADCAST_DATE]: (state, action) => {
        const { edit_info } = action.payload;
        const { liveSeq, newBroadcastDate } = edit_info;
        //console.log("MQ_LIVE_UPDATE_BROADCAST_DATE - ", action.payload);

        return produce(state, draft => {
            if (draft.performLiveInfo.liveSeq === liveSeq) {
                draft.performLiveInfo.broadcastDateTime = { strt: newBroadcastDate, end: "" };
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.broadcastDateTime = {
                    strt: newBroadcastDate,
                    end: ""
                };
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                watchLive_info.broadcastDateTime = {
                    strt: newBroadcastDate,
                    end: ""
                };
            }
        });
    },

    [MQ_LIVE_APPLY]: (state, action) => {
        const { apply_info } = action.payload;
        const { liveSeq, user_info } = apply_info;
        const { userSeq, userNickname, userProfile, profileImgUrl, userStatus } = user_info;
        //console.log("MQ_LIVE_APPLY - ", action.payload);

        const member_info = {
            userSeq,
            userNickname,
            userProfile,
            profileImgUrl,
            userStatus,
            userJoinStatus: "N",
            liveCertiYN: "N"
        };

        return produce(state, draft => {
            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_waitMember) {
                    live_info.list_waitMember.push(member_info);
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_waitMember) {
                    robotLive_info.list_waitMember.push(member_info);
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_waitMember) {
                    draft.selectedLiveInfo.list_waitMember.push(member_info);
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_waitMember) {
                    draft.openedLiveInfo.list_waitMember.push(member_info);
                }
            }
        });
    },

    [MQ_LIVE_APPROVE]: (state, action) => {
        const { live_info } = action.payload;
        //console.log("MQ_LIVE_APPROVE - ", action.payload);

        return {
            ...state, 
            list_watchLive          : state.list_watchLive.concat(live_info),
            list_requestedWatchLive : state.list_requestedWatchLive.filter(info => info.liveSeq !== live_info.liveSeq)
        }
    },

    [MQ_LIVE_MEMBER_ADD ]: (state, action) => {
        const { member_info } = action.payload;
        const { liveSeq, user_info } = member_info;
        //console.log("MQ_LIVE_MEMBER_ADD - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.performLiveInfo.list_member) {
                            draft.performLiveInfo.list_member.push(user_info);
                            let nickname = user_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + "...";
                            }
                            draft.nextPhaseData = nickname + i18n.t("클래스_멤버_승인_알림", { ns: "live" });
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    if (draft.performLiveInfo.list_member) {
                        draft.performLiveInfo.list_member.push(user_info);
                        let nickname = user_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + "...";
                        }
                        draft.nextPhaseData = nickname + i18n.t("클래스_멤버_승인_알림", { ns: "live" });
                    }
                }
            }

            const live_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    live_info.list_member.push(user_info);
                }
            }
        });
    },

    [MQ_LIVE_MEMBER_APPROVE]: (state, action) => {
        const { member_info } = action.payload;
        const { liveSeq, user_info } = member_info;
        //console.log("MQ_LIVE_MEMBER_APPROVE - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.performLiveInfo.list_member) {
                            draft.performLiveInfo.list_member.push(user_info);
                            let nickname = user_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + "...";
                            }
                            draft.nextPhaseData = nickname + i18n.t("클래스_멤버_승인_알림", { ns: "live" });
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    if (draft.performLiveInfo.list_member) {
                        draft.performLiveInfo.list_member.push(user_info);
                        let nickname = user_info.userNickname;
                        if (nickname.length > 8) {
                            nickname = nickname.substring(0, 6) + "...";
                        }
                        draft.nextPhaseData = nickname + i18n.t("클래스_멤버_승인_알림", { ns: "live" });
                    }
                }
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    live_info.list_member.push({
                        ...user_info,
                        isInkON     : true,
                        isSoundON   : true,
                        isScreenON  : true,
                        isSelect    : false,
                        isPullScreen: true,
                        isPushScreen: true
                    });
                }

                if (live_info.list_waitMember) {
                    live_info.list_waitMember = live_info.list_waitMember.filter(info => info.userSeq !== user_info.userSeq);
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_member) {
                    robotLive_info.list_member.push({
                        ...user_info,
                        isInkON     : true,
                        isSoundON   : true,
                        isScreenON  : true,
                        isSelect    : false,
                        isPullScreen: true,
                        isPushScreen: true
                    });
                }

                if (robotLive_info.list_waitMember) {
                    robotLive_info.list_waitMember = robotLive_info.list_waitMember.filter(info => info.userSeq !== user_info.userSeq);
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_member) {
                    draft.openedLiveInfo.list_member.push({
                        ...user_info,
                        isInkON     : true,
                        isSoundON   : true,
                        isScreenON  : true,
                        isSelect    : false,
                        isPullScreen: true,
                        isPushScreen: true
                    });
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_member) {
                    draft.selectedLiveInfo.list_member.push(user_info);
                }

                if (draft.selectedLiveInfo.list_waitMember) {
                    draft.selectedLiveInfo.list_waitMember = draft.selectedLiveInfo.list_waitMember.filter(info => info.userSeq !== user_info.userSeq);
                }
            }
        });
    },

    [MQ_LIVE_MEMBER_REJECT]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_MEMBER_REJECT - ", action.payload);

        return produce(state, draft => {
            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_waitMember) {
                    live_info.list_waitMember = live_info.list_waitMember.filter(info => info.userSeq !== userSeq);
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_waitMember) {
                    robotLive_info.list_waitMember = robotLive_info.list_waitMember.filter(info => info.userSeq !== userSeq);
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_waitMember) {
                    draft.selectedLiveInfo.list_waitMember = draft.selectedLiveInfo.list_waitMember.filter(info => info.userSeq !== userSeq);
                }
            }
        });
    },

    [MQ_LIVE_MEMBER_REJECTED]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_MEMBER_REJECTED - ", action.payload);

        return produce(state, draft => {
            const live_info = draft.list_requestedWatchLive.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.liveCertiYN = 'R';
            }
        });
    },

    [MQ_LIVE_MEMBER_REMOVE]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_MEMBER_REMOVE - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.performLiveInfo.list_member) {
                            const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                let nickname = member_info.userNickname;
                                if (nickname.length > 8) {
                                    nickname = nickname.substring(0, 6) + "...";
                                }
                                draft.nextPhaseData = nickname + i18n.t("클래스_멤버_제외_알림", { ns: "live" });
                                draft.performLiveInfo.list_member = draft.performLiveInfo.list_member.filter(info => info.userSeq !== userSeq);
                            }
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    if (draft.performLiveInfo.list_member) {
                        const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info) {
                            let nickname = member_info.userNickname;
                            if (nickname.length > 8) {
                                nickname = nickname.substring(0, 6) + "...";
                            }
                            draft.nextPhaseData = nickname + i18n.t("클래스_멤버_제외_알림", { ns: "live" });
                            draft.performLiveInfo.list_member = draft.performLiveInfo.list_member.filter(info => info.userSeq !== userSeq);
                        }
                    }
                }
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                if (watchLive_info.list_member) {
                    watchLive_info.list_member = watchLive_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    live_info.list_member = live_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_member) {
                    robotLive_info.list_member = robotLive_info.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_member) {
                    draft.openedLiveInfo.list_member = draft.openedLiveInfo.list_member.filter(info => info.userSeq !== userSeq);
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_member) {
                    draft.selectedLiveInfo.list_member = draft.selectedLiveInfo.list_member.filter(info => info.userSeq !== userSeq);
                }
            }
        });
    }, 

    [MQ_LIVE_MEMBER_REMOVED]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        //console.log("MQ_LIVE_MEMBER_REMOVED - ", action.payload);

        const closedLiveInfo = {
            liveName            : "",
            teacher_info        : { 
                userSeq         : -1,
                userNickname    : "",
                userProfile     : "",
                profileImgUrl   : "" 
            }
        };

        if (state.isMixedClassMember) {
            if (state.mixedClassInfo.localLiveSeq === liveSeq) {
                if (state.performLiveInfo.liveSeq === state.mixedClassInfo.serverLiveSeq) {
                    if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                        closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                    }
                }
            }
        } else {
            if (state.performLiveInfo.liveSeq === liveSeq) {
                closedLiveInfo.liveName = state.performLiveInfo.liveName;
                if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                    closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                }
            }
        }

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        draft.performLiveInfo = {
                            isBroadcastLive             : false,
                            isUsablePass                : false,
                            liveSeq                     : -1,
                            liveName                    : "",
                            liveStatus                  : "N",
                            handsUpYN                   : "N",
                            handsUpSelectYN             : "N",
                            list_member                 : [],
                            mixedClassStatus            : "N",
                            teacher_info                : { 
                                userSeq                 : -1,
                                userNickname            : "",
                                userProfile             : "",
                                profileImgUrl           : "" 
                            }
                        };

                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                        draft.isLiveAlertModalRemoveMemberOpened = true;
                        draft.closedPerformLiveInfo = closedLiveInfo;
                        draft.isGotoWebLive = false;
    
                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    draft.performLiveInfo = {
                        isBroadcastLive             : false,
                        isUsablePass                : false,
                        liveSeq                     : -1,
                        liveName                    : "",
                        liveStatus                  : "N",
                        handsUpYN                   : "N",
                        handsUpSelectYN             : "N",
                        list_member                 : [],
                        mixedClassStatus            : "N",
                        teacher_info                : { 
                            userSeq                 : -1,
                            userNickname            : "",
                            userProfile             : "",
                            profileImgUrl           : "" 
                        }
                    };

                    draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                    draft.isLiveAlertModalRemoveMemberOpened = true;
                    draft.closedPerformLiveInfo = closedLiveInfo;
                    draft.isGotoWebLive = false;

                    draft.myHandsUpSendKey = null;
                    draft.myHandsUpBindKeyArr = [];
                }
            }

            draft.list_watchLive = draft.list_watchLive.filter(live => live.liveSeq !== liveSeq);
        });
    },

    [MQ_UPDATE_LIVE_MEMBER_INFO]: (state, action) => {
        const { update_info } = action.payload;
        const { userSeq, userNickname, userProfile, profileImgUrl } = update_info;
        //console.log("MQ_UPDATE_LIVE_MEMBER_INFO - ", action.payload);

        return produce(state, draft => {
            // mixed class user일 경우 어떻게 처리할지 생각해보기....
            if (draft.performLiveInfo.liveSeq >= 0) {
                if (draft.performLiveInfo.teacher_info) {
                    const teacher_info = draft.performLiveInfo.teacher_info;
                    if (teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                        teacher_info.userProfile = userProfile !== null ? userProfile : teacher_info.userProfile;
                        teacher_info.userNickname = profileImgUrl !== null ? profileImgUrl : teacher_info.profileImgUrl;
                    }
                }

                if (draft.performLiveInfo.list_member) {
                    const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            }

            if (draft.selectedLiveInfo.liveSeq >= 0) {
                if (draft.selectedLiveInfo.list_member) {
                    const member_info = draft.selectedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }

                if (draft.selectedLiveInfo.list_waitMember) {
                    const member_info = draft.selectedLiveInfo.list_waitMember.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            }

            if (draft.searchLiveInfo.teacher_info) {
                console.log('searchLiveInfo.teacher_info case... ', state.searchLiveInfo.teacher_info);
                const teacher_info = draft.searchLiveInfo.teacher_info;
                if (teacher_info.userSeq === userSeq) {
                    teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                    teacher_info.userProfile = userProfile !== null ? userProfile : teacher_info.userProfile;
                    teacher_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : teacher_info.profileImgUrl;
                }
            }

            if (draft.openedLiveInfo.liveSeq >= 0) {
                if (draft.openedLiveInfo.list_member) {
                    const member_info = draft.openedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            }

            draft.list_live.forEach(live_info => {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }

                if (live_info.list_waitMember) {
                    const member_info = live_info.list_waitMember.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            });

            draft.list_watchLive.forEach(live_info => {
                if (live_info.teacher_info) {
                    const teacher_info = live_info.teacher_info;
                    if (teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                        teacher_info.userProfile = userProfile !== null ? userProfile : teacher_info.userProfile;
                        teacher_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : teacher_info.profileImgUrl;
                    }
                }

                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            });

            draft.list_requestedWatchLive.forEach(live_info => {
                if (live_info.teacher_info) {
                    const teacher_info = live_info.teacher_info;
                    if (teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                        teacher_info.userProfile = userProfile !== null ? userProfile : teacher_info.userProfile;
                        teacher_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : teacher_info.profileImgUrl;
                    }
                }

                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            });

            draft.list_searchLive.forEach(live_info => {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            });

            draft.list_robotLive.forEach(live_info => {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        member_info.userProfile = userProfile !== null ? userProfile : member_info.userProfile;
                        member_info.profileImgUrl = profileImgUrl !== null ? profileImgUrl : member_info.profileImgUrl;
                    }
                }
            });
        });
    },

    [MQ_UPDATE_LIVE_MEMBER_NICKNAME]: (state, action) => {
        const { user_info } = action.payload;
        const { liveSeq, userSeq, userNickname } = user_info;
        console.log("MQ_UPDATE_LIVE_MEMBER_NICKNAME - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        if (draft.mixedClassInfo.localTeacherSeq === userSeq) {
                            const teacher_info = draft.performLiveInfo.teacher_info;
                            if (teacher_info) {
                                teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                            }
                        }

                        if (draft.performLiveInfo.list_member) {
                            const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                            if (member_info) {
                                member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                            }
                        }
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    const teacher_info = draft.performLiveInfo.teacher_info;
                    if (teacher_info && teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                    }

                    if (draft.performLiveInfo.list_member) {
                        const member_info = draft.performLiveInfo.list_member.find(info => info.userSeq === userSeq);
                        if (member_info) {
                            member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                        }
                    }
                }
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                if (live_info.list_member) {
                    const member_info = live_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }

                if (live_info.list_waitMember) {
                    const member_info = live_info.list_waitMember.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                if (watchLive_info.teacher_info) {
                    const teacher_info = watchLive_info.teacher_info;
                    if (teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                    }
                }

                if (watchLive_info.list_member) {
                    const member_info = watchLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            const requestedWatchLive_info = draft.list_requestedWatchLive.find(info => info.liveSeq === liveSeq);
            if (requestedWatchLive_info) {
                if (requestedWatchLive_info.teacher_info) {
                    const teacher_info = requestedWatchLive_info.teacher_info;
                    if (teacher_info.userSeq === userSeq) {
                        teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                    }
                }

                if (requestedWatchLive_info.list_member) {
                    const member_info = requestedWatchLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                if (robotLive_info.list_member) {
                    const member_info = robotLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            const searchLive_info = draft.list_searchLive.find(info => info.liveSeq === liveSeq);
            if (searchLive_info) {
                if (searchLive_info.list_member) {
                    const member_info = searchLive_info.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            if (draft.selectedLiveInfo.liveSeq === liveSeq) {
                if (draft.selectedLiveInfo.list_member) {
                    const member_info = draft.selectedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }

                if (draft.selectedLiveInfo.list_waitMember) {
                    const member_info = draft.selectedLiveInfo.list_waitMember.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }

            if (draft.searchLiveInfo.liveSeq === liveSeq) {
                const teacher_info = draft.searchLiveInfo.teacher_info;
                if (teacher_info && teacher_info.userSeq === userSeq) {
                    teacher_info.userNickname = userNickname !== null ? userNickname : teacher_info.userNickname;
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                if (draft.openedLiveInfo.list_member) {
                    const member_info = draft.openedLiveInfo.list_member.find(info => info.userSeq === userSeq);
                    if (member_info) {
                        member_info.userNickname = userNickname !== null ? userNickname : member_info.userNickname;
                    }
                }
            }
        });
    },

    [MQ_LIVE_CHAT_MSG]: (state, action) => {
        const { msg_info } = action.payload;
        console.log("MQ_LIVE_CHAT_MSG - ", msg_info);

        return {
            ...state
        }
    },

    [MQ_HANDS_UP_START]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_HANDS_UP_START - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.handsUpYN = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.handsUpYN = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.handsUpYN = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.handsUpYN = "Y";

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.handsUpYN = "Y";

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.handsUpYN = "Y";

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                    }
                }
            }
        });
    },

    [MQ_HANDS_UP_STOP]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_HANDS_UP_STOP - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.handsUpYN = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                            //draft.performLiveInfo.handsUpSelectYN = "N";
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                            /* draft.performHandsUpStatusInfo = {
                                myHandsUpYN     : "N",
                                connectStatus   : "disable",
                                micStatus       : "disable"
                            }; */
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.handsUpYN = "N";
                        //watchLive_info.handsUpSelectYN = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                            //draft.performLiveInfo.handsUpSelectYN = "N";
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                            /* draft.performHandsUpStatusInfo = {
                                myHandsUpYN     : "N",
                                connectStatus   : "disable",
                                micStatus       : "disable"
                            }; */
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.handsUpYN = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                            //draft.performLiveInfo.handsUpSelectYN = "N";
                            draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                            // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                            /* draft.performHandsUpStatusInfo = {
                                myHandsUpYN     : "N",
                                connectStatus   : "disable",
                                micStatus       : "disable"
                            }; */
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.handsUpYN = "N";

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = live_info.handsUpYN;
                        //draft.performLiveInfo.handsUpSelectYN = "N";
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                        /* draft.performHandsUpStatusInfo = {
                            myHandsUpYN     : "N",
                            connectStatus   : "disable",
                            micStatus       : "disable"
                        }; */
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.handsUpYN = "N";
                    //watchLive_info.handsUpSelectYN = "N";

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = watchLive_info.handsUpYN;
                        //draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                        /* draft.performHandsUpStatusInfo = {
                            myHandsUpYN     : "N",
                            connectStatus   : "disable",
                            micStatus       : "disable"
                        }; */
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.handsUpYN = "N";

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        draft.performLiveInfo.handsUpYN = robotLive_info.handsUpYN;
                        //draft.performLiveInfo.handsUpSelectYN = "N";
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";

                        // performLiveInfo.liveSeq와 동일한 경우 handsUpStatusInfo reset performLiveInfo.handsUpYN N가 되면 아마 값이 바뀔건데... 필요할까?
                        /* draft.performHandsUpStatusInfo = {
                            myHandsUpYN     : "N",
                            connectStatus   : "disable",
                            micStatus       : "disable"
                        }; */
                    }
                }
            }
        });
    },

    [MQ_HANDS_UP_ON]: (state, action) => {
        //console.log("MQ_HANDS_UP_ON");

        /** 학생이 받을 일이 있을지...? */
        return {
            ...state
        }
    },

    [MQ_HANDS_UP_OFF]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_HANDS_UP_OFF - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        /** 깜빡이는 효과를 꺼쥬세요.... */
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    /** 깜빡이는 효과를 꺼쥬세요.... */
                    draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                }
            }
        });
    },

    [MQ_HANDS_UP_ACTIVE]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        console.log("MQ_HANDS_UP_ACTIVE - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.selectStatus = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(1) live_info.selectStatus => ", live_info.selectStatus);
                            draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.handsUpSelectYN = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(1) watchLive_info.handsUpSelectYN => ", watchLive_info.handsUpSelectYN);
                            draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.selectStatus = "Y";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(1) robotLive_info.selectStatus => ", robotLive_info.selectStatus);
                            draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.selectStatus = "Y";

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        console.log("(2) live_info.selectStatus => ", live_info.selectStatus);
                        draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.handsUpSelectYN = "Y";

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        console.log("(2) watchLive_info.handsUpSelectYN => ", watchLive_info.handsUpSelectYN);
                        draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.selectStatus = "Y";

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        console.log("(2) robotLive_info.selectStatus => ", robotLive_info.selectStatus);
                        draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                    }
                }
            }
        });
    },

    [MQ_HANDS_UP_INACTIVE]: (state, action) => {
        const { liveSeq, userSeq } = action.payload;
        console.log("MQ_HANDS_UP_INACTIVE - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                    if (live_info) {
                        live_info.selectStatus = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(3) live_info.selectStatus => ", live_info.selectStatus);
                            draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                        }
                    }

                    const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                    if (watchLive_info) {
                        watchLive_info.handsUpSelectYN = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(3) watchLive_info.handsUpSelectYN => ", watchLive_info.handsUpSelectYN);
                            draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                        }
                    }

                    const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                    if (robotLive_info) {
                        robotLive_info.selectStatus = "N";

                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            console.log("(3) robotLive_info.selectStatus => ", robotLive_info.selectStatus);
                            draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                        }
                    }
                }
            } else {
                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.selectStatus = "N";

                    if (draft.performLiveInfo.liveSeq === live_info.liveSeq) {
                        console.log("(4) live_info.selectStatus => ", live_info.selectStatus);
                        draft.performLiveInfo.handsUpSelectYN = live_info.selectStatus;
                    }
                }

                const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
                if (watchLive_info) {
                    watchLive_info.handsUpSelectYN = "N";

                    if (draft.performLiveInfo.liveSeq === watchLive_info.liveSeq) {
                        console.log("(4) watchLive_info.handsUpSelectYN => ", watchLive_info.handsUpSelectYN);
                        draft.performLiveInfo.handsUpSelectYN = watchLive_info.handsUpSelectYN;
                    }
                }

                const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
                if (robotLive_info) {
                    robotLive_info.selectStatus = "N";

                    if (draft.performLiveInfo.liveSeq === robotLive_info.liveSeq) {
                        console.log("(4) robotLive_info.selectStatus => ", robotLive_info.selectStatus);
                        draft.performLiveInfo.handsUpSelectYN = robotLive_info.selectStatus;
                    }
                }
            }
        });
    },

    [MQ_SMALL_GROUP_START]: (state, action) => {
        const { list_user } = action.payload;
        //console.log("MQ_SMALL_GROUP_START - ", action.payload);
        let list_member = [];

        if (list_user) {

            list_user.forEach(userInfo => {
                const member = state.performLiveInfo.list_member.find(info => info.userSeq === userInfo.userSeq);
                if (member) {
                    list_member.push(member);
                }
            });
        }

        return {
            ...state,
            smallGroupInfo  : { status: true, list_member }
        }
    },

    [MQ_SMALL_GROUP_STOP]: (state, action) => {
        //console.log('MQ_SMALL_GROUP_STOP');

        return {
            ...state,
            smallGroupInfo  : { status: false, list_member: [] }
        }
    },

    [MQ_LIVE_INFO_INIT]: (state, action) => {
        const { liveSeq } = action.payload;
        //console.log("MQ_LIVE_INFO_INIT - ", action.payload);

        const closedLiveInfo = {
            liveName            : "",
            teacher_info        : { 
                userSeq         : -1,
                userNickname    : "",
                userProfile     : "",
                profileImgUrl   : "" 
            }
        };

        if (state.isMixedClassMember) {
            if (state.mixedClassInfo.localLiveSeq === liveSeq) {
                if (state.performLiveInfo.liveSeq === state.mixedClassInfo.serverLiveSeq) {
                    if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                        closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                    }
                }
            }
        } else {
            if (state.performLiveInfo.liveSeq === liveSeq) {
                closedLiveInfo.liveName = state.performLiveInfo.liveName;
                if (state.performLiveInfo.teacher_info && state.performLiveInfo.teacher_info !== "") {
                    closedLiveInfo.teacher_info = state.performLiveInfo.teacher_info;
                }
            }
        }

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        draft.performLiveInfo.liveStatus = "N";
                        draft.performLiveInfo.handsUpYN = "N";
                        draft.performLiveInfo.handsUpSelectYN = "N";
                        draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                        draft.closedPerformLiveInfo = closedLiveInfo;

                        if (draft.joinLiveInfo.liveSeq === liveSeq) {
                            if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                                draft.isLiveAlertModalOpened = true;
                                if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                            }
                        } else {
                            draft.isLiveAlertModalOpened = true;
                            if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                        }

                        // performLiveInfo.liveSeq 와 동일한 경우에만 reset
                        draft.myHandsUpSendKey = null;
                        draft.myHandsUpBindKeyArr = [];
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    draft.performLiveInfo.liveStatus = "N";
                    draft.performLiveInfo.handsUpYN = "N";
                    draft.performLiveInfo.handsUpSelectYN = "N";
                    draft.performHandsUpStatusInfo.myHandsUpYN = "N";
                    draft.closedPerformLiveInfo = closedLiveInfo;

                    if (draft.joinLiveInfo.liveSeq === liveSeq) {
                        if (draft.joinLiveInfo.broadcastOpenYN === "Y") {
                            draft.isLiveAlertModalOpened = true;
                            if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                        }
                    } else {
                        draft.isLiveAlertModalOpened = true;
                        if (draft.isLivePullScreenAlertModalOpened) draft.isLivePullScreenAlertModalOpened = false;
                    }

                    // performLiveInfo.liveSeq 와 동일한 경우에만 reset
                    draft.myHandsUpSendKey = null;
                    draft.myHandsUpBindKeyArr = [];
                }
            }

            if (draft.openedLiveInfo.liveSeq === liveSeq) {
                draft.openedLiveInfo.liveSeq = -1;
                draft.openedLiveInfo.liveName = "";
                draft.openedLiveInfo.list_member = [];
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.liveStatus = "N";
                live_info.handsUpYN = "N";
                live_info.selectStatus = "N";
            }

            const watchLive_info = draft.list_watchLive.find(info => info.liveSeq === liveSeq);
            if (watchLive_info) {
                watchLive_info.liveStatus = "N";
                watchLive_info.handsUpYN = "N";
                watchLive_info.handsUpSelectYN = "N";
            }

            const robotLive_info = draft.list_robotLive.find(info => info.liveSeq === liveSeq);
            if (robotLive_info) {
                robotLive_info.liveStatus = "N";
                robotLive_info.handsUpYN = "N";
                robotLive_info.selectStatus = "N";
            }

            if (draft.smartTVLiveInfo.liveSeq === liveSeq) {
                draft.smallGroupInfo.liveStatus = "N";
            }
        });
    },

    [MQ_CHATTING_LOCK]: (state, action) => {
        const { liveSeq, userSeq, isLiveChattingLocked } = action.payload;
        console.log("MQ_CHATTING_LOCK - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) { // mixed class 멤버인 경우
                // 우선 mixed class local teacherSeq & local liveSeq 와 일치 여부 비교
                if (draft.mixedClassInfo.localTeacherSeq === userSeq && (draft.mixedClassInfo.localLiveSeq === liveSeq || liveSeq === -1)) {
                    let performLiveSeq = draft.performLiveInfo.liveSeq;
                    let performLiveTeacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                    // mixed class serverTeacherSeq & serverLiveSeq 와 perform teacherSeq & liveSeq 일치 여부 비교
                    if (draft.mixedClassInfo.serverTeacherSeq === performLiveTeacherSeq && draft.mixedClassInfo.serverLiveSeq === performLiveSeq) {
                        draft.isLiveMemberModalOpened = false;
                        draft.isLiveChattingLocked = isLiveChattingLocked;
                    }
                }
            } else {
                // perform teacherSeq & liveSeq 일치 여부 비교
                if (draft.performLiveInfo.teacher_info.userSeq === userSeq && (draft.performLiveInfo.liveSeq === liveSeq || liveSeq === -1)) {
                    draft.isLiveMemberModalOpened = false;
                    draft.isLiveChattingLocked = isLiveChattingLocked;
                }
            }
        });
    },

    [MQ_TOUCH_SCREEN_LOCK]: (state, action) => {
        const { liveSeq, userSeq, isTouchScreenLocked } = action.payload;
        console.log("MQ_TOUCH_SCREEN_LOCK - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) { // mixed class 멤버인 경우
                // 우선 mixed class local teacherSeq & local liveSeq 와 일치 여부 비교
                if (draft.mixedClassInfo.localTeacherSeq === userSeq && (draft.mixedClassInfo.localLiveSeq === liveSeq || liveSeq === -1)) {
                    let performLiveSeq = draft.performLiveInfo.liveSeq;
                    let performLiveTeacherSeq = draft.performLiveInfo.teacher_info.userSeq;
                    // mixed class serverTeacherSeq & serverLiveSeq 와 perform teacherSeq & liveSeq 일치 여부 비교
                    if (draft.mixedClassInfo.serverTeacherSeq === performLiveTeacherSeq && draft.mixedClassInfo.serverLiveSeq === performLiveSeq) {
                        draft.isTouchScreenLocked = isTouchScreenLocked;
                    }
                }
            } else {
                // perform teacherSeq & liveSeq 일치 여부 비교
                if (draft.performLiveInfo.teacher_info.userSeq === userSeq && (draft.performLiveInfo.liveSeq === liveSeq || liveSeq === -1)) {
                    draft.isTouchScreenLocked = isTouchScreenLocked;
                }
            }
        });
    },

    [MQ_RCV_SMART_TV_BIND_INFO]: (state, action) => {
        const { bindKeyArr, startSeqOfSmartTV, serverGateWay } = action.payload;
        console.log("MQ_RCV_SMART_TV_BIND_INFO - ", action.payload);

        let list_member = action.payload.list_member ? action.payload.list_member : [];

        return {
            ...state,
            //isSmartTVAlertModalOpened   : true,
            smartTVBindInfo             : {
                bindKeyArr              : bindKeyArr,
                startSeqOfSmartTV       : startSeqOfSmartTV,
                serverGateWay           : serverGateWay
            },
            smartTVLiveInfo             : {
                ...state.smartTVLiveInfo,
                list_member             : list_member
            }
        }
    },

    [MQ_START_MIXED_CLASS]: (state, action) => {
        const { liveSeq } = action.payload;
        console.log("MQ_START_MIXED_CLASS - ", action.payload);

        return produce(state, draft => {
            if (draft.isMixedClassMember) {
                if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                    if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                        draft.performLiveInfo.mixedClassStatus = "Y";
                    }
                }
            } else {
                if (draft.performLiveInfo.liveSeq === liveSeq) {
                    draft.performLiveInfo.mixedClassStatus = "Y";
                }
            }

            const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
            if (live_info) {
                live_info.mixedClassStatus = "Y";
            }
        });
    },

    [MQ_STOP_MIXED_CLASS]: (state, action) => {
        const { liveSeq } = action.payload;
        console.log("MQ_STOP_MIXED_CLASS - ", action.payload);

        return produce(state, draft => {
            return produce(state, draft => {
                if (draft.isMixedClassMember) {
                    if (draft.mixedClassInfo.localLiveSeq === liveSeq) {
                        if (draft.performLiveInfo.liveSeq === draft.mixedClassInfo.serverLiveSeq) {
                            draft.performLiveInfo.mixedClassStatus = "N";
                        }
                    }
                } else {
                    if (draft.performLiveInfo.liveSeq === liveSeq) {
                        draft.performLiveInfo.mixedClassStatus = "Y";
                    }
                }

                const live_info = draft.list_live.find(info => info.liveSeq === liveSeq);
                if (live_info) {
                    live_info.mixedClassStatus = "N";
                }
            });
        });
    }
}, initialState);

export default live;
import React, { Component } from "react";
import { connect } from "react-redux";

import { enTokenCMD } from "../../../lib/classTalk/CodeEnum";
import { ctActionType } from "../../../lib/ConstCommand";

import ClassTalk from "../../../components/chat/ClassTalk";

import { teamupOpenToggle, toggle_alertModal } from "../../../modules/chat";
import { handsupOpenToggle } from "../../../modules/live";
import { getQuizList } from "../../../modules/quiz";
import {
    appendSendMessage,
    appendSendAudioMemo,
    appendSendFileShare,
    emoticonSelectedToggle,
    selectEmoticonSeq,
    emoticonSelectedCancel,
    addFunctionOpenedToggle,
    selectFunctionSeq,
    inkMemoOpenedToggle,
    voiceMemoClosedToggle,
    openWhisperPopup,
    fileShareToggle,
    fileOpenToggle,
    imgThumbnailClick,
    showQuizResponseInfo,
    showQuizResponseResult,
    quizResponseFinish,
    getDownloadFileList,
    clearDownloadFiles,
    fileDirRemoveToggle
} from "../../../modules/classTalk";
import FileDirRemoveModal from "../../../components/chat/modal/FileDirRemoveModal";
import CenteredModalInChatting from "../../../components/template/modal/CenteredModalInChatting";

class ClassTalkContainer extends Component {

    handleSelectedClassTalkFunc = ({ selectedFunc, emoticonSeq, func, msgText, data, filename, fileURL, idx, fileType, memberSeqList, list_member, liveSeq, quizSeq }) => {
        const { performChatRoomInfo, userNickname, userSeq } = this.props;

        switch (selectedFunc) {
            // --------------------------- Emoticon --------------------------- //

            case "EMOTICON_TOGGLE":
                this.props.emoticonSelectedToggle();
                break;

            case "EMOTICON_SELECT":
                this.props.selectEmoticonSeq(emoticonSeq);
                break;

            case "EMOTICON_CANCLE":
                this.props.emoticonSelectedCancel();
                break;

            // --------------------------- Send message --------------------------- //

            case "SEND_MSG":
                this.props.appendSendMessage({ msgText, userNickname, memberSeqList });
                //this.props.appendSendMessage({ msgText, userNickname }); 
                break;

            case "SEND_VOICE_MEMO":
                this.props.appendSendAudioMemo({ data, url: fileURL, userNickname, userSeq, memberSeqList });
                //this.props.appendSendAudioMemo({ selectedFunc, data, url: fileURL, userNickname, userSeq });
                break;

            // --------------------------- Classtalk func --------------------------- //

            case "FUNC_TOGGLE":
                this.props.addFunctionOpenedToggle();
                break;

            case "FUNC_SELECT":
                this.props.selectFunctionSeq({ func, mode: "hiclass", chatRoomSeq: performChatRoomInfo.chatRoomSeq, userNickname, userSeq, memberSeqList });
                //this.props.selectFunctionSeq({ func, mode: "hiclass", chatRoomSeq: performChatRoomInfo.chatRoomSeq, userNickname, userSeq });

                switch (func) {
                    case ctActionType.InkMemo:
                        //this.props.inkMemoOpenedToggle({ performChatRoomSeq: performChatRoomInfo.chatRoomSeq, userNickname });
                        break;

                    case ctActionType.FileShare:
                        let chatRoomSeq = 0;
                        if (performChatRoomInfo.chatRoomSeq !== -1) chatRoomSeq = performChatRoomInfo.chatRoomSeq;
                        this.props.appendSendFileShare({
                            mode: "hiclass",
                            chatRoomSeq,
                            data,
                            teacherSeq: userSeq,
                            filename, userSeq,
                            userNickname,
                            command: enTokenCMD.ct_UploadAndShareFiles,
                            memberSeqList,
                            mixedClassStatus: this.props.activeLive.mixedClassStatus
                        });
                        /*if (performChatRoomInfo.chatRoomSeq !== -1) {
                            this.props.appendSendFileShare({ mode: "hiclass", chatRoomSeq: performChatRoomInfo.chatRoomSeq, data, teacherSeq: userSeq, filename, userSeq, userNickname, command: enTokenCMD.ct_UploadAndShareFiles });
                        } else {
                            this.props.appendSendFileShare({ mode: "hiclass", chatRoomSeq: 0, data, teacherSeq: userSeq, filename, userSeq, userNickname, command: enTokenCMD.ct_UploadAndShareFiles });
                        }*/ break;

                    case ctActionType.TeamUpWindow:
                        this.props.teamupOpenToggle();
                        break;

                    case ctActionType.HandsUpWindow:
                        this.props.handsupOpenToggle({ userSeq });
                        break;

                    case ctActionType.Quiz:
                        if (!this.props.isQuizWindowOpened) this.props.getQuizList({ userSeq });
                        break;

                    case ctActionType.FileDownload:
                        this.props.getDownloadFileList({ teacherSeq: userSeq, liveSeq: this.props.activeLive.liveSeq });
                        break;

                    case ctActionType.FileClear:
                        this.props.clearDownloadFiles({ teacherSeq: userSeq, liveSeq: this.props.activeLive.liveSeq });
                        break;

                    default:
                        break;
                }
                break;

            case "CLOSE_VOICE_MEMO":
                this.props.voiceMemoClosedToggle();
                break;

            case "INK_MEMO":
                this.props.inkMemoOpenedToggle({ selectedFunc, imgUrl: fileURL, userNickname, userSeq /**, performChatRoomSeq: performChatRoomInfo.chatRoomSeq */ });
                break;

            case "PAGE_JPEG":
                this.props.imgThumbnailClick({ imgDataUrl: fileURL, idx });
                break;

            case "FILE_DOWNLOAD":
                this.props.fileShareToggle({ fileURL, idx });
                break;

            case "FILE_OPEN":
                this.props.fileOpenToggle({ fileURL, fileType });
                break;

            case "OPEN_WHISPER":
                this.props.openWhisperPopup({ liveSeq: this.props.activeLive.liveSeq, memberSeqList: data.data });
                break;

            // --------------------------- Quiz --------------------------- //

            case "SHOW_QUIZ_RESPONSE_INFO":
                this.props.showQuizResponseInfo({ idx });
                break;

            case "QUIZ_RESPONSE_FINISH":
                console.log("idx - ", idx);
                console.log("liveSeq - ", liveSeq);
                console.log("quizSeq - ", quizSeq);
                console.log("userNickname - ", userNickname);
                this.props.quizResponseFinish({ idx, liveSeq, list_member, userNickname, quizSeq });
                break;

            case "TOGGLE_FILE_DIR_REMOVE":
                this.props.fileDirRemoveToggle({ isModalOpened: !this.props.isFileDirRemoveModalOpened });
                break;

            default:
                break;
        }
    }

    render() {
        const { classTalkMsg } = this.props;

        return (
            <>
                {
                    this.props.isFileDirRemoveModalOpened &&
                    <CenteredModalInChatting modalClose={this.props.handleSelectedFunc}>
                        <FileDirRemoveModal
                            handleSelectedClassTalkFunc={this.handleSelectedClassTalkFunc}
                        />
                    </CenteredModalInChatting>
                }
                <ClassTalk
                    openInfo={this.props.openInfo}
                    activeLive={this.props.activeLive}
                    isFileDirRemoveModalOpened={this.props.isFileDirRemoveModalOpened}
                    isFileDownloadingNow={this.props.isFileDownloadingNow}
                    isQuizWindowOpened={this.props.isQuizWindowOpened}
                    isHandsupOpened={this.props.isHandsupOpened}
                    isTeamUpOpened={this.props.isTeamUpOpened}
                    classTalkMsg={classTalkMsg}
                    handleSelectedClassTalkFunc={this.handleSelectedClassTalkFunc}
                />
            </>
        );
    }
}

export default connect(
    ({ classTalk, chat, user, live }) => ({
        userSeq: user.userSeq,
        userNickname: user.userNickname,

        whisperMsg: classTalk.whisperMsg,

        openInfo: {
            isEmoticonSelected: classTalk.isEmoticonSelected,
            selectedEmoticonSeq: classTalk.selectedEmoticonSeq,
            isAddFunctionOpened: classTalk.isAddFunctionOpened,
            isAudioOpened: classTalk.isAudioOpened,
            audioStatus: classTalk.audioStatus,
            selectedFunctionSeq: classTalk.selectedFunctionSeq
        },

        classTalkMsg: classTalk.receivedMsg,
        performChatRoomInfo: chat.performChatRoomInfo,

        activeLive: live.list_live.find(info => info.selectStatus === "Y") !== undefined && live.list_live.find(info => info.selectStatus === "Y") !== null ?
            live.list_live.find(info => info.selectStatus === "Y") :
            {
                liveSeq: -1,
                liveName: "",
                list_member: [],
                mixedClassStatus: "N"
            },

        isInkMemoOpened: classTalk.isInkMemoOpened,
        isFileDirRemoveModalOpened: classTalk.isFileDirRemoveModalOpened,
        isFileDownloadingNow: classTalk.isFileDownloadingNow,
        isTeamUpOpened: chat.isOpenedTeamUp,
        isHandsupOpened: live.isOpenedHandsUp
    }),
    {
        emoticonSelectedToggle,
        emoticonSelectedCancel,
        selectEmoticonSeq,

        addFunctionOpenedToggle,
        selectFunctionSeq,

        appendSendMessage,
        appendSendAudioMemo,
        appendSendFileShare,

        handsupOpenToggle,
        teamupOpenToggle,

        inkMemoOpenedToggle,
        voiceMemoClosedToggle,
        fileShareToggle,
        fileOpenToggle,
        imgThumbnailClick,

        getQuizList,
        showQuizResponseInfo,
        showQuizResponseResult,
        quizResponseFinish,

        getDownloadFileList,
        clearDownloadFiles,
        fileDirRemoveToggle,

        openWhisperPopup,

        toggle_alertModal
    }
)(ClassTalkContainer);
import React from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const ForceLogoutAlertModal = ({ isModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isModalOpened} backdrop={false} centered toggle={() => handleSelectedFunc({ selectedFunc: "FORCE_LOGOUT_ALERT_TOGGLE" })}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>{t("중복_로그인_알림", { ns: "user" })}</span>
                    </div>
                    <div className="content-area dark-gray-text">
                        <div className="text-area text-center">
                            <span>{t("로그아웃_알림_문구_3", { ns: "user" })}</span>
                            <span>{t("로그아웃_알림_문구_4", { ns: "user" })}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                        <button type="button" className="btn btn-md green white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "FORCE_LOG_OUT" })}>{t("확인", { ns: "common" })}</button>
                        <button type="button" className="btn btn-md red white-text py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc: "FORCE_LOGOUT_ALERT_TOGGLE" })}>{t("취소", { ns: "common" })}</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ForceLogoutAlertModal;
/** New Rabbitmq */
export const getDefaultDirectKey = function (userSeq, mode) {
    return "C.0." + userSeq + "." + mode;
}

export const getOtherDirectKey = function (userSeq, mode, liveSeq) {
    return "C.0." + userSeq + "." + mode + "." + liveSeq;
}

export const getHandsUpDirectKey = function (teacherSeq, liveSeq, userSeq, rcvUserSeq) {
    return "L." + teacherSeq + "." + liveSeq + "." + userSeq + "." + rcvUserSeq;
}

export const getHandsUpBindKey = function (teacherSeq, userSeq) {
    // return ["L." + teacherSeq + ".*.*", "L." + teacherSeq + ".*.*." + userSeq];
    return [
        "L." + teacherSeq + ".*.*", 
        "L." + teacherSeq + ".*.*." + userSeq,
        "LM." + teacherSeq + ".*.*", 
        "LM." + teacherSeq + ".*.*." + userSeq,
    ];
}

export const getHandsUpSendKey = function (teacherSeq, liveSeq, userSeq) {
    return "L." + teacherSeq + "." + liveSeq + "." + userSeq;
}

export const getTeamUpBindKey = function (roomSeq, userSeq) {
    return ["C." + roomSeq + ".*", "C." + roomSeq + ".*." + userSeq];
}

export const getTeamUpSendKey = function (roomSeq, userSeq) {
    return "C." + roomSeq + "." + userSeq;
}

export const getSmallGroupBindKey = function (liveSeq, groupSeq) {
    return [
        "G." + liveSeq + "." + groupSeq + ".*.*",
        "GM." + liveSeq + "." + groupSeq + ".*.*"
    ];
}

export const getLocalServerKey = (localServerSeq) => {
    return "S." + localServerSeq;
}

export const getReplacedRoutingKeyForMixedClass = (routingKey, localLiveSeq, serverLiveSeq) => {
    try {
        if (routingKey === undefined || routingKey === null) return;

        if (Array.isArray(routingKey)) {
            let newRoutingKeyArr = [];

            for (let i = 0; i < routingKey.length; i++) {
                let searchValue = "." + localLiveSeq + ".";
                let replaceValue = "." + serverLiveSeq + ".";

                let newRoutingKey = routingKey[i].replace(searchValue, replaceValue);
                console.log(`getReplacedRoutingKeyForMixedClass - newRoutingKey[${newRoutingKey}]`);

                newRoutingKeyArr.push(newRoutingKey);
            }

            console.log(`getReplacedRoutingKeyForMixedClass - newRoutingKeyArr[`, newRoutingKeyArr , "]");

            return newRoutingKeyArr;
        } else {
            let searchValue = "." + localLiveSeq + ".";
            let replaceValue = "." + serverLiveSeq + ".";
    
            let newRoutingKey = routingKey.replace(searchValue, replaceValue);
            console.log(`getReplacedRoutingKeyForMixedClass - newRoutingKey[${newRoutingKey}]`);
    
            return newRoutingKey;
        }
    } catch (err) {
        console.log("getReplacedRoutingKeyForMixedClass() - err => ", err);

        return routingKey;
    }
}
import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_quiz_list = ({ userSeq }) => {
    return axiosClient.get(`/quiz?userSEQ=${userSeq}`, options);
}

export const get_quiz_info = ({ quizSeq }) => {
    return axiosClient.get(`/quiz/${quizSeq}`, options);
}

export const create_quiz = ({ userSeq, modalQuiz_info }) => {
    /* let data = {
        userSEQ                 : userSeq,
        questionKind            : modalQuiz_info.questionKind,
        questionDataType        : modalQuiz_info.questionDataType,
        questionData            : modalQuiz_info.questionData,
        answerExampleYN         : modalQuiz_info.answerExampleYN,
    }; */

    let data = { userSEQ: userSeq };

    console.log('create_quiz api - ', data, modalQuiz_info);

    if (modalQuiz_info.quizTitle !== undefined) {
        data.quizTitle = modalQuiz_info.quizTitle;
    }

    if (modalQuiz_info.questionKind !== undefined) {
        data.questionKind = modalQuiz_info.questionKind;
    }

    if (modalQuiz_info.questionDataType !== undefined) {
        data.questionDataType = modalQuiz_info.questionDataType;
    }

    if (modalQuiz_info.questionData !== undefined) {
        data.questionData = modalQuiz_info.questionData;
    }

    if (modalQuiz_info.answerExampleYN !== undefined) {
        data.answerExampleYN = modalQuiz_info.answerExampleYN;
    }

    if (modalQuiz_info.answerExampleSeq !== undefined) {
        data.answerExampleSeq = modalQuiz_info.answerExampleSeq;
    }

    if (modalQuiz_info.answerKind !== undefined) {
        data.answerKind = modalQuiz_info.answerKind;
    }

    if (modalQuiz_info.answerType !== undefined) {
        data.answerType = modalQuiz_info.answerType;
    }

    if (modalQuiz_info.answerData !== undefined) {
        data.answerData = modalQuiz_info.answerData;
    }

    if (modalQuiz_info.congratulationImageType !== undefined) {
        data.congratulationImageType = modalQuiz_info.congratulationImageType;
    }

    if (modalQuiz_info.exampleData !== undefined && modalQuiz_info.exampleData !== null) {
        data.exampleData = modalQuiz_info.exampleData;
    }

    console.log(data);

    return axiosClient.post(`/quiz`, data, options);
}

export const edit_quiz = ({ modalQuiz_info }) => {
    /* let data = {
        questionKind            : modalQuiz_info.questionKind,
        questionDataType        : modalQuiz_info.questionDataType,
        questionData            : modalQuiz_info.questionData,
        answerExampleYN         : modalQuiz_info.answerExampleYN,
    }; */

    let data = {};

    if (modalQuiz_info.quizTitle !== undefined) {
        data.quizTitle = modalQuiz_info.quizTitle;
    }

    if (modalQuiz_info.questionKind !== undefined) {
        data.questionKind = modalQuiz_info.questionKind;
    }

    if (modalQuiz_info.questionDataType !== undefined) {
        data.questionDataType = modalQuiz_info.questionDataType;
    }

    if (modalQuiz_info.questionData !== undefined) {
        data.questionData = modalQuiz_info.questionData;
    }

    if (modalQuiz_info.answerExampleYN !== undefined) {
        data.answerExampleYN = modalQuiz_info.answerExampleYN;
    }

    if (modalQuiz_info.answerExampleSeq !== undefined) {
        data.answerExampleSeq = modalQuiz_info.answerExampleSeq;
    }

    if (modalQuiz_info.answerKind !== undefined) {
        data.answerKind = modalQuiz_info.answerKind;
    }

    if (modalQuiz_info.answerType !== undefined) {
        data.answerType = modalQuiz_info.answerType;
    }

    if (modalQuiz_info.answerData !== undefined) {
        data.answerData = modalQuiz_info.answerData;
    }

    if (modalQuiz_info.congratulationImageType !== undefined) {
        data.congratulationImageType = modalQuiz_info.congratulationImageType;
    }

    if (modalQuiz_info.exampleData !== undefined && modalQuiz_info.exampleData !== null) {
        data.exampleData = modalQuiz_info.exampleData;
    }

    return axiosClient.put(`/quiz/${modalQuiz_info.quizSeq}`, data, options);
}

export const delete_quiz = ({ quizSeq }) => {
    console.log('api delete_quiz - ', quizSeq);
    return axiosClient.delete(`/quiz/${quizSeq}`, options);
}

export const upload_quiz_file = ({ userSeq, file, filename }) => {
    console.log('api upload_quiz_file - ', userSeq);
    const form = new FormData();
    form.append('file', file, filename);
    return axiosClient.post(`/quiz/file-upload/${userSeq}`, form, options);
}

export const remove_quiz_file = ({ userSeq, queryKind, filePath, quizSeq, seq }) => {
    console.log('api remove_quiz_file - ', userSeq, queryKind, filePath, quizSeq, seq);
    let url = `/quiz/file-upload/${userSeq}?kind=${queryKind}&filePath=${filePath}`;

    if (quizSeq !== undefined && quizSeq !== null && quizSeq > -1) {
        url += `&quizSEQ=${quizSeq}`;

        if (queryKind === 'question') {
            url += `&questionSEQ=${seq}`;
        } else if (queryKind === 'example') {
            url += `&exampleSEQ=${seq}`;
        }
    }

    console.log('url => ', url);
    /* return axiosClient.delete(`/quiz/file-upload/${userSeq}?kind=${queryKind}&filePath=${filePath}`); */
    return axiosClient.delete(url, options);
}
import React from 'react';
import { useTranslation } from "react-i18next";
import { BsX } from 'react-icons/bs';
import { FaGrinSquint, FaUserAstronaut, FaIdBadge } from 'react-icons/fa';

const AddFriendWindow = ({ handleSelectedFunc, selectedFunc, countRecommendFriends }) => {
    const { t } = useTranslation();

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const addByRecommend = () => {
        console.log(selectedFunc);
        if(selectedFunc === "ADD" && selectedFunc === "RECOMMEND") {
            selectedFunc = "NONE";
        } else {
            selectedFunc = "RECOMMEND";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const addByKeyword = () => {
        console.log(selectedFunc);
        if(selectedFunc === "ADD" && selectedFunc === "KEYWORD_TOGGLE") {
            selectedFunc = "NONE";
        } else {
            selectedFunc = "KEYWORD_TOGGLE";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const addByID = () => {
        console.log(selectedFunc);
        if(selectedFunc === "ADD" && selectedFunc === "ID_TOGGLE") {
            selectedFunc = "NONE";
        } else {
            selectedFunc = "ID_TOGGLE";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area">
                <span>
                    <b>{t("친구_추가", { ns: "friend" })}</b>
                </span>
                <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={()=>handleSelectedFunc({selectedFunc:"NONE"})}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex justify-content-around align-items-center w-100 py-2">
                <button className="d-flex flex-column align-items-center hiclasstv-btn rounded b-none mx-1 px-1 py-2 area-30" title={t("추천_친구_목록_조회", { ns: "friend" })} onClick={addByRecommend}>
                    <div className="d-flex justify-content-center">
                        <div className="d-flex h-100 justify-content-center align-items-top">
                            <FaGrinSquint className={"font-xxlg hiclasstv-blue-text" + (countRecommendFriends > 0 ? " ml-2" : "")} />
                                {
                                    countRecommendFriends > 0 &&
                                        <div className="h-100 align-items-top ml-n2 p-0">
                                            <span className="badge badge-pill badge-danger">{countRecommendFriends}</span>
                                        </div>
                                }
                        </div>                        
                    </div>
                    <span className="font-weight-bold black-text mt-1 font-xsm">{t("추천_친구", { ns: "friend" })}</span>
                </button>
                <button className="d-flex flex-column align-items-center hiclasstv-btn rounded b-none mx-1 px-1 py-2 area-30" title={t("키워드로_친구_찾기", { ns: "friend" })} onClick={addByKeyword}>
                    <span className="d-flex align-items-center justify-content-center">
                        <FaUserAstronaut className="font-xxlg hiclasstv-blue-text" />
                    </span>
                    <span className="font-weight-bold black-text mt-1 font-xsm">{t("키워드로_추가", { ns: "friend" })}</span>
                </button>
                <button className="d-flex flex-column align-items-center hiclasstv-btn rounded b-none mx-1 px-1 py-2 area-30" title={t("ID로_친구_찾기", { ns: "friend" })} onClick={addByID}>
                    <span className="d-flex align-items-center justify-content-center">
                        <FaIdBadge className="font-xxlg hiclasstv-blue-text" />
                    </span>
                    <span className="font-weight-bold black-text mt-1 font-xsm">{t("ID로_추가", { ns: "friend" })}</span>
                </button>
            </div>
        </div>
    );
}
export default AddFriendWindow;
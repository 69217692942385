import React, { useState, useEffect } from "react";
import { FaTimes, FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import CustomProfileImage from "../../user/profile/CustomProfileImage";

const ChattingMenuInvite = ({ chatRoomSeq, list_inviteableFriends, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lUserSelectArr, setUserSelectArr] = useState([]);

    useEffect(() => {
        if (list_inviteableFriends !== undefined && list_inviteableFriends !== null) {
            const userSelectArr = list_inviteableFriends.map(member => ({
                userSeq: member.userSeq,
                userNickname: member.userNickname,
                profileImgUrl: member.profileImgUrl,
                isPossibleInvite: member.isPossibleInvite,
                isSelect: false
            }));
            setUserSelectArr(userSelectArr);
        }
    }, [list_inviteableFriends]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onClickSearch = (e) => {
        console.log("search click");
    }

    const getSelectedMemberCount = (selectArr) => {
        let count = 0;

        for (let i = 0; i < selectArr.length; i++) {
            if (selectArr[i].isSelect) {
                count++;
            }
        }

        return count;
    }

    const onChangeUnSelectValue = (userSeq) => {
        const arr = lUserSelectArr.map((info) => {
            let value = info.userSeq === userSeq ? {
                userSeq: info.userSeq,
                userNickname: info.userNickname,
                profileImgUrl: info.profileImgUrl,
                isPossibleInvite: info.isPossibleInvite,
                isSelect: false
            } : info;

            return value;
        });

        setUserSelectArr(arr);
    }

    const onChangeSelectValue = (e) => {
        const { id, checked } = e.target;

        if (lUserSelectArr && lUserSelectArr.length > 0) {
            const arr = lUserSelectArr.map((info) => {
                let value = info.userSeq == id ? {
                    userSeq: info.userSeq,
                    userNickname: info.userNickname,
                    profileImgUrl: info.profileImgUrl,
                    isPossibleInvite: info.isPossibleInvite,
                    isSelect: checked
                } : info;

                return value;
            });

            setUserSelectArr(arr);
        }
    }

    const onChangeSearchValue = (e) => {
        console.log("change search value");
    }

    const inviteMember = () => {
        let selectedArr = [];

        if (lUserSelectArr && lUserSelectArr.length > 0) {
            lUserSelectArr.forEach(element => {
                if (element.isSelect) {
                    selectedArr.push(element.userSeq);
                }
            });
        }

        if (selectedArr.length > 0) {
            handleSelectedFunc({ selectedFunc: "INVITE_OK", chatRoomSeq: chatRoomSeq, list_member: selectedArr });
        } else {
            handleSelectedFunc({ selectedFunc: "CHAT_MENU" });
        }
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="title-area justify-content-between">
                <span className="hiclasstv-darkgrey-text button" title={t("취소", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "CHAT_MENU" })}>
                    {t("취소", { ns: "common" })}
                </span>
                <div className="text-area px-2" title={t("멤버_초대", { ns: "chat" })}>
                    <b>{t("멤버_초대", { ns: "chat" })}</b>
                    <span className="hiclass-darkgrey-text mx-1 font-weight-bold">
                        {getSelectedMemberCount(lUserSelectArr)}
                    </span>
                </div>
                <span className="hiclasstv-darkgrey-text button" title={t("팀업_생성", { ns: "chat" })} onClick={() => inviteMember()}>
                    {t("확인", { ns: "common" })}
                </span>
            </div>
            {
                getSelectedMemberCount(lUserSelectArr) > 0 ?
                    <div className="list-group list-group-horizontal w-100 scroll-show scroll-hor-show" style={{ minHeight: 'max-content', overflow: 'auto' }}>
                        {
                            lUserSelectArr.map(select => (
                                select.isSelect ?
                                    <div className="list-group-hitem list-group-hitem-action flex-column align-items-center" key={select.userSeq}>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex pt-2">
                                                <CustomProfileImage url={select.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={select.userNickname} />
                                            </div>
                                            <div className="d-flex align-items-start ml-n3">
                                                <button type="button" className="d-flex btn btn-sm white m-0 p-1" style={{ borderRadius: '1rem' }} title={t("취소", { ns: "common" })} onClick={() => onChangeUnSelectValue(select.userSeq)}>
                                                    <FaTimes className="font-sm" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="text-area pt-1">
                                            <span className="hiclass-text" title={select.userNickname}>
                                                <b>{select.userNickname}</b>
                                            </span>
                                        </div>
                                    </div> :
                                    <div key={select.userSeq} />
                            ))
                        }
                    </div> :
                    <></>
            }
            <div className="input-area">
                <input type="text" className="search" placeholder={`${t("닉네임", { ns: "user" })}, ${t("프로필", { ns: "user" })} ${t("검색", { ns: "common" })}`} onChange={onChangeSearchValue} />
                <div className="input-group-append ml-1" title={t("검색", { ns: "common" })} onClick={onClickSearch}>
                    <span className="d-flex hiclasstv-btn b-none rounded p-1">
                        <FaSearch className="d-flex font-md black-text" />
                    </span>
                </div>
            </div>
            <div className="list-group" style={{ overflow: 'auto' }}>
                {
                    lUserSelectArr.map((friend, index) => (
                        <div key={"friend-" + friend.userSeq} className="d-flex list-group-hitem list-group-hitem-action py-2 px-1 justify-content-center align-items-center">
                            <div className="d-flex area-15 fit-content">
                                <CustomProfileImage url={friend.profileImgUrl} alt={friend.userNickname} />
                            </div>
                            <div className="text-area flex-column align-items-start">
                                <span className="hiclass-text" title={friend.userNickname}>
                                    <b>{friend.userNickname}</b>
                                </span>
                                <span className="hiclass-text hiclasstv-deepDarkgrey-text small" title={friend.userProfile}>
                                    {friend.userProfile}
                                </span>
                            </div>
                            <div className="d-flex area-15 ml-1">
                                <div className="custom-control custom-checkbox pr-0 pl-2" style={{ display: 'inherit' }}>
                                    {
                                        friend.isPossibleInvite === "Y" ?
                                            <input type="checkbox" className="custom-control-input" style={{ position: 'inherit' }} id={friend.userSeq} checked={lUserSelectArr[index].isSelect} onChange={onChangeSelectValue} /> :
                                            <input type="checkbox" className="custom-control-input" style={{ position: 'inherit' }} id={friend.userSeq} checked={false} disabled />
                                    }
                                    <label className="custom-control-label" htmlFor={friend.userSeq} />
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default ChattingMenuInvite;
import React from 'react';
import { useTranslation } from "react-i18next";

import ConstData from '../../../lib/ConstData';

const WebFriendConfigButton = ({ kind, friend_info, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    switch (friend_info.applyStatus) {
        case ConstData.FRIEND_APPLY_STATUS.APPLY :
            return (
                kind === 'send' ? 
                    <td className="d-flex flex-column p-0 px-1 align-items-end justify-content-center area-40 fit-content">
                        <span className="small">{t("수락대기", { ns: "friend" })}</span>
                        <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend_info.userSeq, mode: "web" })}>
                            {t("취소", { ns: "common" })}
                        </button>
                    </td> :
                kind === 'rcv' ?
                    <td className="d-flex flex-column justify-content-center align-items-center px-1 area-20 fit-content">
                        <button type="button" className="btn btn-sm green white-text px-2 py-1 m-0 mb-1" onClick={() => handleSelectedFunc({ selectedFunc: "ACCEPT", friendSeq: friend_info.userSeq, mode: "web" })}>
                            {t("수락", { ns: "friend" })}
                        </button>
                        <button type="button" className="btn btn-sm red white-text px-2 py-1 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "REJECT", friendSeq: friend_info.userSeq, mode: "web" })}>
                            {t("거절", { ns: "common" })}
                        </button>
                    </td> :
                    <></>
            );

        case ConstData.FRIEND_APPLY_STATUS.APPROVE :
            return (
                <td className="d-flex px-1 align-items-center area-20 fit-content">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "DELETE", friendSeq: friend_info.userSeq, mode: "web" })}>
                        {t("삭제", { ns: "common" })}
                    </button>
                </td>
            );

        case ConstData.FRIEND_APPLY_STATUS.REJECT :
            return (
                <td className="d-flex flex-column px-1 align-items-end justify-content-center area-40 fit-content">
                    <span className="small">{t("거절됨", { ns: "friend" })}</span>
                    <div className="d-flex">
                        <button type="button" className="btn btn-sm green white-text py-1 px-2 m-0" onClick={() => handleSelectedFunc({ selectedFunc: "APPLY_SINGLE", friendSeq: friend_info.userSeq, mode: "web" })}>
                            {t("신청", { ns: "friend" })}
                        </button>
                        <button type="button" className="btn btn-sm red white-text py-1 px-2 m-0 ml-1" onClick={() => handleSelectedFunc({ selectedFunc: "CANCEL", friendSeq: friend_info.userSeq, mode: "web" })}>
                            {t("취소", { ns: "friend" })}
                        </button>
                    </div>
                </td>
            );

        default :
            return <></>;
    }
}

export default WebFriendConfigButton;
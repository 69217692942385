import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import createRequestThunk from "../lib/createRequestThunk";

import {
    get_promotion_init_list,
    get_promotion_register_info,
    regist_promotion,
    get_promotion_request_list,
    publish_coupon_code,
    regist_coupon_code
} from "../lib/api/promotion";

export const PROMOTION_STATE_INIT               = "promotion/PROMOTION_STATE_INIT";

const TOGGLE_MODAL_OPENED                       = "promotion/TOGGLE_MODAL_OPENED";
const TOGGLE_MODAL_OPENED_SUCCESS               = "promotion/TOGGLE_MODAL_OPENED_SUCCESS";
const TOGGLE_MODAL_CLOSED                       = "promotion/TOGGLE_MODAL_CLOSED";
const REGIST_PROMOTION_INFO                     = "promotion/REGIST_PROMOTION_INFO";
const REGIST_PROMOTION_INFO_SUCCESS             = "promotion/REGIST_PROMOTION_INFO_SUCCESS";
const REGIST_PROMOTION_CODE                     = "promotion/REGIST_PROMOTION_CODE";
const REGIST_PROMOTION_CODE_SUCCESS             = "promotion/REGIST_PROMOTION_CODE_SUCCESS";

const SET_SELECTED_PROMOTION_CLEAR              = "promotion/SET_SELECTED_PROMOTION_CLEAR";
const GET_PROMOTION_LIST                        = "promotion/GET_PROMOTION_LIST";
const GET_PROMOTION_LIST_SUCCESS                = "promotion/GET_PROMOTION_LIST_SUCCESS";
const GET_PROMOTION_REGIST_INFO                 = "promotion/GET_PROMOTION_REGIST_INFO";
const GET_PROMOTION_REGIST_INFO_SUCCESS         = "promotion/GET_PROMOTION_REGIST_INFO_SUCCESS";
const GET_PROMOTION_REQUEST_LIST                = "promotion/GET_PROMOTION_REQUEST_LIST";
const GET_PROMOTION_REQUEST_LIST_SUCCESS        = "promotion/GET_PROMOTION_REQUEST_LIST_SUCCESS";

const PUBLISH_PROMOTION_COUPON_CODE             = "promotion/PUBLISH_PROMOTION_COUPON_CODE";
const PUBLISH_PROMOTION_COUPON_CODE_SUCCESS     = "promotion/PUBLISH_PROMOTION_COUPON_CODE_SUCCESS";
//export const getPromotionListByUser             = createRequestThunk(GET_PROMOTION_LIST, getPromotionList);
//export const getPromotionListByAdmin            = createRequestThunk(GET_PROMOTION_LIST, getPromotionList);

export const togglePromotionNotiModalOpened     = createRequestThunk(TOGGLE_MODAL_OPENED, get_promotion_register_info);
export const togglePromotionNotiModalClosed     = createAction(TOGGLE_MODAL_CLOSED);
export const registPromotionRequest             = createRequestThunk(REGIST_PROMOTION_INFO, regist_promotion);
export const registPromotionCouponCode          = createRequestThunk(REGIST_PROMOTION_CODE, regist_coupon_code);

export const setPromotionRegistInfoClear        = createAction(SET_SELECTED_PROMOTION_CLEAR);
export const getPromotionList                   = createRequestThunk(GET_PROMOTION_LIST, get_promotion_init_list);
export const getPromotionRegistInfo             = createRequestThunk(GET_PROMOTION_REGIST_INFO, get_promotion_register_info);
export const getPromotionRequestInfo            = createRequestThunk(GET_PROMOTION_REQUEST_LIST, get_promotion_request_list);
export const publishPromotionCouponCode         = createRequestThunk(PUBLISH_PROMOTION_COUPON_CODE, publish_coupon_code);

const initialState = {
    isPromotionNotiModalOpened  : false,

    list_promotion              : [],
    selectedPromotion           : "",
    list_request                : [],

    isCheckedRegistCouponCode   : false,
    isSuccessedRegistCoupon     : false
};

const promotion = handleActions({
    [PROMOTION_STATE_INIT]: (state, action) => {
        return {
            ...state,
        }
    },

    [SET_SELECTED_PROMOTION_CLEAR]: (state, action) => {
        return {
            ...state,
            selectedPromotion : ""
        }
    },

    [GET_PROMOTION_LIST_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { list_promotion } = action.payload;

            if (list_promotion === undefined || list_promotion === null) {
                console.log("promotion list is null");

                return {
                    ...state,
                    list_promotion : []
                }
            } else {
                const { kind } = action.parms;
                //console.log("GET_PROMOTION_LIST_SUCCESS is successed");
                //console.log(list_promotion);

                if (kind === "init") {      // 일반 사용자
                    return {
                        ...state,
                        list_promotion : list_promotion.map(row => (
                            {
                                promotionSeq        : row.PRMT_SEQ,
                                promotionName       : row.PRMT_NM,
                                promotionStart_YMD  : row.PRMT_STRT_YMD,
                                promotionEnd_YMD    : row.PRMT_END_YMD
                            }
                        )),
                        selectedPromotion   : ""
                    }
                } else {                    // 관리자
                    return {
                        ...state,
                        list_promotion : list_promotion.map(row => (
                            {
                                promotionSeq        : row.PRMT_SEQ,
                                promotionName       : row.PRMT_NM,
                                promotionStart_YMD  : row.PRMT_STRT_YMD,
                                promotionEnd_YMD    : row.PRMT_END_YMD,
                                isPublicYN          : row.PUBS_YN
                            }
                        )),
                        selectedPromotion   : ""
                    }
                }                    
            }
        } else {
            console.log("getPromotionList is failed");

            return {
                ...state,
            }
        }
    },

    [GET_PROMOTION_REGIST_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;
        const { promotionSeq } = action.parms;

        if (isSuccessed) {
            const promotion_info = state.list_promotion.find(promotion => promotion.promotionSeq === promotionSeq);
            
            if (promotion_info !== undefined && promotion_info !== null) {
                const { content } = action.payload;

                if (content === undefined || content === null) {
                    console.log("get promotion content list is failed");

                    return {
                        ...state,
                        isPromotionNotiModalOpened : false
                    }
                } else {
                    const { itemsList } = action.payload;

                    if (itemsList === undefined || itemsList === null) {
                        console.log("GET_PROMOTION_REGIST_INFO_SUCCESS ........ item list is null", content);

                        return {
                            ...state,
                            selectedPromotion : {
                                ...promotion_info,
                                promotionTitle          : content.NOTI_TITL,
                                promotionContents       : content.NOTI_CNTN,
                                promotionPassName       : content.PRDT_NM,
                                isRequiredEmailYN       : content.EMAL_INPT_YN,
                                isRequiredLicenseYN     : content.LICN_INPT_YN,
                                isHasCouponYN           : content.CPON_CODE_PUBS_YN,
                                list_item               : []
                            },
                        }
                    } else {
                        //console.log("GET_PROMOTION_REGIST_INFO_SUCCESS", content, itemsList);

                        return {
                            ...state,
                            selectedPromotion : {
                                ...promotion_info,
                                promotionTitle          : content.NOTI_TITL,
                                promotionContents       : content.NOTI_CNTN,
                                promotionPassName       : content.PRDT_NM,
                                isRequiredEmailYN       : content.EMAL_INPT_YN,
                                isRequiredLicenseYN     : content.LICN_INPT_YN,
                                isHasCouponYN           : content.CPON_CODE_PUBS_YN,
                                list_item               : itemsList.map(row => (
                                    {
                                        itemSeq         : row.ITEM_SEQ,
                                        itemName        : row.ITEM_NAME
                                    }
                                ))
                            },
                        }
                    }
                }
            } else {
                console.log("getPromotionRegistInfo is failed ....... select promotion is except");

                return {
                    ...state,
                    selectedPromotion   : "",
                }
            }
        } else {
            console.log("getPromotionRegistInfo is failed .......");

            return {
                ...state,

            }
        }
    },

    [GET_PROMOTION_REQUEST_LIST_SUCCESS]: (state, action) => {
        //console.log("GET_PROMOTION_REQUEST_LIST_SUCCESS", action.payload);
        const{ promotionSeq } = action.parms;
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const promotion_info = state.list_promotion.find(promotion => promotion.promotionSeq === promotionSeq);

            if (promotion_info !== undefined && promotion_info !== null) {
                const { headResults } = action.payload;

                if (headResults !== undefined && headResults !== null) {
                    const { itemsList } = action.payload;

                    if (itemsList !== undefined && itemsList !== null) {
                        //console.log("GET_PROMOTION_REQUEST_LIST_SUCCESS", itemsList);

                        return {
                            ...state,
                            selectedPromotion   : {
                                ...state.selectedPromotion,
                                cnt_issued      : headResults.ISSU_CNT,
                                cnt_request     : headResults.RQST_CNT
                            },
                            list_request        : itemsList.map(row => ({
                                requestSeq              : row.RQST_SEQ,
                                requestLicense          : row.LICN_CD,
                                requestEmail            : row.EMAL_ADD,
                                requestDate             : row.RQST_DTTM,
                                list_item               : [
                                    row.ITEM_INFO_1, 
                                    row.ITEM_INFO_2, 
                                    row.ITEM_INFO_3, 
                                    row.ITEM_INFO_4, 
                                    row.ITEM_INFO_5
                                ],
                                isPublishedCouponCodeYN : row.CPON_PUBS_YN,
                                isLicenseValidateYN     : row.LICN_VALD_YN,
                            }))
                        }
                    } else {
                        console.log("GET_PROMOTION_REQUEST_LIST_SUCCESS ....... request list is null");

                        return {
                            ...state,
                            selectedPromotion   : {
                                ...state.selectedPromotion,
                                cnt_issued      : headResults.ISSU_CNT,
                                cnt_request     : headResults.RQST_CNT
                            }, 
                            list_request        : []
                        }
                    }
                } else {
                    console.log("getPromotionRequestList is failed ....... select promotion info is null");

                    return {
                        ...state,
                        selectedPromotion : ""
                    }
                }
            } else {
                console.log("getPromotionRequestList is failed ....... select promotion is except");

                return {
                    ...state,
                    selectedPromotion : ""
                }
            }
        } else {
            console.log("getPromotionRequestList is failed ....... ");
            return {
                ...state,
                selectedPromotion : ""
            }
        }
    },

    [PUBLISH_PROMOTION_COUPON_CODE_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isUpdateSuccessed } = action.payload;

            if (isUpdateSuccessed) {
                const { promotionSeq, requestSeq } = action.parms;
                const { couponCode, couponResults } = action.payload;

                if (couponCode !== undefined && couponCode !== null && couponResults !== undefined && couponResults !== null) {

                    return produce(state, draft => {
                        const request_info = draft.list_request.find(request => request.requestSeq === requestSeq);

                        if (request_info !== undefined && request_info !== null) {
                            console.log("PUBLISH_PROMOTION_COUPON_CODE_SUCCESS");

                            request_info.isPublishedCouponCodeYN = "Y";
                        } else {
                            console.log("publishPromotionCouponCode is failed ....... coupon code is null");
                        }

                        if (draft.selectedPromotion.promotionSeq === promotionSeq) {
                            draft.selectedPromotion.cnt_issued += 1;
                        }
                    });
                } else {
                    console.log("publishPromotionCouponCode is failed ....... coupon code is null");
                }
            } else {
                console.log("publishPromotionCouponCode is failed ....... publish code is failed")
                
                return {
                    ...state
                }
            }
        } else {
            console.log("publishPromotionCouponCode is failed .......");

            return {
                ...state
            }
        }            
    },

    // 프로모션 신청
    [REGIST_PROMOTION_INFO_SUCCESS]: (state, action) => {
        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isInsertSuccessed } = action.payload;

            if (isInsertSuccessed) {
                console.log("REGIST_PROMOTION_INFO_SUCCESS");

                return {
                    ...state,
                    isPromotionNotiModalOpened : false
                }
            } else {
                console.log("registPromotionInfo is failed ....... regist promotion info is failed");
                
                return {
                    ...state
                }
            }
        } else {
            console.log("registPromotionInfo is failed .......");

            return {
                ...state
            }
        }
    },

    // 쿠폰 번호로 이용권 등록
    [REGIST_PROMOTION_CODE_SUCCESS]: (state, action) => {
        //console.log("REGIST_PROMOTION_CODE_SUCCESS", action.payload);

        const { isSuccessed } = action.payload;

        if (isSuccessed) {
            const { isUpdateSuccessed } = action.payload;

            if (isUpdateSuccessed) {
                const { couponResults } = action.payload;

                if (couponResults !== undefined && couponResults !== null) {
                    if (state.selectedPromotion.promotionSeq === couponResults.PRMT_SEQ) {
                        console.log("REGIST_PROMOTION_CODE_SUCCESS", action.payload);

                        return {
                            ...state,
                            isPromotionNotiModalOpened  : false,
                            isCheckedRegistCouponCode   : true,
                            isSuccessedRegistCoupon     : true
                        }
                    } else {
                        console.log("coupon code's promotion is except");
                        
                        return {
                            ...state,
                            isCheckedRegistCouponCode: true,
                            isSuccessedRegistCoupon: false
                        }
                    }
                } else {
                    console.log("registPromotionCode is successed ....... coupon code info is null");

                    return {
                        ...state,
                        isCheckedRegistCouponCode   : true,
                        isSuccessedRegistCoupon     : true
                    }
                }
            } else {
                console.log("registPromotionCode is failed ....... coupon code is wrong");

                return {
                    ...state,
                    isCheckedRegistCouponCode   : true,
                    isSuccessedRegistCoupon     : false,
                }    
            }
        } else {
            console.log("registPromotionCode is failed .......");

            return {
                ...state,
                isCheckedRegistCouponCode : false,
                isSuccessedRegistCoupon : false,
            }
        }            
    },

    [TOGGLE_MODAL_OPENED_SUCCESS]: (state, action) => {
        //console.log("TOGGLE_MODAL_OPENED_SUCCESS", action.payload);

        const { isSuccessed } = action.payload;
        const { promotionSeq } = action.parms;

        if (isSuccessed) {
            const promotion_info = state.list_promotion.find(promotion => promotion.promotionSeq === promotionSeq);
            
            if (promotion_info !== undefined && promotion_info !== null) {
                const { content } = action.payload;

                if (content === undefined || content === null) {
                    console.log("get promotion content list is failed");

                    return {
                        ...state,
                        isPromotionNotiModalOpened : false
                    }
                } else {
                    const { itemsList } = action.payload;

                    if (itemsList === undefined || itemsList === null) {
                        console.log("TOGGLE_MODAL_OPENED_SUCCESS ........ item list is null", content);

                        return {
                            ...state,
                            selectedPromotion : {
                                ...promotion_info,
                                promotionTitle          : content.NOTI_TITL,
                                promotionContents       : content.NOTI_CNTN,
                                promotionPassName       : content.PRDT_NM,
                                isRequiredEmailYN       : content.EMAL_INPT_YN,
                                isRequiredLicenseYN     : content.LICN_INPT_YN,
                                isHasCouponYN           : content.CPON_CODE_PUBS_YN,
                                list_item               : []
                            },
                            isPromotionNotiModalOpened  : true,
                            isCheckedRegistCouponCode   : false,
                            isSuccessedRegistCoupon     : false,
                        }
                    } else {
                        console.log("TOGGLE_MODAL_OPENED_SUCCESS", content, itemsList);

                        return {
                            ...state,
                            selectedPromotion : {
                                ...promotion_info,
                                promotionTitle          : content.NOTI_TITL,
                                promotionContents       : content.NOTI_CNTN,
                                promotionPassName       : content.PRDT_NM,
                                isRequiredEmailYN       : content.EMAL_INPT_YN,
                                isRequiredLicenseYN     : content.LICN_INPT_YN,
                                isHasCouponYN           : content.CPON_CODE_PUBS_YN,
                                list_item               : itemsList.map(row => (
                                    {
                                        itemSeq         : row.ITEM_SEQ,
                                        itemName        : row.ITEM_NAME
                                    }
                                ))
                            },
                            isPromotionNotiModalOpened  : true,
                            isCheckedRegistCouponCode   : false,
                            isSuccessedRegistCoupon     : false,
                        }
                    }
                }
            } else {
                console.log("select promotion is except");

                return {
                    ...state,
                    selectedPromotion : "",
                    isPromotionNotiModalOpened  : false
                }
            }
        } else {
            console.log("getPromotionRegistInfo is failed");

            return {
                ...state,
                isPromotionNotiModalOpened : false
            }
        }
    },

    [TOGGLE_MODAL_CLOSED]: (state, action) => {
        console.log("TOGGLE_MODAL_CLOSED", action.payload);

        return {
            ...state,
            isCheckedRegistCouponCode   : false,
            isSuccessedRegistCoupon     : false,
            isPromotionNotiModalOpened  : false,
            selectedPromotion           : ""
        }
    },
}, initialState);

export default promotion;
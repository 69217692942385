import React, { useState, useEffect, useRef, createRef } from "react";

import SmartTVControlTool from "./SmartTVControlTool";
import SmartTVMediaSoupRcv from "./SmartTVMediaSoupRcv";
import SmartTVMediaSoupRcvForStd from "./SmartTVMediaSoupRcvForStd";

const SmartTVRcvView = ({ currentPageType, userNickname, performLiveInfo, performLiveStatusInfo, smartTVBindInfo, isModalOpened, isRbmqServerConnected, isReadyLiveConsume, isConnectedOnScreenApp, list_isConnectedOnScreenApp, handleSelectedFunc }) => {

    const [list_member, setList_member] = useState(list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0 ? list_isConnectedOnScreenApp : [{ userSeq: -1, userNickname: "", isConnectedOnScreenApp: false }]);

    const mediaRef = useRef(null);
    // const imageRef = useRef(null);
    const controlRef = useRef(null);

    const memberCnt = list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0 ? list_isConnectedOnScreenApp.length : 1;
    const stdMediaRefs = useRef(new Array(memberCnt));

    const timesValue = 240;
    const maxPlusRightValue = timesValue * (memberCnt - 1);

    useEffect(() => {
        const memberCnt = list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0 ? list_isConnectedOnScreenApp.length : 1;
        stdMediaRefs.current = new Array(memberCnt);
        for (let i = 0; i < stdMediaRefs.current.length; i++) {
            stdMediaRefs.current[i] = createRef();
            console.log(`memberCnt [${memberCnt}], i[${i}] stdMediaRefs.current[${i}] => `, stdMediaRefs.current[i]);
        }
    }, []);

    useEffect(() => {
        console.log("list_isConnectedOnScreenApp => ", list_isConnectedOnScreenApp);
        if (list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0) {
            console.log("stdMediaRefs.current.length - ", stdMediaRefs.current.length);
            for (let i = 0; i < list_isConnectedOnScreenApp.length; i++) {
                let isConnectedOnScreenApp = list_isConnectedOnScreenApp[i];
                if (!isConnectedOnScreenApp) {
                    console.log(`stdMediaRefs.current[${i}] - `, stdMediaRefs.current[i]);
                    if (stdMediaRefs.current[i]) {
                        if (stdMediaRefs.current[i].current) {
                            stdMediaRefs.current[i].current.initConnectImg();
                        } else {
                            stdMediaRefs.current[i].initConnectImg();
                        }
                    }
                }
            }
            setList_member(list_isConnectedOnScreenApp);
        }
    }, [list_isConnectedOnScreenApp]);

    const onSwitchView = ({ kind, idx, userSeq }) => {
        console.log("onSwitchView - ", kind, idx, userSeq);
        if (idx !== undefined && idx !== null) {
            if (kind === "media_full_mode") {
                mediaRef.current.updateMediaView({
                    className: "main-view",
                    width: "100%",
                    display: "block",
                    zIndex: "unset",
                });
                controlRef.current.updateControlTool({ viewMode: "default" });

                // let groupLimit = performLiveInfo.groupLimit;
                let groupLimit = memberCnt === 1 ? 4 : memberCnt;

                if (stdMediaRefs.current) {
                    stdMediaRefs.current.forEach((stdMediaRef, index) => {
                        let className = `small-view-${groupLimit}`;

                        if (memberCnt > 1) {
                            className += ` view-${index + 1}`;
                        } else {
                            className += " view-4";
                        }

                        stdMediaRef = stdMediaRef.current || stdMediaRef;
                        stdMediaRef.updateStdMediaView({
                            className,
                            width: "240px"
                        });

                        /* if (stdMediaRef.current) {
                            stdMediaRef.current.updateStdMediaView({
                                className,
                                width: "240px"
                            });
                        } else {
                            stdMediaRef.updateStdMediaView({
                                className,
                                width: "240px"
                            });
                        } */
                    });
                }
            } else if (kind === "image_full_mode") {
                // let groupLimit = performLiveInfo.groupLimit;
                let groupLimit = memberCnt === 1 ? 4 : memberCnt;

                mediaRef.current.updateMediaView({
                    className: `small-view-${groupLimit} view-${memberCnt > 1 ? 1 : 4}`,
                    width: "240px",
                    display: "block",
                    zIndex: 5
                });
                controlRef.current.updateControlTool({ viewMode: "default" });

                if (stdMediaRefs.current) {
                    stdMediaRefs.current.forEach((stdMediaRef, index) => {
                        let width = "240px";
                        let className = "";

                        if (index === idx) {
                            width = "100%";
                            className = "main-view";
                        } else {
                            if (index < idx) {
                                className = `small-view-${groupLimit} view-${index + 2}`;
                            } else {
                                className = `small-view-${groupLimit} view-${index + 1}`;
                            }
                        }

                        stdMediaRef = stdMediaRef.current || stdMediaRef;
                        stdMediaRef.updateStdMediaView({
                            className,
                            width
                        });

                        /* if (stdMediaRef.current) {
                            stdMediaRef.current.updateStdMediaView({
                                className,
                                width
                            });
                        } else {
                            stdMediaRef.updateStdMediaView({
                                className,
                                width
                            });
                        } */
                    });
                }
            }
        } /* else {
            if (imageRef.current && imageRef.current.getVisibility() === "visible") {
                if (kind === "media_full_mode") {
                    mediaRef.current.updateMediaView({
                        className: "main-view",
                        width: "100%",
                        zIndex: "unset"
                    });
                    imageRef.current.updateSize({
                        width: "240px",
                        height: "135px",
                        zIndex: 5
                    });
                    controlRef.current.updateControlTool({ viewMode: "default" });
                } else if (kind === "image_full_mode") {
                    mediaRef.current.updateMediaView({
                        className: "small-view-4 view-4",
                        width: "240px",
                        zIndex: 5
                    });
                    imageRef.current.updateSize({
                        width: "100%",
                        height: "100%",
                        zIndex: "unset"
                    });
                    controlRef.current.updateControlTool({ viewMode: "default" });
                }
            }
        } */
    }

    const onToggleImageView = ({ display, value }) => {
        if (mediaRef.current) {
            mediaRef.current.updateMediaView({
                display,
                visibility: value,
                isImageViewToggle: true
            });
        }

        if (stdMediaRefs.current) {
            stdMediaRefs.current.forEach((stdMediaRef, index) => {
                stdMediaRef = stdMediaRef.current || stdMediaRef;
                stdMediaRef.updateStdMediaView({
                    display,
                    visibility: value,
                    isImageViewToggle: true
                });

                /* if (stdMediaRef.current) {
                    stdMediaRef.current.updateStdMediaView({
                        display,
                        visibility: value
                    });
                } else {
                    stdMediaRef.updateStdMediaView({
                        display,
                        visibility: value
                    });
                } */
            });
        }
    }

    const onToggleViewMode = ({ value }) => {
        if (value === "default") {
            mediaRef.current.updateMediaView({
                className: "main-view",
                width: "100%",
                display: "block",
                zIndex: "unset",
                isMultiViewToggle: true
            });

            // let groupLimit = performLiveInfo.groupLimit;
            let groupLimit = memberCnt === 1 ? 4 : memberCnt;

            if (stdMediaRefs.current) {
                stdMediaRefs.current.forEach((stdMediaRef, index) => {
                    stdMediaRef = stdMediaRef.current || stdMediaRef;
                    stdMediaRef.updateStdMediaView({
                        className: `small-view-${groupLimit} view-${index + 1}`,
                        width: "240px"
                    });

                    /* if (stdMediaRef.current) {
                        stdMediaRef.current.updateStdMediaView({
                            className: `small-view-${groupLimit} view-${index + 1}`,
                            width: "240px"
                        });
                    } else {
                        stdMediaRef.updateStdMediaView({
                            className: `small-view-${groupLimit} view-${index + 1}`,
                            width: "240px"
                        });
                    } */
                });
            }
        } else {
            mediaRef.current.updateMediaView({
                display: "none",
                zIndex: "unset",
                isMultiViewToggle: true
            });

            // let groupLimit = performLiveInfo.groupLimit;
            let groupLimit = memberCnt === 1 ? 4 : memberCnt;
            let parent = document.getElementById("live-area");
            let parentWidth = parent.clientWidth;
            let parentHeight = parent.clientHeight - 44;    // height - control toolbar height ... by hjkim 20231207
            console.log(`groupLimit[${groupLimit}], memberCnt[${memberCnt}], parentWidth[${parentWidth}], parentHeight[${parentHeight}]`);

            let width = 240;
            let height = 135;

            if (groupLimit <= 4) {
                width = parentWidth / 2;
                height = parentHeight / 2;
            } else if (groupLimit > 4 && groupLimit <= 6) {
                width = parentWidth / 3;
                height = parentHeight / 2;
            } else {
                width = parentWidth / 3;
                height = parentHeight / 3;
            }

            console.log(`width[${width}], height[${height}]`);

            if (stdMediaRefs.current) {
                stdMediaRefs.current.forEach((stdMediaRef, index) => {
                    stdMediaRef = stdMediaRef.current || stdMediaRef;
                    stdMediaRef.updateStdMediaView({
                        className: `multi-view-${groupLimit} view-${index + 1}`,
                        width
                    });

                    /* if (stdMediaRef.current) {
                        stdMediaRef.current.updateStdMediaView({
                            className: `multi-view-${groupLimit} view-${index + 1}`,
                            width
                        });
                    } else {
                        stdMediaRef.updateStdMediaView({
                            className: `multi-view-${groupLimit} view-${index + 1}`,
                            width
                        });
                    } */
                });
            }
        }
    }

    const initViewMode = ({ kind }) => {
        if (kind === "media_full_mode") {
            mediaRef.current.updateMediaView({
                className: "main-view",
                width: "100%",
                display: "block",
                zIndex: "unset",
            });
            controlRef.current.updateControlTool({ viewMode: "default", visibility: "visible" });

            // let groupLimit = performLiveInfo.groupLimit;
            let groupLimit = memberCnt === 1 ? 4 : memberCnt;

            if (stdMediaRefs.current) {
                stdMediaRefs.current.forEach((stdMediaRef, index) => {
                    let className = `small-view-${groupLimit}`;

                    if (memberCnt > 1) {
                        className += ` view-${index + 1}`;
                    } else {
                        className += " view-4";
                    }

                    stdMediaRef = stdMediaRef.current || stdMediaRef;
                    stdMediaRef.updateStdMediaView({
                        className,
                        width: "240px",
                        display: "block",
                        visibility: "visible",
                        zIndex: 5,
                    });
                });
            }
        }
    }

    const onClickConnectApp = () => {
        if (stdMediaRefs.current) {
            stdMediaRefs.current.forEach((stdMediaRef, index) => {
                stdMediaRef = stdMediaRef.current || stdMediaRef;
                stdMediaRef.sendMessageToStd({ kind: "TRY_CONN_APP", isRbmqServerConnected });
            });
        }
    }

    return (
        <div id="live-area" className="live-entire-area">
            <div className="live-layer-area">
                <div className="live-area mediasoup">
                    <SmartTVMediaSoupRcv
                        ref={mediaRef}
                        // idx={4}
                        userSeq={performLiveInfo.teacher_info.userSeq}
                        idx={list_isConnectedOnScreenApp.length}
                        isModalOpened={isModalOpened}
                        currentPageType={currentPageType}
                        performLiveInfo={performLiveInfo}
                        performLiveStatusInfo={performLiveStatusInfo}
                        smartTVBindInfo={smartTVBindInfo}
                        onSwitchView={onSwitchView}
                        handleSelectedFun={handleSelectedFunc}
                    />
                    {
                        // list_isConnectedOnScreenApp && list_isConnectedOnScreenApp.length > 0 && 
                        // list_isConnectedOnScreenApp.map((info, idx) => (
                        list_member && list_member.length > 0 &&
                        list_member.map((info, idx) => (
                            <SmartTVMediaSoupRcvForStd
                                key={"smart_image_" + idx}
                                ref={stdMediaRefs.current[idx]}
                                idx={idx}
                                isModalOpened={isModalOpened}
                                userSeq={info.userSeq}
                                userNickname={info.userNickname}
                                liveSeq={performLiveInfo.liveSeq}
                                defaultClassName={memberCnt > 1 ? `small-view-${memberCnt} view-${idx + 1}` : "small-view-4 view-4"}
                                isRbmqServerConnected={isRbmqServerConnected}
                                isReadyLiveConsume={isReadyLiveConsume}
                                isConnectedOnScreenApp={info.isConnectedOnScreenApp}
                                initViewMode={initViewMode}
                                onSwitchView={onSwitchView}
                                handleSelectedFunc={handleSelectedFunc}
                            />
                        ))
                    }
                </div>
            </div>
            <SmartTVControlTool
                ref={controlRef}
                currentPageType={currentPageType}
                userNickname={userNickname}
                teacherInfo={performLiveInfo.teacher_info}
                performLiveInfo={performLiveInfo}
                performLiveStatusInfo={performLiveStatusInfo}
                smartTVBindInfo={smartTVBindInfo}
                isConnectedOnScreenApp={isConnectedOnScreenApp}
                list_isConnectedOnScreenApp={list_isConnectedOnScreenApp}
                onToggleImageView={onToggleImageView}
                onToggleViewMode={onToggleViewMode}
                onClickConnectApp={onClickConnectApp}
                handleSelectedFunc={handleSelectedFunc}
            />
        </div>
    );
}

export default SmartTVRcvView;
import React from "react";
import { useTranslation } from "react-i18next";

const WebBroadcastLiveStatus = ({ liveStatus, liveName }) => {
    const { t } = useTranslation();

    return (
        <div className="text-area">
            <span className={(liveStatus === "Y" ? "info-title " : "") + "hiclass-text"} title={liveName}>
                <b>{liveName}</b>
            </span>
            {
                liveStatus === "Y" ?
                    <span className="live-status on ml-1" title={t("클래스_시작", { ns: "live" })}>
                        {t("온", { ns: "live" })}
                    </span> :
                    <span className="live-status off ml-1" title={t("클래스_종료", { ns: "live" })}>
                        {t("오프", { ns: "live" })}
                    </span>
            }
        </div>
    )
}

const WebWatchLiveStatus = ({ isGuestYn, broadcastOpenYN, liveStatus, liveName }) => {
    const { t } = useTranslation();

    return (
        <div className="text-area">
            {
                isGuestYn === "Y" ?
                    broadcastOpenYN === "Y" && liveStatus === "Y" &&
                    <span className="live-status onAir mr-1">
                        <b>{t("온에어", { ns: "live" })}</b>
                    </span> :
                    liveStatus === "Y" &&
                    <span className="live-status onAir mr-1">
                        <b>{t("온에어", { ns: "live" })}</b>
                    </span>
            }
            {/* {
                liveStatus === "Y" &&
                <span className="live-status onAir mr-1">
                    <b>{t("온에어", { ns: "live" })}</b>
                </span>
            } */}
            <span className={(liveStatus === "Y" ? "info-title " : "") + "hiclass-text"} title={liveName}>
                <b>{liveName}</b>
            </span>
        </div>
    );
}

const WebLiveStatus = ({ kind, isGuestYn, broadcastOpenYN, liveStatus, liveName }) => {
    switch (kind) {
        case "broadcast":
            return <WebBroadcastLiveStatus liveStatus={liveStatus} liveName={liveName} />;

        default:
            return <WebWatchLiveStatus isGuestYn={isGuestYn} broadcastOpenYN={broadcastOpenYN} liveStatus={liveStatus} liveName={liveName} />;
    }
}

export default WebLiveStatus;
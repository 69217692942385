export const enTabPacketType = {
    Text        : 0,
    Binary      : 1
};

export const remoconType = {
    None            : -1,
    Ink             : 0,
    HandsUP         : 1,
    TeamUp          : 2,
    PullScreen      : 3,
    PushScreen      : 4,
    SoundControl    : 5,
    ScreenControl   : 6
};

export const enDrawingMode = {
    Pen             : 0,
    Pencil          : 1,
    Marker          : 2,
    Selector        : 3,
    Hand            : 4,
    EraseStroke     : 5,
    ErasePobyte     : 6  
};

export const enTabStatus = {
    Disconnected    : 0,
    Connected       : 1,
    Already         : 2,
    NoUser          : 3
};

export const enMQType = {
    None        : 0,
    Video       : 1,
    Audio       : 2,
    InkMedia    : 3,
    Files       : 4,
    Office      : 5,
    Download    : 6,
    Voice       : 7
};

export const ctBubbleKind = {
    Info        : 0,
    RcvInfo     : 1,
    SendMsg     : 2,
    RcvMsg      : 3,
    SendWhisper : 4,
    RcvWhisper  : 5,
};

export const ctRcvKind = {
    Teacher     : 0,
    Student     : 1,
    Team        : 2,
    sendPentalk : 5,
    rcvPentalk  : 6,
    infoPentalk : 7,
    sendWhisper : 8,
    rcvWhisper  : 9,
    Card        : 10,
};

export const ctMsgInfoKind = {
    InkMemo     : 0,
    VoiceMemo   : 1,
    FileShare   : 2,
    PageJpeg    : 3
};

/**
 * enFrame
 */
export const enFrame = {
    /****** ▶ 초당 약 2프레임 ******/
    TWO_FRAMES                  : 2,

    /****** ▶ 초당 약 2.3프레임 ******/
    TWO_POINT_THREE_FRAMES      : 2.3,

    /****** ▶ 초당 약 2.5프레임 ******/
    TWO_POINT_FIVE_FRAMES       : 2.5,

    /****** ▶ 초당 약 2.8프레임 ******/
    TWO_POINT_EIGHT_FRAMES      : 2.8,

    /****** ▶ 초당 약 3프레임 ******/
    THREE_FRAMES                : 3,

    /****** ▶ 초당 약 3.3프레임 ******/
    THREE_POINT_THREE_FRAMES    : 3.3,

    /****** ▶ 초당 약 3.5프레임 ******/
    THREE_POINT_FIVE_FRAMES     : 3.5,

    /****** ▶ 초당 약 3.8프레임 ******/
    THREE_POINT_EIGHT_FRAMES    : 3.8,

    /****** ▶ 초당 약 4프레임 ******/
    FOUR_FRAMES                 : 4,

    /****** ▶ 초당 약 4.3프레임 ******/
    FOUR_POINT_THREE_FRAMES     : 4.3,

    /****** ▶ 초당 약 4.5프레임 ******/
    FOUR_POINT_FIVE_FRAMES      : 4.5,

    /****** ▶ 초당 약 5프레임 ******/
    FIVE_FRAMES                 : 5,

    /****** ▶ 초당 약 5.98프레임 ******/
    SIX_FRAMES                  : 6,

    /****** ▶ 초당 약 6.289프레임 ******/
    SIX_POINT_THREE_FRAMES      : 6.3,

    /****** ▶ 초당 약 6.49프레임 ******/
    SIX_POINT_FIVE_FRAMES       : 6.5,

    /****** ▶ 초당 약 6.99프레임 ******/
    SEVEN_FRAMES                : 7,

    /****** ▶ 초당 약 7.29프레임 ******/
    SEVEN_POINT_THREE_FRAMES    : 7.3,

    /****** ▶ 초당 약 7.518프레임 ******/
    SEVEN_POINT_FIVE_FRAMES     : 7.5,

    /****** ▶ 초당 약 7.8125프레임 ******/
    SEVEN_POINT_EIGHT_FRAMES    : 7.8,

    /****** ▶ 초당 약 8프레임 ******/
    EIGHT_FRAMES                : 8,

    /****** ▶ 초당 약 8.9프레임 ******/
    NINE_FRAMES                 : 9,

    /****** ▶ 초당 약 10프레임 ******/
    TEN_FRAMES                  : 10,

    /****** ▶ 초당 약 10.9프레임 ******/
    ELEVEN_FRAMES               : 11,

    /****** ▶ 초당 약 11.9프레임 ******/
    TWELVE_FRAMES               : 12,

    /****** ▶ 초당 약 12.5프레임 ******/
    TWELVE_POINT_FIVE_FRAMES    : 12.5,

    /****** ▶ 초당 약 12.9프레임 ******/
    THIRTEEN_FRAMES             : 13,

    /****** ▶ 초당 약 13.8프레임 ******/
    FOURTEEN_FRAMES             : 14,

    /****** ▶ 초당 약 14.9프레임 ******/
    FIFTEEN_FRAMES              : 15,

    /****** ▶ 초당 약 15.38프레임 ******/
    FIFTEEN_POINT_FOUR_FRAMES   : 15.4,

    /****** ▶ 초당 약 15.87프레임 ******/
    SIXTEEN_FRAMES              : 16,

    /****** ▶ 초당 약 16.94프레임 ******/
    SEVENTEEN_FRAMES            : 17,

    /****** ▶ 초당 약 17.85프레임 ******/
    EIGHTEEN_FRAMES             : 18,

    /****** ▶ 초당 약 18.86프레임 ******/
    NINETEEN_FRAMES             : 19,

    /****** ▶ 초당 약 20프레임 ******/
    TWENTY_FRAMES               : 20,

    /****** ▶ 초당 약 23.8프레임 ******/
    TWENTY_FOUR_FRAMES          : 24,

    /****** ▶ 초당 약 29.4프레임 ******/
    THIRTY_FRAMES               : 30,
};

/**
 * enTimeLv
 */
export const enTimeLv = {
    /****** ▶ 초당 약 2프레임 ******/
    TWO_FRAMES                  : 500,

    /****** ▶ 초당 약 2.3프레임 ******/
    TWO_POINT_THREE_FRAMES      : 434,

    /****** ▶ 초당 약 2.5프레임 ******/
    TWO_POINT_FIVE_FRAMES       : 400,

    /****** ▶ 초당 약 2.8프레임 ******/
    TWO_POINT_EIGHT_FRAMES      : 357,

    /****** ▶ 초당 약 3프레임 ******/
    THREE_FRAMES                : 333,

    /****** ▶ 초당 약 3.3프레임 ******/
    THREE_POINT_THREE_FRAMES    : 300,

    /****** ▶ 초당 약 3.5프레임 ******/
    THREE_POINT_FIVE_FRAMES     : 285,

    /****** ▶ 초당 약 3.8프레임 ******/
    THREE_POINT_EIGHT_FRAMES    : 263,

    /****** ▶ 초당 약 4프레임 ******/
    FOUR_FRAMES                 : 250,

    /****** ▶ 초당 약 4.3프레임 ******/
    FOUR_POINT_THREE_FRAMES     : 232,

    /****** ▶ 초당 약 4.5프레임 ******/
    FOUR_POINT_FIVE_FRAMES      : 222,

    /****** ▶ 초당 약 5프레임 ******/
    FIVE_FRAMES                 : 200,

    /****** ▶ 초당 약 5.98프레임 ******/
    SIX_FRAMES                  : 167,

    /****** ▶ 초당 약 6.289프레임 ******/
    SIX_POINT_THREE_FRAMES      : 159,

    /****** ▶ 초당 약 6.49프레임 ******/
    SIX_POINT_FIVE_FRAMES       : 154,

    /****** ▶ 초당 약 6.99프레임 ******/
    SEVEN_FRAMES                : 143,

    /****** ▶ 초당 약 7.29프레임 ******/
    SEVEN_POINT_THREE_FRAMES    : 137,

    /****** ▶ 초당 약 7.518프레임 ******/
    SEVEN_POINT_FIVE_FRAMES     : 133,

    /****** ▶ 초당 약 7.8125프레임 ******/
    SEVEN_POINT_EIGHT_FRAMES    : 128,

    /****** ▶ 초당 약 8프레임 ******/
    EIGHT_FRAMES                : 125,

    /****** ▶ 초당 약 8.9프레임 ******/
    NINE_FRAMES                 : 112,

    /****** ▶ 초당 약 10프레임 ******/
    TEN_FRAMES                  : 100,

    /****** ▶ 초당 약 10.9프레임 ******/
    ELEVEN_FRAMES               : 91,

    /****** ▶ 초당 약 11.9프레임 ******/
    TWELVE_FRAMES               : 84,

    /****** ▶ 초당 약 12.5프레임 ******/
    TWELVE_POINT_FIVE_FRAMES    : 80,

    /****** ▶ 초당 약 12.9프레임 ******/
    THIRTEEN_FRAMES             : 77,

    /****** ▶ 초당 약 13.8프레임 ******/
    FOURTEEN_FRAMES             : 72,

    /****** ▶ 초당 약 14.9프레임 ******/
    FIFTEEN_FRAMES              : 67,

    /****** ▶ 초당 약 15.38프레임 ******/
    FIFTEEN_POINT_FOUR_FRAMES   : 65,

    /****** ▶ 초당 약 15.87프레임 ******/
    SIXTEEN_FRAMES              : 63,

    /****** ▶ 초당 약 16.94프레임 ******/
    SEVENTEEN_FRAMES            : 59,

    /****** ▶ 초당 약 17.85프레임 ******/
    EIGHTEEN_FRAMES             : 56,

    /****** ▶ 초당 약 18.86프레임 ******/
    NINETEEN_FRAMES             : 53,

    /****** ▶ 초당 약 20프레임 ******/
    TWENTY_FRAMES               : 50,

    /****** ▶ 초당 약 23.8프레임 ******/
    TWENTY_FOUR_FRAMES          : 42,

    /****** ▶ 초당 약 29.4프레임 ******/
    THIRTY_FRAMES               : 34,
};

/**
 * stTimeKind
 */
export const stTimeKind = {
    /****** 초당 약 2프레임 ******/
    TWO_FRAMES          : "2-frames",

    /****** 초당 약 3프레임 ******/
    THREE_FRAMES        : "3-frames",

    /****** 초당 약 4프레임 ******/
    FOUR_FRAMES         : "4-frames",

    /****** 초당 약 5프레임 ******/
    FIVE_FRAMES         : "5-frames",

    /****** 초당 약 6프레임 ******/
    SIX_FRAMES          : "6-frames",

    /****** 초당 약 7프레임 ******/
    SEVEN_FRAMES        : "7-frames",

    /****** 초당 약 8프레임 ******/
    EIGHT_FRAMES        : "8-frames",

    /****** 초당 약 10프레임 ******/
    TEN_FRAMES          : "10-frames",

    /****** 초당 약 11.9프레임 ******/
    TWELVE_FRAMES       : "12-frames",

    /****** 초당 약 13.8프레임 ******/
    FOURTEEN_FRAMES     : "14-frames",

    /****** 초당 약 15.87프레임 ******/
    SIXTEEN_FRAMES      : "16-frames",

    /****** 초당 약 23.8프레임 ******/
    TWENTY_FOUR_FRAMES  : "24-frames",

    /****** 초당 약 29.4프레임 ******/
    THIRTY_FRAMES       : "30-frames",
};

/**
 * enImage 변수는 화면 캡처 이미지 크기 정보 값을 정의해둔 변수
 */
export const enImage = {
    /****** HD 의 1/100 사이즈 ******/
    hHD         : { width: 128, height: 72 },

    /****** HDpi 의 1/100 사이즈 ******/
    hHDpi       : { width: 144, height: 81 },

    /****** Full HD의 1/100 사이즈 ******/
    hFHD        : { width: 192, height: 108 },

    /****** qqqHD 보다 (-32, -18) 사이즈 ******/
    qqqHD_MR2   : { width: 208, height: 117 },

    /****** qqqHD 보다 (-16, -9) 사이즈 ******/
    qqqHD_MR1   : { width: 224, height: 126 },

    /****** Full HD의 1/64 사이즈 ******/
    qqqHD       : { width: 240, height: 135 },

    /****** Full HD의 1/36 사이즈 ******/
    qnHD        : { width: 320, height: 180 },

    /****** Full HD의 1/16 사이즈 ******/
    qqHD        : { width: 480, height: 270 },

    /****** nHD 보다 (-128, -72) 사이즈 ******/
    nHD_MR8     : { width: 512, height: 288 },

    /****** nHD 보다 (-112, -63) 사이즈 ******/
    nHD_MR7     : { width: 528, height: 297 },

    /****** nHD 보다 (-96, -54) 사이즈 ******/
    nHD_MR6     : { width: 544, height: 306 },

    /****** nHD 보다 (-80, -45) 사이즈 ******/
    nHD_MR5     : { width: 560, height: 315 },

    /****** nHD 보다 (-64, -36) 사이즈 ******/
    nHD_MR4     : { width: 576, height: 324 },

    /****** nHD 보다 (-48, -27) 사이즈 ******/
    nHD_MR3     : { width: 592, height: 333 },

    /****** nHD 보다 (-32, -18) 사이즈 ******/
    nHD_MR2     : { width: 608, height: 342 },

    /****** nHD 보다 (-16, -9) 사이즈 ******/
    nHD_MR1     : { width: 624, height: 351 },

    /****** Full HD의 1/9 사이즈 ******/
    nHD         : { width: 640, height: 360 },

    /****** Full Wide VGA ******/
    FWVGA       : { width: 854, height: 480 },

    /****** qHD 보다 (-96, -54) 사이즈 ******/
    qHD_MR6     : { width: 864, height: 486 },

    /****** qHD 보다 (-80, -45) 사이즈 ******/
    qHD_MR5     : { width: 880, height: 495 },

    /****** qHD 보다 (-64, -36) 사이즈 ******/
    qHD_MR4     : { width: 896, height: 504 },

    /****** qHD 보다 (-48, -27) 사이즈 ******/
    qHD_MR3     : { width: 912, height: 513 },

    /****** qHD 보다 (-32, -18) 사이즈 ******/
    qHD_MR2     : { width: 928, height: 522 },

    /****** qHD 보다 (-16, -9) 사이즈 ******/
    qHD_MR1     : { width: 944, height: 531 },

    /****** Full HD의 1/4 사이즈 ******/
    qHD         : { width: 960, height: 540 },

    /****** HD 보다 (-256, -144) 사이즈 ******/
    WSVGA       : { width: 1024, height: 576 },

    /****** HD 보다 (-128, -72) 사이즈 ******/
    HD_MR6      : { width: 1152, height: 648 },

    /****** HD 보다 (-80, -45) 사이즈 ******/
    HD_MR5      : { width: 1200, height: 675 },

    /****** HD 보다 (-64, -36) 사이즈 ******/
    HD_MR4      : { width: 1216, height: 684 },

    /****** HD 보다 (-48, -27) 사이즈 ******/
    HD_MR3      : { width: 1232, height: 693 },

    /****** HD 보다 (-32, -18) 사이즈 ******/
    HD_MR2      : { width: 1248, height: 702 },

    /****** HD 보다 (-16, -9) 사이즈 ******/
    HD_MR1      : { width: 1264, height: 711 },

    /****** Full HD의 4/9 사이즈 ******/
    HD          : { width: 1280, height: 720 },

    /****** Full Wide XGA ******/
    FWXGA       : { width: 1366, height: 768 },

    /****** HDpi 보다 (-64, -36) 사이즈 ******/
    HDpi_MR4    : { width: 1376, height: 774 },

    /****** HDpi 보다 (-48, -27) 사이즈 ******/
    HDpi_MR3    : { width: 1392, height: 783 },

    /****** HDpi 보다 (-32, -18) 사이즈 ******/
    HDpi_MR2    : { width: 1408, height: 792 },

    /****** HDpi 보다 (-16, -9) 사이즈 ******/
    HDpi_MR1    : { width: 1424, height: 801 },

    /****** HD 720p / 720i ******/
    HDpi        : { width: 1440, height: 810 },

    /****** HDp 보다 (-128, -72) 사이즈 ******/
    HDp_MR6     : { width: 1472, height: 828 },

    /****** HDp 보다 (-80, -45) 사이즈 ******/
    HDp_MR5     : { width: 1520, height: 855 },

    /****** HDp 보다 (-64, -36) 사이즈 ******/
    HDp_MR4     : { width: 1536, height: 864 },

    /****** HDp 보다 (-48, -27) 사이즈 ******/
    HDp_MR3     : { width: 1552, height: 873 },

    /****** HDp 보다 (-32, -18) 사이즈 ******/
    HDp_MR2     : { width: 1568, height: 882 },

    /****** HDp 보다 (-16, -9) 사이즈 ******/
    HDp_MR1     : { width: 1584, height: 891 },

    /****** HD Plus (HD+) ******/
    HDp         : { width: 1600, height: 900 },

    /****** FHD 보다 (-256, -144) 사이즈 ******/
    FHD_MR8     : { width: 1664, height: 936 },

    /****** FHD 보다 (-192, -108) 사이즈 ******/
    FHD_MR7     : { width: 1728, height: 972 },

    /****** FHD 보다 (-128, -72) 사이즈 ******/
    FHD_MR6     : { width: 1792, height: 1008 },

    /****** FHD 보다 (-80, -45) 사이즈 ******/
    FHD_MR5     : { width: 1840, height: 1035 },

    /****** FHD 보다 (-64, -36) 사이즈 ******/
    FHD_MR4     : { width: 1856, height: 1044 },

    /****** FHD 보다 (-48, -27) 사이즈 ******/
    FHD_MR3     : { width: 1872, height: 1053 },

    /****** FHD 보다 (-32, -18) 사이즈 ******/
    FHD_MR2     : { width: 1888, height: 1062 },

    /****** FHD 보다 (-16, -9) 사이즈 ******/
    FHD_MR1     : { width: 1904, height: 1071 },

    /****** Full HD 2K ******/
    FHD         : { width: 1920, height: 1080 },

    /****** WSVGA의 4배 Wide Quad HD(WQHD) ******/
    QWXGA       : { width: 2048, height: 1152 },

    /****** HD 의 4배 Quad HD(QHD) ******/
    QHD         : { width: 2560, height: 1440 },

    /****** 3K (QHD+ / WQHD+) ******/
    QHDp        : { width: 3200, height: 1800 },

    /****** Full HD 의 4배 Ultra HD 4K / 4K UHD ******/
    UHD         : { width: 3840, height: 2160 },

    /****** Ultra HD Plus 5K (UHD+) ******/
    UHDp        : { width: 5120, height: 2880 },

    /****** Ultra HD 의 4배 Full Ultra HD 8K / 8K UHD ******/
    FUHD        : { width: 7680, height: 4320 }
};

/**
 * enImageQuality
 */
export const enImageQuality = {
    /****** 사전 정의된 이 값은 15입니다. ******/
    Q15         : 15,

    /****** 사전 정의된 이 값은 20입니다. ******/
    Q20         : 20,

    /****** 사전 정의된 이 값은 25입니다. ******/
    Q25         : 25,

    /****** 사전 정의된 이 값은 30입니다. ******/
    Q30         : 30,

    /****** 사전 정의된 이 값은 35입니다. ******/
    Q35         : 35,

    /****** 사전 정의된 이 값은 40입니다. ******/
    Q40         : 40,

    /****** 사전 정의된 이 값은 45입니다. ******/
    Q45         : 45,

    /****** 사전 정의된 이 값은 50입니다. ******/
    Q50         : 50,

    /****** 사전 정의된 이 값은 55입니다. ******/
    Q55         : 55,

    /****** 사전 정의된 이 값은 60입니다. ******/
    Q60         : 60,

    /****** 사전 정의된 이 값은 65입니다. ******/
    Q65         : 65,

    /****** 사전 정의된 이 값은 70입니다. ******/
    Q70         : 70,

    /****** 사전 정의된 이 값은 75입니다. ******/
    Q75         : 75,

    /****** 사전 정의된 이 값은 80입니다. ******/
    Q80         : 80,
};

/**
 * enP2PMode
 */
export const enP2PMode = {
    /****** default value is enImage#nHD 640 x 360, enFrame#THREE_FRAMES 3frames ******/
    Thumbnail   : 0,

    /****** default value is enImage#FHD 1920 x 1080, enFrame#EIGHT_FRAMES 8frames ******/
    P1P         : 1,

    /****** default value is enImage#HD 1280 x 720, enFrame#FIVE_FRAMES 5frames ******/
    P2P         : 2,

    /****** default value is enImage#qHD 960 x 540, enFrame#FIVE_FRAMES 5frames ******/
    P4P         : 4
};

/**
 * stTopKind
 */
export const stTopKind = {
    All_Client          : "all-client",
    P1P_Top_Client      : "1-top-client",
    P2P_Top_Client      : "2-top-client",
    P4P_Top_Client      : "4-top-client",
    Presentation_Client : "present-client"
};

export const enTokenCMD = {
    NULL                    :0,             //O default로 Token을 만들때 NULL 이 되면 아무것도 하지 않게 되니까 Logic 구성시에 성능 업이 된다.

    //ClassTalk/P2P 전송 제어
    ct_Poll                 :1,             //… 나한테 모든것을 맞추도록 하는것과 상대방에게 그렇게 되었다는 답을 강요
    ct_Response             :2, 	        //… Poll 에 대해 내 정보를 Return 한다.
    ct_Visibility           :3, 	        //… CrossTalk 창 전체의 Visibility를 맞춘다.
    ct_Login                :4,		        //… 내가 Login 되었음을 알린다.
    ct_LoginResp            :5,             //...170101 Login Confirm 정보...이걸 받아야만 처리되게 한다.
    ct_Logout               :6,		        //… 내가 Logout 되었음을 알린다.
    ct_LogoutResp           :7,             //...170101 LogOut Confirm 정보

    // Ink 제어 ... 170101 위 제어 명령의 확장을 위하여 아래는 10번부터 시작한다....
    Ink_SDn                 :10,            //1O   SystemPen Down < Stylus, Mouse, Touch….. >
    Ink_SM                  :11,            //O	… SystemPen Move
    Ink_SDM                 :12,            //O	… SystemPen Move with Double Points
    Ink_STM                 :13,            //O	… SystemPen Move with Triple Points
    Ink_SUp                 :14,            //O	… SystemPen Up

    Ink_DDn                 :15,            //O	… DigitalPen Down < SmartPen … Max 35 >
    Ink_DM                  :16,            //O	… DigitalPen Move
    Ink_DDM                 :17,            //O	… DigitalPen Move with Double Points
    Ink_DTM                 :18,            //O	… DigitalPen Move with Triple Points
    Ink_DUp                 :19,            //O	… DigitalPen Up

    Ink_TDn                 :20,            //O… TabletPen Down < Tablet 에서 HiClass 로 들어온 Ink… >
    Ink_TM                  :21,	        //O… TabletPen Move
    Ink_TDM                 :22,	        //O… TabletPen Move with Double Points
    Ink_TTM                 :23,	        //O… TabletPen Move with Triple Points
    Ink_TUp                 :24,	        //O… TabletPen Up
    Ink_Erase1              :25,		    //O… Stroke 하나를 지우는 Token

    // Stroke 제어
    Ink_SingleStroke        :26,        //...InkStroke 하나를 만드는 Token.
    Ink_StrokeCollection    :27,        //...Ink Stroke Collection
    Ink_PageStroke          :28,		//… 현재 페이지의 모든 Ink를 Encoding 하여 Binary Token으로 생성
    Ink_Clear               :29,		//… 현재 페이지의 모든 Stroke 을 지운다.

    Ink_DefaultAttr         :30,     //200710 현재 InkPage의 Mode, Color, Tip 을 한번에 전송한다.
    Ink_Mode                :31,        //… 현재 펜의 Mode를 바꾼다.	< Ink, Marker, Select, EraseByStroke, None >
    Ink_Color               :32,		//… 현재 Ink의 색을 바꾼다. <R><G><B>
    Ink_Tip                 :33,		//… 현재 Ink 의 두께를 바꾼다.
    Ink_Curve               :34,		//… 현재 Ink Rendering 에서 커브화를 할것인지를 나타낸다.
    Ink_RemoveStrokes       :35,        // InkMedia의 Play가 종료되고나면 이 명령에 의해 화면에서 재생된 Stroke들만 찾아서 지운다.

    // Page 제어
    Page_Goto               :36,        //… 특정 페이지로 이동한다.
    Page_Sync               :37,        //… 현재 페이지의 위치와 크기(Zoom) 을 맞춘다.
    Page_TouchScroll        :38,        //… Mouse Wheel 등으로 Scroll 하는 명령
    Page_HandScroll         :39,        //… Mouse Hand로 (특히 칠판) 로 Scroll 하는 명령
    page_PutArtifact        :40,        //...170102 사용자의 현재 InkPage Bitmap을 다른 사용자에게 전송하고, 받은자는 이를 PDF Page의 배경 이미지(artifact)로 저장한다.
    page_GetArtifact        :41,        //...170102 특정 사용자의 현재 InkPage Bitmap을 가져와서 이를 PDF Page의 배경 이미지(artifact)로 저장한다.

    //Contents 제어 … multi-token 사용으로 하나의 전송이 완료되기 전까지 multi-action을 하지 못함.
    cont_PageJpeg           :42,        //… 현재 페이지를 JPEG으로 Capture 하여 보내고 상대방은 이걸 그냥 보드 화면에 꽉차게 넣는다.
    cont_GetPageJpeg        :43,        //… 상대방의 화면을 cont_PageJPeg으로 나에게 보내게 한다.   [OLD]Dicaboard SmartPhone 의 촬영 Animation을 보낸다.
    cont_StudentPageJpeg    :44,        // 학생생화면을 jpeg으로 전송하여 보낸다. 이거는 그냥 펜톡화면에 이미지로 나타난다. 누르면 그때 이미지아노테이션으로 페이지에 삽입된다.
    cont_GetStudentPageJpeg :45,		//… Dicaboard SmartPhone 에서 촬영 확정된 Image를 보낸다.

    cont_PlayMusic          :46,		//… mp3 음성파일을 보내고 나서 자동으로 Play 한다.
    cont_PlayInkMedia       :47,	    //… ImkMedia 파일을 보내고 나서 자동으로 Play 한다.
    cont_PlayPD             :48,		//… PDF 파일을 보내고 나서 자동으로 그 문서를 HiClass 에 열도록 한다.

    chat_FileName           :49,		//… 전송할 파일 이름을 보낸다.
    chat_FileTransfer       :50,	    //… 파일을 전송한다.
    chat_Text               :51,	    //… Text Chatting
    chat_Voice              :52,		//… Voice Chatting
    chat_Memo               :53,        //… Memo Chatting using Pen
    chat_Emoticon           :54,		//… 이모티콘 넘버에 의해 해당 이모티콘이 나타나게 한다.

    //HiClass 제어
    ctl_TPadMove            :55,        //... TPAD 를 움직이면 모든 HiClass 의 TPAD가 움직이게 된다.
    ctl_OnHiPaper           :56,        //... 상대방 HiClass 나 HiNote 에게 HiPaper Mode로 강제로 들어가게 한다.
    ctl_OffHiPaper          :57,        //    상대방 HiClass 나 HiNote 에게 HiPaper Mode에서 강제로 나오게 한다.
    ctl_InkEnable           :58,        //170123 true 면 OnAir, false 면 Ink를 전송하지 않는다.
    ctl_PageInfo            :59,        //170123 현재 HiClass의 페이지 정보를 Client에 전송한다.

    //HiNote, DicaBote 모둠수업 제어...
    modum_PutArtifacts              :60,    //170103 서버에서 모둠 수업용 페이지가 내려옴....자동으로 해당 Page가 Locking됨....HiPaper Mode
    modum_AutoSavePDF               :61,    //170103 서버에서 현재 페이지를 저장하게 함...자동으로 Page 원래대로 해제....Dicaboard/DicaNote Mode
    modum_ScreenMode                :62,    //170130 서버에서 Screen Streaming Mode로 들어감....따라서 DicaNote나 HiNote등 Client들은 메뉴 조정을 해야함...

    //170117 버튼 명령
    control_Button                  :63,    // 페이지 이동, 펜 변경등 버튼 Command

    //170310 RemoteConsole Touch Device
    remocon_Down                    :64,    // Tablet Touch Down
    remocon_Move                    :65,    // Tablet Touch Move
    remocon_Up                      :66,    // Tablet Touch Up
    remocon_RightButton             :67,    // Tablet RightButton
    remocon_DoubleClick             :68,    // Tablet Double Touch

    remocon_StreamMode              :69,    // 170321 HiClass 의 선생님 태블릿에서 HiClass 를 영상모드로 전환하는데 사용...
    page_GetArtifactWithInks        :70,    //...170102 특정 사용자의 현재 InkPage Bitmap을 가져와서 이를 PDF Page의 배경 이미지(artifact)로 저장한다.

    webrtc_ChangeLessonMode         :71,    //170831...Collabo 에서만 사용하고, 상대방의 Lesson Mode를 무조건 나를 기준으로 변경한다....
    webrtc_MyVideoDevice            :72,    //170921...Collabo에서만 사용하고, 현재 내 VideoDevice 즉 HiClass/Webcam/MIC 중 어느것으로 보내고 있는지를 상대방에게 알려준다.
    webrtc_StartEndStreaming        :73,    //170923...상대방의 Streaming을 시작하거나 종료하게 할 수 있다.

    webrtc_PopupMessage             :74,   //171026 MessageBox로 상대방으로 부터 온 메시지를 확인할 수 있게 한다.
    webrtc_RefreshPartnersRcv       :75,   //171107 [Internal Use Only] 상대방의 나에 해당하는 Collabo Receiver 를 Refresh 한다.

    //---- HiClass Modoom 을 위하여 12.16일 새로 들어간 기능들...WIFI 에서만 쓰이는데 이거는 전부 확장해도 좋은 기능들이라...
    cont_PushCurrentScreen          :76,   //171226 상대방의 [윈도우화면:Hotkey] 이나 현재 InkPage를 jpg Stream으로 보내게 한다.
    ct_PaperDirection               :77,   //171226 HiClass의 DocumentPage의 PageDirection을 보낸다.

    //----180404 Google Assistant 나 Alexa 또는 Windows 에서 HiClass를 음성명령으로 제어하는 Token 
    ct_KillPentalkBoard             :79,    // 241009 WIFI 버전과 Pro 버전에서 보드를 죽이는 Program이다.
    AI_VoiceCommand                 :80,    // 

    //----180925 채팅방에 대한 정보들을 주고 받는다...JHK 그런데 왜 필요하지???
    ct_UserLoginInfo                :81,    //로그인 체팅방 로그인 여부 정보
    ct_RoomMemberNumberInfo         :82,    //채팅반 인원수 정보
    ct_LoginUserUnreadChatCnt       :83,    //로그인 사용자 안읽은 채팅수 정보

    //----181025 파일을 Upload하고 거기서 Download 받아서 하는걸로 파일공유 방법을 바꾼다.
    ct_UploadAndShareFiles          :84,
    ct_HelloGetAudio                :85,    //181118 상대방들의 스피커 onoff 현황을 가져온다. "1010" => 0SndPrev:On, 1rcv:Off, 2rcv:On, 3rcv:off
    ct_HelloSetAudio                :86,    //181118 먼저 상대방 SenderPreview의 Audio Preview를 제거하고 "1010" 즉 오디오 데이터를 Return 한다.
    ct_HelloRspAudio                :87,    //HelloRcvAudio/HelloClrAudio 등에 응답하는 Audio 정보 token

    ct_SendToWebJSonInfo            :88,    //190501 [88] Json 형식 명령전달  ---20190418 김주희 추가
    ct_AndroidPageJPEG              :89,    //Android 가 화면잡는것이 안되어 Background에서 잡아서 보내는데 여기서 ImageSource를 만든다.

    ct_ScreenUploadWindow           :90,

    //----220628 핸즈업 이미지 for Pentalk HandsUp
    ct_UpdateHandsUpImage           :91,    //이미지 데이터를 받아 이미지를 갱신한다... ---220628 김혜정 추가 
    rq_DefaultInkAttr               :92,	//230817 클라이언트가 Pencam 에게 Default Ink Attr을 요구한다.
    rq_Ink_PageStroke               :93,    //230817 클라이언트가 Pencam 에게 Default Ink Attr을 요구한다.

    req_ScreenSize                  :94,    //230829 클라이언트가 Pencam에게 현재 모니터의 크기를 요구한다.
    ScreenSize                      :95,    // req_ScreenSize 에 대한 응답 토큰이다.
    ScreenChange                    :96,
    rq_PageClear                    :97,    //231120 Pentalk과 Pencam 이 연결 해제될 경우 페이지 잉크&배경 이미지를 클리어한다. ---김혜정 추가 

    //--------------------------  99 부터는 Error를 처리하는 Pkt Command로 사용한다.
    Err_Token                       :99,    //170523 마지막 한 Byte가 오류나 승인등 서로 Confirm이 필요한 경우에 주고 받는 Token ACK/NACK처리에 사용...
};
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { FaSitemap, FaPlusCircle } from 'react-icons/fa';

import WebAddSubDomain from './WebAddSubDomain';
import WebListArrowButton from '../etc/button/WebListArrowButton';
import WebCreateSubDomainList from './list/WebCreateSubDomainList';

const WebMyCreateSubDomainList = ({ userSeq, userKind, isStandardType, list_subDomainInfo, isCheckSubDomainOK, isAddSubDomainOK, alertMessage, alertKind, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (list_subDomainInfo.length > 0) {
            setIsOpened(true);
        }
    }, [list_subDomainInfo]);

    const goToPassListPage = ({subDomainSeq}) => {
        if (subDomainSeq === 1) {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "USER", subDomainSeq, selectedUserSeq: userSeq });
        } else {
            handleSelectedFunc({ selectedFunc: "GET_PCHS_LIST", kind: "SUB_DOMAIN", subDomainSeq });
        }
    }

    return (
        <div className="web-main admin list-area">
            <div className="card p-2">
                <div className="view gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaSitemap className="font-lg mr-2" aria-hidden="true" />{t("내가_생성한_서브_도메인", { ns: "admin" })}
                        </span>
                        {
                            (userKind === 'hiclasstv' || userKind === 'easy') &&
                            <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addSubDomain">
                                <FaPlusCircle className="font-md mr-1" />{t("서브_도메인_추가", { ns: "admin" })}
                            </button>
                        }
                    </div>
                    <WebListArrowButton toggle_id={'myCreateSubDomainList'} list_array={list_subDomainInfo} isOpened={isOpened} />
                </div>
                {
                    (userKind === 'hiclasstv' || userKind === 'easy') &&
                    <UncontrolledCollapse toggler="#addSubDomain" defaultOpen={false}>
                        <WebAddSubDomain 
                            isCheckSubDomainOK={isCheckSubDomainOK}
                            isAddSubDomainOK={isAddSubDomainOK}
                            alertKind={alertKind}
                            alertMessage={alertMessage}
                            handleSelectedFunc={handleSelectedFunc}
                        />
                    </UncontrolledCollapse>
                }
                <div className="px-2">
                    <UncontrolledCollapse toggler="#myCreateSubDomainList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                        <WebCreateSubDomainList 
                            isStandardType={isStandardType}
                            list_subDomain={list_subDomainInfo}
                            goToPassListPage={goToPassListPage}
                        />
                    </UncontrolledCollapse>
                </div>
            </div>
        </div>
    );
}
export default WebMyCreateSubDomainList;
import React from 'react';
import { useTranslation } from "react-i18next";
import { FaSearch, FaComment, FaCog } from 'react-icons/fa';

const ChatListTitleTemplate = ({ handleSelectedFunc, selectedFunc }) => {
    const { t } = useTranslation();

    const createChatRoom = () => {
        if(selectedFunc === "CREATE") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "CREATE";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const searchChatList = () => {
        if(selectedFunc === "SEARCH") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "SEARCH";
        }
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    const configChatList = () => {
        if(selectedFunc === "CONFIG") {
            selectedFunc = "NONE";    
        } else {
            selectedFunc = "CONFIG";
        } 
        handleSelectedFunc({selectedFunc:selectedFunc});
    }

    return(
        <div className="title-area main justify-content-between white-text" style={{ backgroundColor: '#2874ff' }}>
            <b>{t("팀업_목록", { ns: "chat" })}</b>
            <div className="func-area">
                <button type="button" className={(selectedFunc === "SEARCH" ? " select" : "")} title={t("검색", { ns: "common" })} onClick={searchChatList}>
                    <FaSearch className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "CREATE" ? " select" : "")} title={t("팀업_생성", { ns: "chat" })} onClick={createChatRoom}>
                    <FaComment className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "EDIT" ? " select" : "")} title={t("팀업_관리", { ns: "chat" })} onClick={configChatList}>
                    <FaCog className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default ChatListTitleTemplate;
import React, { useEffect, useState } from "react";
import { BsX, BsArrowLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";

import { stQnAItemType } from "../../../lib/ConstCommand";

import Item from "../../lecture/card/item/Item";

const QuizInfo = ({ quiz_info, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [title, setTitle] = useState(quiz_info.title);
    const [question, setQuestion] = useState(null);
    const [answer, setAnswer] = useState(null);
    const [example, setExample] = useState(null);

    useEffect(() => {
        console.log(quiz_info);
        console.log(quiz_info.data);
        if (quiz_info.data && quiz_info.data.length > 0) {
            let exampleInfo;

            for (let i = 0; i < quiz_info.data.length; i++) {
                let item = quiz_info.data[i];

                switch (item.type) {
                    case stQnAItemType.Question:
                        setQuestion(item.data);

                        if (item.exYN === "Y") {
                            exampleInfo = {
                                type: item.exType,
                                no: item.exNum,
                                list: []
                            }
                        }
                        break;

                    case stQnAItemType.Answer:
                        if (typeof item.data === "string") {
                            setAnswer(item.data);
                        } else if (typeof item.data === "object") {
                            setAnswer(item.data.data);
                        } else {
                            console.log("item.data - ", item.data);
                        }
                        break;

                    case stQnAItemType.Example:
                        let exArr = [];

                        for (let j = 0; j < item.data.length; j++) {
                            let ex = item.data[j];

                            exArr.push(ex);
                        }

                        if (exampleInfo) {
                            exampleInfo = {
                                ...exampleInfo,
                                list: exArr
                            };

                            setExample(exampleInfo);
                        }
                        break;

                    default:
                        break;
                }
            }
        }
    }, [quiz_info]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onHandleSendQuiz = () => {
        //console.log(quiz_info);
        handleSelectedFunc({ selectedFunc: "SEND_QUIZ", data: { quizTitle: quiz_info.title, data: quiz_info.data }, quizSeq: quiz_info.quizSeq });
    }

    return (
        <div className="d-flex flex-column align-items-start" style={{ maxHeight: '80%', minHeight: '200px' }} onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span className="func-btn" style={{ position: 'absolute', left: 0 }} title={t("목록으로_돌아가기", { ns: "classTalk" })} onClick={() => handleSelectedFunc({ selectedFunc: "GO_BACK_QUIZ_LIST" })}>
                    <BsArrowLeft />
                </span>
                <span>Quiz</span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex w-100">
                {
                    quiz_info.data && quiz_info.data.length > 0 ?
                        <div className="quiz-card w-100 p-2">
                            {
                                question &&
                                question.map((item, index) => (
                                    index === 0 ?
                                        <>
                                            <span key={"question-sp-" + index} className="font-weight-bold pr-1">Q.</span>
                                            <Item key={"question-" + index} info={item} title={"q-" + index} />
                                        </> :
                                        <Item key={"question-" + index} info={item} title={"q-" + index} />
                                ))
                            } 
                            {
                                answer && 
                                <div className="d-flex">
                                    <span className="pr-1">A.</span>
                                    <div className="answer-area">
                                        {answer}
                                    </div>
                                </div>
                            }
                            {
                                example &&
                                <div className={"example-area " + example.type}>
                                    {
                                        example.list.map((item, index) => (
                                            <div key={"ex-" + index} className="example-item">
                                                {/*<span className="fit-content">{item.no}.</span>
                                                <Item info={item} title={"ex-" + item.no} />*/}
                                                <span style={{ width: '5%' }}>{item.no}.</span>
                                                <Item info={item} title={"ex-" + item.no} />
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            <div className="d-flex align-items-center justify-content-between">
                                <button type="button" className="hiclasstv-button green font-md white-text w-100" title={t("전송", { ns: "common" })} onClick={() => onHandleSendQuiz()}>
                                    {t("전송", { ns: "common" })}
                                </button>
                            </div>
                        </div> :
                        <></>
                }
            </div>
        </div>
    );
}

export default QuizInfo;
import React from 'react';
import { useTranslation } from "react-i18next";

const ShowResetPassword = ({ isExistUserInfo, handleSelectedFunc, }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column align-items-center my-2">
            <div className="d-flex font-md py-4 px-2">
                {
                    isExistUserInfo ? 
                        <span>
                            {t("비밀번호_변경_성공", { ns: "user" })}
                        </span> : 
                        <span>
                            {t("비밀번호_변경_오류_안내", { ns: "user" })}
                        </span>
                }
            </div>
            <div className="d-flex w-100 justify-content-center">
                {
                    isExistUserInfo ? 
                        <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc: "LOGIN"})}>{t("로그인하기", { ns: "user" })}</button> : 
                        <button className="btn btn-primary btn-sm" onClick={() => handleSelectedFunc({selectedFunc: "CLOSE"})}>{t("확인", { ns: "common" })}</button>
                }
            </div>
        </div>
    );
}

export default ShowResetPassword;
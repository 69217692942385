import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { FaHandPaper, FaBorderAll, FaSearch, FaComment, FaCog, FaTelegramPlane } from 'react-icons/fa';
import ConstData from '../../../lib/ConstData';

const LiveListTitleTemplate = ({ isOnAirLive, isOpenedHandsUp, isOpenedGroupStudy, handleSelectedFunc, selectedFunc }) => {
    const { t } = useTranslation();

    const createLive = () => {
        if (selectedFunc === 'CREATE') {
            selectedFunc = 'NONE';    
        } else {
            selectedFunc = 'CREATE';
        }
        handleSelectedFunc({selectedFunc});
    }

    const searchLiveList = () => {
        if (selectedFunc === 'SEARCH') {
            selectedFunc = 'NONE';
        } else {
            selectedFunc = 'SEARCH';
        }
        handleSelectedFunc({selectedFunc});
    }

    const configLiveList = () => {
        if (selectedFunc === 'CONFIG') {
            selectedFunc = 'NONE';    
        } else {
            selectedFunc = 'CONFIG';
        } 
        handleSelectedFunc({selectedFunc});
    }

    const notifyLiveBroadcastTime = () => {
        if (selectedFunc === 'NOTIFY') {
            selectedFunc = 'NONE';
        } else {
            selectedFunc = 'NOTIFY';
        }
        handleSelectedFunc({selectedFunc});
    }

    const sendMixedClassLink = () => {
        if (selectedFunc === 'SEND_MIXED_CLASS_MAIL') {
            selectedFunc = 'NONE';
        } else {
            selectedFunc = 'SEND_MIXED_CLASS_MAIL';
        }
        handleSelectedFunc({selectedFunc});
    }

    return(
        <div className="title-area main justify-content-between white-text" style={{ backgroundColor: '#2874ff' }}>
            <b>{t("클래스", { ns: "live" })}</b>
            <div className="func-area">
                {/*
                <button type="button" className="hiclasstv-blue-title round" onClick={() => handleSelectedFunc({ selectedFunc: isOpenedHandsUp ? "CLOSE_HANDS_UP_WINDOW" : "OPEN_HANDS_UP_WINDOW" })}>
                    <FaHandPaper className={"font-md" + (isOpenedHandsUp ? " hiclasstv-yellow-text" : " hiclasstv-grey-text")} />
                </button>
                <button type="button" className="hiclasstv-blue-title round" onClick={() => handleSelectedFunc({ selectedFunc: isOpenedGroupStudy ? "CLOSE_GROUP_STUDY_WINDOW" : "OPEN_GROUP_STUDY_WINDOW" })}>
                    <FaBorderAll className={"font-md" + (isOpenedGroupStudy ? " hiclasstv-yellow-text" : " hiclasstv-grey-text")} />
                </button>
                */}
                {/* 
                ::::: Real HiClassTV 서비스 - Guest 초대 ::::
                */}
                {
                    !ConstData.IS_LOCAL_VERSION &&
                    <button type="button" className={(selectedFunc === "NOTIFY" ? "select" : "")} title={t("방송알림", { ns: "hiclasstv" })} onClick={notifyLiveBroadcastTime}>
                        <FaTelegramPlane className="d-flex font-md white-text" />
                    </button>
                }
                {/* 
                ::::: Local HiClassTV 서비스 - Mixed Class (외부접속) 링크 전송 ::::: 
                */}
                {
                    /* ConstData.IS_LOCAL_VERSION &&
                        <button type="button" className={(selectedFunc === "SEND_MIXED_CLASS_MAIL" ? " select" : "")} title={t("믹스드_링크_전송", { ns: "hiclasstv" })} onClick={sendMixedClassLink}>
                            <FaTelegramPlane className="d-flex font-md white-text" />
                        </button> */
                }
                <button type="button" className={(selectedFunc === "SEARCH" ? "select" : "")} title={t("검색", { ns: "common" })} onClick={searchLiveList}>
                    <FaSearch className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "CREATE" ? "select" : "")} title={t("클래스_생성", { ns: "live" })} onClick={createLive}>
                    <FaComment className="d-flex font-md white-text" />
                </button>
                <button type="button" className={(selectedFunc === "EDIT" ? "select" : "")} title={t("클래스_관리", { ns: "live" })} onClick={configLiveList}>
                    <FaCog className="d-flex font-md white-text" />
                </button>
            </div>
        </div>
    );
}

export default LiveListTitleTemplate;
const ConstData = {
    /******************************************************/
    /*----------------------------------------------------*/
    /*--------------- 개발용 (로컬 개인 PC) ---------------*/
    // IS_FOR_DEV              : true,
    // IS_DEV_VALUE            : true,
    // CLOUD_HOST_ADD          : "https://hiclass.tv",
    // MAIN_HOST_ADD           : "https://192.168.3.118",
    // REST_API_HOST_ADD       : "https://192.168.3.118:8001",
    // PAY_HOST_ADD            : ":8443/payment/request_inicis.jsp",
    // MOBILE_PAY_HOST_ADD     : ":8443/payment/mobile_return_inicis.jsp",

    /*----------------------------------------------------*/
    /*----------------- 리얼 서버 빌드 시 -----------------*/
    IS_FOR_DEV              : false,
    IS_DEV_VALUE            : false,
    CLOUD_HOST_ADD          : "https://hiclass.tv",
    MAIN_HOST_ADD           : "https://hiclass.tv",
    REST_API_HOST_ADD       : "http://hiclass.tv:8002",    // 변경이 정상적으로 동작하는지 보기 위해 임의값으로 세팅해 둠 by hjkim 20240228)
    PAY_HOST_ADD            : ":8443/payment/request_inicis.jsp",
    MOBILE_PAY_HOST_ADD     : ":8443/payment/mobile_return_inicis.jsp",

    /*----------------------------------------------------*/
    /*--------------- 리얼 로컬 서버 빌드 시 ---------------*/
    // IS_FOR_DEV              : false,
    // IS_DEV_VALUE            : false,
    // CLOUD_HOST_ADD          : "https://hiclass.tv",
    // MAIN_HOST_ADD           : "https://192.168.3.118",
    // REST_API_HOST_ADD       : "https://192.168.3.118:8002",    // 변경이 정상적으로 동작하는지 보기 위해 임의값으로 세팅해 둠 by hjkim 20240228)
    // PAY_HOST_ADD            : ":8443/payment/request_inicis.jsp",
    // MOBILE_PAY_HOST_ADD     : ":8443/payment/mobile_return_inicis.jsp",

    /*----------------------------------------------------*/
    /*---------- 리얼 & 테스트 서버 빌드용 (공통) ----------*/
    IS_LOCAL_VERSION        : false,

    /*----------------------------------------------------*/ 
    /*----------------- 로컬 서버 빌드 시 -----------------*/
    // IS_LOCAL_VERSION        : true,

    /*----------------------------------------------------*/

    DOWNLOAD_DIR: "/download/",                 // FILE-SHARE 다운로드 URL
    IMG_DIR: "/images/user/",              // 프로필 이미지 폴더
    ICON_IMG_DIR: "/images/classtalk/",
    LOGO_IMG_DIR: "/download/logo/",

    HANDS_UP_IMG_SEND_TIME: 250,

    HICLASS_CSS_VALUE: {
        WIDTH: "320px",
        MODAL_VALUE: {
            TOP: "37px",
            HEIGHT: "calc(100% - 101px)",
            HEIGHT_IN_CHATTING: "calc(100% - 4rem)",
            HEIGHT_IN_LIVE: "100%"
        }
    },

    ALERT_KIND : {       // alert 종류 코드
        NONE    : "NONE",           // alert 없음
        SUCCESS : "SUCCESS",        //성공
        DANGER  : "DANGER",         //위험
        WARNING : "WARNING",        //경고
        INFO    : "INFO"            //정보
    },

    USER_STATUS : {      // 사용자 상태 정보
        LOG_IN      : "LOG_IN",     // 로그인
        LOG_OUT     : "LOG_OUT",    // 로그아웃 (기본값)
        STUDYING    : "STUDYING",   // 강의 참여중
    },

    USER_PROFILE_IMG :  {
        KIND_1      : "1",
        KIND_2      : "2",
        KIND_3      : "3",
        KIND_4      : "4",
        KIND_5      : "5",
        KIND_6      : "6"
    },

    FRIEND_APPLY_STATUS : {   // 친구 신청 상태 정보
        APPLY       : "APPLY",       //신청
        APPROVE     : "APPROVE",     //수락
        REJECT      : "REJECT",      //거부
        CANCEL      : "CANCEL",      //거부
    },

    LOGIN_MODE : {
        NONE    : "NONE",
        WEB     : "WEB",
        HICLASS : "HICLASS"
    },

    LOGIN_STATUS : {            //사용자 상태 정보
        LOG_OUT         : "LOG_OUT",        //로그아웃(기본값)
        LOG_IN_FAIL     : "LOG_IN_FAIL",     //로그인 실패
        REGIST_OK       : "REGIST_OK",      //등록 성공
        REGIST_FAILD    : "REGIST_FAILD",   //등록 실패
        LOG_IN          : "LOG_IN"          //로그인
    },

    PUBLIC_SCOPE : {            //공개 범위
        ALL : "ALL",                //전체 공개
        FRIEND : "FRIEND",          //친구 공개(기본값)
    },

    FIND_OPTION : {
        START   : 0,
        ID      : 1,
        PW      : 2
    },

    FIND_STEP : {
        START           : 0,
        CHECK_ID        : 3,
        CERTIFY_PHONE   : 4,
        SHOW_ID         : 5,
        RESET_PW        : 6,
        END             : 7
    },

    SCREEN_RATE : {
        WIDTH   : 1123,
        HEIGHT  : 794
    },

    /******************************************************/
}

export default ConstData;
import React from "react";
import { useTranslation } from "react-i18next";

// import title from "../../image/info/main/title.png";
// import main01 from "../../image/info/main/main01.png";
// import main02 from "../../image/info/main/main02.png";
// import advantage01 from "../../image/info/main/main_advantage1.png";
// import advantage02 from "../../image/info/main/main_advantage2.png";

const HiClassInfo = () => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column h-100 overflow-auto" style={{ margin: '0px 1px 0px 0px', padding: '0px 20px 20px 20px', backgroundColor: 'white' }}>
            <div className="card border-primary mb-3 overflow-auto">
                <div className="card-header">
                    <b>HiClassTV Info</b>
                </div>
                <div className="card-body overflow-auto text-center" style={{ width: '100%', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
                    <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                        <img src="/images/info/main/logo.png" style={{ /* display: 'flex', width: '80%', */ maxWidth: '2.529rem' }} alt="HiClass_Logo.png" />
                        <div className="d-flex flex-column" style={{ minWidth: '10%', textAlign: 'left' }}>
                            <div className="d-flex justify-content-center" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                                <span>HiClassTV&nbsp;</span>
                                <span className="hiclasstv-blue-text">Pentalk</span>
                            </div>
                            <span style={{ fontSize: '0.5rem', fontWeight: '600', lineHeight: '0.4rem' }}>
                                {t("인포_홈_설명_1", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                    <img src="/images/info/main/main01.png" className="w-100 my-2 mb-3" alt="HiClassTV_Pentalk_Main01.png" />
                    <span className="grey-text" style={{ fontSize: '0.9rem', fontWeight: '400' }}>
                        {t("인포_홈_설명_2", { ns: "info" })}<br />{t("인포_홈_설명_2_1", { ns: "info" })}
                    </span>
                    <img src="/images/info/main/main02.png" className="w-100 my-2 mt-3" alt="HiClassTV_Pentalk_Main02.png" />
                    <div className="d-flex my-4"></div>
                    <div className="card grey-gradient-card">
                        <div className="card-body d-flex justify-content-center text-center pb-2" style={{ position: 'relative' }}>
                            <div className="info-circle">
                                <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <img src="/images/info/main/icon01.png" style={{ width: '70%', height: '70%' }} alt="" />
                                </span>
                            </div>
                            <div className="pt-3 px-1">
                                <span className="d-flex justify-content-center mt-3 mb-1 pt-1" style={{ fontSize: '1.1rem', fontWeight: 'bold'/* , color: '#4f4f4f', color: '#6e6e6e', color: '#212529', color: '#495057', color: '#6d7277', color: '#343a40', color: '#202020' */ }}>
                                    {t("인포_홈_설명_3", { ns: "info" })}
                                </span>
                                <span className="d-flex justify-content-between hiclasstv-deepDarkgrey-text my-0 mx-1 px-3" style={{ fontSize: '0.8rem', fontWeight: '600', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.25rem', lineHeight: '1.4rem' }}>
                                    {t("인포_홈_설명_4", { ns: "info" })}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-4"></div>
                    <div className="card grey-gradient-card">
                        <div className="card-body d-flex justify-content-center text-center pb-2" style={{ position: 'relative' }}>
                            <div className="info-circle">
                                <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <img src="/images/info/main/icon03.png" style={{ width: '70%', height: '70%' }} alt="" />
                                </span>
                            </div>
                            <div className="pt-3 px-1">
                                <span className="d-flex justify-content-center mt-3 mb-1 pt-1" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    {t("인포_홈_설명_7", { ns: "info" })}
                                </span>
                                <span className="d-flex justify-content-between hiclasstv-deepDarkgrey-text my-0 mx-1 px-2" style={{ fontSize: '0.8rem', fontWeight: '600', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.25rem', lineHeight: '1.4rem' }}>
                                    {t("인포_홈_설명_8", { ns: "info" })}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-4"></div>
                    <div className="card grey-gradient-card">
                        <div className="card-body d-flex justify-content-center text-center pb-2" style={{ position: 'relative' }}>
                            <div className="info-circle">
                                <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <img src="/images/info/main/icon02.png" style={{ width: '70%', height: '70%' }} alt="" />
                                </span>
                            </div>
                            <div className="pt-3 px-1">
                                <span className="d-flex justify-content-center mt-3 mb-1 pt-1" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    {t("인포_홈_설명_5", { ns: "info" })}
                                </span>
                                <span className="d-flex justify-content-between hiclasstv-deepDarkgrey-text my-0 mx-1 px-1" style={{ fontSize: '0.8rem', fontWeight: '600', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.25rem', lineHeight: '1.4rem' }}>
                                    {t("인포_홈_설명_6", { ns: "info" })}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex my-4"></div>
                    <div className="card grey-gradient-card mb-3">
                        <div className="card-body d-flex justify-content-center text-center pb-2" style={{ position: 'relative' }}>
                            <div className="info-circle">
                                <span className="d-flex w-100 h-100 align-items-center justify-content-center">
                                    <img src="/images/info/main/icon04.png" style={{ width: '70%', height: '70%' }} alt="" />
                                </span>
                            </div>
                            <div className="pt-3 px-1">
                                <span className="d-flex justify-content-center mt-3 mb-1 pt-1" style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                                    {t("인포_홈_설명_11", { ns: "info" })}
                                </span>
                                <span className="d-flex justify-content-between hiclasstv-deepDarkgrey-text my-0 mx-1 px-1" style={{ fontSize: '0.8rem', fontWeight: '600', wordBreak: 'keep-all', wordWrap: 'break-word', wordSpacing: '0.25rem', lineHeight: '1.4rem' }}>
                                    {t("인포_홈_설명_12", { ns: "info" })}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <img src="/images/info/main/title.png" className="w-100 mb-2" alt="" />
                    <img src="/images/info/main/main_advantage1.png" className="w-100 mt-2" alt="" />
                    <img src="/images/info/main/main_advantage2.png" className="w-100 mb-2" alt="" /> */}
                </div>
            </div>
        </div>
    );
}

export default HiClassInfo;
import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_build_version_info = () => {
    return axiosClient.get(`/hiclasstv/version`, options);
};

export const get_hiclasstv_info = () => {
    return axiosClient.get(`/`, options);
};

export const get_refresh_hiclasstv_info = () => {
    return axiosClient.get(`/hiclasstv/info`, options);
};

export const get_refresh_my_friend_list = ({ userSeq }) => {
    return axiosClient.get(`/hiclasstv/${userSeq}/list?kind=friend`, options);
};

export const get_download_info = () => {
    return axiosClient.get(`/hiclasstv/info/download`, options);
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaHome, FaComments, FaDownload, FaLaptop, FaChalkboardTeacher, FaUserFriends, FaInfoCircle, FaCogs, FaGift, FaTicketAlt } from "react-icons/fa";

import { enWebPage } from "../../../lib/ConstCommand";
import ConstData from "../../../lib/ConstData";

const WebSideMenu = ({ currentPageType, userKind, isConfigAdmin, isCreateAdmin, isStandardType, isUsablePass, goToAloneChatting }) => {
    const { t } = useTranslation();

    let history = useHistory();

    const callMethod = () => {
        if (isUsablePass) {
            //console.log("is usable pass");
        } else {
            //console.log("is unusable pass");
        }

        goToAloneChatting();
    }

    return (
        <div className="web-side-menu">
            <ul>
                <li className={currentPageType === enWebPage.Home ? "active" : ""} title={t("메인으로", { ns: "hiclasstv" })} onClick={() => { history.push("/main") }}>
                    <div><FaHome className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">{t("MAIN", { ns: "hiclasstv" })}</span>
                </li>
                { // ** 임시 ** 현재 사용 안 하므로 주석 처리 (for GS인증 시험) by hjkim 20230912
                    /* <li className={currentPageType === enWebPage.Friend ? "active" : ""} title={t("친구_관리", { ns: "friend "})} onClick={() => { history.push("/list_friend") }}>
                        <div><FaUserFriends className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">{t("FRIEND", { ns: "friend" })}</span>
                    </li> */
                }
                <li className={currentPageType === enWebPage.LiveClassTalk ? "active" : ""} title={t("나와의_채팅", { ns: "chat" })} onClick={() => callMethod()}>
                    <div><FaComments className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">{t("CHATTING", { ns: "chat" })}</span>
                </li>
                <li className={currentPageType === enWebPage.Live ? "active" : ""} title={t("클래스_목록_조회", { ns: "live" })} onClick={() => { history.push("/list_live") }}>
                    <div><FaLaptop className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">{t("CLASS", { ns: "live" })}</span>
                </li>
                { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                    /* isStandardType && (userKind === "hiclasstv" || isConfigAdmin) &&
                    <li className={currentPageType === enWebPage.Studio ? "active" : ""} title={t("스튜디오_목록_조회", { ns: "studio" })} onClick={() => { history.push("/list_studio") }}>
                        <div><FaChalkboardTeacher className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">{t("STUDIO", { ns: "studio" })}</span>
                    </li> */
                }
                { // ** 임시 ** 매뉴얼 작업할 동안 주석 처리 by hjkim 20230503
                    /* !ConstData.IS_LOCAL_VERSION && isStandardType &&
                    <li className={currentPageType === enWebPage.Promotion ? "active" : ""} title={t("진행_중인_이벤트", { ns: "promotion" })} onClick={() => { history.push("/promotion") }}>
                        <div><FaGift className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">{t("PROMOTION", { ns: "promotion" })}</span>
                    </li> */
                }
                { // ** 임시 ** 이니시스 이용하지 않는 동안 주석 처리 by hjkim 20240717
                    /* !ConstData.IS_LOCAL_VERSION && isStandardType && (userKind === "hiclasstv" || (isConfigAdmin || isCreateAdmin)) &&
                    <li className={currentPageType === enWebPage.Pass ? "active" : ""} title={t("이용권_조회", { ns: "pass" })} onClick={() => { history.push("/pass") }}>
                        <div><FaTicketAlt className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">{t("PASS", { ns: "pass" })}</span>
                    </li> */
                }
                <li className={currentPageType === enWebPage.Download ? "active" : ""} title={t("다운로드", { ns: "info" })} onClick={() => { history.push("/download") }}>
                    <div><FaDownload className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">{t("DOWNLOAD", { ns: "info" })}</span>
                </li>
                <li className={currentPageType === enWebPage.Info ? "active" : ""} title={t("정보_더_보기", { ns: "info" })} onClick={() => { history.push("/info") }}>
                    <div><FaInfoCircle className="font-lg" aria-hidden="true" /></div>
                    <span className="ml-3 align-middle">{t("INFO", { ns: "info" })}</span>
                </li>
                {
                    (isConfigAdmin || isCreateAdmin) &&
                    <li className={currentPageType === enWebPage.Manage ? "active" : ""} title={t("관리자_페이지", { ns: "admin" })} onClick={() => { history.push("/admin") }}>
                        <div><FaCogs className="font-lg" aria-hidden="true" /></div>
                        <span className="ml-3 align-middle">{t("MANAGE", { ns: "admin" })}</span>
                    </li>
                }
            </ul>
        </div>
    );
}

export default WebSideMenu;
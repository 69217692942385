import React from "react";
import MainTemplate from "../components/template/MainTemplate";
import MainContainer from "../containers/MainContainer";
import BroadcastLiveListContainer from "../containers/BroadcastLiveListContainer";
import ContentTemplate from "../components/template/ContentTemplate";

const BroadcastLiveListPage = ({ browserType, selectMainTap, cookies, routeProps }) => {
    return (
        <MainTemplate>
            <MainContainer 
                browserType={browserType} 
                cookies={cookies} 
                routeProps={routeProps}
            >
                <ContentTemplate selectMainTap={selectMainTap}>
                    <BroadcastLiveListContainer 
                        browserType={browserType} 
                        routeProps={routeProps}
                        isConfigLiveListModalOpened={false} 
                    />
                </ContentTemplate>
            </MainContainer>
        </MainTemplate>
    );
};

export default BroadcastLiveListPage;
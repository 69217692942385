import React from "react";

import MainContainer from "../containers/MainContainer";
import WebContentTemplate from "../components/template/WebContentTemplate";
import MainAllListContainer from "../containers/MainAllListContainer";

const WebMainPage = ({ browserType, currentPageType, cookies, routeProps }) => {
    return (
        <MainContainer 
            browserType={browserType} 
            cookies={cookies} 
            currentPageType={currentPageType} 
            routeProps={routeProps}
        >
            <WebContentTemplate>
                <MainAllListContainer
                    browserType={browserType}
                    cookies={cookies} 
                    currentPageType={currentPageType}
                    routeProps={routeProps}
                />
            </WebContentTemplate>
        </MainContainer>
    );
};

export default WebMainPage;
import React, { useState, useEffect, createRef } from 'react';
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import { BsX } from 'react-icons/bs';

import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const SendMixedClassLink = ({ list_live, handleSelectedFunc, alertMessage, alertKind }) => {
    const { t } = useTranslation();

    const [lLiveList, setLiveList] = useState(list_live);
    const [selectedLiveOption, setSelectedLiveOption] = useState(-1);

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        if (lLiveList && lLiveList.length > 0) {
            setSelectedLiveOption({ idx:0, liveSeq:lLiveList[0].liveSeq, liveName:lLiveList[0].liveName });
        }
    }, [lLiveList]);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const selectLive = (e) => {
        setSelectedLiveOption({idx:e.target.value, liveSeq:lLiveList[e.target.value].liveSeq, liveName:lLiveList[e.target.value].liveName});
    }
    
    const sendMail = () => {
        handleSelectedFunc({ selectedFunc: "SEND_MIXED_CLASS_MAIL_OK", liveSeq: selectedLiveOption.liveSeq, liveName: selectedLiveOption.liveName });
    }

    return (
        <div className="trans-area" onClick={handlePreventClick}>
            <div>
                <div className="title-area">
                    <span>
                        <b>{t("믹스드_클래스_접속_링크_전송", { ns: "live" })}</b>
                    </span>
                    <span className="func-btn" style={{position:'absolute', right:'0'}} onClick={() => handleSelectedFunc({ selectedFunc:"NONE" })}>
                        <BsX />
                    </span>
                </div>
                <div className="input-area mt-2">
                    <span className="input-label justify-content-center pr-2 area-15 hiclasstv-deepDarkgrey-text">
                        {t("대상", { ns: "pass" })}
                    </span>
                    <div className="input-data">
                        <select id='targetLive' className="custom-select font-md" style={{width:'100%'}} value={selectedLiveOption.idx} onChange={selectLive}>
                            {
                                !lLiveList || lLiveList.length <= 0 ?
                                <option value={-1}>
                                    {t("선택_가능한_클래스_없음", { ns: "live" })}
                                </option> :
                                lLiveList.map(
                                    (info, index) => (
                                        <option value={index} key={'item-' + index} disabled={info.mixedClassExistYn === 'N'}>{info.liveName}</option>
                                    )
                                )
                            }
                        </select>
                    </div>
                </div>
                <Alerts kind={lAlertKind} style={{marginTop:'0'}} message={lAlertMessage} />
                <div className="d-flex footer-area align-items-center justify-content-end">
                    <button type="basic" className="hiclasstv-blue" onClick={() => sendMail()}>
                        {t("전송", { ns: "common" })}
                    </button>
                    <button type="basic" onClick={() => handleSelectedFunc({ selectedFunc:"NONE" })}>
                        {t("취소", { ns: "common" })}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SendMixedClassLink;
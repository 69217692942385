import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { enTokenCMD } from "../../../lib/classTalk/CodeEnum";
import { setElementForGroupSmartTV } from "../../../lib/MediaSoupClient";
import { xmitCmd_MessageForSmartTV } from "../../../lib/RabbitmqClient";
import { initFuncProcArr } from "../../../lib/rbmq/rcvMsgFromRbmq";

// import ConnectImg from "../../../images/connect.png";
// import ConnectImg from "../../../images/android_connect2.png";
// import NotConnectedImg from "../../../images/android_notconnected.png";
// import SharedCameraModeImg from "../../../images/android_shared_camera_mode.png";

const ConnectImgComp = styled.img`
    width : 100%;
    height : 100%;
`;

const VideoComp = ConnectImgComp.withComponent("video");

const SmartTVMediaSoupRcvForStd = forwardRef(({ idx, isModalOpened, userSeq, userNickname, liveSeq, defaultClassName, isRbmqServerConnected, isReadyLiveConsume, isConnectedOnScreenApp, initViewMode, onSwitchView, handleSelectedFunc }, ref) => {
    const { t } = useTranslation();

    const isClientConnected = useRef(false);
    // const [isClientConnected, setIsClientConnected] = useState(false);

    // const lClassName = useRef(defaultClassName ? defaultClassName : "");
    // const [lPaddingLeftValue, setPaddingLeftValue] = useState("2px");
    // const [lLeftValue, setLeftValue] = useState("unset");
    // const [lRightValue, setRightValue] = useState(defaultRightValue ? defaultRightValue : "2px");
    // const [lTopValue, setTopValue] = useState("2px");
    // const [lWidth, setWidth] = useState("240px");
    // const [lHeight, setHeight] = useState("135px");
    // const [lZIndexValue, setZIndexValue] = useState(5);
    // const [lVisibility, setVisibility] = useState("visible");
    // const [lWidth, setWidth] = useState("100%");
    // const [lHeight, setHeight] = useState("100%");
    // const [lLeftValue, setLeftValue] = useState("2px");
    // const [lBottomValue, setBottomValue] = useState("2px");
    // const [lZIndexValue, setZIndexValue] = useState("unset");
    // const [lStyle, setStyle] = useState(style ? style : {
    //     position: 'absolute',
    //     right: '2px',
    //     top: '2px',
    //     width: '240px',
    //     height: '135px',
    //     paddingLeft: '2px',
    //     zIndex: 5,
    //     visibility: 'visible'
    // });

    const imageType = "image/webp";     // "image/jpeg"  "image/webp" ;

    const lWidth = useRef("240px");
    const [lClassName, setClassName] = useState(defaultClassName ? defaultClassName : "");
    const [lDisplay, setDisplay] = useState("block");
    const [lVideoDisplay, setVideoDisplay] = useState("block");
    const [lImageDisplay, setImageDisplay] = useState("none");

    const [lUserNickname, setUserNickname] = useState("");

    const videoEl = useRef(null);
    const audioEl = useRef(null);
    const imgEl = useRef(null);
    const sharedModeStarted = useRef(false);
    const viewMode = useRef("video");
    // const sharedCameraMode = useRef(false);

    useImperativeHandle(ref, () => ({
        updateStdMediaView: value => updateView({
            ...value,
            userSeq,
            userNickname,
            liveSeq,
            isConnectedOnScreenApp
        }),
        getClassName: () => { return lClassName },
        getVisibility: () => { return lDisplay },
        // getVisibility: () => { return lVisibility },
        initConnectImg: () => setNotConnectedImg(),
        sendMessageToStd: value => {
            // console.log("sendMessageToStd - ", value, userSeq, liveSeq, isRbmqServerConnected, isConnectedOnScreenApp);
            // sendMessageToClient({ ...value, userSeq, liveSeq, isConnectedOnScreenApp });
            const { kind, isRbmqServerConnected } = value;
            console.log(`sendMessageToClient - kind[${kind}], userSeq[${userSeq}], liveSeq[${liveSeq}], isRbmqServerConnected[${isRbmqServerConnected}], isConnectedOnScreenApp[${isConnectedOnScreenApp}]`);
            if (kind === "TRY_CONN_APP") {
                if (isRbmqServerConnected) {
                    if (!isConnectedOnScreenApp) {
                        if (userSeq > -1 && liveSeq > -1) {
                            // let mode = lWidth.current === "100%" ? "image_full_mode" : "media_full_mode";
                            let mode = lWidth.current === "100%" ? true : false;
                            xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, mode);
                        }
                    }
                } else {
                    alert(t("스마트_티브이_안내_문구_13", { ns: "live" }));
                }
            } else if (kind === "TRY_DISCONN_APP") {
                if (isRbmqServerConnected) {
                    if (isConnectedOnScreenApp) {
                        if (userSeq > -1 && liveSeq > -1) {
                            xmitCmd_MessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.NULL, userSeq, userNickname, liveSeq, false);
                        }
                    }
                } else {
                    alert(t("스마트_티브이_안내_문구_14", { ns: "live" }));
                }
            }
        }
    }), [t, userSeq, userNickname, liveSeq, isConnectedOnScreenApp, lClassName, lDisplay]);

    /* const imageProcess = (status, imageData) => {
        if (status === "connect") {
            sharedModeStarted.current = true;
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            }
            handleSelectedFunc({ selectedFunc: "CONNECTED_APP" });
        } else if (status === "disconnect") {
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/android_notconnected.png";
            }
            handleSelectedFunc({ selectedFunc: "DISCONN_APP" });
        } else if (status === "update") {
            if (!sharedModeStarted.current) {
                return;
            } else {
                if (imgEl.current !== null) {
                    if (imageData !== undefined && imageData !== null) {
                        let blob = new Blob([imageData], { type: "image/jpeg" });
                        let url = URL.createObjectURL(blob);

                        imgEl.current.src = url;
                        imgEl.current.onload = function () {
                            //cleanup.
                            URL.revokeObjectURL(url);
                        }
                    } else {
                        imgEl.current.src = "/images/connect.png";
                    }
                }
            }
        } else if (status === "shared-screen-mode") {
            sharedModeStarted.current = true;
            //sharedCameraMode.current = false;
        } else if (status === "shared-camera-mode") {
            sharedModeStarted.current = false;
            //sharedCameraMode.current = true;
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/android_shared_camera_mode.png";
            }
        } else if (status === "shared-stop-mode") {
            sharedModeStarted.current = false;
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            }
        } else if (status === "media_full_mode") {
            onSwitchView({ kind: "media_full_mode", idx, userSeq });
        } else if (status === "image_full_mode") {
            onSwitchView({ kind: "image_full_mode", idx, userSeq });
        }
    } */

    const imageProcess = (status, data) => {
        // console.log("imageProcess - ", status, data);
        if (status === "connect") {
            console.log("rcv connect");
            sharedModeStarted.current = true;
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            } else {
                if (videoEl.current !== null) {
                    videoEl.current.pause();
                    videoEl.current.currentTime = 0;
                    videoEl.current.poster = "/images/connect.png";
                }
            }

            // setIsClientConnected(true);
            // isClientConnected.current = true;
            handleSelectedFunc({ selectedFunc: "CONNECTED_APP", idx, userSeq });
        } else if (status === "connect-client") {
            console.log("rcv connect-client");
            // let mode = lWidth.current === "100%" ? true : false;
            // let mode = true;
            let localStreamingMode;
            let tvMode =  lWidth.current === "240px" ? false : true;
            if (lWidth.current === "240px") {
                localStreamingMode = 0;
                tvMode = false;
            } else if (lWidth.current === "100%") {
                localStreamingMode = 1;
                tvMode = true;
            } else {
                localStreamingMode = 4;
                tvMode = true;
            }
            xmitCmd_MessageForSmartTV("smart_tv", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);

            if (!isConnectedOnScreenApp) {
                // mode = lWidth.current === "100%" ? true : false;
                xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
            } else {
                xmitCmd_MessageForSmartTV("android", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
            }

            // setIsClientConnected(true);
            isClientConnected.current = true;
        } else if (status === "disconnect") {
            console.log("rcv disconnect");
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
                // imgEl.current.src = "/images/android_notconnected.png";
            } else {
                if (videoEl.current !== null) {
                    videoEl.current.pause();
                    videoEl.current.currentTime = 0;
                    videoEl.current.poster = "/images/connect.png";
                    // videoEl.current.poster = "/images/android_notconnected.png";
                }
            }

            // setIsClientConnected(false);
            isClientConnected.current = false;
            handleSelectedFunc({ selectedFunc: "DISCONN_APP", idx, userSeq });
        } else if (status === "update") {
            // console.log("status === update");
            //console.log("sharedModeStarted.current - ", sharedModeStarted.current);
            //console.log(`sharedModeStarted.current[${sharedModeStarted.current}], viewMode.current[${viewMode.current}]`);
            /* if (viewMode.current !== "image") viewMode.current = "image";
            if (lImageDisplay !== "block") {
                setVideoDisplay("none");
                setImageDisplay("block");
            } */
            // console.log(`imgEl.current.style.display - `, imgEl.current.style.display);

            // if (!sharedModeStarted.current) {
                // return;
            // } else {
                if (imgEl.current) {
                    // console.log("imgEl.current not null & undefined ! ", imgEl.current);
                    if (data !== undefined && data !== null) {
                        // let blob = new Blob([data], { type: "image/jpeg" });
                        // let url = URL.createObjectURL(blob);

                        // imgEl.current.src = url;
                        // imgEl.current.onload = function () {
                        //     //cleanup.
                        //     console.log("imgEl.current.onload called");
                        //     URL.revokeObjectURL(url);
                        // }

                        if (imgEl.current.complete) {   // draw가 완전히 끝난 경우에만 그려주게 처리 ... (by shlee from pentalk wifi) 20240610
                            let blob = new Blob([data], { type: imageType });

                            let reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onload = () => {
                                imgEl.current.src = reader.result;
                                blob = null;
                            };
                        }
                    } else {
                        imgEl.current.src = "/images/connect.png";
                    }
                } else {
                    // console.log("imgEl.current is ", imgEl.current);
                    if (videoEl.current) {
                        if (data !== undefined && data !== null) {
                            // let blob = new Blob([data], { type: "image/jpeg" });
                            // let url = URL.createObjectURL(blob);

                            // videoEl.current.pause();
                            // videoEl.current.currentTime = 0;
                            // videoEl.current.poster = url;
                            // videoEl.current.onload = function () {
                            //     //cleanup.
                            //     console.log("videoEl.current.onload called");
                            //     URL.revokeObjectURL(url);
                            // }

                            //if (videoEl.current.complete) {     // draw가 완전히 끝난 경우에만 그려주게 처리 ... (by shlee from pentalk wifi) 20240610
                                let blob = new Blob([data], { type: imageType });

                                let reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = () => {
                                    videoEl.current.pause();
                                    videoEl.current.currentTime = 0;
                                    videoEl.current.poster = reader.result;
                                    blob = null;
                                };
                            //}
                        } else {
                            // imgEl.current.src = "/images/connect.png";
                            videoEl.current.pause();
                            videoEl.current.currentTime = 0;
                            videoEl.current.poster = "/images/connect.png";
                        }
                    }
                }
            // }
        } else if (status === "shared-screen-mode") {
            //console.log("rcv shared-screen-mode");
            //console.log("viewMode.current => ", viewMode.current);
            //console.log("lImageDisplay => ", lImageDisplay);
            /* if (viewMode.current !== "image") viewMode.current = "image";
            if (lImageDisplay !== "block") {
                setVideoDisplay("none");
                setImageDisplay("block");
            } */
            sharedModeStarted.current = true;
            //sharedCameraMode.current = false;
        } else if (status === "shared-camera-mode") {
            //console.log("rcv shared-camera-mode");
            //console.log("viewMode.current => ", viewMode.current);
            //console.log("lImageDisplay => ", lImageDisplay);
            /* if (viewMode.current !== "video") viewMode.current = "video";
            if (lImageDisplay !== "none") {
                setVideoDisplay("block");
                setImageDisplay("none");
            } */
            sharedModeStarted.current = false;
            //sharedCameraMode.current = true;
            /* if (imgEl.current !== null) {
                imgEl.current.src = "/images/android_shared_camera_mode.png";
            } else {
                if (videoEl.current !== null) {
                    videoEl.current.pause();
                    videoEl.current.currentTime = 0;
                    videoEl.current.poster = "/images/android_shared_camera_mode.png";
                }
            } */
        } else if (status === "shared-stop-mode") {
            //console.log("rcv shared-stop-mode");
            /* if (viewMode.current !== "video") viewMode.current = "video";
            if (lImageDisplay !== "none") {
                setVideoDisplay("block");
                setImageDisplay("none");
            } */
            sharedModeStarted.current = false;
            if (imgEl.current !== null) {
                imgEl.current.src = "/images/connect.png";
            } else {
                if (videoEl.current !== null) {
                    videoEl.current.pause();
                    videoEl.current.currentTime = 0;
                    videoEl.current.poster = "/images/connect.png";
                }
            }
        } else if (status === "media_full_mode" || status === "image_mode") {
            //console.log("rcv ", status);
            initViewMode({ kind: "media_full_mode", idx, userSeq });
        } else if (status === "image_full_mode" || status === "video_mode") {
            //console.log("rcv ", status);
            onSwitchView({ kind: "image_full_mode", idx, userSeq });
        } else if (status === "update_view_mode") {
            //console.log("rcv ", status);
            viewMode.current = status;
        } /* else if (status === "video_mode") {
            onSwitchView({ kind: "image_full_mode", idx, userSeq });
        } else if (status === "image_mode") {
            onSwitchView({ kind: "media_full_mode", idx, userSeq });
        } */
    }

    useEffect(() => {
        return () => {
            imgEl.current = null;
            videoEl.current = null;
            audioEl.current = null;

            initFuncProcArr(idx, -1, null);
            // xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, false);
            console.log("useEffect return => ", userSeq, userNickname, liveSeq, isRbmqServerConnected, isConnectedOnScreenApp);
            xmitCmd_MessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.ct_Logout, userSeq, userNickname, liveSeq, false);
        }
    }, []);

    useEffect(() => {
        // console.log(`(2) useEffect - idx[${idx}], userSeq[${userSeq}]]`);
        initFuncProcArr(idx, userSeq, imageProcess);
    }, [idx, userSeq]);

    useEffect(() => {
        // console.log(`SmartTVMediaSoupRcvForStd - userSeq[${userSeq}], isRbmqServerConnected[${isRbmqServerConnected}], isReadyLiveConsume[${isReadyLiveConsume}]`);
        if (!isModalOpened) {
            if (isRbmqServerConnected) {
                if (isReadyLiveConsume) {
                    setElementForGroupSmartTV(userSeq, videoEl.current, audioEl.current);
                }
            }
        }
    }, [isModalOpened, isRbmqServerConnected, isReadyLiveConsume, userSeq]);

    useEffect(() => {
        console.log(`SmartTVMediaSoupRcvForStd - userSeq[${userSeq}], isConnectedOnScreenApp[${isConnectedOnScreenApp}], isRbmqServerConnected[${isRbmqServerConnected}], isReadyLiveConsume[${isReadyLiveConsume}], lWidth.current[${lWidth.current}]`);
        if (!isModalOpened) {
            if (isRbmqServerConnected && isReadyLiveConsume) {
                /* if (!isConnectedOnScreenApp) {
                    if (userSeq > -1 && liveSeq > -1) {
                        // let mode = lWidth.current === "100%" ? "image_full_mode" : "media_full_mode";
                        let mode = lWidth.current === "100%" ? true : false;
                        xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, mode);
                    }
                } */
                // let mode = true;
                let localStreamingMode;
                let tvMode =  lWidth.current === "240px" ? false : true;
                if (lWidth.current === "240px") {
                    localStreamingMode = 0;
                    tvMode = false;
                } else if (lWidth.current === "100%") {
                    localStreamingMode = 1;
                    tvMode = true;
                } else {
                    localStreamingMode = 4;
                    tvMode = true;
                }
                xmitCmd_MessageForSmartTV("smart_tv", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);

                if (!isConnectedOnScreenApp) {
                    // mode = lWidth.current === "100%" ? true : false;
                    /* let localStreamingMode;
                    let tvMode =  lWidth.current === "240px" ? false : true;
                    if (lWidth.current === "240px") {
                        localStreamingMode = 0;
                        tvMode = false;
                    } else if (lWidth.current === "100%") {
                        localStreamingMode = 1;
                        tvMode = true;
                    } else {
                        localStreamingMode = 4;
                        tvMode = true;
                    } */

                    xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
                } else {
                    /* let localStreamingMode;
                    let tvMode =  lWidth.current === "240px" ? false : true;
                    if (lWidth.current === "240px") {
                        localStreamingMode = 0;
                        tvMode = false;
                    } else if (lWidth.current === "100%") {
                        localStreamingMode = 1;
                        tvMode = true;
                    } else {
                        localStreamingMode = 4;
                        tvMode = true;
                    } */

                    xmitCmd_MessageForSmartTV("android", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
                }
            }
        } /* else {
            if (isRbmqServerConnected && isReadyLiveConsume) {
                if (!isConnectedOnScreenApp) {
                    // mode = lWidth.current === "100%" ? true : false;
                    let localStreamingMode;
                    let tvMode =  lWidth.current === "240px" ? false : true;
                    if (lWidth.current === "240px") {
                        localStreamingMode = 0;
                        tvMode = false;
                    } else if (lWidth.current === "100%") {
                        localStreamingMode = 1;
                        tvMode = true;
                    } else {
                        localStreamingMode = 4;
                        tvMode = true;
                    }

                    xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
                } else {
                    let localStreamingMode;
                    let tvMode =  lWidth.current === "240px" ? false : true;
                    if (lWidth.current === "240px") {
                        localStreamingMode = 0;
                        tvMode = false;
                    } else if (lWidth.current === "100%") {
                        localStreamingMode = 1;
                        tvMode = true;
                    } else {
                        localStreamingMode = 4;
                        tvMode = true;
                    }

                    xmitCmd_MessageForSmartTV("android", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
                }
            }
        } */
    }, [isModalOpened, isRbmqServerConnected, isReadyLiveConsume, isConnectedOnScreenApp, userSeq, userNickname, liveSeq, lWidth]);

    useEffect(() => {
        if (userNickname && userNickname !== "") {
            setUserNickname(userNickname);
        }/*  else {
            if (idx !== undefined) {
                let number = idx.toString().padStart(2, "0");
                setUserNickname("HiNote " + number);
            } else {
                setUserNickname("HiNote 00");
            }
        } */
    }, [idx, userNickname]);

    // useEffect(() => {
    //     // console.log(`SmartTVMediaSoupRcvForStd - userSeq[${userSeq}], liveSeq[${liveSeq}], isRbmqServerConnected[${isRbmqServerConnected}], isReadyLiveConsume[${isReadyLiveConsume}]`);
    //     if (!isModalOpened) {
    //         if (isRbmqServerConnected && isReadyLiveConsume) {
    //             if (userSeq > -1 && liveSeq > -1) {
    //                 // let mode = lWidth.current === "100%" ? "image_full_mode" : "media_full_mode";
    //                 // let mode = lWidth.current === "100%" ? true : false;
    //                 let mode = true;
    //                 xmitCmd_MessageForSmartTV("smart_tv", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, mode);
    //             }
    //         }
    //     }
    // }, [isModalOpened, isRbmqServerConnected, isReadyLiveConsume, userSeq, userNickname, liveSeq/* , lWidth */]);

    const updateView = ({ userSeq, userNickname, liveSeq, isConnectedOnScreenApp, className, width, display, zIndex, visibility, isImageViewToggle }) => {
        console.log(`userSeq[${userSeq}], userNickname[${userNickname}], liveSeq[${liveSeq}], width[${width}], isConnectedOnScreenApp[${isConnectedOnScreenApp}]`);
        // let msg = `updateView - className[${className}], lWidth[${lWidth.current}], width[${width}]`;
        // msg += `, display[${display}], zIndex[${zIndex}], visibility[${visibility}], isImageViewToggle[${isImageViewToggle}]`;
        // console.log(msg);
        // if (width) setWidth(width);
        if (width) {
            lWidth.current = width;

            let localStreamingMode;
            let tvMode = lWidth.current === "240px" ? false : true;
            if (lWidth.current === "240px") {
                localStreamingMode = 0;
                tvMode = false;
            } else if (lWidth.current === "100%") {
                localStreamingMode = 1;
                tvMode = true;
            } else {
                localStreamingMode = 4;
                tvMode = true;
            }

            xmitCmd_MessageForSmartTV("smart_tv", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);

            if (isConnectedOnScreenApp) {
                /* let localStreamingMode;
                let tvMode = lWidth.current === "240px" ? false : true;
                if (lWidth.current === "240px") {
                    localStreamingMode = 0;
                    tvMode = false;
                } else if (lWidth.current === "100%") {
                    localStreamingMode = 1;
                    tvMode = true;
                } else {
                    localStreamingMode = 4;
                    tvMode = true;
                } */
    
                xmitCmd_MessageForSmartTV("android", "ct_SwitchStudentVideoResolution", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
            } else {
                xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, tvMode, localStreamingMode);
            }
        }

        if (className) setClassName(className);
        // console.log(`lWidth[${lWidth.current}], width[${width}]`);
        // console.log(`lDisplay[${lDisplay}], display[${display}]`);
        if (lWidth.current !== "100%") {
            if (display) setDisplay(display);
        } else {
            if (lWidth.current === "100%") {
                setDisplay("block");
                // if (zIndex) setZIndexValue(zIndex);
            } else {
                // console.log("lWidth.current is 100%. don't update visibility.");
            }
        }

        /* if (lWidth.current !== "100%") {
            if (display) setDisplay(display);
            // if (zIndex) setZIndexValue(zIndex);
        } else {
            console.log("lWidth.current is 100%. don't update visibility.");
        } */
    }

    const setNotConnectedImg = () => {
        if (imgEl.current) {
            imgEl.current.src = "/images/connect.png";
            // imgEl.current.src = "/images/android_notconnected.png";
        } else {
            if (videoEl.current !== null) {
                videoEl.current.pause();
                videoEl.current.currentTime = 0;
                videoEl.current.poster = "/images/connect.png";
                // videoEl.current.poster = "/images/android_notconnected.png";
            }
        }
    }

    const onClickView = () => {
        // console.log("onClickView - userSeq => ", userSeq);
        if (userSeq > -1) {
            if (idx !== undefined && idx !== null) {
                if (lWidth.current !== "100%") {
                    onSwitchView({ kind: "image_full_mode", idx, userSeq });
                }
            } else {
                if (lWidth.current === "100%") {
                    onSwitchView({ kind: "media_full_mode", idx, userSeq });
                } else {
                    onSwitchView({ kind: "image_full_mode", idx, userSeq });
                }
            }
        }
    }

    const sendMessageToClient = ({ kind, userSeq, liveSeq, isConnectedOnScreenApp, isRbmqServerConnected }) => {
        console.log(`sendMessageToClient - kind[${kind}], userSeq[${userSeq}], liveSeq[${liveSeq}], isRbmqServerConnected[${isRbmqServerConnected}], isConnectedOnScreenApp[${isConnectedOnScreenApp}]`);
        if (kind === "TRY_CONN_APP") {
            if (isRbmqServerConnected) {
                if (!isConnectedOnScreenApp) {
                    if (userSeq > -1 && liveSeq > -1) {
                        // let mode = lWidth.current === "100%" ? "image_full_mode" : "media_full_mode";
                        let mode = lWidth.current === "100%" ? true : false;
                        xmitCmd_MessageForSmartTV("android", "ct_RequestUpdateImageForSmartTV", enTokenCMD.NULL, userSeq, userNickname, liveSeq, mode);
                    }
                }
            } else {
                alert(t("스마트_티브이_안내_문구_13", { ns: "live" }));
            }
        } else if (kind === "TRY_DISCONN_APP") {
            if (isRbmqServerConnected) {
                if (isConnectedOnScreenApp) {
                    if (userSeq > -1 && liveSeq > -1) {
                        xmitCmd_MessageForSmartTV("android", "ct_SmartTVLogout", enTokenCMD.NULL, userSeq, userNickname, liveSeq, false);
                    }
                }
            } else {
                alert(t("스마트_티브이_안내_문구_14", { ns: "live" }));
            }
        }
    }

    return (
        <div
            ref={ref}
            className={"mediasoup_comp_container" + (lClassName !== "" ? " " + lClassName : lClassName)}
            style={{ display: `${lDisplay}` }}
            onClick={onClickView}
        >
            <VideoComp id={"mediasoup_video_comp_" + idx} ref={videoEl} className="mediasoup_comp" style={{ display: `${lVideoDisplay}` }} poster="/images/connect.png" playsInline autoPlay muted />
            {/* <ConnectImgComp id={"mediasoup_img_comp_" + idx} ref={imgEl} className="mediasoup_comp" style={{ display: `${lImageDisplay}` }} src="/images/connect.png" alt="" /> */}
            {
                lUserNickname && lUserNickname !== "" &&
                <span className="mediasoup_comp">{lUserNickname}</span>
            }
            <audio id={"audio_comp_" + idx} ref={audioEl} style={{ display: 'none' }} autoPlay />
        </div>
    );
});

export default SmartTVMediaSoupRcvForStd;
import React, { useState, useEffect, useRef } from "react";
import { BsX } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import ConstData from "../../../lib/ConstData";

import Alerts from "../../etc/list/Alerts";

const QuizList = ({ list_quiz, isEditQuizModalOpened, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);
    const [lAlertMessage, setAlertMessage] = useState("");

    const timerRef = useRef(null);
    const isMount = useRef(false);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            if (timerRef && timerRef.current) {
                clearTimeout(timerRef.current);
                timerRef.current = null;
            }
        }
    }, []);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const onHandleSelectQuiz = (quiz_info) => {
        if (isEditQuizModalOpened) {
            if (lAlertMessage === "") {
                setAlertMessage(t("퀴즈_알림_메시지_1", { ns: "classTalk" }));
                setAlertKind(ConstData.ALERT_KIND.DANGER);

                timerRef.current = setTimeout(() => {
                    if (isMount.current) { // 혹시 모를 오류 방지를 위해... by hjkim 20230103 (react unmount 상태에서 setState 현상 일어나는 경우 등...)
                        setAlertMessage("");
                        setAlertKind(ConstData.ALERT_KIND.NONE);
                    }
                }, 5000);
            } else {
                console.log("already defined alertMessage");
            }
        } else {
            handleSelectedFunc({ selectedFunc: "SELECT_QUIZ", quizSeq: quiz_info.quizSeq });
        }
    }

    const onHandleEditQuizModal = (e, quiz_info) => {
        //console.log(quiz_info);
        e.stopPropagation();
        handleSelectedFunc({ selectedFunc: "OPEN_EDIT_QUIZ_MODAL", quizSeq: quiz_info.quizSeq });
    }

    return (
        <div style={{ height: '100%', maxHeight: '40%', minHeight: '300px' }} onClick={handlePreventClick}>
            <div className="title-area justify-content-center">
                <span title={t("퀴즈_목록", { ns: "classTalk" })}>
                    <b>{t("퀴즈_목록", { ns: "classTalk" })}</b>
                </span>
                <span className="func-btn" style={{ position: 'absolute', right: 0 }} title={t("닫기", { ns: "common" })} onClick={() => handleSelectedFunc({ selectedFunc: "NONE" })}>
                    <BsX />
                </span>
            </div>
            <div className="d-flex">
                <button type="button" className="btn btn-sm hiclasstv-blue white-text" title={t("퀴즈_추가", { ns: "classTalk" })} onClick={() => handleSelectedFunc({ selectedFunc: "OPEN_CREATE_QUIZ_MODAL" })}>
                    + {t("퀴즈_추가", { ns: "classTalk" })}
                </button>
            </div>
            {
                lAlertMessage !== "" &&
                <Alerts kind={lAlertKind} message={lAlertMessage} style={{ transition: '2.5s' }} />
            }
            {
                (list_quiz && list_quiz.length > 0) ?
                    list_quiz.map(quiz => (
                        <div className="d-flex list-group-hitem list-group-hitem-action p-2" style={{ minHeight: '2.5rem' }} key={quiz.quizSeq} onClick={() => onHandleSelectQuiz(quiz)}>
                            <div className="text-area justify-content-between">
                                <span className="hiclass-text" title="">Q. {quiz.quizTitle}</span>
                                <button type="button" className="btn btn-sm hiclasstv-btn-transparent my-0 px-1" onClick={(e) => onHandleEditQuizModal(e, quiz)}>
                                    <FaPen className="font-sm hiclasstv-grey-text" />
                                </button>
                            </div>
                        </div>
                    )) :
                    <div className="grey-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: '60%' }}>
                        {t("퀴즈_알림_메시지_2", { ns: "classTalk" })}
                    </div>
            }
        </div>
    );
}

export default QuizList;
import React from "react";
import { useTranslation } from "react-i18next";

const AudioMemoMessage = ({ indexKey, msgInfo, handleSelectUrl }) => {
    const { t } = useTranslation();

    return (
        <button type="button" className="btn btn-sm mx-1 px-2 py-1" style={{ alignSelf: 'flex-start', paddingTop: '0.25rem', paddingLeft: '0.25rem', borderRadius: '0.5rem' }} title={t("재생", { ns: "classTalk" })} onClick={() => handleSelectUrl(msgInfo.kind, msgInfo.urlInfo, indexKey)}>
            {t("재생", { ns: "classTalk" })}
        </button>
    );
}

export default AudioMemoMessage;
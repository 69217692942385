import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const update_mixed_class_exist_yn = ({ localLiveSeq, mixedClassExistYn }) => {
    //console.log('api update_mixed_class_exist_yn => ', localLiveSeq, mixedClassYN);

    return axiosClient.put(`/mixed-class?kind=update-live-info`, {        //payload 형식
        localLiveSEQ: localLiveSeq,
        mixedClassYN: mixedClassExistYn
    }, options);
}

export const get_mixed_class_mail_info = ({ localLiveSeq }) => {
    //console.log('api get_mixed_class_mail_info => ', localLiveSeq);

    return axiosClient.put(`/mixed-class?kind=mail-info`, {        //payload 형식
        localLiveSEQ: localLiveSeq
    }, options);
}

export const update_server_user_auth_info = ({ localUserSeq, serverUserSeq }) => {
    console.log('api update_server_user_auth_info => ', localUserSeq, serverUserSeq);

    return axiosClient.put(`/mixed-class/auth-update`, {
        localUserSEQ: localUserSeq,
        serverUserSEQ: serverUserSeq
    }, options);
}

export const update_mixed_class_seq = ({ localLiveSeq, mixedClassSeq }) => {
    console.log('api update_mixed_class_seq => ', localLiveSeq, mixedClassSeq);

    return axiosClient.post(`/mixed-class/${localLiveSeq}`, {
        mixedClassSEQ: mixedClassSeq
    }, options);
}

export const update_mixed_class_info_for_live = ({ localLiveSeq, mixedClassSeq, mixedClassExistYn }) => {
    console.log('api update_mixed_class_info_for_live => ', localLiveSeq, mixedClassSeq, mixedClassExistYn);

    return axiosClient.put(`/mixed-class?kind=update-live-info`, {        //payload 형식
        localLiveSEQ: localLiveSeq,
        mixedClassSEQ: mixedClassSeq,
        mixedClassExistYn
    }, options);
}
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import WebPassPolicy from './modal/WebPassPolicy';
import WebPayModal from './modal/WebPayModal';

import ConstData from '../../lib/ConstData';
import { enResponseStatus } from '../../lib/ConstCommand';

const WebPassProductList = ({ user_info, list_pass, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [isPolicyModalOpened, setIsPolicyModalOpened] = useState(false);
    const [isPayModalOpened, setIsPayModalOpened] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);

    useEffect(() => {
        console.log(list_pass);
    }, [list_pass]);

    const numberWithCommas = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const togglePolicyModalOpened = (isPolicyModalOpened) => {
        setIsPolicyModalOpened(isPolicyModalOpened);
    }

    const togglePayModalOpened = (isPayModalOpened, pass_info, list_service) => {
        let buyername = user_info.userID;
        let buyertel = user_info.phoneNumber;
        let buyeremail = user_info.userEmail;
        
        if (isPayModalOpened) {
            let price = pass_info.passPrice;
            let goodname = pass_info.passName + ' (' + list_service.map(service => (service.serviceName)).join(" + ") + ')';
            //let merchantData = JSON.stringify({ uSeq : user_info.userSeq, dSeq : user_info.subDomainSeq, pSeq : pass_info.passSeq });
            let merchantData = user_info.userSeq + ',' + user_info.subDomainSeq + ',' + pass_info.passSeq;
            console.log(merchantData);
            setOrderInfo({ buyername, buyertel, buyeremail, price, goodname, merchantData, uSeq : user_info.userSeq, dSeq : user_info.subDomainSeq, pSeq : pass_info.passSeq });
        } else {
            setOrderInfo(null);
        }
        
        setIsPayModalOpened(isPayModalOpened);
    }
    
    return(
        <div className="web-main list-area">
            {
                list_pass !== undefined && list_pass !== null && list_pass.length > 0 ?
                    list_pass.map(
                        pass => (
                            <div className="d-flex flex-column my-4 px-2" key={pass.prdtServiceSeq}>
                                <div className="landscape-to-portrait justify-content-start align-items-center my-4">
                                    <span className="font-lg font-weight-bold mr-2">{pass.prdtServiceName}</span>
                                    <span className="font-md black white-text py-1 px-3" style={{borderRadius:'1rem'}}><b>{pass.list_service.map(service => (service.serviceName)).join(" + ")}</b></span>
                                </div>
                                {
                                    pass.list_passItem !== undefined && pass.list_passItem !== null && pass.list_passItem.length > 0 ?
                                        pass.list_passItem.map(
                                            item => (
                                                <div className="card purple-gradient white-text mb-4" key={item.passSeq}>
                                                    <div className="only-web py-2" />
                                                    <div className="landscape-to-portrait justify-content-between p-4">
                                                        <div className="only-web px-1" />
                                                        <div className="d-flex flex-column">
                                                            <div className="d-flex">
                                                                <span className="font-xlg"><b>{item.passName}</b></span>
                                                                <span className="font-lg ml-2 button" onClick={() => togglePolicyModalOpened(true)}>ⓘ</span>
                                                            </div>
                                                            <span className="font-md plus">{pass.list_service.map(service => (service.serviceName)).join(" + ")}</span>
                                                        </div>
                                                        <div className="list-row justify-content-end py-2 font-lg" style={{width:'40%'}}>
                                                            <div className="d-flex align-items-center font-lg mr-2">
                                                                <span className="font-weight-bold mr-2"><i>{numberWithCommas(item.passPrice)}</i></span>
                                                                <span className="font-md plus">{t("원", { ns: "pass" })}</span>
                                                            </div>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <button type="button" className="btn btn-white py-2 px-4" title={item.passName + t("구매_새창", { ns: "pass" })} style={{ borderRadius: '1.5rem' }}
                                                                    onClick={() => togglePayModalOpened(true, item, pass.list_service)}>
                                                                    {t("구매", { ns: "pass" })}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="only-web px-1" />
                                                    </div>
                                                    <div className="only-web py-2" />
                                                </div>
                                            )
                                        ) :
                                        <div className="d-flex p-2 grey-text">
                                            {t("구매_가능한_이용권_없음", { ns: "pass" })}
                                        </div>
                                }
                            </div>
                        )
                    ) :
                    <></>
            }
            <WebPassPolicy
                isModalOpened={isPolicyModalOpened}
                togglePolicyModalOpened={togglePolicyModalOpened}
            />
            <WebPayModal
                isModalOpened={isPayModalOpened}
                orderInfo={orderInfo}
                togglePayModalOpened={togglePayModalOpened}
                handleSelectedFunc={handleSelectedFunc}
            />
        </div>
    );
}

export default WebPassProductList;

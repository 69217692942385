import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaLockOpen } from "react-icons/fa";

const WebLiteServerInfo = ({ idx, liteServer_info, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lMemo, setMemo] = useState(liteServer_info.liteServerMemo === null ? "" : liteServer_info.liteServerMemo);

    useEffect(() => {
        let memo = liteServer_info.liteServerMemo === null ? "" : liteServer_info.liteServerMemo;
        console.log("memo => ", memo);
        setMemo(memo);
    }, [liteServer_info.liteServerMemo]);

    const onChangeMemo = (e) => {
        setMemo(e.target.value);
    }

    const onHandleModifyServer = () => {
        handleSelectedFunc({ selectedFunc: "UPDATE_LITE_SERVER", data: { liteServerSeq: liteServer_info.liteServerSeq, authCode: liteServer_info.authCode, memo: lMemo } });
    }

    const onHandleRemoveServer = () => {
        handleSelectedFunc({ selectedFunc: "DELETE_LITE_SERVER", data: liteServer_info });
    }

    const onHandleInitServer = () => {
        handleSelectedFunc({ selectedFunc: "INIT_LITE_SERVER", data: { liteServerSeq: liteServer_info.liteServerSeq, authCode: liteServer_info.authCode, initStatus: "Y" } });
    }

    return (
        <>
            <tr className="d-flex">
                <td className="d-flex align-items-center justify-content-center area-10">
                    <span className="hiclass-num-text">{liteServer_info.liteServerSeq}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-20">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.liteLocalSiteName}>{liteServer_info.liteLocalSiteName}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.liteServerName}>{liteServer_info.liteServerName}</span>
                    {
                        //liteServer_info.publicStatus &&
                        idx === 2 &&
                        <FaLockOpen className="font-md green-text ml-2" title={t("공개가_허용된_수업입니다", { ns: "admin" })} />
                    }
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <div className="text-area">
                        <input
                            type="text"
                            value={lMemo}
                            className="web-search-input transparent m-0 p-0"
                            style={{ color: 'unset !important', borderColor: 'unset' }}
                            placeholder={lMemo && lMemo !== "" ? lMemo : t("메모_입력_안내", { ns: "admin" })}
                            onChange={onChangeMemo}
                        />
                    </div>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span className={"hiclass-text" + (liteServer_info.liteLocalSiteSeq === -1 ? " grey-text" : "")} title={liteServer_info.ipAddress}>{liteServer_info.ipAddress}</span>
                </td>
                <td className="d-flex align-items-center justify-content-center area-15">
                    <span>{liteServer_info.authCode}</span>
                </td>
                <td className="d-flex flex-column align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm green white-text py-1 px-2 area-60" title={t("변경사항저장", { ns: "admin" })} onClick={() => onHandleModifyServer()}>
                        <b>{t("저장", { ns: "common" })}</b>
                    </button>
                    <button type="button" className="btn btn-sm hiclasstv-yellow white-text mt-0 py-1 px-2 area-60" title={t("재설정", { ns: "user" })} onClick={() => onHandleInitServer()} disabled={liteServer_info.initStatus === "Y" ? true : false}>
                        <b>{t("재설정", { ns: "user" })}</b>
                    </button>
                </td>
                {/*<td className="d-flex align-items-center justify-content-center area-10 p-0">
                    <button type="button" className="btn btn-sm red white-text py-1 px-2 " title={t("삭제", { ns: "common" })} onClick={() => onHandleRemoveServer()}>
                        <b>{t("삭제", { ns: "common" })}</b>
                    </button>
                </td>*/}
            </tr>
        </>
    );
}

export default WebLiteServerInfo;
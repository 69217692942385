import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import WebLocalSiteInfo from './WebLocalSiteInfo';

const WebLocalSiteList = ({ list_localSite, handleSelectedFunc }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column w-100 my-2">
            {
                list_localSite && list_localSite.length > 0 ?
                <table className="d-flex flex-column table-sm flex-list mb-0">
                    <thead>
                        <tr className="d-flex">
                            <th className="d-flex align-items-center justify-content-center area-10">
                                <b>Seq.</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-70">
                                <b>{t("로컬_사이트_이름", { ns: "admin" })}</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10">
                                <b>{t("오픈_사이트", { ns: "admin" })}</b>
                            </th>
                            <th className="d-flex align-items-center justify-content-center area-10" />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list_localSite.map((localSite, idx) => (
                                <WebLocalSiteInfo
                                    key={"config-local-site-" + idx}
                                    idx={idx}
                                    localSite_info={localSite}
                                    handleSelectedFunc={handleSelectedFunc}
                                />
                            ))
                        }
                    </tbody>
                </table> :
                <div className="d-flex view m-3 justify-content-center grey-text" style={{ fontSize: '1rem' }}>
                    {t("관리_가능한_로컬_사이트_목록_없음", { ns: "admin" })}
                </div>
            }
        </div>
    );
};

export default WebLocalSiteList;
import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { IoMdClose } from "react-icons/io";
import { useTranslation } from "react-i18next";

const RemoveAlertModal = ({ isModalOpened, handleAction_info, handleRemoveAlertModal }) => {
    const { t } = useTranslation();

    const [lQuizTitle, setQuizTitle] = useState();

    useEffect(() => {
        if (handleAction_info !== undefined && handleAction_info !== null) {
            if (handleAction_info.remove_info !== undefined && handleAction_info.remove_info !== null) {
                if (handleAction_info.remove_info.quizTitle && handleAction_info.remove_info.quizTitle !== "") {
                    console.log("handleAction_info.remove_info.quizTitle => ", handleAction_info.remove_info.quizTitle);
                    setQuizTitle(handleAction_info.remove_info.quizTitle);
                }
            }
        }
    }, [handleAction_info]);

    return (
        <Modal isOpen={isModalOpened} size="sm" wrapClassName="quiz-modal" centered backdrop={false}>
            <div className="d-flex justify-content-end w-100">
                <span className="func-btn" title={t("닫기", { ns: "common" })} onClick={() => handleRemoveAlertModal(false)}>
                    <IoMdClose />
                </span>
            </div>
            <div className="d-flex flex-column align-items-center w-100">
                <div className="d-flex text-center my-2 dark-grey-text small">
                    {t("퀴즈_알림_메시지_8", { ns: "classTalk" })}<br /><br />
                    {t("퀴즈_알림_메시지_6", { ns: "classTalk" })}<br /><br />
                    {t("퀴즈_알림_메시지_8_1", { ns: "classTalk" })}<br />
                </div>
                <div className="content-area dark-grey-text mt-2 mb-0">
                    <span className="small mb-1">[{t("퀴즈제목", { ns: "classTalk" })}]</span>
                    <div className="text-area text-center area-80">
                        <span className="hiclass-text" title={lQuizTitle}>
                            <b>{lQuizTitle}</b>
                        </span>
                    </div>
                </div>
                <div className="d-flex justify-content-center my-2">
                    <button type="button" className="btn modal-btn modal-btn-danger" title={t("예", { ns: "common" })} onClick={() => handleRemoveAlertModal(true)}>
                        {t("예", { ns: "common" })}
                    </button>
                    <button type="button" className="btn modal-btn modal-btn-success" title={t("아니오", { ns: "common" })} onClick={() => handleRemoveAlertModal(false)}>
                        {t("아니오", { ns: "common" })}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default RemoveAlertModal;
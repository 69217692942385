import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const display = ({ userSeq }) => {
    return axiosClient.get(`/friend/${userSeq}`, options);
};

export const search = ({ userSeq, keyWord, kind, userKind }) => {
    return axiosClient.get(`/user?kind=${kind}&userSEQ=${userSeq}&keyword=${keyWord}&userKind=${userKind}`, options);
};

export const search_id = ({ userSeq, userId, kind, userKind }) => {
    return axiosClient.get(`/user?kind=${kind}&userSEQ=${userSeq}&userID=${userId}&userKind=${userKind}`, options);
};

export const search_contacts = ({ userSeq, userPNArray, kind, userKind }) => {
    return axiosClient.get(`/user?kind=${kind}&userSEQ=${userSeq}&userKind=${userKind}`, options);
    //, {
    //  userPNArray : userPNArray
    //});
};

export const apply = ({ userSeq, applyUserSeq, mode }) => {
    return axiosClient.post(`/friend/${userSeq}/${applyUserSeq}?mode=${mode}`, {
        status: ConstData.FRIEND_APPLY_STATUS.APPLY
    }, options);
};

export const apply_multi = ({ userSeq, friendUserSEQList, mode }) => {
    return axiosClient.post(`/friend/${userSeq}?kind=apply&mode=${mode}`, {
        friendUserSEQList: friendUserSEQList
    }, options);
};

export const invite = ({ userSeq, inviteUserPNArray }) => {
    return axiosClient.post(`/friend/${userSeq}?kind=invite`, {
        friendPNList: inviteUserPNArray
    }, options);
};

export const confirm = ({ userSeq, confirmUserSeq, mode }) => {
    //console.log(`api confirm userSeq[${userSeq}], confirmUserSeq[${confirmUserSeq}], mode[${mode}]`);
    return axiosClient.post(`/friend/${userSeq}/${confirmUserSeq}?mode=${mode}`, {
        status: ConstData.FRIEND_APPLY_STATUS.APPROVE
    }, options);
};

export const reject = ({ userSeq, rejectUserSeq, mode }) => {
    return axiosClient.put(`/friend/${userSeq}/${rejectUserSeq}?mode=${mode}`, {
        status: ConstData.FRIEND_APPLY_STATUS.REJECT
    }, options);
};

export const cancel_apply = ({ userSeq, cancelUserSeq, mode }) => {
    //console.log(userSeq, cancelUserSeq);
    return axiosClient.put(`/friend/${userSeq}/${cancelUserSeq}?mode=${mode}`, {
        status: ConstData.FRIEND_APPLY_STATUS.CANCEL
    }, options);
};

export const remove_friend = ({ userSeq, deleteUserSeq, mode }) => {
    return axiosClient.delete(`/friend/${userSeq}/${deleteUserSeq}?mode=${mode}`, options);
};
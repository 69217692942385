import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FaEllipsisH } from 'react-icons/fa';

import WebHeaderMenu from '../etc/menu/WebHeaderMenu';
import WebHeaderPassMenu from '../etc/menu/WebHeaderPassMenu';
import WebHeaderInfoMenu from '../etc/menu/WebHeaderInfoMenu';
import WebHeaderManageMenu from '../etc/menu/WebHeaderManageMenu';
import WebMyProfileWindow from './modal/WebMyProfileWindow';

import CustomProfileImage from './profile/CustomProfileImage';

import { enWebPage } from '../../lib/ConstCommand';

const isMobile = (navigator.userAgent.toLowerCase().indexOf('android') > -1 || navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2));

const WebUserInfoBlock = ({ handleSelectedFunc, currentPageType, currentManagePageType, hiclasstvInfo, isLoginYn, userInfo, isStandardType, isConfigAdmin, isCreateAdmin, isHiClassDomain, isMediasoupAdmin, goToAloneChatting }) => {
    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState(false);
    
    useEffect(() => {    
        if (isMobile && isLoginYn === "Y") {
            let navbar = document.getElementById("navbar");
            let totalWidth = navbar.offsetWidth;
            let baseWidth = navbar.parentElement.offsetWidth;

            let offset = -1;

            if (navbar) {
                switch (currentPageType) {
                    case enWebPage.Home:
                        offset = document.getElementById("tool_main").offsetLeft;
                        break;

                    case enWebPage.Friend:
                        offset = document.getElementById("tool_friend").offsetLeft;
                        break;

                    case enWebPage.Live:
                        offset = document.getElementById("tool_live").offsetLeft;
                        break;

                    case enWebPage.Studio:
                        offset = document.getElementById("tool_studio").offsetLeft;
                        break;

                    case enWebPage.Pass:
                        offset = document.getElementById("tool_pass").offsetLeft;
                        break;

                    case enWebPage.Promotion:
                        offset = document.getElementById("tool_promotion").offsetLeft;
                        break;

                    case enWebPage.Download:
                        offset = document.getElementById("tool_download").offsetLeft;
                        break;

                    case enWebPage.Info:
                        offset = document.getElementById("tool_info").offsetLeft;
                        break;

                    case enWebPage.Manage:
                        offset = document.getElementById("tool_manage").offsetLeft;
                        break;

                    default:
                        break;
                }
            }

            let position = -1;

            if (totalWidth - offset < baseWidth) {
                position = totalWidth - baseWidth;
            } else {
                position = offset - 12;
            }

            navbar.parentElement.scrollTo(position, 0);

        }
    }, [currentPageType]);

    const tossSelectedFunc = (selectedFunc) => {
        if (isOpened) setIsOpened(!isOpened);
        handleSelectedFunc({selectedFunc});
    }

    const handleProfileModalFunc = ({selectedFunc}) => {
        toggleProfileModalOpened();
        handleSelectedFunc({selectedFunc});
    }

    const toggleProfileModalOpened = () => {
        let profileView = document.getElementById('myProfile');

        if (profileView.classList.contains('show')) {
            profileView.classList.remove('show');
        } else {
            profileView.classList.add('show');
        }
    }
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return(
        isLoginYn === 'Y' ?
            isMobile ?
                <div className="header-info-block">
                    <div className="header-info-menu scroll-wrap">
                        <div className="header-info-frame left" />
                        <div className="header-info-frame right" />
                        <nav className="horizontal" role="navigation">
                            <ul id="navbar" className="nav_list">
                                <WebHeaderMenu
                                    isMobile={true}
                                    isStandardType={isStandardType}
                                    isHiClassDomain={isHiClassDomain}
                                    isMediasoupAdmin={isMediasoupAdmin}
                                    isConfigAdmin={isConfigAdmin}
                                    isCreateAdmin={isCreateAdmin}
                                    isLoginYn={isLoginYn}
                                    hiclasstvInfo={hiclasstvInfo}
                                    currentPageType={currentPageType}
                                    currentManagePageType={currentManagePageType}
                                    tossSelectedFunc={tossSelectedFunc}
                                    goToAloneChatting={goToAloneChatting}
                                />
                            </ul>
                        </nav>
                    </div>
                    <div className="header-info-button">
                        <button type="button" className="btn btn-md hiclasstv-btn-transparent no-shadow py-1 px-2" title={t("메뉴", { ns: "common" })} onClick={() => handleSelectedFunc({selectedFunc:"SHOW_HEADER_BIG_MENU"})}>
                            <FaEllipsisH className="font-md white-text"/>
                        </button>
                    </div>
                </div> :
                <div className="header-info-block">
                    <div className="header-info-menu">
                        <nav className="horizontal" role="navigation">
                            <ul id="navbar" className="nav_list">
                                {
                                    currentPageType === enWebPage.Pass ?
                                        <WebHeaderPassMenu
                                            isMobile={false}
                                            isPassPage={true}
                                            hiclasstvInfo={hiclasstvInfo}
                                            tossAndHandleSelectedFunc={tossSelectedFunc}
                                        /> :
                                    currentPageType === enWebPage.Info ?
                                        <WebHeaderInfoMenu 
                                            isMobile={false}
                                            isLoginYn={isLoginYn}
                                            isInfoPage={true}
                                            hiclasstvInfo={hiclasstvInfo}
                                            tossAndHandleSelectedFunc={tossSelectedFunc}
                                        /> : 
                                    currentPageType === enWebPage.Manage ? 
                                        <WebHeaderManageMenu
                                            isMobile={false}
                                            isHiClassDomain={isHiClassDomain}
                                            isMediasoupAdmin={isMediasoupAdmin}
                                            isConfigAdmin={isConfigAdmin}
                                            isAdminPage={currentPageType === enWebPage.Manage}
                                            hiclasstvInfo={hiclasstvInfo}
                                            tossAndHandleSelectedFunc={tossSelectedFunc}
                                        /> : 
                                        <></>
                                }
                            </ul>
                        </nav>
                        <div className="header-info-button">
                            <div className="btn-group" onClick={handlePreventClick}>
                                <button type="button" className="d-flex hiclasstv-btn-transparent align-items-center" style={{maxWidth:'250px'}} onClick={() => toggleProfileModalOpened()}>
                                    <div className="area-20 fit-content">
                                        <CustomProfileImage url={userInfo.profileImgUrl} style={{ width: '1.7rem', height: '1.7rem'}} alt={userInfo.userNickname} />
                                    </div>
                                    <div className="text-area text-center font-md white-text area-80">
                                        <span className="hiclass-text" title={t("내_정보", { ns: "user" })}><b>{userInfo.userNickname}</b></span>
                                        <span className="hiclass-text small">{t("님", { ns: "user" })}</span>
                                    </div>
                                </button>
                                <WebMyProfileWindow
                                    userInfo={userInfo}
                                    handleProfileModalFunc={handleProfileModalFunc}
                                />
                            </div>
                        </div>
                    </div>
                </div> :
            <div className="header-info-block">
                <div className="header-info-menu scroll-wrap">
                    <div className="header-info-frame left" />
                    <div className="header-info-frame right" />
                    <nav className="horizontal" role="navigation">
                        <ul id="navbar" className="nav_list">
                            <WebHeaderInfoMenu
                                isMobile={true}
                                isLoginYn={isLoginYn}
                                isInfoPage={true}
                                hiclasstvInfo={hiclasstvInfo}
                                tossAndHandleSelectedFunc={tossSelectedFunc}
                            />
                        </ul>
                    </nav>
                </div>
                <div className="header-info-button">
                    <button type="button" className="btn btn-md outline bold login py-1 px-2" style={{ borderRadius: '3rem' }} onClick={() => handleSelectedFunc({ selectedFunc: "LOGIN_TOGGLE" })}>
                        <b>Login</b>
                    </button>
                </div>
            </div> 
    );
}

export default WebUserInfoBlock;



import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { UncontrolledCollapse } from 'reactstrap';
import { FaGift, FaPlusCircle, FaLock } from 'react-icons/fa';

import WebAddPromotion from './WebAddPromotion';
import WebListArrowButton from '../etc/button/WebListArrowButton';

const WebPromotionListForAdmin = ({ list_promotion, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (list_promotion.length > 0) {
            setIsOpened(true);
        }
    }, [list_promotion]);

    return(
        <div className="web-main list-area">
            <div className="card p-2">
                <div className="view view-cascade gradient-card-header r-blue-gradient-rgba narrower px-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <span className="d-flex white-text mx-1">
                            <FaGift className="font-lg mr-2" aria-hidden="true" />{t("프로모션", { ns: "promotion" })}
                        </span>
                        <button type="button" className="d-flex btn btn-sm mr-0 ml-2 px-1 py-1 hiclasstv-blue btn-outline-white" id="addPromotion" style={{minWidth:'max-content'}}>
                            <FaPlusCircle className="font-md mr-1" />{t("프로모션_추가", { ns: "promotion" })}
                        </button>
                    </div>
                    <WebListArrowButton toggle_id={'promotionList'} list_array={list_promotion} isOpened={isOpened} />
                </div>
                <UncontrolledCollapse toggler="#addPromotion" defaultOpen={false}>
                    <WebAddPromotion
                        handleSelectedFunc={handleSelectedFunc}
                    />
                </UncontrolledCollapse>
                <div className="px-2">
                    {
                        list_promotion !== undefined && list_promotion !== null && list_promotion.length > 0 ?
                            <UncontrolledCollapse toggler="#promotionList" defaultOpen={true} onEntering={() => setIsOpened(true)} onExiting={() => setIsOpened(false)}>
                                <table className="d-flex flex-column table table-sm table-hover mb-0">
                                    <thead>
                                        <tr className="d-flex">
                                            <th className="area-50">
                                                <b>{t("프로모션_명", { ns: "promotion" })}</b>
                                            </th>
                                            <th className="area-30">
                                                <b>{t("프로모션_기간", { ns: "promotion" })}</b>
                                            </th>
                                            <th className="area-20"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list_promotion.map(
                                                promotion => (
                                                    <tr key={promotion.promotionSeq} className="d-flex">
                                                        <td className="d-flex align-items-center justify-content-center area-50">
                                                            <div className="text-area" style={{textAlign:'center'}}>
                                                                {
                                                                    promotion.isPublicYN == 'N' &&
                                                                    <FaLock className="font-sm grey-text mr-1" />
                                                                }
                                                                <span className="hiclass-text font-weight-bold" title={promotion.promotionName}>{promotion.promotionName}</span>
                                                            </div>                                                            
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center area-30">
                                                            <span className="hiclass-num-text" title={promotion.promotionStart_YMD + " ~ " + promotion.promotionEnd_YMD}>{promotion.promotionStart_YMD}&nbsp;~&nbsp;{promotion.promotionEnd_YMD}</span>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center ara-20">
                                                            <button type="button" className="btn btn-sm btn-outline-primary px-2 py-1 my-0 mx-1 small" onClick={() => handleSelectedFunc({selectedFunc:"SHOW_PROMOTION_INFO", promotionSeq:promotion.promotionSeq})}>
                                                                {t("자세히_보기", { ns: "promotion" })}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </table>
                            </UncontrolledCollapse> :
                            <div className="view m-3 text-center grey-text">
                                {t("등록된_프로모션_없음", { ns: "promotion" })}
                            </div>
                    }
                </div>
            </div>
        </div>
    );
}
export default WebPromotionListForAdmin;
import React, { useRef, useEffect } from "react";

import AlertMessage from "./template/AlertMessage";
import SendMessage from "./template/SendMessage";
import ReceiveMessage from "./template/ReceiveMessage";
import ConstData from "../../lib/ConstData";
import { ctBubbleKind } from "../../lib/classTalk/CodeEnum";
import { ctMsgType } from "../../lib/ConstCommand";

const ClassTalkMassages = ({ activeLive, classTalkMsg, handleSelectedClassTalkFunc }) => {
    const classTalkEL = useRef(null);
    const audioEL = useRef(null);

    /* useEffect(() => {
        const { scrollHeight, clientHeight } = classTalkEL.current;
        classTalkEL.current.scrollTop = scrollHeight - clientHeight;
    }, [classTalkMsg]); */

    useEffect(() => {
        const observer = new MutationObserver((mutationList, observer) => {
            if (classTalkEL.current) {
                classTalkEL.current.scrollTop = classTalkEL.current.scrollHeight;
            }
        });

        const config = {
            attributes: true,
            childList: true,
            subtree: true,
        };

        if (classTalkEL.current) {
            observer.observe(classTalkEL.current, config);
        }

        return () => {
            observer.disconnect();
        };
    }, [classTalkMsg]);

    const handleSelectUrl = (selKind, srcUrl, idx, type) => {
        if (selKind === ctMsgType.AudioMemo) {
            audioEL.current.src = srcUrl;
        } else if (selKind === ctMsgType.InkMemo) {
            handleSelectedClassTalkFunc({ selectedFunc: "INK_MEMO", fileURL: srcUrl });
        } else if (selKind === ctMsgType.FileShare) {
            //console.log("file_share msg : ", srcUrl);
            handleSelectedClassTalkFunc({ selectedFunc: "FILE_DOWNLOAD", fileURL: ConstData.DOWNLOAD_DIR + srcUrl, idx });
        } else if (selKind === "file_open") {
            handleSelectedClassTalkFunc({ selectedFunc: "FILE_OPEN", fileURL: srcUrl, fileType: type });
        } else if (selKind === ctMsgType.PageJpeg) {
            handleSelectedClassTalkFunc({ selectedFunc: "PAGE_JPEG", fileURL: srcUrl, idx });
        } else if (selKind === ctMsgType.Whisper) {
            handleSelectedClassTalkFunc({ selectedFunc: "OPEN_WHISPER", data: srcUrl, idx });
        } 
    }

    return (
        <>
            <div id="classTalkView" className="d-flex flex-column justify-content-start align-items-center" style={{ width: '100%', height: '100%', maxHeight: '100%', overflow: 'auto', padding: '1px' }} ref={classTalkEL}>
                {
                    classTalkMsg.map((msg, idx) => (
                        msg.kind === ctBubbleKind.Info ?
                            <AlertMessage key={idx} msgInfo={msg.msgInfo} /> :
                            msg.kind === ctBubbleKind.SendMsg || msg.kind === ctBubbleKind.SendWhisper ?
                                <SendMessage
                                    key={idx}
                                    indexKey={idx}
                                    msgInfo={msg.msgInfo}
                                    activeLive={activeLive}
                                    handleSelectUrl={handleSelectUrl}
                                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                                /> :
                                /* msg.kind === ctBubbleKind.RcvMsg */
                                <ReceiveMessage
                                    key={idx}
                                    indexKey={idx}
                                    msgInfo={msg.msgInfo}
                                    handleSelectUrl={handleSelectUrl}
                                    handleSelectedClassTalkFunc={handleSelectedClassTalkFunc}
                                />
                    ))
                }
            </div>
            <audio ref={audioEL} autoPlay style={{ display: 'none' }} />
        </>
    );
}

export default ClassTalkMassages;
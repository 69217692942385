import React from 'react';
import { useTranslation } from "react-i18next";
import { FaTimes } from 'react-icons/fa';

const AlertModal = ({ modalType, modalTargetUserNickname, handleSelectedFunc }) => {
    const { t } = useTranslation();
    
    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    return (
        <div onClick={handlePreventClick}>
            <div className="d-flex justify-content-end w-100">
                <button className="hiclasstv-btn b-none p-0"  onClick={() => handleSelectedFunc({selectedFunc:modalType})}>
                    <FaTimes className="d-flex m-1 black-text" />
                </button>
            </div>
            <div className="content-area dark-grey-text">
                {
                    modalType === "MEMBER_DELETE_ALERT" ? 
                        <div className="text-area text-center">
                            <span>
                                <span className="font-weight-bold">{modalTargetUserNickname}</span>
                                {t("팀업_알림_창_메시지_1", { ns: "chat" })}<br />
                                {t("팀업_알림_창_메시지_2", { ns: "chat" })}
                            </span>
                        </div> : 
                    modalType === "MEMBER_INVITE_ALERT" ? 
                        <div className="text-area text-center">
                            <span>
                                <span className="font-weight-bold">{t("팀업_알림_창_메시지_3", { ns: "chat" })}</span>{t("팀업_알림_창_메시지_4", { ns: "chat" })}<br />
                                {t("팀업_알림_창_메시지_5", { ns: "chat" })}
                            </span>
                        </div> : 
                    modalType === "ALONE_TALK_ALERT" ?
                        <div className="text-area text-center">
                            <span>
                                {t("팀업_알림_창_메시지_6", { ns: "chat" })}<br />
                                {t("팀업_알림_창_메시지_7", { ns: "chat" })}<br />
                                {t("팀업_알림_창_메시지_8", { ns: "chat" })}
                            </span>
                        </div> : 
                        <></>
                }
            </div>
            <div className="d-flex justify-content-center my-2">
                <button type="button" className="btn btn-sm small green white-text m-0 py-1 px-2" onClick={() => handleSelectedFunc({ selectedFunc:modalType})}>{t("확인", { ns: "common" })}</button>
            </div>
        </div>
    );
}

export default AlertModal;
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";

import { format } from "date-fns";

import ConstData from "../../../lib/ConstData";

const WebHiClassDownload = ({ download_info, handleSelectedFunc }) => {
    const { t } = useTranslation();

    const [lPentalkInfo, setPentalkInfo] = useState({ version: "", updateDT: "" });
    const [lPentalkWifiInfo, setPentalkWifiInfo] = useState({ version: "", updateDT: "" });
    const [lPentalkBoardInfo, setPentalkBoardInfo] = useState({ version: "", updateDT: "" });
    const [lPentalkManualInfo, setPentalkManualInfo] = useState({ version: "", updateDT: "" });
    const [lPentalkCloudManualInfo, setPentalkCloudManualInfo] = useState({ version: "", updateDT: "" });
    const [lPentalkWifiManualInfo, setPentalkWifiManualInfo] = useState({ version: "", updateDT: "" });
    // const [lPencamInfo, setPencamInfo] = useState({ version: "", updateDT: "" });
    const [lPenMgrInfo, setPenMgrInfo] = useState({ version: "", updateDT: "" });

    useEffect(() => {
        // console.log("WebHiClassDownload useEffect");
        handleSelectedFunc({ selectedFunc: "GET_DOWNLOAD_INFO" });
    }, []);

    useEffect(() => {
        if (download_info !== undefined && download_info !== null) {
            if (download_info.pentalk_info !== undefined && download_info.pentalk_info !== null) {
                let date = new Date(download_info.pentalk_info.updateDT);
                setPentalkInfo({ version: download_info.pentalk_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            if (download_info.pentalkWifi_info !== undefined && download_info.pentalkWifi_info !== null) {
                let date = new Date(download_info.pentalkWifi_info.updateDT);
                setPentalkWifiInfo({ version: download_info.pentalkWifi_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            if (download_info.pentalkBoard_info !== undefined && download_info.pentalkBoard_info !== null) {
                let date = new Date(download_info.pentalkBoard_info.updateDT);
                setPentalkBoardInfo({ version: download_info.pentalkBoard_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            if (download_info.pentalkCloudManual_info !== undefined && download_info.pentalkCloudManual_info !== null) {
                let date = new Date(download_info.pentalkCloudManual_info.updateDT);
                setPentalkCloudManualInfo({ version: download_info.pentalkCloudManual_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            if (download_info.pentalkManual_info !== undefined && download_info.pentalkManual_info !== null) {
                let date = new Date(download_info.pentalkManual_info.updateDT);
                setPentalkManualInfo({ version: download_info.pentalkManual_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            if (download_info.pentalkWifiManual_info !== undefined && download_info.pentalkWifiManual_info !== null) {
                let date = new Date(download_info.pentalkWifiManual_info.updateDT);
                setPentalkWifiManualInfo({ version: download_info.pentalkWifiManual_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }

            /* if (download_info.pencam_info !== undefined && download_info.pencam_info !== null) {
                let date = new Date(download_info.pencam_info.updateDT);
                setPencamInfo({ version: download_info.pencam_info.version, updateDT: format(date, "yyyy / MM / dd") });
            } */

            if (download_info.penMgr_info !== undefined && download_info.penMgr_info !== null) {
                let date = new Date(download_info.penMgr_info.updateDT);
                setPenMgrInfo({ version: download_info.penMgr_info.version, updateDT: format(date, "yyyy / MM / dd") });
            }
        }
    }, [download_info]);

    const onClickDownload = (kind) => {
        let url = ConstData.CLOUD_HOST_ADD + ConstData.DOWNLOAD_DIR;
        let filename = "";

        switch (kind) {
            case "HICLASSTV_PENTALK":
                filename = "HiClassTVPentalk_Setup.zip";
                break;

            case "HICLASSTV_PENTALK_WIFI":
                filename = "PentalkWiFi_Setup.zip";
                break;

            case "PENTALK_BOARD":
                filename = "PentalkBoard_Setup.zip";
                break;

            case "HICLASSTV_PENTALK_MANUAL":
                filename = "HiClassTVPentalk_Manual.pdf";
                break;

            case "HICLASSTV_PENTALK_CLOUD_MANUAL":
                filename = "HiClassTVPentalk_Manual_Cloud.pdf";
                break;

            case "HICLASSTV_PENTALK_WIFI_MANUAL":
                filename = "HiClassTVPentalk_Manual_WiFi.pdf";
                break;

            case "HICLASS_PENCAM":
                filename = "HiClassPencam_Setup.zip";
                break;

            case "PENLAB_MGR":
                filename = "PenlabMgr.zip";
                break;

            default:
                break;
        }

        if (filename !== "" && filename !== null) {
            var link = document.createElement("a");
            link.href = url + filename;
            link.download = filename;
            link.click();
        } else {
            console.log("WebHiClassDownload() - onClickDownload url, filename error !");
        }
    }

    return (
        <div className="d-flex flex-column w-100" style={{ margin: '10px 0px', wordBreak: 'keep-all', wordWrap: 'break-word' }}>
            <div className="d-flex justify-content-center align-items-center w-100" style={{ margin: '2rem 0' }}>
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-wrap" style={{ fontSize: '3.5rem', fontWeight: 'bold', lineHeight: 'normal' }}>
                        <span className="hiclasstv-blue-text">{t("소프트웨어", { ns: "info" })}&nbsp;</span>
                        <span className="hiclasstv-darkblue-text">{t("다운로드", { ns: "info" })}</span>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title="HiClassTV Pentalk">
                                HiClassTV Pentalk
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`HiClassTV Pentalk ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASSTV_PENTALK")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : {lPentalkInfo.version}<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("인포_다운로드_펜톡_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_2", { ns: "info" })}<br />
                                {t("인포_다운로드_펜톡_설명_2", { ns: "info" })}<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("인포_다운로드_펜톡_설명_3", { ns: "info" })}<a href="https://vb-audio.com/Cable/" target="_blank" rel="noopener noreferrer">https://vb-audio.com/Cable/</a>
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title="Pentalk Wifi">
                                HiClassTV Pentalk WiFi
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`Pentalk Wifi ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASSTV_PENTALK_WIFI")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : {lPentalkWifiInfo.version}<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkWifiInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("인포_다운로드_펜톡_와이파이_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_2", { ns: "info" })}<br />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title="Pentalk Board">
                                Pentalk Board
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`Pentalk Board ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("PENTALK_BOARD")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : {lPentalkBoardInfo.version}<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkBoardInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("인포_다운로드_펜톡_보드_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_펜톡_보드_설명_2", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title={`HiClassTV Pentalk Pro(Cloud) ${t("매뉴얼", { ns: "info" })}`}>
                                HiClassTV Pentalk Pro(Cloud) {t("매뉴얼", { ns: "info" })}
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`HiClassTV Pentalk Pro(Cloud) ${t("매뉴얼", { ns: "info" })} ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASSTV_PENTALK_CLOUD_MANUAL")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : HiClassTV Pentalk Pro(Cloud) (Ver {lPentalkCloudManualInfo.version})<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkCloudManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk Pro(Cloud) {lPentalkCloudManualInfo.version}{t("인포_다운로드_매뉴얼_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_매뉴얼_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title={`HiClassTV Pentalk Pro ${t("매뉴얼", { ns: "info" })}`}>
                                HiClassTV Pentalk Pro {t("매뉴얼", { ns: "info" })}
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`HiClassTV Pentalk Pro ${t("매뉴얼", { ns: "info" })} ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASSTV_PENTALK_MANUAL")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : HiClassTV Pentalk Pro (Ver {lPentalkManualInfo.version})<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk Pro {lPentalkManualInfo.version}{t("인포_다운로드_매뉴얼_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_매뉴얼_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }} >
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title={`HiClassTV Pentalk Wifi ${t("매뉴얼", { ns: "info" })}`}>
                                HiClassTV Pentalk WiFi {t("매뉴얼", { ns: "info" })}
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`HiClassTV Pentalk Wifi ${t("매뉴얼", { ns: "info" })} ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASSTV_PENTALK_WIFI_MANUAL")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : HiClassTV Pentalk WiFi (Ver {lPentalkWifiManualInfo.version})<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPentalkWifiManualInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                ▶ HiClassTV Pentalk WiFi {lPentalkWifiManualInfo.version}{t("인포_다운로드_매뉴얼_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_매뉴얼_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column my-4 w-100" style={{ borderBottom: '1px dashed rgb(63, 133, 203)' }}>
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title="HiClass Pencam">
                                HiClass Pencam
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`HiClass Pencam ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("HICLASS_PENCAM")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : {lPencamInfo.version}<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPencamInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2 mb-5">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("인포_다운로드_펜캠_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_펜캠_설명_2", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div> */}
                    <div className="d-flex flex-column mt-4 w-100">
                        <div className="d-flex align-items-center mt-2">
                            <span className="d-flex hiclasstv-blue-text" style={{ fontSize: '1.4rem', fontWeight: 'bold', lineHeight: 'normal' }} title="PenlabMgr">
                                PenlabMgr
                            </span>
                            <span className="d-flex hiclasstv-button hiclasstv-blue white-text ml-2 py-2 px-2" style={{ borderRadius: '50%' }} title={`PenlabMgr ${t("다운로드", { ns: "info" })}`} data-toggle="tooltip" onClick={() => onClickDownload("PENLAB_MGR")}>
                                <FaDownload className="font-md" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("버전", { ns: "info" })} : {lPenMgrInfo.version}<br />
                                {t("프로그램_업데이트일", { ns: "info" })} : {lPenMgrInfo.updateDT}<br />
                            </span>
                        </div>
                        <div className="d-flex my-2">
                            <span className="grey-text" style={{ fontSize: '0.85rem' }}>
                                {t("인포_다운로드_펜매니저_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_펜매니저_설명_2", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_1", { ns: "info" })}<br />
                                {t("인포_다운로드_공통_설명_2", { ns: "info" })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WebHiClassDownload;
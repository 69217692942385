import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Modal } from 'reactstrap';
import { FaLock } from 'react-icons/fa';

import CustomProfileImage from '../../user/profile/CustomProfileImage';
import ConstData from '../../../lib/ConstData';
import Alerts from '../../etc/list/Alerts';

const WebUserPWResetAlertModal = ({ isModalOpened, selectedMemberInfo, toggleModalOpened, onClickResetUserPW, alertKind, alertMessage }) => {
    const { t } = useTranslation();

    const [memberInfo, setMemberInfo] = useState(selectedMemberInfo);
    const [password, setPassword] = useState("");

    const [lAlertMessage, setAlertMessage] = useState("");
    const [lAlertKind, setAlertKind] = useState(ConstData.ALERT_KIND.NONE);

    useEffect(() => {
        setAlertKind(alertKind);
    }, [alertKind]);

    useEffect(() => {
        setAlertMessage(alertMessage);
    }, [alertMessage]);

    useEffect(() => {
        setMemberInfo(selectedMemberInfo);
    }, [selectedMemberInfo]);

    const onChangePassword = (e) => {
        if (lAlertKind !== ConstData.ALERT_KIND.NONE) {
            setAlertKind(ConstData.ALERT_KIND.NONE);
            setAlertMessage("");
        }

        setPassword(e.target.value);
    }

    const checkInputValue = () => {
        if (password === undefined || password === null || password === "") {
            setAlertMessage(t("유효성_검사_비밀번호_입력오류", { ns: "user" }));
            setAlertKind(ConstData.ALERT_KIND.DANGER);
        } else {
            onClickResetUserPW(memberInfo.subDomainSeq, memberInfo.userSeq, password);
        }
    }

    const onCloseModal = () => {
        setAlertKind(ConstData.ALERT_KIND.NONE);
        setAlertMessage("");
    }

    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} onClosed={onCloseModal}>
            <div className="card container-fluid">
                <div className="card-body">
                    <div className="title-area text-center dark-grey-text">
                        <span>
                            {t("관리자_비밀번호_변경_문구_1", { ns: "admin" })}<br />
                            {t("관리자_비밀번호_변경_문구_2", { ns: "admin" })}
                        </span>
                    </div>
                    <div className="content-area dark-grey-text">
                        <span className="small mb-1">[{t("관리자_비밀번호_변경_문구_3", { ns: "admin" })}]</span>
                        <div className="d-flex align-items-center justify-content-center mb-3 area-80">
                            <div className="area-15 fit-content">
                                <CustomProfileImage url={memberInfo.profileImgUrl} style={{ width: '2rem', height: '2rem' }} alt={memberInfo.userNickname} />
                            </div>
                            <div className="text-area text-center">
                                <span className="hiclass-text" title={memberInfo.userNickname}>
                                    <b>{memberInfo.userNickname}</b>
                                </span>
                            </div>
                        </div>
                        <div className="d-flex input-group justify-content-center px-2">
                            <div className="d-flex align-items-center justify-content-center mr-2">
                                <FaLock className="font-md blue-text" title={t("비밀번호", { ns: "user" })} />
                            </div>
                            <input type="password" id="resetMemberPw" className="web-search-input px-2 area-80" placeholder={t("유효성_검사_비밀번호_입력오류", { ns: "user" })} onChange={onChangePassword} value={password} />
                        </div>
                        <Alerts kind={lAlertKind} message={lAlertMessage} />
                    </div>
                    <div className="d-flex justify-content-center my-2">
                        <button type="button" className="btn red white-text mr-1 py-1 px-2" onClick={checkInputValue}>{t("예", { ns: "common" })}</button>
                        <button type="button" className="btn green white-text ml-1 py-1 px-2" onClick={() => toggleModalOpened(false)}>{t("아니오", { ns: "common" })}</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebUserPWResetAlertModal;
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { FaPlay, FaPause } from "react-icons/fa";

import * as musicMetadata from "music-metadata-browser";
import gifFrames from "gif-frames";

import { stAttachmentType, stQuizDataType } from "../../../../lib/ConstCommand";

const AnimatedMP3Control = forwardRef(({ toggle }, ref) => {
    const [played, setPlayed] = useState(false);

    useImperativeHandle(ref, () => ({
        play: () => setPlayed(true),
        pause: () => setPlayed(false)
    }));

    return (
        <div className="control">
            {
                !played ?
                    <FaPlay onClick={toggle} /> :
                    <FaPause onClick={toggle} />
            }
        </div>
    );
});

const AnimatedMP3Item = ({ animatedMP3 }) => {
    const imgRef = useRef(null);
    const audioRef = useRef(null);
    const controlRef = useRef(null);
    const isMount = useRef(false);

    const [textData, setTextData] = useState(null);

    useEffect(() => {
        isMount.current = true;
        return () => {
            isMount.current = false;
            // 메모리 clear를 위해 revokeObjectURL!
            if (imgRef && imgRef.current) {
                if (imgRef.current.getAttribute("data-static") && imgRef.current.getAttribute("data-static") !== "") {
                    URL.revokeObjectURL(imgRef.current.getAttribute("data-static"));
                    imgRef.current.setAttribute("data-static", "");
                }

                if (imgRef.current.getAttribute("data-gif") && imgRef.current.getAttribute("data-gif") !== "") {
                    URL.revokeObjectURL(imgRef.current.getAttribute("data-gif"));
                    imgRef.current.setAttribute("data-gif", "");
                }

                if (imgRef.current.src && imgRef.current.src !== "") {
                    URL.revokeObjectURL(imgRef.current.src);
                    imgRef.current.src = "";
                }

                imgRef.current = null;
            }

            if (audioRef && audioRef.current) {
                if (!audioRef.current.paused) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                }

                if (audioRef.current.src && audioRef.current.src !== "") {
                    URL.revokeObjectURL(audioRef.current.src);
                    audioRef.current.src = "";
                }

                audioRef.current = null;
            }
        }
    }, []);

    useEffect(() => {
        if (animatedMP3 !== undefined && animatedMP3 !== null) {
            if (animatedMP3.data !== undefined && animatedMP3.data !== null) {
                if (animatedMP3.type === stAttachmentType.URL || animatedMP3.type === stQuizDataType.URL) {
                    let url = animatedMP3.data;
                    (async function parseFile() {
                        try {
                            const res = await fetch(url);
                            const blob = res.blob();
                            if (isMount.current) {
                                // mp3 파일 작업
                                let audio_objURL = URL.createObjectURL(blob);
                                if (audioRef && audioRef.current) {
                                    audioRef.current.src = audio_objURL;
                                }

                                const metadata = await musicMetadata.parseBlob(blob);
                                if (isMount.current) {
                                    // image 작업
                                    let picData = metadata.common.picture.find(item => item.format === "image/gif");
                                    if (picData) {
                                        let blob = new Blob([picData.data], { type: "image/gif" });
                                        let objURL = URL.createObjectURL(blob);

                                        if (imgRef && imgRef.current) {
                                            imgRef.current.setAttribute("data-gif", objURL);
                                        }

                                        const frameData = await gifFrames({ url: objURL, frames: 0, outputType: "canvas" });
                                        if (isMount.current) {
                                            let staticImg = frameData[0].getImage();
                                            let staticBlob = convertDataURIToBinary(staticImg.toDataURL("image/png"));
                                            let staticURL = URL.createObjectURL(staticBlob);

                                            if (imgRef && imgRef.current) {
                                                imgRef.current.src = staticURL;
                                                imgRef.current.setAttribute("data-static", staticURL);
                                            }
                                        }
                                    }

                                    // text 작업
                                    let textData = metadata.common.picture.find(item => item.format === "application/octet-stream");
                                    if (textData) {
                                        let str = new TextDecoder().decode(textData.data);
                                        setTextData(str);
                                    }
                                }
                            }
                        } catch (err) {
                            console.log("parseFile(AnimatedMP3Item) - err => ", err);
                        }
                    })();
                }
            }
        }
    }, [animatedMP3]);

    const toggleAnimatedMP3 = () => {
        if (audioRef && audioRef.current) {
            if (audioRef.current.paused) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        }
    }

    const onPauseAudio = () => {
        if (imgRef && imgRef.current) {
            imgRef.current.src = imgRef.current.getAttribute("data-static");
        }
        if (controlRef && controlRef.current) controlRef.current.pause();
    }

    const onPlayAudio = () => {
        if (imgRef && imgRef.current) {
            imgRef.current.src = imgRef.current.getAttribute("data-gif");
        }
        if (controlRef && controlRef.current) controlRef.current.play();
    }

    const onEndedAudio = () => {
        if (imgRef && imgRef.current) {
            imgRef.current.src = imgRef.current.getAttribute("data-static");
        }
        if (controlRef && controlRef.current) controlRef.current.pause();
    }

    const parseFile = async (url) => {
        try {
            const res = await fetch(url);
            const blob = res.blob();
            if (isMount.current) {
                // mp3 파일 작업
                let audio_objURL = URL.createObjectURL(blob);
                if (audioRef && audioRef.current) {
                    audioRef.current.src = audio_objURL;
                }

                const metadata = await musicMetadata.parseBlob(blob);
                if (isMount.current) {
                    // image 작업
                    let picData = metadata.common.picture.find(item => item.format === "image/gif");
                    if (picData) {
                        let blob = new Blob([picData.data], { type: "image/gif" });
                        let objURL = URL.createObjectURL(blob);

                        if (imgRef && imgRef.current) {
                            imgRef.current.setAttribute("data-gif", objURL);
                        }

                        const frameData = await gifFrames({ url: objURL, frames: 0, outputType: "canvas" });
                        if (isMount.current) {
                            let staticImg = frameData[0].getImage();
                            let staticBlob = convertDataURIToBinary(staticImg.toDataURL("image/png"));
                            let staticURL = URL.createObjectURL(staticBlob);

                            if (imgRef && imgRef.current) {
                                imgRef.current.src = staticURL;
                                imgRef.current.setAttribute("data-static", staticURL);
                            }
                        }
                    }

                    // text 작업
                    let textData = metadata.common.picture.find(item => item.format === "application/octet-stream");
                    if (textData) {
                        let str = new TextDecoder().decode(textData.data);
                        setTextData(str);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const convertDataURIToBinary = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        var bb = new Blob([ab], { "type": mimeString });

        return bb;
    }

    return (
        <>
            <div className="aniMP3-item" type="item">
                <img className="img-item" ref={imgRef} alt="" />
                {
                    textData &&
                    <div className="text-item">
                        <span>{textData}</span>
                    </div>
                }
                <AnimatedMP3Control ref={controlRef} toggle={toggleAnimatedMP3} />
            </div>
            <audio ref={audioRef} style={{ display: 'none' }} controlsList="nodownload" onPause={onPauseAudio} onPlay={onPlayAudio} onEnded={onEndedAudio} />
        </>
    );
}

export default AnimatedMP3Item;